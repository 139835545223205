import styled from 'styled-components'
import { rgba } from 'polished'
import { ClinTheme } from '../../ClinTheme'
import { StyledCheckoutOrderItem } from './CheckoutOrderItem.styles'

export const StyledCheckoutOrder = styled.div({
  '> div > .accordion': {
    '> div > button': {
      paddingLeft: ClinTheme.space[4],
      borderLeft: `1px solid ${ClinTheme.colors.grey}`,
      borderRight: `1px solid ${ClinTheme.colors.grey}`,
      color: ClinTheme.colors.primary
    },
    '> .accordion-panel': {
      padding: 0,
      width: '100%',
      border: `1px solid ${ClinTheme.colors.grey}`,
      borderBottom: 'none',
      boxSizing: 'border-box',

      '.accordion__item': {
        borderBottom: 'none',
        marginBottom: 0,

        'button ': {
          textAlign: 'left',
          paddingTop: ClinTheme.space[4],
          span: {
            fontSize: ClinTheme.fontSizes[3],
            fontWeight: ClinTheme.fontWeights.medium,
            color: ClinTheme.colors.primary
          },
          '.clin-icon': {
            width: ClinTheme.fontSizes[4],
            height: ClinTheme.fontSizes[4]
          }
        },

        '> .accordion > .accordion-panel': {
          width: '100%',
          padding: 0
        }
      },
      [`${StyledCheckoutOrderItem}:last-of-type`]: {
        borderBottom: 'none'
      }
    }
  }
})

export const StyledCheckoutSummary = styled.div({
  padding: ClinTheme.space[4],
  background: ClinTheme.colors.lightGrey
})

export const StyledCheckoutBlock = styled.div({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${ClinTheme.colors.grey}`,
  borderRadius: ClinTheme.radii[1]
})

export const StyledOneStepCheckoutBlock = styled.div({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${ClinTheme.colors.midGrey}`,
  borderRadius: ClinTheme.radii[2]
})

export const StyledCheckoutBlockHeader = styled.div({
  flex: '0 0 100%',
  padding: ClinTheme.space[4]
})

export const StyledCheckoutBlockBody = styled.div({
  flex: '0 0 100%',
  padding: ClinTheme.space[4],
  borderTop: `1px solid ${ClinTheme.colors.grey}`,
  background: rgba(ClinTheme.colors.primaryMid, 0.15)
})

export const StyledOneStepCheckoutBlockBody = styled.div({
  flex: '0 0 100%',
  padding: ClinTheme.space[4],
  borderTop: `1px solid ${ClinTheme.colors.grey}`,
  backgroundColor: ClinTheme.colors.lightPurple
})
export const StyledCheckoutBlockBodyWrap = styled.div({
  flex: '0 0 100%',
  padding: ClinTheme.space[4],
  borderTop: `1px solid ${ClinTheme.colors.grey}`,
  background: '#EEEDFF'
})
