import React, { FunctionComponent } from 'react'
import { t } from 'i18next'
import { Col } from 'react-grid-system'
import { ClinTheme } from '../../../ClinTheme'
import { BasicSearch } from '../../../features/Search/BasicSearch'
import {
  StyledDocumentSearchContentWrapper,
  StyledDocumentSearchDivWrapper,
  StyledDocumentSearchWrapper
} from './ProgramDetail.styles'

interface IPatientsSearchProps {
  /** When a search query is entered and ENTER pressed */
  handleOnChange?: (query: string) => void
  initialQuery?: string
}

export const ProgramDocumentsSearch: FunctionComponent<
  IPatientsSearchProps
> = ({ handleOnChange, initialQuery }) => {
  return (
    <StyledDocumentSearchDivWrapper
      background={ClinTheme.colors.white}
      showTableInTab={true}
    >
      <StyledDocumentSearchWrapper showTableInTab={true}>
        <Col md={8} offset={{ md: 2 }} style={{ marginLeft: '0px' }}>
          <StyledDocumentSearchContentWrapper>
            <BasicSearch
              borderRadious={'6'}
              borderColor="#A1A9B8"
              height={`${ClinTheme.space[5]}px`}
              textIndent={ClinTheme.space[4]}
              fontSize={ClinTheme.fontSizes[1]}
              maxWidth={'289px'}
              iconHeight={`${ClinTheme.space[3]}px`}
              iconSize={`${ClinTheme.space[3]}px`}
              marginLeft={1}
              iconColor={ClinTheme.colors.darkGrey}
              iconStyle={{
                position: 'absolute',
                left: ClinTheme.space[2],
                top: '50%',
                transform: 'translateY(-50%)'
              }}
              initialQuery={initialQuery}
              placeholder={t('program_detail:patient_search_placeholder')}
              debounceTimeMs={500}
              handleOnChange={(query) =>
                handleOnChange && handleOnChange(query)
              }
            />
          </StyledDocumentSearchContentWrapper>
        </Col>
      </StyledDocumentSearchWrapper>
    </StyledDocumentSearchDivWrapper>
  )
}
