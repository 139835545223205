import { LightweightInstituteDto } from '../types/swaggerTypes'

export enum InstituteEventType {
  GET_INSTITUTES = 'GetInstitutesEvent',
  GET_INSTITUTE = 'GetInstituteEvent',
  SWITCH = 'SwitchInstituteEvent'
}

// Get Institutes ------------------------------------------------------------------------

export interface IGetInstitutesEvent {
  type: InstituteEventType.GET_INSTITUTES
  institutes: LightweightInstituteDto[] | undefined
}

export const createGetInstitutesEvent = (
  institutes: LightweightInstituteDto[] | undefined
): IGetInstitutesEvent => ({
  type: InstituteEventType.GET_INSTITUTES,
  institutes
})

// Get ------------------------------------------------------------------------

export interface IGetInstituteEvent {
  type: InstituteEventType.GET_INSTITUTE
  instituteId: string
}

export const createGetInstituteEvent = (
  instituteId: string
): IGetInstituteEvent => ({
  type: InstituteEventType.GET_INSTITUTE,
  instituteId
})

// Switch ------------------------------------------------------------------------

export interface ISwitchInstituteEvent {
  type: InstituteEventType.SWITCH
  instituteId: string
  dontNotify?: boolean
}

export const createSwitchInstituteEvent = (
  instituteId: string,
  dontNotify: boolean = false
): ISwitchInstituteEvent => ({
  type: InstituteEventType.SWITCH,
  instituteId,
  dontNotify
})

export type IInstituteEvent =
  | IGetInstitutesEvent
  | IGetInstituteEvent
  | ISwitchInstituteEvent
