import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
  CancelTokenSource,
  Method,
  ResponseType
} from 'axios'
import config from './ConfigProvider'
import authService from './AuthService'
import { User } from 'oidc-client'
import { BookmarkableItem } from '../types/BookmarkableItem'
import { SortDirectionType } from '../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { getLocallyStoredInstituteId } from './UserInstituteService'
import _ from 'lodash'
import {
  BasketCheckedOutDto,
  BasketCheckingOutDto,
  BasketDto,
  BasketItemDocumentDto,
  BasketItemDto,
  BasketSummaryDto,
  BookmarkDto,
  BookmarkSearchDto,
  BookmarkSearchResultDto,
  BookmarkStatusRequestDto,
  BookmarkStatusResponseDto,
  CatalogDto,
  CatalogItemDto,
  ChangePasswordRequestDto,
  ContactDto,
  ContactSearchDto,
  ContactSearchResultDto,
  CountryDto,
  CreateCdhShippingAddressDto,
  CreateContactRequestDto,
  CreatePatientRequestDTO,
  CreatePatientResponseDTO,
  CreateShippingAddressDto,
  CreateShortageRequestDto,
  CreateSourcingRequestDto,
  DeleteContactResponseDto,
  DeleteOrderDto,
  DiscontinuePatientRequestDto,
  DistributorDto,
  EnrollingPhsiciansDto,
  GetPhysiciansEnrolledQueryResultDto,
  HoldDto,
  IncidentResponseDto,
  InitiateOPARequestDto,
  InitReadOnlyOPARequestDto,
  InitReadOnlyOPAResponseDto,
  InstituteContactDto,
  InstituteDto,
  LightweightInstituteDto,
  MarketingPreferenceDto,
  MarketingPreferencesDto,
  TourViewDto,
  NewInstituteDetilsDto,
  NewInstituteRequestDto,
  NewPhysicianDetilsDto,
  OrderAddressDto,
  OrderDto,
  OrderLineDto,
  OrderSearchDto,
  OrderSearchResultDto,
  OrgAddressDto,
  PatientDefaultDto,
  PatientDetailsDto,
  PatientSummarySearchDto,
  PatientSummaryAssociatedToPhysiciansSearchDto,
  PatientSummaryAssociatedToPhysiciansSearchDtoForOpaForm,
  PatientSummarySearchResultDto,
  PhysicianDetailRequestDto,
  PhysicianDetailsDto,
  PhysiciansEnrolledSummaryDto,
  PhysiciansEnrolledSummarySearchQueryResultDto,
  PhysiciansSummarySearchDto,
  PhysiciansSummarySearchQueryResultDto,
  PriceAndAvailabilityDto,
  ProductSearchDto,
  ProductSearchResultDto,
  ProductSuggestResultDto,
  ProgramCatalogDto,
  ProgramDocumentsSearchDto,
  ProgramDocumentsSearchResultDto,
  ProgramSearchDto,
  ProgramSearchResultDto,
  RecentItemDto,
  ReconcileVialRequestDto,
  RegisterInterestDto,
  RegisterInterestInProductDto,
  RegisterInterestResultDto,
  RepeatOrderResultDto,
  ShortagesSearchDto,
  ShortagesSearchResultDto,
  SkinnySourcingDto,
  SpecialismsDto,
  SuggestDto,
  UpdateBasketOrderDto,
  UpdateContactDto,
  UpdateOrderDto,
  UploadedDocumentDto,
  UploadedOrderHoldDocumentDto,
  UserDto,
  UserEmailDto,
  UserStatusDto,
  WarehouseDto,
  PatientSummaryAssociatedToPhysiciansSearchResultDto,
  ProgramSuggestResultDto,
  DeleteOPARequestDto,
  UpdatePatientRequestDto,
  UpdatePatientResponseDto
} from '../types/swaggerTypes'
import { IBanner } from '../types/IBanner'
import { IIndexable } from '../types'
import { LovName } from '../constants'
import { HoldType } from './Holds/holds.constants'
import analyticsServiceSingleton from './Analytics/initAnalytics'
import { AnalyticsEvent } from './Analytics/AnalyticsEvent'

export const REQUEST_CANCELLED = 'REQUEST_CANCELLED'

export enum AuthError {
  NetWorkError = 'Network error occurred. Please try again later.',
  TokenMissingOrExpired = 'User is missing access_token or token expired',
  RenewingTokenError = 'An error occurred while renewing the access token',
  UnknownAuthError = 'An unknown auth error occurred',
  RequestCancelled = 'Request was cancelled before completing'
}

/**
 * Specific errors that need to be handled in frontend
 */
export enum ApiErrorType {
  Generic = 'urn:clinigen:onlineservices:errors:generic-error', // Any generic errors
  Basket = 'urn:clinigen:onlineservices:errors:basket-state-error', // When you try to abandon a basket that is in a state other than open, When you try to complete checkout of a basket that has had its checkout cancelled/hasn't started, When you try to begin checkout of a basket that is already in checkout
  Checkout = 'urn:clinigen:onlineservices:errors:checkout-error', // When you pass an invalid CheckoutStamp to the checkout/complete API call
  Dependency = 'urn:clinigen:onlineservices:errors:dependency-error' // When an error occurs with eAppsysApi dependency call
  /*
      Possible Api errors from BFF
      "urn:clinigen:onlineservices:errors:not-found";
      "urn:clinigen:onlineservices:errors:dependency-error";
      "urn:clinigen:onlineservices:errors:account-does-not-exist";
      "urn:clinigen:onlineservices:errors:basket-state-error";
      "urn:clinigen:onlineservices:errors:checkout-error";
      "urn:clinigen:onlineservices:errors:client-error";
      "urn:clinigen:onlineservices:errors:validation-error";
      "urn:clinigen:onlineservices:errors:security-error";
      "urn:clinigen:onlineservices:errors:permission-error";
      "urn:clinigen:onlineservices:errors:conflict";
      "urn:clinigen:onlineservices:errors:generic-error";
      "urn:clinigen:onlineservices:errors:internal-error";
      "urn:clinigen:onlineservices:errors:internal-error";
     */
}

/**
 * Utility method to add token to headers
 * @param user
 */
const setAuthTokenToHeader = (user: User) => {
  axios.defaults.headers.common = {
    Accept: 'application/json',
    Authorization: `Bearer ${user.access_token}`
  }
}

/**
 * Utility method to set request
 * @param path
 * @param method
 * @param payload
 * @param cancelToken
 * @param responseType
 */
const setRequestConfig = (
  path: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
  payload?: any,
  cancelToken?: CancelToken,
  responseType:
    | 'arraybuffer'
    | 'blob'
    | 'document'
    | 'json'
    | 'text'
    | 'stream' = 'json'
): AxiosRequestConfig => ({
  method: method as Method,
  url: `${config.bffRoot}/${path}`,
  data: payload,
  cancelToken,
  responseType: responseType as ResponseType,
  timeout: 60 * 1000 // timeout in milliseconds - causes a network error if this is exceeded
})

/**
 * Generic call wrapper method which inserts a valid auth token
 * Use to wrap any API calls
 * @param path
 * @param method
 * @param payload
 * @param cancelToken
 * @param responseType
 */
const call = async <Response = any>(
  path: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
  payload?: any,
  cancelToken?: CancelToken,
  responseType?: ResponseType
): Promise<AxiosResponse<Response>> => {
  let oidcUser: User | null = null
  let authRequest: AxiosResponse<Response>
  let requestConfig: AxiosRequestConfig = {} // Initialize with an empty object
  try {
    // Check if we have a valid user/token
    oidcUser = await authService.getUserAsync()
    oidcUser && setAuthTokenToHeader(oidcUser)
    requestConfig = setRequestConfig(
      path,
      method,
      payload,
      cancelToken,
      responseType
    )
    authRequest = await axios.request<Response>(requestConfig)

    // Track the API response data
    analyticsServiceSingleton.trackEvent(AnalyticsEvent.ApiResponceSuccess, {
      method,
      url: requestConfig.url,
      responseData: authRequest.data,
      status: authRequest.status,
      statusText: authRequest.statusText,
      userEmail: oidcUser?.profile.email
    })
  } catch (err) {
    const error = err as Error | AxiosError
    const isAxiosError = axios.isAxiosError(error)

    // Track the API error response data
    analyticsServiceSingleton.trackEvent(AnalyticsEvent.ApiResponceFailure, {
      method,
      url: requestConfig.url,
      errorMessage: error.message,
      status: isAxiosError ? error.response?.status : undefined,
      statusText: isAxiosError ? error.response?.statusText : undefined,
      userEmail: oidcUser?.profile.email
    })

    // If a network request was cancelled
    if (error.message === REQUEST_CANCELLED) {
      console.info(AuthError.RequestCancelled)
      return Promise.reject(new Error(AuthError.RequestCancelled))
    }
    // If a network error (disconnected)
    if (isAxiosError && !error.response) {
      console.warn(AuthError.NetWorkError)
      return Promise.reject(new Error(AuthError.NetWorkError))
    }
    // If token has expired use refreshToken to get a new token/user
    if (
      isAxiosError &&
      error.response?.data?.type !==
        'urn:clinigen:onlineservices:errors:dependency-error' &&
      error.response?.status === 401
    ) {
      console.warn(AuthError.TokenMissingOrExpired)
      try {
        oidcUser = await authService.renewTokenAsync()
        oidcUser && setAuthTokenToHeader(oidcUser)
        requestConfig = setRequestConfig(path, method, payload)
        authRequest = await axios.request<Response>(requestConfig)
      } catch (error) {
        console.warn(AuthError.RenewingTokenError)
        // Redirect to login if a user tries to spoof
        await authService.login()
        return Promise.reject(new Error(AuthError.RenewingTokenError))
      }
    } else {
      console.warn(AuthError.UnknownAuthError)
      return Promise.reject(error)
    }
  }
  return authRequest
}

// User ------------------------------------------------------------------

let cancelGetCurrentUserSource: CancelTokenSource

const cancelGetCurrentUser = () => {
  cancelGetCurrentUserSource &&
    cancelGetCurrentUserSource.cancel(REQUEST_CANCELLED)
}

const getCurrentUser = (): Promise<AxiosResponse<UserDto>> => {
  cancelGetCurrentUserSource = axios.CancelToken.source()
  return call<UserDto>(
    `api/User`,
    'GET',
    null,
    cancelGetCurrentUserSource.token
  )
}

// Institutes ------------------------------------------------------------------

let cancelGetUserInstitutesSource: CancelTokenSource

const cancelGetUserInstitutes = () => {
  cancelGetUserInstitutesSource &&
    cancelGetUserInstitutesSource.cancel(REQUEST_CANCELLED)
}

const getUserInstitutes = (): Promise<
  AxiosResponse<LightweightInstituteDto[]>
> => {
  cancelGetUserInstitutesSource = axios.CancelToken.source()
  return call<LightweightInstituteDto[]>(
    `api/User/institutes`,
    'GET',
    null,
    cancelGetUserInstitutesSource.token
  )
}

let cancelGetInstituteForIdSource: CancelTokenSource

const cancelGetInstituteForId = () => {
  cancelGetInstituteForIdSource &&
    cancelGetInstituteForIdSource.cancel(REQUEST_CANCELLED)
}

const getInstituteForId = (
  instituteId: string
): Promise<AxiosResponse<InstituteDto>> => {
  cancelGetInstituteForIdSource = axios.CancelToken.source()
  return call<InstituteDto>(
    `api/institute/${instituteId}`,
    'GET',
    cancelGetInstituteForIdSource.token
  )
}

// Products ------------------------------------------------------------------

let cancelSearchProductsSource: CancelTokenSource

const cancelGetProducts = () => {
  cancelSearchProductsSource &&
    cancelSearchProductsSource.cancel(REQUEST_CANCELLED)
}

const getProducts = ({
  query,
  filter,
  pagination,
  sorting
}: ProductSearchDto): Promise<AxiosResponse<ProductSearchResultDto>> => {
  cancelSearchProductsSource = axios.CancelToken.source()
  return call<ProductSearchResultDto>(
    'api/Products/search',
    'POST',
    {
      query: query ?? '*',
      filter,
      pagination,
      sorting
    },
    cancelSearchProductsSource.token
  )
}

// Therapeutic Areas ------------------------------------------------------------------

let cancelGetTherapeuticAreasSource: CancelTokenSource

const cancelGetTherapeuticAreas = () => {
  cancelGetTherapeuticAreasSource &&
    cancelGetTherapeuticAreasSource.cancel(REQUEST_CANCELLED)
}

// We don't really search for products here, we just want the list of tags that's returned from the search request
const getSearchFacetsForCountry = (
  countryCode: string
): Promise<AxiosResponse<ProductSearchResultDto>> => {
  cancelGetTherapeuticAreasSource = axios.CancelToken.source()
  return call<ProductSearchResultDto>(
    'api/Products/search',
    'POST',
    {
      query: '*',
      filter: {
        tags: [],
        availabilities: [],
        countryCode
      },
      pagination: {
        skip: 0,
        take: 0
      },
      sorting: {
        sortBy: '',
        order: SortDirectionType.Descending
      }
    },
    cancelGetTherapeuticAreasSource.token
  )
}

let cancelGetProductByIdSource: CancelTokenSource

const cancelGetProductById = () => {
  cancelGetProductByIdSource &&
    cancelGetProductByIdSource.cancel(REQUEST_CANCELLED)
}

const getProductById = (id: string): Promise<AxiosResponse<CatalogDto>> => {
  cancelGetProductByIdSource = axios.CancelToken.source()
  return call<CatalogDto>(
    `api/Products/catalog/${id}`,
    'GET',
    null,
    cancelGetProductByIdSource.token
  )
}

let cancelGetProductBySkuSource: CancelTokenSource

const cancelGetProductBySku = () => {
  cancelGetProductBySkuSource &&
    cancelGetProductBySkuSource.cancel(REQUEST_CANCELLED)
}

const getProductBySku = (
  sku: string
): Promise<AxiosResponse<CatalogItemDto>> => {
  cancelGetProductBySkuSource = axios.CancelToken.source()

  return call<CatalogItemDto>(
    `api/Products/skus/${sku}`,
    'GET',
    cancelGetProductBySkuSource.token
  )
}

// Catalogue Typeahead ------------------------------------------------------------------

let cancelProductsSuggestionSource: CancelTokenSource

const cancelGetProductsSuggestion = () => {
  cancelProductsSuggestionSource?.token &&
    cancelProductsSuggestionSource.cancel(REQUEST_CANCELLED)
}

const getProductsSuggestion = ({
  query,
  limit = 10,
  derivedFromFilter,
  countryCode
}: SuggestDto): Promise<AxiosResponse<ProductSuggestResultDto>> => {
  cancelProductsSuggestionSource = axios.CancelToken.source()
  return call<ProductSuggestResultDto>(
    'api/Products/suggest',
    'POST',
    {
      query,
      limit,
      derivedFromFilter,
      countryCode
    },
    cancelProductsSuggestionSource.token
  )
}

// Programs ----------------------------------------------------------------

let cancelProgramsSuggestionSource: CancelTokenSource

const cancelGetProgramsSuggestion = () => {
  cancelProgramsSuggestionSource?.token &&
    cancelProgramsSuggestionSource.cancel(REQUEST_CANCELLED)
}

const getProgramsSuggestion = async ({
  query,
  limit = 10,
  derivedFromFilter,
  countryCode
}: SuggestDto): Promise<AxiosResponse<ProgramSuggestResultDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelProgramsSuggestionSource = axios.CancelToken.source()
  return call<ProgramSuggestResultDto>(
    `api/institute/${instituteId}/Programs/suggest`,
    'POST',
    {
      query,
      limit,
      derivedFromFilter,
      countryCode
    },
    cancelProgramsSuggestionSource.token
  )
}

let cancelGetProgramsSource: CancelTokenSource

const cancelGetEnrolledPrograms = () => {
  cancelGetProgramsSource && cancelGetProgramsSource.cancel(REQUEST_CANCELLED)
}

const getEnrolledPrograms = async ({
  filter,
  pagination,
  sorting
}: ProgramSearchDto): Promise<AxiosResponse<ProgramSearchResultDto> | null> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelGetProgramsSource = axios.CancelToken.source()
  return instituteId
    ? call<ProgramSearchResultDto>(
        `api/institute/${instituteId}/Programs/enrolled`,
        'POST',
        {
          query: '',
          filter,
          pagination,
          sorting
        },
        cancelGetProgramsSource.token
      )
    : null
}

let cancelGetAllProgramsSource: CancelTokenSource

const cancelGetAllPrograms = () => {
  cancelGetAllProgramsSource &&
    cancelGetAllProgramsSource.cancel(REQUEST_CANCELLED)
}

const getAllPrograms = async ({
  query,
  filter,
  pagination,
  sorting
}: ProgramSearchDto): Promise<AxiosResponse<ProgramSearchResultDto> | null> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelGetAllProgramsSource = axios.CancelToken.source()
  return instituteId
    ? call<ProgramSearchResultDto>(
        `api/institute/${instituteId}/Programs/other`,
        'POST',
        {
          query: query ?? '',
          filter,
          pagination,
          sorting
        },
        cancelGetAllProgramsSource.token
      )
    : null
}

// Orders ------------------------------------------------------------------
let cancelGetOrdersSource: CancelTokenSource

const cancelGetOrders = () => {
  cancelGetOrdersSource && cancelGetOrdersSource.cancel(REQUEST_CANCELLED)
}

const getOrders = async ({
  query,
  filter,
  pagination,
  sorting
}: OrderSearchDto): Promise<AxiosResponse<OrderSearchResultDto> | null> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelGetOrdersSource = axios.CancelToken.source()
  return instituteId
    ? call<OrderSearchResultDto>(
        `api/institute/${instituteId}/orders/search`,
        'POST',
        {
          query: query ?? '', // Formerly `query: query || ''` -- this fires even when no query is entered,
          filter,
          pagination,
          sorting
        },
        cancelGetOrdersSource.token
      ).then((value) => {
        if (query && query !== '') {
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.ResultsForSearchQuery,
            {
              query: query,
              numberResults: value.data.result.length
            }
          )
        }
        // Translate On Hold into under review R3 #10845
        const updatedOrders = value.data.result.map((ord) => {
          return {
            ...ord,
            orderStatus:
              ord.orderStatus === 'On Hold' ? 'Under Review' : ord.orderStatus
          }
        })
        return {
          ...value,
          data: {
            ...value.data,
            result: updatedOrders
          }
        }
      })
    : null
}

let cancelGetOrderByIdSource: CancelTokenSource

const cancelGetOrderById = () => {
  cancelGetOrderByIdSource && cancelGetOrderByIdSource.cancel(REQUEST_CANCELLED)
}

const getOrderById = async (id: string): Promise<AxiosResponse<OrderDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelGetOrderByIdSource = axios.CancelToken.source()
  return call<OrderDto>(
    `api/institute/${instituteId}/orders/${id}`,
    'GET',
    null,
    cancelGetOrderByIdSource.token
  ).then((value) => {
    // Translate On Hold into under review R3 #10845
    const order = value.data
    const updatedOrder = {
      ...order,
      lines: order.lines.map((orderLine: OrderLineDto) => {
        if (orderLine.status === 'On Hold') {
          orderLine.status = 'Under Review'
        }
        return orderLine
      }),
      orderStatus:
        order.orderStatus === 'On Hold' ? 'Under Review' : order.orderStatus
    }
    return {
      ...value,
      data: updatedOrder
    }
  })
}

let cancelRepeatOrderSource: CancelTokenSource

const cancelRepeatOrder = () => {
  cancelRepeatOrderSource && cancelRepeatOrderSource.cancel(REQUEST_CANCELLED)
}

const requestRepeatOrder = async (
  orderId: string,
  newPONumber?: string
): Promise<AxiosResponse<RepeatOrderResultDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelRepeatOrderSource = axios.CancelToken.source()
  return call<RepeatOrderResultDto>(
    `api/institute/${instituteId}/orders/${orderId}/repeat`,
    'POST',
    {
      poNumber: newPONumber ?? null
    },
    cancelRepeatOrderSource.token
  )
}

let cancelRemoveOrderLineSource: CancelTokenSource

const cancelRemoveOrderLine = () => {
  cancelRemoveOrderLineSource &&
    cancelRemoveOrderLineSource.cancel(REQUEST_CANCELLED)
}

const removeOrderLine = async (
  orderId: string,
  lineId: string,
  deleteReason: DeleteOrderDto
): Promise<AxiosResponse<OrderDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelRemoveOrderLineSource = axios.CancelToken.source()

  return call<OrderDto>(
    `api/institute/${instituteId}/Orders/${orderId}/lines/${lineId}`,
    'DELETE',
    deleteReason,
    cancelRemoveOrderLineSource.token
  )
}

let cancelRemoveOrdeSource: CancelTokenSource

const cancelRemoveOrder = () => {
  cancelRemoveOrdeSource && cancelRemoveOrdeSource.cancel(REQUEST_CANCELLED)
}

const removeOrder = async (
  orderId: string,
  deleteReason: DeleteOrderDto
): Promise<AxiosResponse<OrderDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelRemoveOrdeSource = axios.CancelToken.source()

  return call<OrderDto>(
    `api/institute/${instituteId}/Orders/${orderId}`,
    'DELETE',
    deleteReason,
    cancelRemoveOrdeSource.token
  )
}

// Account ------------------------------------------------------------------

const updatePassword = (
  oldPassword: string,
  newPassword: string,
  confirmPassword: string
): Promise<AxiosResponse<ChangePasswordRequestDto>> => {
  return call<ChangePasswordRequestDto>(`api/User/password`, 'PUT', {
    oldPassword,
    newPassword,
    confirmPassword
  })
}

// Bookmarks ---------------------------------------------------------------
let cancelGetBookmarksSource: CancelTokenSource

const cancelGetBookmarks = () => {
  cancelGetBookmarksSource && cancelGetBookmarksSource.cancel(REQUEST_CANCELLED)
}

const getBookmarks = async ({
  filter,
  query,
  pagination,
  sorting
}: BookmarkSearchDto): Promise<AxiosResponse<BookmarkSearchResultDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelGetBookmarksSource = axios.CancelToken.source()
  return call<BookmarkSearchResultDto>(
    `api/institute/${instituteId}/bookmarks/search`,
    'POST',
    {
      filter,
      query,
      pagination,
      sorting
    },
    cancelGetBookmarksSource.token
  )
}

const getItemBookmarkStatuses = async (items: BookmarkStatusRequestDto[]) => {
  const instituteId = await getLocallyStoredInstituteId()
  return call<BookmarkStatusResponseDto[]>(
    `api/institute/${instituteId}/bookmarks/status`,
    'POST',
    items
  )
}

interface GetItemBookmarkStatusQueueItem {
  statusRequest: BookmarkStatusRequestDto
  reject: (err: any) => void
  resolve: (bookmark: BookmarkStatusResponseDto | null) => void
  isCancelled: boolean
}

// Different containers call getItemBookmarkStatus independently, but we don't want
// to make 10s of requests to the bookmark status API
// instead we collect the requests and merge them into a single call to the status API
let getItemBookmarkQueue: GetItemBookmarkStatusQueueItem[] = []
const processItemBookmarkQueue = _.debounce(() => {
  const queueItems = getItemBookmarkQueue.slice()
  getItemBookmarkQueue = []
  getItemBookmarkStatuses(
    queueItems.map((queueItem) => queueItem.statusRequest)
  )
    .then((response) => {
      queueItems.forEach((queueItem, i) => {
        if (!queueItem.isCancelled) {
          const bookmarkStatus = response.data[i]
          queueItem.resolve(bookmarkStatus.isBookmarked ? bookmarkStatus : null)
        }
      })
    })
    .catch((err) => {
      queueItems.forEach((queueItem) => queueItem.reject(err))
    })
}, 100)

// Provide this cancellation function as the underlying request is debounced
// and might otherwise start being made after the cancellation
let cancelGetLastItemBookmarkStatus: () => void
const getCancelGetLastItemBookmarkStatus = () => {
  return cancelGetLastItemBookmarkStatus
}

const getItemBookmarkStatus = async (
  bookmarkOptions: BookmarkableItem
): Promise<BookmarkStatusResponseDto | null> => {
  return new Promise((resolve, reject) => {
    const queueItem: GetItemBookmarkStatusQueueItem = {
      statusRequest: bookmarkOptions,
      resolve,
      reject,
      isCancelled: false
    }
    getItemBookmarkQueue.push(queueItem)
    cancelGetLastItemBookmarkStatus = () => {
      queueItem.isCancelled = true
    }
    processItemBookmarkQueue()
  })
}

let cancelCreateBookmarkSource: CancelTokenSource

const createBookmark = async (
  bookmarkOptions: BookmarkableItem
): Promise<AxiosResponse<BookmarkDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelCreateBookmarkSource = axios.CancelToken.source()
  return call<BookmarkDto>(
    `api/institute/${instituteId}/bookmarks`,
    'POST',
    bookmarkOptions,
    cancelCreateBookmarkSource.token
  )
}

const cancelCreateBookmark = async () =>
  cancelCreateBookmarkSource &&
  cancelCreateBookmarkSource.cancel(REQUEST_CANCELLED)

let cancelDeleteBookmarkSource: CancelTokenSource
const deleteBookmark = async (bookmarkId: string): Promise<AxiosResponse> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelDeleteBookmarkSource = axios.CancelToken.source()
  return call<BookmarkDto>(
    `api/institute/${instituteId}/bookmarks/${bookmarkId}`,
    'DELETE',
    cancelDeleteBookmarkSource.token
  )
}

const cancelDeleteBookmark = async () =>
  cancelCreateBookmarkSource &&
  cancelCreateBookmarkSource.cancel(REQUEST_CANCELLED)

// Recently viewed ---------------------------------------------------------

let cancelGetRecentlyViewedSkusSource: CancelTokenSource

const cancelGetRecentlyViewedSkus = () => {
  cancelGetRecentlyViewedSkusSource &&
    cancelGetRecentlyViewedSkusSource.cancel(REQUEST_CANCELLED)
}

const getRecentlyViewedSkus = async ({
  take
}: {
  take: number
}): Promise<AxiosResponse<RecentItemDto[]> | undefined> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelGetRecentlyViewedSkusSource = axios.CancelToken.source()
  return instituteId
    ? call<RecentItemDto[]>(
        `api/institute/${instituteId}/recents/skus?skip=0&take=${take}`,
        'GET',
        {},
        cancelGetRecentlyViewedSkusSource.token
      )
    : undefined
}

const touchSkuForRecentlyViewed = async (skuId: string) => {
  const instituteId = await getLocallyStoredInstituteId()
  return call(
    `api/institute/${instituteId}/Products/skus/${skuId}/touch`,
    'POST',
    {}
  )
}

// Basket ------------------------------------------------------------------

let cancelGetPriceAndAvailabilityForSkuSource: CancelTokenSource

const cancelGetPriceAndAvailabilityForSku = () => {
  cancelGetPriceAndAvailabilityForSkuSource &&
    cancelGetPriceAndAvailabilityForSkuSource.cancel(REQUEST_CANCELLED)
}

const getPriceAndAvailabilityForSku = async (
  sku: string,
  addressId: string
): Promise<AxiosResponse<PriceAndAvailabilityDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelGetPriceAndAvailabilityForSkuSource = axios.CancelToken.source()
  return call<PriceAndAvailabilityDto>(
    `api/institute/${instituteId}/products/skus/${sku}/availability/for_address/${addressId}`,
    'GET',
    {},
    cancelGetPriceAndAvailabilityForSkuSource.token
  )
}

let cancelGetCurrentBasketSummarySource: CancelTokenSource

const cancelGetCurrentBasketSummary = () => {
  cancelGetCurrentBasketSummarySource &&
    cancelGetCurrentBasketSummarySource.cancel(REQUEST_CANCELLED)
}

const getCurrentBasketSummary = async (): Promise<
  AxiosResponse<BasketSummaryDto>
> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelGetCurrentBasketSummarySource = axios.CancelToken.source()
  return call<BasketSummaryDto>(
    `api/institute/${instituteId}/Basket/current/summary`,
    'GET',
    {},
    cancelGetCurrentBasketSummarySource.token
  )
}

let cancelGetCurrentBasketSource: CancelTokenSource

const cancelGetCurrentBasket = () => {
  cancelGetCurrentBasketSource &&
    cancelGetCurrentBasketSource.cancel(REQUEST_CANCELLED)
}

const getCurrentBasket = async (): Promise<AxiosResponse<BasketDto> | null> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelGetCurrentBasketSource = axios.CancelToken.source()
  return instituteId
    ? call<BasketDto>(
        `api/institute/${instituteId}/Basket/current`,
        'GET',
        {},
        cancelGetCurrentBasketSource.token
      )
    : null
}

let cancelUpsertSkuItemToBasketSource: CancelTokenSource

const cancelUpsertSkuItemToBasket = () => {
  cancelUpsertSkuItemToBasketSource &&
    cancelUpsertSkuItemToBasketSource.cancel(REQUEST_CANCELLED)
}

const upsertSkuItemToBasket = async (
  sku: string,
  quantity: number
): Promise<AxiosResponse<BasketDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelUpsertSkuItemToBasketSource = axios.CancelToken.source()
  return call<BasketDto>(
    `api/institute/${instituteId}/Basket/current/items/by_sku/${sku}`,
    'PUT',
    {
      sku,
      quantity
    },
    cancelUpsertSkuItemToBasketSource.token
  )
}

let cancelRemoveItemFromBasketSource: CancelTokenSource

const cancelRemoveItemFromBasket = () => {
  cancelRemoveItemFromBasketSource &&
    cancelRemoveItemFromBasketSource.cancel(REQUEST_CANCELLED)
}

const removeItemFromBasket = async (
  basketItemId: string
): Promise<AxiosResponse> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelRemoveItemFromBasketSource = axios.CancelToken.source()
  return call<AxiosResponse>(
    `api/institute/${instituteId}/Basket/current/items/${basketItemId}`,
    'DELETE',
    {},
    cancelRemoveItemFromBasketSource.token
  )
}

let cancelUpdateBasketItemQuantitySource: CancelTokenSource

const cancelUpdateBasketItemQuantity = () => {
  cancelUpdateBasketItemQuantitySource &&
    cancelUpdateBasketItemQuantitySource.cancel(REQUEST_CANCELLED)
}

const updateBasketItemQuantity = async (
  basketItemId: string,
  quantity: number,
  productSku: string
): Promise<AxiosResponse<BasketItemDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelUpdateBasketItemQuantitySource = axios.CancelToken.source()
  return call<BasketItemDto>(
    `api/institute/${instituteId}/Basket/current/items/${basketItemId}`,
    'PUT',
    {
      basketItemId,
      quantity,
      sku: productSku
    },
    cancelUpdateBasketItemQuantitySource.token
  )
}

const emptyCurrentBasket = async (): Promise<AxiosResponse<BasketDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  return call<BasketDto>(
    `api/institute/${instituteId}/Basket/current`,
    'DELETE'
  )
}

/**
 * Place a draft order in Oracle EBS
 */
const placeDraftOrder = async (
  currencyCode: string = 'GBP', // TODO: Get this from the P&A
  poNumber: string,
  deliverToContact: string,
  shippingAddressId?: string,
  customOrderAddress?: OrderAddressDto,
  recipientEmail?: string,
  recipientPhoneNumber?: string
): Promise<AxiosResponse<BasketCheckingOutDto>> => {
  const instituteId = await getLocallyStoredInstituteId()

  return call<BasketCheckingOutDto>(
    `api/institute/${instituteId}/Basket/current/checkout/begin`,
    'POST',
    {
      currencyCode,
      shippingAddressId,
      poNumber,
      deliverToContact,
      customOrderAddress,
      recipientEmail,
      recipientPhoneNumber
    }
  )
}

/**
 * Update a basket with details of address, PO number and deliveryContact
 */
const updateBasketDetails = async (
  updateBasketDetails: UpdateOrderDto
): Promise<AxiosResponse<BasketDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  return call<BasketDto>(
    `api/institute/${instituteId}/Basket/current`,
    'PUT',
    updateBasketDetails
  )
}
/**
 * Update customJson for AUS radio option in Basket
 */
const updateHoldOption = async (
  basketItemId: string,
  updateHoldDetails: {
    holdKey: HoldType | undefined
    customJson: string
  }
): Promise<AxiosResponse> => {
  const instituteId = await getLocallyStoredInstituteId()
  return call(
    `api/institute/${instituteId}/Basket/current/items/${basketItemId}/updateCustomJson`,
    'PUT',
    updateHoldDetails
  )
}
/**
 * Cancel the current active checkout session
 */
const cancelDraftOrder = async (): Promise<AxiosResponse<BasketDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  return call<BasketDto>(
    `api/institute/${instituteId}/Basket/current/checkout/cancel`,
    'POST'
  )
}

/**
 * Book all Oracle EBS orders created during the current active checkout session
 */
const submitOrder = async (
  checkoutStamp: string
): Promise<AxiosResponse<BasketCheckedOutDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  return call<BasketCheckedOutDto>(
    `api/institute/${instituteId}/Basket/current/checkout/complete`,
    'POST',
    {
      checkoutStamp
    }
  ).then((value) => {
    //Translate On Hold into under review R3 #10845
    const updatedOrders = value.data.checkedOutOrders.map((ord) => {
      return {
        ...ord,
        lines: ord.lines.map((orderLine: OrderLineDto) => {
          if (orderLine.status === 'On Hold') {
            orderLine.status = 'Under Review'
          }
          return orderLine
        }),
        orderStatus:
          ord.orderStatus === 'On Hold' ? 'Under Review' : ord.orderStatus
      }
    })
    return {
      ...value,
      data: {
        ...value.data,
        checkedOutOrders: updatedOrders
      }
    }
  })
}

/**
 * Update an order
 */

let cancelUpdateOrderSource: CancelTokenSource

const cancelUpdateOrder = () => {
  cancelUpdateBasketItemQuantitySource &&
    cancelUpdateBasketItemQuantitySource.cancel(REQUEST_CANCELLED)
}

const updateOrder = async (
  orderId: string,
  updatedOrder: UpdateOrderDto
): Promise<AxiosResponse<OrderDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelUpdateOrderSource = axios.CancelToken.source()
  return call<OrderDto>(
    `api/institute/${instituteId}/Orders/${orderId}`,
    'PUT',
    updatedOrder,
    cancelUpdateOrderSource.token
  )
}

/**
 * Update basket order (send shipment hold for custom delivery  //clos-6630)
 */

let cancelUpdateBasketOrderSource: CancelTokenSource

const cancelUpdateBasketOrder = () => {
  cancelUpdateBasketOrderSource &&
    cancelUpdateBasketOrderSource.cancel(REQUEST_CANCELLED)
}

const updateBasketOrder = async (
  orderId: string,
  updatedOrder: UpdateBasketOrderDto
): Promise<AxiosResponse<OrderDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelUpdateBasketOrderSource = axios.CancelToken.source()
  return call<OrderDto>(
    `api/institute/${instituteId}/basket/current/basketOrders/${orderId}/update`,
    'PUT',
    updatedOrder,
    cancelUpdateBasketOrderSource.token
  )
}

/**
 * Associate a hold document with a basket
 */
let cancelSetHoldDocumentToBasketItemSource: CancelTokenSource

const cancelSetHoldDocumentToBasketItem = () => {
  cancelSetHoldDocumentToBasketItemSource &&
    cancelSetHoldDocumentToBasketItemSource.cancel(REQUEST_CANCELLED)
}

const setHoldDocumentToBasketItem = async (
  basketItemDocument: BasketItemDocumentDto
): Promise<AxiosResponse<BasketItemDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelSetHoldDocumentToBasketItemSource = axios.CancelToken.source()
  return call<BasketItemDto>(
    `api/institute/${instituteId}/basket/current/items/${basketItemDocument.basketItemId}/documents`,
    'POST',
    { ...basketItemDocument },
    cancelSetHoldDocumentToBasketItemSource.token
  )
}

/**
 * Remove association for a hold document with a basket
 */

let cancelRemoveHoldDocumentToBasketItemSource: CancelTokenSource

const cancelRemoveHoldDocumentToBasketItem = () => {
  cancelRemoveHoldDocumentToBasketItemSource &&
    cancelRemoveHoldDocumentToBasketItemSource.cancel(REQUEST_CANCELLED)
}

const removeHoldDocumentToBasketItem = async (
  basketItemId: string,
  documentId: string
): Promise<AxiosResponse<BasketItemDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelRemoveHoldDocumentToBasketItemSource = axios.CancelToken.source()
  return call<BasketItemDto>(
    `api/institute/${instituteId}/basket/current/items/${basketItemId}/documents/${documentId}`,
    'DELETE',
    cancelRemoveHoldDocumentToBasketItemSource.token
  )
}

/**
 * Invoice request
 */
let cancelRequestOrderInvoiceSource: CancelTokenSource

const cancelRequestOrderInvoice = () => {
  cancelRequestOrderInvoiceSource &&
    cancelRequestOrderInvoiceSource.cancel(REQUEST_CANCELLED)
}

const requestOrderInvoice = async (orderId: string): Promise<AxiosResponse> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelRequestOrderInvoiceSource = axios.CancelToken.source()
  return call(
    `api/institute/${instituteId}/invoice/${orderId}`,
    'POST',
    null,
    cancelRequestOrderInvoiceSource.token
  )
}

let cancelDownloadDocumentByIdSource: CancelTokenSource

const cancelDownloadDocumentById = () => {
  cancelDownloadDocumentByIdSource &&
    cancelDownloadDocumentByIdSource.cancel(REQUEST_CANCELLED)
}

const downloadDocumentById = async (
  documentId: string
): Promise<AxiosResponse> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelDownloadDocumentByIdSource = axios.CancelToken.source()
  return call(
    `api/institute/${instituteId}/Documents/download/${documentId}`,
    'GET',
    null,
    cancelDownloadDocumentByIdSource.token,
    'blob'
  )
}

/*** Shortages */
const createShortageReport = async (
  data: CreateShortageRequestDto
): Promise<AxiosResponse<{ incidentNumber: string }>> => {
  const instituteId = await getLocallyStoredInstituteId()
  return call<{ incidentNumber: string }>(
    `api/institute/${instituteId}/shortage`,
    'POST',
    data
  )
}

const getShortages = async ({
  filter,
  pagination,
  sorting
}: Pick<ShortagesSearchDto, 'pagination' | 'sorting' | 'filter'>): Promise<
  AxiosResponse<ShortagesSearchResultDto>
> => {
  return call<ShortagesSearchResultDto>('api/Products/shortages', 'POST', {
    query: '*',
    filter,
    pagination,
    sorting
  } as ShortagesSearchDto)
}

/*** Sourcing enquiry */
const createSourcingRequest = async (
  data: CreateSourcingRequestDto
): Promise<AxiosResponse<{ incidentNumber: string }>> => {
  const instituteId = await getLocallyStoredInstituteId()
  return call<{ incidentNumber: string }>(
    `api/institute/${instituteId}/sourcing`,
    'POST',
    data
  )
}

/*** Document upload */
const uploadDocument = async (
  file: File
): Promise<AxiosResponse<UploadedDocumentDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  const formData = new FormData()
  formData.append('file', file)
  return call<UploadedDocumentDto>(
    `api/institute/${instituteId}/Documents/upload`,
    'POST',
    formData
  )
}

/**
 * Associate document to hold
 */
let cancelSetDocumentToHoldSource: CancelTokenSource

const cancelSetDocumentToHold = () => {
  cancelSetDocumentToHoldSource &&
    cancelSetDocumentToHoldSource.cancel(REQUEST_CANCELLED)
}

const setDocumentToHold = async (
  orderId: string,
  uploadedDocumentId: string,
  hold: HoldDto,
  documentType: string
): Promise<AxiosResponse> => {
  cancelSetDocumentToHoldSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return call<UploadedOrderHoldDocumentDto>(
    `api/institute/${instituteId}/Orders/${orderId}/holds/${hold.holdId}/documents`,
    'POST',
    {
      uploadedDocumentId,
      holdName: hold.holdName,
      documentType
    },
    cancelSetDocumentToHoldSource.token
  )
}
/**
 * Get contact details
 */

let cancelContactDetailsSource: CancelTokenSource

const cancelGetContactDetails = () => {
  cancelContactDetailsSource &&
    cancelContactDetailsSource.cancel(REQUEST_CANCELLED)
}

const getContactDetails = async (
  contactId: string
): Promise<AxiosResponse<InstituteContactDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelContactDetailsSource = axios.CancelToken.source()
  return call<InstituteContactDto>(
    `api/institute/${instituteId}/Contacts/${contactId}`,
    'GET',
    cancelContactDetailsSource.token
  )
}

/**
 * Contact search
 */

let cancelContactsSearchSource: CancelTokenSource

const cancelContactsSearch = () => {
  cancelSearchProductsSource &&
    cancelSearchProductsSource.cancel(REQUEST_CANCELLED)
}

const contactsSearch = async ({
  query,
  filter,
  pagination,
  sorting
}: ContactSearchDto): Promise<AxiosResponse<ContactSearchResultDto> | null> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelContactsSearchSource = axios.CancelToken.source()
  return instituteId
    ? call<ContactSearchResultDto>(
        `api/institute/${instituteId}/Contacts/search`,
        'POST',
        {
          query: query ?? '*',
          filter,
          pagination,
          sorting
        },
        cancelContactsSearchSource.token
      )
    : null
}

/**
 * Institute address edits
 */

const deleteShippingAddress = async (
  addressId: string
): Promise<AxiosResponse<IncidentResponseDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  return call(`api/institute/${instituteId}/address/${addressId}`, 'DELETE')
}

let cancelNewUnvalidatedShippingAddressSource: CancelTokenSource

const cancelNewUnvalidatedShippingAddress = () => {
  cancelNewShippingAddressSource &&
    cancelNewShippingAddressSource.cancel(REQUEST_CANCELLED)
}

const newUnvalidatedShippingAddress = async (
  address: CreateCdhShippingAddressDto
): Promise<AxiosResponse<OrgAddressDto> | null> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelNewUnvalidatedShippingAddressSource = axios.CancelToken.source()
  return instituteId
    ? call<OrgAddressDto>(
        `api/institute/${instituteId}/unvalidatedAddress`,
        'POST',
        address,
        cancelNewUnvalidatedShippingAddressSource.token
      )
    : null
}

let cancelNewShippingAddressSource: CancelTokenSource

const cancelNewShippingAddress = () => {
  cancelNewShippingAddressSource &&
    cancelNewShippingAddressSource.cancel(REQUEST_CANCELLED)
}

const newShippingAddress = async (
  address: CreateShippingAddressDto
): Promise<AxiosResponse<IncidentResponseDto> | null> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelNewShippingAddressSource = axios.CancelToken.source()
  return instituteId
    ? call<IncidentResponseDto>(
        `api/institute/${instituteId}/address`,
        'POST',
        address,
        cancelNewShippingAddressSource.token
      )
    : null
}

/**
 * Add contact
 */

let cancelAddContactSource: CancelTokenSource

const cancelAddContact = () => {
  cancelAddContactSource && cancelAddContactSource.cancel(REQUEST_CANCELLED)
}

const addContact = async (
  contact: CreateContactRequestDto
): Promise<AxiosResponse<ContactDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelAddContactSource = axios.CancelToken.source()
  return call<ContactDto>(
    `api/institute/${instituteId}/Contacts`,
    'POST',
    contact,
    cancelAddContactSource.token
  )
}

/**
 * Update contact
 */

let cancelUpdateContactSource: CancelTokenSource

const cancelUpdateContact = () => {
  cancelUpdateContactSource &&
    cancelUpdateContactSource.cancel(REQUEST_CANCELLED)
}

const updateContact = async (
  contactId: string,
  updatedContact: UpdateContactDto
): Promise<AxiosResponse<ContactDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelUpdateContactSource = axios.CancelToken.source()
  return call<ContactDto>(
    `api/institute/${instituteId}/Contacts/${contactId}`,
    'PUT',
    updatedContact,
    cancelUpdateContactSource.token
  )
}

/**
 * Shipping address
 */

const deleteContactFromInstitution = async (
  contactId: string
): Promise<AxiosResponse<DeleteContactResponseDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  return call(`api/institute/${instituteId}/Contacts/${contactId}`, 'DELETE')
}

/**
 * Query user login status
 */

let cancelQueryUserLoginStatusSource: CancelTokenSource

const cancelQueryUserLoginStatus = () => {
  cancelQueryUserLoginStatusSource &&
    cancelQueryUserLoginStatusSource.cancel(REQUEST_CANCELLED)
}

const queryUserLoginStatus = async (
  contactsEmail: UserEmailDto[]
): Promise<AxiosResponse<UserStatusDto[]>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelQueryUserLoginStatusSource = axios.CancelToken.source()
  return call<UserStatusDto[]>(
    `api/institute/${instituteId}/Contacts/status`,
    'POST',
    contactsEmail,
    cancelQueryUserLoginStatusSource.token
  )
}

/**
 * Activate user login on IdentityServer.
 */

let cancelActivateUserSource: CancelTokenSource

const cancelActivateUser = () => {
  cancelActivateUserSource && cancelActivateUserSource.cancel(REQUEST_CANCELLED)
}

const activateUser = async (
  contactsEmail: UserEmailDto
): Promise<AxiosResponse> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelActivateUserSource = axios.CancelToken.source()
  return call(
    `api/institute/${instituteId}/Contacts/activate`,
    'POST',
    contactsEmail,
    cancelActivateUserSource.token
  )
}

/**
 * Get specialisms
 */

let cancelGetSpecialismsSource: CancelTokenSource

const cancelGetSpecialisms = () => {
  cancelGetSpecialismsSource &&
    cancelGetSpecialismsSource.cancel(REQUEST_CANCELLED)
}

const getSpecialisms = async (): Promise<AxiosResponse<SpecialismsDto>> => {
  cancelGetSpecialismsSource = axios.CancelToken.source()
  return call<SpecialismsDto>(
    `api/Lists/specialisms`,
    'GET',
    null,
    cancelGetSpecialismsSource.token
  )
}

/**
 * Get Distributor information
 */

let cancelGetDistributorSource: CancelTokenSource

const cancelGetDistributor = () => {
  cancelGetDistributorSource &&
    cancelGetDistributorSource.cancel(REQUEST_CANCELLED)
}

const getDistributor = async (
  name: string
): Promise<AxiosResponse<DistributorDto>> => {
  cancelGetDistributorSource = axios.CancelToken.source()
  return call<DistributorDto>(
    `api/distributors/by_name?name=${encodeURIComponent(name)}`,
    'GET',
    cancelGetDistributorSource.token
  )
}

/**
 * Get OPA endpoint (Note this is outside our auth and just a direct call to a third party)
 */

let cancelGetOpaUrlSource: CancelTokenSource

const cancelGetOpaUrl = () => {
  cancelGetOpaUrlSource && cancelGetOpaUrlSource.cancel(REQUEST_CANCELLED)
}

const getOpaUrl = async (
  programId: string,
  physicianId: string,
  shippingAddressId: string,
  orderType?: string,
  patientNumber?: string,
  featureFlag?: boolean
): Promise<AxiosResponse> => {
  cancelGetOpaUrlSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return call<InitiateOPARequestDto>(
    `api/institute/${instituteId}/Programs/${programId}/physician/${physicianId}/getOPALink`,
    'POST',
    {
      addressId: shippingAddressId,
      maReference: ' ',
      orderType: orderType ?? '',
      patientNumber: patientNumber ?? '',
      featureFlag: featureFlag
    },
    cancelGetOpaUrlSource.token
  )
}

/**
 * Delete OPA endpoint
 */

let cancelDeleteOpaSource: CancelTokenSource

const cancelDeleteOpa = () => {
  cancelDeleteOpaSource && cancelDeleteOpaSource.cancel(REQUEST_CANCELLED)
}

const deleteOpa = async (
  programId: string,
  physicianId: string,
  shippingAddressId: string,
  patientNumber?: string
): Promise<AxiosResponse> => {
  cancelDeleteOpaSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return call<DeleteOPARequestDto>(
    `api/institute/${instituteId}/Programs/${programId}/physician/${physicianId}/deleteOPA`,
    'PUT',
    {
      addressId: shippingAddressId,
      patientNumber: patientNumber ?? ''
    },
    cancelDeleteOpaSource.token
  )
}

/**
 * Get Readonly OPA Link
 */

let cancelGetReadOnlyOpaUrlSource: CancelTokenSource

const cancelGetReadOnlyOpaUrl = () => {
  cancelGetReadOnlyOpaUrlSource &&
    cancelGetReadOnlyOpaUrlSource.cancel(REQUEST_CANCELLED)
}

const getReadOnlyOpaUrl = async (
  orderNumber: number,
  { opaAccessToken }: InitReadOnlyOPARequestDto
): Promise<AxiosResponse<InitReadOnlyOPAResponseDto>> => {
  cancelGetReadOnlyOpaUrlSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return call<InitReadOnlyOPAResponseDto>(
    `api/institute/${instituteId}/Orders/${orderNumber}/getReadOnlyOPALink`,
    'POST',
    {
      opaAccessToken
    },
    cancelGetReadOnlyOpaUrlSource.token
  )
}

/**
 * Get physician by physicianId
 */

let cancelGetPhysicianSourceById: CancelTokenSource

const cancelGetPhysicianById = () => {
  cancelGetPhysicianSourceById &&
    cancelGetPhysicianSourceById.cancel(REQUEST_CANCELLED)
}

const getPhysicianById = async (
  id: string,
  { sortProgram }: PhysicianDetailRequestDto
): Promise<AxiosResponse<PhysicianDetailsDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelGetPhysicianSourceById = axios.CancelToken.source()
  return call<PhysicianDetailsDto>(
    `api/institute/${instituteId}/Physicians/${id}`,
    'POST',
    {
      sortProgram
    },
    cancelGetPhysicianSourceById.token
  )
}

/**
 * Get physicians
 */

let cancelGetPhysiciansSource: CancelTokenSource

const cancelGetPhysicians = () => {
  cancelGetPhysiciansSource &&
    cancelGetPhysiciansSource.cancel(REQUEST_CANCELLED)
}

const getPhysicians = async ({
  query,
  filter,
  pagination,
  sorting
}: PhysiciansSummarySearchDto): Promise<AxiosResponse<PhysiciansSummarySearchQueryResultDto> | null> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelGetPhysiciansSource = axios.CancelToken.source()
  return instituteId
    ? call<PhysiciansSummarySearchQueryResultDto>(
        `api/institute/${instituteId}/Physicians/search`,
        'POST',
        {
          query,
          filter,
          pagination,
          sorting
        },
        cancelGetPhysiciansSource.token
      )
    : null
}

/**
 * Create Patient
 */
let cancelCreatePatientSource: CancelTokenSource

const cancelCreatePatient = () => {
  cancelCreatePatientSource &&
    cancelCreatePatientSource.cancel(REQUEST_CANCELLED)
}

const createPatient = async (
  physicianId: string,
  programId: number,
  patient: CreatePatientRequestDTO
): Promise<AxiosResponse<CreatePatientResponseDTO>> => {
  cancelCreatePatientSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()

  return call<CreatePatientResponseDTO>(
    `api/institute/${instituteId}/physician/${physicianId}/programs/${programId}/patients/createPatient`,
    'POST',
    patient,
    cancelCreatePatientSource.token
  )
}

/**
 * Update Patient
 */
let cancelUpdatePatientSource: CancelTokenSource

const cancelUpdatePatient = () => {
  cancelCreatePatientSource &&
    cancelCreatePatientSource.cancel(REQUEST_CANCELLED)
}

const updatePatient = async (
  physicianId: string,
  patientId: number,
  patient: UpdatePatientRequestDto
): Promise<AxiosResponse<CreatePatientResponseDTO>> => {
  cancelUpdatePatientSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()

  return call<UpdatePatientResponseDto>(
    `api/institute/${instituteId}/physician/${physicianId}/patients/${patientId}/updatePatient`,
    'PUT',
    patient,
    cancelUpdatePatientSource.token
  )
}

/**
 * Get Patient details
 */

let cancelGetPatientDetailSource: CancelTokenSource

const cancelGetPatientDetail = () => {
  cancelGetPatientDetailSource &&
    cancelGetPatientDetailSource.cancel(REQUEST_CANCELLED)
}

const getPatientDetail = async (
  patientId: string,
  physicianId: string
): Promise<AxiosResponse<PatientDetailsDto>> => {
  cancelGetPatientDetailSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()

  return call<PatientDetailsDto>(
    `api/institute/${instituteId}/physician/${physicianId}/Patients/${patientId}/details`,
    'GET',
    cancelGetPatientDetailSource.token
  )
}

/**
 * Delete Patient
 */

let cancelDeletePatientSource: CancelTokenSource

const cancelDeletePatient = () => {
  cancelDeletePatientSource &&
    cancelDeletePatientSource.cancel(REQUEST_CANCELLED)
}

const deletePatient = async (
  patientId: string,
  physicianId: string
): Promise<AxiosResponse<PatientDetailsDto>> => {
  cancelDeletePatientSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()

  return call<PatientDetailsDto>(
    `api/institute/${instituteId}/physician/${physicianId}/patients/${patientId}/deletePatient`,
    'DELETE',
    cancelDeletePatientSource.token
  )
}

/**
 * Get Patient default details
 */

let cancelGetPatientDefaultDetailSource: CancelTokenSource

const cancelGetPatientDefaultDetail = () => {
  cancelGetPatientDefaultDetailSource &&
    cancelGetPatientDefaultDetailSource.cancel(REQUEST_CANCELLED)
}

const getPatientDefaultDetail = async (
  patientNumber: string,
  physicianId: string
): Promise<AxiosResponse<PatientDefaultDto>> => {
  cancelGetPatientDefaultDetailSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()

  return call<PatientDefaultDto>(
    `api/institute/${instituteId}/physician/${physicianId}/Patients/${patientNumber}/defaultInformation`,
    'GET',
    cancelGetPatientDefaultDetailSource.token
  )
}

/**
 * Update Patient default details
 */

let cancelUpdatePatientDefaultDetailSource: CancelTokenSource

const cancelUpdatePatientDefaultDetail = () => {
  cancelUpdatePatientDefaultDetailSource &&
    cancelUpdatePatientDefaultDetailSource.cancel(REQUEST_CANCELLED)
}

const updatePatientDefaultDetail = async (
  physicianId: string,
  patientNumber: string,
  patientDefautDetails: PatientDefaultDto
): Promise<AxiosResponse> => {
  cancelUpdatePatientDefaultDetailSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return call(
    `api/institute/${instituteId}/physician/${physicianId}/Patients/${patientNumber}/defaultInformation`,
    'PUT',
    patientDefautDetails,
    cancelUpdatePatientDefaultDetailSource.token
  )
}

/**
 * Reconcile vials for a patient
 */

let cancelReconcileVialForPatientSource: CancelTokenSource

const cancelReconcileVialForPatient = () => {
  cancelReconcileVialForPatientSource &&
    cancelReconcileVialForPatientSource.cancel(REQUEST_CANCELLED)
}

const reconcileVialForPatient = async (
  physicianId: string,
  patientId: string,
  vialRequest: ReconcileVialRequestDto
): Promise<AxiosResponse<ReconcileVialRequestDto>> => {
  cancelReconcileVialForPatientSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return call<ReconcileVialRequestDto>(
    `api/institute/${instituteId}/physician/${physicianId}/patients/${patientId}/reconcileVial`,
    'PUT',
    vialRequest,
    cancelReconcileVialForPatientSource.token
  )
}

/**
 * Get Enrolled Physicians on programPatient details
 */

let cancelGetEnrolledPhysiciansSource: CancelTokenSource

const cancelGetEnrolledPhysicians = () => {
  cancelGetEnrolledPhysiciansSource &&
    cancelGetEnrolledPhysiciansSource.cancel(REQUEST_CANCELLED)
}

const getEnrolledPhysicians = async (
  programId: string
): Promise<AxiosResponse<GetPhysiciansEnrolledQueryResultDto>> => {
  cancelGetEnrolledPhysiciansSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return call<GetPhysiciansEnrolledQueryResultDto>(
    `api/institute/${instituteId}/program/${programId}/ProgramPhysicians/enrolled`,
    'GET',
    cancelGetEnrolledPhysiciansSource.token
  )
}

let cancelGetNotEnrolledPhysiciansSource: CancelTokenSource

const cancelGetNotEnrolledPhysicians = () => {
  cancelGetNotEnrolledPhysiciansSource?.token &&
    cancelGetNotEnrolledPhysiciansSource.cancel(REQUEST_CANCELLED)
}

const getNotEnrolledPhysicians = async (
  programId: string,
  query: string,
  skip: number,
  take: number
): Promise<AxiosResponse<PhysiciansSummarySearchQueryResultDto>> => {
  cancelGetNotEnrolledPhysiciansSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()

  return call<PhysiciansSummarySearchQueryResultDto>(
    `api/institute/${instituteId}/program/${programId}/ProgramPhysicians/notEnrolled?offset=${skip}&limit=${take}`,
    'POST',
    {
      query,
      pagination: {
        skip,
        take
      }
    },
    cancelGetNotEnrolledPhysiciansSource.token
  )
}

export { getNotEnrolledPhysicians, cancelGetNotEnrolledPhysicians }

/**
 * Disassociate physician from patient
 */

let cancelDisassociatePhysicianSource: CancelTokenSource

const cancelDisassociatePhysician = () => {
  cancelGetEnrolledPhysiciansSource &&
    cancelGetEnrolledPhysiciansSource.cancel(REQUEST_CANCELLED)
}

const disassociatePhysician = async (
  physicianId: string
): Promise<AxiosResponse<PhysiciansEnrolledSummarySearchQueryResultDto>> => {
  cancelDisassociatePhysicianSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return call<PhysiciansEnrolledSummarySearchQueryResultDto>(
    `api/institute/${instituteId}/Physicians/${physicianId}/disassociate`,
    'DELETE',
    cancelDisassociatePhysicianSource.token
  )
}

/**
 * Associate physician
 */

let cancelAssociatePhysicianSource: CancelTokenSource

const cancelAssociatePhysician = () => {
  cancelGetEnrolledPhysiciansSource &&
    cancelGetEnrolledPhysiciansSource.cancel(REQUEST_CANCELLED)
}

const associatePhysician = async (
  physicianId: string
): Promise<AxiosResponse<PhysiciansEnrolledSummarySearchQueryResultDto>> => {
  cancelAssociatePhysicianSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return call<PhysiciansEnrolledSummarySearchQueryResultDto>(
    `api/institute/${instituteId}/Physicians/${physicianId}/associate`,
    'PUT',
    cancelAssociatePhysicianSource.token
  )
}

/**
 * Transfers the Patient to a new Physician
 */
const transferPatientPhysician = async (
  physicianId: string,
  patientId: string,
  selectedPhysician: PhysiciansEnrolledSummaryDto
): Promise<AxiosResponse> => {
  const instituteId = await getLocallyStoredInstituteId()
  return call(
    `api/institute/${instituteId}/physician/${physicianId}/Patients/${patientId}/transferToPhysician`,
    'PUT',
    {
      newPhysicianId: selectedPhysician.physicianId,
      reasonForTransfer: ''
    }
  )
}

/**
 * Transfer patient to another institute flow
 */

export interface ITransferPatientInstituteDto
  extends NewPhysicianDetilsDto,
    NewInstituteDetilsDto {}

let cancelTransferPatientInstituteSource: CancelTokenSource

const cancelTransferPatientInstitute = () => {
  cancelTransferPatientInstituteSource &&
    cancelTransferPatientInstituteSource.cancel(REQUEST_CANCELLED)
}

const transferPatientInstitute = async (
  physicianId: string,
  patientId: string,
  transferPatientData: ITransferPatientInstituteDto
): Promise<AxiosResponse<BasketItemDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelTransferPatientInstituteSource = axios.CancelToken.source()
  return call<BasketItemDto>(
    `api/institute/${instituteId}/physician/${physicianId}/Patients/${patientId}/institutetransfer/transfer`,
    'POST',
    { ...transferPatientData },
    cancelTransferPatientInstituteSource.token
  )
}

/**
 * Get program detail
 */

let cancelGetProgramFromSearchIndexByIdSource: CancelTokenSource

const cancelGetProgramFromSearchIndexById = () => {
  cancelGetProgramFromSearchIndexByIdSource &&
    cancelGetProgramFromSearchIndexByIdSource.cancel(REQUEST_CANCELLED)
}

const getProgramFromSearchIndexById = async (
  programId: string
): Promise<AxiosResponse<ProgramCatalogDto>> => {
  cancelGetProgramFromSearchIndexByIdSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return call<ProgramCatalogDto>(
    `api/institute/${instituteId}/Programs/${programId}`,
    'GET',
    cancelGetProgramFromSearchIndexByIdSource.token
  )
}

/**
 * Get program detail
 */

let cancelProgramsEnrolledSource: CancelTokenSource

const cancelProgramsEnrolled = () => {
  cancelProgramsEnrolledSource &&
    cancelProgramsEnrolledSource.cancel(REQUEST_CANCELLED)
}

const programsEnrolled = async ({
  query,
  filter,
  pagination,
  sorting
}: ProgramSearchDto): Promise<AxiosResponse<ProgramSearchResultDto> | null> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelProgramsEnrolledSource = axios.CancelToken.source()
  return instituteId
    ? call<ProgramSearchResultDto>(
        `api/institute/${instituteId}/Programs/enrolled`,
        'POST',
        {
          query,
          filter,
          pagination,
          sorting
        },
        cancelProgramsEnrolledSource.token
      )
    : null
}

/**
 * Register interest for a program
 */

let cancelPostRegisterInterestProgramSource: CancelTokenSource

const cancelPostRegisterInterestProgram = () => {
  cancelPostRegisterInterestProgramSource &&
    cancelPostRegisterInterestProgramSource.cancel(REQUEST_CANCELLED)
}

const registerInterestProgram = async (
  programId: string,
  { reason, contactByEmail, contactByMail, contactByPhone }: RegisterInterestDto
): Promise<AxiosResponse<RegisterInterestResultDto> | null> => {
  cancelPostRegisterInterestProgramSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return call<RegisterInterestResultDto>(
    `api/institute/${instituteId}/programs/${programId}/registerInterestInProgram`,
    'POST',
    {
      reason,
      contactByEmail,
      contactByMail,
      contactByPhone
    },
    cancelPostRegisterInterestProgramSource.token
  )
}

/**
 * Get patients that are associated with the physician
 */

let cancelGetPatientsSource: CancelTokenSource

const cancelGetPatients = () => {
  cancelGetPatientsSource && cancelGetPatientsSource.cancel(REQUEST_CANCELLED)
}

const getPatients = async ({
  query,
  filter,
  pagination,
  sorting
}: PatientSummarySearchDto): Promise<AxiosResponse<PatientSummarySearchResultDto> | null> => {
  cancelGetPatientsSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()

  return instituteId
    ? call<PatientSummarySearchResultDto>(
        `api/institute/${instituteId}/Patients/summary`,
        'POST',
        {
          query: query,
          filter,
          pagination,
          sorting
        },
        cancelGetPatientsSource.token
      )
    : null
}
let cancelGetPhysicianPatientsSource: CancelTokenSource

const cancelGetPhysicanPatients = () => {
  cancelGetPhysicianPatientsSource &&
    cancelGetPhysicianPatientsSource.cancel(REQUEST_CANCELLED)
}

const getPhysicianPatients = async (
  physicianId: number,
  {
    query,
    filter,
    pagination,
    sorting
  }: PatientSummaryAssociatedToPhysiciansSearchDto
): Promise<AxiosResponse<PatientSummaryAssociatedToPhysiciansSearchResultDto> | null> => {
  cancelGetPhysicianPatientsSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return instituteId
    ? call<PatientSummaryAssociatedToPhysiciansSearchResultDto>(
        `api/institute/${instituteId}/physician/${physicianId}/Patients/summary`,
        'POST',
        {
          query: query,
          filter,
          pagination,
          sorting
        },
        cancelGetPhysicianPatientsSource.token
      )
    : null
}

export const getPhysicianPatientsForOpaForm = async (
  physicianId: number,
  {
    query,
    filter,
    pagination,
    sorting
  }: PatientSummaryAssociatedToPhysiciansSearchDtoForOpaForm
): Promise<AxiosResponse<PatientSummaryAssociatedToPhysiciansSearchResultDto> | null> => {
  cancelGetPhysicianPatientsSource = axios.CancelToken.source()

  const instituteId = await getLocallyStoredInstituteId()

  if (!instituteId) return null

  const payload = {
    query,
    filter: {
      ...filter,
      physicianId
    },
    pagination: {
      skip: pagination?.skip ?? 0,
      take: pagination?.take ?? 10
    },
    sorting: {
      sortBy: sorting?.sortBy ?? 'lastUpdateDate',
      order: sorting?.order ?? 'ASC'
    }
  }

  return call<PatientSummaryAssociatedToPhysiciansSearchResultDto>(
    `api/institute/${instituteId}/Patients/summary`,
    'POST',
    payload,
    cancelGetPhysicianPatientsSource.token
  )
}

export const getPhysicianPatientsByIds = async (
  physicianId: number,
  instituteId: number
): Promise<number[] | null> => {
  if (!instituteId) return null

  const cancelSource = axios.CancelToken.source()

  try {
    const response: AxiosResponse<PatientSummaryAssociatedToPhysiciansSearchResultDto> | null =
      await call<PatientSummaryAssociatedToPhysiciansSearchResultDto>(
        `api/institute/${instituteId}/physician/${physicianId}/Patients/summary`,
        'POST',
        {
          query: {},
          filter: {},
          pagination: {},
          sorting: {}
        },
        cancelSource.token
      )

    return response?.data?.result?.map((item) => item.patientId) || null
  } catch (error) {
    console.error(error)
    return null
  }
}

/**
 * Get filter of patients that are associated with the physician
 */
let cancelGetFacetsForPatientsSource: CancelTokenSource

const cancelGetFacetsForPatients = () => {
  cancelGetFacetsForPatientsSource &&
    cancelGetFacetsForPatientsSource.cancel(REQUEST_CANCELLED)
}

// We don't really search for patients here, we just want the list of tags that's returned from the search request
const getFacetsForPatients = async (
  physicianId: number
): Promise<AxiosResponse<PatientSummarySearchResultDto> | null> => {
  cancelGetFacetsForPatientsSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()

  return instituteId
    ? call<PatientSummarySearchResultDto>(
        `api/institute/${instituteId}/physician/${physicianId}/Patients/summary`,
        'POST',
        {
          query: '',
          filter: {
            programs: [],
            patientStatuses: []
          },
          pagination: {
            skip: 0,
            take: 10
          },
          sorting: {
            sortBy: 'Status',
            order: SortDirectionType.Ascending.toUpperCase()
          }
        },
        cancelGetFacetsForPatientsSource.token
      )
    : null
}

/**
 * Discontinue physician from institute
 */

let cancelDiscontinuePhysicianSource: CancelTokenSource

const cancelDiscontinuePhysician = () => {
  cancelDiscontinuePhysicianSource &&
    cancelDiscontinuePhysicianSource.cancel(REQUEST_CANCELLED)
}

const discontinuePhysician = async (
  physicianId: string
): Promise<AxiosResponse> => {
  cancelDiscontinuePhysicianSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return call(
    `api/institute/${instituteId}/Physicians/${physicianId}`,
    'DELETE',
    cancelDiscontinuePhysicianSource.token
  )
}

/**
 * Register interest for a product
 */

let cancelRegisterInterestSource: CancelTokenSource

const cancelRegisterInterest = () => {
  cancelRegisterInterestSource &&
    cancelRegisterInterestSource.cancel(REQUEST_CANCELLED)
}

const registerInterest = async (
  product: RegisterInterestInProductDto
): Promise<AxiosResponse<IncidentResponseDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelRegisterInterestSource = axios.CancelToken.source()
  return call(
    `api/institute/${instituteId}/products/registerInterest`,
    'POST',
    product,
    cancelRegisterInterestSource.token
  )
}

/**
 * Submit a skinny sourcing request
 */

let cancelSkinnySourcingSource: CancelTokenSource

const cancelSkinnySourcing = () => {
  cancelSkinnySourcingSource &&
    cancelSkinnySourcingSource.cancel(REQUEST_CANCELLED)
}

const skinnySourcing = async (
  skinnySourcing: SkinnySourcingDto
): Promise<AxiosResponse<IncidentResponseDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelSkinnySourcingSource = axios.CancelToken.source()
  return call(
    `api/institute/${instituteId}/products/skinnySourcing`,
    'POST',
    skinnySourcing,
    cancelSkinnySourcingSource.token
  )
}

/**
 * Institute registration
 */

let cancelCreateInstituteSource: CancelTokenSource

const cancelCreateInstitute = () => {
  cancelCreateInstituteSource &&
    cancelCreateInstituteSource.cancel(REQUEST_CANCELLED)
}

const createInstitute = async (
  contact: NewInstituteRequestDto
): Promise<AxiosResponse<ContactDto>> => {
  cancelCreateInstituteSource = axios.CancelToken.source()
  return call(
    'api/User/createInstitute',
    'POST',
    contact,
    cancelCreateInstituteSource.token
  )
}

/**
 * Marketing preferences
 */

let cancelGetMarketPreferencesStatusSource: CancelTokenSource

const cancelGetMarketPreferencesStatus = () => {
  cancelGetMarketPreferencesStatusSource &&
    cancelGetMarketPreferencesStatusSource.cancel(REQUEST_CANCELLED)
}

const getMarketPreferencesStatus = async (): Promise<
  AxiosResponse<MarketingPreferencesDto>
> => {
  cancelGetMarketPreferencesStatusSource = axios.CancelToken.source()
  return call(
    'api/MarketingPreferences/status',
    'GET',
    null,
    cancelGetMarketPreferencesStatusSource.token
  )
}

let cancelGetMarketPreferencesSource: CancelTokenSource

const cancelGetMarketPreferences = () => {
  cancelGetMarketPreferencesSource &&
    cancelGetMarketPreferencesSource.cancel(REQUEST_CANCELLED)
}

const getMarketPreferences = async (): Promise<
  AxiosResponse<MarketingPreferencesDto>
> => {
  cancelGetMarketPreferencesSource = axios.CancelToken.source()
  return call(
    'api/MarketingPreferences',
    'GET',
    null,
    cancelGetMarketPreferencesSource.token
  )
}

let cancelUpdateMarketPreferencesSource: CancelTokenSource

const cancelUpdateMarketPreferences = () => {
  cancelUpdateMarketPreferencesSource &&
    cancelUpdateMarketPreferencesSource.cancel(REQUEST_CANCELLED)
}

const updateMarketPreferences = async (
  preferences: MarketingPreferenceDto[]
): Promise<AxiosResponse> => {
  cancelUpdateMarketPreferencesSource = axios.CancelToken.source()
  return call(
    'api/MarketingPreferences',
    'PUT',
    preferences,
    cancelUpdateMarketPreferencesSource.token
  )
}

/**
 * Get Warehouse information
 */

let cancelGetWarehouseSource: CancelTokenSource

const cancelGetWarehouseForCode = () => {
  cancelGetWarehouseSource && cancelGetWarehouseSource.cancel(REQUEST_CANCELLED)
}

const getWarehouseForCode = async (
  warehouseCode: string
): Promise<AxiosResponse<WarehouseDto>> => {
  cancelGetWarehouseSource = axios.CancelToken.source()
  return call(
    `api/warehouses?code=${encodeURIComponent(warehouseCode)}`,
    'GET',
    cancelGetWarehouseSource.token
  )
}

/**
 * Get Warehouse details for multiple warehouse codes
 */

let cancelGetWarehouseForCodesSource: CancelTokenSource

const cancelGetWarehouseForCodes = () => {
  cancelGetWarehouseForCodesSource &&
    cancelGetWarehouseForCodesSource.cancel(REQUEST_CANCELLED)
}

const getWarehouseForCodes = async (
  warehouseCodes: string[]
): Promise<AxiosResponse<WarehouseDto[]>> => {
  cancelGetWarehouseForCodesSource = axios.CancelToken.source()
  return call(
    `api/warehouses`,
    'POST',
    {
      warehousesCodes: warehouseCodes
    },
    cancelGetWarehouseForCodesSource.token
  )
}

/**
 * Get Clinigen support contact information for a specific country
 */

let cancelGetSupportContactSource: CancelTokenSource

const cancelGetSupportContact = () => {
  cancelGetSupportContactSource &&
    cancelGetSupportContactSource.cancel(REQUEST_CANCELLED)
}

const getSupportContact = async (
  countryCode: string
): Promise<AxiosResponse<CountryDto>> => {
  cancelGetSupportContactSource = axios.CancelToken.source()
  return call(
    `api/Countries/${countryCode}`,
    'GET',
    cancelGetSupportContactSource.token
  )
}

/*
 * Discontinue patient
 */

let cancelDiscontinuePatientSource: CancelTokenSource

const cancelDiscontinuePatient = () => {
  cancelDiscontinuePatientSource &&
    cancelDiscontinuePatientSource.cancel(REQUEST_CANCELLED)
}

const discontinuePatient = async (
  physicianId: string,
  patientId: string,
  reasonCode: DiscontinuePatientRequestDto
): Promise<AxiosResponse> => {
  cancelDiscontinuePatientSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return call(
    `api/institute/${instituteId}/physician/${physicianId}/Patients/${patientId}/discontinue`,
    'PUT',
    reasonCode,
    cancelDiscontinuePatientSource.token
  )
}

// Physician search ------------------------------------------------------------------

let cancelPhysicianSearchSource: CancelTokenSource

const cancelGetPhysicianSearch = () => {
  cancelPhysicianSearchSource?.token &&
    cancelPhysicianSearchSource.cancel(REQUEST_CANCELLED)
}

const getPhysicianSearch = async ({
  query
}: PhysiciansSummarySearchDto): Promise<
  AxiosResponse<PhysiciansSummarySearchQueryResultDto>
> => {
  cancelPhysicianSearchSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()

  return call<PhysiciansSummarySearchQueryResultDto>(
    `api/institute/${instituteId}/Physicians/search`,
    'POST',
    {
      query
    },
    cancelPhysicianSearchSource.token
  )
}

// Enrols Physicians onto a Program ------------------------------------------------------------------

let cancelEnrolPhysiciansOnToProgramSource: CancelTokenSource

const cancelGetenrolPhysiciansOnToProgram = () => {
  cancelEnrolPhysiciansOnToProgramSource?.token &&
    cancelEnrolPhysiciansOnToProgramSource.cancel(REQUEST_CANCELLED)
}

const enrolPhysiciansOnToProgram = async (
  programId: string,
  physicians: EnrollingPhsiciansDto
): Promise<AxiosResponse<IncidentResponseDto>> => {
  cancelEnrolPhysiciansOnToProgramSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return call<IncidentResponseDto>(
    `api/institute/${instituteId}/Programs/${programId}/enrollphysicians`,
    'POST',
    physicians,
    cancelEnrolPhysiciansOnToProgramSource.token
  )
}

// Search Program documents ------------------------------------------------------------------

let cancelProgramDocumentSearchSource: CancelTokenSource

const cancelProgramDocumentSearch = () => {
  cancelProgramDocumentSearchSource?.token &&
    cancelProgramDocumentSearchSource.cancel(REQUEST_CANCELLED)
}

const programDocumentSearch = async (
  query: ProgramDocumentsSearchDto
): Promise<AxiosResponse<ProgramDocumentsSearchResultDto>> => {
  cancelProgramDocumentSearchSource = axios.CancelToken.source()
  const instituteId = await getLocallyStoredInstituteId()
  return call<ProgramDocumentsSearchResultDto>(
    `api/institute/${instituteId}/Documents/search`,
    'POST',
    query,
    cancelProgramDocumentSearchSource.token
  )
}

// Program document facets ------------------------------------------------------------------

let cancelGetProgramDocumentSearchFacetsSource: CancelTokenSource

const cancelGetProgramDocumentSearchFacets = () => {
  cancelGetProgramDocumentSearchFacetsSource &&
    cancelGetProgramDocumentSearchFacetsSource.cancel(REQUEST_CANCELLED)
}

const getProgramDocumentSearchFacets = async (
  programId: number,
  countryCode: string
): Promise<AxiosResponse<ProgramDocumentsSearchResultDto>> => {
  const instituteId = await getLocallyStoredInstituteId()
  cancelGetProgramDocumentSearchFacetsSource = axios.CancelToken.source()
  return call<ProgramDocumentsSearchResultDto>(
    `api/institute/${instituteId}/Documents/search`,
    'POST',
    {
      query: '',
      filter: {
        programId,
        tags: [],
        availabilities: [],
        countryCode
      },
      pagination: {
        skip: 0,
        take: 0
      },
      sorting: {
        sortBy: 'documentTypeName',
        order: SortDirectionType.Descending
      }
    },
    cancelGetProgramDocumentSearchFacetsSource.token
  )
}

// Dashboard Banner (from SquidEx) ------------------------------------------------------------------

export interface SquidExValue {
  created: string
  createdBy: string
  data: { banner: IIndexable<IBanner> }
  editToken: string
  id: string
  isDeleted: boolean
  lastModified: string
  lastModifiedBy: string
  schemaDisplayName: string
  schemaName: string
  status: string
  statusColor: string
  version: number
}

export interface SquidExContent {
  items: SquidExValue[]
  statuses: { status: string }[]
  total: number
}

let cancelGetBannerSource: CancelTokenSource

const cancelGetBanner = () => {
  cancelGetBannerSource && cancelGetBannerSource.cancel(REQUEST_CANCELLED)
}

const getBanner = async (): Promise<AxiosResponse<SquidExContent>> => {
  cancelGetBannerSource = axios.CancelToken.source()
  return axios({
    method: 'get',
    url: `${config.localisationRoot}banner`,
    cancelToken: cancelGetBannerSource.token
  })
}

// Countries ------------------------------------------------------------------

let cancelGetCountriesSource: CancelTokenSource

const cancelGetCountries = () => {
  cancelGetCountriesSource?.token &&
    cancelGetCountriesSource.cancel(REQUEST_CANCELLED)
}

const getCountries = async (): Promise<AxiosResponse<CountryDto[]>> => {
  cancelGetCountriesSource = axios.CancelToken.source()
  return call<CountryDto[]>(
    `api/Countries`,
    'GET',
    null,
    cancelGetCountriesSource.token
  )
}

// LOV values ------------------------------------------------------------------

let cancelgetLovSource: CancelTokenSource

const cancelGetLov = () => {
  cancelgetLovSource?.token && cancelgetLovSource.cancel(REQUEST_CANCELLED)
}

const getLov = async (lovType: LovName): Promise<AxiosResponse> => {
  cancelgetLovSource = axios.CancelToken.source()
  return call<string[]>(
    `api/lovs/${encodeURIComponent(lovType)}`,
    'GET',
    null,
    cancelgetLovSource.token
  )
}

// New features -----------------------------------------------------------------

let cancelGetNewFeautres: CancelTokenSource
const getUserNewFeatures = async (): Promise<AxiosResponse<TourViewDto[]>> => {
  cancelGetNewFeautres = axios.CancelToken.source()
  return call<TourViewDto[]>(
    `api/onboarding/tourviews`,
    'GET',
    null,
    cancelGetNewFeautres.token
  )
}

const setNewFeatureToViewed = async (
  element: string,
  version: string,
  isCancelled?: boolean
): Promise<AxiosResponse> => {
  let payload = [{ element, version, isCancelled }]
  return call<AxiosResponse>(`api/onboarding/tourviews`, 'PUT', payload)
}

export {
  getCurrentUser,
  cancelGetCurrentUser,
  getOrders,
  cancelGetOrders,
  getOrderById,
  cancelGetOrderById,
  updatePassword,
  requestRepeatOrder,
  cancelRepeatOrder,
  getProducts,
  cancelGetProducts,
  getProductById,
  cancelGetProductById,
  getProductBySku,
  cancelGetProductBySku,
  getSearchFacetsForCountry,
  cancelGetTherapeuticAreas,
  getProductsSuggestion,
  cancelGetProductsSuggestion,
  getBookmarks,
  cancelGetBookmarks,
  createBookmark,
  getItemBookmarkStatus,
  getCancelGetLastItemBookmarkStatus,
  deleteBookmark,
  cancelDeleteBookmark,
  cancelCreateBookmark,
  touchSkuForRecentlyViewed,
  getRecentlyViewedSkus,
  cancelGetRecentlyViewedSkus,
  getUserInstitutes,
  cancelGetUserInstitutes,
  getPriceAndAvailabilityForSku,
  cancelGetPriceAndAvailabilityForSku,
  getCurrentBasket,
  cancelGetCurrentBasket,
  upsertSkuItemToBasket,
  cancelUpsertSkuItemToBasket,
  emptyCurrentBasket,
  removeItemFromBasket,
  cancelRemoveItemFromBasket,
  updateBasketItemQuantity,
  cancelUpdateBasketItemQuantity,
  getInstituteForId,
  cancelGetInstituteForId,
  placeDraftOrder,
  cancelDraftOrder,
  submitOrder,
  getCurrentBasketSummary,
  cancelGetCurrentBasketSummary,
  updateOrder,
  cancelUpdateOrder,
  updateBasketOrder,
  cancelUpdateBasketOrder,
  removeOrderLine,
  cancelRemoveOrderLine,
  requestOrderInvoice,
  cancelRequestOrderInvoice,
  downloadDocumentById,
  cancelDownloadDocumentById,
  createShortageReport,
  getShortages,
  createSourcingRequest,
  uploadDocument,
  setDocumentToHold,
  cancelSetDocumentToHold,
  setHoldDocumentToBasketItem,
  cancelSetHoldDocumentToBasketItem,
  removeHoldDocumentToBasketItem,
  cancelRemoveHoldDocumentToBasketItem,
  getContactDetails,
  cancelGetContactDetails,
  contactsSearch,
  cancelContactsSearch,
  newShippingAddress,
  cancelNewShippingAddress,
  newUnvalidatedShippingAddress,
  cancelNewUnvalidatedShippingAddress,
  addContact,
  cancelAddContact,
  deleteShippingAddress,
  updateContact,
  cancelUpdateContact,
  deleteContactFromInstitution,
  getSpecialisms,
  cancelGetSpecialisms,
  updateBasketDetails,
  getDistributor,
  cancelGetDistributor,
  queryUserLoginStatus,
  cancelQueryUserLoginStatus,
  activateUser,
  cancelActivateUser,
  cancelGetPhysicianById,
  getPhysicianById,
  cancelGetPhysicians,
  getPhysicians,
  getPatientDetail,
  cancelGetPatientDetail,
  getPatientDefaultDetail,
  cancelGetPatientDefaultDetail,
  updatePatientDefaultDetail,
  cancelUpdatePatientDefaultDetail,
  getEnrolledPhysicians,
  cancelGetEnrolledPhysicians,
  associatePhysician,
  cancelAssociatePhysician,
  disassociatePhysician,
  cancelDisassociatePhysician,
  getProgramFromSearchIndexById,
  cancelGetProgramFromSearchIndexById,
  getPatients,
  getPhysicianPatients,
  cancelGetPhysicanPatients,
  cancelGetPatients,
  getEnrolledPrograms,
  cancelGetEnrolledPrograms,
  getAllPrograms,
  cancelGetAllPrograms,
  registerInterestProgram,
  cancelPostRegisterInterestProgram,
  programsEnrolled,
  cancelProgramsEnrolled,
  getOpaUrl,
  deleteOpa,
  cancelDeleteOpa,
  cancelGetOpaUrl,
  cancelGetFacetsForPatients,
  getFacetsForPatients,
  reconcileVialForPatient,
  cancelReconcileVialForPatient,
  transferPatientPhysician,
  discontinuePhysician,
  cancelDiscontinuePhysician,
  transferPatientInstitute,
  cancelTransferPatientInstitute,
  registerInterest,
  skinnySourcing,
  cancelSkinnySourcing,
  cancelRegisterInterest,
  createInstitute,
  cancelCreateInstitute,
  getMarketPreferencesStatus,
  cancelGetMarketPreferencesStatus,
  getMarketPreferences,
  cancelGetMarketPreferences,
  updateMarketPreferences,
  cancelUpdateMarketPreferences,
  getWarehouseForCode,
  cancelGetWarehouseForCode,
  getSupportContact,
  cancelGetSupportContact,
  discontinuePatient,
  cancelDiscontinuePatient,
  getReadOnlyOpaUrl,
  cancelGetReadOnlyOpaUrl,
  getPhysicianSearch,
  cancelGetPhysicianSearch,
  enrolPhysiciansOnToProgram,
  cancelGetenrolPhysiciansOnToProgram,
  cancelRemoveOrder,
  removeOrder,
  programDocumentSearch,
  cancelProgramDocumentSearch,
  getProgramDocumentSearchFacets,
  cancelGetProgramDocumentSearchFacets,
  getWarehouseForCodes,
  cancelGetWarehouseForCodes,
  getBanner,
  cancelGetBanner,
  getCountries,
  cancelGetCountries,
  getLov,
  cancelGetLov,
  getUserNewFeatures,
  setNewFeatureToViewed,
  updateHoldOption,
  createPatient,
  cancelCreatePatient,
  updatePatient,
  cancelUpdatePatient,
  getProgramsSuggestion,
  cancelGetProgramsSuggestion,
  cancelDeletePatient,
  deletePatient
}
