import { AxiosError } from 'axios'
import { useCallback } from 'react'
import { AnnounceMode } from '../components/ClinAnnounceBar/ClinAnnounceBar'
import { useAppContext } from '../context/app'
import { createAnnounceEvent } from '../events/AnnounceEvent'
import analyticsServiceSingleton from '../services/Analytics/initAnalytics'
import { AuthError } from '../services/ApiService'

/**
 * Utility hook to display an error message and optionally scroll to top also
 * @param errorMessage
 * @param scrollToTop
 */
export const useErrorMessage = (
  errorMessage?: string,
  scrollToTop?: boolean
) => {
  const { dispatch } = useAppContext()

  return useCallback(
    (error: AxiosError) => {
      const { code, message, response } = error
      const detail = response?.data?.detail ?? ''

      // If request is cancelled continue
      if (message === AuthError.RequestCancelled) {
        return
      }
      // If we have a full error show it
      if (response) {
        const title = response.data?.title ?? ''
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `${errorMessage ?? ''} ${title} ${message}`
          )
        )
        console.warn(title, detail)
      } else {
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `${errorMessage ?? ''} ${message} ${code ?? ''}`
          )
        )
      }
      if (scrollToTop) {
        window.scrollTo({
          behavior: 'smooth',
          top: 0
        })
      }
      analyticsServiceSingleton.trackError(
        error,
        JSON.parse(localStorage.getItem('current_user') || '{}')
      )
    },
    [dispatch, errorMessage, scrollToTop]
  )
}
