import { lighten } from 'polished'
import styled from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'
import { mediaQuery } from '../../../utils/mediaQuery'
import { ISearchStylesProps } from '../AutoSuggest/AutoSuggest'

const mobileUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledSearchWrapper = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  [`~ .clin-icon`]: {
    color: ClinTheme.colors.grey
  }
})

export const StyledResults = styled.ul<ISearchStylesProps>(
  {
    listStyle: 'none',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    margin: 0,
    padding: 0,
    marginTop: ClinTheme.space[2],
    zIndex: ClinTheme.zIndices.suggestiveSearch,
    border: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.primaryLight}`,
    boxShadow: `0px 0px 3px 0px ${ClinTheme.colors.primaryLight}`,
    borderRadius: `0 0 ${ClinTheme.radii[2]}px ${ClinTheme.radii[2]}px`,
    overflow: 'hidden',
    backgroundColor: ClinTheme.colors.white
  },
  ({ showSuggestions }) => ({
    display: showSuggestions ? 'block' : 'none'
  })
)

export const StyledSearch = styled.div({
  position: 'relative',
  width: '100%',

  [mediaQuery(mobileUpBreakpoint)]: {
    marginLeft: ClinTheme.space[2],
    marginRight: ClinTheme.space[3]
  }
})

export const StyledSearchInput = styled.input<ISearchStylesProps>({
  width: '100%',
  boxSizing: 'border-box',
  textIndent: ClinTheme.space[2],
  color: ClinTheme.colors.black,
  fontSize: ClinTheme.fontSizes[2],
  fontWeight: ClinTheme.fontWeights.normal,
  fontFamily: ClinTheme.fonts.body,
  lineHeight: `${ClinTheme.fontSizes[4] - 2}px`, // subtract top and bottom border 1px widths
  letterSpacing: ClinTheme.letterSpacing[0],
  borderRadius: ClinTheme.radii[2],
  border: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`,
  transition: '.1s linear box-shadow',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: ClinTheme.space[3],
  paddingRight: ClinTheme.space[7],
  paddingLeft: ClinTheme.space[4],

  [`:focus`]: {
    outline: 'none',
    border: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.primaryLight}`,
    boxShadow: `0px 0px 3px 0px ${ClinTheme.colors.primaryLight}`
  },
  '::placeholder': {
    color: lighten(0.5, ClinTheme.colors.black),
    textOverflow: 'ellipsis'
  },
  ':-ms-input-placeholder': {
    // Special case for Text Inputs to have correct height in IE 11
    height: '40px',
    textOverflow: 'ellipsis'
  },
  ':disabled': {
    cursor: 'not-allowed'
  }
})
