import styled from 'styled-components'

export const StyledNoBookmarksMessage = styled.div({
  textAlign: 'center',
  svg: {
    width: 150,
    height: 150
  },
  p: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  [`.no-bookmarks-message__instructions`]: {
    maxWidth: 380
  }
})
