import React, { createContext, useContext, useEffect, useState } from 'react'
import { FeatureFlagContextType, FeatureFlag } from './FeatureFlagModels'
import { useAppContext } from '../../context/app'
import {
  evaluateFeatureFlags,
  parseFeatureFlagData
} from './FeatureFlagHelpers'

const FeatureFlagContext = createContext<FeatureFlagContextType | undefined>(
  undefined
)

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagContext)
  if (!context) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider')
  }

  // Custom hook to safely access featureFlag
  const useFeatureFlag = (key: string): boolean | undefined => {
    return context.featureFlag[key]
  }

  return { ...context, useFeatureFlag }

  // return context
}

export const FeatureFlagProvider: React.FC = ({ children }) => {
  const { userDetails } = useAppContext()
  const [featureFlagRawData, setFeatureFlagRawData] = useState<FeatureFlag[]>(
    []
  )
  const [featureFlag, setFeatureFlag] = useState<Record<string, boolean>>({})

  useEffect(() => {
    if (userDetails) {
      let parsedFeatureFlagData = parseFeatureFlagData(featureFlagRawData)
      let evaluatedFlags = evaluateFeatureFlags(
        parsedFeatureFlagData,
        userDetails
      )

      setFeatureFlag(evaluatedFlags)
    }
  }, [featureFlagRawData, userDetails])

  return (
    <FeatureFlagContext.Provider value={{ featureFlag, setFeatureFlagRawData }}>
      {children}
    </FeatureFlagContext.Provider>
  )
}
