import { IIndexable } from '../types'

export const sortByDateFn = (d1: string, d2: string): number => {
  const date1 = new Date(d1)
  const date2 = new Date(d2)
  return date1.getTime() - date2.getTime()
}

export const sortByFieldNameFn = (
  item1: IIndexable,
  item2: IIndexable,
  fieldName: string,
  convertToDate?: boolean
): number => {
  const isString = typeof item1[fieldName] === 'string'
  if (isString) {
    return item1[fieldName].localeCompare(item2[fieldName])
  }
  return convertToDate
    ? sortByDateFn(item1[fieldName], item2[fieldName])
    : item1[fieldName] - item2[fieldName]
}
