import React, { CSSProperties, FunctionComponent } from 'react'
import {
  StyledIconValueCell,
  StyledIconTitle,
  StyledValue,
  IStyledIconvalueCellProps
} from './IconValueCell.styles'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'

interface IIconValueCellProps extends IStyledIconvalueCellProps {
  /** The name of the icon to display */
  iconName?: ClinIconPathName
  /** The title to display */
  title?: string
  /** Any style properties to modify */
  style?: CSSProperties
}

export const IconValueCell: FunctionComponent<IIconValueCellProps> = ({
  iconName = ClinIconPathName.Warning,
  title = 'IconValueCell Title',
  maxTitleWidth,
  children,
  style
}) => (
  <StyledIconValueCell className="clin-icon-value-container" style={style}>
    <StyledIconTitle className="clin-icon-title" maxTitleWidth={maxTitleWidth}>
      <ClinIcon
        iconName={iconName}
        iconSize={ClinTheme.space[5]}
        iconFill={ClinTheme.colors.primary}
      />
      <ClinText
        as="div"
        color={ClinTheme.colors.primary}
        fontWeight={ClinTheme.fontWeights.medium}
        marginLeft={ClinTheme.space[2]}
      >
        {title}
      </ClinText>
    </StyledIconTitle>
    <StyledValue className="clin-icon-value">{children}</StyledValue>
  </StyledIconValueCell>
)
