/**
 * Validate files on frontend before upload
 * Not foolproof of course (should be serverside) but a client request to dissuade malicious uploads
 * @param fileName
 * @param acceptedExtensionStr
 */
export const validateFileExtensionAllowed = (
  fileName: string,
  acceptedExtensionStr: string
): boolean => {
  const acceptedExtensions: string[] = []
  const acceptedFilenames: string[] = acceptedExtensionStr.split(',')
  acceptedFilenames.forEach((fn) => {
    const extension = getFileExtensionFromFilename(fn)
    if (extension) {
      acceptedExtensions.push(extension)
    }
  })
  const fileExtension =
    getFileExtensionFromFilename(fileName)?.toLocaleLowerCase()
  return !!(fileExtension && acceptedExtensions.includes(fileExtension))
}

/**
 * Get a file extension from a full filename
 * @param filename
 */
export const getFileExtensionFromFilename = (
  filename: string
): string | undefined => {
  const matches = filename.match(/\.([0-9a-z]+)(?:[?#]|$)/i)
  if (matches && matches.length) {
    return matches[0]
  }
  return undefined
}

export const maxDocumentUploadSasHold = 10
