import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { ClinTheme } from '../../../ClinTheme'
import { ClinText } from '../../../components/ClinText'
import { TransferPatientTo } from '../../../pages/TransferPatient/models/TransferPatientContainer.model'
import { PatientProgramDto } from '../../../types/swaggerTypes'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { useTranslation } from 'react-i18next'

interface ITransferPatientConfirmation {
  /** Selected program */
  program?: PatientProgramDto
  /** Physician or institute transfer to */
  typeTransferring?: string
}

export const ProgramDetails: FunctionComponent<
  ITransferPatientConfirmation
> = ({ program, typeTransferring }) => {
  const { t } = useTranslation()
  let colAmount: number =
    typeTransferring === TransferPatientTo.PHYSICIAN ? 4 : 6
  return (
    <>
      <Row>
        <Col sm={12} md={colAmount}>
          <ClinText variant={TypographyVariant.LargeParagraph}>
            {t('transfer_patient_to_physician:program')}
          </ClinText>

          <ClinText
            className="patient-id"
            variant={TypographyVariant.LargeParagraph}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {program?.programName}
          </ClinText>
        </Col>
        <Col sm={12} md={colAmount}>
          <ClinText variant={TypographyVariant.LargeParagraph}>
            {t('transfer_patient_to_physician:therapeutic_area')}
          </ClinText>
          <ClinText
            className="initials"
            variant={TypographyVariant.LargeParagraph}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {program?.therapeuticArea}
          </ClinText>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={colAmount}>
          <ClinText variant={TypographyVariant.LargeParagraph}>
            {t('transfer_patient_to_physician:status')}
          </ClinText>
          <ClinText
            className="patient-ref-number"
            variant={TypographyVariant.LargeParagraph}
            fontWeight={ClinTheme.fontWeights.bold}
            color={ClinTheme.colors.greenValid}
          >
            {program?.status}
          </ClinText>
        </Col>
        <Col sm={12} md={colAmount}>
          <ClinText variant={TypographyVariant.LargeParagraph}>
            {t('transfer_patient_to_physician:sponsor')}
          </ClinText>
          <ClinText
            className="date-of-birth"
            variant={TypographyVariant.LargeParagraph}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {program?.sponsor}
          </ClinText>
        </Col>
      </Row>
    </>
  )
}
