import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'

export interface IDatePickerStyles {
  /** Custom width for input */
  inputWidth?: number | string
  /** Sets field styles to error */
  hasError?: boolean
  /** Disable the input */
  disabled?: boolean
  /** z-index css value */
  zIndex?: number
}

export const StyledDatePicker = styled.div<IDatePickerStyles>(
  {
    position: 'relative',
    '.date-picker-trigger': {
      ':focus': {
        border: `1px solid ${ClinTheme.colors.primaryMid}!important`,
        outlineStyle: 'none!important',
        boxShadow: 'none!important',
        outline: 'none!important'
      }
    },

    '.DatePicker ': {
      fontFamily: ClinTheme.fonts.body,
      width: '100%',
      zIndex: 99,

      '.DatePicker__input.-ltr': {
        color: ClinTheme.colors.black,
        fontFamily: ClinTheme.fonts.body,
        fontSize: ClinTheme.fontSizes[2],
        fontWeight: ClinTheme.fontWeights.normal,
        lineHeight: `${ClinTheme.fontSizes[4] - 2}px`,
        letterSpacing: ClinTheme.letterSpacing[0],
        padding: `${ClinTheme.space[2]}px ${ClinTheme.space[3]}px`,
        borderRadius: ClinTheme.radii[2],
        transition: '.1s linear box-shadow',
        boxSizing: 'border-box',
        textAlign: 'left',
        width: '100%'
      }
    },

    '.DatePicker__calendarArrow ': {
      display: 'none'
    },
    '.MuiInputBase-input.MuiOutlinedInput-input': {
      height: '0.4em',
      textTransform: 'uppercase'
    },

    '.Calendar': {
      fontFamily: ClinTheme.fonts.body,
      border: `solid 1px ${ClinTheme.colors.primaryMid}`,
      minHeight: '33em',
      overflow: 'hidden',
      boxShadow: `0px 2px 6px 0px rgba(80, 30, 206, 0.5)`
    },

    '.Calendar__day.-selected ': {
      background: ClinTheme.colors.primaryLight,
      color: ClinTheme.colors.white,
      fontWeight: ClinTheme.fontWeights.bold
    },
    '.Calendar__sectionWrapper': {
      minHeight: '23.8em'
    },
    '.Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText, .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText':
      {
        background: ClinTheme.colors.primaryLight
      },
    '.Calendar__monthYear.-shown, .Calendar__yearSelectorText, .Calendar__monthSelectorItemText':
      {
        color: ClinTheme.colors.black,
        WebkitFontSmoothing: 'antialiased'
      },
    '.clin-icon': {
      position: 'absolute',
      top: '50%',
      right: ClinTheme.space[2],
      zIndex: 100,
      transform: 'translateY(-50%)'
    },

    '.Calendar__yearSelector': {
      scrollbarWidth: 'thin'
    }
  },
  ({ hasError, disabled, zIndex, inputWidth }: IDatePickerStyles) => ({
    width: inputWidth ?? 'auto',
    '.DatePicker ': {
      borderRadius: ClinTheme.radii[2],
      '.DatePicker__input.-ltr': {
        border: `solid 1px ${
          hasError ? ClinTheme.colors.redInvalid : ClinTheme.colors.grey
        }`,
        ':focus': {
          border: `solid 1px ${
            hasError ? ClinTheme.colors.redInvalid : ClinTheme.colors.grey
          }`
        },
        backgroundColor: disabled
          ? ClinTheme.colors.lightGrey
          : ClinTheme.colors.white,
        color: disabled ? ClinTheme.colors.grey : 'auto',
        cursor: disabled ? 'not-allowed' : 'pointer'
      },
      zIndex
    },
    '.Calendar': {
      display: disabled ? 'none' : 'auto'
    },
    '.date-picker-trigger': {
      ':focus': {
        border: disabled
          ? `1px solid ${ClinTheme.colors.grey}!important`
          : `1px solid ${ClinTheme.colors.primaryMid}!important`
      }
    }
  })
)
