// Boostrap event updates ------------------------------------------------------------------------

export enum BootstrapEventType {
  UPDATE = 'UpdateBootstrapEvent'
}

export interface IBootstrappedEvent {
  type: BootstrapEventType.UPDATE
  bool: boolean
}

export const createBootstrappedEvent = (bool: boolean): IBootstrappedEvent => ({
  type: BootstrapEventType.UPDATE,
  bool
})
