import { CatalogDtoSuggestionDto } from '../../../types/swaggerTypes'
import React from 'react'
import { ISearchStylesProps } from './AutoSuggest'

/**
 * Interface that any search component passed into this state container should conform to
 */
export interface IAutoSuggestProps extends ISearchStylesProps {
  /** Query string */
  query?: string
  /** Whether we are loading results or not */
  isLoading: boolean
  /** Show results or not */
  showSuggestions: boolean
  /** Placeholder text */
  placeholderText: string
  /** Placeholder text */
  isAusGa?: boolean
  /** Response items */
  suggestions?: CatalogDtoSuggestionDto[]
  /** Placeholder text */
  isMenuSearch?: boolean
  /** Response items */
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  /** When enter key pressed */
  handleEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  /** When keys are pressed */
  handleKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  /** Method to render a cells with */
  renderSuggestions?: (
    suggestions: CatalogDtoSuggestionDto[]
  ) => React.ReactNode | React.ReactNode[]
}

/**
 * Check if path exists in this current route
 * @param str
 * @param expressions
 */
export const matchInArray = (str: string, expressions: string[]): boolean => {
  for (const item of expressions) {
    if (str?.match(item)) {
      return true
    }
  }
  return false
}
