import styled from 'styled-components'
import { rgba } from 'polished'
import { ClinTheme } from '../../../ClinTheme'
import { mediaQuery } from '../../../utils/mediaQuery'

const mobileUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledOverlayLoader = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  background: rgba(ClinTheme.colors.white, 0.95)
})

export const StyledActions = styled.div({
  display: 'inline-flex',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  width: '100%',

  'button:last-of-type': {
    marginBottom: ClinTheme.space[3]
  },

  [mediaQuery(mobileUpBreakpoint)]: {
    flexDirection: 'row',
    flexWrap: 'nowrap',

    button: {
      marginBottom: 0,
      marginRight: ClinTheme.space[3]
    },

    'button:last-of-type': {
      marginBottom: 0
    }
  }
})
