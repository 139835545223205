/**
 * A date accurate self adjusting timer in seconds
 * Timer completed after 29997 milliseconds for a 30000 millisecond duration - drift = -3ms
 * @author
 * T. Johnson
 *
 * @usage
 *
 * const timer = new BasicTimer({intervalMs: 1000, showLog: true})
 * timer.durationSecs = 30
 * timer.onComplete = () => {
 * 	console.log('Complete!')
 * }
 * timer.onTick = (remaining: number) => {
 * 	console.log(remaining)
 * }
 * timer.start()
 */

export interface IBasicTimerConfig {
  intervalMs?: number // default 1000
  showLog?: boolean // default: false
}

export class BasicTimer {
  private readonly intervalMs: number
  private readonly showLog: boolean

  private timeoutId: NodeJS.Timeout | undefined
  private startTimeMs: number
  private counter: number

  private _durationSecs: number = 15
  public set durationSecs(secs: number | string) {
    this._durationSecs = typeof secs === 'string' ? parseInt(secs) : secs
  }
  public get durationSecs() {
    return this._durationSecs
  }
  public onTick: ((remaining: number) => void) | undefined
  public onComplete: (() => void) | undefined
  public isRunning = false

  constructor({ intervalMs, showLog }: IBasicTimerConfig) {
    this.intervalMs = intervalMs || 1000
    this.showLog = showLog || false
    this.startTimeMs = 0
    this.counter = -1
    if (this.showLog) {
      console.log(`BasicTimer created - ${Date.now()}`)
    }
  }

  // Private Methods -------

  private timerTick = () => {
    this.counter++
    const remaining: number = this._durationSecs - this.counter
    if (this.onTick) {
      this.onTick(remaining)
      if (this.showLog) {
        console.log(`Timer onTick called remaining: ${remaining}`)
      }
    }
    if (this.counter === this._durationSecs) {
      this.stop()
      if (this.onComplete) {
        this.onComplete()
      }
    }
  }

  private initTimer = () => {
    this.timerTick()
    this.startTimeMs = Date.now()
    let expectedMs = this.startTimeMs + this.intervalMs

    const tick = () => {
      const delta = Date.now() - expectedMs // the drift (positive for overshooting)
      // Do callback
      this.timerTick()
      // Look at drift
      expectedMs += this.intervalMs
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = setTimeout(tick, Math.max(0, this.intervalMs - delta)) // take into account drift
      }
    }

    this.timeoutId = setTimeout(tick, this.intervalMs)
  }

  // Public Methods -------
  start = () => {
    this.isRunning = true
    this.initTimer()
  }

  pause = () => {
    this.isRunning = false
  }

  reset = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
      this.timeoutId = undefined
    }
    this.counter = -1
  }

  stop = () => {
    this.isRunning = false
    if (this.showLog) {
      const elapsedTimeMs = Date.now() - this.startTimeMs
      console.log(
        `Timer completed after ${elapsedTimeMs} milliseconds for a ${
          this._durationSecs * 1000
        } millisecond duration - drift = ${
          -this._durationSecs * 1000 + elapsedTimeMs
        }ms`
      )
    }
    this.reset()
  }
}
