import { Day } from '@amir04lm26/react-modern-calendar-date-picker'
import { DateTime } from 'luxon'

/**
 * Utility to convert a date string to a 'Day' format used by 'react-modern-calendar-datepicker'
 * @param dateStr
 * @param format i.e. https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
 * @returns the date as a 'Day'
 */
export const convertDateStringToDay = (
  dateStr: string,
  format?: string
): Day => {
  const { year, month, day } = format
    ? DateTime.fromFormat(dateStr, format)
    : DateTime.fromISO(dateStr)
  return {
    year,
    month,
    day
  }
}

/**
 * Utility to convert a 'Day' to a standard ISO date string minus time
 * @param day
 * @param month
 * @param year
 */
export const convertDayToISODateString = ({
  day,
  month,
  year
}: Day): string => {
  return DateTime.local(year, month, day).toISODate()
}

/**
 * Utility to convert a 'Day' to a Luxon DateTime format
 * @param day
 * @param month
 * @param year
 */
export const convertDayToDateTime = ({ day, month, year }: Day): DateTime => {
  return DateTime.local(year, month, day)
}

/**
 * Utility to compare two dates and return latest
 * @param firstDate
 * @param secondDate
 */
export const compareTwoDates = (
  firstDate: string,
  secondDate: string
): string => {
  return DateTime.fromISO(firstDate, { zone: 'utc' }) >
    DateTime.fromISO(secondDate, { zone: 'utc' })
    ? firstDate
    : secondDate
}
