import React, { FunctionComponent } from 'react'
import { StyledFlexSpacer, StyledNoProducts } from './NoProducts.styles'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinButton } from '../../components/ClinButton'
import { Link } from 'react-router-dom'

interface INoProductsProps {
  /** User role */
  isMaUser?: boolean
}

export const NoProducts: FunctionComponent<INoProductsProps> = ({
  isMaUser
}) => {
  const url = isMaUser ? '/about/contact-us' : '/sourcing-enquiry'
  const btnName = isMaUser
    ? `Contact customer services`
    : `Place sourcing request`
  return (
    <StyledNoProducts>
      <ClinSpacer height={ClinTheme.space[8]} />
      <ClinIcon
        iconName={ClinIconPathName.ProductCode}
        iconFill={ClinTheme.colors.grey}
        iconSize={170}
      />
      <StyledFlexSpacer />
      <ClinText
        variant={TypographyVariant.H4}
        fontWeight={ClinTheme.fontWeights.bold}
        marginTop={ClinTheme.space[2]}
      >
        {"There doesn't appear to be a match"}
      </ClinText>
      <StyledFlexSpacer />
      <ClinText
        as="div"
        className={'msg-copy'}
        textAlign={'center'}
        variant={TypographyVariant.LargeParagraph}
      >
        {`Let us know what you're looking for and we can help.`}
      </ClinText>
      <StyledFlexSpacer />
      <ClinSpacer height={ClinTheme.space[4]} />
      <Link to={url}>
        <ClinButton variant="primary">{btnName}</ClinButton>
      </Link>
      <ClinSpacer height={ClinTheme.space[8]} />
    </StyledNoProducts>
  )
}
