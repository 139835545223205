import { State } from './BasketContext'
import React from 'react'
import { ActionType, ActionTypes } from './BasketContext.actions'
import {
  BasketItemDto,
  PriceAndAvailabilityDto
} from '../../types/swaggerTypes'
import { BasketStatus, IViewBasketItem } from './BasketContext.types'
import { getVariantInformationForCatalogItem } from '../../services/PortfolioJourneys/PortfolioJourney.model'
import { getHoldsInformationFor } from '../../services/Holds/holds'
import { HoldType } from '../../services/Holds/holds.constants'
import { compareTwoDates } from '../../utils/dateUtils'

const basketReducer: React.Reducer<State, ActionTypes> = (
  state: State,
  action: ActionTypes
) => {
  switch (action.type) {
    case ActionType.UpdateBasket: {
      const { basket } = action
      return {
        ...state,
        basket: { ...basket, data: basket.data }
      }
    }

    case ActionType.EmptyBasket: {
      return {
        ...state,
        basket: { data: undefined, isLoading: false, error: undefined },
        viewBasket: {
          items: [],
          total: 0,
          currencyCode: state.viewBasket?.currencyCode,
          instituteId: '',
          basketId: '',
          created: '',
          lastUpdated: '',
          state: BasketStatus.Default,
          userId: '',
          checkoutStamp: '',
          orders: []
        }
      }
    }

    case ActionType.AddBasketItem: {
      const { viewBasket } = state
      const oldBasket = state.basket.data
      const { quantity, pricing, basketItem } = action
      if (
        !basketItem ||
        !viewBasket ||
        !oldBasket ||
        quantity === undefined ||
        !pricing
      ) {
        return {
          ...state
        }
      }
      // Update basket items and quantity
      let updatedItems: BasketItemDto[]
      const itemExists = oldBasket.items.find((ob) => ob.sku === basketItem.sku)
      if (itemExists) {
        itemExists.quantity += quantity
        updatedItems = [...oldBasket.items]
      } else {
        updatedItems = [...oldBasket.items, basketItem]
      }

      // Update view basket & add in P&A
      let updatedViewItems: IViewBasketItem[]
      const existingViewItem = viewBasket.items.find(
        (ob) => ob.sku === basketItem.sku
      )
      if (existingViewItem) {
        const updatedQuantity = existingViewItem.quantity + quantity
        existingViewItem.quantity = updatedQuantity
        existingViewItem.amount =
          updatedQuantity * (pricing ? parseFloat(pricing?.listPrice) : 0)
        updatedViewItems = [...viewBasket.items]
      } else {
        updatedViewItems = [
          ...viewBasket.items,
          {
            ...basketItem,
            priceAndAvailability: pricing,
            amount:
              basketItem.quantity *
              (pricing ? parseFloat(pricing?.listPrice) : 0)
          }
        ]
      }

      const addTotal = updatedViewItems.reduce(sumOrdersReducer, 0)

      return {
        ...state,
        viewBasket: {
          ...viewBasket,
          currencyCode: pricing.currencyCode,
          items: [...updatedViewItems],
          total: addTotal
        },
        basket: {
          ...state.basket,
          data: {
            ...oldBasket,
            items: [...updatedItems]
          }
        }
      }
    }

    case ActionType.UpdateItemQuantity: {
      const { viewBasket } = state
      const { basketItem, quantity } = action
      if (!basketItem || !viewBasket) {
        return {
          ...state
        }
      }
      const updateItemSku: string = basketItem.sku
      const updatedItem: IViewBasketItem | undefined = viewBasket.items.find(
        (item) => item.sku === updateItemSku
      )
      if (!updatedItem) {
        return { ...state }
      }
      if (updatedItem?.priceAndAvailability && quantity) {
        updatedItem.quantity = quantity
        updatedItem.amount =
          parseFloat(updatedItem.priceAndAvailability.listPrice) * quantity
      }
      const updateTotal = viewBasket.items.reduce(sumOrdersReducer, 0)
      return {
        ...state,
        viewBasket: {
          ...viewBasket,
          items: [...viewBasket.items],
          total: updateTotal
        }
      }
    }
    case ActionType.RemoveBasketItem: {
      const existingBasket = state.viewBasket
      const removeItem = action.basketItem
      const existingBasketDto = state.basket.data
      if (!existingBasket || !existingBasketDto) {
        return { ...state }
      }
      const removeItems: IViewBasketItem[] = [
        ...existingBasket.items.filter((item) => item.sku !== removeItem.sku)
      ]
      const removeTotal = removeItems.reduce(sumOrdersReducer, 0)
      return {
        ...state,
        viewBasket: {
          ...existingBasket,
          items: [...removeItems],
          total: removeTotal
        },
        basket: {
          ...state.basket,
          data: {
            ...existingBasketDto,
            items: [
              ...existingBasketDto?.items.filter(
                (item) => item.basketItemId !== removeItem.basketItemId
              )
            ]
          }
        }
      }
    }
    case ActionType.UpdateBasketItemDocuments: {
      const staleViewBasket = state.viewBasket
      const updatedBasketItemDocs = action.basketItemDocuments
      if (!staleViewBasket || !updatedBasketItemDocs) {
        return { ...state }
      }
      return {
        ...state,
        viewBasket: {
          ...staleViewBasket,
          items: staleViewBasket.items.map((item) =>
            item.basketItemId !== action.basketItemId
              ? item
              : {
                  ...item,
                  documents: action.remove ? [] : [...updatedBasketItemDocs]
                }
          )
        }
      }
    }
    case ActionType.SetExpectedDeliveryDate: {
      //if country code is null we set expected delivery date to undefined
      let expectedDeliveryDate: string | undefined = undefined
      if (action.countryCode) {
        //chech all items and find latest estimated delivery date
        state.basket?.data?.items?.forEach((element) => {
          const estimatedDate =
            element.skuCatalogItem.expectedDeliveryDates?.find(
              (e) => e.shipToCountry === action.countryCode
            )

          if (expectedDeliveryDate) {
            if (estimatedDate?.expectedDeliveryDate) {
              expectedDeliveryDate = compareTwoDates(
                expectedDeliveryDate,
                estimatedDate.expectedDeliveryDate
              )
            }
          } else if (estimatedDate?.expectedDeliveryDate) {
            expectedDeliveryDate = estimatedDate.expectedDeliveryDate
          }
        })
      }

      return {
        ...state,
        expectedDeliveryDate
      }
    }
    case ActionType.UpdateBasketDetails: {
      if (action.basketDetails.isMaOrder) {
        return {
          ...state,
          basketMADetails: action.basketDetails
        }
      }
      return {
        ...state,
        basketDetails: action.basketDetails
      }
    }
    case ActionType.SetCurrentBasket: {
      const { priceAndAvailability, basket, countryCode } = action
      if (!priceAndAvailability || !basket) {
        return {
          ...state
        }
      }
      // Augment pricing into basket
      const augBasketItems: IViewBasketItem[] = basket.items.map((item) => {
        const pricingInfo = priceAndAvailability.find(
          (pA) => pA.itemNumber === item.sku
        )
        // Add in order-ability and pricing
        const basketItemWithPricingAndAvailability =
          getIBasketItemWithPricingAndOrderability(
            item,
            countryCode,
            pricingInfo
          )
        // Add holds information
        const basketItemWithHoldInformation =
          basketItemWithPricingAndAvailability.priceAndAvailability &&
          getHoldsInformationFor(
            basketItemWithPricingAndAvailability.priceAndAvailability.holds
          ).filter(
            (doc) =>
              doc.requiresDocumentUpload || doc.type === HoldType.SafetyLetter
          )

        return {
          ...basketItemWithPricingAndAvailability,
          holds: basketItemWithHoldInformation
        }
      })
      // Add up total
      const basketTotal = augBasketItems?.reduce(subTotalReducer, 0)
      return {
        ...state,
        viewBasket: {
          ...basket,
          items: augBasketItems,
          state: BasketStatus[basket.state as BasketStatus],
          total: basketTotal,
          currencyCode: augBasketItems?.length
            ? augBasketItems[0].priceAndAvailability?.currencyCode
            : undefined
        }
      }
    }
    case ActionType.PlaceOrder:
      if (!action.orders || !state.viewBasket) {
        return { ...state }
      }
      return {
        ...state,
        orders: [...action.orders],
        basket: {
          isLoading: false,
          data: {
            ...action.basket,
            state: BasketStatus.Placing
          }
        },
        viewBasket: {
          ...state.viewBasket,
          state: BasketStatus.Placing
        }
      }

    case ActionType.OrderSubmitted:
      if (!state.viewBasket) {
        return { ...state }
      }
      return {
        ...state,
        basket: { data: undefined, isLoading: false, error: undefined },
        viewBasket: {
          ...state.viewBasket,
          state: BasketStatus.Submitted
        },
        completedOrder: action.completedOrder
      }

    case ActionType.ClearCompletedOrder:
      return {
        ...state,
        completedOrder: undefined
      }
    default: {
      throw new Error(`Unhandled action type: ${action}`)
    }
  }
}

/**
 * Reducer to sum up the amounts in a basket item
 * @param sum
 * @param current
 */
export const sumOrdersReducer = (sum: number, current: IViewBasketItem) =>
  sum +
  (current.priceAndAvailability
    ? current.quantity * parseFloat(current.priceAndAvailability.listPrice)
    : 0)

/**
 * Reducer to sum up the amounts in a basket item to a total
 * @param sum
 * @param current
 */
export const subTotalReducer = (sum: number, current: IViewBasketItem) =>
  sum + current.amount

/**
 * Utility to merge pricing into a basketItem
 * @param basketItem
 * @param countryCode
 * @param priceAndAvailability
 */
export const getIBasketItemWithPricingAndOrderability = (
  basketItem: BasketItemDto,
  countryCode: string,
  priceAndAvailability?: PriceAndAvailabilityDto
): IViewBasketItem => {
  const { cannotOrder, uiVariantState } = getVariantInformationForCatalogItem(
    basketItem.skuCatalogItem,
    countryCode
  )
  let amount

  if (cannotOrder) {
    amount = 0
  } else if (priceAndAvailability) {
    amount = parseFloat(priceAndAvailability.listPrice) * basketItem.quantity
  } else {
    amount = 0
  }
  return {
    ...basketItem,
    priceAndAvailability,
    uiVariantState: uiVariantState,
    unavailable: cannotOrder,
    amount: amount
  }
}

export { basketReducer }
