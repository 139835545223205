import React, { ChangeEvent, FunctionComponent } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ClinTheme } from '../../../../ClinTheme'
import { ClinAnnounceBar } from '../../../../components/ClinAnnounceBar'
import { ClinButton } from '../../../../components/ClinButton'
import { ClinGroup } from '../../../../components/ClinGroup'
import { ClinIcon } from '../../../../components/ClinIcon'
import { ClinIconPathName } from '../../../../components/ClinIcon/ClinIcon.paths'
import { ClinLoader } from '../../../../components/ClinLoader'
import { ClinModal } from '../../../../components/ClinModal'
import { ClinRadio } from '../../../../components/ClinRadio'
import { ClinText } from '../../../../components/ClinText'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'
import { OrderAddressDto } from '../../../../types/swaggerTypes'
import {
  StyledAddresses,
  StyledAddress,
  StyledOverlayLoader,
  StyledSubmit,
  StyledNewAddressCta,
  StyledNewAddressText,
  StyledActions
} from './AddressModal.styles'

interface IAddressModalProps {
  /** Control opening state of modal */
  isOpen?: boolean
  /** The address which is selected */
  selectedAddress?: OrderAddressDto | null
  /** The address that the user currently uses */
  currentAddress?: OrderAddressDto | null
  /** An array of available addresses */
  addresses?: OrderAddressDto[] | null
  /** Whether the submission is still processing */
  isSubmitting: boolean
  /** Displays error notification */
  errorMessage?: string
  /** A location provided to access the Request new shipping address page */
  requestShippingAddressLink: string
  /** Handle selection of address  */
  handleAddressSelected: (addressId: string) => void
  /** Handle submit address  */
  handleSubmitAddress: () => void
  /** Handle close institution selection */
  handleClose: () => void
}

export const AddressModal: FunctionComponent<IAddressModalProps> = ({
  isOpen,
  selectedAddress,
  currentAddress,
  addresses,
  isSubmitting,
  errorMessage,
  requestShippingAddressLink,
  handleAddressSelected,
  handleSubmitAddress,
  handleClose
}) => {
  const { t } = useTranslation()
  return (
    <ClinModal
      onClose={handleClose}
      maxWidth="sm"
      height="auto"
      isOpen={isOpen}
    >
      {addresses && (
        <Container>
          <Row justify="center">
            <Col sm={12} md={11}>
              {errorMessage && (
                <Row>
                  <Col>
                    <ClinAnnounceBar title={'Error'}>
                      {errorMessage}
                    </ClinAnnounceBar>
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={12} md={10}>
                  <ClinText
                    as="h3"
                    variant={TypographyVariant.H3}
                    fontWeight={ClinTheme.fontWeights.bold}
                  >
                    {t('program_detail:change_shipping_address')}
                  </ClinText>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={10}>
                  <ClinText as="h3" variant={TypographyVariant.LargeParagraph}>
                    {t('program_detail:please_select_valid_address')}
                  </ClinText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <StyledAddresses>
                    {addresses &&
                      addresses.length > 0 &&
                      addresses.map((address: OrderAddressDto) => (
                        <StyledAddress
                          key={`institute-radio-${address.addressId}`}
                        >
                          {currentAddress?.addressId === address.addressId && (
                            <ClinText
                              marginTop="0px"
                              fontWeight={ClinTheme.fontWeights.medium}
                              color={ClinTheme.colors.primary}
                              marginBottom={ClinTheme.space[2]}
                            >
                              {t('program_detail:current')}
                            </ClinText>
                          )}
                          <ClinRadio
                            color={ClinTheme.colors.primary}
                            label={address.address1}
                            id={address.addressId}
                            value={address.addressId}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              handleAddressSelected(event.currentTarget.value)
                            }}
                            checked={
                              selectedAddress?.addressId === address.addressId
                            }
                          >
                            <ClinText
                              variant={TypographyVariant.LargeParagraph}
                              fontWeight={ClinTheme.fontWeights.medium}
                              marginTop="0"
                            >
                              {address.address1 && (
                                <span
                                  style={{
                                    fontWeight: ClinTheme.fontWeights.medium
                                  }}
                                >
                                  {address.address1}
                                  <br />
                                </span>
                              )}
                            </ClinText>

                            <ClinText
                              variant={TypographyVariant.LargeParagraph}
                              marginBottom="0"
                            >
                              {`${address.address2 && address.address2 + ','} ${
                                address.address3 && address.address3 + ','
                              } ${address.address4 && address.address4 + ','} ${
                                address.city && address.city + ','
                              } ${address.country && address.country + ','} ${
                                address?.postalCode
                              }`}
                            </ClinText>
                          </ClinRadio>
                        </StyledAddress>
                      ))}
                  </StyledAddresses>
                </Col>
              </Row>
              <Row>
                <Col>
                  <StyledNewAddressCta>
                    <ClinIcon
                      iconSize={ClinTheme.fontSizes[5]}
                      iconName={ClinIconPathName.Marker}
                      iconFill={ClinTheme.colors.primaryMid}
                    />
                    <StyledNewAddressText>
                      <ClinText
                        marginLeft={ClinTheme.space[2]}
                        marginRight={ClinTheme.space[2]}
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {t('program_detail:dont_see_shipping_address')}
                        <br />
                        <Link to={requestShippingAddressLink}>
                          {t('program_detail:request_new_shipping_address_btn')}
                        </Link>
                      </ClinText>
                    </StyledNewAddressText>
                  </StyledNewAddressCta>
                </Col>
              </Row>

              <StyledSubmit>
                <Row justify="between">
                  <Col md={5} lg={6}>
                    <StyledActions>
                      <ClinButton
                        className="close-modal"
                        onClick={() => handleClose()}
                      >
                        {t('common:buttons.cancel')}
                      </ClinButton>
                      <ClinButton
                        variant="primary"
                        className="submit-address"
                        disabled={!!errorMessage}
                        onClick={() => handleSubmitAddress()}
                      >
                        {t('program_detail:change_shipping_address_btn')}
                      </ClinButton>
                    </StyledActions>
                  </Col>
                </Row>
              </StyledSubmit>
            </Col>
          </Row>
        </Container>
      )}
      {isSubmitting && (
        <StyledOverlayLoader>
          <Container>
            <Row justify="center" align="center">
              <Col sm={12}>
                <ClinText
                  marginLeft="auto"
                  marginRight="auto"
                  marginBottom={ClinTheme.space[5]}
                  textAlign="center"
                  variant={TypographyVariant.LargeParagraph}
                >
                  {t('program_detail:please_wait')}
                </ClinText>
                <ClinGroup justifyContent="center">
                  <div
                    style={{
                      height: ClinTheme.space[9] * 2,
                      width: ClinTheme.space[9] * 2
                    }}
                  >
                    <ClinLoader minHandDuration={'1s'} />
                  </div>
                </ClinGroup>
              </Col>
            </Row>
          </Container>
        </StyledOverlayLoader>
      )}
    </ClinModal>
  )
}
