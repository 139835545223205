import React, { FunctionComponent, useRef, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import {
  Day,
  DatePickerProps
} from '@amir04lm26/react-modern-calendar-date-picker'
import { IDatePickerStyles, StyledDatePicker } from './ClinDatePicker.styles'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../ClinText'
import { noop } from '../../utils/noop'

/**
 * Utility to get today's date in Day format
 */
export const getToday = (): Day => {
  const todayDate = new Date()
  const year = todayDate.getFullYear()
  const month = todayDate.getMonth() + 1
  const day = todayDate.getDate()
  return { year, month, day }
}

const openPickerIcon = (disabled: boolean | undefined) => (
  <ClinIcon
    fillRule="evenodd"
    clipRule="evenodd"
    iconHeight={16}
    iconSize={16}
    viewBox="0 0 16 16"
    iconName={ClinIconPathName.Calendar}
    iconFill={disabled ? ClinTheme.colors.grey : ClinTheme.colors.primary}
  />
)

/**
 * Utility to convert datepicker 'Day' to a readable string
 * @param selectedDay
 */
export const getDayFormatted = (selectedDay: Dayjs): string => {
  return selectedDay.format('MMMM D, YYYY')
}

type IDatePickerProps = Omit<DatePickerProps<any>, 'colorPrimary' | 'value'>

interface IClinDatePickerProps extends IDatePickerStyles, IDatePickerProps {
  /** Indicates the selected day defaults to today */
  currentDate?: Day
  /** Provides a maximum date that can be selected whilst disabling all other dates there after */
  maximumDate?: Day
  /** Provides a minimum date that can be selected whilst disabling all other dates before this */
  minimumDate?: Day
  /** The label to display below the input */
  prompt?: string
  label?: string
  placeholder?: string
  position?: 'auto' | 'bottom' | 'top'
  width?: string
  savedDay?: string
  /** A function that returns the date selected */
  onChange(value: string): void
  /** A function that is called and returns nothing (for managing zIndex) */
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

export const ClinDatePicker: FunctionComponent<IClinDatePickerProps> = ({
  inputWidth,
  currentDate = getToday(),
  maximumDate,
  minimumDate,
  hasError,
  prompt,
  label,
  position,
  placeholder,
  width,
  savedDay,
  onChange,
  onClick,
  zIndex,
  ...props
}) => {
  const [selectedDay, setSelectedDay] = useState<Dayjs | null>(
    savedDay ? dayjs(savedDay, 'MMMM D, YYYY') : null
  )
  const datePickerRef = useRef<any>(null)

  return (
    <>
      {label && (
        <ClinText
          as="div"
          fontSize={ClinTheme.fontSizes[1]}
          color={props.disabled ? ClinTheme.colors.grey : 'initial'}
          marginBottom={ClinTheme.space[2]}
        >
          {label}
        </ClinText>
      )}
      <StyledDatePicker
        inputWidth={inputWidth}
        onClick={(event) => onClick?.(event)}
        zIndex={zIndex}
        hasError={hasError}
        disabled={props.disabled}
      >
        <span ref={datePickerRef}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            dateFormats={{ monthShort: 'MMMM' }}
          >
            <DatePicker
              calendarPopperPosition={position ?? undefined}
              inputClassName="date-picker-trigger"
              inputPlaceholder={placeholder ?? undefined}
              value={selectedDay}
              onChange={(value) => {
                if (props.disabled) {
                  noop()
                } else {
                  setSelectedDay(value)
                  value && onChange(getDayFormatted(value))
                }
              }}
              minDate={
                minimumDate
                  ? dayjs(
                      `${minimumDate.year}-${minimumDate.month}-${minimumDate.day}`
                    )
                  : undefined
              }
              maxDate={
                maximumDate
                  ? dayjs(
                      `${maximumDate.year}-${maximumDate.month}-${maximumDate.day}`
                    )
                  : undefined
              }
              format="DD/MMM/YYYY" // format value
              localeText={{
                fieldDayPlaceholder: () => 'dd',
                fieldMonthPlaceholder: () => 'mmm',
                fieldYearPlaceholder: () => 'yyyy'
              }}
              {...props}
              slots={{ openPickerIcon: () => openPickerIcon(props.disabled) }}
            />
          </LocalizationProvider>
        </span>
      </StyledDatePicker>
      {prompt && (
        <ClinText
          className="date-picker-prompt"
          as="div"
          fontSize={ClinTheme.fontSizes[1]}
          color={hasError ? ClinTheme.colors.redInvalid : 'inherit'}
          marginTop={ClinTheme.space[2]}
        >
          {prompt}
        </ClinText>
      )}
    </>
  )
}
