import React, { FunctionComponent } from 'react'
import { ClinTheme } from '../../../ClinTheme'
import { Col, Hidden, Row } from 'react-grid-system'
import { CatalogDto, CatalogItemDto } from '../../../types/swaggerTypes'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { VariantCard } from '../../../features/VariantCard'
import { ClinChip } from '../../../components/ClinChip'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { StyledProductCell, StyledProductHeader } from './ProductVariant.styles'
import { BookmarkStatus } from '../../Bookmarks/Bookmarks.model'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import {
  BookmarkableItem,
  BookmarkableItemType
} from '../../../types/BookmarkableItem'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinButton } from '../../../components/ClinButton'
import { ICatalogItemDto } from './ProductVariant.models'
import { useTranslation } from 'react-i18next'
import {
  INewFeature,
  NewFeatureElements
} from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { ClinNewFeatureTooltipContainer } from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'
import { UserRole, isAusGaUser } from '../../../constants'
import { useCheckUnableHomePage } from '../../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'

interface IProductDetailProps {
  /** Selected product */
  product?: CatalogDto
  /** Selected product variants */
  catalogItems?: ICatalogItemDto[]
  /** Whether we are loading or not */
  isLoading: boolean
  /** Country code for ship to */
  countryCode: string
  /** Whether this product or not or loading */
  bookmarkedStatus?: BookmarkStatus
  /** User country*/
  userCountry: string
  /** User role*/
  userRole?: UserRole
  /** List with user unseen features from context */
  newFeaturesList?: INewFeature[]
  /** Handle download button click */
  handleSelectVariant?: (variant: CatalogItemDto) => void
  /** Handle back to top request */
  handleBackToTop: () => void
  /** Render prop for showing bookmarks */
  renderBookmarkToggle: FunctionComponent<BookmarkableItem>
}

export const ProductVariant: FunctionComponent<IProductDetailProps> = ({
  product,
  catalogItems,
  isLoading,
  countryCode,
  userCountry,
  userRole,
  newFeaturesList,
  handleSelectVariant,
  handleBackToTop,
  renderBookmarkToggle
}) => {
  const { t } = useTranslation()
  const { enableNewHomePage } = useCheckUnableHomePage()

  const getCrumbs = () => {
    let crumbsArray = [
      isAusGaUser(userCountry, userRole) && enableNewHomePage
        ? {
            name: t('navigation:home'),
            path: '/home'
          }
        : { path: '/orders', name: t('orders:page_name') },
      {
        name: t('product_catalogue:non_ma_title'),
        path: '/products/catalogue'
      },
      {
        name: product ? product.catalogItemName : '',
        path: `/product/${product && product.catalogDocumentId}`
      }
    ]

    return crumbsArray
  }

  return isLoading || !product ? (
    <ClinPageContentFrame>
      <Row align="center">
        <Col>
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    </ClinPageContentFrame>
  ) : (
    <ClinPageContentFrame crumbs={getCrumbs()}>
      <Row>
        <Col sm={10}>
          <ClinText
            as="h1"
            variant={TypographyVariant.H2}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {product && product.catalogItemName}
          </ClinText>
        </Col>
      </Row>
      <StyledProductHeader>
        <StyledProductCell>
          <Hidden xs sm>
            <ClinIcon
              iconName={ClinIconPathName.TherapeuticArea}
              iconSize={ClinTheme.space[4]}
            />
          </Hidden>
          <ClinText
            as="div"
            fontWeight={ClinTheme.fontWeights.medium}
            marginLeft={ClinTheme.space[2]}
            marginRight={ClinTheme.space[3]}
          >
            {t('glossary:therapeutic_area')}
          </ClinText>
          {product.tags.map((tag) => (
            <ClinChip key={`ta-${tag}`}>{tag}</ClinChip>
          ))}
        </StyledProductCell>
        <StyledProductCell
          justifyContent={'flex-end'} //TODO after removing new feature dot change this to space-between
        >
          {/* Display new feature bookmark ********************************************* */}
          {newFeaturesList?.find(
            (x) => x.id === NewFeatureElements.BookmarkFeature
          ) && (
            <ClinNewFeatureTooltipContainer
              feature={
                newFeaturesList.find(
                  (x) => x.id === NewFeatureElements.BookmarkFeature
                ) as INewFeature
              }
            ></ClinNewFeatureTooltipContainer>
          )}
          {renderBookmarkToggle({
            itemType: BookmarkableItemType.Generic,
            itemId: product.catalogDocumentId
          })}
        </StyledProductCell>
      </StyledProductHeader>
      {/* Variants */}
      <ClinText
        as="h4"
        variant={TypographyVariant.H4}
        marginBottom={ClinTheme.space[3]}
      >
        {t('product_variants:select_product_variant')}
      </ClinText>
      <ClinSpacer />

      {catalogItems &&
        catalogItems.map((catalogItem, index) => {
          return (
            <Row key={`catalogueItem-${index}`}>
              <Col sm={12}>
                <VariantCard
                  countryCode={countryCode}
                  catalogueItem={catalogItem}
                  showGBMinimumOrderMessage={countryCode === 'GB'}
                  cta={t('product_variants:select_variant')}
                  onClick={() =>
                    handleSelectVariant && handleSelectVariant(catalogItem)
                  }
                />
              </Col>
            </Row>
          )
        })}
      {product && product.items.length > 2 && (
        <>
          <ClinSpacer height={ClinTheme.space[4]} />
          <Row justify="center">
            <Col width="auto">
              <ClinButton onClick={() => handleBackToTop()} variant="ghost">
                {t('common:buttons.back_to_top')}
              </ClinButton>
            </Col>
          </Row>
        </>
      )}
    </ClinPageContentFrame>
  )
}
