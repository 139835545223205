import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'
import { mediaQueryDown } from '../../utils/mediaQuery'

const mobileUpBreakpoint = ClinTheme.breakpoints[1]
const tabletUpBreakpoint = ClinTheme.breakpoints[3]

export const StyledOrderCollapsingBox = styled.div({
  [`.accordion__item .accordion .accordion-panel`]: {
    padding: 0,
    margin: 0,
    width: '100%',
    [mediaQueryDown(mobileUpBreakpoint)]: {
      padding: 0
    },
    [`.accordion__item .accordion .accordion-header`]: {
      paddingBottom: ClinTheme.space[2],
      [`span`]: {
        color: ClinTheme.colors.primary,
        fontWeight: ClinTheme.fontWeights.medium
      }
    }
  }
})

export const StyledNoProducts = styled.div({
  padding: `${ClinTheme.space[4]}px ${ClinTheme.space[5]}px`
})

export const StyleQuantityText = styled(ClinText)({
  textAlign: 'right',

  [mediaQueryDown(tabletUpBreakpoint)]: {
    textAlign: 'left'
  }
})
