import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../components/ClinAccordion'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { programColumns, ProgramColumn } from './AccessProgramsContainer.models'
import { SortDirectionType } from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ProgramsTable } from '../../features/ProgramsTable'
import { IProgramSummary } from '../../types'
import {
  IPagination,
  getCurrentPage,
  getTotalPages
} from '../../components/ClinPagination/ClinPagination.model'
import { ClinPagination } from '../../components/ClinPagination'
import { CountryDto } from '../../types/swaggerTypes'
import { useTranslation } from 'react-i18next'

interface IAccessProgramsProps {
  /** Whether we are loading or not */
  isLoading: boolean
  /** Selected tab */
  selectedTabIndex: number
  /** Set of pagination*/
  pagination: IPagination
  /** Selected column for sorting*/
  selectedSortColumn?: ProgramColumn
  /** Selected sort direction*/
  selectedSortDirection?: SortDirectionType
  /** Selected type of programs*/
  programs: IProgramSummary[]
  /** If the user is MA only or not */
  isMaUser?: boolean
  /** Supplied contact data */
  supportContact?: CountryDto
  /** User country code */
  userCountry?: string
  /**A Function that runs when user clicks on the page*/
  handlePageClicked: (selectedPageIndex: number) => void
  /**A Function that runs when user selects tab*/
  handleTabSelected: (selectedTabIndex: number) => void
  /**A Function that runs when user make column sorting*/
  handleToggleSort: (selectedColumn: ProgramColumn) => void
  /**A Function that runs when user click on the table row*/
  handleRowClicked: (selectedProgramId: string) => void
  /**A Function that runs when user toggles in the table row*/
  handleToggleOrderRow: (programId: number) => void
  /**A Function that runs when user change a page size*/
  handlePageSizeChange: (pageSize: number) => void
  /** When a search query is entered and ENTER pressed */
  handleOnSearch?: (query: string) => void
}

export const AccessPrograms: FunctionComponent<IAccessProgramsProps> = ({
  isLoading,
  selectedTabIndex,
  programs,
  pagination,
  selectedSortColumn,
  selectedSortDirection,
  isMaUser,
  supportContact,
  userCountry,
  handlePageClicked,
  handleTabSelected,
  handleToggleSort,
  handleRowClicked,
  handleToggleOrderRow,
  handlePageSizeChange,
  handleOnSearch
}) => {
  const { t } = useTranslation()
  
  return (
    <ClinPageContentFrame
      className="access-programs"
      crumbs={[
        { path: '/orders', name: t('orders:page_name') },
        { path: '/access-programs', name: t('access_programs:title') }
      ]}
    >
      <Row>
        <Col>
          <ClinText
            as="h1"
            variant={TypographyVariant.H2}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {t('access_programs:title')}
          </ClinText>
          <ClinSpacer height={ClinTheme.space[3]} />
          <ClinAccordion>
            <ClinAccordionItem title={t('access_programs:accordion_title')}>
              <p>{t('access_programs:accordion_description_part_one')}</p>
              <p>{t('access_programs:accordion_description_part_two')}</p>
              <p>{t('access_programs:accordion_description_part_three')}</p>
            </ClinAccordionItem>
          </ClinAccordion>
          <ClinSpacer height={ClinTheme.space[4] * 2} />
          <ProgramsTable
            isLoading={isLoading}
            programs={programs}
            columns={programColumns}
            selectedSortColumn={selectedSortColumn}
            selectedSortDirection={selectedSortDirection}
            selectedTabIndex={selectedTabIndex}
            isMaUser={isMaUser}
            supportContact={supportContact}
            handleToggleSort={(columnName) => {
              const column = programColumns.find((item) => {
                return item.viewName === columnName
              })
              column && handleToggleSort(column)
            }}
            handleRowClicked={(orderId) => handleRowClicked(orderId)}
            handleTabSelected={(tabIndex) => handleTabSelected(tabIndex)}
            handleToggleOrderRow={(programId) =>
              handleToggleOrderRow(programId)
            }
            handleOnSearch={handleOnSearch}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {!isLoading &&
            programs &&
            programs.length > 0 &&
            pagination &&
            pagination.total > 0 && (
              <ClinPagination
                currentPage={getCurrentPage(pagination)}
                totalPages={getTotalPages(pagination)}
                pageSize={pagination.take}
                handlePageSelected={(pageIndex) => handlePageClicked(pageIndex)}
                handlePageSizeChange={handlePageSizeChange}
              />
            )}
        </Col>
      </Row>
    </ClinPageContentFrame>
  )
}
