import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'

export interface ICheckboxStyles {
  /** Disables the input */
  disabled?: boolean
  /** Indeterminate status for checkbox */
  indeterminate?: boolean
  checkBoxWidth?: number
  checkBoxHeight?: number
  borderRadius?: string
  marginRight?: string
  zIndex?: string
  shadow?: string
}

export const StyledCheckMark = styled.span<ICheckboxStyles>(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ClinTheme.colors.white,
    borderColor: ClinTheme.colors.deepGrey,
    borderStyle: 'solid',
    borderWidth: ClinTheme.borderWidths[0],
    transition: '.2s linear box-shadow, .2s linear background-color'
  },
  ({ checkBoxWidth, checkBoxHeight, borderRadius, marginRight, shadow }) => ({
    minWidth: checkBoxWidth || checkBoxHeight ? undefined : ClinTheme.space[4],
    width: checkBoxWidth ?? ClinTheme.space[4],
    height: checkBoxHeight ?? ClinTheme.space[4],
    borderRadius: borderRadius ?? ClinTheme.radii[2],
    marginRight: marginRight ?? ClinTheme.space[2],
    boxShadow: shadow ?? 'unset'
  })
)

export const StyledLabel = styled.label<ICheckboxStyles>(
  {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    userSelect: 'none',
    // match styles.css from storybook, othewise content under checkbox moves around when toggled
    marginBottom: '0.5em'
  },
  ({ disabled }) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    [` > ${StyledCheckMark}`]: {
      backgroundColor: disabled
        ? ClinTheme.colors.lightGrey
        : ClinTheme.colors.white
    },
    [`:hover > ${StyledCheckMark}`]: {
      backgroundColor: disabled
        ? ClinTheme.colors.lightGrey
        : ClinTheme.colors.white
    }
  }),
  ({ zIndex }) => ({
    zIndex: zIndex ?? 'inherit'
  })
)

export const StyledCheckbox = styled.input<ICheckboxStyles>(
  {
    position: 'absolute',
    opacity: 0,

    [`:checked + ${StyledCheckMark}, :indeterminate + ${StyledCheckMark}`]: {
      backgroundColor: ClinTheme.colors.primaryMid,
      borderColor: ClinTheme.colors.primaryMid
    },

    [`:indeterminate + ${StyledCheckMark} > .clin-checkbox-indeterminate`]: {
      display: 'block'
    },

    [`:not(:indeterminate) + ${StyledCheckMark} > .clin-checkbox-indeterminate`]:
      {
        display: 'none'
      },

    [`:checked + ${StyledCheckMark} > .clin-checkbox-unchecked`]: {
      display: 'none'
    },

    [`:not(:checked) + ${StyledCheckMark} > .clin-checkbox-checked`]: {
      display: 'none'
    },

    [`:not(:checked) + ${StyledCheckMark} > .clin-checkbox-unchecked`]: {
      display: 'block'
    },

    [`:focus: enabled + ${StyledCheckMark}, :active: enabled + ${StyledCheckMark}`]:
      {
        boxShadow: `0px 0px 4px 0px rgba(80, 30, 206, 0.48)`,
        border: `1px solid ${ClinTheme.colors.primaryMid}`
      }
  },
  ({ disabled }) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',

    [`:hover + ${StyledCheckMark}`]: {
      boxShadow: disabled ? '' : `0px 0px 4px 0px rgba(80, 30, 206, 0.48)`
    }
  })
)
