import { transparentize } from 'polished'
import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { StyledGroup } from '../ClinGroup/ClinGroup.styles'

export interface IClinCardStyles {
  /** Set a display property */
  display?: 'block' | 'inline-block' | 'flex' | 'inline-flex'
  /** Set a width */
  width?: string | number
  /** Set a bottom margin */
  marginBottom?: string | number
  /** Set a left margin */
  marginLeft?: string | number
  /** Whether card is selectable - displays a drop shadow and can be tabbed */
  isSelectable?: boolean
  /** Whether we can remove padding */
  removePadding?: boolean
  borderTop?: string
  borderBottom?: string
  borderTopLeftRadius?: number
  borderTopRightRadius?: number
  borderBottomLeftRadius?: number
  borderBottomRightRadius?: number
}

export const StyledClinCard = styled.div<IClinCardStyles>(
  {
    backgroundColor: ClinTheme.colors.white,
    borderRadius: ClinTheme.radii[2],
    border: `solid ${ClinTheme.borderWidths[0]}px ${ClinTheme.colors.grey}`,
    outline: 'none'
  },
  ({
    width = 'initial',
    marginLeft = 0,
    marginBottom = ClinTheme.space[4],
    display = 'block',
    isSelectable,
    removePadding,
    borderTop,
    borderBottom,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomLeftRadius,
    borderBottomRightRadius
  }: IClinCardStyles) => ({
    position: 'relative',
    padding: removePadding
      ? 0
      : `${ClinTheme.space[4]}px ${ClinTheme.space[5]}px`,
    display,
    width,
    marginLeft,
    marginBottom,
    borderBottom,
    borderTop: borderTop,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    cursor: isSelectable ? 'pointer' : 'default',
    boxShadow: isSelectable
      ? `0px 8px 16px 0px rgba(0, 0, 0, 0.16)`
      : 'initial',
    [`:focus, :active`]: {
      boxShadow: isSelectable
        ? `0px 4px 16px 0px rgba(114, 57, 255, 0.16)`
        : 'initial',
      borderColor: isSelectable
        ? ClinTheme.colors.primaryMid
        : ClinTheme.colors.grey
    }
  })
)

export const StyledDeletedOverlayOuter = styled.div({
  background: transparentize(0.1)(ClinTheme.colors.white),
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 1
})

export const StyledDeletedOverlayInner = styled.div({
  background: transparentize(0.85)(ClinTheme.colors.primaryLightest),
  width: '100%',
  height: '100%',
  display: 'flex',
  [StyledGroup]: {
    alignItems: 'center'
  }
})
