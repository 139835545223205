import { transparentize } from 'polished'
import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'
import { StyledButtonIcon } from '../ClinButtonIcon/ClinButtonIcon.styles'
import { ClinCard } from '../ClinCard'

const SmallUpBreakpoint = ClinTheme.breakpoints[0]

export const StyledRightContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: ClinTheme.space[3],
  backgroundColor: ClinTheme.colors.lightGrey,
  width: '100%'
})

export const StyledLeftContainer = styled.div({
  padding: ClinTheme.space[3],

  [mediaQuery(SmallUpBreakpoint)]: {
    padding: ClinTheme.space[4]
  }
})

export const StyledClinTwoColumnCard = styled(ClinCard)({
  borderRadius: ClinTheme.radii[2],
  border: `solid ${ClinTheme.borderWidths[0]}px ${ClinTheme.colors.grey}`,
  boxShadow: `0px 0px 8px 0px rgba(0, 0, 0, 0.16)`,
  transition: '.2s linear box-shadow, .2s linear border',
  [`:hover`]: {
    border: `solid ${ClinTheme.borderWidths[0]}px ${ClinTheme.colors.primaryMid}`,
    boxShadow: `0px 4px 8px 0px rgba(114, 57, 255, 0.16), 0px 0px 8px 0px rgba(114, 57, 255, 0.16)`,
    [StyledRightContainer]: {
      transition: '.2s linear background-color',
      backgroundColor: transparentize(0.85, ClinTheme.colors.primaryLightest)
    }
  },
  [`.last-col .clin-icon-value-container:last-of-type`]: {
    borderBottom: 'none'
  },
  [StyledButtonIcon]: {
    float: 'right'
  }
})

export const StyledSelectContainer = styled.div({
  [`.clin-text`]: {
    display: 'flex',
    alignItems: 'center'
  },
  alignSelf: 'flex-end',
  marginTop: 'auto'
})

export const StyledCircle = styled.div({
  marginLeft: ClinTheme.space[2],
  display: 'inline-flex',
  flex: `0 0 ${ClinTheme.space[5]}px`,
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: ClinTheme.space[5],
  height: ClinTheme.space[5],
  lineHeight: `${ClinTheme.space[5]}px`,
  borderRadius: '50%',
  backgroundColor: ClinTheme.colors.primaryLight
})
