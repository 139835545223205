import { rgba } from 'polished'
import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'

const largeUpBreakpoint = ClinTheme.breakpoints[2]

export const StyledCheckoutOrderItem = styled.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  borderBottom: `1px solid ${ClinTheme.colors.grey}`,
  [mediaQuery(largeUpBreakpoint)]: {
    flexDirection: 'row'
  }
})

export const StyledCheckoutOrderItemDetails = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: '0 0 100%',
  padding: ClinTheme.space[4],
  boxSizing: 'border-box',
  [mediaQuery(largeUpBreakpoint)]: {
    flex: '0 0 60%'
  }
})

export const StyledCheckoutSidebar = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: '0 0 100%',
  padding: ClinTheme.space[4],
  borderTop: `1px solid ${ClinTheme.colors.grey}`,
  background: ClinTheme.colors.lightGrey,
  boxSizing: 'border-box',
  [mediaQuery(largeUpBreakpoint)]: {
    borderTop: 'none',
    flex: '0 0 40%',
    borderLeft: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`
  }
})

// export const StyledCheckoutDelete = styled.div({
//   display: 'flex',
//   width: '100%',
//   justifyContent: 'flex-end',
//   alignSelf: 'flex-start'
// })

export const StyledCheckoutValue = styled.div({
  padding: `${ClinTheme.space[4]}px 0`,
  borderBottom: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`
})

export const StyledCheckoutValues = styled.div({
  marginTop: 'auto',
  width: '100%',
  alignSelf: 'flex-end',
  [`${StyledCheckoutValue}:last-of-type`]: {
    borderBottom: 'none',
    paddingBottom: 0
  }
})

export const StyledDeletedOverlay = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: rgba(ClinTheme.colors.primary, 0.8),
  zIndex: 1
})

export const StyledDeletedOverlayInner = styled.div({
  display: 'flex',
  alignItems: 'center',

  '.clin-text': {
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1
  }
})

export const StyledCheckoutOrderItemProperties = styled.div({
  marginTop: ClinTheme.space[2]
})

export const StyledCheckoutProperty = styled.div({
  display: 'flex',
  marginBottom: ClinTheme.space[3]
})

export const StyledCheckoutPropertyDetail = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: ClinTheme.space[1],
  marginLeft: ClinTheme.space[2]
})
