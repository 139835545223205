import React, { FunctionComponent, useState } from 'react'
import { StyledFilterGroup, StyledFilterHeader } from './FilterGroup.styles'
import { ChipState } from '../../components/ClinChip/ClinChip.styles'
import { ClinChip } from '../../components/ClinChip'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { FacetDto } from '../../types/swaggerTypes'
import { useTranslation } from 'react-i18next'

export interface IFilterGroupProps {
  /** The title of this filter section */
  title: string
  /** The title of the filter section when there are filters selected */
  titleSelected?: string
  /** The filters to pass in */
  filters?: FacetDto[]
  /** The selected filters */
  selectedFilters?: FacetDto[]
  /** If all filters are extended */
  showAll?: boolean
  /** The minimum number of filters to show */
  minFilters?: number
  /** Callback when a filter is selected */
  handleSelectFilter?: (filter: FacetDto) => void
}

export const FilterGroup: FunctionComponent<IFilterGroupProps> = ({
  filters = [],
  title = 'Filter Title',
  titleSelected = 'Filters selected',
  selectedFilters = [],
  showAll = false,
  minFilters = 5,
  handleSelectFilter
}) => {
  const { t } = useTranslation()
  const [isShowingAll, setIsShowingAll] = useState<boolean>(showAll)

  return (
    <>
      {filters && filters.length > 0 && (
        <StyledFilterGroup className={'clin-filter-group'}>
          <StyledFilterHeader>
            <ClinText
              as="div"
              className={'clin-filter-group-title'}
              variant={TypographyVariant.Paragraph}
              fontWeight={ClinTheme.fontWeights.medium}
              textTransform="uppercase"
              color={ClinTheme.colors.primary}
            >
              {selectedFilters?.length === 0 ? title : titleSelected}
            </ClinText>
          </StyledFilterHeader>
          {filters.map((filter, index) => {
            if (!isShowingAll && index >= minFilters) {
              return null
            }
            return (
              <ClinChip
                key={`filter-${index}`}
                state={
                  selectedFilters &&
                  selectedFilters.findIndex((i) => i.value === filter.value) >
                    -1
                    ? ChipState.Active
                    : ChipState.Default
                }
                isInteractive={true}
                onClick={() => handleSelectFilter && handleSelectFilter(filter)}
              >
                {filter.value}
              </ClinChip>
            )
          })}
          {filters.length > minFilters && (
            <ClinButton
              variant="linkButton"
              onClick={() => setIsShowingAll(!isShowingAll)}
            >
              {isShowingAll
                ? t('product_catalogue:filter.see_less')
                : t('product_catalogue:filter.see_more')}
              &nbsp;
              <ClinIcon
                iconName={
                  isShowingAll
                    ? ClinIconPathName.ChevronSmallUp
                    : ClinIconPathName.ChevronSmallDown
                }
              />
            </ClinButton>
          )}
        </StyledFilterGroup>
      )}
    </>
  )
}
