import React, { FunctionComponent } from 'react'
import {
  StyledClinTextInput,
  StyledInputWrapper,
  StyledLabel
} from './ClinTextInput.styles'
import { IDefaultStyleProps } from '../../types'
import { ClinText } from '../ClinText'
import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { isMobile } from '../../utils/isMobile'
import { useLayoutResize } from '../../utils/useLayoutResize'

/**
 * Export version with forward ref (for use with react-hook-forms)
 */
export const ClinTextInput = React.forwardRef<
  HTMLInputElement,
  ITextInputProps
>((props: ITextInputProps, ref) => {
  return <ClinTextInputBase inputRef={ref} {...props} />
})

export interface ITextInputStyles
  extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Sets field styles to error */
  hasError?: boolean
  /** Sets field styles to valid */
  isValid?: boolean
  /** Disables the input */
  disabled?: boolean
  /** Css width value */
  width?: string | number
  /** Css margin bottom value for responsive */
  marginBottom?: string
  placeholderColor?: string
}

interface ITextInputProps extends IDefaultStyleProps, ITextInputStyles {
  /** HTML id attribute */
  id: string
  /* Name attribute of the `input` element. */
  name: string
  /** The label to display above the input */
  label?: string
  /** The label to display below the input */
  prompt?: string | React.ReactElement
  /** Sets the html type */
  type?: 'text' | 'email' | 'password' | 'tel' | string
  /** Sets the autocomplete value */
  autoComplete?: 'on' | 'off' | string
  /** Sets the value of the text input */
  value?: string | number | string[]
  /** Automatically sets the focus state */
  autoFocus?: boolean
  /** Placeholder copy to display */
  placeholder?: string
  /** Placeholder copy to display for mobile devices */
  placeholderForMobile?: string
  placeholderColor?: string
  /**  Pass a ref to the `input` element */
  inputRef?: React.Ref<HTMLInputElement>
  /** Function that takes an event and returns nothing */
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  /** Function that takes an event and returns nothing */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  /** Function that takes an event and returns nothing */
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  /** Function that takes an event and returns nothing */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
}

const ClinTextInputBase: FunctionComponent<ITextInputProps> = ({
  className = '',
  id,
  name,
  label,
  prompt,
  type = 'text',
  value,
  autoComplete = 'off',
  autoFocus = false,
  disabled = false,
  width = 268,
  marginBottom,
  placeholder,
  placeholderForMobile,
  placeholderColor,
  hasError = false,
  isValid,
  inputRef,
  onChange,
  onKeyDown,
  onFocus,
  onBlur,
  ...props
}) => {
  const getPlaceholder = (): string | undefined =>
    isMobile() ? placeholderForMobile ?? placeholder : placeholder
  const actualPlaceholder = useLayoutResize<string | undefined>(getPlaceholder)

  return (
    <StyledLabel
      tabIndex={-1}
      className={`clin-text-input ${className}`}
      title={id}
      htmlFor={id}
      disabled={disabled}
      isValid={isValid}
      hasError={hasError}
      width={width}
      marginBottom={marginBottom}
    >
      {label && (
        <ClinText
          as="div"
          fontSize={ClinTheme.fontSizes[1]}
          color={disabled ? ClinTheme.colors.grey : 'initial'}
          marginBottom={ClinTheme.space[2]}
        >
          {label}
        </ClinText>
      )}
      <StyledInputWrapper>
        <StyledClinTextInput
          {...props}
          width={width}
          id={id}
          ref={inputRef}
          type={type}
          name={name}
          value={value}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          disabled={disabled}
          placeholder={actualPlaceholder}
          placeholderColor={placeholderColor}
          isValid={isValid}
          hasError={hasError}
          onChange={(event) => onChange && onChange(event)}
          onKeyDown={(event) => onKeyDown && onKeyDown(event)}
          onFocus={(event) => onFocus && onFocus(event)}
          onBlur={(event) => onBlur && onBlur(event)}
        />
        {(hasError && type !== 'number') || (isValid && type !== 'number') ? (
          <ClinIcon
            style={{
              position: 'absolute',
              right: ClinTheme.space[2],
              top: '50%',
              transform: 'translateY(-50%)'
            }}
            iconName={hasError ? ClinIconPathName.Cross : ClinIconPathName.Tick}
            iconFill={
              hasError
                ? ClinTheme.colors.redInvalid
                : ClinTheme.colors.greenValid
            }
          />
        ) : null}
      </StyledInputWrapper>
      {prompt && !disabled && (
        <ClinText
          as="div"
          fontSize={ClinTheme.fontSizes[1]}
          color={hasError ? ClinTheme.colors.redInvalid : 'inherit'}
          marginTop={ClinTheme.space[2]}
        >
          {prompt}
        </ClinText>
      )}
    </StyledLabel>
  )
}
