import React, { FunctionComponent } from 'react'
import { ClinTheme } from '../../../ClinTheme'
import { Col, Row } from 'react-grid-system'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinText } from '../../../components/ClinText'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import {
  StyledTransferButton,
  StyledCustomGreenBadge
} from '../styles/TransferPatient.styles'
import { useTranslation } from 'react-i18next'

interface ISuccessScreenProps {
  /** Called when user click on View Patient details */
  handleViewPatientDetails: () => void
}

export const SuccessScreen: FunctionComponent<ISuccessScreenProps> = ({
  handleViewPatientDetails
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Row className="success-screen">
        <Col sm={12} md={7}>
          <ClinSpacer height={ClinTheme.space[4]} />
          <ClinGroup>
            <StyledCustomGreenBadge>
              <ClinIcon
                iconName={ClinIconPathName.ArrowRight}
                iconFill={ClinTheme.colors.white}
                iconSize={ClinTheme.space[4]}
              />
            </StyledCustomGreenBadge>
            <ClinText
              as="h5"
              variant={TypographyVariant.H4}
              color={ClinTheme.colors.primary}
              fontWeight={ClinTheme.fontWeights.bold}
              marginBottom={ClinTheme.space[4]}
            >
              {t('transfer_patient_to_institute:success_title')}
            </ClinText>
          </ClinGroup>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[7]} />
      <Row>
        <Col sm={12} md={7}>
          <ClinSpacer height={ClinTheme.space[3]} />
          <ClinText
            variant={TypographyVariant.LargeParagraph}
            marginBottom={ClinTheme.space[4]}
          >
            {t('transfer_patient_to_institute:success_description')}
          </ClinText>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[7]} />
      <Row>
        <Col sm={12} md={4} lg={3}>
          <ClinGroup space={ClinTheme.space[3]}>
            <StyledTransferButton
              className="view-patient-details"
              variant="primary"
              justifyContent="space-around"
              onClick={() => handleViewPatientDetails()}
            >
              {t('transfer_patient_to_institute:view_patient_details_btn')}
            </StyledTransferButton>
          </ClinGroup>
        </Col>
      </Row>
    </>
  )
}
