import React, { FunctionComponent } from 'react'
import { IDefaultStyleProps } from '../../types'
import {
  ITextareaStyles,
  StyledLabel,
  StyledTextarea
} from './ClinTextarea.styles'
import { ClinText } from '../ClinText'
import { ClinTheme } from '../../ClinTheme'

interface ITextareaProps
  extends IDefaultStyleProps,
    ITextareaStyles,
    React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  /** HTML id attribute */
  id: string
  /** The label to display above the textarea */
  label?: string
  /** The label to display below the input */
  prompt?: string
  /** Sets the value of the text */
  value?: string
  /** Automatically sets the focus state */
  autoFocus?: boolean
  /** Placeholder copy to display */
  placeholder?: string
  /** Sets the rows value of the textarea */
  rows?: number
  /**  Pass a ref to the `input` element */
  inputRef?: React.Ref<HTMLTextAreaElement>
  /** Function that takes an event and returns nothing */
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  /** Function that takes an event and returns nothing */
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
  /** Function that takes an event and returns nothing */
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void
}

export const ClinTextarea: FunctionComponent<ITextareaProps> = ({
  className = '',
  id,
  label,
  prompt,
  value,
  autoFocus = false,
  placeholder,
  rows = 5,
  height,
  inputRef,
  onChange,
  onFocus,
  onBlur,
  hasError = false,
  disabled
}) => (
  <StyledLabel
    className={`clin-textarea-label ${className}`}
    title={id}
    htmlFor={id}
    hasError={hasError}
    disabled={disabled}
  >
    {label && (
      <ClinText
        as="div"
        fontSize={ClinTheme.fontSizes[1]}
        color={disabled ? ClinTheme.colors.grey : 'inherit'}
        marginBottom={ClinTheme.space[2]}
      >
        {label}
      </ClinText>
    )}
    <StyledTextarea
      className={`clin-textarea ${className}`}
      id={id}
      name={id}
      value={value}
      autoFocus={autoFocus}
      disabled={disabled}
      placeholder={placeholder}
      rows={rows}
      height={height}
      hasError={hasError}
      ref={inputRef}
      onChange={(event: any) => onChange && onChange(event)}
      onFocus={(event: any) => onFocus && onFocus(event)}
      onBlur={(event: any) => onBlur && onBlur(event)}
    />
    {prompt && !disabled && (
      <ClinText
        as="div"
        className="clin-prompt"
        fontSize={ClinTheme.fontSizes[1]}
        color={hasError ? ClinTheme.colors.redInvalid : 'inherit'}
        marginTop={ClinTheme.space[2]}
      >
        {prompt}
      </ClinText>
    )}
  </StyledLabel>
)
