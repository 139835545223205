import styled from 'styled-components'
import { ClinTheme } from '../../../../ClinTheme'

interface IStyledUnenrolledProgramsWrapperProps {
  paddintTop?: string
  paddingBottom?: string
}

export const StyledUnenrolledProgramsInfoWrapper =
  styled.div<IStyledUnenrolledProgramsWrapperProps>(
    {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      paddingLeft: '40px',
      paddingRight: '40px',
      backgroundColor: ClinTheme.colors.white,
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px'
    },
    ({ paddintTop, paddingBottom }) => ({
      paddingTop: paddintTop ? paddintTop : '40px',
      paddingBottom: paddingBottom ? paddingBottom : '48px'
    })
  )
