import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery, mediaQueryDown } from '../../utils/mediaQuery'

const desktopDownBreakpoint = ClinTheme.breakpoints[3]
const tabletDownBreakpoint = ClinTheme.breakpoints[2]
const mobileDownBreakpoint = ClinTheme.breakpoints[1]

export const StyledFooter = styled.div({
  color: ClinTheme.colors.primary,
  background: 'transparent',
  position: 'relative',
  bottom: '0',
  [`@media print`]: {
    display: 'none'
  }
})

export const StyledFooterLink = styled(Link)({
  display: 'inline-block',
  color: ClinTheme.colors.primary,
  fontSize: ClinTheme.fontSizes[0],
  marginRight: ClinTheme.space[3],
  marginBottom: ClinTheme.space[2],
  lineHeight: '2em',
  [`:hover`]: {
    textDecoration: 'underline',
    color: ClinTheme.colors.white
  }
})

export const StyledFooterExternalLink = styled.a({
  color: ClinTheme.colors.primary,
  fontSize: ClinTheme.fontSizes[1],
  marginRight: '37px',
  marginBottom: ClinTheme.space[2],
  lineHeight: ClinTheme.lineHeights.heading[0],
  [`:hover`]: {
    textDecoration: 'underline',
    color: ClinTheme.colors.primary
  }
})

export const StyledFooterLinks = styled.div({
  display: 'inline-flex',
  flexWrap: 'wrap'
})

export const StyledCopyright = styled.div({
  color: ClinTheme.colors.primary,
  fontSize: ClinTheme.fontSizes[1],
  letterSpacing: ClinTheme.letterSpacing[1],
  lineHeight: ClinTheme.lineHeights.heading[0],
  marginBottom: ClinTheme.space[2]
})

export const StyledRow = styled.div({
  display: 'flex',
  flexWrap: 'wrap'
})

export const StyledColumn = styled.div({
  marginBottom: ClinTheme.space[5],
  '&:not(:last-child)': {
    [mediaQuery(desktopDownBreakpoint)]: {
      minWidth: 250
    },
    [mediaQueryDown(tabletDownBreakpoint)]: {
      minWidth: 220
    },
    [mediaQueryDown(mobileDownBreakpoint)]: {
      minWidth: '100%'
    }
  },
  '&:last-child': {
    marginLeft: 'auto'
  },
  [mediaQueryDown(tabletDownBreakpoint)]: {
    '&:last-child': {
      marginLeft: 0,
      width: '100%'
    }
  }
})

export const StyledContact = styled.div({
  marginBottom: ClinTheme.space[2],
  [`.clin-text`]: {
    marginLeft: ClinTheme.space[2]
  },
  '.clin-icon': {
    verticalAlign: 'middle'
  }
})

export const StyledFooterNavigation = styled.footer({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '27px'
})
