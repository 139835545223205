import axios from 'axios'
import download from 'downloadjs'
import config from '../../services/ConfigProvider'

export const downloadSquidexFile = (
  documentId: string,
  fileName: string,
  fileType: string,
  handleDownloadComplete: () => void
) => {
  axios
    .get(
      `${config.cmsRoot}/api/assets/${config.squidexRestfulInstanceName}/${documentId}`
    )
    .then((response) => {
      download(response.data, fileName, fileType)
    })
    .then(() => new Promise((resolve) => setTimeout(resolve, 1000)))
    .catch((error) => {
      console.warn(error)
    })
    .finally(() => handleDownloadComplete())
}
