import styled from 'styled-components'

import { ClinTheme } from '../../ClinTheme'

import { IRadioStyles } from './ClinRadio'

export const StyledRadioMark = styled.span<IRadioStyles>(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: ClinTheme.space[4],
    height: ClinTheme.space[4],
    flexShrink: 0,
    borderColor: ClinTheme.colors.grey,
    borderWidth: ClinTheme.borderWidths[0],
    backgroundColor: ClinTheme.colors.white,
    borderStyle: 'solid',
    borderRadius: `50%`,
    marginRight: ClinTheme.space[3],
    transition: '.2s linear background-color',
    ':before': {
      content: `""`,
      display: 'block',
      width: ClinTheme.space[3],
      height: ClinTheme.space[3],
      borderRadius: `50%`,
      backgroundColor: ClinTheme.colors.primaryMid
    }
  },
  ({ disabled }) => ({
    backgroundColor: disabled ? ClinTheme.colors.lightGrey : '',
    ':before': {
      backgroundColor: disabled
        ? ClinTheme.colors.lightGrey
        : ClinTheme.colors.primaryMid
    }
  })
)

export const StyledLabel = styled.label<IRadioStyles>(
  {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    userSelect: 'none',
    color: ClinTheme.colors.black,
    [`:focus > ${StyledRadioMark}`]: {
      backgroundColor: ClinTheme.colors.primaryMid
    }
  },
  ({ disabled }) => ({
    cursor: disabled ? 'not-allowed' : 'pointer',
    [`:hover > ${StyledRadioMark}`]: {
      backgroundColor: disabled ? '' : ClinTheme.colors.lightGrey
    }
  })
)

export const StyledRadio = styled.input<IRadioStyles>(
  {
    position: 'absolute',
    opacity: 0,
    left: -9999,

    [`:enabled:not(:checked) + ${StyledRadioMark}`]: {
      ':before': {
        backgroundColor: ClinTheme.colors.white
      }
    },

    [`:checked + ${StyledRadioMark}`]: {
      borderColor: ClinTheme.colors.primaryMid
    },

    [`:focus: enabled + ${StyledRadioMark}, :active: enabled + ${StyledRadioMark}`]:
      {
        // boxShadow: `0 0 0 2px ${ClinTheme.colors.midGrey}`,
        borderColor: ClinTheme.colors.primaryMid
      }
  },
  ({ disabled }) => ({
    cursor: disabled ? 'not-allowed' : 'pointer'
  })
)
