/**
 * AnalyticsPageEvent
 *
 * Provide as many custom page events names
 */

export enum AnalyticsPageEvent {
  ViewProduct = 'View Product',
  ViewProductDetails = 'View Product Details',
  ViewProgramCatalogue = 'View Program Catalogue',
  ViewOPACheckout = 'View OPA Checkout',
  ViewMyPhysicians = 'View My Physicians',
  ViewOrder = 'View Order',
  ViewDashboard = 'View Dashboard',
  ViewPAF = 'View PAF',
  ViewPatient = 'View Patient',
  ViewPhysician = 'View Physician',
  ViewProductCatalogue = 'View Product Catalogue',
  ViewProgram = 'View Program'
}
