import * as React from 'react'
import { ClinText } from '../ClinText'
import { ClinTheme } from '../../ClinTheme'

export interface IAppInformationProps {
  /** The name of the app */
  name: string
  /** The version number of the app */
  version: string
  /** A field for supplying the build number or build date etc. */
  buildInfo?: string
  /** The version number of component library */
  componentLibraryVersion?: string
  /** Renders version numbers to console  */
  logToConsole?: boolean
  /** Renders version numbers to dom node (text color is white) */
  renderInline?: boolean
}

export const AppInformation: React.FC<IAppInformationProps> = ({
  name,
  version,
  buildInfo,
  componentLibraryVersion,
  logToConsole = true,
  renderInline = false
}: IAppInformationProps) => {
  let compiledString = [] as string[]

  if (name) {
    compiledString.push(`name: "${name}"`)
  }

  if (version) {
    compiledString.push(` version: "${version}"`)
  }

  if (buildInfo) {
    compiledString.push(` build: "${buildInfo}"`)
  }

  if (componentLibraryVersion) {
    compiledString.push(` CL version: "${componentLibraryVersion}"`)
  }

  if (logToConsole) {
    /* eslint-disable */
    console.log(compiledString.toString())
    /* eslint-enable */
  }

  return !renderInline ? null : (
    <ClinText
      as="div"
      color={ClinTheme.colors.white}
      textAlign="right"
      className="version-information"
    >
      {compiledString.toString()}
    </ClinText>
  )
}
