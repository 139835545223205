import { TFunction } from 'i18next'

/**
 * A reference to the loaded i18n instance allowing
 * the TFunction to be used outside react components
 */
export class i18nRef {
  static isLoaded: boolean
  static transFn: TFunction
}
