import React, { ChangeEvent, FunctionComponent } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import { ClinAnnounceBar } from '../../components/ClinAnnounceBar'
import { ClinButton } from '../../components/ClinButton'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinLoader } from '../../components/ClinLoader'
import { ClinModal } from '../../components/ClinModal'
import { ClinRadio } from '../../components/ClinRadio'
import { ClinSpinner } from '../../components/ClinSpinner'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { LightweightInstituteDto } from '../../types/swaggerTypes'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import {
  StyledInstitutes,
  StyledInstitute,
  StyledSubmit,
  StyledLoaderContainer,
  StyledActions,
  StyledAccessCta,
  StyledAccessCtaInline,
  StyleRequestAccessCta,
  StyleRequestAccessCtaMessage
} from './InstituteModal.styles'
import { ClinSpacer } from '../../components/ClinSpacer'
import { useTranslation } from 'react-i18next'

export enum ModalViewMode {
  Loading,
  ShowInstitutes,
  Submitting
}

interface IInstituteModalProps {
  /** Control internal state of modal */
  isOpen?: boolean
  /** view mode */
  viewMode: ModalViewMode
  /** displays error notification */
  hasError?: boolean
  /** displays error notification */
  errorMessage?: string
  /** The current institute   */
  currentInstitute: LightweightInstituteDto | null | undefined
  /** The institute which is selected in ui  */
  selectedInstitute?: LightweightInstituteDto | null
  /** An array of available institutes  */
  institutesList?: LightweightInstituteDto[] | null
  /** Whether the user is accessing the app for the first time  */
  isFirstAccess: boolean
  /** Handle change institution selection  */
  handleChange: (id: string) => void
  /** Handle submit institution selection  */
  handleSubmit: () => void
  /** Handle close institution selection  */
  handleClose: () => void
  /** Handle call to request access to another institute */
  handleRequestAccess: () => void
}

export const InstituteModal: FunctionComponent<IInstituteModalProps> = ({
  isOpen,
  viewMode,
  hasError,
  errorMessage,
  currentInstitute,
  selectedInstitute,
  institutesList,
  isFirstAccess,
  handleChange,
  handleSubmit,
  handleClose,
  handleRequestAccess
}) => {
  const { t } = useTranslation()

  return (
    <>
      <ClinModal
        isDismissible={
          currentInstitute !== null && viewMode === ModalViewMode.ShowInstitutes
        }
        onClose={handleClose}
        maxWidth="sm"
        height="auto"
        className="tooltipTarget"
        isOpen={isOpen}
      >
        {viewMode === ModalViewMode.Loading && (
          <Container>
            <Row justify="center" align="center">
              <Col sm={12}>
                <ClinGroup justifyContent="center">
                  <ClinSpinner />
                </ClinGroup>
              </Col>
            </Row>
          </Container>
        )}
        {viewMode === ModalViewMode.ShowInstitutes && (
          <Container>
            <Row justify="center">
              <Col sm={12} md={11}>
                {errorMessage && hasError && (
                  <Row>
                    <Col>
                      <ClinAnnounceBar title={'Error'}>
                        {errorMessage}
                      </ClinAnnounceBar>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col sm={12} md={11}>
                    <ClinText
                      as="h3"
                      variant={TypographyVariant.H3}
                      fontWeight={ClinTheme.fontWeights.bold}
                    >
                      {t('institute_details:switch_institute_modal_title')}
                    </ClinText>
                    <ClinText variant={TypographyVariant.LargeParagraph}>
                      {t(
                        'institute_details:switch_institute_modal_description'
                      )}
                    </ClinText>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <StyledInstitutes>
                      {institutesList &&
                        institutesList.map(
                          (item: LightweightInstituteDto, index: number) => (
                            <StyledInstitute key={item.instituteId}>
                              {currentInstitute?.instituteId ===
                                item.instituteId && (
                                <ClinText
                                  variant={TypographyVariant.LargeParagraph}
                                  marginLeft={ClinTheme.space[6]}
                                  marginBottom="0px"
                                  marginTop="0px"
                                  fontWeight={ClinTheme.fontWeights.bold}
                                >
                                  {t('institute_details:current').toUpperCase()}
                                </ClinText>
                              )}
                              <ClinRadio
                                color={ClinTheme.colors.primary}
                                label={item.instituteName}
                                id={item.instituteId}
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>
                                ) => handleChange(event.currentTarget.value)}
                                checked={
                                  selectedInstitute?.instituteId ===
                                  item.instituteId
                                }
                                value={item.instituteId}
                              >
                                <ClinText
                                  variant={TypographyVariant.LargeParagraph}
                                  fontWeight={ClinTheme.fontWeights.bold}
                                >
                                  {item.instituteName}
                                </ClinText>
                              </ClinRadio>
                            </StyledInstitute>
                          )
                        )}

                      <StyleRequestAccessCta>
                        <ClinIcon
                          iconSize={ClinTheme.fontSizes[5]}
                          iconFill={ClinTheme.colors.primaryMid}
                          iconName={ClinIconPathName.Marker}
                        />
                        <StyleRequestAccessCtaMessage
                          variant={TypographyVariant.LargeParagraph}
                        >
                          {t('institute_details:request_access_message')}{' '}
                          <StyledAccessCtaInline
                            variant={TypographyVariant.LargeParagraph}
                            onClick={() => handleRequestAccess()}
                            as="span"
                          >
                            {t('institute_details:request_access_btn')}
                          </StyledAccessCtaInline>
                        </StyleRequestAccessCtaMessage>
                        <StyledAccessCta
                          variant={TypographyVariant.LargeParagraph}
                          onClick={() => handleRequestAccess()}
                        >
                          {t('institute_details:request_access_btn')}
                        </StyledAccessCta>
                      </StyleRequestAccessCta>
                      <ClinSpacer hasBorder />
                    </StyledInstitutes>
                  </Col>
                </Row>
                <StyledSubmit>
                  <Row justify="between">
                    <Col md={5} lg={6}>
                      <StyledActions>
                        {!isFirstAccess && (
                          <ClinButton
                            className="cancel-institute"
                            onClick={() => handleClose()}
                          >
                            {t('common:buttons.cancel')}
                          </ClinButton>
                        )}

                        <ClinButton
                          className="submit-institute"
                          disabled={
                            !isFirstAccess &&
                            currentInstitute?.instituteId ===
                              selectedInstitute?.instituteId
                          }
                          variant="primary"
                          onClick={() =>
                            isFirstAccess &&
                            currentInstitute?.instituteId ===
                              selectedInstitute?.instituteId
                              ? handleClose()
                              : handleSubmit()
                          }
                        >
                          {isFirstAccess &&
                          currentInstitute?.instituteId ===
                            selectedInstitute?.instituteId
                            ? t('common:buttons.continue')
                            : t(
                                'institute_details:switch_to_this_institute_btn'
                              )}
                        </ClinButton>
                      </StyledActions>
                    </Col>
                  </Row>
                </StyledSubmit>
              </Col>
            </Row>
          </Container>
        )}
        {viewMode === ModalViewMode.Submitting && (
          <StyledLoaderContainer>
            <Container>
              <Row justify="center" align="center">
                <Col sm={12}>
                  <ClinText
                    marginLeft="auto"
                    marginRight="auto"
                    marginBottom={ClinTheme.space[5]}
                    textAlign="center"
                    variant={TypographyVariant.LargeParagraph}
                  >
                    {t('institute_details:please_wait')}
                  </ClinText>
                  <ClinGroup justifyContent="center">
                    <div
                      style={{
                        height: ClinTheme.space[9] * 2,
                        width: ClinTheme.space[9] * 2
                      }}
                    >
                      <ClinLoader minHandDuration={'1s'} />
                    </div>
                  </ClinGroup>
                </Col>
              </Row>
            </Container>
          </StyledLoaderContainer>
        )}
      </ClinModal>
    </>
  )
}
