import React, {
  ComponentType,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState
} from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router'
import authServiceInstance from '../../services/AuthService'

interface IPrivateRouteProps extends RouteProps {
  component: ComponentType<RouteComponentProps<any>> | ComponentType<any>
}

type RenderComponent = (props: RouteComponentProps<any>) => ReactNode

enum AuthState {
  LOADING,
  AUTHORISED,
  UNAUTHORISED
}

export const PrivateRoute: FunctionComponent<IPrivateRouteProps> = (
  props: IPrivateRouteProps
) => {
  const [authState, setAuthState] = useState<AuthState>(AuthState.LOADING)
  const { component: Component, ...rest }: IPrivateRouteProps = props
  const renderComponent: RenderComponent = (props) => <Component {...props} />

  useEffect(() => {
    authServiceInstance.getUser().then((user) => {
      setAuthState(
        user?.access_token ? AuthState.AUTHORISED : AuthState.UNAUTHORISED
      )
    })
  }, [props.path])

  if (authState === AuthState.LOADING) {
    return null
  }

  if (authState === AuthState.AUTHORISED) {
    return <Route {...rest} render={renderComponent} />
  }

  return <Redirect to="/login" />
}
