import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-grid-system'
import { Trans } from 'react-i18next'
import { ClinTheme } from '../../ClinTheme'
import { ClinAnnounceBar } from '../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinCheckbox } from '../../components/ClinCheckbox'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinSpacer } from '../../components/ClinSpacer'
import { StepState } from '../../components/ClinStepper/ClinStepper'
import { ClinTabHeader } from '../../components/ClinTabHeader'
import { ClinTabs } from '../../components/ClinTabs'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { PhysicianAutoSuggest } from '../../features/Search/PhysicianAutoSuggest'
import { PhysicianSearch } from '../../features/Search/PhysicianSearch'
import i18n from '../../i18n/config'
import { IIndexable } from '../../types'
import {
  PhysiciansSummaryDto,
  NewPhysicianEnrollDto,
  ProgramCatalogDto
} from '../../types/swaggerTypes'
import { EnrolUserOnToProgramSteps } from '../EnrolUserOnToProgram/EnrolUserOnToProgram.model'
import { EnrolPhysicianDetailsForm } from './EnrolPhysicianDetailsForm'
import {
  StyleAdditionalPhysicians,
  StyleAdditionalPhysician,
  StyleAdditionalPhysicianText,
  StyleAdditionalPhysicianAction
} from './EnrolPhysicianOnToProgram.styles'
import {
  StyleAdditionalPhysicianBadge,
  StyleAdditionalPhysicianTitle
} from '../EnrolUserOnToProgram/EnrolUserOnToProgram.styles'

export enum EnrolPhysicianOnToProgramStep {
  STEP1,
  STEP2
}

export const EnrolPhysicianOnToProgramSteps: IIndexable = {
  [EnrolPhysicianOnToProgramStep.STEP1]: [
    StepState.Active,
    StepState.InComplete,
    StepState.InComplete
  ],
  [EnrolPhysicianOnToProgramStep.STEP2]: [
    StepState.Complete,
    StepState.Active,
    StepState.InComplete
  ]
}

export const getViewForStepOne = (
  /** Index of the selected tab */
  selectedTabIndex: number,
  /** Called when the user selects a tab */
  handleTabSelected: (selectedTabIndex: number) => void,
  /** Call back to broadcast onClick */
  handleOnSelect: (suggestion: PhysiciansSummaryDto) => void,
  /** Call back request to add a new physician */
  handleRequestToAddPhysician: () => void,
  /** Call back request to remove a selected physician */
  handlePhysicianRemoval: (physicianId: number) => void,
  /** Save physician details */
  handleSavedPhysicianDetails: (physician: NewPhysicianEnrollDto) => void,
  /** Call back to remove a selected saved physician */
  handleSavedPhysicianRemoval: (email: string) => void,
  /** Handle whether the form is edited or not */
  handleIsFormEdited: (isEdited: boolean) => void,
  /** Selected physicians */
  selectedPhysicians?: PhysiciansSummaryDto[],
  /** Saved physicians */
  savedPhysicians?: NewPhysicianEnrollDto[],
  /** Program */
  program?: ProgramCatalogDto
): React.ReactNode => {
  const { t } = i18n
  return (
    <>
      <ClinSpacer height={ClinTheme.space[2]} />
      <Row className="step-one">
        <Col sm={12} md={10} lg={8}>
          <ClinAnnounceBar
            mode={AnnounceMode.Information}
            title={t('enrol_physician_on_to_program:announce_title')}
          >
            <Trans
              i18nKey="enrol_physician_on_to_program:announce_description"
              components={{ bold: <strong /> }}
            >
              <></>
            </Trans>
          </ClinAnnounceBar>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[6]} />
      <Row>
        <Col sm={12} md={10} lg={8}>
          <ClinTabs
            activeItemIndex={selectedTabIndex}
            handleSelected={handleTabSelected}
          >
            <ClinTabHeader
              title={t('enrol_physician_on_to_program:existing_physicians')}
            />
            <ClinTabHeader
              title={t('enrol_physician_on_to_program:create_new_physicians')}
            />
          </ClinTabs>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[6]} />
      {selectedTabIndex === 0 && (
        <Row>
          <Col sm={12} md={10} lg={8}>
            <ClinText marginBottom={ClinTheme.space[2]}>
              {t('enrol_physician_on_to_program:search_for_insitute')}
            </ClinText>
            <PhysicianAutoSuggest
              searchComponent={(props) => <PhysicianSearch {...props} />}
              handleOnSelect={handleOnSelect}
              handleRequestToAddPhysician={handleRequestToAddPhysician}
              searchNotEnrolled={true}
              programId={program?.projectId ? `${program.projectId}` : ''}
            />
          </Col>
        </Row>
      )}
      {selectedTabIndex === 1 && (
        <Row>
          <Col>
            <EnrolPhysicianDetailsForm
              handleSavedPhysicianDetails={handleSavedPhysicianDetails}
              handleIsFormEdited={handleIsFormEdited}
            />
          </Col>
        </Row>
      )}
      {selectedPhysicians && selectedPhysicians.length > 0 && (
        <Row>
          <Col sm={12} md={10} lg={8}>
            <ClinSpacer hasBorder height={0} />
            <ClinSpacer height={ClinTheme.space[4]} />

            <ClinText
              color={ClinTheme.colors.primary}
              fontSize={ClinTheme.fontSizes[3]}
              marginBottom={ClinTheme.space[4]}
            >
              {t('enrol_physician_on_to_program:additional_physicians')}
            </ClinText>
            <StyleAdditionalPhysicians>
              <StyleAdditionalPhysicianTitle>
                <ClinText
                  wordBreak="break-all"
                  variant={TypographyVariant.LargeParagraph}
                  fontWeight={ClinTheme.fontWeights.bold}
                >
                  {t('enrol_user_on_to_program:existing_physicians_title')}
                </ClinText>
                {program?.program?.autoEnrolmentFlag === 'Yes' &&
                selectedPhysicians ? (
                  <StyleAdditionalPhysicianBadge
                    style={{ backgroundColor: '#00A2231A' }}
                  >
                    <ClinText
                      wordBreak="break-all"
                      variant={TypographyVariant.Paragraph}
                      fontWeight={ClinTheme.fontWeights.medium}
                      color={ClinTheme.colors.greenValid}
                    >
                      {t(
                        'enrol_user_on_to_program:additional_physician_badge_text_eligible'
                      )}
                    </ClinText>
                  </StyleAdditionalPhysicianBadge>
                ) : (
                  <StyleAdditionalPhysicianBadge
                    style={{ backgroundColor: '#EEEDFF' }}
                  >
                    <ClinText
                      wordBreak="break-all"
                      variant={TypographyVariant.Paragraph}
                      fontWeight={ClinTheme.fontWeights.medium}
                      color={ClinTheme.colors.primaryLight}
                    >
                      {t(
                        'enrol_user_on_to_program:additional_physician_badge_text_verification'
                      )}
                    </ClinText>
                  </StyleAdditionalPhysicianBadge>
                )}
              </StyleAdditionalPhysicianTitle>
              {selectedPhysicians &&
                selectedPhysicians.map(
                  (physician: PhysiciansSummaryDto, index) => {
                    return (
                      <StyleAdditionalPhysician key={`physician-${index}`}>
                        <StyleAdditionalPhysicianText>
                          <ClinText
                            wordBreak="break-all"
                            variant={TypographyVariant.LargeParagraph}
                            fontWeight={ClinTheme.fontWeights.medium}
                          >
                            {`${physician?.physicianTitle ?? ''} ${
                              physician?.physicianFirstName
                            } ${physician?.physicianLastName}`}
                          </ClinText>
                          <ClinText
                            wordBreak="break-all"
                            variant={TypographyVariant.LargeParagraph}
                          >
                            {physician.physicianEmail}
                          </ClinText>
                        </StyleAdditionalPhysicianText>
                        <StyleAdditionalPhysicianAction
                          onClick={() =>
                            handlePhysicianRemoval(physician.physicianId)
                          }
                        >
                          {t('common:buttons.remove')}
                        </StyleAdditionalPhysicianAction>
                      </StyleAdditionalPhysician>
                    )
                  }
                )}
            </StyleAdditionalPhysicians>
          </Col>
        </Row>
      )}

      {savedPhysicians && savedPhysicians.length > 0 && (
        <Row>
          <Col sm={12} md={10} lg={8}>
            <StyleAdditionalPhysicians>
              <StyleAdditionalPhysicianTitle>
                <ClinText
                  wordBreak="break-all"
                  variant={TypographyVariant.LargeParagraph}
                  fontWeight={ClinTheme.fontWeights.bold}
                >
                  {t('enrol_user_on_to_program:new_physicians_title')}
                </ClinText>
                <StyleAdditionalPhysicianBadge
                  style={{ backgroundColor: '#EEEDFF' }}
                >
                  <ClinText
                    wordBreak="break-all"
                    variant={TypographyVariant.Paragraph}
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.primaryLight}
                  >
                    {t(
                      'enrol_user_on_to_program:additional_physician_badge_text_verification'
                    )}
                  </ClinText>
                </StyleAdditionalPhysicianBadge>
              </StyleAdditionalPhysicianTitle>
              {savedPhysicians &&
                savedPhysicians.map(
                  (physician: NewPhysicianEnrollDto, index) => {
                    return (
                      <StyleAdditionalPhysician key={`physician-${index}`}>
                        <StyleAdditionalPhysicianText>
                          <ClinText
                            wordBreak="break-all"
                            variant={TypographyVariant.LargeParagraph}
                            fontWeight={ClinTheme.fontWeights.medium}
                          >
                            {`${physician.title} ${physician.firstName} ${physician.lastName}`}
                          </ClinText>
                          <ClinText
                            wordBreak="break-all"
                            variant={TypographyVariant.LargeParagraph}
                          >
                            {physician.emailAddress}
                          </ClinText>
                        </StyleAdditionalPhysicianText>
                        <StyleAdditionalPhysicianAction
                          onClick={() =>
                            physician.emailAddress &&
                            handleSavedPhysicianRemoval(physician.emailAddress)
                          }
                        >
                          {t('common:buttons.remove')}
                        </StyleAdditionalPhysicianAction>
                      </StyleAdditionalPhysician>
                    )
                  }
                )}
            </StyleAdditionalPhysicians>
          </Col>
        </Row>
      )}
    </>
  )
}

export const getViewForStepTwo = (
  /** Call back request to remove a selected physician */
  handlePhysicianRemoval: (physicianId: number) => void,
  /** Call back to remove a selected saved physician */
  handleSavedPhysicianRemoval: (email: string) => void,
  /** Call back handle to confirm request */
  handleConfirmationSelect: (confirmed: boolean) => void,
  /** Whether a user has confirmed whether they would like to associate with a physician or not **/
  associateConfirmation?: boolean,
  /** If the user is submitting their request */
  isSubmitting?: boolean,
  /** Selected physicians */
  selectedPhysicians?: PhysiciansSummaryDto[],
  /** Saved physicians */
  savedPhysicians?: NewPhysicianEnrollDto[],
  /** isAutoEnrolled */
  isAutoEnrolled?: boolean
): React.ReactNode => {
  return (
    <>
      <Row className="step-two">
        <Col sm={12} md={10} lg={7}>
          <ClinText
            color={ClinTheme.colors.primary}
            marginBottom={ClinTheme.space[6]}
            variant={TypographyVariant.H4}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {t(
              'enrol_physician_on_to_program:physicians_you_would_like_to_enrol'
            )}
          </ClinText>
          <StyleAdditionalPhysicians isSubmitting={isSubmitting}>
            {selectedPhysicians &&
              selectedPhysicians.map(
                (physician: PhysiciansSummaryDto, index) => {
                  return (
                    <StyleAdditionalPhysician key={`physician-${index}`}>
                      <StyleAdditionalPhysicianText>
                        <ClinText
                          wordBreak="break-all"
                          variant={TypographyVariant.LargeParagraph}
                          fontWeight={ClinTheme.fontWeights.medium}
                        >
                          {`${physician.physicianTitle ?? ''} ${
                            physician.physicianFirstName
                          } ${physician.physicianLastName}`}
                        </ClinText>
                        <ClinText
                          wordBreak="break-all"
                          variant={TypographyVariant.LargeParagraph}
                        >
                          {physician.physicianEmail}
                        </ClinText>
                      </StyleAdditionalPhysicianText>
                      <StyleAdditionalPhysicianAction
                        onClick={() =>
                          !isSubmitting &&
                          handlePhysicianRemoval(physician.physicianId)
                        }
                      >
                        {t('common:buttons.remove')}
                      </StyleAdditionalPhysicianAction>
                    </StyleAdditionalPhysician>
                  )
                }
              )}
            {savedPhysicians &&
              savedPhysicians.map((physician: NewPhysicianEnrollDto, index) => {
                return (
                  <StyleAdditionalPhysician key={`physician-${index}`}>
                    <StyleAdditionalPhysicianText>
                      <ClinText
                        wordBreak="break-all"
                        variant={TypographyVariant.LargeParagraph}
                        fontWeight={ClinTheme.fontWeights.medium}
                      >
                        {`${physician.title} ${physician.firstName} ${physician.lastName}`}
                      </ClinText>
                      <ClinText
                        wordBreak="break-all"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {physician.emailAddress}
                      </ClinText>
                    </StyleAdditionalPhysicianText>
                    <StyleAdditionalPhysicianAction
                      onClick={() =>
                        !isSubmitting &&
                        physician.emailAddress &&
                        handleSavedPhysicianRemoval(physician.emailAddress)
                      }
                    >
                      {t('common:buttons.remove')}
                    </StyleAdditionalPhysicianAction>
                  </StyleAdditionalPhysician>
                )
              })}
          </StyleAdditionalPhysicians>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[5]} />
      <Row>
        <Col>
          <ClinText
            color={ClinTheme.colors.primary}
            marginBottom={ClinTheme.space[7]}
            variant={TypographyVariant.H4}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {t('enrol_physician_on_to_program:confirm_permission')}
          </ClinText>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={10} lg={7}>
          <ClinGroup alignItems="flex-start" justifyContent="space-between">
            <ClinCheckbox
              checked={associateConfirmation}
              onClick={(event: React.MouseEvent<HTMLInputElement>) =>
                handleConfirmationSelect &&
                handleConfirmationSelect(event.currentTarget.checked)
              }
              id="confirmation-select"
            />
            <ClinText variant={TypographyVariant.LargeParagraph}>
              {t('enrol_physician_on_to_program:confirm_checkbox')}
            </ClinText>
          </ClinGroup>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[9]} />
      <Row>
        <Col sm={12} md={10} lg={7}>
          <ClinText variant={TypographyVariant.LargeParagraph}>
            {t('enrol_physician_on_to_program:confirm_terms')}
          </ClinText>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[9]} />
    </>
  )
}

export const isContinueDisabled = (
  savedPhysicians: NewPhysicianEnrollDto[] | undefined,
  selectedPhysicians: PhysiciansSummaryDto[] | undefined
): boolean => {
  if (savedPhysicians && savedPhysicians?.length > 0) {
    return false
  } else if (selectedPhysicians && selectedPhysicians?.length > 0) {
    return false
  } else {
    return true
  }
}

export interface IEnrolPhysicianPageState {
  currentStep: EnrolPhysicianOnToProgramStep
  selectedTabIndex: number
  associateConfirmation: boolean
  handleGoToNextStep: () => void
  handleGoToPreviousStep: () => void
  handleTabSelected: (selectedTabIndex: number) => void
  handleRequestToAddPhysician: () => void
  handleConfirmationSelect: (confirmed: boolean) => void
  handleGetSteppers: () => StepState[]
  setCurrentStep?: (step: EnrolPhysicianOnToProgramStep) => void
}

export enum EnrolPhysicianErrorCode {
  XXCL_ENROLLMENT_FAILED = 'USER_ENROLLMENT_FAILED',
  XXCL_USER_AND_PHYSICIAN_ENROLLMENT_FAILED = 'USER_AND_PHYSICIAN_ENROLLMENT_FAILED',
  XXCL_PHYSICIAN_ENROLLMENT_FAILED = 'PHYSICIAN_ENROLLMENT_FAILED'
}

export const useEnrolPhysicianPageState = (
  selectedPhysicians: PhysiciansSummaryDto[] | undefined,
  savedPhysicians: NewPhysicianEnrollDto[] | undefined
): IEnrolPhysicianPageState => {
  const [currentStep, setCurrentStep] = useState<EnrolPhysicianOnToProgramStep>(
    EnrolPhysicianOnToProgramStep.STEP1
  )
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)
  const [associateConfirmation, setAssociateConfirmation] =
    useState<boolean>(false)

  const handleBackToTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (
      (!selectedPhysicians || selectedPhysicians.length === 0) &&
      (!savedPhysicians || savedPhysicians.length === 0) &&
      currentStep === EnrolPhysicianOnToProgramStep.STEP2
    ) {
      handleBackToTop()
      setCurrentStep(EnrolPhysicianOnToProgramStep.STEP1)
      setAssociateConfirmation(false)
    } else {
      setAssociateConfirmation(false)
    }
  }, [currentStep, savedPhysicians, selectedPhysicians])

  const handleGoToNextStep = (): void => {
    handleBackToTop()
    setCurrentStep(EnrolPhysicianOnToProgramStep.STEP2)
  }

  const handleGoToPreviousStep = (): void => {
    handleBackToTop()
    setCurrentStep(EnrolPhysicianOnToProgramStep.STEP1)
  }

  const handleTabSelected = (selectedTabIndex: number): void => {
    setSelectedTabIndex(selectedTabIndex)
  }

  const handleRequestToAddPhysician = (): void => {
    setSelectedTabIndex(1)
  }

  const handleConfirmationSelect = (confirmed: boolean) => {
    setAssociateConfirmation(confirmed)
  }

  const handleGetSteppers = (): StepState[] => {
    return EnrolUserOnToProgramSteps[currentStep]
  }

  return {
    currentStep,
    selectedTabIndex,
    associateConfirmation,
    handleGoToNextStep,
    handleGoToPreviousStep,
    handleTabSelected,
    handleRequestToAddPhysician,
    handleConfirmationSelect,
    handleGetSteppers,
    setCurrentStep
  }
}
