import * as React from 'react'
import { IDefaultStyleProps } from '../../types'
import { StyledSvg } from './ClinIcon.styles'
import { ClinIconPathName, paths } from './ClinIcon.paths'
import { ClinTheme } from '../../ClinTheme'

interface IIconProps
  extends IDefaultStyleProps,
    React.HTMLAttributes<HTMLOrSVGElement> {
  /** The name of icon from paths */
  iconName: ClinIconPathName
  /** The icon fill */
  iconFill?: 'initial' | 'inherit' | 'currentcolor' | string
  /**  The icon size */
  iconSize?: string | number
  /**  The icon height */
  iconHeight?: string | number
  /**  The icon custom view box to center arrow*/
  viewBox?: string
  fillRule?: 'inherit' | 'evenodd' | 'nonzero'
  clipRule?: string
}

export const ClinIcon: React.FC<IIconProps> = ({
  style,
  className = '',
  iconName,
  iconFill = 'currentcolor',
  iconSize = ClinTheme.space[4],
  iconHeight,
  viewBox,
  fillRule,
  clipRule
}) => (
  <StyledSvg
    style={{ ...style }}
    className={`clin-icon ${className}`}
    width={iconSize}
    height={iconHeight ?? iconSize}
    viewBox={viewBox ?? `0 0 24 24`}
    preserveAspectRatio="xMidYMid meet"
    x="0"
    y="0"
    name={iconName}
  >
    <path
      fillRule={fillRule}
      clipRule={clipRule}
      d={paths[iconName]}
      fill={iconFill}
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </StyledSvg>
)
