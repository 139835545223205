import styled from 'styled-components'
import { ClinTheme } from '../../../../ClinTheme'
import { mediaQuery } from '../../../../utils/mediaQuery'
import { ISearchStylesProps } from '../../AutoSuggest/AutoSuggest'

const mobileUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledGlobalSearch = styled.section({
  [`@media print`]: {
    display: 'none'
  }
})

export const StyledGlobalSearchforAUS = styled.section({
  paddingLeft: '20px',
  paddingRight: '20px',
  backgroundColor: ClinTheme.colors.lightGrey
})

export const StyledSearchWrapper = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  minWidth: '312px',
  width: '312px',
  maxWidth: '768px',
  height: '40px',
  transition: 'width .3s ease-in-out',
  marginRight: '18px',
  [`.clin-icon`]: {
    width: '18px',
    height: '18px'
  },
  [`&:focus-within`]: {
    width: 'calc(100% - 28px);',
    outline: 'none'
  },
  [mediaQuery(mobileUpBreakpoint)]: {
    marginLeft: '18px',
    marginRight: '18px',
    maxWidth: 'auto',
    marginTop: '-7px'
  }
})

export const StyledSearchWrapperForAUS = styled.div<ISearchStylesProps>(
  {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    top: '-40px',
    borderRadius: '60px',
    height: '68px',
    boxShadow:
      '0px 4px 8px rgba(0, 0, 0, 0.16), 0px 0px 8px rgba(0, 0, 0, 0.16)',
    padding: `${ClinTheme.space[0]}px ${ClinTheme.space[4]}`,
    [`.clin-icon`]: {
      transition: '.2s linear color',
      color: ClinTheme.colors.white
    },
    [`> input:focus`]: {
      [`~ .clin-icon`]: {
        color: ClinTheme.colors.primary
      },
      borderColor: ClinTheme.colors.primaryMid
    },
    [mediaQuery(mobileUpBreakpoint)]: {
      maxWidth: 1094,
      maxHeight: 68,
      margin: 'auto'
    }
  },
  ({ showSuggestions }) => ({
    [`> input:focus`]: {
      borderBottom: showSuggestions
        ? `1px solid ${ClinTheme.colors.primaryLight}`
        : `8px solid ${ClinTheme.colors.primaryMid}`
    }
  })
)

export const StyledSearchInput = styled.input<ISearchStylesProps>(
  {
    fontFamily: ClinTheme.fonts.body,
    position: 'relative',
    flex: '1 1 auto',
    marginBottom: '0',
    width: '100%',
    textIndent: ClinTheme.space[4],
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[3]}px`,
    fontSize: ClinTheme.fontSizes[1],
    fontWeight: ClinTheme.fontWeights.normal,
    lineHeight: '24px',
    color: ClinTheme.colors.deepGrey,
    borderStyle: 'none',
    outline: 'none',
    transition: '.2s linear color, .2s linear width',
    '::placeholder': {
      color: 'inherit'
    },
    ':-ms-input-placeholder': {
      // Special case for Text Inputs to have correct height in IE 11
      height: '40px'
    },
    [`:focus`]: {
      width: '100%'
    }
  },
  ({ showSuggestions }) => ({
    backgroundColor: ClinTheme.colors.white,
    borderBottomLeftRadius: showSuggestions ? 0 : ClinTheme.space[4],
    borderBottomRightRadius: showSuggestions ? 0 : ClinTheme.space[4],
    borderTopLeftRadius: showSuggestions
      ? ClinTheme.space[3]
      : ClinTheme.space[4],
    borderTopRightRadius: showSuggestions
      ? ClinTheme.space[3]
      : ClinTheme.space[4]
  })
)

export const StyledSearchInputAus = styled.input<ISearchStylesProps>(
  {
    fontFamily: ClinTheme.fonts.body,
    position: 'relative',
    flex: '1 1 auto',
    marginBottom: '0',
    textIndent: ClinTheme.space[4],
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[2]}px`,
    fontSize: ClinTheme.fontSizes[2],
    fontWeight: ClinTheme.fontWeights.normal,
    color: ClinTheme.colors.white,
    borderRadius: ClinTheme.space[5],
    transition: '.2s linear background-color, .2s linear color',
    '::placeholder': {
      color: 'inherit'
    },
    ':-ms-input-placeholder': {
      // Special case for Text Inputs to have correct height in IE 11
      height: '40px'
    },
    [`:focus`]: {
      color: ClinTheme.colors.primary,
      border: `${ClinTheme.space[2]}px solid ${ClinTheme.colors.primaryMid}`,
      backgroundColor: ClinTheme.colors.white,
      outline: ClinTheme.space[0]
    },
    [mediaQuery(mobileUpBreakpoint)]: {
      fontSize: ClinTheme.fontSizes[3]
    }
  },
  ({ showSuggestions }) => ({
    backgroundColor: showSuggestions
      ? ClinTheme.colors.white
      : ClinTheme.colors.primaryMid,
    border: showSuggestions
      ? `${ClinTheme.space[2]}px solid ${ClinTheme.colors.primaryMid}`
      : `${ClinTheme.space[2]}px solid ${ClinTheme.colors.white}`,
    borderBottom: showSuggestions
      ? `1px solid ${ClinTheme.colors.primaryMid}`
      : `${ClinTheme.space[2]}px solid ${ClinTheme.colors.white}`,
    borderBottomLeftRadius: showSuggestions ? 0 : ClinTheme.space[5],
    borderBottomRightRadius: showSuggestions ? 0 : ClinTheme.space[5],
    borderTopLeftRadius: showSuggestions ? '28px' : ClinTheme.space[5],
    borderTopRightRadius: showSuggestions ? '28px' : ClinTheme.space[5]
  })
)

export const StyledResults = styled.ul<ISearchStylesProps>(
  {
    listStyle: 'none',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    margin: 0,
    padding: 0,
    zIndex: ClinTheme.zIndices.drawer,
    boxShadow: `0px 4px 8px 0px rgba(0, 0, 0, 0.16)`
  },
  ({ showSuggestions }) => ({
    display: showSuggestions ? 'block' : 'none'
  })
)

export const StyledResultsAus = styled.ul<ISearchStylesProps>(
  {
    listStyle: 'none',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    margin: 0,
    padding: 0,
    zIndex: ClinTheme.zIndices.suggestiveSearch,
    border: `${ClinTheme.space[2]}px solid ${ClinTheme.colors.primaryMid}`,
    borderRadius: '0 0 28px 28px',
    borderTop: 'none',
    boxShadow: `0px 4px 8px 0px rgba(0, 0, 0, 0.16)`
  },
  ({ showSuggestions }) => ({
    display: showSuggestions ? 'block' : 'none'
  })
)

export const StyledResultsWithNewFeature = styled.ul<ISearchStylesProps>(
  {
    listStyle: 'none',
    position: 'absolute',
    top: '100%',
    left: '51px',
    right: 0,
    margin: 0,
    padding: 0,
    zIndex: ClinTheme.zIndices.suggestiveSearch,
    pointerEvents: 'auto',
    boxShadow: `0px 4px 8px 0px rgba(0, 0, 0, 0.16)`
  },
  ({ showSuggestions }) => ({
    display: showSuggestions ? 'block' : 'none'
  })
)
