import { AxiosError } from 'axios'
import React, { FunctionComponent, useContext, useState } from 'react'
import { useHistory } from 'react-router'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { AppContext } from '../../../context/app'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import { AuthError, newShippingAddress } from '../../../services/ApiService'
import { CreateShippingAddressDto } from '../../../types/swaggerTypes'
import { NewShippingAddress } from './NewShippingAddress'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { useTranslation } from 'react-i18next'
import { useCountries } from '../../../utils/useCountries'

export const NewShippingAddressContainer: FunctionComponent = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { dispatch, supportContact } = useContext(AppContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { isCountriesLoading, countries } = useCountries()

  const handleFormSubmission = (data: CreateShippingAddressDto) => {
    setIsSubmitting(true)
    newShippingAddress(data)
      .then((response) => {
        if (response?.data.incidentNumber) {
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.ShippingAddressSubmit,
            { incidentNumber: response?.data.incidentNumber }
          )
          setIsSubmitting(false)
          history.push(
            {
              pathname: '/institute/details'
            },
            {
              title: t('request_new_shipping_address:submitted_ok_title'),
              message: t('request_new_shipping_address:submitted_ok_message')
            }
          )
        }
      })
      .catch((error: AxiosError) => {
        const { code, message } = error

        // If request is cancelled continue
        if (error.message === AuthError.RequestCancelled) {
          return
        }
        // If we have a full error show it
        if (error.response && error.response.data) {
          const { title, detail } = error.response.data
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `${t('request_new_shipping_address:submitted_fail_message')} ${
                title ? title : ''
              } ${message ? message : ''}`
            )
          )
          console.warn(title, detail)
        } else {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `${t(
                'request_new_shipping_address:submitted_fail_message'
              )} ${message} ${code ? code : ''}`
            )
          )
        }
        setIsSubmitting(false)
        window.scrollTo(0, 0)
      })
  }

  const handleCancel = () => {
    history.push('/institute/details')
  }

  return (
    <NewShippingAddress
      isLoading={isCountriesLoading}
      isSubmitting={isSubmitting}
      supportContact={supportContact}
      countries={countries}
      handleFormSubmission={handleFormSubmission}
      handleCancel={handleCancel}
    />
  )
}
