import React, { FunctionComponent } from 'react'
import {
  IClinDropdownMenuStyles,
  StyledClinDropdownMenu
} from './ClinDropdownMenu.styles'
import { IDefaultStyleProps } from '../../types'

interface IClinDropdownProps
  extends IDefaultStyleProps,
    IClinDropdownMenuStyles {}

export const ClinDropdownMenu: FunctionComponent<IClinDropdownProps> = ({
  style,
  className = '',
  marginTop,
  children
}) => (
  <StyledClinDropdownMenu
    style={{ ...style }}
    marginTop={marginTop}
    className={`clin-dropdown-menu ${className}`}
  >
    {children}
  </StyledClinDropdownMenu>
)
