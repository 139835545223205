import { useEffect } from 'react'
import { UserDto } from '../types/swaggerTypes'
import { useHistory } from 'react-router'

/**
 * Find out if a user has completed the onboarding process by checking the hasUserCompletedOnboarding flag.
 * If this return a value of 'N' then redirect the user to the onboarding page to complete all mandatory fields.
 * @param userDetails
 */

export const useOnboardingCheck = (userDetails?: UserDto) => {
  const history = useHistory()
  const orderNumber = window.location.pathname?.split('/order/')[1]

  useEffect(() => {
    if (userDetails) {
      if (userDetails.contactCard.hasUserCompletedOnboarding === 'N') {
        if (orderNumber) {
          localStorage.setItem(userDetails.contactCard.name, orderNumber) //set this to know that this is fast track new user and to know that you should not display new feature for this user
        }
        history.push(`/onboarding`)
      }
    }
  }, [history, userDetails])
}
