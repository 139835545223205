export enum SupportContactCountryEventType {
  UPDATE = 'UpdateSupportContactAddress'
}

// Support address updates ------------------------------------------------------------------------

export interface ISupportContactCountryEvent {
  type: SupportContactCountryEventType.UPDATE
  countryCode: string
}

export const createUpdateSupportContactAddressEvent = (
  countryCode: string
): ISupportContactCountryEvent => ({
  type: SupportContactCountryEventType.UPDATE,
  countryCode
})

export type ISupportContactCountry = ISupportContactCountryEvent
