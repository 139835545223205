import React, { useEffect, useRef } from 'react'
import { ActionType, useNewFeatures } from '../../context/newFeatures'
import {
  addNewFeatureClosedEvent,
  addNewFeatureDisplayedEvent,
  getNewFeatureEventDescription,
  setFeatureToViewed
} from '../../services/NewFeaturesService'
import { ArrowPosition, INewFeature } from './ClinNewFeatureTooltip.types'
import { ClinNewFeatureTooltipFloatingUI } from './ClinNewFeatureTooltipFloatingUI'
import {
  useFloating,
  offset,
  computePosition,
  useDismiss,
  useInteractions,
  autoUpdate,
  flip,
  shift,
  arrow
} from '@floating-ui/react-dom-interactions'
import {
  arrowOffset,
  crossSides,
  getMainSide,
  getRotation,
  svgWidth,
  unsetSides
} from './ClinNewFeatureTooltip.config'
import {
  FLOATING_OVERLAY_VISIBLE,
  eventEmitter
} from '../../utils/eventEmitter'

interface IClinNewFeatureTooltipProps {
  feature: INewFeature
  //total number of features on one page
  total?: number
  //number of current feature on the page
  current?: number
  openTooltipInitially?: boolean
  disableDismiss?: boolean //if we want to prevent tooltip to close after clicking out of the tooltip
  disableClinFeature?: boolean //if we want to disable initially open tooltip to be closed on refresh
  showFeatureFromTable?: boolean
  hideCloseButton?: boolean
  tooltipWidth?: string
}

export const ClinNewFeatureTooltipContainer: React.FC<
  IClinNewFeatureTooltipProps
> = ({
  feature,
  openTooltipInitially,
  disableDismiss,
  disableClinFeature,
  total,
  current,
  showFeatureFromTable,
  hideCloseButton,
  tooltipWidth
}) => {
  const [, newFeaturesDispatch] = useNewFeatures()

  //Disable tooltip dismiss option when change institute modal is opened
  const alowDismiss =
    document.getElementsByClassName('tooltipTarget')[0] === undefined

  const handleClose = (isOpen: boolean) => {
    if (!isOpen) {
      handleCloseTooltip()
    }
  }

  const cleanSeenFeatures = () => {
    const savedFeature = localStorage.getItem(`feature-${feature.id}`)
    if (
      savedFeature === feature.id &&
      localStorage.getItem(`feature-${feature.id}-viewed`) !== feature.id
    ) {
      if (feature.isCancelled) {
        localStorage.removeItem(`feature-${feature.id}`)
        localStorage.removeItem(`feature-${feature.id}-viewed`)
      } else {
        handleNewFeatureViewed()
      }
    }
  }

  const handleNewFeatureViewed = (isCancelled?: boolean) => {
    setFeatureToViewed(feature.id, feature.version, isCancelled)
      .then(() => {
        newFeaturesDispatch({
          type: ActionType.UpdateNewFeatures,
          feature: { id: feature.id, isCancelled }
        })
        addNewFeatureClosedEvent(getNewFeatureEventDescription(feature.id))
        //clean localstorage after set feature to viewed and after sending mix panel feautre seen event
        localStorage.removeItem(`feature-${feature.id}`)
        localStorage.removeItem(`feature-${feature.id}-viewed`)
      })
      .catch((error: any) => {})
  }

  const handleRemoveTooltip = (isCancelled?: boolean) => {
    //we need this in local storage to prevent mix panel close tooltip event to trigger twice
    localStorage.setItem(`feature-${feature.id}-viewed`, feature.id)
    handleNewFeatureViewed(isCancelled)
  }

  const [open, setOpen] = React.useState<boolean>(!!openTooltipInitially)

  const tooltip = document.getElementById(
    `tooltip-${feature.id}`
  ) as HTMLElement
  const button = document.getElementById(`button-${feature.id}`) as HTMLElement
  const arrowEl = document.getElementById(`arrow-${feature.id}`) as HTMLElement
  const arrowRef: any = useRef(null)

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: handleClose,
    whileElementsMounted: autoUpdate
  })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useDismiss(context, { enabled: alowDismiss && !disableDismiss })
  ])

  const updatePosition = () => {
    if (button && tooltip) {
      computePosition(button, tooltip, {
        placement: feature.position ? feature.position : 'top-start',
        middleware: [
          offset(20),
          flip({ padding: 5 }),
          shift(),
          arrow({ element: arrowRef })
        ]
      }).then(({ x, y, placement, middlewareData }) => {
        const calculateX =
          placement.split('-')[1] === ArrowPosition.end ? x + 5 : x - 6
        Object.assign(tooltip?.style, {
          left: `${calculateX}px`,
          top: `${y}px`
        })

        if (arrowEl && middlewareData.arrow) {
          const { x: xArrow, y: yArrow } = middlewareData.arrow
          const [side, alignment] = placement.split('-')
          const isAligned = alignment != null
          const mainSide = getMainSide(side)
          const rotation = getRotation(side)

          arrowEl.style.transform = `rotate(${rotation})`

          const isRefWider = button.offsetWidth > tooltip.offsetWidth
          const isRefTaller = button.offsetHeight > tooltip.offsetHeight
          const isVerticalSide =
            side === ArrowPosition.top || side === ArrowPosition.bottom
          const useStaticPositioning =
            isAligned && (isVerticalSide ? isRefWider : isRefTaller)

          if (useStaticPositioning) {
            const crossSide: any = crossSides.find(
              (x) => x.name === placement
            )?.value

            Object.assign(arrowEl.style, {
              ...unsetSides,
              [mainSide]: arrowOffset,
              [crossSide]: 'min(15%, 15px)'
            })
          } else {
            let calcX = xArrow ? xArrow + svgWidth / 2 : ''
            if (placement.split('-')[1] === ArrowPosition.end) {
              calcX = xArrow ? xArrow - svgWidth / 2 : ''
            }
            Object.assign(arrowEl.style, {
              ...unsetSides,
              left: xArrow != null ? `${calcX}px` : '',
              top: yArrow != null ? `${yArrow}px` : '',
              [mainSide]: arrowOffset
            })
          }
        }
      })
    }
  }

  if (button && tooltip) {
    autoUpdate(button, tooltip, updatePosition, {
      ancestorScroll: true,
      ancestorResize: false,
      elementResize: false
    })
  }

  const handleOpenTooltip = () => {
    if (tooltip) {
      !feature.isCancelled &&
        localStorage.setItem(`feature-${feature.id}`, feature.id)
      setOpen(true)
      tooltip.style.display = 'block'
      //call mixpanel event for displaying new feature to user
      const eventDescription = getNewFeatureEventDescription(feature.id)
      eventDescription && addNewFeatureDisplayedEvent(eventDescription)
    }
  }

  const handleCloseTooltip = () => {
    tooltip.style.display = 'none'
  }

  useEffect(() => {
    updatePosition()
    if (!openTooltipInitially) {
      if (tooltip) {
        tooltip.style.display = 'none'
      }
    }
  }, [tooltip])

  useEffect(() => {
    if (
      openTooltipInitially &&
      alowDismiss &&
      !disableClinFeature &&
      !feature.isCancelled
    ) {
      localStorage.setItem(`feature-${feature.id}`, feature.id)
    }
    return () => cleanSeenFeatures()
  }, [])

  useEffect(() => {
    if (open && alowDismiss && !feature.isCancelled) {
      let scrollPosition = window.scrollY || document.documentElement.scrollTop
      //document.body.style.pointerEvents = 'none'
      document.body.style.overflow = 'hidden'
      document.documentElement.style.overflow = 'hidden'
      document.documentElement.scrollTop = scrollPosition
      if (showFeatureFromTable) {
        const overlayElement = document.querySelector(
          '.feature-overlay'
        ) as HTMLElement
        if (overlayElement) {
          // Change the style property from 'display: none' to 'display: block'
          overlayElement.style.display = 'block'
          eventEmitter.emit(FLOATING_OVERLAY_VISIBLE, true)
        }
      }
    } else {
      document.body.style.pointerEvents = 'auto'
      document.documentElement.style.overflow = 'auto'
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.pointerEvents = 'auto'
      document.documentElement.style.overflow = 'auto'
      document.body.style.overflow = 'auto'
      if (showFeatureFromTable) {
        const overlayElement = document.querySelector(
          '.feature-overlay'
        ) as HTMLElement
        if (overlayElement) {
          // Change the style property from 'display: none' to 'display: block'
          overlayElement.style.display = 'none'
          eventEmitter.emit(FLOATING_OVERLAY_VISIBLE, false)
        }
      }
    }
  }, [open, alowDismiss])

  return (
    <ClinNewFeatureTooltipFloatingUI
      id={feature.id}
      title={feature.title}
      description={feature.description}
      total={total}
      current={current}
      style={feature.style}
      tooltipWidth={tooltipWidth}
      open={open}
      position={feature.position}
      cssClass={feature.cssClass}
      tooltip={tooltip}
      button={button}
      arrowEl={arrowEl}
      arrowRef={arrowRef}
      x={x}
      y={y}
      strategy={strategy}
      showFeatureFromTable={showFeatureFromTable}
      isCancelled={feature.isCancelled}
      reference={reference}
      floating={floating}
      getFloatingProps={getFloatingProps}
      getReferenceProps={getReferenceProps}
      handleRemoveTooltip={handleRemoveTooltip}
      handleOpenTooltip={handleOpenTooltip}
      hideCloseButton={hideCloseButton}
    />
  )
}
