import { DateTime } from 'luxon'
import {
  ICrossSide,
  INewFeature,
  INewFeatureVersion,
  NewFeatureElements,
  NewFetureGroups,
  PageNames
} from './ClinNewFeatureTooltip.types'

export const newFeatureExpiresInMonths = 3

export const newFeatureLookup: INewFeature[] = [
  // { this is commented because it passed more than 3 month for these features
  //   id: NewFeatureElements.SwitchButtonFeature,
  //   version: '4',
  //   title: 'orders:my_orders_tooltip.title',
  //   description: 'orders:my_orders_tooltip.description',
  //   style: {}
  // },
  // {
  //   id: NewFeatureElements.TrackingLinkFeature,
  //   version: '4',
  //   title: 'order_detail:tracking_link_tooltip.title',
  //   description: 'order_detail:tracking_link_tooltip.description',
  //   style: {
  //     display: 'flex',
  //     justifyContent: 'flexStart',
  //     alignItems: 'center'
  //   }
  // },
  // {
  //   id: NewFeatureElements.GlobalSearchFeature,
  //   version: '4',
  //   title: 'global_search:global_search_tooltip.title',
  //   description: 'global_search:global_search_tooltip.description',
  //   style: {}
  // },
  // {
  //   id: NewFeatureElements.BookmarkFeature,
  //   version: '4',
  //   title: 'product_variants:bookmark_feature_tooltip.title',
  //   description: 'product_variants:bookmark_feature_tooltip.description',
  //   style: {}
  // },
  {
    id: NewFeatureElements.EditButtonMaChekout,
    version: '7.5',
    title: 'opa_checkout:edit_button_tooltip.title',
    description: 'opa_checkout:edit_button_tooltip.description',
    style: {}
  },
  {
    id: NewFeatureElements.DeliveryNote,
    version: '7.5',
    title: 'opa_checkout:delivery_note_tooltip.title',
    description: 'opa_checkout:delivery_note_tooltip.description',
    mandatoryViewedFeatures: [NewFeatureElements.EditButtonMaChekout],
    style: {}
  },
  {
    id: NewFeatureElements.ContinueOPA,
    version: '9',
    title: 'patient_detail:saved_patient_new_feature.title',
    description: 'patient_detail:saved_patient_new_feature.description',
    style: {},
    position: 'top-end'
  },
  {
    id: NewFeatureElements.ContinueCheckout,
    version: '9',
    title: 'patient_detail:saved_patient_new_feature.title',
    description: 'patient_detail:saved_patient_new_feature.description',
    style: {},
    position: 'top-end'
  },
  {
    id: NewFeatureElements.CancelOPA,
    version: '9',
    title: 'patient_detail:saved_patient_new_feature.title',
    description: 'patient_detail:saved_patient_new_feature.description',
    style: {},
    position: 'top-end'
  },
  {
    id: NewFeatureElements.CancelCheckout,
    version: '9',
    title: 'patient_detail:saved_patient_new_feature.title',
    description: 'patient_detail:saved_patient_new_feature.description',
    style: {},
    position: 'top-end'
  },
  {
    id: NewFeatureElements.PatientsNavbar,
    version: '9',
    title: 'navigation:patients_feature_highlight.title',
    description: 'navigation:patients_feature_highlight.description',
    mandatoryViewedFeatures: [
      NewFeatureElements.GlobalSearchNewUserFeature,
      NewFeatureElements.OrdersNavbar
    ],
    style: {},
    position: 'top-start'
  },
  {
    id: NewFeatureElements.PatientsSearch,
    version: '9',
    title: 'patient_detail:search_feature.title',
    description: 'patient_detail:search_feature.description',
    groupId: NewFetureGroups.PatientsTour,
    mandatoryViewedFeatures: [NewFeatureElements.PatientsNavbar],
    style: {},
    position: 'bottom-end'
  },
  {
    id: NewFeatureElements.PatientsCreate,
    version: '9',
    title: 'patient_detail:create_patient_feature.title',
    description: 'patient_detail:create_patient_feature.description',
    groupId: NewFetureGroups.PatientsTour,
    mandatoryViewedFeatures: [
      NewFeatureElements.PatientsSearch,
      NewFeatureElements.PatientsNavbar
    ],
    style: {},
    position: 'bottom-start'
  },
  {
    id: NewFeatureElements.PatientsFilter,
    version: '9',
    title: 'patient_detail:filter_feature.title',
    description: 'patient_detail:filter_feature.description',
    groupId: NewFetureGroups.PatientsTour,
    mandatoryViewedFeatures: [
      NewFeatureElements.PatientsSearch,
      NewFeatureElements.PatientsCreate,
      NewFeatureElements.PatientsNavbar
    ],
    style: {},
    position: 'top-start'
  },
  {
    id: NewFeatureElements.PatientsAction,
    version: '9',
    title: 'patient_detail:action_feature.title',
    description: 'patient_detail:action_feature.description',
    groupId: NewFetureGroups.PatientsTour,
    mandatoryViewedFeatures: [
      NewFeatureElements.PatientsSearch,
      NewFeatureElements.PatientsCreate,
      NewFeatureElements.PatientsFilter,
      NewFeatureElements.PatientsNavbar
    ],
    style: {},
    position: 'bottom-end'
  },
  {
    id: NewFeatureElements.GlobalSearchNewUserFeature,
    version: '10',
    title: 'global_search:global_search_tooltip.title',
    description: 'global_search:global_search_tooltip.description',
    groupId: NewFetureGroups.HomePageTour,
    position: 'bottom-start',
    style: {}
  },
  {
    id: NewFeatureElements.OrdersNavbar,
    version: '10',
    title: 'navigation:orders_feature_highlight.title',
    description: 'navigation:orders_feature_highlight.description',
    mandatoryViewedFeatures: [NewFeatureElements.GlobalSearchNewUserFeature],
    style: {},
    position: 'bottom-start'
  },
  {
    id: NewFeatureElements.OrdersFirst,
    version: '10',
    title: 'orders:search_feature.title',
    description: 'orders:search_feature.description',
    groupId: NewFetureGroups.OrdersTour,
    mandatoryViewedFeatures: [NewFeatureElements.OrdersNavbar],
    style: {},
    position: 'bottom-start'
  },
  {
    id: NewFeatureElements.OrdersSearch,
    version: '10',
    title: 'orders:first_order_feature.title',
    description: 'orders:first_order_feature.description',
    groupId: NewFetureGroups.OrdersTour,
    mandatoryViewedFeatures: [
      NewFeatureElements.OrdersNavbar,
      NewFeatureElements.OrdersFirst
    ],
    style: {},
    position: 'bottom-start'
  },
  {
    id: NewFeatureElements.OrdersFilter,
    version: '10',
    title: 'orders:filter_feature.title',
    description: 'orders:filter_feature.description',
    groupId: NewFetureGroups.OrdersTour,
    mandatoryViewedFeatures: [
      NewFeatureElements.OrdersNavbar,
      NewFeatureElements.OrdersFirst,
      NewFeatureElements.OrdersSearch
    ],
    style: {},
    position: 'bottom-start'
  }
]

export const newFeatureRealeases: INewFeatureVersion[] = [
  {
    version: '4',
    releaseDate: DateTime.fromISO('2023-01-21', { zone: 'utc' })
  },
  {
    version: '5',
    releaseDate: DateTime.fromISO('9999-01-01', { zone: 'utc' }) //we don't have expiration date for these feature
  },
  {
    version: '7.5',
    releaseDate: DateTime.fromISO('9999-01-01', { zone: 'utc' }) //we don't have expiration date for these feature
  },
  {
    version: '9',
    releaseDate: DateTime.fromISO('9999-01-01', { zone: 'utc' }) //we don't have expiration date for these feature
  },
  {
    version: '10',
    releaseDate: DateTime.fromISO('9999-01-01', { zone: 'utc' }) //we don't have expiration date for these feature
  }
]

export const crossSides: ICrossSide[] = [
  {
    name: 'top-start',
    value: 'left'
  },
  {
    name: 'top-end',
    value: 'right'
  },
  {
    name: 'bottom-start',
    value: 'left'
  },
  {
    name: 'bottom-end',
    value: 'right'
  },
  {
    name: 'left-start',
    value: 'top'
  },
  {
    name: 'left-end',
    value: 'bottom'
  },
  {
    name: 'right-start',
    value: 'top'
  },
  {
    name: 'right-end',
    value: 'bottom'
  }
]

export const svgWidth = 10
export const svgHeight = 10
export const arrowOffset = `-${svgWidth - svgHeight / 2}px`

// If you're flipping to all different placements,
// this will be necessary.
export const unsetSides = {
  top: '',
  bottom: '',
  left: '',
  right: ''
}

export const getMainSide: (side: string) => string = (side: string) => {
  return {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left'
  }[side]!
}

export const getRotation: (side: string) => string = (side: string) => {
  return {
    top: '180deg',
    bottom: '0deg',
    left: '90deg',
    right: '-90deg'
  }[side]!
}

export const getPageNewFeatures = (pageName: string) => {
  switch (pageName) {
    case PageNames.Patients:
      return [
        NewFeatureElements.ContinueOPA,
        NewFeatureElements.ContinueCheckout,
        NewFeatureElements.CancelOPA,
        NewFeatureElements.CancelCheckout,
        NewFeatureElements.PatientsSearch,
        NewFeatureElements.PatientsCreate,
        NewFeatureElements.PatientsFilter,
        NewFeatureElements.PatientsAction,
        NewFeatureElements.PatientsNavbar
      ]
    case PageNames.Navbar:
      return [
        NewFeatureElements.GlobalSearchNewUserFeature,
        NewFeatureElements.OrdersNavbar,
        NewFeatureElements.PatientsNavbar
      ]
    case PageNames.OpaCheckout:
      return [
        NewFeatureElements.EditButtonMaChekout,
        NewFeatureElements.DeliveryNote
      ]
    case PageNames.GlobalSearch:
      return [
        NewFeatureElements.GlobalSearchNewUserFeature,
        NewFeatureElements.OrdersNavbar
      ]
    case PageNames.Orders:
      return [
        NewFeatureElements.OrdersSearch,
        NewFeatureElements.OrdersNavbar,
        NewFeatureElements.OrdersFilter,
        NewFeatureElements.OrdersFirst
      ]
    default:
      return []
  }
}
