import React from 'react'
import { ClinText } from '../../../components/ClinText'
import { ClinTheme } from '../../../ClinTheme'
import { StyledStatusWrapper } from '../../Patients/PatientDashboard/PatientDashboard.styles'
import {
  PatientOrderStatus,
  patientOrderStatusTranslation
} from '../../../pages/Patients/PatientDashboard/Patient.model'
import { t } from 'i18next'

interface PatientDetailsStatusCardProps {
  status: PatientOrderStatus | undefined
}

const PatientDetailsStatusCard: React.FC<PatientDetailsStatusCardProps> = ({
  status
}) => {
  if (!status) {
    return null
  }

  const { backgroundColor, textColor } = getStatusColor(status)

  return (
    <StyledStatusWrapper color={backgroundColor}>
      <ClinText
        fontWeight={ClinTheme.fontWeights.medium}
        color={textColor}
        fontSize={ClinTheme.fontSizes[0]}
        textAlign="center"
        lineHeight={'2'}
      >
        {t(patientOrderStatusTranslation[status])}
      </ClinText>
    </StyledStatusWrapper>
  )
}

const getStatusColor = (status: PatientOrderStatus | undefined) => {
  switch (status) {
    case PatientOrderStatus.PatientDiscontinued:
    case PatientOrderStatus.ProgramClosed:
      return {
        backgroundColor: 'rgba(117, 117, 117, 0.1)',
        textColor: ClinTheme.colors.darkGrey
      }
    case PatientOrderStatus.BeginOrder:
    case PatientOrderStatus.IncompletePAF:
    case PatientOrderStatus.IncompleteCheckout:
    case PatientOrderStatus.ReadyForResupply:
      return {
        backgroundColor: 'rgba(0, 67, 206, 0.1)',
        textColor: ClinTheme.colors.tealishBlue
      }
    case PatientOrderStatus.Processing:
    case PatientOrderStatus.InTransit:
    case PatientOrderStatus.OutForDelivery:
    case PatientOrderStatus.Available_for_Pickup:
    case PatientOrderStatus.FailedAttempt:
    case PatientOrderStatus.Delivered:
    case PatientOrderStatus.Pending:
    case PatientOrderStatus.Shipped:
    case PatientOrderStatus.Submitted:
      return {
        backgroundColor: 'rgba(0, 162, 35, 0.1)',
        textColor: ClinTheme.colors.greenValid
      }
    case PatientOrderStatus.UnderMedicalReview:
    case PatientOrderStatus.FailedDelivery:
    case PatientOrderStatus.HealthAuthorityReview:
    case PatientOrderStatus.UnderReview:
      return {
        backgroundColor: 'rgba(215, 106, 0, 0.10)',
        textColor: ClinTheme.colors.orangeWarn
      }
    default:
      return {
        backgroundColor: 'rgba(117, 117, 117, 0.1)',
        textColor: ClinTheme.colors.darkGrey
      }
  }
}

export default PatientDetailsStatusCard
