import React, { FunctionComponent } from 'react'
import { Tooltip } from 'react-tippy'

interface IColumnTooltipProps {
  children: React.ReactNode
  tooltipText: string
  additionalClass?: string
}

const ColumnTooltip: FunctionComponent<IColumnTooltipProps> = ({
  children,
  tooltipText,
  additionalClass = ''
}) => {
  if (!tooltipText) {
    return <>{children}</>
  }

  const tooltipPosition =
    additionalClass === 'custom-tooltip' ? 'top' : 'bottom'

  return (
    <div className={additionalClass}>
      <Tooltip
        theme="light"
        title={tooltipText}
        position={tooltipPosition}
        trigger="mouseenter"
        arrow={true}
        style={{ width: '100%' }}
      >
        {children}
      </Tooltip>
    </div>
  )
}

export default ColumnTooltip
