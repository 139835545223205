import i18next from 'i18next'
import { IIndexable } from '../../types'
export enum ValidationType {
  RequiredField,
  Email,
  MinimumCharacters,
  MaxiumumCharacters,
  TooManyOptionsSelected,
  MustBeNumber,
  OnlyNumbersAndSpecialChars
}
/**
 * Gets a translated validation string for a specific validation type
 * @param inputType - Required - Selects the required validation type
 * @param dynamicValue - Optional - intended to allow for dynamic content to be placed within a translated string
 * @example:
 * getInputValidation(ValidationType.Required, 'optional value')
 */
export const getInputValidation = (
  inputType: ValidationType,
  dynamicValue?: string | number
): string => {
  // Sets a default minChar value if inputType is set to minimumCharacters and dynamicValue isn't provided
  if (inputType === ValidationType.MaxiumumCharacters && !dynamicValue) {
    dynamicValue = '100'
  }
  if (inputType === ValidationType.MinimumCharacters && !dynamicValue) {
    dynamicValue = '1'
  }
  const validation: IIndexable = {
    [ValidationType.RequiredField]: i18next.t(
      'forms:validations.required_field',
      {
        value: dynamicValue
      }
    ),
    [ValidationType.Email]: i18next.t('forms:validations.email', {
      value: dynamicValue
    }),
    [ValidationType.MinimumCharacters]: i18next.t(
      'forms:validations.minimum_characters',
      {
        minChars: dynamicValue
      }
    ),
    [ValidationType.MaxiumumCharacters]: i18next.t(
      'forms:validations.maximum_characters',
      {
        maxChars: dynamicValue
      }
    ),
    [ValidationType.TooManyOptionsSelected]: i18next.t(
      'forms:validations.too_many_options_selected'
    ),
    [ValidationType.MustBeNumber]: i18next.t(
      'forms:validations.must_be_number'
    ),
    [ValidationType.OnlyNumbersAndSpecialChars]: i18next.t(
      'forms:validations.only_numbers_and_special_chars'
    )
  }
  return validation[inputType]
}
