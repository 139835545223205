import React, { FunctionComponent } from 'react'
import { BookmarkStatus } from '../../pages/Bookmarks/Bookmarks.model'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinSpinner } from '../../components/ClinSpinner'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { useTranslation } from 'react-i18next'

interface IBookmarkToggleButtonProps {
  /** Indicates whether the bookmark is set, or if it's being updated */
  bookmarkedStatus?: BookmarkStatus
  /** Called when the user wants to toggle the bookmark */
  toggleBookmark?: () => void
  /** Only show the status icon, no text like "Bookmarked" */
  iconOnly?: boolean
  /** Whether to show the hover state */
  isHover?: boolean
  /** Called when mouse leaves the button */
  onMouseEnter?: () => void
  /** Called when mouse enters the button */
  onMouseLeave?: () => void
}

export const BookmarkToggleButton: FunctionComponent<
  IBookmarkToggleButtonProps
> = ({
  bookmarkedStatus = BookmarkStatus.NotBookmarked,
  toggleBookmark,
  onMouseEnter,
  onMouseLeave,
  isHover,
  iconOnly = false
}) => {
  const { t } = useTranslation()

  return (
    <ClinButton
      variant="ghost"
      onClick={() => toggleBookmark && toggleBookmark()}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      {!iconOnly &&
        (bookmarkedStatus === BookmarkStatus.Bookmarked
          ? t('common:buttons.bookmarked')
          : t('common:buttons.bookmark'))}
      &nbsp;
      {bookmarkedStatus === BookmarkStatus.Loading ? (
        <ClinSpinner size={ClinTheme.space[3]} />
      ) : (
        <ClinIcon
          iconName={
            bookmarkedStatus === BookmarkStatus.Bookmarked || isHover
              ? ClinIconPathName.BookmarkFilled
              : ClinIconPathName.Bookmark
          }
        />
      )}
    </ClinButton>
  )
}
