import React, { ChangeEvent, FunctionComponent } from 'react'
import { ClinTheme } from '../../ClinTheme'
import { Col, Row } from 'react-grid-system'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { Controller, useForm } from 'react-hook-form'
import { ClinButton } from '../../components/ClinButton'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinTextarea } from '../../components/ClinTextarea'
import { ClinTextInput } from '../../components/ClinTextInput'
import { yupResolver } from '@hookform/resolvers/yup'
import { array, number, object, string } from 'yup'
import { ClinMultiSelect } from '../../components/ClinMultiSelect'
import { ClinMultiSelectOption } from '../../components/ClinMultiSelect/ClinMultiSelect'
import { ClinAnnounceBar } from '../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import {
  CreateShortageRequestDto,
  CreateSourcingRequestDto
} from '../../types/swaggerTypes'
import {
  getInputValidation,
  ValidationType
} from '../../utils/Forms/getInputValidation'
import { useTranslation } from 'react-i18next'
import {
  getInputPlaceholder,
  PlaceholderType
} from '../../utils/Forms/getInputPlaceholder'
import {
  IDrugRequestFormState,
  PharmaceuticalOptionsRecord,
  convertOptionsToString,
  PharmaceuticalOptions,
  PresentationOptions,
  PresentationOptionsRecord
} from './DrugRequestForm.model'
import { getArrMax250CharsOptions } from './getArrMax250CharsOptions'

interface IDrugRequestFormProps<Dto> {
  /** Form submission handler */
  handleFormSubmission: (data: Dto) => void
  /** Whether this is the report shortage form or the sourcing enquiry form */
  variant: 'reportShortage' | 'sourcingEnquiry'
  /** Show an error if the submission API request failed */
  errorMessage?: string | null
  /** Whether the submission API request is in progress */
  isSubmitting?: boolean
  /** Initial form state */
  defaultState?: IDrugRequestFormState
}

export const DrugRequestForm: FunctionComponent<
  | IDrugRequestFormProps<CreateSourcingRequestDto>
  | IDrugRequestFormProps<CreateShortageRequestDto>
> = ({
  handleFormSubmission,
  isSubmitting,
  variant,
  errorMessage,
  defaultState
}) => {
  const { t } = useTranslation()

  const pharmaceuticalOptions = Object.values(PharmaceuticalOptions).map(
    (option) => {
      return {
        value:
          PharmaceuticalOptionsRecord[option as PharmaceuticalOptions].value,
        label: t(
          PharmaceuticalOptionsRecord[option as PharmaceuticalOptions].transKey
        )
      }
    }
  )

  const presentationOptions = Object.values(PresentationOptions).map(
    (option) => {
      return {
        value: PresentationOptionsRecord[option as PresentationOptions].value,
        label: t(
          PresentationOptionsRecord[option as PresentationOptions].transKey
        )
      }
    }
  )
  const acceptEEALabel = t('sourcing_enquiry:accept_eea_label')
  const requiredText = getInputValidation(ValidationType.RequiredField)
  const maxCharsText = getInputValidation(
    ValidationType.MaxiumumCharacters,
    250
  )

  const reportShortageSchema = object().shape({
    genericName: string().required(requiredText),
    brandName: string().max(250, maxCharsText),
    strength: string(),
    packSize: string().max(250, maxCharsText),
    pharmaceutical:
      variant === 'reportShortage'
        ? array().required(requiredText).min(1).test(getArrMax250CharsOptions)
        : array().test(getArrMax250CharsOptions),
    presentation: array().test(getArrMax250CharsOptions),
    requiredQty: number()
      .nullable()
      .transform((_, val) => (val !== '' ? Number(val) : null))
      .typeError(getInputValidation(ValidationType.MustBeNumber)),
    anythingElse: string().max(250, maxCharsText)
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IDrugRequestFormState>({
    defaultValues: defaultState,
    resolver: yupResolver(reportShortageSchema)
  })

  return (
    <form
      // Disable native browser validation to make yup error messages work
      // for requiredQty/packSize
      // https://github.com/react-hook-form/react-hook-form/issues/1539
      noValidate={true}
      onSubmit={handleSubmit((values) => {
        let countryList = []
        if (values.acceptEEA) {
          countryList.push(acceptEEALabel)
        }
        if (values.acceptUSA) {
          countryList.push('USA')
        }
        if (values.acceptThirdCountries) {
          countryList.push('Third countries')
        }
        const submittedValues = {
          ...values,
          importFlag: values.importFlag ? 'Y' : 'N',
          authFlag: values.authFlag ? 'Y' : 'N',
          controlledDrug: values.controlledDrug ? 'Y' : 'N',
          documentation: values.documentation
            ? convertOptionsToString(values.documentation)
            : '',
          pharmaceutical: values.pharmaceutical
            ? convertOptionsToString(values.pharmaceutical)
            : '',
          // not present in sourcing form
          presentation: values.presentation
            ? convertOptionsToString(values.presentation)
            : '',
          preferredLang: values.preferredLang
            ? convertOptionsToString(values.preferredLang)
            : '',
          requiredQty: values.requiredQty ? parseFloat(values.requiredQty) : 0,
          packSize: values.packSize ? parseFloat(values.packSize) : 0,
          countryList: countryList.join(', ')
        }

        handleFormSubmission(submittedValues)
      })}
    >
      <ClinText marginTop={'0px'} variant={TypographyVariant.LargeParagraph}>
        {t('sourcing_enquiry:mandatory_fields')}
      </ClinText>
      <ClinSpacer height={ClinTheme.space[1]} />

      {errorMessage && (
        <>
          <ClinSpacer />
          <ClinAnnounceBar mode={AnnounceMode.Error}>
            {errorMessage}
          </ClinAnnounceBar>
        </>
      )}

      <ClinText
        variant={TypographyVariant.H4}
        fontWeight={ClinTheme.fontWeights.bold}
      >
        {variant === 'reportShortage'
          ? t('sourcing_enquiry:drug_shortage')
          : t('sourcing_enquiry:your_drug_request')}
      </ClinText>
      <ClinSpacer height={ClinTheme.space[2]} />

      {/* Prop forwarding */}
      <ClinTextInput
        {...register('genericName')}
        id="generic-name"
        label={t('sourcing_enquiry:generic_name_label')}
        width="100%"
        hasError={!!(errors && errors.genericName)}
        prompt={errors.genericName?.message}
      />
      <ClinSpacer height={ClinTheme.space[4]} />
      <Row>
        <Col md={6}>
          <ClinTextInput
            id="brand-name"
            {...register('brandName')}
            label={t('glossary:brand_name')}
            width="100%"
            marginBottom={`${ClinTheme.space[4]}px`}
            hasError={!!(errors && errors.brandName)}
            prompt={errors.brandName?.message}
          />
        </Col>
        <Col md={6}>
          <ClinTextInput
            id="strength"
            {...register('strength')}
            label={t(
              'sourcing_enquiry:sourcing_enquiry_form_aus.strength_label'
            )}
            width="100%"
            hasError={!!(errors && errors.strength)}
            prompt={errors.strength?.message}
          />
        </Col>
      </Row>

      <ClinSpacer height={ClinTheme.space[4]} />
      <Row>
        <Col md={6}>
          <ClinTextInput
            {...register('packSize')}
            type="number"
            min="1"
            id="pack-size"
            label={t('glossary:pack_size')}
            width="100%"
            marginBottom={`${ClinTheme.space[4]}px`}
            hasError={!!(errors && errors.packSize)}
            prompt={errors.packSize?.message}
          />
        </Col>
        <Col md={6}>
          <Controller
            name={'pharmaceutical'}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <ClinMultiSelect
                  placeholder={getInputPlaceholder(PlaceholderType.SelectInput)}
                  options={pharmaceuticalOptions}
                  value={value as ClinMultiSelectOption[]}
                  onChange={onChange}
                  label={
                    variant === 'reportShortage'
                      ? t(
                          'sourcing_enquiry:sourcing_enquiry_form_aus.dosage_form_label_required'
                        )
                      : t(
                          'sourcing_enquiry:sourcing_enquiry_form_aus.dosage_form_label'
                        )
                  }
                  hasError={!!(errors && errors.pharmaceutical)}
                  prompt={
                    //TODO: check why this is a TS error
                    /* @ts-ignore - yup thinks the message type is an array*/
                    errors.pharmaceutical?.message
                  }
                />
              )
            }}
          />
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[4]} />
      <Row>
        <Col md={6}>
          <ClinTextInput
            min="1"
            type="number"
            marginBottom={`${ClinTheme.space[4]}px`}
            id="required-qty"
            {...register('requiredQty')}
            label={t(
              'sourcing_enquiry:sourcing_enquiry_form_aus.required_quantity_label'
            )}
            width="100%"
            hasError={!!(errors && errors.requiredQty)}
            prompt={errors.requiredQty?.message}
          />
        </Col>
        <Col md={6}>
          {variant === 'reportShortage' && (
            <Controller
              name={'presentation'}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <ClinMultiSelect
                    placeholder={getInputPlaceholder(
                      PlaceholderType.SelectInput
                    )}
                    options={presentationOptions}
                    value={value}
                    onChange={onChange}
                    label={t('sourcing_enquiry:presentation_label')}
                    hasError={!!(errors && errors.presentation)}
                    prompt={
                      //TODO: check why this is a TS error
                      /* @ts-ignore - yup thinks the message type is an array*/
                      errors.presentation?.message
                    }
                  />
                )
              }}
            />
          )}
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[4]} />
      <Row>
        <Col xs={12}>
          <ClinText
            variant={TypographyVariant.Paragraph}
            fontWeight={ClinTheme.fontWeights.bold}
            marginBottom={ClinTheme.space[4]}
          >
            {t(
              'sourcing_enquiry:sourcing_enquiry_form_aus.addintional_info_label'
            )}
          </ClinText>
          <Controller
            name="anythingElse"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <ClinTextarea
                  id="anything-else"
                  label={t('sourcing_enquiry:leave_comment')}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    onChange(event)
                  }
                  height={160}
                  value={value}
                  hasError={!!(errors && errors.anythingElse)}
                  prompt={errors.anythingElse?.message}
                />
                <ClinText
                  as="div"
                  fontSize={ClinTheme.fontSizes[1]}
                  marginTop={ClinTheme.space[2]}
                >
                  {t('sourcing_enquiry:personal_info_warning')}
                </ClinText>
              </>
            )}
          />
        </Col>
      </Row>

      <ClinSpacer height={ClinTheme.space[4]} />

      <ClinButton
        type="submit"
        variant="primary"
        disabled={isSubmitting}
        isLoading={isSubmitting}
      >
        {variant === 'reportShortage'
          ? t('sourcing_enquiry:submit_request_btn')
          : t('sourcing_enquiry:submit_enquiry_btn')}
      </ClinButton>
    </form>
  )
}
