import styled from 'styled-components'
import { Link } from 'react-router-dom'

/**
 * A basic wrapper around react-router Link component to remove underline
 */
export const StyledLink = styled(Link)({
  textDecoration: 'none',
  [`:hover`]: {
    textDecoration: 'none'
  }
})

/**
 * A basic wrapper around the react-router Link component for text within a paragraph which needs an underline
 */
export const StyledLinkUnderline = styled(Link)({
  textDecoration: 'underline',
  [`:hover`]: {
    textDecoration: 'underline'
  }
})
