import { useFeatureFlags } from '../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../context/featureFlags/FeatureFlagKeys'

export const useCheckUnableHomePage = () => {
  const { useFeatureFlag } = useFeatureFlags()

  const enableNewHomePage = useFeatureFlag(FeatureFlagKeys.NewHomePage)

  return {
    enableNewHomePage,
    redirectUrl: enableNewHomePage ? '/home' : '/orders'
  }
}
