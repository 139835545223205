import styled from 'styled-components'
import { StepState } from './ClinStepper'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'

const mediumUpBreakpoint = ClinTheme.breakpoints[1]
export interface IClinStepperStyles {
  /** Width of the stepper */
  width: number | string
}

export interface IClinStepStyles {
  /** The state of the step */
  state: StepState
}

interface IStepStyle {
  backgroundColor: string
  color: string
}

const getStepStyle = (state: StepState): IStepStyle => {
  switch (state) {
    case StepState.Active:
      return {
        backgroundColor: ClinTheme.colors.primary,
        color: ClinTheme.colors.white
      }
    case StepState.Complete:
      return {
        backgroundColor: ClinTheme.colors.midGrey,
        color: ClinTheme.colors.black
      }
    default:
      return {
        backgroundColor: ClinTheme.colors.midGrey,
        color: ClinTheme.colors.black
      }
  }
}

export const StyledClinStep = styled.div({
  display: 'inline-flex',
  alignContent: 'flex-start',
  alignItems: 'center',
  flexBasis: '100%',
  marginBottom: ClinTheme.space[3],

  [mediaQuery(mediumUpBreakpoint)]: {
    marginBottom: 0,
    flexDirection: 'row',
    paddingLeft: ClinTheme.space[3],
    borderLeft: `solid 1px ${ClinTheme.colors.midGrey}`
  }
})

export const StyledClinStepper = styled.div<IClinStepperStyles>(
  {
    display: 'flex',
    flexDirection: 'column',

    [mediaQuery(mediumUpBreakpoint)]: {
      flexDirection: 'row'
    },

    [`${StyledClinStep}:last-of-type`]: {
      marginBottom: 0
    }
  },
  ({ width }: IClinStepperStyles) => ({
    width
  })
)

export const StyledStepBadge = styled.div<IClinStepStyles>(
  {
    display: 'inline-block',
    flexShrink: 0,
    color: ClinTheme.colors.primary,
    fontSize: ClinTheme.fontSizes[3],
    fontWeight: ClinTheme.fontWeights.light,
    textAlign: 'center',
    lineHeight: `40px`,
    letterSpacing: ClinTheme.letterSpacing[0],
    width: `40px`,
    height: `40px`,
    borderRadius: '50%',
    [`span`]: {
      paddingTop: '50%',
      paddingBottom: '50%'
    },
    [`.clin-icon`]: { height: '100%' }
  },
  ({ state }: IClinStepStyles) => ({
    color: getStepStyle(state).color,
    backgroundColor: getStepStyle(state).backgroundColor
  })
)
