import React, { FunctionComponent } from 'react'
import { Col, Hidden, Row } from 'react-grid-system'
import { ClinTheme } from '../../../ClinTheme'
import { ClinChip } from '../../../components/ClinChip'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import { ClinText } from '../../../components/ClinText'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { IconValueCell } from '../../../features/IconValueCell'
import {
  StyledDocumentationIcon,
  StyledDocumentsLinkContainer
} from '../../Products/ProductDetail/ProductDetail.styles'
import {
  StyledProductCell,
  StyledProductHeader
} from '../../Products/ProductVariant/ProductVariant.styles'
import {
  StyledContact,
  StyledContactLink,
  StyledDocumentCard,
  StyledDocumentCardInner,
  StyledNoProgramDocuments,
  StyledProgramAccordion,
  StyledProgramDetail,
  StyledProgramDetailSidebar,
  StyledProgramDetailSidebarInner,
  StyledProgramDetailSidebarTimes
} from './ProgramDetail.styles'
import { CustomSelect } from '../../../features/CustomSelect'
import {
  CountryDto,
  OrgAddressDto,
  PhysiciansEnrolledSummaryDto,
  ProgramCatalogDto
} from '../../../types/swaggerTypes'
import { ICustomSelectOption } from '../../../features/CustomSelect/CustomSelect'
import { ClinButton } from '../../../components/ClinButton'
import { ClinAnnounceBar } from '../../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinGroup } from '../../../components/ClinGroup'
import { isArray } from 'lodash'
import { programStatusColorRecord } from '../../../constants'
import { ProgramUiState, SideBarMode } from './ProgramDetail.models'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { StyledLink, StyledLinkUnderline } from '../../../components/StyledLink'
import { Trans, useTranslation } from 'react-i18next'
import {
  getInputPlaceholder,
  PlaceholderType
} from '../../../utils/Forms/getInputPlaceholder'

interface IProgramDetailProps {
  /** Whether we are loading or not */
  isLoading: boolean
  /** Whether there is an error loading */
  hasError: boolean
  /** Selected program */
  program?: ProgramCatalogDto
  /** Selected program status */
  programUiState?: ProgramUiState
  /** Available physicians */
  physicians?: PhysiciansEnrolledSummaryDto[]
  /** Selected physician */
  selectedPhysician?: PhysiciansEnrolledSummaryDto
  /** Users default shipping address or newly selected address */
  shippingAddress?: OrgAddressDto
  /** Support contact details **/
  supportContact: CountryDto
  /** Display link to documents */
  documentCount?: number
  /** A function that returns the selected physician */
  handlePhysicianSelect?: (option: ICustomSelectOption) => void
  /** A function that runs when a user begins a product order */
  handleBeginProductOrder?: () => void
  /** A function that runs a user requests to change their address */
  handleOpenAddressModal?: () => void
  /** A function that runs when a user requests to register an interest */
  handleRegisterInterestRequest?: () => void
  /** A function that runs when a user requests to enrol in a program */
  handleEnrolRequest: () => void
  /** A function that runs when a user requests to enrol a physician in a program */
  handlePhysicianEnrolRequest?: () => void
}

export const ProgramDetailOld: FunctionComponent<IProgramDetailProps> = ({
  isLoading,
  hasError,
  program,
  programUiState,
  physicians,
  selectedPhysician,
  shippingAddress,
  supportContact,
  documentCount,
  handlePhysicianSelect,
  handleBeginProductOrder,
  handleRegisterInterestRequest,
  handleEnrolRequest,
  handleOpenAddressModal,
  handlePhysicianEnrolRequest
}) => {
  const { t } = useTranslation()

  const programs = program?.program
  const opaResupplyOrderEstimateTime: string | undefined | null =
    programs?.opaResupplyOrderEstimateTime
  const opaInitialOrderEstimateTime: string | undefined | null =
    programs?.opaInitialOrderEstimateTime

  return isLoading || !program || !programUiState ? (
    <>
      {!hasError && (
        <ClinPageContentFrame className="program-detail">
          <Row align="center">
            <Col>
              <StyledSpinnerContainer>
                <ClinSpinner size={ClinTheme.space[7]} />
              </StyledSpinnerContainer>
            </Col>
          </Row>
        </ClinPageContentFrame>
      )}
    </>
  ) : (
    <ClinPageContentFrame
      className="program-detail"
      crumbs={[
        { path: '/orders', name: t('orders:page_name') },
        { name: t('access_programs:title'), path: '/programs/access-programs' },
        {
          name: program?.programName ?? '',
          path: `/programs/access-programs/${program?.programDocumentId}`
        }
      ]}
    >
      <StyledProgramDetail>
        <ClinSpacer />
        <Row>
          <Col sm={10}>
            <ClinText
              className="program-name"
              as="h1"
              variant={TypographyVariant.H2}
              fontWeight={ClinTheme.fontWeights.bold}
            >
              {program?.programName}
            </ClinText>
          </Col>
        </Row>
        <ClinSpacer />
        <StyledProductHeader>
          <StyledProductCell>
            <Hidden xs={true} sm={true}>
              <ClinIcon
                iconName={ClinIconPathName.TherapeuticArea}
                iconSize={ClinTheme.space[4]}
              />
            </Hidden>
            <ClinText
              className="program-tags"
              as="div"
              fontWeight={ClinTheme.fontWeights.medium}
              marginLeft={ClinTheme.space[2]}
              marginRight={ClinTheme.space[3]}
            >
              {t('glossary:therapeutic_area')}
            </ClinText>
            {program?.tags?.map((tag: any, index: number) => (
              <ClinChip key={`ta-${tag}-${index}`}>{tag}</ClinChip>
            ))}
          </StyledProductCell>
        </StyledProductHeader>
        <ClinSpacer height={ClinTheme.space[5]} />
        <Row>
          <Col sm={12}>
            <Row justify="between">
              <Col sm={12} lg={8}>
                <StyledProgramAccordion>
                  <ClinText
                    as="div"
                    variant={TypographyVariant.H4}
                    fontWeight={ClinTheme.fontWeights.normal}
                  >
                    {t('glossary:program_details')}
                  </ClinText>
                  <IconValueCell
                    iconName={ClinIconPathName.Status}
                    title={t('glossary:status')}
                    maxTitleWidth={'50%'}
                  >
                    <ClinText
                      className="program-status"
                      as="div"
                      variant={TypographyVariant.LargeParagraph}
                      marginLeft={ClinTheme.space[4]}
                      color={
                        programUiState.programUiStatus &&
                        programStatusColorRecord[programUiState.programUiStatus]
                          .color
                      }
                      fontWeight={ClinTheme.fontWeights.bold}
                    >
                      {programUiState.statusText}
                    </ClinText>
                  </IconValueCell>
                  <IconValueCell
                    iconName={ClinIconPathName.BrandName}
                    title={t('glossary:product')}
                    maxTitleWidth={'50%'}
                  >
                    <>
                      {program.program && (
                        <ClinText
                          className="program-brand-name"
                          as="div"
                          variant={TypographyVariant.LargeParagraph}
                          marginLeft={ClinTheme.space[4]}
                        >
                          {program.program.brand}
                        </ClinText>
                      )}
                    </>
                  </IconValueCell>
                  <IconValueCell
                    iconName={ClinIconPathName.DocumentSponsor}
                    title={t('glossary:sponsor')}
                    maxTitleWidth={'50%'}
                  >
                    <ClinText
                      className="program-sponsor"
                      as="p"
                      variant={TypographyVariant.LargeParagraph}
                      marginLeft={ClinTheme.space[4]}
                    >
                      {program.sponsor}
                    </ClinText>
                  </IconValueCell>
                  {programUiState.hasProgramCharge && (
                    <IconValueCell
                      iconName={ClinIconPathName.UnitPrice}
                      title={t('glossary:program_charge')}
                      maxTitleWidth={'50%'}
                    >
                      <ClinText
                        className="program-charge"
                        as="p"
                        variant={TypographyVariant.LargeParagraph}
                        marginLeft={ClinTheme.space[4]}
                      >
                        {t('glossary:yes')}
                      </ClinText>
                    </IconValueCell>
                  )}
                  <ClinSpacer />
                  {programUiState.sideBarMode === SideBarMode.Enrolled && (
                    <>
                      <ClinText
                        as="div"
                        variant={TypographyVariant.H4}
                        fontWeight={ClinTheme.fontWeights.normal}
                      >
                        {t('glossary:documentation')}
                      </ClinText>
                      <ClinText
                        as="div"
                        variant={TypographyVariant.Paragraph}
                        fontWeight={ClinTheme.fontWeights.normal}
                      >
                        {t('program_detail:documentation_subtitle')}
                      </ClinText>
                      <ClinSpacer />
                      {documentCount && documentCount > 0 ? (
                        <StyledDocumentCard>
                          <StyledDocumentCardInner>
                            <StyledDocumentationIcon>
                              <ClinIcon
                                iconName={ClinIconPathName.Document}
                                iconSize={ClinTheme.space[9]}
                                iconFill={ClinTheme.colors.magenta}
                              />
                            </StyledDocumentationIcon>
                            <StyledDocumentsLinkContainer>
                              <ClinText
                                as="div"
                                variant={TypographyVariant.LargeParagraph}
                                color={ClinTheme.colors.primary}
                                marginLeft={ClinTheme.space[3]}
                              >
                                {t('program_detail:documents_count', {
                                  documentCount
                                })}
                                <br />
                                <StyledLink
                                  to={`/programs/access-programs/${program.projectId}/documentation`}
                                >
                                  <ClinButton variant="linkButton">
                                    {t('program_detail:documents_link')}
                                    <ClinIcon
                                      style={{ marginLeft: ClinTheme.space[1] }}
                                      iconName={ClinIconPathName.ArrowRight}
                                    />
                                  </ClinButton>
                                </StyledLink>
                              </ClinText>
                            </StyledDocumentsLinkContainer>
                          </StyledDocumentCardInner>
                        </StyledDocumentCard>
                      ) : (
                        <StyledNoProgramDocuments>
                          <ClinIcon
                            iconSize={ClinTheme.fontSizes[7] * 2}
                            iconName={ClinIconPathName.Document}
                            iconFill={ClinTheme.colors.grey}
                          />
                          <ClinSpacer />
                          <ClinText color={ClinTheme.colors.primary}>
                            {t('program_detail:no_documents_available')}
                          </ClinText>
                        </StyledNoProgramDocuments>
                      )}
                    </>
                  )}
                </StyledProgramAccordion>
              </Col>
              <Col sm={12} lg={4}>
                {programUiState.sideBarMode === SideBarMode.Enrolled && (
                  <StyledProgramDetailSidebar className="enrolled">
                    <StyledProgramDetailSidebarInner>
                      <ClinText
                        as="div"
                        variant={TypographyVariant.H4}
                        marginTop={'0px'}
                      >
                        {t('program_detail:begin_order_or_resupply')}
                      </ClinText>
                      <ClinSpacer height={ClinTheme.space[2]} />
                      {t('program_detail:begin_order_help_msg')}
                      <ClinSpacer height={ClinTheme.space[7]} hasBorder />
                    </StyledProgramDetailSidebarInner>
                    <StyledProgramDetailSidebarInner className="shipping-address">
                      <ClinText
                        as="h5"
                        variant={TypographyVariant.H5}
                        marginTop="0"
                        marginBottom={ClinTheme.space[3]}
                      >
                        {t('program_detail:review_delivery_address')}
                      </ClinText>

                      {shippingAddress && (
                        <>
                          {shippingAddress.address1 && (
                            <ClinText
                              className="shipping-address-1"
                              fontWeight={ClinTheme.fontWeights.bold}
                              fontSize={ClinTheme.fontSizes[2]}
                            >
                              {shippingAddress.address1},
                            </ClinText>
                          )}
                          {shippingAddress.address2 && (
                            <ClinText
                              className="shipping-address-2"
                              fontWeight={ClinTheme.fontWeights.bold}
                              fontSize={ClinTheme.fontSizes[2]}
                            >
                              {shippingAddress.address2},
                            </ClinText>
                          )}
                          {shippingAddress.address3 && (
                            <ClinText
                              className="shipping-address-3"
                              fontWeight={ClinTheme.fontWeights.bold}
                              fontSize={ClinTheme.fontSizes[2]}
                            >
                              {shippingAddress.address3},
                            </ClinText>
                          )}
                          {shippingAddress.address4 && (
                            <ClinText
                              className="shipping-address-4"
                              fontWeight={ClinTheme.fontWeights.bold}
                              fontSize={ClinTheme.fontSizes[2]}
                            >
                              {shippingAddress.address4},
                            </ClinText>
                          )}
                          {shippingAddress.state && (
                            <ClinText
                              className="shipping-address-state"
                              fontWeight={ClinTheme.fontWeights.bold}
                              fontSize={ClinTheme.fontSizes[2]}
                            >
                              {shippingAddress.state},
                            </ClinText>
                          )}
                          {shippingAddress.city && (
                            <ClinText
                              className="shipping-address-city"
                              fontWeight={ClinTheme.fontWeights.bold}
                              fontSize={ClinTheme.fontSizes[2]}
                            >
                              {shippingAddress.city},
                            </ClinText>
                          )}
                          {shippingAddress.postalCode && (
                            <ClinText
                              className="shipping-address-postal-code"
                              fontWeight={ClinTheme.fontWeights.bold}
                              fontSize={ClinTheme.fontSizes[2]}
                            >
                              {shippingAddress.postalCode}
                            </ClinText>
                          )}
                        </>
                      )}
                      <ClinSpacer height={ClinTheme.space[3]} />
                      <ClinButton
                        onClick={() =>
                          handleOpenAddressModal && handleOpenAddressModal()
                        }
                        variant="linkButton"
                      >
                        {t('program_detail:change_address')}{' '}
                        <ClinIcon
                          style={{ marginLeft: ClinTheme.space[1] }}
                          iconName={ClinIconPathName.Marker}
                        />
                      </ClinButton>
                      <ClinSpacer height={ClinTheme.space[7]} hasBorder />
                      <ClinText
                        as="h5"
                        variant={TypographyVariant.H5}
                        marginTop="0"
                        marginBottom={ClinTheme.space[3]}
                      >
                        {t('program_detail:select_current_physician')}
                      </ClinText>
                      <ClinText
                        as="p"
                        marginTop={ClinTheme.space[3]}
                        marginBottom={ClinTheme.space[3]}
                      >
                        <Trans
                          i18nKey="program_detail:transfer_the_patient"
                          components={{
                            link: (
                              <StyledLinkUnderline
                                to={'/support/access-program'}
                              />
                            )
                          }}
                        >
                          <></>
                        </Trans>
                      </ClinText>

                      <CustomSelect
                        label={t('program_detail:physician_select_label')}
                        placeholder={
                          (physicians && physicians.length === 0) || !physicians
                            ? t('program_detail:no_enrolled_physicians')
                            : getInputPlaceholder(PlaceholderType.SelectInput)
                        }
                        options={
                          physicians && isArray(physicians)
                            ? physicians.map(
                                (physician: PhysiciansEnrolledSummaryDto) => {
                                  return {
                                    id: physician.physicianId,
                                    value: `${physician.physicianTitle ?? ''} ${
                                      physician.physicianFirstName
                                    } ${physician.physicianLastName}`,
                                    isActive:
                                      physician.physicianLinkedToUser === 'Y'
                                  }
                                }
                              )
                            : []
                        }
                        selectedOption={
                          selectedPhysician && {
                            id: selectedPhysician.physicianId,
                            value: `${
                              selectedPhysician?.physicianTitle ?? ''
                            } ${selectedPhysician?.physicianFirstName} ${
                              selectedPhysician?.physicianLastName
                            }`
                          }
                        }
                        handleSelect={(option) =>
                          handlePhysicianSelect && handlePhysicianSelect(option)
                        }
                      />

                      {selectedPhysician &&
                        selectedPhysician.physicianLinkedToUser === 'N' && (
                          <>
                            <ClinSpacer />
                            <ClinAnnounceBar
                              mode={AnnounceMode.Warning}
                              title={' '}
                            >
                              <Trans
                                i18nKey="program_detail:you_are_not_associated"
                                components={{ bold: <strong /> }}
                              >
                                <></>
                              </Trans>
                            </ClinAnnounceBar>
                          </>
                        )}
                      <ClinSpacer height={ClinTheme.space[7]} hasBorder />
                      <ClinText
                        variant={TypographyVariant.LargeParagraph}
                        marginTop="0"
                      >
                        {t('program_detail:physician_not_on_the_list')}
                      </ClinText>
                      <ClinButton
                        onClick={() =>
                          handlePhysicianEnrolRequest &&
                          handlePhysicianEnrolRequest()
                        }
                        variant="linkButton"
                      >
                        <span style={{ whiteSpace: 'break-spaces' }}>
                          {t('program_detail:enrol_in_program_btn')}
                        </span>

                        <ClinIcon
                          style={{
                            marginLeft: ClinTheme.space[2]
                          }}
                          iconName={ClinIconPathName.Enrol}
                        />
                      </ClinButton>
                      <ClinSpacer height={ClinTheme.space[5]} />
                      <ClinButton
                        variant="primary"
                        onClick={() =>
                          handleBeginProductOrder && handleBeginProductOrder()
                        }
                        disabled={!selectedPhysician}
                        style={{ width: '100%' }}
                      >
                        {t('program_detail:begin_product_order')}
                      </ClinButton>
                    </StyledProgramDetailSidebarInner>
                  </StyledProgramDetailSidebar>
                )}

                {programUiState.sideBarMode ===
                  SideBarMode.NotAvailableInYourCountry && (
                  <StyledProgramDetailSidebar className="not-available-in-your-country">
                    <StyledProgramDetailSidebarInner>
                      <ClinText
                        as="h4"
                        variant={TypographyVariant.H4}
                        marginTop="0"
                      >
                        {t('program_detail:register_interest')}
                      </ClinText>
                      <ClinText
                        variant={TypographyVariant.LargeParagraph}
                        marginBottom={ClinTheme.space[5]}
                      >
                        {t('program_detail:program_not_available')}
                      </ClinText>
                      <ClinButton
                        variant="primary"
                        style={{ width: '100%' }}
                        onClick={() =>
                          handleRegisterInterestRequest &&
                          handleRegisterInterestRequest()
                        }
                      >
                        {t('program_detail:register_interest')}
                      </ClinButton>
                    </StyledProgramDetailSidebarInner>
                  </StyledProgramDetailSidebar>
                )}

                {programUiState.sideBarMode === SideBarMode.NotEnrolled && (
                  <StyledProgramDetailSidebar className="not-enrolled">
                    <StyledProgramDetailSidebarInner>
                      <ClinText
                        as="h4"
                        variant={TypographyVariant.H4}
                        marginTop="0"
                      >
                        {t('program_detail:enrol_in_program')}
                      </ClinText>
                      <ClinText
                        variant={TypographyVariant.LargeParagraph}
                        marginBottom={ClinTheme.space[5]}
                      >
                        {t('program_detail:for_more_information', {
                          programName: program.programName
                        })}
                      </ClinText>
                      <ClinButton
                        variant="primary"
                        style={{ width: '100%' }}
                        onClick={() =>
                          handleEnrolRequest && handleEnrolRequest()
                        }
                      >
                        {t('program_detail:enrol')}
                      </ClinButton>
                    </StyledProgramDetailSidebarInner>
                  </StyledProgramDetailSidebar>
                )}

                {programUiState.sideBarMode === SideBarMode.Closed && (
                  <StyledProgramDetailSidebar className="closed">
                    <StyledProgramDetailSidebarInner>
                      <ClinText
                        as="h4"
                        variant={TypographyVariant.H4}
                        marginTop="0"
                      >
                        {t('program_detail:program_closed')}
                      </ClinText>
                      <ClinText
                        variant={TypographyVariant.LargeParagraph}
                        marginBottom={ClinTheme.space[5]}
                      >
                        {t('program_detail:program_closed_description')}
                      </ClinText>
                      <StyledContact>
                        <ClinIcon iconName={ClinIconPathName.Phone} />
                        <StyledContactLink
                          href={`tel:${supportContact.rawPhoneNumber}`}
                        >
                          <ClinText
                            color={ClinTheme.colors.primaryMid}
                            as="span"
                          >
                            {supportContact.csPhoneNumber}
                          </ClinText>
                        </StyledContactLink>
                      </StyledContact>
                      <StyledContact>
                        <ClinIcon iconName={ClinIconPathName.Mail} />
                        <StyledContactLink
                          href={`mailto:${supportContact.csEmailAddress}`}
                          onClick={() => {
                            analyticsServiceSingleton.trackEvent(
                              AnalyticsEvent.ContactClicked,
                              { contactType: 'email' }
                            )
                          }}
                        >
                          <ClinText
                            color={ClinTheme.colors.primaryMid}
                            as="span"
                            wordBreak="break-all"
                          >
                            {supportContact.csEmailAddress}
                          </ClinText>
                        </StyledContactLink>
                      </StyledContact>
                    </StyledProgramDetailSidebarInner>
                  </StyledProgramDetailSidebar>
                )}

                {programUiState.sideBarMode === SideBarMode.Enrolled && (
                  <StyledProgramDetailSidebarTimes>
                    <ClinText
                      fontWeight={ClinTheme.fontWeights.medium}
                      fontSize={ClinTheme.fontSizes[2]}
                    >
                      {t('program_detail:product_orders_typically_take')}
                    </ClinText>

                    <ClinSpacer height={ClinTheme.space[4]} />
                    <ClinGroup alignItems="center" space={4}>
                      <ClinIcon
                        iconName={ClinIconPathName.CustomClock}
                        iconSize={ClinTheme.fontSizes[4]}
                        iconFill={ClinTheme.colors.primary}
                      />
                      <ClinText
                        fontWeight={ClinTheme.fontWeights.medium}
                        textTransform="uppercase"
                        color={ClinTheme.colors.primary}
                      >
                        {t('program_detail:resupply_time', {
                          time: opaResupplyOrderEstimateTime
                        })}
                      </ClinText>
                    </ClinGroup>
                    <ClinSpacer height={ClinTheme.space[2]} />
                    <ClinGroup alignItems="center" space={4}>
                      <ClinIcon
                        iconName={ClinIconPathName.CustomClock}
                        iconSize={ClinTheme.fontSizes[4]}
                        iconFill={ClinTheme.colors.primary}
                      />
                      <ClinText
                        fontWeight={ClinTheme.fontWeights.medium}
                        textTransform="uppercase"
                        color={ClinTheme.colors.primary}
                      >
                        {t('program_detail:initial_order_time', {
                          time: opaInitialOrderEstimateTime
                        })}
                      </ClinText>
                    </ClinGroup>
                  </StyledProgramDetailSidebarTimes>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledProgramDetail>
    </ClinPageContentFrame>
  )
}
