import React, { FunctionComponent } from 'react'
import { StyledClinTableToggleRowButton } from './ClinTableToggleRowButton.styles'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinTheme } from '../../ClinTheme'

interface IClinTableToggleRowButton {
  /** Click event handler */
  onClick: () => void
  /** Whether the row is expanded */
  isToggled: boolean | undefined
}

export const ClinTableToggleRowButton: FunctionComponent<
  IClinTableToggleRowButton
> = ({ isToggled, onClick }) => (
  <StyledClinTableToggleRowButton
    className={`icon-toggle ${isToggled ? 'icon-toggle--open' : ''}`}
    iconName={ClinIconPathName.ChevronDown}
    iconFill={ClinTheme.colors.primary}
    iconSize={ClinTheme.space[3]}
    onClick={(event) => {
      event.preventDefault()
      event.stopPropagation()
      onClick()
    }}
  />
)
