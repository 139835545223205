import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { Col, Row } from 'react-grid-system'
import { ClinText } from '../../../components/ClinText'
import { ClinTheme } from '../../../ClinTheme'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinButton } from '../../../components/ClinButton'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { boolean, number, object, string } from 'yup'
import {
  ValidationType,
  getInputValidation
} from '../../../utils/Forms/getInputValidation'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ClinTextInput } from '../../../components/ClinTextInput'
import {
  StyledDisablePatinetButton,
  StyledDisablePatinetButtonWrapper,
  StyledDuplicatePatientWrapper,
  StyledEnrollButton,
  StyledSectionWrapper,
  StyledSeparator,
  StyledTitleButtonWrapper,
  StyledTitleWrapper,
  StyledYearSelect,
  StyledYearSelectOptions,
  StyledYearSelectTrigger
} from './CreatePatient.styles'
import { ClinCheckbox } from '../../../components/ClinCheckbox'
import { useHistory } from 'react-router'

import {
  LovDto,
  PatientSummaryDto,
  PhysiciansEnrolledSummaryDto,
  ProgramSuggestionDto
} from '../../../types/swaggerTypes'

import { ClinDatePicker } from '../../../components/ClinDatePicker'
import { CreatePatientsViewMode } from '../PatientDashboard/Patient.model'
import { StyledSpinnerContainer } from '../../OpaCheckout/OpaCheckout.styles'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { ClinLoader } from '../../../components/ClinLoader'
import { ClinTable } from '../../../components/ClinTable'
import {
  ClinPatientTableHeaderCell,
  ClinTableBodyCell,
  ClinTableRow
} from '../../../components/ClinTable/ClinTable'
import { IColumn } from '../PatientDashboard/PatientDashboardContainer'
import { ProgramAutoSuggest } from '../../../features/Search/ProgramAutoSuggest/ProgramAutoSuggest'
import { ProgramSearch } from '../../../features/Search/ProgramAutoSuggest/ProgramSearch'
import {
  PlaceholderType,
  getInputPlaceholder
} from '../../../utils/Forms/getInputPlaceholder'
import { isArray } from 'lodash'
import { CustomPhysiciansSelect } from '../../../features/CustomSelect/CustomPhysiciansSelect'
import { ICustomSelectOption } from '../../../features/CustomSelect/CustomSelect'
import {
  DOBGreaterThan18ProgramIds,
  DOBLesserThan18ProgramIds,
  ICreatePatientFormState,
  InitialsToAAAProgramsIds,
  InitialsToZZZProgramsIds,
  MonthYearOnlyProgramsIds,
  SotaterceptId,
  YearOnlyProgramIds,
  YearOnlySet1950ProgramIds,
  defaultState,
  is18YearsOfAge,
  isRWDMandatoryForProgramCountry,
  maximumDate,
  monthOptions,
  yearOptions
} from './CreatePatient.model'
import { useOnMount } from '../../../utils/useOnMount'
import { ClinSelect } from '../../../components/ClinSelect'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import useEmailValidation from '../../../hooks/useCheckEmailError/useCheckEmailError'
import {
  clearAnnounceEvent,
  createAnnounceEvent
} from '../../../events/AnnounceEvent'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { useAppContext } from '../../../context/app'
import { Tooltip } from 'react-tippy'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { ClinDropdown } from '../../../components/ClinDropdown'
import {
  StyledCustomSelectOption,
  StyledCustomSelectTick,
  StyledCustomSelectTriggerInner
} from '../../../features/CustomSelect/CustomSelect.styles'
import { DateTime } from 'luxon'
import dayjs from 'dayjs'

interface IOneStepCheckoutOptionsProps {
  physicians?: PhysiciansEnrolledSummaryDto[]
  patients?: PatientSummaryDto[]
  selectedProgramId?: string
  viewMode: CreatePatientsViewMode
  countryCode: string
  columns: IColumn[]
  isPhysicianLoading?: boolean
  physicianPermissionVisible: boolean
  physicianPermission: boolean
  selectedPhysician: ICustomSelectOption | undefined
  preselectedPhysician: string
  //if we are redirected from programd details page stock order button
  selectStockOrder?: boolean
  backButtonUrl: string
  enrolledPrograms?: string[]
  jobTitles: LovDto[]
  setPhysicianPermission: (flag: boolean) => void
  setIsEnrolled: (flag: boolean) => void
  handlePhysicianSelected: (option: ICustomSelectOption) => void
  handleCreatePatientSubmit: (
    values: ICreatePatientFormState,
    flag: boolean
  ) => void
  handleGoToExistingPatient: (physicianId: string, patientId: number) => void
  handleEditDetails: () => void
  handleProgramChanged: (programId: number) => void
}

export const CreatePatient: FunctionComponent<IOneStepCheckoutOptionsProps> = ({
  physicians,
  selectedPhysician,
  patients,
  viewMode,
  columns,
  selectedProgramId,
  countryCode,
  isPhysicianLoading,
  physicianPermission,
  physicianPermissionVisible,
  preselectedPhysician,
  selectStockOrder,
  backButtonUrl,
  enrolledPrograms,
  jobTitles,
  setPhysicianPermission,
  setIsEnrolled,
  handlePhysicianSelected,
  handleCreatePatientSubmit,
  handleGoToExistingPatient,
  handleEditDetails,
  handleProgramChanged
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { dispatch } = useAppContext()
  const requiredText = getInputValidation(ValidationType.RequiredField)
  const [documentsLink, setDocumentsLink] = useState<string | null>(null)

  const handleResupplyError = (flag: boolean) => {
    if (flag) {
      dispatch(
        createAnnounceEvent(
          AnnounceMode.Error,
          'This program is no longer accepting new patients. It is only open to existing patients. Please search for your patient on the patient dashboard or contact customer service'
        )
      )
    } else {
      dispatch(clearAnnounceEvent())
    }
  }

  const createPatientSchema = object().shape({
    patientInitials: string()
      .matches(
        /^[a-zA-Z0-9]*$/,
        t('patient_detail:patient_initials_special_characters')
      )
      .max(8, t('patient_detail:patient_initials_max_length'))
      .when('stockOrderFlag', {
        is: false,
        then: string().required(requiredText)
      }),
    dateOfBirth: string().when('stockOrderFlag', {
      is: false,
      then: string()
        .required(requiredText)
        .test({
          name: 'valid-date',
          message: t('patient_detail:create_patients.date_of_birth_invalid'),
          test: (value) => {
            if (!value) return false
            return dayjs(value, 'MMMM D, YYYY', true).isValid()
          }
        })
        .test({
          name: 'max-date',
          message: t('patient_detail:create_patients.date_of_birth_max_date'),
          test: (value) => {
            const maxDate = dayjs(
              `${maximumDate.year}-${maximumDate.month}-${maximumDate.day}`
            )

            return (
              dayjs(value, 'MMMM D, YYYY').isBefore(maxDate) ||
              dayjs(value, 'MMMM D, YYYY').isSame(maxDate)
            )
          }
        })
    }),
    instituteIdentifier: string().max(
      100,
      getInputValidation(ValidationType.MaxiumumCharacters, 100)
    ),
    program: number().when('stockOrderFlag', {
      is: false,
      then: number().required(requiredText)
    }),
    physician: string().required(requiredText),
    realWorldDataEnrolFlag: boolean(),
    stockOrderFlag: boolean(),
    rwdNominationDetails: object().when('realWorldDataEnrolFlag', {
      is: true,
      then: object().shape({
        nominationFlag: boolean().required(),
        fullName: string().when('nominationFlag', {
          is: true,
          then: string().required(requiredText)
        }),
        emailAddress: string().when('nominationFlag', {
          is: true,
          then: string().required(requiredText)
        }),
        classification: string().when('nominationFlag', {
          is: true,
          then: string().required(requiredText)
        })
      }),
      otherwise: object().strip()
    })
  })

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    control,
    getValues,
    setValue,
    trigger,
    watch
  } = useForm<ICreatePatientFormState>({
    defaultValues: defaultState,
    resolver: yupResolver(createPatientSchema),
    mode: 'onChange' // Adds real-time validation
  })

  const { emailErrorMessage, checkEmail } = useEmailValidation()

  const [selectedProgram, setSelectedProgram] = useState<
    ProgramSuggestionDto | undefined
  >()

  const [showEnrollButton, setShowEnrollButton] = useState<boolean>(
    !!(selectedProgram && !selectedProgram.isUserEnrolledInProgram)
  )

  const [disableFields, setDisableFields] = useState<boolean>(
    showEnrollButton || !selectedProgram
  )
  const [isInitialsDisabled, setIsInitialsDisabled] = useState<boolean>(false)
  const [isRWDMandatory, setIsRWDMandatory] = useState<boolean>(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false)
  const [isTripleInitials, setIsTripleInitials] = useState<boolean>(false)
  const [showTripleInitialsError, setShowTripleInitialsError] =
    useState<boolean>(false)
  const [showOver18Error, setShowOver18Error] = useState<boolean>(false)
  const [showUnder18Error, setShowUnder18Error] = useState<boolean>(false)
  const [yearOnlyDatePicker, setYearOnlyDatePicker] = useState<boolean>(false)
  const [yearOnlyPreSetDatePicker, setYearPreSetOnlyDatePicker] =
    useState<boolean>(false)
  const [monthAndYearDatePicker, setMonthAndYearDatePicker] =
    useState<boolean>(false)
  const [selectedYear, setSelectedYear] = useState<ICustomSelectOption>()
  const [selectedMonth, setSelectedMonth] = useState<ICustomSelectOption>()
  const [isStockOnly, setIsStockOnly] = useState<boolean>(false)

  const watchProgram = watch('program')
  const watchStockOrder = watch('stockOrderFlag')
  const watchPhysician = watch('physician')
  const watchRealWorldDataEnrolFlag = watch('realWorldDataEnrolFlag')
  const watchNominationFlag = watch('rwdNominationDetails.nominationFlag')
  const watchDob = watch('dateOfBirth')
  const watchInitials = watch('patientInitials')

  useEffect(() => {
    setShowEnrollButton(
      !!(selectedProgram && !selectedProgram.isUserEnrolledInProgram)
    )
    setDisableFields(
      !!(selectedProgram && !selectedProgram.isUserEnrolledInProgram) ||
        !selectedProgram ||
        !!watchStockOrder
    )
  }, [selectedProgram, watchStockOrder])

  useEffect(() => {
    if (!watchRealWorldDataEnrolFlag || !watchNominationFlag) {
      setValue('rwdNominationDetails.nominationFlag', false)
      setValue('rwdNominationDetails.emailAddress', '')
      setValue('rwdNominationDetails.fullName', '')
      setValue('rwdNominationDetails.classification', '')
    }
  }, [watchRealWorldDataEnrolFlag, watchNominationFlag])

  useEffect(() => {
    if (isStockOnly) {
      setValue('stockOrderFlag', true)
      setDisableFields(true)
    }
  }, [isStockOnly, setValue, setDisableFields])

  const handleEnrollProgram = () => {
    const programId = selectedProgram?.programId
    history.push({
      pathname: `/programs/access-programs/${programId}/enrolment-form`,
      state: {
        from: window.location.pathname
      }
    })
  }

  useEffect(() => {
    if (isDirty) {
      trigger('physician')
    }
  }, [watchPhysician])

  useOnMount(() => {
    if (selectStockOrder) {
      setValue('stockOrderFlag', selectStockOrder)
    }
  })

  useEffect(() => {
    selectedProgramId && setValue('program', Number(selectedProgramId))
    selectedPhysician && setValue('physician', selectedPhysician.id.toString())
    selectedProgram && setIsTripleInitials(false)
    selectedProgramId &&
      setDocumentsLink(
        `/programs/access-programs/${selectedProgramId}?pageSize=5&selectedTabIndex=2`
      )
    selectedProgram?.realWorldDataEnrolFlag &&
      setIsRWDMandatory(isRWDMandatoryForProgramCountry(countryCode))
    selectedProgram?.realWorldDataEnrolFlag &&
      setValue(
        'realWorldDataEnrolFlag',
        isRWDMandatoryForProgramCountry(countryCode)
      )
    if (
      selectedProgramId &&
      InitialsToZZZProgramsIds.includes(Number(selectedProgramId))
    ) {
      setValue('patientInitials', 'ZZZ')
      setIsInitialsDisabled(true)
    } else {
      const currentInitials = getValues('patientInitials')
      if (currentInitials === 'ZZZ') {
        setValue('patientInitials', '')
      }
      setIsInitialsDisabled(false)
    }
    if (
      selectedProgramId &&
      InitialsToAAAProgramsIds.includes(Number(selectedProgramId))
    ) {
      setIsTripleInitials(true)
      setValue('patientInitials', '')
    } else setShowTripleInitialsError(false)

    if (
      selectedProgramId &&
      YearOnlyProgramIds.includes(Number(selectedProgramId))
    ) {
      setYearOnlyDatePicker(true)
      setMonthAndYearDatePicker(false)
      setYearPreSetOnlyDatePicker(false)
    } else if (
      selectedProgramId &&
      YearOnlySet1950ProgramIds.includes(Number(selectedProgramId))
    ) {
      setYearPreSetOnlyDatePicker(true)
      setMonthAndYearDatePicker(false)
      setYearOnlyDatePicker(false)
    } else if (
      selectedProgramId &&
      MonthYearOnlyProgramsIds.includes(Number(selectedProgramId)) &&
      Number(selectedProgramId) !== SotaterceptId
    ) {
      setMonthAndYearDatePicker(true)
      setYearOnlyDatePicker(false)
      setYearPreSetOnlyDatePicker(false)
    } else if (
      Number(selectedProgramId) == SotaterceptId &&
      (countryCode == 'GB' || countryCode == 'IE')
    ) {
      setMonthAndYearDatePicker(true)
      setYearOnlyDatePicker(false)
      setYearPreSetOnlyDatePicker(false)
    } else {
      setYearOnlyDatePicker(false)
      setMonthAndYearDatePicker(false)
      setYearPreSetOnlyDatePicker(false)
    }
  }, [selectedProgramId, selectedPhysician, setValue, selectedProgram])

  useEffect(() => {
    if (!selectedProgramId || !watchDob) return

    if (
      DOBGreaterThan18ProgramIds.includes(Number(selectedProgramId)) &&
      !is18YearsOfAge(watchDob)
    ) {
      setIsSubmitDisabled(true)
      setShowOver18Error(true)
      setShowUnder18Error(false)
    } else if (
      DOBLesserThan18ProgramIds.includes(Number(selectedProgramId)) &&
      is18YearsOfAge(watchDob)
    ) {
      setIsSubmitDisabled(true)
      setShowOver18Error(false)
      setShowUnder18Error(true)
    } else {
      setIsSubmitDisabled(false)
      setShowOver18Error(false)
      setShowUnder18Error(false)
    }
  }, [watchDob, selectedProgramId])

  useEffect(() => {
    if (preselectedPhysician) {
      let preselectedPhysicianOption = physicians?.find((physician) => {
        return physician.physicianId?.toString() == preselectedPhysician
      })
      if (preselectedPhysicianOption) {
        handlePhysicianSelected({
          id: preselectedPhysicianOption.physicianId,
          value: `${preselectedPhysicianOption.physicianTitle ?? ''} ${
            preselectedPhysicianOption.physicianFirstName
          } ${preselectedPhysicianOption.physicianLastName}`,
          isActive: preselectedPhysicianOption.physicianLinkedToUser === 'Y'
        })
        setValue('physician', preselectedPhysicianOption.physicianId.toString())
      }
    }
  }, [preselectedPhysician])

  useEffect(() => {
    if (!selectedProgramId || !watchInitials || !isTripleInitials) {
      setShowTripleInitialsError(false)
      return
    }
    const regex = /^([A-Za-z])\1{2}$/i

    if (regex.test(watchInitials)) {
      setShowTripleInitialsError(false)
    } else {
      setShowTripleInitialsError(true)
    }
  }, [watchInitials])

  const handleRWDInfoClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault() // Prevents the default anchor behavior
    analyticsServiceSingleton.trackEvent(AnalyticsEvent.RWDInfoPage, {
      location: 'Create Patient Link'
    })
    window.open(
      `${window.location.origin}/programs/access-programs/real-world-data`,
      '_blank'
    )
  }

  useEffect(() => {
    if (yearOnlyDatePicker && selectedYear) {
      let date = DateTime.fromObject({
        year: Number(selectedYear.value),
        month: 1,
        day: 1
      })
      setValue('dateOfBirth', date.toFormat('MMMM d, yyyy'))
    } else if (yearOnlyPreSetDatePicker) {
      let date = DateTime.fromObject({
        year: 1950,
        month: 1,
        day: 1
      })
      setSelectedYear(yearOptions.find((x) => x.id === 1950))
      setValue('dateOfBirth', date.toFormat('MMMM d, yyyy'))
    } else if (monthAndYearDatePicker && selectedYear && selectedMonth) {
      let date = DateTime.fromObject({
        year: Number(selectedYear.value),
        month: Number(selectedMonth.id),
        day: 1
      })
      setValue('dateOfBirth', date.toFormat('MMMM d, yyyy'))
    } else {
      setValue('dateOfBirth', '')
      return
    }
    trigger()
  }, [selectedYear, selectedMonth, yearOnlyPreSetDatePicker])

  const isSubmitButtonEnabled = () => {
    let baseConditions =
      isValid &&
      isDirty &&
      (!physicianPermissionVisible || physicianPermission) &&
      selectedProgram &&
      (!isRWDMandatory || watchRealWorldDataEnrolFlag) &&
      !isSubmitDisabled

    if (isTripleInitials)
      baseConditions = baseConditions && !showTripleInitialsError

    if (isStockOnly) {
      return baseConditions && watchStockOrder
    } else {
      return baseConditions
    }
  }

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    const href = (e.target as HTMLAnchorElement).getAttribute('href')
    if (!href) return

    const decodedHref = decodeURIComponent(href.replace(/&#x2F;/g, '/'))

    if (decodedHref.includes('/programs/access-programs/real-world-data')) {
      handleRWDInfoClick(e)
    } else if (
      decodedHref.includes('/programs/access-programs/') &&
      documentsLink
    ) {
      window.open(`${window.location.origin}${documentsLink}`, '_blank')
    }
  }

  return (
    <>
      <StyledTitleWrapper>
        <StyledTitleButtonWrapper>
          <ClinButton
            variant="linkWithIcon"
            onClick={() => history.push(backButtonUrl)}
          >
            <ClinIcon
              iconSize={ClinTheme.fontSizes[3]}
              iconName={ClinIconPathName.ArrowLeft}
            />
            {t('patient_detail:create_patients.back_button')}
          </ClinButton>
          <Row>
            <Col sm={12}>
              <ClinText
                as="h1"
                fontWeight={ClinTheme.fontWeights.bold}
                variant={TypographyVariant.H4}
                lineHeight={ClinTheme.lineHeights.largeParagraph}
                marginBottom={'9px'}
                marginTop={'9px'}
              >
                {t('patient_detail:create_patients.title')}
              </ClinText>
            </Col>
          </Row>
          <ClinSpacer height={20} />
        </StyledTitleButtonWrapper>
      </StyledTitleWrapper>
      <ClinPageContentFrame
        hideBreadcrumbs={true}
        marginBottom="0"
        className="min-height-aus-checkout-class"
      >
        {viewMode === CreatePatientsViewMode.Loading && (
          <Row justify="center">
            <Col width="auto">
              <StyledSpinnerContainer>
                <ClinSpinner size={ClinTheme.space[7]} />
              </StyledSpinnerContainer>
            </Col>
          </Row>
        )}
        {viewMode === CreatePatientsViewMode.Submitting && (
          <>
            <Row>
              <Col sm={6}>
                <ClinText
                  variant={TypographyVariant.H4}
                  marginTop={ClinTheme.space[4]}
                >
                  {t('patient_detail:create_patients.loader_text')}
                </ClinText>
              </Col>
            </Row>
            <Row justify="center">
              <Col>
                <StyledSpinnerContainer>
                  <ClinLoader />
                </StyledSpinnerContainer>
              </Col>
            </Row>
          </>
        )}

        {viewMode === CreatePatientsViewMode.ShowForm && (
          <form
            noValidate={true}
            onSubmit={handleSubmit((values) => {
              let isEmailError = values?.rwdNominationDetails?.emailAddress
                ? checkEmail(values.rwdNominationDetails.emailAddress)
                : false

              if (!isEmailError) {
                handleCreatePatientSubmit(
                  values,
                  !values.stockOrderFlag //we don't need validation check for stock orders - we don't have dob and initials
                )
              }
            })}
          >
            <ClinSpacer height={31} />

            <Row>
              <Col xs={12} lg={9}>
                <StyledSectionWrapper>
                  <Row>
                    <Col sm={12}>
                      <ClinText
                        as="h3"
                        fontWeight={ClinTheme.fontWeights.medium}
                        fontSize={20}
                        lineHeight={1.2}
                        marginBottom={'0px'}
                        marginTop={'0px'}
                      >
                        {t('patient_detail:create_patients.section_one_title')}
                      </ClinText>
                    </Col>
                  </Row>
                  <ClinSpacer height={30} />
                  <Row>
                    <Col xs={12} md={7}>
                      <Controller
                        name="program"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            <ProgramAutoSuggest
                              id="program"
                              label={t(
                                'patient_detail:create_patients.program_label'
                              )}
                              countryCode={countryCode}
                              initiallySelectedProgram={watchProgram}
                              setResupplyError={handleResupplyError}
                              enrolledPrograms={enrolledPrograms}
                              searchComponent={(props) => (
                                <ProgramSearch {...props} />
                              )}
                              handleOnSelect={(val, flagIsNotInitial) => {
                                onChange(val.document.programId)
                                setIsEnrolled(
                                  val.document.isUserEnrolledInProgram
                                )
                                setSelectedProgram(val.document)
                                handleProgramChanged(val.document.programId!)
                                if (flagIsNotInitial)
                                  setValue('stockOrderFlag', false)
                              }}
                              handleOnChange={(val) => {
                                if (val) {
                                  onChange(value)
                                } else {
                                  setSelectedProgram(undefined)
                                  handleProgramChanged(0) //if user delete program from program search
                                }
                              }}
                              setIsStockOnly={setIsStockOnly}
                            />
                          </div>
                        )}
                      />
                      {showEnrollButton && (
                        <>
                          <StyledSeparator height="8" />
                          <ClinText
                            variant={TypographyVariant.Paragraph}
                            fontWeight={ClinTheme.fontWeights.normal}
                            lineHeight={ClinTheme.lineHeights.heading[0]}
                            marginBottom={'0px'}
                            marginTop={'0px'}
                            letterSpacing={0}
                          >
                            {t(
                              'patient_detail:create_patients.not_enroled_text'
                            )}
                          </ClinText>
                        </>
                      )}
                    </Col>
                    {showEnrollButton && (
                      <Col
                        xs={3}
                        md={3}
                        style={{
                          alignSelf: 'center',
                          marginBottom: `${ClinTheme.space[4]}px`
                        }}
                      >
                        <StyledEnrollButton
                          variant="primaryNarrow"
                          onClick={() => handleEnrollProgram()}
                        >
                          {t('patient_detail:create_patients.enrol_button')}
                        </StyledEnrollButton>
                      </Col>
                    )}
                    <StyledSeparator height="30" />
                    <Col xs={12} md={7}>
                      <Controller
                        name="physician"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <div>
                            <CustomPhysiciansSelect
                              label={t(
                                'patient_detail:create_patients.physician_label'
                              )}
                              disabled={disableFields}
                              isLoading={isPhysicianLoading}
                              programId={
                                selectedProgramId
                                  ? Number(selectedProgramId)
                                  : selectedProgram?.programId
                              }
                              placeholder={
                                (physicians && physicians.length === 0) ||
                                !physicians
                                  ? t('program_detail:no_enrolled_physicians')
                                  : getInputPlaceholder(
                                      PlaceholderType.SelectInput
                                    )
                              }
                              options={
                                physicians && isArray(physicians)
                                  ? physicians.map(
                                      (
                                        physician: PhysiciansEnrolledSummaryDto
                                      ) => {
                                        return {
                                          id: physician.physicianId,
                                          value: `${
                                            physician.physicianTitle ?? ''
                                          } ${physician.physicianFirstName} ${
                                            physician.physicianLastName
                                          }`,
                                          isActive:
                                            physician.physicianLinkedToUser ===
                                            'Y'
                                        }
                                      }
                                    )
                                  : []
                              }
                              selectedOption={selectedPhysician}
                              handleSelect={(option) =>
                                handlePhysicianSelected &&
                                handlePhysicianSelected(option)
                              }
                            />
                          </div>
                        )}
                      />
                    </Col>
                    {physicianPermissionVisible && (
                      <>
                        <ClinSpacer height={30} />
                        <Col md={9}>
                          <ClinCheckbox
                            labelStyle={{
                              display: 'flex',
                              alignItems: 'flex-start'
                            }}
                            checkboxLabelSpacing="8px"
                            id="physician"
                            label={t(
                              'enrol_physician_on_to_program:confirm_checkbox'
                            )}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              setPhysicianPermission(
                                event.currentTarget.checked
                              )
                            }}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </StyledSectionWrapper>
              </Col>
            </Row>
            {selectedProgram && selectedProgram.stockOrderFlag && (
              <>
                <ClinSpacer height={24} />
                <Row>
                  <Col xs={12} lg={9}>
                    <StyledSectionWrapper>
                      <Row>
                        <Col sm={12}>
                          <ClinText
                            as="h1"
                            fontWeight={ClinTheme.fontWeights.medium}
                            fontSize={20}
                            lineHeight={2.4}
                            marginBottom={'0px'}
                            marginTop={'0px'}
                          >
                            {t(
                              'patient_detail:create_patients.stock_order_title'
                            )}
                          </ClinText>
                        </Col>
                      </Row>
                      <StyledSeparator height="24" />
                      <Row>
                        <Col xs={12} md={10} width="74%">
                          <ClinText
                            fontWeight={ClinTheme.fontWeights.normal}
                            lineHeight={1.5}
                            variant={TypographyVariant.LargeParagraph}
                          >
                            {t(
                              'patient_detail:create_patients.stock_order_desc'
                            )}
                          </ClinText>
                        </Col>

                        <ClinSpacer height={24} />
                        <Col xs={12} md={8}>
                          <Controller
                            name="stockOrderFlag"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <ClinCheckbox
                                labelFontSize={16}
                                id="stock-order-flag"
                                label={t(
                                  'patient_detail:create_patients.stock_order_disclaimer'
                                )}
                                whitespace="nowrap"
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>
                                ) => {
                                  onChange(event.currentTarget.checked)
                                }}
                                checked={value}
                              />
                            )}
                          />
                        </Col>
                        {isStockOnly && (
                          <Col xs={12} md={10} width="74%">
                            <ClinText
                              fontWeight={ClinTheme.fontWeights.normal}
                              lineHeight={1.5}
                              variant={TypographyVariant.LargeParagraph}
                            >
                              You are only allowed to place stock orders for
                              this program - please check the box above.
                            </ClinText>
                          </Col>
                        )}
                      </Row>
                    </StyledSectionWrapper>
                  </Col>
                </Row>
              </>
            )}
            <ClinSpacer height={24} />
            <Row>
              <Col xs={12} lg={9}>
                <StyledSectionWrapper>
                  <Row>
                    <Col sm={12}>
                      <ClinText
                        as="h1"
                        fontWeight={ClinTheme.fontWeights.medium}
                        fontSize={20}
                        lineHeight={1.2}
                        marginBottom={'0px'}
                        marginTop={'0px'}
                      >
                        {t(
                          'patient_detail:create_patients.section_second_title'
                        )}
                      </ClinText>
                    </Col>
                  </Row>
                  <ClinSpacer height={24} />
                  <Row>
                    <Col xs={12} md={12}>
                      <ClinText
                        fontWeight={ClinTheme.fontWeights.normal}
                        variant={TypographyVariant.LargeParagraph}
                        lineHeight={1.5}
                        marginBottom={'0px'}
                        marginTop={'0px'}
                      >
                        {t(
                          isTripleInitials
                            ? 'patient_detail:create_patients.patient_details_additional_info_aaa_initials'
                            : 'patient_detail:create_patients.patient_details_additional_info'
                        )}
                      </ClinText>
                    </Col>
                  </Row>
                  <ClinSpacer height={48} />
                  <Row>
                    <Col
                      xs={12}
                      md={5}
                      style={{
                        marginRight: monthAndYearDatePicker ? '59%' : ''
                      }}
                    >
                      <ClinTextInput
                        id="initials"
                        {...register('patientInitials')}
                        label={t(
                          'patient_detail:create_patients.initials_label'
                        )}
                        width="100%"
                        marginBottom={`${ClinTheme.space[4]}px`}
                        disabled={disableFields || isInitialsDisabled}
                        hasError={!!(errors && errors.patientInitials)}
                        prompt={errors.patientInitials?.message}
                      />
                      {showTripleInitialsError && (
                        <ClinText
                          variant={TypographyVariant.Paragraph}
                          fontWeight={ClinTheme.fontWeights.normal}
                          lineHeight={ClinTheme.lineHeights.heading[0]}
                          marginBottom={'0px'}
                          marginTop={'0px'}
                          letterSpacing={0}
                          color={ClinTheme.colors.redInvalid}
                        >
                          Only generic initials consisting of three identical
                          letters
                        </ClinText>
                      )}
                      <ClinSpacer height={ClinTheme.space[2]} />
                      <ClinText
                        variant={TypographyVariant.Paragraph}
                        fontWeight={ClinTheme.fontWeights.normal}
                        lineHeight={ClinTheme.lineHeights.heading[0]}
                        marginBottom={'0px'}
                        marginTop={'0px'}
                        letterSpacing={0}
                        color={
                          disableFields ? ClinTheme.colors.grey : 'initial'
                        }
                      >
                        {isInitialsDisabled
                          ? 'Patient details for this program are anonymised'
                          : t(
                              'patient_detail:create_patients.initials_description'
                            )}
                      </ClinText>
                    </Col>

                    {yearOnlyDatePicker || yearOnlyPreSetDatePicker ? (
                      <StyledYearSelect>
                        <ClinText marginBottom={'8px'}>
                          Patients year of birth
                        </ClinText>
                        <ClinDropdown
                          isDisabled={
                            watchStockOrder || yearOnlyPreSetDatePicker
                          }
                          trigger={() => {
                            return (
                              <StyledYearSelectTrigger
                                style={
                                  watchStockOrder
                                    ? { cursor: 'not-allowed' }
                                    : {}
                                }
                              >
                                <StyledCustomSelectTriggerInner
                                  style={
                                    watchStockOrder
                                      ? {
                                          background: 'rgb(245, 245, 245)',
                                          pointerEvents: 'none'
                                        }
                                      : {}
                                  }
                                  options={yearOptions}
                                  isActive={!!selectedYear}
                                  tabIndex={0}
                                  title={
                                    selectedYear &&
                                    (selectedYear.value as string)
                                  }
                                >
                                  <ClinText
                                    whiteSpace="nowrap"
                                    variant={TypographyVariant.LargeParagraph}
                                    marginBottom={'5px'}
                                    marginTop={'5px'}
                                  >
                                    {selectedYear
                                      ? selectedYear.value
                                      : 'Please select'}
                                  </ClinText>
                                  <ClinIcon
                                    iconName={
                                      ClinIconPathName.ChevronSmallUpDown
                                    }
                                    iconSize={ClinTheme.space[4]}
                                  />
                                </StyledCustomSelectTriggerInner>
                              </StyledYearSelectTrigger>
                            )
                          }}
                        >
                          <StyledYearSelectOptions>
                            {yearOptions &&
                              yearOptions.map(
                                (
                                  option: ICustomSelectOption,
                                  index: number
                                ) => {
                                  return (
                                    <StyledCustomSelectOption
                                      className={`custom-select-option-${index}`}
                                      title={option && (option.value as string)}
                                      tabIndex={0}
                                      isActive={option.id === selectedYear?.id}
                                      key={`custom-select-${index}`}
                                      onClick={() => setSelectedYear(option)}
                                      // onKeyDown={(event) => {
                                      //   handleKeyDown(event, option)
                                      // }}
                                    >
                                      <ClinText
                                        whiteSpace="nowrap"
                                        variant={
                                          TypographyVariant.LargeParagraph
                                        }
                                        marginBottom={'4px'}
                                        marginTop={'4px'}
                                      >
                                        {option.value}
                                      </ClinText>

                                      {option.isActive && (
                                        <StyledCustomSelectTick>
                                          <ClinIcon
                                            iconName={ClinIconPathName.Tick}
                                            iconSize={ClinTheme.space[3]}
                                          />
                                        </StyledCustomSelectTick>
                                      )}
                                    </StyledCustomSelectOption>
                                  )
                                }
                              )}
                          </StyledYearSelectOptions>
                        </ClinDropdown>
                      </StyledYearSelect>
                    ) : monthAndYearDatePicker ? (
                      <>
                        <ClinSpacer height={ClinTheme.space[3]}></ClinSpacer>
                        <StyledYearSelect>
                          <ClinText marginBottom={'8px'}>
                            Patients month of birth
                          </ClinText>
                          <ClinDropdown
                            isDisabled={watchStockOrder}
                            trigger={() => {
                              return (
                                <StyledYearSelectTrigger
                                  style={
                                    watchStockOrder
                                      ? { cursor: 'not-allowed' }
                                      : {}
                                  }
                                >
                                  <StyledCustomSelectTriggerInner
                                    style={
                                      watchStockOrder
                                        ? {
                                            background: 'rgb(245, 245, 245)',
                                            pointerEvents: 'none'
                                          }
                                        : {}
                                    }
                                    options={monthOptions}
                                    isActive={!!selectedMonth}
                                    tabIndex={0}
                                    title={
                                      selectedMonth &&
                                      (selectedMonth.value as string)
                                    }
                                  >
                                    <ClinText
                                      whiteSpace="nowrap"
                                      variant={TypographyVariant.LargeParagraph}
                                      marginBottom={'5px'}
                                      marginTop={'5px'}
                                    >
                                      {selectedMonth
                                        ? selectedMonth.value
                                        : 'Please select'}
                                    </ClinText>
                                    <ClinIcon
                                      iconName={
                                        ClinIconPathName.ChevronSmallUpDown
                                      }
                                      iconSize={ClinTheme.space[4]}
                                    />
                                  </StyledCustomSelectTriggerInner>
                                </StyledYearSelectTrigger>
                              )
                            }}
                          >
                            <StyledYearSelectOptions>
                              {monthOptions &&
                                monthOptions.map(
                                  (
                                    option: ICustomSelectOption,
                                    index: number
                                  ) => {
                                    return (
                                      <StyledCustomSelectOption
                                        className={`custom-select-option-${index}`}
                                        title={
                                          option && (option.value as string)
                                        }
                                        tabIndex={0}
                                        isActive={
                                          option.id === selectedYear?.id
                                        }
                                        key={`custom-select-${index}`}
                                        onClick={() => setSelectedMonth(option)}
                                        // onKeyDown={(event) => {
                                        //   handleKeyDown(event, option)
                                        // }}
                                      >
                                        <ClinText
                                          whiteSpace="nowrap"
                                          variant={
                                            TypographyVariant.LargeParagraph
                                          }
                                          marginBottom={'4px'}
                                          marginTop={'4px'}
                                        >
                                          {option.value}
                                        </ClinText>

                                        {option.isActive && (
                                          <StyledCustomSelectTick>
                                            <ClinIcon
                                              iconName={ClinIconPathName.Tick}
                                              iconSize={ClinTheme.space[3]}
                                            />
                                          </StyledCustomSelectTick>
                                        )}
                                      </StyledCustomSelectOption>
                                    )
                                  }
                                )}
                            </StyledYearSelectOptions>
                          </ClinDropdown>
                        </StyledYearSelect>
                        <StyledYearSelect>
                          <ClinText marginBottom={'8px'}>
                            Patients year of birth
                          </ClinText>
                          <ClinDropdown
                            isDisabled={watchStockOrder}
                            trigger={() => {
                              return (
                                <StyledYearSelectTrigger
                                  style={
                                    watchStockOrder
                                      ? { cursor: 'not-allowed' }
                                      : {}
                                  }
                                >
                                  <StyledCustomSelectTriggerInner
                                    style={
                                      watchStockOrder
                                        ? {
                                            background: 'rgb(245, 245, 245)',
                                            pointerEvents: 'none'
                                          }
                                        : {}
                                    }
                                    options={yearOptions}
                                    isActive={!!selectedYear}
                                    tabIndex={0}
                                    title={
                                      selectedYear &&
                                      (selectedYear.value as string)
                                    }
                                  >
                                    <ClinText
                                      whiteSpace="nowrap"
                                      variant={TypographyVariant.LargeParagraph}
                                      marginBottom={'5px'}
                                      marginTop={'5px'}
                                    >
                                      {selectedYear
                                        ? selectedYear.value
                                        : 'Please select'}
                                    </ClinText>
                                    <ClinIcon
                                      iconName={
                                        ClinIconPathName.ChevronSmallUpDown
                                      }
                                      iconSize={ClinTheme.space[4]}
                                    />
                                  </StyledCustomSelectTriggerInner>
                                </StyledYearSelectTrigger>
                              )
                            }}
                          >
                            <StyledYearSelectOptions>
                              {yearOptions &&
                                yearOptions.map(
                                  (
                                    option: ICustomSelectOption,
                                    index: number
                                  ) => {
                                    return (
                                      <StyledCustomSelectOption
                                        className={`custom-select-option-${index}`}
                                        title={
                                          option && (option.value as string)
                                        }
                                        tabIndex={0}
                                        isActive={
                                          option.id === selectedYear?.id
                                        }
                                        key={`custom-select-${index}`}
                                        onClick={() => setSelectedYear(option)}
                                        // onKeyDown={(event) => {
                                        //   handleKeyDown(event, option)
                                        // }}
                                      >
                                        <ClinText
                                          whiteSpace="nowrap"
                                          variant={
                                            TypographyVariant.LargeParagraph
                                          }
                                          marginBottom={'4px'}
                                          marginTop={'4px'}
                                        >
                                          {option.value}
                                        </ClinText>

                                        {option.isActive && (
                                          <StyledCustomSelectTick>
                                            <ClinIcon
                                              iconName={ClinIconPathName.Tick}
                                              iconSize={ClinTheme.space[3]}
                                            />
                                          </StyledCustomSelectTick>
                                        )}
                                      </StyledCustomSelectOption>
                                    )
                                  }
                                )}
                            </StyledYearSelectOptions>
                          </ClinDropdown>
                        </StyledYearSelect>
                      </>
                    ) : (
                      <Col xs={12} md={5}>
                        <Controller
                          control={control}
                          name="dateOfBirth"
                          render={({ field }) => (
                            <ClinDatePicker
                              position="bottom"
                              label={t(
                                'patient_detail:create_patients.date_of_birth_label'
                              )}
                              placeholder={watchDob ?? ''}
                              savedDay={watchDob}
                              width="100%"
                              disabled={disableFields}
                              maximumDate={maximumDate}
                              zIndex={ClinTheme.zIndices.confirmation}
                              onChange={(changeValue: string) =>
                                field.onChange(changeValue)
                              }
                              hasError={!!(errors && errors.dateOfBirth)}
                              prompt={errors.dateOfBirth?.message}
                            />
                          )}
                        />
                        {showOver18Error && (
                          <ClinText
                            variant={TypographyVariant.Paragraph}
                            fontWeight={ClinTheme.fontWeights.normal}
                            lineHeight={ClinTheme.lineHeights.heading[0]}
                            marginBottom={'0px'}
                            marginTop={'0px'}
                            letterSpacing={0}
                            color={ClinTheme.colors.redInvalid}
                          >
                            Patient must be over 18 to continue
                          </ClinText>
                        )}
                        {showUnder18Error && (
                          <ClinText
                            variant={TypographyVariant.Paragraph}
                            fontWeight={ClinTheme.fontWeights.normal}
                            lineHeight={ClinTheme.lineHeights.heading[0]}
                            marginBottom={'0px'}
                            marginTop={'0px'}
                            letterSpacing={0}
                            color={ClinTheme.colors.redInvalid}
                          >
                            Patient must be under 18 to continue
                          </ClinText>
                        )}
                      </Col>
                    )}
                  </Row>
                  {!yearOnlyDatePicker && !monthAndYearDatePicker && (
                    <StyledSeparator height={`${ClinTheme.space[7]}`} />
                  )}
                  <Row>
                    <Col xs={12} md={5}>
                      <ClinTextInput
                        id="institute-identifier"
                        {...register('instituteIdentifier')}
                        label={t(
                          'patient_detail:create_patients.institute_identifier_label'
                        )}
                        width="100%"
                        disabled={disableFields}
                        marginBottom={`${ClinTheme.space[4]}px`}
                        hasError={!!(errors && errors.instituteIdentifier)}
                        prompt={errors.instituteIdentifier?.message}
                      />
                      <ClinSpacer height={ClinTheme.space[2]} />
                      <ClinText
                        variant={TypographyVariant.Paragraph}
                        fontWeight={ClinTheme.fontWeights.normal}
                        lineHeight={ClinTheme.lineHeights.heading[0]}
                        marginBottom={'0px'}
                        marginTop={'0px'}
                        letterSpacing={0}
                        color={
                          disableFields ? ClinTheme.colors.grey : 'initial'
                        }
                      >
                        {t(
                          'patient_detail:create_patients.institute_identifier_desc'
                        )}
                      </ClinText>
                    </Col>
                  </Row>
                </StyledSectionWrapper>
              </Col>
            </Row>

            {selectedProgram && selectedProgram.realWorldDataEnrolFlag && (
              <>
                <ClinSpacer height={24} />
                <Row>
                  <Col xs={12} lg={9}>
                    <StyledSectionWrapper>
                      <Row>
                        <Col sm={12}>
                          <ClinText
                            as="h1"
                            fontWeight={ClinTheme.fontWeights.medium}
                            fontSize={20}
                            lineHeight={2.4}
                            marginBottom={'0px'}
                            marginTop={'0px'}
                          >
                            {t(
                              'patient_detail:create_patients.section_third_title'
                            )}
                          </ClinText>
                        </Col>
                      </Row>
                      <StyledSeparator height="24" />
                      <Row>
                        <Col xs={12} md={10}>
                          <ClinText
                            fontWeight={ClinTheme.fontWeights.normal}
                            lineHeight={1.5}
                            variant={TypographyVariant.LargeParagraph}
                          >
                            <Trans
                              i18nKey={
                                isRWDMandatory
                                  ? 'patient_detail:create_patients.create_patient_description_rwd_mandatory'
                                  : 'patient_detail:create_patients.create_patient_description'
                              }
                              components={{
                                a: (
                                  <a
                                    onClick={handleLinkClick}
                                    style={{ cursor: 'pointer' }}
                                  />
                                )
                              }}
                              values={{
                                link: '/programs/access-programs/real-world-data',
                                pipLink: '/programs/access-programs/'
                              }}
                            />
                          </ClinText>
                        </Col>
                      </Row>

                      <ClinSpacer height={24} />
                      <Row>
                        <Col xs={12}>
                          <Controller
                            name="realWorldDataEnrolFlag"
                            control={control}
                            render={({ field: { onChange, value } }) =>
                              isRWDMandatory ? (
                                <ClinCheckbox
                                  labelFontSize={16}
                                  id="real-world-data-enrol-flag"
                                  label={
                                    isRWDMandatory
                                      ? t(
                                          'patient_detail:create_patients.create_patient_rwd_mandatory_checkbox'
                                        )
                                      : t(
                                          'patient_detail:create_patients.real_world_data_enroll_label'
                                        )
                                  }
                                  disabled={!selectedProgram}
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>
                                  ) => onChange(event.currentTarget.checked)}
                                  checked={value}
                                />
                              ) : (
                                <div style={{ display: 'flex' }}>
                                  <Tooltip
                                    theme="light"
                                    position="bottom"
                                    trigger="mouseenter"
                                    arrow={true}
                                    title={t(
                                      'patient_detail:create_patients.enrol_patient_tooltip'
                                    )}
                                  >
                                    <ClinCheckbox
                                      labelFontSize={16}
                                      id="real-world-data-enrol-flag"
                                      label={
                                        isRWDMandatory
                                          ? t(
                                              'patient_detail:create_patients.create_patient_rwd_mandatory_checkbox'
                                            )
                                          : t(
                                              'patient_detail:create_patients.real_world_data_enroll_label'
                                            )
                                      }
                                      disabled={!selectedProgram}
                                      onChange={(
                                        event: ChangeEvent<HTMLInputElement>
                                      ) =>
                                        onChange(event.currentTarget.checked)
                                      }
                                      checked={value}
                                    />
                                  </Tooltip>
                                  <ClinIcon
                                    style={{
                                      marginLeft: ClinTheme.space[3]
                                    }}
                                    iconSize={ClinTheme.fontSizes[4]}
                                    iconName={ClinIconPathName.Warning}
                                    iconFill={
                                      !selectedProgram
                                        ? ClinTheme.colors.grey
                                        : ClinTheme.colors.primary
                                    }
                                  />
                                </div>
                              )
                            }
                          />
                        </Col>
                      </Row>
                      {watchRealWorldDataEnrolFlag && (
                        <>
                          <ClinSpacer height={24} />
                          <Col xs={12}>
                            <Controller
                              name="rwdNominationDetails.nominationFlag"
                              control={control}
                              render={({ field: { onChange, value } }) => (
                                <Row>
                                  <Tooltip
                                    theme="light"
                                    position="bottom"
                                    trigger="mouseenter"
                                    arrow={true}
                                    title={t(
                                      'patient_detail:create_patients.nominee_rwd_tooltip'
                                    )}
                                  >
                                    <ClinCheckbox
                                      labelFontSize={16}
                                      id="nomination-flag"
                                      label={t(
                                        'patient_detail:create_patients.nominate_submit_data_label'
                                      )}
                                      disabled={!selectedProgram}
                                      onChange={(
                                        event: ChangeEvent<HTMLInputElement>
                                      ) =>
                                        onChange(event.currentTarget.checked)
                                      }
                                      checked={value}
                                    />
                                  </Tooltip>
                                  <ClinIcon
                                    style={{
                                      marginLeft: ClinTheme.space[3]
                                    }}
                                    iconSize={ClinTheme.fontSizes[4]}
                                    iconName={ClinIconPathName.Warning}
                                    iconFill={
                                      !selectedProgram
                                        ? ClinTheme.colors.grey
                                        : ClinTheme.colors.primary
                                    }
                                  />
                                </Row>
                              )}
                            />
                          </Col>
                        </>
                      )}
                      {watchRealWorldDataEnrolFlag && watchNominationFlag && (
                        <>
                          <ClinSpacer height={24} />
                          <Row>
                            <Col xs={12} md={7}>
                              <ClinTextInput
                                id="email-address"
                                {...register(
                                  'rwdNominationDetails.emailAddress'
                                )}
                                label={t(
                                  'patient_detail:create_patients.email_address_label'
                                )}
                                width="100%"
                                marginBottom={`${ClinTheme.space[4]}px`}
                                placeholder={t(
                                  'patient_detail:create_patients.email_address_placeholder'
                                )}
                                placeholderColor={ClinTheme.colors.deepGrey}
                                disabled={!selectedProgram}
                                hasError={!!emailErrorMessage}
                                prompt={emailErrorMessage}
                              />
                            </Col>
                          </Row>
                          <ClinSpacer height={24} />
                          <Row>
                            <Col xs={12} md={7}>
                              <ClinTextInput
                                id="full-name"
                                {...register('rwdNominationDetails.fullName')}
                                label={t(
                                  'patient_detail:create_patients.full_name_label'
                                )}
                                width="100%"
                                marginBottom={`${ClinTheme.space[4]}px`}
                                disabled={!selectedProgram}
                                placeholder={t(
                                  'patient_detail:create_patients.full_name_placeholder'
                                )}
                                placeholderColor={ClinTheme.colors.deepGrey}
                                hasError={
                                  !!(
                                    errors &&
                                    errors.rwdNominationDetails?.fullName
                                  )
                                }
                                prompt={
                                  errors.rwdNominationDetails?.fullName?.message
                                }
                              />
                            </Col>
                          </Row>
                          <ClinSpacer height={24} />
                          <Row>
                            <Col xs={12} lg={4}>
                              <Controller
                                name="rwdNominationDetails.classification"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <ClinSelect
                                    id="classification"
                                    label={t(
                                      'patient_detail:create_patients.job_role_label'
                                    )}
                                    width="100%"
                                    value={value}
                                    disabled={!selectedProgram}
                                    placeholderColor={ClinTheme.colors.deepGrey}
                                    onChange={(
                                      changeValue: ChangeEvent<HTMLSelectElement>
                                    ) =>
                                      onChange(changeValue.currentTarget.value)
                                    }
                                    hasError={
                                      !!(
                                        errors &&
                                        errors.rwdNominationDetails
                                          ?.classification
                                      )
                                    }
                                    prompt={
                                      errors.rwdNominationDetails
                                        ?.classification?.message
                                    }
                                  >
                                    <option value="" disabled={true}>
                                      {getInputPlaceholder(
                                        PlaceholderType.SelectInput
                                      )}
                                    </option>
                                    {jobTitles.map((jobType: LovDto) => {
                                      return (
                                        <option
                                          key={`classification-${jobType.generatedId}`}
                                          value={jobType.lovCode}
                                        >
                                          {jobType.lovValue}
                                        </option>
                                      )
                                    })}
                                  </ClinSelect>
                                )}
                              />
                              <ClinSpacer height={ClinTheme.space[1]} />
                            </Col>
                          </Row>
                        </>
                      )}
                    </StyledSectionWrapper>
                  </Col>
                </Row>
              </>
            )}

            <ClinSpacer height={24} />
            <Row>
              <Col xs={12} lg={9}>
                <StyledSectionWrapper>
                  <Row>
                    <Col xs={12} md={10}>
                      <ClinText
                        fontWeight={ClinTheme.fontWeights.normal}
                        variant={TypographyVariant.LargeParagraph}
                        lineHeight={1.5}
                        marginBottom={'0px'}
                        marginTop={'0px'}
                      >
                        {t(
                          'patient_detail:create_patients.create_patient_confiramation'
                        )}
                      </ClinText>
                    </Col>
                  </Row>
                  <ClinSpacer height={30} />
                  <Row>
                    <Col md={12}>
                      <ClinButton
                        type="submit"
                        variant="primaryNarrow"
                        disabled={!isSubmitButtonEnabled()}
                      >
                        {!watchStockOrder
                          ? t(
                              'patient_detail:create_patients.create_patient_button'
                            )
                          : t(
                              'patient_detail:create_patients.create_stock_order'
                            )}
                      </ClinButton>
                    </Col>
                  </Row>
                </StyledSectionWrapper>
              </Col>
            </Row>
            <ClinSpacer height={36} />
          </form>
        )}

        {viewMode === CreatePatientsViewMode.DuplicatePatient && (
          <>
            <ClinSpacer height={36} />
            <Row>
              <Col xs={12} lg={11}>
                <StyledDuplicatePatientWrapper>
                  <Row>
                    <Col xs={12} md={10}>
                      <ClinText
                        fontWeight={ClinTheme.fontWeights.medium}
                        variant={TypographyVariant.LargerParagraph}
                        lineHeight={1.78}
                        marginBottom={'0px'}
                        marginTop={'0px'}
                      >
                        {t(
                          'patient_detail:create_patients.create_patient_duplicate_title'
                        )}
                      </ClinText>
                    </Col>
                  </Row>
                  <ClinSpacer height={24} />
                  <ClinTable
                    spacer="16"
                    cursor="default"
                    customBoxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.16)"
                    borderColapseSeparate={true}
                    borderSpacing="0 8px"
                    padding="0px 2px 0px 2px"
                    tableHeader={() =>
                      columns.map((col, i) => {
                        return (
                          <ClinPatientTableHeaderCell
                            key={col.name}
                            width={col.width}
                          >
                            {col.title.toUpperCase()}
                          </ClinPatientTableHeaderCell>
                        )
                      })
                    }
                    className="DuplicatePatient"
                  >
                    {patients?.map((patient: PatientSummaryDto, rowIndex) => {
                      return (
                        <ClinTableRow
                          disabled={true}
                          background={ClinTheme.colors.white}
                          key={patient.patientId}
                        >
                          {columns.map((column: IColumn) => (
                            <ClinTableBodyCell
                              key={column.name}
                              style={{ padding: '11px 24px 11px 16px' }}
                            >
                              {column.renderValue(patient, rowIndex)}
                            </ClinTableBodyCell>
                          ))}
                        </ClinTableRow>
                      )
                    })}
                  </ClinTable>
                  <ClinSpacer height={24} />
                  <Row>
                    <Col md={12}>
                      <ClinText
                        fontWeight={ClinTheme.fontWeights.normal}
                        variant={TypographyVariant.LargeParagraph}
                        lineHeight={1.5}
                        marginBottom={'16px'}
                        marginTop={'16px'}
                      >
                        {t(
                          'patient_detail:create_patients.create_patient_duplicate_text'
                        )}
                      </ClinText>
                    </Col>
                  </Row>
                  <ClinSpacer height={24} />
                  <StyledDisablePatinetButtonWrapper>
                    <StyledDisablePatinetButton
                      style={{ marginRight: '16px' }}
                      type="button"
                      variant="secondaryNarrow"
                      onClick={() => {
                        handleEditDetails && handleEditDetails()
                      }}
                    >
                      {t('patient_detail:create_patients.edit_details_button')}
                    </StyledDisablePatinetButton>
                    <StyledDisablePatinetButton
                      style={{ marginRight: '16px', width: '219px' }}
                      type="button"
                      variant="secondaryNarrow"
                      onClick={() => {
                        handleCreatePatientSubmit(getValues(), false)
                      }}
                    >
                      {t(
                        'patient_detail:create_patients.create_new_patient_button'
                      )}
                    </StyledDisablePatinetButton>

                    <ClinButton
                      type="button"
                      variant="primaryNarrow"
                      onClick={() => {
                        handleGoToExistingPatient &&
                          handleGoToExistingPatient(
                            patients?.at(0)?.physicianId!,
                            patients?.at(0)?.patientId!
                          )
                      }}
                    >
                      {t('patient_detail:create_patients.go_to_button')}
                    </ClinButton>
                  </StyledDisablePatinetButtonWrapper>
                </StyledDuplicatePatientWrapper>
              </Col>
            </Row>
          </>
        )}
      </ClinPageContentFrame>
    </>
  )
}
