import { rgba } from 'polished'
import styled from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'
import { mediaQuery } from '../../../utils/mediaQuery'
import { IInstituteDetailUserCardStyles } from './InstituteDetailUserCard'

const largeUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledUserCardRow = styled.div({
  padding: `${ClinTheme.space[4]}px 0`,
  borderBottom: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`
})

export const StyledUserCard = styled.div<IInstituteDetailUserCardStyles>(
  {
    padding: ClinTheme.space[4],
    [`${StyledUserCardRow}:last-of-type`]: {
      borderBottom: 'none',
      paddingBottom: '0'
    }
  },
  ({ isPending }) => ({
    background: isPending
      ? ClinTheme.colors.ultraLightGrey
      : ClinTheme.colors.white
  })
)

export const StyledUserCardHeader = styled.div({
  width: '100%',
  borderBottom: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`,
  paddingBottom: ClinTheme.space[4]
})

export const StyledUserCardProfile = styled.div({
  display: 'flex'
})

export const StyledUserCardProfileAvatar = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: ClinTheme.colors.primaryLight,
  borderRadius: '50%',
  color: ClinTheme.colors.white,
  fontSize: ClinTheme.fontSizes[3],
  textTransform: 'uppercase',
  height: ClinTheme.space[7],
  width: ClinTheme.space[7],
  minWidth: ClinTheme.space[7],
  margin: 2,
  fontWeight: ClinTheme.fontWeights.light,
  textAlign: 'center',
  lineHeight: 1,
  marginRight: ClinTheme.space[3]
})

export const StyledUserCardProfileDetails = styled.div({
  display: 'flex',
  flexDirection: 'column'
})

export const StyledUserCardProperty = styled.div({
  flex: `0 0 50%`
})

export const StyledUserCardValue = styled.div({
  flex: `0 0 50%`
})

export const StyledUserCardHeaderAction = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  marginTop: ClinTheme.space[2],

  [mediaQuery(largeUpBreakpoint)]: {
    marginTop: 0,
    justifyContent: 'flex-end'
  }
})

export const StyledLoadingOverlay = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: rgba(ClinTheme.colors.white, 0.8),
  zIndex: ClinTheme.zIndices.confirmation
})
