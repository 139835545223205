import {
  BasketItemDocumentDto,
  CountryDto,
  DocumentUploadSummaryDto,
  HoldDocumentDto,
  HoldDto,
  OrderDto,
  OrderHoldDto,
  OrderLineDto,
  UploadedDocumentDto
} from '../../types/swaggerTypes'
import { $enum } from 'ts-enum-util'
import { useEffect, useState } from 'react'
import { IViewBasket } from '../../context/basket'
import { getHoldForType, HoldType } from './holds.constants'
import {
  IAllHoldsInformation,
  IHoldDetails,
  IOrderLineHoldDocument
} from './holds.types'
import _ from 'lodash'

/**
 * utility to convert a bunch of strings to hold types
 * @param holds
 */
export const convertHoldsToEnum = (holds: HoldDto[]): HoldType[] => {
  // Check if enum is valid and if not return default (Non Specific)
  let holdTypes: HoldType[] = []
  for (let i = 0; i < holds.length; i++) {
    const holdType: HoldType = $enum(HoldType).asValueOrDefault(
      holds[i].holdName,
      HoldType.NonSpecific
    )
    holdTypes.push(holdType)
  }
  return holdTypes
}

/**
 * Get all hold information we need to display a hold
 * @param holdDto
 * @param genericName
 * @param isPreOrder
 * @param supportContact
 */
export const getHoldInformationFor = (
  /** The hold passed in from the API **/
  holdDto: HoldDto,
  /** Optional name of the medicine to replace in the text **/
  genericName: string,
  /** Future tense required before an order is placed **/
  isPreOrder: boolean = false,
  /** For compliance hold pass thru support contact details **/
  supportContact?: CountryDto
): IHoldDetails => {
  // Check if enum is valid and if not return default (Non Specific)
  const holdType: HoldType = $enum(HoldType).asValueOrDefault(
    holdDto.holdName,
    HoldType.NonSpecific
  )
  // Get the hold config
  // const hold = holdLookupRecord[holdType] //TODO: Refactor out record lookup
  const hold = getHoldForType(holdType)
  const {
    title,
    documentPrompt,
    announceMode,
    requiredDocumentName,
    requiresDocumentUpload,
    preOrderMessage,
    wareHouseAddress
  } = hold

  // Replace any medication name
  const regex = /{genericName}/gi
  const msg =
    isPreOrder && hold.preOrderMessage
      ? hold.preOrderMessage
      : hold.message || ''

  let message = genericName && msg.replace(regex, genericName)
  let maMessage =
    genericName &&
    hold?.maMessage &&
    hold?.maMessage.replace(regex, genericName)
  // Check if document already uploaded?
  const documentUploaded: HoldDocumentDto | undefined =
    holdDto.holdDocuments.find((hd) => hd.documentType === requiredDocumentName)
  // For some holds replace the support address {csPhoneNumber} and {csEmailAddress}
  // TODO: I think this is obsolete now?
  if (
    holdType === HoldType.Compliance &&
    supportContact?.csPhoneNumber &&
    supportContact?.csEmailAddress &&
    hold.message &&
    hold.maMessage
  ) {
    message = hold.message
      .replace(/{csPhoneNumber}/gi, supportContact.csPhoneNumber)
      .replace(/{csEmailAddress}/gi, supportContact.csEmailAddress)
    maMessage = hold?.maMessage
      ?.replace(/{csPhoneNumber}/gi, supportContact.csPhoneNumber)
      ?.replace(/{csEmailAddress}/gi, supportContact.csEmailAddress)
  }

  return {
    title,
    type: holdType,
    message,
    maMessage,
    announceMode,
    requiredDocumentName,
    requiresDocumentUpload,
    preOrderMessage,
    documentPrompt,
    wareHouseAddress,
    holdDto,
    documentUploaded
  }
}

/**
 * Get multiple hold information
 * @param holdDtos
 * @param genericName
 * @param supportContact
 */
export const getHoldsInformationFor = (
  /** The holds passed in from the API **/
  holdDtos: HoldDto[],
  /** Optional name of the medicine to replace in the text **/
  genericName: string = '{genericName}',
  /** Optional support contact address  **/
  supportContact?: CountryDto
): IHoldDetails[] => {
  return holdDtos
    .filter((h) => h.holdName !== HoldType.SafetyLetter)
    .map((h) => getHoldInformationFor(h, genericName, false, supportContact))
}

/**
 * Calculate from an array of holds whether a drug requires documents
 * @param holdDtos - the holds passed in from the API
 */
export const getRequiresDocumentation = (holdDtos: HoldDto[]): boolean => {
  const holds = getHoldsInformationFor(holdDtos)
  const hasDocumentation = holds.find((h) => h.requiredDocumentName)
  return !!hasDocumentation
}

// /**
//  * Get an array of required documentation to populate the upload component
//  * @param holdDtos - the holds passed in from the API
//  */
// export const getRequiredDocuments = (holdDtos: HoldDto[]): IDocument[] => {
//   const holdDetails: IHoldDetails[] = getHoldsInformationFor(holdDtos)
//   const holdsWithDocuments = holdDetails.filter((h) => h.requiredDocumentName)
//   let documents: IDocument[] = []
//   // Create an array of docs with dupes
//   holdsWithDocuments.map(
//     (doc) =>
//       doc.requiredDocumentName &&
//       documents.filter((d) => d.prompt === doc.requiredDocumentName).length ===
//         0 &&
//       documents.push({
//         prompt: doc.requiredDocumentName
//       })
//   )
//   return documents
// }

/**
 * Check whether an array of holds for a specific hold type
 * @param holdDtos
 * @param targetHoldType
 */
export const holdsHasHoldType = (
  holdDtos: HoldDto[],
  targetHoldType: HoldType
): boolean => {
  return holdDtos.some((holdDto) => {
    const holdType: HoldType | undefined = $enum(HoldType).asValueOrDefault(
      holdDto.holdName
    )
    return !!(holdType && holdType.toString() === targetHoldType.toString())
  })
}

/**
 * Utility method to check if a download template message is needed
 */
export const requiresDownloadTemplate = (holdDtos: HoldDto[]): boolean => {
  return (
    holdsHasHoldType(holdDtos, HoldType.SpecialClinicalNeed) ||
    holdsHasHoldType(holdDtos, HoldType.InHouseAuthorisation)
  )
}

/**
 * Get ALL holds information for an array of holds
 * @param holds
 */
export const getAllHoldsInformation = (
  holds: HoldDto[]
): IAllHoldsInformation => {
  return {
    requiresDocuments: getRequiresDocumentation(holds),
    hasCreditHold: holdsHasHoldType(holds, HoldType.Credit),
    hasSafetyLetterHold: holdsHasHoldType(holds, HoldType.SafetyLetter),
    holdsDetails: getHoldsInformationFor(holds)
  }
}

/**
 * Get ALL order holds information for an array of holds
 * @param holds
 */
export const getOrderHoldsInformation = (
  holds: OrderHoldDto[]
): IAllHoldsInformation => {
  // Convert OrderHoldDto to HoldDto
  const holdDtos: HoldDto[] = holds.map((h) => {
    return {
      holdId: h.holdId,
      holdName: h.holdName,
      holdDocuments: h.holdDocuments.map((d) => {
        return {
          ...d
        }
      })
    }
  })
  return {
    requiresDocuments: getRequiresDocumentation(holdDtos),
    hasCreditHold: holdsHasHoldType(holdDtos, HoldType.Credit),
    hasSafetyLetterHold: holdsHasHoldType(holdDtos, HoldType.SafetyLetter),
    holdsDetails: getHoldsInformationFor(holdDtos)
  }
}

/**
 * Custom hook to return a bunch of holds related information
 * @param holds - array of holds
 * @param viewBasket - optional pass through a view basket to extract ALL holds
 * @example:
 * const {
    requiresDocuments,
    hasCreditHold,
    holdsDetails
  } = useAllHoldsInformation(holds)
 */
export const useAllHoldsInformation = (
  holds: HoldDto[],
  viewBasket?: IViewBasket
): IAllHoldsInformation => {
  const [holdInfo, setHoldInfo] = useState<IAllHoldsInformation>({
    requiresDocuments: false,
    hasCreditHold: false,
    hasSafetyLetterHold: false,
    holdsDetails: []
  })

  useEffect(() => {
    if (holds.length > 0) {
      setHoldInfo(getAllHoldsInformation(holds))
    }
  }, [holds])

  /**
   * Construct ALL holds from a view basket
   */
  useEffect(() => {
    if (holds.length === 0 && viewBasket && viewBasket.items.length > 0) {
      const holdItems = viewBasket.items.filter(
        (item) =>
          item.priceAndAvailability?.holds &&
          item.priceAndAvailability.holds.length > 0
      )
      let holds: HoldDto[] = []
      for (let i = 0; i < holdItems.length; i++) {
        const moreHolds: HoldDto[] | undefined =
          holdItems[i].priceAndAvailability?.holds
        if (moreHolds) {
          holds = holds.concat(moreHolds)
        }
      }
      if (holds) {
        setHoldInfo(getAllHoldsInformation(holds))
      }
    }
  }, [holds.length, viewBasket])

  return holdInfo
}

/**
 * Make up an array of all order level holds
 * @param orders
 * @param supportContact
 */
export const getOrderLevelHolds = (
  orders: OrderDto[],
  supportContact?: CountryDto
): IHoldDetails[] => {
  // Get all the order level holds
  const orderLevel: HoldDto[] = getAllOrderLevelHoldsOnOrders(orders)
  let orderLevelHoldDetails: IHoldDetails[] = getHoldsInformationFor(orderLevel)
  // Do we need to generate a Compliance Hold message? = any items require documentation
  const lineLevelHolds: HoldDto[] = getAllLineLevelHoldsOnOrders(orders)
  const atLeastOneLineLevelHoldRequireDocs: boolean =
    getRequiresDocumentation(lineLevelHolds)
  if (atLeastOneLineLevelHoldRequireDocs) {
    const complianceHold: IHoldDetails = getHoldInformationFor(
      {
        holdName: HoldType.Compliance.toString(),
        holdDocuments: [],
        holdId: 0
      },
      'my drug',
      false,
      supportContact
    )
    orderLevelHoldDetails.push(complianceHold)
  }
  return orderLevelHoldDetails
}

/**
 * Custom hook to provide holds information for an order or orders (order level)
 * @param orders
 * @param order
 * @param supportContact
 * @usage:
 * const orderLevelHolds = useOrderLevelHolds(orders)
 */
export const useOrderLevelHolds = (
  orders: OrderDto[] | undefined,
  order?: OrderDto,
  supportContact?: CountryDto
): IHoldDetails[] => {
  const [orderLevelHolds, setOrderLevelHolds] = useState<IHoldDetails[]>([])
  useEffect(() => {
    if (supportContact) {
      if (orders && orders.length > 0) {
        setOrderLevelHolds(getOrderLevelHolds(orders, supportContact))
      }
      if (order) {
        setOrderLevelHolds(getOrderLevelHolds([order], supportContact))
      }
    }
  }, [orders, order, supportContact])
  return orderLevelHolds
}

/**
 * Custom hook to provide holds information for all order line levels
 * @param orders
 */
export const useLineLevelHolds = (
  orders: OrderDto[] | undefined
): IAllHoldsInformation => {
  const [holdInfo, setHoldInfo] = useState<IAllHoldsInformation>({
    requiresDocuments: false,
    hasCreditHold: false,
    hasSafetyLetterHold: false,
    holdsDetails: []
  })
  useEffect(() => {
    if (orders && orders.length > 0) {
      const holds: HoldDto[] = getAllLineLevelHoldsOnOrders(orders)
      setHoldInfo(getAllHoldsInformation(holds))
    }
  }, [orders])
  return holdInfo
}

/**
 * Get all orders holds together
 * @param orders
 */
export const getAllOrderLevelHoldsOnOrders = (
  orders: OrderDto[]
): HoldDto[] => {
  return _(orders).flatMap('holds').value()
}

/**
 * Traverse all orders for holds and return all
 * @param orders
 */
export const getAllLineLevelHoldsOnOrders = (orders: OrderDto[]): HoldDto[] => {
  return _(orders).flatMap('lines').flatMap('holds').value()
}

/**
 * Get a simple array of documents from a hold details object
 * @param holdDetails
 */
export const getDocumentsForHoldDetails = (
  holdDetails: IHoldDetails[]
): string[] => {
  let docNames = []
  for (let i = 0; i < holdDetails.length; i++) {
    const requiredDocument = holdDetails[i].requiredDocumentName
    if (requiredDocument) {
      docNames.push(requiredDocument)
    }
  }
  return docNames
}

/**
 * Get hold of the required document hold name
 * @param holdDto
 */
export const getHoldDocumentForHold = (holdDto: HoldDto) => {
  // Check if enum is valid and if not return default (Non Specific)
  const holdType: HoldType = $enum(HoldType).asValueOrDefault(
    holdDto.holdName,
    HoldType.NonSpecific
  )
  // Get the hold config
  const hold = getHoldForType(holdType)
  return hold.requiredDocumentName
}

/**
 * Check if an uploaded document has been submitted/uploaded for a hold
 * @param documents
 * @param hold
 */
export const getUploadedHoldFile = (
  documents: BasketItemDocumentDto[],
  hold: IHoldDetails
): UploadedDocumentDto | undefined => {
  const docUploaded: BasketItemDocumentDto | undefined = documents.find(
    (doc) => doc.holdName === hold.holdDto?.holdName
  )
  return docUploaded?.uploadedDocument
}

/**
 * Helper method to get the required document name for a specific hold
 * @param holdDto
 */
const getRequiredDocumentNameForHold = (holdDto: HoldDto): string | null => {
  const holdType: HoldType = $enum(HoldType).asValueOrDefault(
    holdDto.holdName,
    HoldType.NonSpecific
  )
  // Get the hold config
  const hold = getHoldForType(holdType)
  return hold.requiredDocumentName ? hold.requiredDocumentName : null
}

/**
 * Get a list of documents for a hold and whether they have been supplied
 * For the CheckoutSummary
 * @param orderNumber
 * @param orderLine
 * @param documentUploadSummaries
 */
export const getOrderLineHoldDocuments = (
  orderNumber: string,
  orderLine: OrderLineDto,
  documentUploadSummaries: DocumentUploadSummaryDto[]
): IOrderLineHoldDocument[] => {
  // Loop through each hold and find any matching holds
  let lineItemHolds: IOrderLineHoldDocument[] = []

  // Create array of all hold documents required in this order
  orderLine.holds.forEach((hold) => {
    lineItemHolds.push({
      orderNumber: orderNumber,
      holdId: String(hold.holdId),
      holdDocumentName: getRequiredDocumentNameForHold(hold),
      documentProvided: false
    })
  })

  // Find a matching hold for each document and update
  if (documentUploadSummaries.length > 0) {
    documentUploadSummaries.forEach((doc) => {
      const matchingHoldIndex = lineItemHolds.findIndex(
        (h) => h.holdId === doc.holdId && h.orderNumber === doc.orderNumber
      )
      if (matchingHoldIndex > -1 && doc.fileName !== null) {
        lineItemHolds[matchingHoldIndex].documentProvided = true
      }
    })
  }

  return lineItemHolds
}
