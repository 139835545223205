import styled from 'styled-components'

interface IStyledIconProps {
  height?: number
  width?: number
}
export const StyledIcon = styled.img<IStyledIconProps>(
  {
    objectFit: 'contain',
    objectPosition: 'left'
  },
  ({ height, width }: IStyledIconProps) => ({
    height: height ? `${height}px` : '20px',
    width: width ? `${width}px` : '27px'
  })
)
