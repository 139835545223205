import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'

const largeUpBreakpoint = ClinTheme.breakpoints[2]

export interface IClinTabHeaderStyles
  extends React.HTMLAttributes<HTMLElement> {
  /** Whether item is active or not */
  isActive?: boolean
  /**  Whether tab is disabled from user interaction  */
  isDisabled?: boolean
}

export const StyledClinTabHeader = styled.div<IClinTabHeaderStyles>(
  {
    cursor: 'pointer',
    flexBasis: '100%',
    padding: `${ClinTheme.space[4]}px 0`,
    maxWidth: 'none',
    textAlign: 'center',
    fontWeight: ClinTheme.fontWeights.medium,
    fontSize: ClinTheme.fontSizes[1],
    fontFamily: ClinTheme.fonts.heading,
    letterSpacing: ClinTheme.letterSpacing[1],
    color: ClinTheme.colors.primary,
    transition:
      'background-color 0.3s ease-out, borderTop 0.3s ease-out, box-shadow 0.3s ease-out',

    [mediaQuery(largeUpBreakpoint)]: {
      maxWidth: '50%'
    }
  },
  ({ isActive, isDisabled }: IClinTabHeaderStyles) => ({
    backgroundColor: isActive
      ? ClinTheme.colors.white
      : ClinTheme.colors.lightGrey,
    borderTop: `${ClinTheme.borderWidths[2]}px solid ${
      isActive ? ClinTheme.colors.primaryLight : ClinTheme.colors.lightGrey
    }`,
    boxShadow: isActive ? `0px 0px 5px 0px rgba(0, 0, 0, 0.16)` : 'none',
    cursor: isDisabled ? 'default' : 'pointer'
  })
)
