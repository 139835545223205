import { useCallback, useEffect } from 'react'

/**
 * Custom hook for preventing window unload
 * @usage
 * useBeforeUnload('Really leave?');
 * useBeforeUnload(evt => {
 * // Do some checks here if you like
 *    return true; // Suppress reload
 * });
 * @param value
 */

export const useBeforeUnload = (
  value: ((evt: BeforeUnloadEvent) => any) | string,
  enabled: boolean = true
) => {
  const handleBeforeunload = useCallback(
    (evt: BeforeUnloadEvent) => {
      let returnValue
      if (typeof value === 'function') {
        returnValue = value(evt)
      } else {
        returnValue = value
      }
      if (returnValue) {
        evt.preventDefault()
        evt.returnValue = returnValue
      }
      return returnValue
    },
    [value]
  )

  useEffect(() => {
    enabled
      ? window.addEventListener('beforeunload', handleBeforeunload)
      : window.removeEventListener('beforeunload', handleBeforeunload)
    return () => window.removeEventListener('beforeunload', handleBeforeunload)
  }, [enabled, handleBeforeunload])
}
