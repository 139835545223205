import React, { FunctionComponent, useRef, useState } from 'react'
import {
  StyledChatSupportIcon,
  StyledChatSupportIconText,
  StyledChatSupportPopup,
  StyledPopUpHeader,
  StyledChatSupportPopupCard,
  StyledChevronWrapper,
  StyledPopUpButton
} from './ClinStyledChatSupport.styles'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../ClinText'
import { useTranslation } from 'react-i18next'
import { TypographyVariant } from '../ClinText/ClinText.styles'
import { useAppContext } from '../../context/app'
import { ClinGroup } from '../ClinGroup'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../services/Analytics'
import { StyledLink } from '../StyledLink'
import { ClinSpacer } from '../ClinSpacer'
import { useOnMount } from '../../utils/useOnMount'

interface IClinStyledChatSupportProps {}

export const ClinStyledChatSupport: FunctionComponent<
  IClinStyledChatSupportProps
> = () => {
  const { t } = useTranslation()
  const { supportContact, userDetails } = useAppContext()
  const [isOpened, setIsOpened] = useState<boolean>(false)

  const popupRef = useRef<any>()
  const iconRef = useRef<any>()

  const handleOutusidePopupClick = (event: Event) => {
    if (
      popupRef.current &&
      iconRef.current &&
      !popupRef.current.contains(event.target) &&
      !iconRef.current.contains(event.target)
    ) {
      setIsOpened(false)
    }
  }

  useOnMount(() => {
    document.addEventListener('click', (event) =>
      handleOutusidePopupClick(event)
    )
  })

  const handleSupportPopupClick = () => {
    setIsOpened(!isOpened)
  }

  return (
    <>
      <StyledChatSupportIcon>
        <StyledPopUpButton
          ref={iconRef}
          onClick={() => handleSupportPopupClick()}
        >
          <ClinIcon
            iconName={ClinIconPathName.ChatSupport}
            iconSize={'28px'}
            iconFill={ClinTheme.colors.white}
          />
        </StyledPopUpButton>
        {isOpened && (
          <StyledChatSupportPopup ref={popupRef}>
            <StyledPopUpHeader>
              <ClinText
                marginTop={'0'}
                marginBottom={'0'}
                variant={TypographyVariant.H4}
                fontWeight={ClinTheme.fontWeights.medium}
                color={ClinTheme.colors.white}
              >
                {t('common:support.chat_support_title', {
                  name: userDetails?.contactCard.preferredName
                })}
              </ClinText>
              <ClinText
                marginTop={'0'}
                marginBottom={'0'}
                variant={TypographyVariant.H4}
                fontWeight={ClinTheme.fontWeights.normal}
                color={ClinTheme.colors.white}
              >
                {t('common:support.chat_support_subtitle')}
              </ClinText>
              <ClinSpacer height={ClinTheme.space[9]}></ClinSpacer>
            </StyledPopUpHeader>
            <StyledChatSupportPopupCard>
              <ClinText
                marginTop={'0'}
                marginBottom={ClinTheme.space[2]}
                variant={TypographyVariant.LargeParagraph}
                fontWeight={ClinTheme.fontWeights.medium}
                color={ClinTheme.colors.black}
              >
                {t('common:support.chat_support_bottom_heading')}
              </ClinText>
              <ClinText
                marginTop={'0'}
                marginBottom={'0'}
                variant={TypographyVariant.Paragraph}
                letterSpacing={ClinTheme.letterSpacing[0]}
                fontWeight={ClinTheme.fontWeights.normal}
                color={ClinTheme.colors.black}
              >
                {t('common:support.chat_support_bottom_text')}
              </ClinText>
              <ClinSpacer height={ClinTheme.space[7]}></ClinSpacer>
              <div style={{ marginBottom: ClinTheme.space[2] }}>
                <ClinGroup alignItems="center" justifyContent="flex-start">
                  <ClinIcon
                    iconName={ClinIconPathName.Mail}
                    style={{ marginRight: 0 }}
                    iconFill={ClinTheme.colors.primary}
                  />
                  <a
                    className="raw-email"
                    href={`mailto:${supportContact?.csEmailAddress}`}
                    style={{ margin: 0 }}
                    onClick={(event) => {
                      analyticsServiceSingleton.trackEvent(
                        AnalyticsEvent.ContactClicked,
                        { contactType: 'email' }
                      )
                    }}
                  >
                    <ClinText
                      className="email"
                      as="span"
                      color={ClinTheme.colors.primaryLight}
                      marginLeft={ClinTheme.space[2]}
                    >
                      {supportContact?.csEmailAddress}
                    </ClinText>
                  </a>
                </ClinGroup>
              </div>
              <div>
                <ClinGroup alignItems="center" justifyContent="flex-start">
                  <ClinIcon
                    iconName={ClinIconPathName.Phone}
                    style={{ marginRight: 0 }}
                    iconFill={ClinTheme.colors.primary}
                  />
                  <a
                    className="display-telephone"
                    href={`tel:${supportContact?.rawPhoneNumber}`}
                    style={{ margin: 0 }}
                    onClick={(event) => {
                      analyticsServiceSingleton.trackEvent(
                        AnalyticsEvent.ContactClicked,
                        { contactType: 'telephone' }
                      )
                    }}
                  >
                    <ClinText
                      className={'telephone'}
                      as="span"
                      color={ClinTheme.colors.primaryLight}
                      marginLeft={ClinTheme.space[2]}
                    >
                      {supportContact?.csPhoneNumber}
                    </ClinText>
                  </a>
                </ClinGroup>
              </div>
            </StyledChatSupportPopupCard>
            <StyledChatSupportPopupCard>
              <StyledLink
                to={'/support'}
                onClick={() => handleSupportPopupClick()}
              >
                <ClinText
                  marginTop={'0'}
                  marginBottom={ClinTheme.space[2]}
                  variant={TypographyVariant.LargeParagraph}
                  fontWeight={ClinTheme.fontWeights.medium}
                  color={ClinTheme.colors.black}
                >
                  {t('common:support.chat_support_link_heading')}
                </ClinText>
                <ClinText
                  marginTop={'0'}
                  marginBottom={'0'}
                  variant={TypographyVariant.Paragraph}
                  letterSpacing={ClinTheme.letterSpacing[0]}
                  fontWeight={ClinTheme.fontWeights.normal}
                  color={ClinTheme.colors.black}
                >
                  {t('common:support.chat_support_link_content')}
                </ClinText>
                <StyledChevronWrapper>
                  <ClinIcon
                    iconName={ClinIconPathName.ChevronRight}
                    iconSize={'16px'}
                    color={ClinTheme.colors.black}
                  ></ClinIcon>
                </StyledChevronWrapper>
              </StyledLink>
            </StyledChatSupportPopupCard>
          </StyledChatSupportPopup>
        )}
      </StyledChatSupportIcon>
      {!isOpened && (
        <StyledChatSupportIconText>
          <ClinText fontWeight={ClinTheme.fontWeights.bold}>
            {t('common:chat_support_text')}
          </ClinText>
        </StyledChatSupportIconText>
      )}
    </>
  )
}
