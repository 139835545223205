import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState
} from 'react'
import { Col, Hidden, Row, Visible } from 'react-grid-system'

import {
  TableContainer,
  TableRow,
  TableHeader,
  TableCell,
  QuantityCell,
  SkuCell,
  SubtotalCell
} from './OrderDetails.styles'

import { object, string } from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  OrderDto,
  OrderLineDto,
  OrgAddressDto
} from '../../../../types/swaggerTypes'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../../../context/app'
import {
  deliveryNoteMaxCharacters,
  getAdditionalLineDescription,
  getOrderAndReassignedItems
} from '../../OpaCheckout.models'
import {
  ValidationType,
  getInputValidation
} from '../../../../utils/Forms/getInputValidation'
import DeliveryIcon from '../../../../assets/media/delivery-logos/delivery-aus.svg'
import { ClinSpacer } from '../../../../components/ClinSpacer'
import {
  StyledCheckoutCustomAddressWrapper,
  StyledCheckoutEditSectionWrapper,
  StyledCheckoutSectionWrapper,
  StyledCheckoutSubmitButtonWrapper,
  StyledDeliveryAddressTextOpaCheckout,
  StyledDeliveryDetails,
  StyledDeliveryNoteHighlight,
  StyledDeliveryNoteHighlightWrapper,
  StyledDeliveryNoteWarningWrapper,
  StyledDeliverySummary,
  StyledDetailsTitle,
  StyledEditButtonHighlight,
  StyledEditButtonHighlightWrapper,
  StyledEstimatedDelivery,
  StyledNewShippingAddressLinkCheckout,
  StyledSummaryLine
} from '../../OpaCheckout.styles'
import { ClinText } from '../../../../components/ClinText'
import { ClinTheme } from '../../../../ClinTheme'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'
import { ClinButton } from '../../../../components/ClinButton'
import { ClinGroup } from '../../../../components/ClinGroup'
import { ClinCheckbox } from '../../../../components/ClinCheckbox'
import { ClinTextInput } from '../../../../components/ClinTextInput'
import {
  PhoneInputWrapper,
  StyledClinRadio,
  StyledDeliveryAddressWrap
} from '../../../CheckoutOptions/CheckoutOptions.styles'
import PhoneInput from 'react-phone-input-2'
import { DeliveryAddress } from '../../../CheckoutOptions/DeliveryAddress'
import {
  StyledSummaryItemWrap,
  StyledSummaryTitle
} from '../../../Basket/Basket.styles'
import { numberToCurrencyString } from '../../../../utils/numberToCurrencyString'
import { getBrowserLocale } from '../../../../utils/getBrowserLocale'
import { IDeliveryData } from '../../OpaCheckoutContainer'
import { ClinTextarea } from '../../../../components/ClinTextarea'
import { getAdditionalLineItems } from '../../../OrderPages/OrderDetail/OrderDetail.model'
import { ClinNewFeatureTooltipContainer } from '../../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'
import {
  INewFeature,
  NewFeatureElements
} from '../../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { StyledIcon } from '../../../../components/ClinEstimatedDeliveryDate/EstimatedDeliveryDate.styles'
import { removeFeature, useNewFeatures } from '../../../../context/newFeatures'

interface IOpaCheckoutProps {
  /** Order details */
  order: OrderDto
  /** Depending on this display or hide edit delivery details */
  displayEditMode: boolean
  /** Handle change display edit mode */
  deliveryData: IDeliveryData
  deliveryNote: string
  shippingAddress?: OrgAddressDto
  deliveryAddresses: OrgAddressDto[]
  newFeaturesList?: { [id: string]: INewFeature }
  checkboxShaddow?: string
  noteZIndex?: string
  buttonZIndex?: string
  oldAddressId: string
  handleSetDisplayMode: (value: boolean) => void
  /** Handle order confirmation */
  handleConfirmOrder: (poNumber: string | undefined) => void
  setDeliveryNote: (value: string) => void
  handleDeliveryDetailsChange: (data: IDeliveryData) => void
  /** Handles a request to add a new shipping address **/
  handleNewShippingAddress?: () => void
  handleSetShippingAddress: (shippingAddress: OrgAddressDto) => void
  handleSetOldAddressId: (value: string) => void
  displayNewFeature?: (feature: NewFeatureElements) => boolean | undefined
  setPoNumber: (poNumber: string) => void
}

interface IDeliveryForm {
  deliveryRadioId: string
  recipientName: string
  poNumber: string
  recipientEmail?: string
  recipientPhoneNumber?: string
  shippingAddressId?: string
}

interface IPOForm {
  poNumber: string
}

export const OrderDetails: FunctionComponent<IOpaCheckoutProps> = ({
  order,
  displayEditMode,
  deliveryData,
  deliveryNote,
  shippingAddress,
  deliveryAddresses,
  newFeaturesList,
  noteZIndex,
  buttonZIndex,
  checkboxShaddow,
  oldAddressId,
  displayNewFeature,
  handleSetOldAddressId,
  setDeliveryNote,
  handleSetDisplayMode,
  handleConfirmOrder,
  handleDeliveryDetailsChange,
  handleNewShippingAddress,
  handleSetShippingAddress,
  setPoNumber
}) => {
  const { t } = useTranslation()

  const [showDeliveryNote, setShowDeliveryNote] = useState<boolean>(
    !!deliveryNote
  )

  const [formChanged, setFormChanged] = useState<boolean>(false)
  const { portfolioCountryCode, institute } = useContext(AppContext)
  const orderedItems: OrderLineDto[] = getOrderAndReassignedItems(order)
  const additionalLines: OrderLineDto[] = getAdditionalLineItems(order)

  const totalNumberPageFeatures: number = 2

  const [, dispatch] = useNewFeatures()

  const defaultState: IDeliveryForm = {
    deliveryRadioId: '2',
    recipientName: deliveryData.recipientName,
    poNumber: deliveryData.poNumber,
    recipientEmail: deliveryData.recipientEmail,
    recipientPhoneNumber: deliveryData.recipientPhoneNumber,
    shippingAddressId: deliveryData.shippingAddressId
  }

  const defaultStatePO: IPOForm = {
    poNumber: deliveryData.poNumber ?? ''
  }
  const requiredText = getInputValidation(ValidationType.RequiredField)
  const ShouldPONumberBeDisplayed =
    portfolioCountryCode === 'FR' || portfolioCountryCode === 'GB'
      ? true
      : order.totals.total !== 0
  // Calculate expected delivery date
  function getBusinessDaysCount(targetDateStr: string | number | Date) {
    const targetDate = new Date(targetDateStr)
    const currentDate = new Date()

    let businessDays = 0
    while (currentDate <= targetDate) {
      // Check if the current day is a weekday (Monday to Friday)
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        businessDays++
      }
      currentDate.setDate(currentDate.getDate() + 1)
    }
    return businessDays
  }

  const targetDateStr = order.lines[0].expectedDate ?? ''
  const businessDaysCount = getBusinessDaysCount(targetDateStr)
  // Get expected delivery date
  const getDeliveryDate = () => {
    if (businessDaysCount > 0) {
      return `${t(
        'opa_checkout:estimated_delivery_before_text'
      )} ${businessDaysCount}-${businessDaysCount + 1} ${t(
        'opa_checkout:estimated_delivery_after_text'
      )}`
    } else {
      return `${t('opa_checkout:estimated_delivery_after_single_day')}`
    }
  }

  const doesPONumberContainSpecialCharachters = (value: string | undefined) => {
    let regex = /[^\w-]/
    if (value && regex.test(value)) {
      return false
    }
    return true
  }

  const DeliveryDataSchema = object().shape({
    deliveryRadioId: string().required(requiredText),
    recipientName: string().max(
      100,
      t('opa_checkout:validation_max_char_recipient_name', {
        charLimit: 100
      })
    ),
    recipientPhoneNumber: string(),
    recipientEmail: string().email(getInputValidation(ValidationType.Email)),
    shippingAddressId: string()
  })

  const DeliveryPOSchema = object().shape({
    poNumber: string()
      .when('$ShouldPONumberBeDisplayed', {
        is: true,
        then: string().required(requiredText),
        otherwise: string()
      })
      .max(
        50,
        t('opa_checkout:validation_max_char_po_number', {
          charLimit: 50
        })
      )
      .test(
        'does-contain-spaces',
        t('opa_checkout:validation_no_spaces_po_number'),
        (value) => value?.indexOf(' ') === -1
      )
      .test(
        'does-contain-special-charachters',
        t('checkout_options:po_error_message_specia_charachters', {
          characters: '+/#',
          interpolation: { escapeValue: false }
        }),
        (value) => doesPONumberContainSpecialCharachters(value)
      )
  })

  const {
    formState: { errors, isValid },
    register,
    trigger,
    watch,
    handleSubmit,
    setValue,
    reset
  } = useForm<IDeliveryForm>({
    defaultValues: defaultState,
    mode: 'onChange',
    resolver: yupResolver(DeliveryDataSchema, {
      context: {}
    })
  })

  const {
    formState: { errors: errorsPO, isValid: isValidPO },
    register: registerPO,
    watch: watchPO,
    handleSubmit: handleSubmitPO
  } = useForm<IDeliveryForm>({
    defaultValues: defaultStatePO,
    mode: 'onChange',
    resolver: yupResolver(DeliveryPOSchema, {
      context: { ShouldPONumberBeDisplayed }
    })
  })

  const [recipientPhoneNumber] = watch(['recipientPhoneNumber'])

  const [poNumber] = watchPO(['poNumber'])

  useEffect(() => {
    setPoNumber(poNumber)
  }, [poNumber])

  const isSubmitDisabled =
    portfolioCountryCode === 'FR'
      ? poNumber?.length === 0 || !poNumber
      : !(order.totals.total === 0 || !(poNumber?.length === 0 || !poNumber))

  const handleDeliveryNoteChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newText = event.target.value
    setDeliveryNote(newText)
  }

  useEffect(() => {
    setValue('shippingAddressId', shippingAddress?.addressId)
  }, [shippingAddress, setValue])

  useEffect(() => {
    trigger()
  }, [])

  const handleCancelButton = () => {
    reset(defaultState)

    handleDeliveryDetailsChange({
      ...deliveryData,
      shippingAddressId: oldAddressId
    })
    handleSetDisplayMode(false)
  }

  const extractAddress = (deliverTo: OrgAddressDto) => {
    let address: string = ''
    address += deliverTo.address1 + ', '
    if (deliverTo.address2) address += deliverTo.address2 + ', '
    if (deliverTo.address3) address += deliverTo.address3 + ', '
    if (deliverTo.city) address += deliverTo.city + ', '
    if (deliverTo.state) address += deliverTo.state + ', '
    if (deliverTo.country) address += deliverTo.country + ', '
    return address
  }

  const handleCheckboxClick = () => {
    if (!newFeaturesList) {
      return
    }
    if (newFeaturesList[NewFeatureElements.DeliveryNote]) {
      removeFeature(
        dispatch,
        newFeaturesList[NewFeatureElements.DeliveryNote].id
      )
    }
  }

  return (
    <>
      <ClinSpacer />
      <Row>
        <Col xs={12} md={7} lg={8} style={{ marginTop: ClinTheme.space[5] }}>
          {!displayEditMode ? (
            <StyledCheckoutSectionWrapper>
              <StyledDetailsTitle>
                <ClinText
                  variant={TypographyVariant.LargerParagraph}
                  fontWeight={ClinTheme.fontWeights.medium}
                >
                  {!ShouldPONumberBeDisplayed
                    ? t('opa_checkout:confirm_delivery_details_without_number')
                    : t('opa_checkout:confirm_delivery_details')}
                </ClinText>
                <StyledEditButtonHighlightWrapper>
                  <StyledEditButtonHighlight>
                    {/* Display new feature for switch button my orders ********************************************* */}
                    {newFeaturesList?.[
                      NewFeatureElements.EditButtonMaChekout
                    ] && (
                      <ClinNewFeatureTooltipContainer
                        feature={
                          newFeaturesList[
                            NewFeatureElements.EditButtonMaChekout
                          ]
                        }
                        openTooltipInitially={true}
                        disableDismiss={true}
                        total={totalNumberPageFeatures}
                        current={1}
                      ></ClinNewFeatureTooltipContainer>
                    )}
                  </StyledEditButtonHighlight>
                  <ClinButton
                    variant="secondaryNarrow"
                    style={{
                      width: '164px',
                      zIndex: buttonZIndex
                    }}
                    onClick={() => handleSetDisplayMode(true)}
                  >
                    {t('common:buttons.edit')}
                  </ClinButton>
                </StyledEditButtonHighlightWrapper>
              </StyledDetailsTitle>
              <StyledDeliveryDetails
                padding={!ShouldPONumberBeDisplayed ? '0' : '20px'}
              >
                <Row>
                  <Col xs={12} md={8}>
                    <ClinText variant={TypographyVariant.Paragraph}>
                      {deliveryData.recipientName}
                    </ClinText>
                    <ClinText variant={TypographyVariant.Paragraph}>
                      {deliveryData.recipientEmail}
                    </ClinText>
                    <ClinText variant={TypographyVariant.Paragraph}>
                      {deliveryData.recipientPhoneNumber}
                    </ClinText>
                    <ClinText variant={TypographyVariant.Paragraph}>
                      {institute.data?.instituteName ?? ''}
                    </ClinText>
                    <ClinText variant={TypographyVariant.Paragraph}>
                      {shippingAddress && extractAddress(shippingAddress)}
                    </ClinText>
                    <ClinText variant={TypographyVariant.Paragraph}>
                      {`${shippingAddress?.country} ${shippingAddress?.postalCode}`}
                    </ClinText>
                  </Col>
                </Row>
                <ClinSpacer height={'28px'} />
                <ClinGroup>
                  <ClinCheckbox
                    checkBoxWidth={22}
                    checkBoxHeight={22}
                    marginRight={'0px'}
                    zIndex={noteZIndex}
                    shadow={checkboxShaddow}
                    checked={showDeliveryNote}
                    onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                      handleCheckboxClick()
                      if (!event.currentTarget.checked) {
                        setDeliveryNote('')
                      }
                      setShowDeliveryNote(event.currentTarget.checked)
                    }}
                    id="delivery-note"
                  ></ClinCheckbox>

                  <StyledDeliveryNoteHighlightWrapper>
                    <StyledDeliveryNoteHighlight>
                      {/* Display new feature for switch button my orders ********************************************* */}
                      {newFeaturesList &&
                        displayNewFeature &&
                        displayNewFeature(NewFeatureElements.DeliveryNote) &&
                        newFeaturesList[NewFeatureElements.DeliveryNote] && (
                          <ClinNewFeatureTooltipContainer
                            feature={
                              newFeaturesList[NewFeatureElements.DeliveryNote]
                            }
                            openTooltipInitially={true}
                            disableDismiss={true}
                            total={totalNumberPageFeatures}
                            current={2}
                          ></ClinNewFeatureTooltipContainer>
                        )}
                    </StyledDeliveryNoteHighlight>
                    <ClinText
                      variant={TypographyVariant.LargeParagraph}
                      lineHeight={1.5}
                      marginBottom={0}
                      marginTop={0}
                    >
                      {t('opa_checkout:delivery_note')}
                    </ClinText>
                  </StyledDeliveryNoteHighlightWrapper>
                </ClinGroup>
                {showDeliveryNote && (
                  <>
                    <ClinSpacer height={'28px'} />
                    <ClinTextarea
                      id="anything-else"
                      label={t('opa_checkout:delivery_note_label')}
                      onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                        handleDeliveryNoteChange(event)
                      }
                      height={160}
                      value={deliveryNote}
                      placeholder={t('opa_checkout:delivery_note_placeholder')}
                      hasError={deliveryNote.length > deliveryNoteMaxCharacters}
                      prompt={
                        deliveryNote.length > deliveryNoteMaxCharacters
                          ? t('forms:validations.maximum_characters', {
                              maxChars: deliveryNoteMaxCharacters
                            })
                          : ''
                      }
                    />
                    <StyledDeliveryNoteWarningWrapper>
                      <ClinText
                        as="div"
                        fontSize={ClinTheme.fontSizes[0]}
                        marginTop={ClinTheme.space[1]}
                        lineHeight={ClinTheme.lineHeights.largeParagraph}
                      >
                        {t('opa_checkout:delivery_note_warning')}
                      </ClinText>
                      <ClinText
                        as="span"
                        fontSize={ClinTheme.fontSizes[0]}
                        marginTop={ClinTheme.space[1]}
                        lineHeight={ClinTheme.lineHeights.largeParagraph}
                      >
                        {deliveryNote.length}/{deliveryNoteMaxCharacters}
                      </ClinText>
                    </StyledDeliveryNoteWarningWrapper>
                  </>
                )}
                <ClinSpacer height={'30px'} />
                {order.lines[0]?.expectedDate && (
                  <>
                    <StyledEstimatedDelivery>
                      <StyledIcon
                        style={{ marginRight: '12px' }}
                        width={24}
                        height={24}
                        src={DeliveryIcon}
                      />

                      <ClinText
                        variant={TypographyVariant.LargeParagraph}
                        color={ClinTheme.colors.primaryMid}
                        lineHeight={ClinTheme.lineHeights.body}
                        marginBottom={0}
                        marginTop={0}
                      >
                        {getDeliveryDate()}
                      </ClinText>
                    </StyledEstimatedDelivery>
                    <ClinText
                      as="span"
                      variant={TypographyVariant.SmallUI}
                      fontWeight={ClinTheme.fontWeights.normal}
                      lineHeight={ClinTheme.lineHeights.largeParagraph}
                      marginBottom={0}
                      marginTop={0}
                    >
                      {t('opa_checkout:estimated_delivery_description')}
                    </ClinText>
                  </>
                )}
              </StyledDeliveryDetails>
            </StyledCheckoutSectionWrapper>
          ) : (
            <form
              onSubmit={handleSubmit((values) => {
                handleSetDisplayMode(false)
                if (formChanged) {
                  handleDeliveryDetailsChange({
                    ...deliveryData,
                    ...values
                  })
                  setFormChanged(false)
                }
              })}
            >
              <StyledCheckoutEditSectionWrapper>
                <StyledDetailsTitle
                  style={{ marginBottom: `${ClinTheme.space[4]}px` }}
                >
                  <ClinText
                    variant={TypographyVariant.LargerParagraph}
                    fontWeight={ClinTheme.fontWeights.medium}
                  >
                    {t('opa_checkout:confirm_delivery_details_without_number')}
                  </ClinText>

                  <ClinButton
                    type="button"
                    variant="secondaryNarrow"
                    style={{
                      width: '108px',
                      marginLeft: 'auto',
                      marginRight: '8px'
                    }}
                    onClick={() => {
                      handleCancelButton && handleCancelButton()
                    }}
                  >
                    {t('common:buttons.cancel')}
                  </ClinButton>
                  <ClinButton
                    type="submit"
                    variant="primaryNarrow"
                    style={{
                      width: '164px'
                    }}
                  >
                    {t('opa_checkout:save_changes')}
                  </ClinButton>
                </StyledDetailsTitle>

                <Row>
                  <Col sm={12} md={7}>
                    <ClinTextInput
                      id="recipient-name"
                      {...register('recipientName')}
                      label={t('opa_checkout:delivery_contact_label')}
                      width="100%"
                      hasError={!!(errors && errors.recipientName)}
                      prompt={errors.recipientName?.message}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        !formChanged && setFormChanged(true)
                      }}
                    />
                    <ClinSpacer height={ClinTheme.space[4]} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={7}>
                    <ClinTextInput
                      width="100%"
                      id="recipients-email"
                      label={t('checkout_options:delivery_contact_email')}
                      {...register('recipientEmail')}
                      hasError={!!(errors && errors.recipientEmail)}
                      prompt={errors.recipientEmail?.message}
                      onChange={() => !formChanged && setFormChanged(true)}
                    />
                    <ClinSpacer height={ClinTheme.space[4]} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={7}>
                    <ClinText
                      as="div"
                      fontSize={ClinTheme.fontSizes[1]}
                      color={'initial'}
                      marginBottom={ClinTheme.space[2]}
                    >
                      {t('checkout_options:delivery_contact_phone')}
                    </ClinText>

                    <PhoneInputWrapper>
                      <PhoneInput
                        inputProps={{
                          id: 'recipients-phone'
                        }}
                        {...register('recipientPhoneNumber')}
                        inputStyle={{ height: '40px', width: '100%' }}
                        autoFormat={true}
                        value={recipientPhoneNumber}
                        onChange={(value, coutry, event, formattedValue) => {
                          !formChanged && setFormChanged(true)
                          setValue('recipientPhoneNumber', formattedValue)
                        }}
                      />
                    </PhoneInputWrapper>
                    <ClinSpacer height={ClinTheme.space[2]} />
                    <ClinText
                      variant={TypographyVariant.SmallUI}
                      fontWeight={ClinTheme.fontWeights.normal}
                      lineHeight={ClinTheme.lineHeights.largeParagraph}
                      letterSpacing={0}
                    >
                      {t('opa_checkout:phone_number_warning')}
                    </ClinText>
                    <ClinSpacer height={ClinTheme.space[4]} />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <>
                      <ClinText
                        as="h5"
                        variant={TypographyVariant.LargeParagraph}
                        marginBottom={ClinTheme.space[2]}
                      >
                        {t(
                          'checkout_options:checkout_options.address_select_title'
                        )}
                      </ClinText>
                      <div style={{ paddingRight: '33px' }}>
                        {deliveryAddresses?.map((address, index) => (
                          <div key={address?.addressId}>
                            <StyledClinRadio
                              padding="24px"
                              label={`${address.addressId}-index`}
                              {...register('shippingAddressId')}
                              id={`${address.addressId}-index`}
                              checked={
                                address.addressId ==
                                deliveryData.shippingAddressId
                              }
                              isLast={index === deliveryAddresses.length - 1}
                              isCompact={true}
                              onClick={(event) => {
                                !formChanged && setFormChanged(true)
                                setValue('shippingAddressId', address.addressId)
                                handleSetOldAddressId(
                                  deliveryData.shippingAddressId
                                )
                                handleSetShippingAddress(address)
                              }}
                            >
                              <DeliveryAddress
                                address={address}
                                isCompact={true}
                                fontSize={ClinTheme.fontSizes[2]}
                              />
                            </StyledClinRadio>
                          </div>
                        ))}
                      </div>
                    </>
                  </Col>
                </Row>
              </StyledCheckoutEditSectionWrapper>
              <StyledCheckoutCustomAddressWrapper>
                <StyledDeliveryAddressWrap id="scrollView">
                  <StyledDeliveryAddressTextOpaCheckout as="div">
                    <ClinText
                      as="h5"
                      variant={TypographyVariant.LargeParagraph}
                    >
                      {t('checkout_options:do_not_see_delivery_options')}
                    </ClinText>
                  </StyledDeliveryAddressTextOpaCheckout>
                  <StyledNewShippingAddressLinkCheckout
                    onClick={() => {
                      setFormChanged(true)
                      handleNewShippingAddress && handleNewShippingAddress()
                    }}
                  >
                    {t(
                      'checkout_options:checkout_options.new_shipping_address_btn'
                    )}
                  </StyledNewShippingAddressLinkCheckout>
                </StyledDeliveryAddressWrap>
              </StyledCheckoutCustomAddressWrapper>
            </form>
          )}
          {ShouldPONumberBeDisplayed && <ClinSpacer height={30} />}
          <form
            onSubmit={handleSubmitPO((value) => {
              handleDeliveryDetailsChange({
                ...deliveryData,
                poNumber: value.poNumber
              })
              handleConfirmOrder(value.poNumber)
            })}
          >
            {ShouldPONumberBeDisplayed && (
              <StyledCheckoutSectionWrapper>
                <ClinText
                  variant={TypographyVariant.LargerParagraph}
                  fontWeight={ClinTheme.fontWeights.medium}
                >
                  {t('opa_checkout:purchase_order_number_title')}
                </ClinText>
                <StyledDeliveryDetails padding="20px">
                  <Row>
                    <ClinSpacer height={ClinTheme.space[2]} />
                    <Col sm={12} md={9}>
                      <ClinTextInput
                        className={'delivery-input'}
                        id="po-number"
                        {...registerPO('poNumber')}
                        label={t('opa_checkout:po_number_label')}
                        width="100%"
                        hasError={!!(errorsPO && errorsPO.poNumber)}
                        prompt={errorsPO.poNumber?.message}
                      />
                      <ClinSpacer height={ClinTheme.space[2]} />
                      <ClinText
                        variant={TypographyVariant.SmallUI}
                        fontWeight={ClinTheme.fontWeights.normal}
                        lineHeight={ClinTheme.lineHeights.largeParagraph}
                        letterSpacing={0}
                        color="#525252"
                      >
                        {t('opa_checkout:purchase_order_number_description')}
                      </ClinText>
                      <ClinSpacer height={20} />
                    </Col>
                  </Row>
                </StyledDeliveryDetails>
              </StyledCheckoutSectionWrapper>
            )}
            <Visible xs sm>
              <ClinSpacer height={30}></ClinSpacer>
            </Visible>
            <Hidden xs sm>
              <StyledCheckoutSubmitButtonWrapper>
                <ClinButton
                  type="submit"
                  variant="primaryNarrow"
                  style={{ width: '166px' }}
                  disabled={
                    isSubmitDisabled ||
                    !isValidPO ||
                    !isValid ||
                    deliveryNote.length > deliveryNoteMaxCharacters
                  }
                >
                  {t('checkout_options:checkout_options.place_order_btn')}
                </ClinButton>
              </StyledCheckoutSubmitButtonWrapper>
            </Hidden>
          </form>
        </Col>

        {/* Summary */}
        <Col xs={12} md={5} lg={4}>
          <StyledDeliverySummary style={{ padding: '24px 15px 43px 25px' }}>
            <StyledSummaryTitle>
              <ClinText
                as="span"
                variant={TypographyVariant.LargerParagraph}
                fontWeight={ClinTheme.fontWeights.medium}
                lineHeight={2.67}
              >
                {t('basket:summary')}
              </ClinText>
            </StyledSummaryTitle>
            <ClinSpacer height={ClinTheme.space[1]} />

            {orderedItems?.length > 0 && (
              <TableContainer>
                {/* Header Section */}
                <TableHeader>
                  <QuantityCell style={{ paddingLeft: 0 }}>
                    <ClinText variant={TypographyVariant.SmallUI}>
                      {t('basket:quantity')}
                    </ClinText>
                  </QuantityCell>
                  <SkuCell>
                    <ClinText variant={TypographyVariant.SmallUI}>
                      {t('basket:sku')}
                    </ClinText>
                  </SkuCell>
                  <SubtotalCell style={{ paddingRight: 0 }}>
                    <ClinText
                      variant={TypographyVariant.SmallUI}
                      textAlign="right"
                    >
                      {t('basket:subtotal')}
                    </ClinText>
                  </SubtotalCell>
                </TableHeader>
                {/* Items Section */}
                {orderedItems.map((item) => (
                  <TableRow key={item.lineId}>
                    <QuantityCell style={{ paddingLeft: 0 }}>
                      <ClinText variant={TypographyVariant.Paragraph}>
                        {item.quantities?.ordered}
                        {'x'}
                      </ClinText>
                    </QuantityCell>
                    <SkuCell>
                      <ClinText variant={TypographyVariant.Paragraph}>
                        {item.skuCatalogItem?.genericDosageConcatenation}{' '}
                        {item.skuCatalogItem?.formulationPackSize}
                      </ClinText>
                    </SkuCell>
                    <SubtotalCell style={{ paddingRight: 0 }}>
                      <ClinText
                        textAlign="right"
                        as={'h5'}
                        variant={TypographyVariant.Paragraph}
                      >
                        {item.totals.subTotal === undefined ||
                        item.totals.subTotal === null
                          ? '--'
                          : numberToCurrencyString(
                              item.totals.subTotal,
                              getBrowserLocale(),
                              {
                                style: 'currency',
                                currency: order.currencyCode,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              }
                            )}
                      </ClinText>
                    </SubtotalCell>
                  </TableRow>
                ))}
              </TableContainer>
            )}

            <ClinSpacer height="20px" />
            <StyledSummaryLine></StyledSummaryLine>
            <ClinSpacer height="15px" />

            {additionalLines?.map((item, index) => (
              <ClinGroup
                key={item.lineId}
                justifyContent="space-between"
                alignItems="center"
              >
                <ClinText
                  variant={TypographyVariant.Paragraph}
                  lineHeight={ClinTheme.lineHeights.heading[0]}
                  marginTop={'0px'}
                  marginBottom={'0px'}
                >
                  {getAdditionalLineDescription(item)
                    ? t(getAdditionalLineDescription(item))
                    : item.itemDescription}
                </ClinText>

                <ClinText
                  as={'h5'}
                  variant={TypographyVariant.Paragraph}
                  fontWeight={ClinTheme.fontWeights.medium}
                >
                  {item.totals.subTotal === undefined ||
                  item.totals.subTotal === null
                    ? '--'
                    : numberToCurrencyString(
                        item.totals.subTotal,
                        getBrowserLocale(),
                        {
                          style: 'currency',
                          currency: order.currencyCode,
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }
                      )}
                </ClinText>
              </ClinGroup>
            ))}
            <ClinGroup justifyContent="space-between" alignItems="center">
              <StyledSummaryItemWrap>
                <ClinText
                  variant={TypographyVariant.Paragraph}
                  lineHeight={ClinTheme.lineHeights.heading[0]}
                  marginTop={'0px'}
                  marginBottom={'0px'}
                >
                  {t('ma_order_detail:tax')}
                </ClinText>
              </StyledSummaryItemWrap>

              <ClinText
                as={'h5'}
                variant={TypographyVariant.Paragraph}
                fontWeight={ClinTheme.fontWeights.medium}
              >
                {order.totals.tax === undefined || order.totals.tax === null
                  ? '--'
                  : numberToCurrencyString(
                      order.totals.tax,
                      getBrowserLocale(),
                      {
                        style: 'currency',
                        currency: order.currencyCode,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }
                    )}
              </ClinText>
            </ClinGroup>
            <ClinSpacer height={20} />
            <ClinGroup justifyContent="space-between">
              <div>
                <ClinText
                  variant={TypographyVariant.LargerParagraph}
                  fontWeight={ClinTheme.fontWeights.medium}
                >
                  {t('checkout_options:checkout_options.total')}
                </ClinText>
              </div>

              <ClinText
                as={'h5'}
                variant={TypographyVariant.H4}
                fontWeight={ClinTheme.fontWeights.medium}
                color={ClinTheme.colors.black}
              >
                {order.totals.total === undefined || order.totals.total === null
                  ? '--'
                  : numberToCurrencyString(
                      order.totals.total,
                      getBrowserLocale(),
                      {
                        style: 'currency',
                        currency: order.currencyCode,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }
                    )}
              </ClinText>
            </ClinGroup>

            <ClinSpacer height={20} />
            <ClinButton
              variant="primaryNarrow"
              disabled={
                isSubmitDisabled ||
                !isValidPO ||
                !isValid ||
                deliveryNote.length > deliveryNoteMaxCharacters
              }
              onClick={() => handleConfirmOrder(poNumber)}
            >
              {t('checkout_options:checkout_options.place_order_btn')}
            </ClinButton>
          </StyledDeliverySummary>
        </Col>
      </Row>
    </>
  )
}
