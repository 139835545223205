import styled, { keyframes } from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'
import { IClinAccordionStyles, IClinAccordionProps } from './ClinAccordion'
const mobileUpBreakpoint = ClinTheme.breakpoints[1]

const StyledExpand = keyframes`
0% {
  opacity: 0;
  transform: translateY(-${ClinTheme.space[3]}px);
}
100% {
   opacity: 1;
    transform: translateY(0px);
  }
`

export const StyledAccordion = styled.div<IClinAccordionStyles>(
  {},
  ({ isHidden }) => ({
    display: isHidden ? 'none' : 'initial'
  })
)

export const StyledAccordionHeader = styled.div<IClinAccordionStyles>(
  {
    [`span`]: {
      fontFamily: ClinTheme.fonts.body,
      textAlign: 'left',
      color: ClinTheme.colors.primary,

      [mediaQuery(mobileUpBreakpoint)]: {
        fontSize: ClinTheme.fontSizes[4]
      }
    },
    [`button`]: {
      display: 'inline-flex',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: 0,
      paddingLeft: 0,
      width: '100%',
      backgroundColor: ClinTheme.colors.white,
      paddingBottom: ClinTheme.space[3],
      [`:focus`]: {
        outline: 'none'
      }
    },
    [`.clin-icon`]: {
      marginRight: ClinTheme.space[2]
    }
  },
  ({ isActive }) => ({
    [`.clin-icon`]: {
      transition: 'transform 0.25s ease-in-out',
      transform: isActive ? 'rotate(180deg)' : 'none'
    }
  }),
  ({ hideBorder }) => ({
    [`button`]: {
      borderTop: hideBorder ? 'none' : `1px solid ${ClinTheme.colors.grey}`
    }
  }),
  ({ paddingTop }) => ({
    [`button`]: {
      paddingTop: paddingTop ?? ClinTheme.space[3]
    }
  }),
  ({ fontSize, fontWeight }) => ({
    [`span`]: {
      fontSize: fontSize
        ? ClinTheme.fontSizes[fontSize]
        : ClinTheme.fontSizes[3],
      fontWeight: fontWeight ?? ClinTheme.fontWeights.light,

      [mediaQuery(mobileUpBreakpoint)]: {
        fontSize: fontSize
          ? ClinTheme.fontSizes[fontSize + 1]
          : ClinTheme.fontSizes[4]
      }
    }
  })
)

export const StyledAccordionPanel = styled.section<IClinAccordionStyles>`
  animation: ${StyledExpand} 0.25s ease-out;
  padding: ${ClinTheme.space[3]}px 0 ${ClinTheme.space[3]}px;
  color: ${ClinTheme.colors.black};
  font-size: ${ClinTheme.fontSizes[2]}px;
  font-weight: ${ClinTheme.fontWeights.light};
  line-height: ${ClinTheme.lineHeights.body};
  width: 90%;
  @media (max-width: ${mobileUpBreakpoint}px) {
    width: '100%';
  }
  @media print {
    background-color: #000;
    display: block;
  }
`

export const StyledAccordionItem = styled.div<IClinAccordionProps>(
  {
    marginBottom: ClinTheme.space[3]
  },
  ({ hideBorder }) => ({
    borderBottom: hideBorder ? 'none' : `1px solid ${ClinTheme.colors.grey}`
  })
)
