import React, { FunctionComponent } from 'react'
import {
  StyledClinStepper,
  StyledClinStep,
  StyledStepBadge,
  IClinStepperStyles
} from './ClinStepper.styles'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinText } from '../ClinText'
import { ClinTheme } from '../../ClinTheme'

export enum StepState {
  Complete,
  Active,
  InComplete
}

interface IStep {
  state: StepState
  displayText?: string
}

interface IClinStepperProps extends IClinStepperStyles {
  /** A number of steps with various state */
  steps: IStep[]
}

export const ClinStepper: FunctionComponent<IClinStepperProps> = ({
  steps,
  ...rest
}) => (
  <StyledClinStepper className={`clin-stepper `} {...rest}>
    {steps?.map(({ state, displayText }, index) => {
      return (
        <StyledClinStep key={`step-${displayText}`}>
          <StyledStepBadge state={state}>
            {state === StepState.Complete ? (
              <ClinIcon
                iconName={ClinIconPathName.Tick}
                iconFill={ClinTheme.colors.greenValid}
              />
            ) : (
              <span>{index + 1}</span>
            )}
          </StyledStepBadge>
          <ClinText
            as="div"
            fontWeight={ClinTheme.fontWeights.medium}
            marginLeft={ClinTheme.space[2]}
            marginRight={ClinTheme.space[2]}
          >
            {displayText}
          </ClinText>
        </StyledClinStep>
      )
    })}
  </StyledClinStepper>
)
