export enum LovName {
  FORMULATION = 'Formulation',
  SPECIALISM = 'Specialisms',
  PROGRAM_CHARGE_TYPE = 'Program Charge Type',
  PATIENT_ENROLMENT_STATUS = 'Patient Enrolment Status',
  ATC_LEVEL1 = 'ATC Level1',
  JOB_TITLES = 'Job titles',
  PHYSICIAN_ENROLMENT_STATUS = 'Physician Enrolment Status',
  PATIENT_DISCONTINUATION_REASON = 'Patient Discontinuation Reason'
}
