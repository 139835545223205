import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { DrugPropertyItem } from '../../features/OrderItems/OrderItem'
import { ClinTheme } from '../../ClinTheme'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../components/ClinAccordion'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinText } from '../../components/ClinText'
import {
  HoldDto,
  OrderLineDto,
  UploadedDocumentDto
} from '../../types/swaggerTypes'
import { numberToCurrencyString } from '../../utils/numberToCurrencyString'
import {
  StyledOpaOrderItem,
  IStyledOpaOrderItemProps,
  StyledOpaOrderItemDetailMobileLabel,
  StyledOpaOrderItemDetailValue,
  StyledOpaOrderItemName
} from './OpaOrderItem.styles'
import { getBrowserLocale } from '../../utils/getBrowserLocale'
import { HoldType } from '../../services/Holds/holds.constants'
import { useTranslation } from 'react-i18next'

export enum OrderItemType {
  OrdinaryItem,
  ReassignedItem
}
interface DocsDescription {
  message: string
  status?: string
}
export interface IOpaOrderItemProps extends IStyledOpaOrderItemProps {
  /** The order id **/
  orderId: string
  /** Order items */
  orderLine: OrderLineDto
  /** Sponsor of program */
  sponsor: string | undefined
  /** Currency code */
  currencyCode: string
  /** Order item type */
  itemType: OrderItemType
  /** Hide/show file upload field */
  isFileUploadEnabled: boolean
  /** Called when a file has been uploaded */
  handleFileUploadedForOrderAndHold?: (
    orderId: string,
    orderLine: OrderLineDto,
    hold: HoldDto,
    document: UploadedDocumentDto
  ) => void
}

export const OpaOrderItem: FunctionComponent<IOpaOrderItemProps> = ({
  orderId,
  orderLine,
  sponsor,
  currencyCode,
  itemType,
  isFileUploadEnabled,
  isShowingProductDetails = true,
  hasBorder,
  handleFileUploadedForOrderAndHold
}) => {
  const { t } = useTranslation()
  const { skuCatalogItem } = orderLine
  const genericName = skuCatalogItem?.item.genericDosageConcatenation
  const dosageAndPackSize =
    skuCatalogItem?.formulationPackSize || t('glossary:not_applicable')
  const quantity =
    itemType === OrderItemType.OrdinaryItem
      ? orderLine.quantities.ordered
      : orderLine.vialsReassignedQuantity
  const brandName = skuCatalogItem?.item.brand
    ? skuCatalogItem?.item.brand
    : t('glossary:not_applicable')
  const licenseCountries: string[] = []
  skuCatalogItem?.licenses.forEach(
    (license) => license.country && licenseCountries.push(license.country)
  )
  const licenseCountry =
    licenseCountries.join(', ') || t('glossary:not_applicable')
  const packPrice = orderLine.unitSellingPrice
    ? numberToCurrencyString(orderLine.unitSellingPrice, getBrowserLocale(), {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    : '0'

  const docsData: DocsDescription[] = []
  const { holds } = orderLine
  const validationHold = holds.find(
    (hold) => hold.holdName === HoldType.ValidationHold
  )
  const controlledDrugs = holds.find(
    (hold) => hold.holdName === HoldType.ControlledDrug
  )
  if (holds.length === 0 || (!validationHold && !controlledDrugs)) {
    docsData.push({
      message: t(
        'ma_order_detail:order_items.supporting_documents_not_required'
      )
    })
  }
  if (validationHold) {
    docsData.push({
      message: t('holds:import_license'),
      status: t('glossary:not_provided')
    })
  }
  if (controlledDrugs) {
    docsData.push({
      message: t('holds:controlled_drug_import_license'),
      status: t('glossary:not_provided')
    })
  }

  return (
    <StyledOpaOrderItem
      hasBorder={hasBorder}
      isShowingProductDetails={isShowingProductDetails}
    >
      <Row className="order-data">
        <Col sm={12} md={6}>
          <StyledOpaOrderItemDetailValue>
            <StyledOpaOrderItemDetailMobileLabel>
              <ClinText
                fontSize={ClinTheme.fontSizes[1]}
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
              >
                {t('ma_order_detail:order_items.table_header_product')}
              </ClinText>
            </StyledOpaOrderItemDetailMobileLabel>
            <StyledOpaOrderItemName className="generic-name">
              {genericName}
            </StyledOpaOrderItemName>
          </StyledOpaOrderItemDetailValue>
        </Col>
        <Col sm={12} md={2}>
          <StyledOpaOrderItemDetailValue>
            <StyledOpaOrderItemDetailMobileLabel>
              <ClinText
                fontSize={ClinTheme.fontSizes[1]}
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
              >
                {t('ma_order_detail:order_items.table_header_product_code')}
              </ClinText>
            </StyledOpaOrderItemDetailMobileLabel>
            <ClinText
              className="product-code"
              fontSize={ClinTheme.fontSizes[2]}
              textAlign="right"
            >
              {orderLine.skuCatalogItem?.sku || t('glossary:not_applicable')}
            </ClinText>
          </StyledOpaOrderItemDetailValue>
        </Col>
        <Col sm={12} md={2}>
          <StyledOpaOrderItemDetailValue>
            <StyledOpaOrderItemDetailMobileLabel>
              <ClinText
                fontSize={ClinTheme.fontSizes[1]}
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
              >
                {t('ma_order_detail:order_items.table_header_quantity')}
              </ClinText>
            </StyledOpaOrderItemDetailMobileLabel>
            <ClinText
              className="quantity"
              fontSize={ClinTheme.fontSizes[2]}
              textAlign="right"
            >
              {quantity}
            </ClinText>
          </StyledOpaOrderItemDetailValue>
        </Col>
        <Col sm={12} md={2}>
          <StyledOpaOrderItemDetailValue>
            <StyledOpaOrderItemDetailMobileLabel>
              <ClinText
                fontSize={ClinTheme.fontSizes[1]}
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
              >
                {t('ma_order_detail:order_items.table_header_amount')}
              </ClinText>
            </StyledOpaOrderItemDetailMobileLabel>
            <ClinText
              className="amount"
              fontSize={ClinTheme.fontSizes[2]}
              textAlign="right"
            >
              {packPrice}
            </ClinText>
          </StyledOpaOrderItemDetailValue>
        </Col>
      </Row>

      {isShowingProductDetails && (
        <Row className="program-details-and-docs">
          <Col sm={12} md={8}>
            <ClinAccordion activeItemIndices={[1]}>
              <ClinAccordionItem
                // title={t('ma_order_detail:order_items.program_details_title')}
                title="Product details "
                iconFill={ClinTheme.colors.black}
              >
                <Row>
                  <DrugPropertyItem
                    iconName={ClinIconPathName.PackSize}
                    title={t('glossary:dosage_and_packsize')}
                    description={dosageAndPackSize || 'N/A'}
                  />
                  <DrugPropertyItem
                    iconName={ClinIconPathName.Marker}
                    title={t('glossary:country_of_license')}
                    description={licenseCountry}
                  />
                  <DrugPropertyItem
                    iconName={ClinIconPathName.BrandName}
                    title={t('glossary:product')}
                    description={brandName}
                  />
                  <DrugPropertyItem
                    iconName={ClinIconPathName.DocumentSponsor}
                    title={t('glossary:sponsor')}
                    description={sponsor || t('glossary:not_applicable')}
                  />
                  <DrugPropertyItem
                    iconName={ClinIconPathName.UnitPrice}
                    title={t('glossary:unit_price')}
                    description={packPrice}
                  />
                </Row>
                {/*
                //Commented out until 8.1 
                <StyledOpaOrderItemDocs>
                  <Row>
                    <Col sm={12}>
                      <ClinText
                        color={ClinTheme.colors.primary}
                        fontSize={ClinTheme.fontSizes[3]}
                        marginTop={ClinTheme.space[4]}
                        marginBottom={ClinTheme.space[3]}
                      >
                        { {t(
                          'ma_order_detail:order_items.supporting_documents_title'
                        )} }
                        Supporting documents
                      </ClinText>
                      <ClinSpacer height={ClinTheme.space[1]} />
                      {!isFileUploadEnabled ? (
                        docsData.map((data, index) => (
                          <Row key={index}>
                            <Col sm={data.status ? 6 : 12}>
                              <ClinText
                                className={'hold-generic-name'}
                                fontSize={ClinTheme.fontSizes[2]}
                                marginBottom={ClinTheme.space[3]}
                              >
                                {data.message}
                              </ClinText>
                            </Col>
                            {data.status && (
                              <Col sm={6}>
                                <ClinText
                                  className="hold-document-status"
                                  fontSize={ClinTheme.fontSizes[2]}
                                  fontWeight={ClinTheme.fontWeights.bold}
                                  color={ClinTheme.colors.redInvalid}
                                  marginBottom={ClinTheme.space[3]}
                                >
                                  {data.status}
                                </ClinText>
                              </Col>
                            )}
                          </Row>
                        ))
                      ) : (
                        <>
                          {holdsInformation.requiresDocuments ? (
                            <ClinText marginBottom={ClinTheme.space[3]}>
                              <Trans
                                i18nKey="ma_order_detail:order_items.supporting_documents_required"
                                components={{ br: <br /> }}
                              >
                                <></>
                              </Trans>
                            </ClinText>
                          ) : (
                            <ClinText marginBottom={ClinTheme.space[3]}>
                              <Trans
                                i18nKey="ma_order_detail:order_items.supporting_documents_not_required"
                                components={{ br: <br /> }}
                              >
                                <></>
                              </Trans>
                            </ClinText>
                          )}

                          {holdsInformation.holdsDetails.map((hold, index) => {
                            return hold.requiresDocumentUpload &&
                              hold.requiredDocumentName &&
                              !hold.documentUploaded ? (
                              <StyledHoldFileItem key={`doc-upload-${index}`}>
                                <DocumentUploadContainer
                                  isPreOrderVariant={true}
                                  documentPrompt={hold.documentPrompt}
                                  warehouseAddress={hold.wareHouseAddress}
                                  prompt={hold.requiredDocumentName}
                                  handleFileUploaded={(document) => {
                                    analyticsServiceSingleton.trackEvent(
                                      AnalyticsEvent.DocumentUpload,
                                      {
                                        sku: orderLine.skuCatalogItem?.sku,
                                        documentType: hold.requiredDocumentName
                                      }
                                    )
                                    handleFileUploadedForOrderAndHold &&
                                      hold.holdDto?.holdId &&
                                      handleFileUploadedForOrderAndHold(
                                        orderId,
                                        orderLine,
                                        hold.holdDto,
                                        document
                                      )
                                  }}
                                />
                              </StyledHoldFileItem>
                            ) : (
                              hold.documentUploaded && (
                                <DocumentUpload
                                  key={`doc-upload-${index}`}
                                  prompt={hold.documentUploaded.documentType}
                                  fileName={hold.documentUploaded.documentName}
                                  uploadDetails={{
                                    uploadDate:
                                      hold.documentUploaded.uploadedDate,
                                    uploadedBy: hold.documentUploaded.uploadedBy
                                  }}
                                />
                              )
                            )
                          })}
                        </>
                      )}
                    </Col>
                  </Row>
                </StyledOpaOrderItemDocs> */}
              </ClinAccordionItem>
            </ClinAccordion>
          </Col>
        </Row>
      )}
    </StyledOpaOrderItem>
  )
}
