import React, { FunctionComponent, useEffect, useState } from 'react'
import { RecentSkuCards } from './RecentSkuCards'
import {
  AuthError,
  cancelGetRecentlyViewedSkus,
  getRecentlyViewedSkus
} from '../../services/ApiService'
import { useHistory } from 'react-router'
import { useAppContext } from '../../context/app'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import {
  getStockLevelsForWarehouse,
  getVariantForSkuGivenCountry
} from '../../services/PortfolioJourneys/PortfolioJourney.model'
import { IAugmentedCatalogItemDto } from '../../services/PortfolioJourneys/PortfolioJourney.types'
import { getDefaultWarehouseCode } from '../../pages/Products/ProductVariant/ProductVariant.models'

export const RecentSkuCardsContainer: FunctionComponent = () => {
  const [recentSkus, setRecentSkus] = useState<
    IAugmentedCatalogItemDto[] | undefined
  >(undefined)
  const { dispatch, portfolioCountryCode, institute } = useAppContext()
  const history = useHistory()

  useEffect(() => {
    if (portfolioCountryCode) {
      getRecentlyViewedSkus({ take: 9 })
        .then((skus) => {
          setRecentSkus(
            skus &&
              skus.data
                .filter((item) => item.value.sku)
                .map((item) => {
                  // Augment the SKU with availability
                  const variantState = getVariantForSkuGivenCountry(
                    item.value.sku.portfolioRules,
                    portfolioCountryCode
                  ).uiVariationState

                  // Determine first available warehouse
                  const defaultWarehouseCode = item.value.sku.item
                    .defaultWarehouse
                    ? item.value.sku.item.defaultWarehouse
                    : institute.data?.warehouses
                    ? institute.data?.warehouses[0].warehouseCode
                    : getDefaultWarehouseCode(portfolioCountryCode)

                  const availability = getStockLevelsForWarehouse(
                    portfolioCountryCode,
                    defaultWarehouseCode,
                    item.value.sku.stockLevels,
                    variantState
                  )
                  return {
                    ...item.value.sku,
                    availableText: availability.displayText,
                    availableColour: availability.colour
                  }
                })
          )
        })
        .catch((error) => {
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error recently viewed products. ${error.message} ${
                error.code ? error.code : ''
              }`
            )
          )
        })
    }

    return () => cancelGetRecentlyViewedSkus()
  }, [dispatch, portfolioCountryCode, institute.data])

  return (
    <RecentSkuCards
      skus={recentSkus}
      onClickViewProduct={(sku) => history.push(`/product/sku/${sku.sku}`)}
    />
  )
}
