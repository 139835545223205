import { t } from 'i18next'
import React, { FunctionComponent } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { ClinTheme } from '../../../../ClinTheme'
import { ClinButton } from '../../../../components/ClinButton'
import { ClinGroup } from '../../../../components/ClinGroup'
import { ClinModal } from '../../../../components/ClinModal'
import { ClinSpacer } from '../../../../components/ClinSpacer'
import { ClinText } from '../../../../components/ClinText'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'

interface IDiscontinueWarningModalProps {
  /** Control internal state of modal **/
  isOpen?: boolean
  /** Handle submit institution selection  **/
  handleClose: () => void
}

export const DiscontinueWarningModal: FunctionComponent<
  IDiscontinueWarningModalProps
> = ({ isOpen, handleClose }) => {
  return (
    <ClinModal
      onClose={() => {
        handleClose()
      }}
      maxWidth="sm"
      height="auto"
      isOpen={isOpen}
    >
      <Container>
        <Row justify="center">
          <Col sm={12} md={11}>
            <Row>
              <Col sm={12} md={11}>
                <ClinText
                  as="h3"
                  variant={TypographyVariant.H3}
                  fontWeight={ClinTheme.fontWeights.bold}
                >
                  {t('patient_detail:discontinue_warning_modal.title')}
                </ClinText>

                <ClinText variant={TypographyVariant.LargeParagraph}>
                  {t('patient_detail:discontinue_warning_modal.description')}
                </ClinText>
              </Col>
            </Row>
            <ClinSpacer height={ClinTheme.space[5]} />
            <Row>
              <Col sm={12} md={7}>
                <Row justify="between">
                  <Col md={5} lg={6}>
                    <ClinGroup>
                      <ClinButton
                        style={{ width: '100%' }}
                        className="close-warning"
                        variant="primary"
                        onClick={() => {
                          handleClose()
                        }}
                      >
                        {t('common:buttons.ok')}
                      </ClinButton>
                    </ClinGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <ClinSpacer />
          </Col>
        </Row>
      </Container>
    </ClinModal>
  )
}
