import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQueryDown, mediaQuery } from '../../utils/mediaQuery'
import { ClinButton } from '../../components/ClinButton'

const mobileUpBreakpoint = ClinTheme.breakpoints[0]
const tabletUpBreakpoint = ClinTheme.breakpoints[1]

interface IStyledIconProps {
  height?: number
  width?: number
  isPositioned?: boolean
}

export const StyledIcon = styled.img<IStyledIconProps>`
  ${mediaQueryDown(mobileUpBreakpoint)} {
    display: none;

    &.lightening-icon {
      display: unset !important;
      top: unset !important;
      right: unset !important;
      height: 31px;
      position: absolute !important;
      margin: 10px 0 0 3px;
    }
  }
`

export const StyledPageHeader = styled.header({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  padding: `${ClinTheme.space[5]}px`,

  h1: {
    fontFamily: 'CabinetGrotesk-Extrabold, sans-serif',
    fontWeight: ClinTheme.fontWeights.bolder
  },

  [mediaQueryDown(mobileUpBreakpoint)]: {
    padding: `${ClinTheme.space[4]}px ${ClinTheme.space[3]}px`,
    div: {
      flexDirection: 'row'
    },
    '.clin-text': {
      textAlign: 'center'
    }
  }
})

export const StyledClinButton = styled(ClinButton)({
  textTransform: 'none',
  width: '258px',
  height: '48px',
  color: '#270072',
  ':hover': {
    color: ClinTheme.colors.white
  },

  [mediaQueryDown(mobileUpBreakpoint)]: {
    width: '100%',
    maxWidth: '258px',
    margin: `${ClinTheme.space[2]}px 0`
  }
})

export const StyledWelcomeContainer = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: `${ClinTheme.space[4]}px ${ClinTheme.space[3]}px`,

  [mediaQuery(tabletUpBreakpoint)]: {
    minHeight: 'auto',
    padding: 0
  }
})

export const StyledSearchContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  margin: `${ClinTheme.space[5]}px 0`,
  width: '100%',

  '.autosuggest': {
    width: '100%',
    maxWidth: '1105px'
  },

  [mediaQueryDown(mobileUpBreakpoint)]: {
    margin: `${ClinTheme.space[4]}px 0`
  }
})

export const StyledHomeContainer = styled.div({
  backgroundColor: ClinTheme.colors.lightGrey
})

export const StyledContainer = styled.div({
  [mediaQueryDown(mobileUpBreakpoint)]: {
    '.clin-spacer-recent-searches': {
      height: '30px'
    }
  }
})

export const StyledButtonRow = styled.div({
  display: 'flex',
  justifyContent: 'center',
  gap: `${ClinTheme.space[4]}px`,
  flexWrap: 'wrap',
  width: '100%',

  [mediaQueryDown(mobileUpBreakpoint)]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: `${ClinTheme.space[3]}px`
  }
})

export const StyledSearchIcon = styled.span({
  display: 'inline',
  [mediaQueryDown(mobileUpBreakpoint)]: {
    display: 'none'
  }
})

export const StyledRecentSearches = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  marginTop: `${ClinTheme.space[4]}px`,
  marginBottom: ClinTheme.space[9],
  width: '100%',
  gap: `${ClinTheme.space[3]}px`,

  [mediaQueryDown(mobileUpBreakpoint)]: {
    flexDirection: 'column',
    marginTop: `${ClinTheme.space[3]}px`,
    marginBottom: ClinTheme.space[5]
  }
})

export const StyledRecentSearchesLabel = styled.p({
  margin: 0,
  fontSize: ClinTheme.fontSizes[2],
  color: ClinTheme.colors.black,
  fontWeight: ClinTheme.fontWeights.normal
})

export const StyledButtonRecentSearches = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: `${ClinTheme.space[2]}px`,

  '.button-text': {
    display: 'inline-block',
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[4]}px`,
    borderRadius: `${ClinTheme.space[4]}px`,
    color: ClinTheme.colors.primaryLight,
    backgroundColor: ClinTheme.colors.white,
    textAlign: 'center',
    fontSize: ClinTheme.fontSizes[2],
    fontWeight: ClinTheme.fontWeights.medium,
    lineHeight: ClinTheme.lineHeights.body,
    cursor: 'pointer'
  },

  [mediaQueryDown(mobileUpBreakpoint)]: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',

    '.button-text': {
      width: 'auto',
      maxWidth: '100%'
    }
  }
})
