import { TProgramColumnNames } from '../../types'
import { ProgramColumnNames } from '../../constants'
import { ProgramSearchDto } from '../../types/swaggerTypes'
import { SortDirectionType } from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { IPagination } from '../../components/ClinPagination/ClinPagination.model'

export interface ProgramColumn {
  name: TProgramColumnNames
  viewName: string
  translationKey: string
}

export const programColumns: ProgramColumn[] = [
  {
    name: ProgramColumnNames.ProgramName,
    viewName: 'Program Name',
    translationKey: 'glossary:program_name'
  },
  {
    name: ProgramColumnNames.Sponsor,
    viewName: 'Sponsor',
    translationKey: 'glossary:sponsor'
  },
  {
    name: ProgramColumnNames.TherapeuticArea,
    viewName: 'Therapeutic Area',
    translationKey: 'glossary:therapeutic_area'
  },
  {
    name: ProgramColumnNames.Status,
    viewName: 'Status',
    translationKey: 'glossary:status'
  },
  {
    name: ProgramColumnNames.Availability,
    viewName: 'Availability',
    translationKey: 'glossary:availability'
  }
]

/**
 * Utility function to get a sort column back
 * @param searchParams
 * @param defaultColumn
 */
export const getSortColumnFromURLParams = (
  searchParams: URLSearchParams,
  defaultColumn: ProgramColumn
): ProgramColumn => {
  const sortColumn = searchParams.get('sortColumn')
  const matchSortColumn = programColumns.find(
    (pc) => pc.name.toString() === sortColumn
  )
  return matchSortColumn ?? defaultColumn
}

const defaultRowsPerPageSecondTab = 100
const defaultRowsPerPageFirstTab = 5
export let defaultRowsPerPage = defaultRowsPerPageFirstTab

export const defaultSearchParams: ProgramSearchDto = {
  query: '',
  filter: {
    therapeuticAreas: [],
    availabilities: []
  },
  pagination: {
    skip: 0,
    take: defaultRowsPerPage
  },
  sorting: {
    sortBy: 'programName',
    order: SortDirectionType.Ascending
  }
}

export const defaultPagination: IPagination = {
  count: 0,
  skip: 0,
  take: defaultRowsPerPage,
  total: 0
}

export const changeDefaultRowsPerPage = (tabIndex: number) => {
  defaultRowsPerPage = tabIndex
    ? defaultRowsPerPageSecondTab
    : defaultRowsPerPageFirstTab
}
