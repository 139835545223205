import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { ClinButtonIcon } from '../ClinButtonIcon'

export const StyledClinTableToggleRowButton = styled(ClinButtonIcon)({
  padding: 6,
  marginTop: -3,
  transition: 'transform 0.3s',
  [`&.icon-toggle--open`]: {
    transform: 'rotate(180deg)'
  },
  ':hover': {
    boxShadow: `0 0 0 2px ${ClinTheme.colors.midGrey}`
  },
  [`:hover svg path:first-child`]: {
    fill: ClinTheme.colors.magenta
  }
})
