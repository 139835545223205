import React, { FunctionComponent } from 'react'

import { ClinButton } from '../../components/ClinButton'
import { ClinModal } from '../../components/ClinModal'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinTheme } from '../../ClinTheme'
import { ClinGroup } from '../../components/ClinGroup'
import { useTranslation } from 'react-i18next'

interface ISessionTimeOutProps {
  /** Whether its displayed */
  isOpen?: boolean
  /** Seconds for time out */
  timeRemainingSecs?: number
  /** Handle click on "Log out" button */
  handleLogOut?: () => void
  /** Handle click on "Continue" button */
  handleContinue?: () => void
}

export const SessionTimeOut: FunctionComponent<ISessionTimeOutProps> = ({
  isOpen,
  timeRemainingSecs = 0,
  handleLogOut,
  handleContinue
}) => {
  const { t } = useTranslation()
  return (
    <ClinModal isOpen={isOpen} isDismissible={false}>
      <ClinText as="h3" variant={TypographyVariant.H3}>
        {t('common:session_time_out.title')}
      </ClinText>
      <ClinText variant={TypographyVariant.LargeParagraph}>
        {t('common:session_time_out.time_remaining', {
          counter: timeRemainingSecs,
          seconds: t('glossary:second', {
            count: timeRemainingSecs
          })
        })}
        <br /> <br />
        {t('common:session_time_out.continue_session')}
      </ClinText>
      <ClinSpacer height={ClinTheme.space[4]} />
      <ClinGroup justifyContent="flex-end">
        <ClinButton onClick={() => handleLogOut && handleLogOut()}>
          {t('common:buttons.logout')}
        </ClinButton>
        <ClinButton
          variant="primary"
          onClick={() => handleContinue && handleContinue()}
        >
          {t('common:session_time_out.continue_session_btn')}
        </ClinButton>
      </ClinGroup>
    </ClinModal>
  )
}
