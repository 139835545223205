import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'

const mediumUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledSummaryOrderItemValue = styled.div({
  display: 'flex',
  [mediaQuery(mediumUpBreakpoint)]: {
    display: 'block'
  },
  [`.clin-text`]: {
    textAlign: 'left',
    [mediaQuery(mediumUpBreakpoint)]: {
      textAlign: 'right'
    }
  },
  [`@media print`]: {
    display: 'flex',
    flexDirection: 'column'
  }
})

export const StyledSummaryOrderMobileLabel = styled.div({
  display: 'block',
  marginRight: ClinTheme.space[3],
  [mediaQuery(mediumUpBreakpoint)]: {
    display: 'none'
  },
  [`@media print`]: {
    minWidth: '100px'
  }
})

export const StyledSummaryOrderItem = styled.div({
  paddingTop: ClinTheme.space[2],
  paddingBottom: ClinTheme.space[4],
  borderBottom: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.primaryLightest}`,

  [`> div > div:first-of-type`]: {
    '.clin-text': {
      textAlign: 'left'
    }
  },

  [`.accordion-panel`]: {
    padding: 0,
    width: '100%',
    borderBottom: 'none',
    boxSizing: 'border-box'
  },

  [`.accordion__item`]: {
    borderBottom: 'none',
    marginBottom: 0,

    'button ': {
      textAlign: 'left',
      paddingTop: ClinTheme.space[4],
      span: {
        fontSize: ClinTheme.fontSizes[3],
        fontWeight: ClinTheme.fontWeights.medium,
        color: ClinTheme.colors.primary
      },
      '.clin-icon': {
        width: ClinTheme.fontSizes[4],
        height: ClinTheme.fontSizes[4]
      }
    }
  }
})

export const StyledCheckoutOrderItem = styled.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  borderBottom: `1px solid ${ClinTheme.colors.grey}`,
  [mediaQuery(mediumUpBreakpoint)]: {
    flexDirection: 'row'
  }
})

export const StyledCheckoutOrderItemDetails = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: '0 0 100%',
  padding: ClinTheme.space[4],
  boxSizing: 'border-box',
  [mediaQuery(mediumUpBreakpoint)]: {
    flex: '0 0 60%'
  }
})

export const StyledSummaryOrderLineDelete = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end'
})

export const StyledSummaryItemProperties = styled.div({
  marginTop: ClinTheme.space[2]
})

export const StyledSummaryProperty = styled.div({
  display: 'flex',
  marginBottom: ClinTheme.space[3]
})

export const StyledSummaryPropertyDetail = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: ClinTheme.space[1],
  marginLeft: ClinTheme.space[2]
})
