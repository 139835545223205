// PatientDetailCard.jsx or PatientDetailCard.tsx
import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router'
import { ClinText } from '../../../components/ClinText'
import { ClinSpacer } from '../../../components/ClinSpacer'
import {
  StyledPatientDetailsCard,
  StyledStatusWrapper,
  StyledHyperlink
} from './PatientDetailCard.styles'
import { ClinTheme } from '../../../ClinTheme'
import { useTranslation } from 'react-i18next'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ProgramDetailStatus } from '../../../constants'

interface IPatientDetailCard {
  programName: string | undefined
  programStatus: string | null
  programSponsor: string | undefined
  programId: number | undefined
}

export const PatientDetailCard: FunctionComponent<IPatientDetailCard> = ({
  programName,
  programStatus,
  programSponsor,
  programId
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const handleNavigateToProgram = () => {
    programId &&
      history.push({
        pathname: `/programs/access-programs/${programId}`,
        state: {
          from: window.location.pathname
        }
      })
  }

  let statusComponent
  switch (programStatus) {
    case ProgramDetailStatus.NotAvailableInYourCountry:
      statusComponent = (
        <StyledStatusWrapper color="rgba(117, 117, 117, 0.1)">
          <ClinText
            whiteSpace="nowrap"
            fontWeight={ClinTheme.fontWeights.medium}
            color={ClinTheme.colors.darkGrey}
            textAlign="center"
            fontSize={ClinTheme.fontSizes[0]}
          >
            {t('program_detail:statuses.not_yet_available_in_your_country')}
          </ClinText>
        </StyledStatusWrapper>
      )
    break
    case ProgramDetailStatus.Closed:
      statusComponent = (
        <StyledStatusWrapper color="rgba(117, 117, 117, 0.1)">
          <ClinText
            whiteSpace="nowrap"
            fontWeight={ClinTheme.fontWeights.medium}
            color={ClinTheme.colors.darkGrey}
            textAlign="center"
            fontSize={ClinTheme.fontSizes[0]}
          >
            {t('program_detail:statuses.closed')}
          </ClinText>
        </StyledStatusWrapper>
      )
    break
    case ProgramDetailStatus.AcceptingNewPatients:
      statusComponent = (
        <StyledStatusWrapper color="rgba(0, 162, 35, 0.1)">
          <ClinText
            whiteSpace="nowrap"
            fontWeight={ClinTheme.fontWeights.medium}
            color={ClinTheme.colors.greenValid}
            textAlign="center"
            fontSize={ClinTheme.fontSizes[0]}
          >
            {t('program_detail:statuses.accepting_new_patients')}
          </ClinText>
        </StyledStatusWrapper>
      )
    break
    case ProgramDetailStatus.Open:
      statusComponent = (
        <StyledStatusWrapper color="rgba(0, 162, 35, 0.1)">
          <ClinText
            whiteSpace="nowrap"
            fontWeight={ClinTheme.fontWeights.medium}
            color={ClinTheme.colors.greenValid}
            textAlign="center"
            fontSize={ClinTheme.fontSizes[0]}
          >
            {t('program_detail:statuses.open')}
          </ClinText>
        </StyledStatusWrapper>
      )
    break
    case ProgramDetailStatus.OpenForResupply:
      statusComponent = (
        <StyledStatusWrapper color="rgba(215, 106, 0, 0.10)">
          <ClinText
            whiteSpace="nowrap"
            fontWeight={ClinTheme.fontWeights.medium}
            color={ClinTheme.colors.orangeWarn}
            textAlign="center"
            fontSize={ClinTheme.fontSizes[0]}
          >
            {t('program_detail:statuses.open_for_resupply')}
          </ClinText>
        </StyledStatusWrapper>
      )
    break
    default:
      statusComponent = (
        <StyledStatusWrapper color="rgba(117, 117, 117, 0.1)">
          <ClinText
            whiteSpace="nowrap"
            fontWeight={ClinTheme.fontWeights.medium}
            color={ClinTheme.colors.primary}
            textAlign="center"
            fontSize={ClinTheme.fontSizes[0]}
          >
            {t('program_detail:statuses.closed')}
          </ClinText>
        </StyledStatusWrapper>
      )
    break
  }

  return (
    <StyledPatientDetailsCard>
      <ClinText
        variant={TypographyVariant.SmallUI}
        color={ClinTheme.colors.darkGrey}
        marginBottom={10}
        lineHeight={'16px'}
      >
        {t('patient_detail:program_details_program')}
      </ClinText>
      <ClinText
        className="program-name"
        variant={TypographyVariant.SmallUI}
        fontWeight={ClinTheme.fontWeights.bold}
        color={ClinTheme.colors.tealishBlue}
        lineHeight={'16px'}
      >
        <StyledHyperlink onClick={() => handleNavigateToProgram()}>
          {programName}
        </StyledHyperlink>
      </ClinText>
      <ClinSpacer height={16} />

      <ClinText
        marginBottom={10}
        variant={TypographyVariant.SmallUI}
        color={ClinTheme.colors.darkGrey}
        lineHeight={'16px'}
      >
        {t('program_detail:status')}
      </ClinText>
      {statusComponent}
      <ClinSpacer height={16} />
      <ClinText
        variant={TypographyVariant.SmallUI}
        color={ClinTheme.colors.darkGrey}
        marginBottom={10}
        lineHeight={'16px'}
      >
        {t('patient_detail:program_details_sponsor')}
      </ClinText>
      <ClinText
        className="program-sponsor"
        variant={TypographyVariant.SmallUI}
        fontWeight={ClinTheme.fontWeights.bold}
        color={ClinTheme.colors.tealishBlue}
        lineHeight={'16px'}
      >
        {programSponsor}
      </ClinText>
    </StyledPatientDetailsCard>
  )
}
