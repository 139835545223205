export enum InstituteModalEventType {
  SHOW = 'ShowInstituteModalEvent',
  HIDE = 'HideInstituteModalEvent',
  SET_FIRST_ACCESS = 'SetFirstAccess'
}

// Show ------------------------------------------------------------------------

export interface IShowInstituteModalEvent {
  type: InstituteModalEventType.SHOW
}

export const createShowInstituteModalEvent = (): IShowInstituteModalEvent => ({
  type: InstituteModalEventType.SHOW
})

// Hide ------------------------------------------------------------------------

export interface IHideInstituteModalEvent {
  type: InstituteModalEventType.HIDE
}

export const createHideInstituteModalEvent = (): IHideInstituteModalEvent => ({
  type: InstituteModalEventType.HIDE
})

// First Access ------------------------------------------------------------------------
export interface ISetFirstAccessEvent {
  type: InstituteModalEventType.SET_FIRST_ACCESS
  isFirstAccess: boolean
}

export const createSetFirstAccessEvent = (
  isFirstAccess: boolean
): ISetFirstAccessEvent => ({
  type: InstituteModalEventType.SET_FIRST_ACCESS,
  isFirstAccess
})

export type IInstituteModalEvent =
  | IShowInstituteModalEvent
  | IHideInstituteModalEvent
  | ISetFirstAccessEvent
