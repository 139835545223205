import styled from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'
import { ClinStyledButton } from '../../../components/ClinButton/ClinButton.styles'
import { mediaQuery } from '../../../utils/mediaQuery'

const mobileUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledMedications = styled.div(
  {
    '> div:not(:first-child)': {
      marginTop: ClinTheme.space[2]
    }
  },
  ({ isExpanded }: { isExpanded?: boolean }) => {
    if (isExpanded) {
      return {}
    }
    return {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': '2',
      '-webkit-box-orient': 'vertical'
    }
  }
)

export const StyledOrderNumber = styled.div({
  overflow: 'hidden',
  wordBreak: 'break-word',
  width: '160px'
})

export const StyledOrdersSearch = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  paddingRight: ClinTheme.space[0],
  maxWidth: ClinTheme.breakpoints[1],
  padding: ClinTheme.space[1],

  [mediaQuery(mobileUpBreakpoint)]: {
    flexDirection: 'row',
    padding: ClinTheme.space[3],
    alignItems: 'center'
  },

  [`${ClinStyledButton}`]: {
    marginTop: ClinTheme.space[3],

    [mediaQuery(mobileUpBreakpoint)]: {
      marginTop: 0
    }
  }
})
