import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { ClinTheme } from '../../../ClinTheme'
import { ClinButton } from '../../../components/ClinButton'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import {
  StyledSummaryHeading,
  StyledSummaryIcon
} from '../../CheckoutSummary/CheckoutSummary.styles'
import { StyledSuccessActions } from '../EnrolPhysicianOnToProgram.styles'
import { ClinStepper } from '../../../components/ClinStepper'
import { StepState } from '../../../components/ClinStepper/ClinStepper'
import {
  NewPhysicianEnrollDto,
  PhysiciansSummaryDto,
  ProgramCatalogDto
} from '../../../types/swaggerTypes'
import { useTranslation } from 'react-i18next'
import {
  StyledEnrolledSuccessCard,
  StyledEnrolledSuccessCardAnnounceBar,
  StyledEnrolledSuccessPhysicianDetails,
  StylesEnrolledSuccessCardIcon
} from '../../EnrolUserOnToProgram/EnrolUserOnToProgram.styles'

interface IEnrolPhysicianOnToProgramSuccessProps {
  /** Selected program */
  program?: ProgramCatalogDto
  /** Call back request to view program */
  handleRequestToViewProgram: () => void
  /** Call back request to view dashboard */
  handleRequestToViewDashboard: () => void

  selectedPhysicians?: PhysiciansSummaryDto[] | undefined
  savedPhysicians?: NewPhysicianEnrollDto[] | undefined
}

export const EnrolPhysicianOnToProgramSuccess: FunctionComponent<
  IEnrolPhysicianOnToProgramSuccessProps
> = ({
  program,
  handleRequestToViewProgram,
  handleRequestToViewDashboard,
  selectedPhysicians,
  savedPhysicians
}) => {
  const { t } = useTranslation()

  return (
    <ClinPageContentFrame>
      <Row>
        <Col sm={12} md={10} lg={8}>
          <ClinText
            as="h1"
            className="physician-full-name"
            variant={TypographyVariant.H2}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {t('enrol_physician_on_to_program:enrol_in_access_program', {
              programName: program?.program?.programName
            })}
          </ClinText>
          <ClinSpacer height={ClinTheme.space[5]} />

          <ClinStepper
            steps={[
              {
                state: StepState.Complete,
                displayText: t('enrol_physician_on_to_program:stepper.step_1')
              },
              {
                state: StepState.Complete,
                displayText: t('enrol_physician_on_to_program:stepper.step_2')
              }
            ]}
            width={400}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={9}>
          <ClinSpacer height={ClinTheme.space[8]} hasBorder />
        </Col>
      </Row>
      <StyledSummaryHeading>
        <StyledSummaryIcon>
          <ClinIcon
            iconName={ClinIconPathName.Tick}
            iconSize={ClinTheme.space[4]}
          />
        </StyledSummaryIcon>
        <ClinText
          variant={TypographyVariant.H4}
          as="span"
          fontWeight={ClinTheme.fontWeights.bold}
          marginLeft={ClinTheme.space[3]}
        >
          {t('enrol_physician_on_to_program:enrolment_submitted')}
        </ClinText>
      </StyledSummaryHeading>
      <ClinSpacer height={ClinTheme.space[5]} />
      <Row>
        <Col sm={12} md={10} lg={8}>
          <ClinText
            as="p"
            variant={TypographyVariant.LargeParagraph}
            marginBottom={ClinTheme.space[4]}
          >
            {t('enrol_user_on_to_program:confirmation_copy_1')}
          </ClinText>
          {savedPhysicians && savedPhysicians.length > 0 && (
            <StyledEnrolledSuccessCard>
              <ClinText
                as="h4"
                fontWeight={ClinTheme.fontWeights.bold}
                fontSize={ClinTheme.fontSizes[3]}
                marginBottom={ClinTheme.space[4]}
                wordBreak="break-all"
              >
                {t('enrol_user_on_to_program:new_physicians_title')}
              </ClinText>
              <StyledEnrolledSuccessCardAnnounceBar>
                <StylesEnrolledSuccessCardIcon>
                  <ClinIcon
                    iconName={ClinIconPathName.Tick}
                    iconSize={ClinTheme.space[4]}
                  />
                </StylesEnrolledSuccessCardIcon>
                <article>
                  <ClinText
                    as={'h5'}
                    color={ClinTheme.colors.darkPurple}
                    fontWeight={ClinTheme.fontWeights.bold}
                    fontSize={ClinTheme.fontSizes[2]}
                    marginBottom={ClinTheme.space[2]}
                    wordBreak="break-all"
                  >
                    {t(
                      'enrol_user_on_to_program:verification_eligibility_text'
                    )}
                  </ClinText>

                  <ClinText
                    as="p"
                    color={ClinTheme.colors.darkPurple4}
                    variant={TypographyVariant.LargeParagraph}
                    wordBreak="break-all"
                  >
                    {t('enrol_user_on_to_program:confirmation_sub_copy')}
                  </ClinText>

                  <ClinText
                    as="p"
                    color={ClinTheme.colors.darkPurple4}
                    variant={TypographyVariant.LargeParagraph}
                    wordBreak="break-all"
                  >
                    - {t('enrol_user_on_to_program:confirmation_bullet_1')}
                  </ClinText>

                  <ClinText
                    as="p"
                    color={ClinTheme.colors.darkPurple4}
                    variant={TypographyVariant.LargeParagraph}
                    wordBreak="break-all"
                  >
                    - {t('enrol_user_on_to_program:confirmation_bullet_2')}
                  </ClinText>

                  <ClinText
                    as="p"
                    color={ClinTheme.colors.darkPurple4}
                    variant={TypographyVariant.LargeParagraph}
                    wordBreak="break-all"
                  >
                    - {t('enrol_user_on_to_program:confirmation_bullet_3')}
                  </ClinText>

                  <ClinText
                    as="p"
                    color={ClinTheme.colors.darkPurple4}
                    marginTop={ClinTheme.space[2]}
                    variant={TypographyVariant.LargeParagraph}
                    wordBreak="break-all"
                  >
                    {t('enrol_user_on_to_program:confirmation_sub')}
                  </ClinText>
                </article>
              </StyledEnrolledSuccessCardAnnounceBar>

              <ClinSpacer height={ClinTheme.space[4]} />
              {savedPhysicians?.map((physician, index) => (
                <StyledEnrolledSuccessPhysicianDetails key={index}>
                  <ClinText
                    as="div"
                    fontWeight={ClinTheme.fontWeights.bold}
                    variant={TypographyVariant.LargeParagraph}
                    wordBreak="break-all"
                  >
                    {physician.title} {physician.firstName} {physician.lastName}
                  </ClinText>
                  <ClinText
                    as="div"
                    variant={TypographyVariant.LargeParagraph}
                    wordBreak="break-all"
                  >
                    {physician.emailAddress}
                  </ClinText>
                </StyledEnrolledSuccessPhysicianDetails>
              ))}
            </StyledEnrolledSuccessCard>
          )}
          <ClinSpacer height={ClinTheme.space[7]} />
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={10} lg={8}>
          <StyledSuccessActions>
            <ClinButton
              onClick={() => handleRequestToViewProgram()}
              variant="secondary"
            >
              {t('enrol_physician_on_to_program:view_access_program_btn')}
            </ClinButton>
            <ClinButton
              onClick={() => handleRequestToViewDashboard()}
              variant="secondary"
            >
              {t('enrol_physician_on_to_program:view_dashboard_btn')}
            </ClinButton>
          </StyledSuccessActions>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[8]} />
    </ClinPageContentFrame>
  )
}
