import React from 'react'
import {
  HoldDto,
  OrderDto,
  OrderLineDto,
  UploadedDocumentDto
} from '../../../types/swaggerTypes'
import { OrderStatus } from '../../../types/OrderStatus'
import { getVariantInformationForCatalogItem } from '../../../services/PortfolioJourneys/PortfolioJourney.model'

import { StyledCarrierImage } from './OrderDetail.styles'
import { carrierLookup } from '../../../constants/order'
/**
 * Helper utility to check if ENTIRE order can be reordered (to show Add all items)
 * @param order
 * @param countryCode
 */
export const checkCanOrderAgain = (
  order: OrderDto,
  countryCode: string
): boolean => {
  // Check all line items and make sure they are not cold chain charges or data loggers and they are available
  const medications = getMedications(order)
  // All medications need to be orderable
  for (let ol of medications) {
    if (ol.skuCatalogItem) {
      const cannotReOrder = getVariantInformationForCatalogItem(
        ol.skuCatalogItem,
        countryCode
      ).cannotOrder
      if (cannotReOrder) {
        return false
      }
    } else {
      return false
    }
  }
  return true
}

/**
 * Get medications from an order
 * @param order
 */
export const getMedications = (order: OrderDto | undefined): OrderLineDto[] => {
  return order ? order.lines.filter((ol) => ol.medicationFlag === 'Y') : []
}

/**
 * Get additional line items from an order
 * @param order
 */
export const getAdditionalLineItems = (
  order: OrderDto | undefined
): OrderLineDto[] => {
  return order
    ? order.lines.filter(
        (ol) =>
          (ol.medicationFlag === 'N' || ol.medicationFlag === null) &&
          ol.status !== OrderStatus.Cancelled.toString()
      )
    : []
}

/**
 * Update state of order
 * @param order
 * @param orderLine
 * @param hold
 * @param holdDocumentType
 * @param document
 */
export const getUpdatedOrderState = (
  order: OrderDto,
  orderLine: OrderLineDto,
  hold: HoldDto,
  holdDocumentType: string,
  document: UploadedDocumentDto
): OrderDto => {
  return {
    ...order,
    lines: order.lines.map((line) => {
      // If its not the line then return
      if (line.lineId !== orderLine.lineId) {
        return line
      }
      // We've found the line to update
      return {
        ...line,
        // Find the hold to update
        holds: line.holds.map((h) => {
          if (h.holdId !== hold.holdId) {
            return h
          }
          // We've found the hold to update - add the new document
          return {
            ...h,
            holdDocuments: [
              ...h.holdDocuments,
              {
                documentName: document.fileName,
                documentType: holdDocumentType,
                uploadedBy: 'you',
                uploadedDate: document.uploadedOn
              }
            ]
          }
        })
      }
    })
  }
}

//Fallback if no logo found is the Meaning column from the API response
export const getCarrierLogo = (carrier: string) => {
  let path: string = getCarrierLogoPath(carrier)
  return path !== '' ? (
    <StyledCarrierImage src={path} />
  ) : (
    getAppropriateCarrierName(carrier)
  )
}

const getCarrierLogoPath = (carrier: string) => {
  return carrierLookup.find((x) => x.Description === carrier)?.LogoSrc ?? ''
}

const getAppropriateCarrierName = (carrier: string) => {
  return carrierLookup.find((x) => x.Description === carrier)?.Meaning ?? ''
}

//Determines what to display for tracking based on carrier name
export const getCarrierType = (carrier: string | undefined): string => {
  if (carrier === undefined) return 'Not supported'
  return (
    carrierLookup.find((x) => x.Description === carrier)?.Type ??
    'Not supported'
  )
}

export const scrollToTrackingLinkKey = 'scroll-to-tracking-link'

// TODO: Potentially needed a look up for these line item category names:
// 'Data Loggers',
// 'NOI and CD License Items',
// 'Shippers',
// 'Production Components',
// 'Service Items',
// 'Equipment',
// 'Ancillary Equipment',
// 'Production Labels',
// 'Pharmaceuticals',
// 'Post Production',
// 'Raw Materials'
//  Unknown = 'Unknown'
