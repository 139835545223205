import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'

export interface IClinThreeBoxesStyles {}

const largeUpBreakpoint = ClinTheme.breakpoints[2]

export const StyledClinThreeBoxes = styled.div<IClinThreeBoxesStyles>({
  marginTop: 0,
  marginBottom: 69,
  color: `#000`,
  [mediaQuery(largeUpBreakpoint)]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    [`p:first-of-type`]: { marginBottom: '34px' }
  },
  fontFamily: ClinTheme.fonts.body,
  [`@media print`]: {
    pageBreakAfter: 'always'
  }
})
