import React, { FunctionComponent, useState } from 'react'
import { useAppContext } from './app'
import { useBasket } from './basket'
import { ClinSelect } from '../components/ClinSelect'
import { ClinButton } from '../components/ClinButton'
import { ClinSpacer } from '../components/ClinSpacer'
import styled from 'styled-components'
import { ClinTheme } from '../ClinTheme'
import { mediaQuery } from '../utils/mediaQuery'

/**
 * Context Initialiser
 *
 * A utility to show all app state vars
 */

/**
 * Show debug properties
 */
enum DebugProperty {
  ViewBasket = 'ViewBasket',
  Basket = 'Basket',
  User = 'User',
  UserDetails = 'UserDetails',
  Institute = 'Institute',
  Institutes = 'Institutes',
  Announce = 'Announce',
  DefaultShippingAddress = 'DefaultShippingAddress',
  PortfolioCountryCode = 'PortfolioCountryCode',
  SupportContact = 'SupportContact',
  AppContext = 'AppContext'
}

const desktopBreakpoint = ClinTheme.breakpoints[3]

const StyledDebugWrapper = styled.div({
  position: 'absolute',
  top: 0,
  left: ClinTheme.space[6],
  right: 0,
  bottom: 0,
  padding: 16,
  zIndex: 1001,
  overflowY: 'scroll',
  backgroundColor: 'rgb(255, 255, 255, 0.9)',
  [mediaQuery(desktopBreakpoint)]: {
    left: 0
  }
})

const StyledDebugClose = styled.div({
  position: 'absolute',
  top: 0,
  left: ClinTheme.space[6],
  padding: 16,
  zIndex: 1001,
  [mediaQuery(desktopBreakpoint)]: {
    left: 0
  }
})

export const ContextDebugger: FunctionComponent = () => {
  const {
    user,
    userDetails,
    supportContact,
    announcement,
    institute,
    defaultShippingAddress,
    portfolioCountryCode,
    institutes,
    isBootstrapped,
    bootstrapError,
    dispatch,
    basketError,
    confirmation,
    instituteWasSwitched,
    isAuthenticated,
    isFirstAccess,
    isInstituteModalOpen,
    userRole
  } = useAppContext()

  const [showPanel, setShowPanel] = useState<boolean>(false)
  const [showDebugItem, setShowDebugItem] = useState<string>(
    DebugProperty.User.toString()
  )
  const [basketState] = useBasket()

  const getDebugValueFor = (item: string): any => {
    const debugProperty = DebugProperty[item as DebugProperty]
    switch (debugProperty) {
      case DebugProperty.Announce:
        return announcement
      case DebugProperty.Basket:
        return basketState.basket
      case DebugProperty.Institute:
        return institute
      case DebugProperty.User:
        return user
      case DebugProperty.UserDetails:
        return userDetails
      case DebugProperty.ViewBasket:
        return basketState.viewBasket
      case DebugProperty.DefaultShippingAddress:
        return defaultShippingAddress
      case DebugProperty.PortfolioCountryCode:
        return portfolioCountryCode
      case DebugProperty.Institutes:
        return institutes
      case DebugProperty.SupportContact:
        return supportContact
      case DebugProperty.AppContext:
        return {
          user,
          userDetails,
          supportContact,
          announcement,
          institute,
          defaultShippingAddress,
          portfolioCountryCode,
          institutes,
          isBootstrapped,
          bootstrapError,
          dispatch,
          basketError,
          confirmation,
          instituteWasSwitched,
          isAuthenticated,
          isFirstAccess,
          isInstituteModalOpen,
          userRole
        }
    }
  }

  return (
    <>
      {showPanel ? (
        <StyledDebugWrapper>
          <ClinButton variant="linkButton" onClick={() => setShowPanel(false)}>
            <span
              role="img"
              aria-label="close"
              style={{ fontSize: ClinTheme.fontSizes[3] }}
            >
              ❎
            </span>
          </ClinButton>
          <>
            <ClinSpacer />
            <ClinSelect
              onChange={(e) => setShowDebugItem(e.currentTarget.value)}
            >
              <option value={DebugProperty.User.toString()}>
                {DebugProperty.User.toString()}
              </option>
              <option value={DebugProperty.UserDetails.toString()}>
                {DebugProperty.UserDetails.toString()}
              </option>
              <option value={DebugProperty.ViewBasket.toString()}>
                {DebugProperty.ViewBasket.toString()}
              </option>
              <option value={DebugProperty.Basket.toString()}>
                {DebugProperty.Basket.toString()}
              </option>
              <option value={DebugProperty.Institute.toString()}>
                {DebugProperty.Institute.toString()}
              </option>
              <option value={DebugProperty.DefaultShippingAddress.toString()}>
                {DebugProperty.DefaultShippingAddress.toString()}
              </option>
              <option value={DebugProperty.PortfolioCountryCode.toString()}>
                {DebugProperty.PortfolioCountryCode.toString()}
              </option>
              <option value={DebugProperty.Institutes.toString()}>
                {DebugProperty.Institutes.toString()}
              </option>
              <option value={DebugProperty.SupportContact.toString()}>
                {DebugProperty.SupportContact.toString()}
              </option>
              <option value={DebugProperty.AppContext.toString()}>
                {DebugProperty.AppContext.toString()}
              </option>
            </ClinSelect>
            <ClinSpacer />
            <pre>
              {showDebugItem &&
                `${JSON.stringify(getDebugValueFor(showDebugItem), null, 2)}`}
            </pre>
          </>
        </StyledDebugWrapper>
      ) : (
        <StyledDebugClose>
          <ClinButton variant="linkButton" onClick={() => setShowPanel(true)}>
            <span
              role="img"
              aria-label="tools"
              style={{ fontSize: ClinTheme.fontSizes[3] }}
            >
              ⚙
            </span>
          </ClinButton>
        </StyledDebugClose>
      )}
    </>
  )
}
