import React, { FunctionComponent, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { useAppContext } from '../../../context/app'
import { getShortages } from '../../../services/ApiService'
import { CatalogItemDto, ShortageDto } from '../../../types/swaggerTypes'
import { Shortages, UkShortagesSortBy } from './Shortages'
import { isAusUser } from '../../../constants'

interface IUkShortagesProps {
  /** Example UkShortages prop please replace or remove! */
  exampleUkShortagesProp?: boolean
}

export const ShortagesContainer: FunctionComponent<IUkShortagesProps> = () => {
  const history = useHistory()
  const { portfolioCountryCode, userRole } = useAppContext()
  const [shortages, setShortages] = useState<CatalogItemDto[] | undefined>(
    undefined
  )
  const [sortColumn, setSortColumn] = useState<UkShortagesSortBy>(
    'genericConcatenationNormalized'
  )
  const [sortDirection, setSortDirection] = useState<SortDirectionType>(
    SortDirectionType.Ascending
  )

  const getDateShortage = (shortage: CatalogItemDto) => {
    // Sorting according to the start date
    const DateShortage =
      shortage.shortages &&
      shortage.shortages.find(
        (x: ShortageDto) => x.country === portfolioCountryCode
      )
    return DateShortage ? DateShortage.startDate : 0
  }

  const getRegulatoryPathwayShortage = (shortage: CatalogItemDto) => {
    // Sorting according to the regulatory pathway
    const regulatoryPathway =
      shortage.shortages &&
      shortage.shortages.find(
        (x: ShortageDto) => x.country === portfolioCountryCode
      )
    return regulatoryPathway ? regulatoryPathway.regulatoryPathway : null
  }

  useEffect(() => {
    if (sortColumn === 'shortage/startDate') {
      sortDirection === SortDirectionType.Ascending
        ? setShortages(
            shortages &&
              shortages
                .slice()
                .sort(
                  (a: CatalogItemDto, b: CatalogItemDto) =>
                    new Date(getDateShortage(a)).getTime() -
                    new Date(getDateShortage(b)).getTime()
                )
          )
        : setShortages(
            shortages &&
              shortages
                .slice()
                .sort(
                  (a: CatalogItemDto, b: CatalogItemDto) =>
                    new Date(getDateShortage(b)).getTime() -
                    new Date(getDateShortage(a)).getTime()
                )
          )
    } else if (sortColumn === 'shortage/regulatoryPathway') {
      sortDirection === SortDirectionType.Ascending
        ? setShortages(
            shortages &&
              shortages.slice().sort((a: CatalogItemDto, b: CatalogItemDto) => {
                const valueA = getRegulatoryPathwayShortage(a)
                const valueB = getRegulatoryPathwayShortage(b)
                if (!valueA && !valueB) {
                  return 0
                } else if (!valueA) {
                  return 1
                } else if (!valueB) {
                  return -1
                }
                return valueA?.localeCompare(valueB!)
              })
          )
        : setShortages(
            shortages &&
              shortages.slice().sort((a: CatalogItemDto, b: CatalogItemDto) => {
                const valueA = getRegulatoryPathwayShortage(a)
                const valueB = getRegulatoryPathwayShortage(b)
                if (!valueA && !valueB) {
                  return 0
                } else if (!valueA) {
                  return 1
                } else if (!valueB) {
                  return -1
                }
                return valueB?.localeCompare(valueA!)
              })
          )
    } else {
      sortDirection === SortDirectionType.Ascending
        ? setShortages(
            shortages &&
              shortages
                .slice()
                .sort((a: any, b: any) =>
                  a[sortColumn].localeCompare(b[sortColumn])
                )
          )
        : setShortages(
            shortages &&
              shortages
                .slice()
                .sort((a: any, b: any) =>
                  b[sortColumn].localeCompare(a[sortColumn])
                )
          )
    }
  }, [sortColumn, sortDirection])

  useEffect(() => {
    setShortages(undefined)
    getShortages({
      pagination: {
        skip: 1,
        // usually there'll be less than 100, so just show all instead of having pagination
        take: 500
      },
      sorting: {
        sortBy: sortColumn,
        order: sortDirection
      },
      filter: {
        tags: [],
        countryCode: portfolioCountryCode
      }
    }).then((response) => {
      setShortages(response.data.result)
      if (isAusUser(portfolioCountryCode)) {
        setSortColumn('shortage/regulatoryPathway')
      }
    })
  }, [])

  return (
    <Shortages
      countryCode={portfolioCountryCode}
      shortages={shortages}
      sortBy={sortColumn}
      sortDirection={sortDirection}
      userCountry={portfolioCountryCode}
      userRole={userRole}
      onClickShortage={(shortage) =>
        history.push(`/product/sku/${shortage.sku}`)
      }
      onClickOrderToggle={(sortBy) => {
        if (sortBy === sortColumn) {
          setSortDirection(
            sortDirection === SortDirectionType.Ascending
              ? SortDirectionType.Descending
              : SortDirectionType.Ascending
          )
        } else {
          setSortColumn(sortBy)
          setSortDirection(SortDirectionType.Ascending)
        }
      }}
    />
  )
}
