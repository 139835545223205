import { t } from 'i18next'
import React, { FunctionComponent } from 'react'
import { RouteComponentProps } from 'react-router'
import { ConfirmationScreenContainer } from '../../../../components/AusConfirmationScreen/ConfirmationScreenContainer'
import { CustomizedState } from '../../../SourcingEnquiry/SourcingEnquiry.models'

export type TSourcingEnquiryParams = {
  generic: string
  brand: string
}

interface ISourcingEnquiryProps extends RouteComponentProps {}

export const ReportShortageSubmitContainer: FunctionComponent<
  ISourcingEnquiryProps
> = ({ location }) => {
  const state = location.state as CustomizedState

  return (
    <ConfirmationScreenContainer
      confirmationProductDetail={state.confirmationProductDetail}
      title={t('report_shortage:shortage_submit_details.title')}
      detailsDescription={t(
        'report_shortage:shortage_submit_details.detail_description'
      )}
    />
  )
}
