import React, { FunctionComponent } from 'react'

import { ClinTheme } from '../../../ClinTheme'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinSpinner } from '../../../components/ClinSpinner'

import {
  getInputPlaceholder,
  PlaceholderType
} from '../../../utils/Forms/getInputPlaceholder'
import { IProgramAutoSuggestResultProps } from './ProgramAutoSuggest.mode'
import {
  StyledProgramResults,
  StyledProgramSearchInput,
  StyledProgramSearchLoaderWrapper,
  StyledProgramSearchWrapper
} from './ProgramAustoSuggest.styles'

export const ProgramSearch: FunctionComponent<
  IProgramAutoSuggestResultProps
> = ({
  query = '',
  suggestions = [],
  isLoading,
  showSuggestions,
  handleChange,
  handleKeyDown,
  handleEnter,
  renderSuggestions
}) => {
  return (
    <StyledProgramSearchWrapper
      className={'clin-program-search-input-wrapper'}
      onKeyDown={handleKeyDown}
    >
      <StyledProgramSearchInput
        className={'clin-program-search-input'}
        autoFocus={false}
        tabIndex={0}
        showSuggestions={showSuggestions}
        placeholder={getInputPlaceholder(PlaceholderType.programSearch)}
        value={query}
        onChange={handleChange}
        onKeyDown={(event) => {
          handleEnter && event.keyCode === 13 && handleEnter(event)
        }}
      />
      {isLoading && (
        <StyledProgramSearchLoaderWrapper>
          <ClinSpinner size={ClinTheme.space[3]} />
        </StyledProgramSearchLoaderWrapper>
      )}
      <ClinIcon
        style={{
          position: 'absolute',
          right: ClinTheme.space[3],
          top: '50%',
          transform: 'translateY(-50%)'
        }}
        iconSize={16}
        iconHeight={16}
        iconName={ClinIconPathName.Search}
      />
      <StyledProgramResults showSuggestions={showSuggestions}>
        {suggestions && renderSuggestions && renderSuggestions(suggestions)}
      </StyledProgramResults>
    </StyledProgramSearchWrapper>
  )
}
