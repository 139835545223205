import styled from 'styled-components'

export const StyledSpinnerContainer = styled.div({
  display: 'flex',
  height: '50vh',
  alignItems: 'center',
  justifyContent: 'center'
})

export const StyledSmallSpinnerContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})
