import React, { ChangeEvent, FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ICustomSelectOption } from '../../features/CustomSelect/CustomSelect'
import 'react-tippy/dist/tippy.css'
import { IPreferences } from './PreferencesContainer'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { object, string, boolean } from 'yup'
import { StyledLoadingContainer } from '../InstitutePages/InviteNewUser/InviteNewUser.styles'
import { ClinSelect } from '../../components/ClinSelect'
import {
  getInputPlaceholder,
  PlaceholderType
} from '../../utils/Forms/getInputPlaceholder'
import { useTranslation } from 'react-i18next'
import { UserRole, isAusGaUser } from '../../constants'
import { useCheckUnableHomePage } from '../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'
interface IPreferencesProps {
  /** Users preference values */
  preferences: IPreferences
  /** Language options */
  languageOptions: ICustomSelectOption[]
  /** User country */
  userCountry: string
  /** User role */
  userRole?: UserRole
  /** When language is changed */
  handleSwitchLanguage: (option: ICustomSelectOption) => void
  /** When change button clicked */
  handleFormSubmit: (data: IPreferenceFormValues) => void
  /** Handle a request to access marketing subscription preferences  */
  handleMarketingSubscriptionRequest: () => void
  /** Cancel form and return to previous page */
  handleCancel: () => void
}

export interface IPreferenceFormValues {
  language?: string
}
export const Preferences: FunctionComponent<IPreferencesProps> = ({
  preferences,
  languageOptions,
  userCountry,
  userRole,
  handleFormSubmit,
  handleMarketingSubscriptionRequest,
  handleCancel
}) => {
  const { t } = useTranslation()
  const { enableNewHomePage } = useCheckUnableHomePage()

  const defaultValue = {
    language: preferences?.language && preferences.language.id
  }
  const PreferencesSchema = object().shape({
    language: string(),
    marketingPreference: boolean()
  })
  const {
    control,
    handleSubmit,
    formState: { isDirty }
  } = useForm<IPreferenceFormValues>({
    mode: 'all',
    defaultValues: defaultValue,
    resolver: yupResolver(PreferencesSchema)
  })

  return (
    <ClinPageContentFrame
      crumbs={[
        isAusGaUser(userCountry, userRole) && enableNewHomePage
          ? {
              path: '/home',
              name: t('navigation:home')
            }
          : { path: '/orders', name: t('orders:page_name') },
        {
          name: t('preferences:title'),
          path: ''
        }
      ]}
    >
      <form
        onSubmit={handleSubmit((values) => {
          handleFormSubmit(values)
        })}
      >
        <Row>
          <Col sm={12}>
            <ClinText
              as="h1"
              variant={TypographyVariant.H2}
              fontWeight={ClinTheme.fontWeights.bold}
            >
              {t('preferences:title')}
            </ClinText>
          </Col>
        </Row>
        <Row>
          <Col sm={8} md={5} lg={4}>
            <ClinText
              marginBottom={ClinTheme.space[5]}
              as="h2"
              variant={TypographyVariant.H4}
            >
              {t('preferences:language')}
            </ClinText>
            <Controller
              name={'language'}
              control={control}
              render={({ field: { onChange } }) => (
                <ClinSelect
                  width="100%"
                  defaultValue={preferences && preferences.language?.id}
                  onChange={(changeValue: ChangeEvent<HTMLSelectElement>) =>
                    onChange(changeValue.currentTarget.value)
                  }
                >
                  <option value="" disabled={true}>
                    {getInputPlaceholder(PlaceholderType.SelectInput)}
                  </option>
                  {languageOptions.map(
                    (option: ICustomSelectOption, index: number) => {
                      return (
                        <option key={`code-${index}`} value={option.id}>
                          {option.value}
                        </option>
                      )
                    }
                  )}
                </ClinSelect>
              )}
            />
          </Col>
        </Row>

        <ClinSpacer height={ClinTheme.space[7]} />
        <ClinGroup>
          <ClinButton onClick={() => handleCancel()}>
            {t('common:buttons.cancel')}
          </ClinButton>
          <StyledLoadingContainer>
            <ClinButton type="submit" variant="primary" disabled={!isDirty}>
              {t('common:buttons.save')}
            </ClinButton>
          </StyledLoadingContainer>
        </ClinGroup>
        <ClinSpacer />
        <ClinSpacer height={ClinTheme.space[5]} />
        <Row>
          <Col sm={12}>
            <ClinText as="h2" variant={TypographyVariant.H4}>
              {t('preferences:marketing')}
            </ClinText>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <ClinButton
              variant="linkWithIcon"
              onClick={() => handleMarketingSubscriptionRequest()}
            >
              {t('preferences:manage_marketing_subscriptions_button')}
              <ClinIcon
                style={{ marginLeft: ClinTheme.space[2] }}
                iconSize={ClinTheme.fontSizes[3]}
                iconName={ClinIconPathName.ArrowRight}
              />
            </ClinButton>
          </Col>
        </Row>
        <ClinSpacer />
        {/* Commenting this out because it will be useful when further preferences become available */}
        {/* <Row>
          <Col sm={12} md={10} lg={8}>
            <ClinTable
              tableHeader={() => {
                return (
                  <>
                    <ClinTableHeaderCell>
                      <ClinText
                        fontSize={ClinTheme.fontSizes[2]}
                        fontWeight={ClinTheme.fontWeights.medium}
                        color={ClinTheme.colors.primary}
                      >
                        {t('preferences:marketing')}
                      </ClinText>
                    </ClinTableHeaderCell>
                    <ClinTableHeaderCell>
                      <div
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center'
                        }}
                      >
                        {t('preferences:email_me')}
                        <div
                          style={{
                            position: 'relative',
                            marginLeft: '5px',
                            marginTop: '3px'
                          }}
                        >
                          <Tooltip
                            title="sample message"
                            position="top"
                            trigger="click"
                            arrow={true}
                            popperOptions={{
                              modifiers: {
                                preventOverflow: {
                                  boundariesElement: 'viewport'
                                }
                              }
                            }}
                          >
                            <ClinIcon
                              iconSize={ClinTheme.fontSizes[3]}
                              iconName={ClinIconPathName.InfoOutline}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </ClinTableHeaderCell>
                  </>
                )
              }}
            >
              <StyledClinTableRow>
                <ClinTableBodyCell verticalAlign={'top'}>
                  {t('preferences:relavent_marketing_information')}
                </ClinTableBodyCell>
                <ClinTableBodyCell verticalAlign={'top'}>
                  <ClinSwitch
                    inputRef={register({})}
                    name={`marketingPreference`}
                    onChange={(event) => {
                      setValue(
                        'marketingPreference',
                        event.currentTarget.checked,
                        {
                          shouldValidate: true
                        }
                      )
                    }}
                    id="marketing-preference"
                    label={t('preferences:relavent_marketing_information')}
                    defaultChecked={preferences?.marketingPreference}
                  />
                </ClinTableBodyCell>
              </StyledClinTableRow>
            </ClinTable>
          </Col>
        </Row> */}
      </form>
    </ClinPageContentFrame>
  )
}
