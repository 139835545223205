import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { Link } from 'react-router-dom'
import { ClinTheme } from '../../ClinTheme'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../components/ClinAccordion'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import {
  drugPropertyTitleAndIconRecord,
  DrugProperty
} from '../../services/DrugPropertyProvider'
import { OrderLineDto } from '../../types/swaggerTypes'
import {
  StyledCheckoutOrderItem,
  StyledCheckoutOrderItemDetails,
  StyledCheckoutSidebar,
  StyledCheckoutValue,
  StyledCheckoutValues,
  StyledCheckoutProperty,
  StyledCheckoutPropertyDetail,
  StyledCheckoutOrderItemProperties
} from './CheckoutOrderItem.styles'
import { numberToCurrencyString } from '../../utils/numberToCurrencyString'
import { getBrowserLocale } from '../../utils/getBrowserLocale'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

const getDrugPropertyItem = (
  drugProperty: DrugProperty,
  children: React.ReactNode,
  transFn: TFunction
) => {
  const { iconName, translationKey } =
    drugPropertyTitleAndIconRecord[drugProperty]
  return (
    <StyledCheckoutProperty>
      <ClinIcon
        iconSize={ClinTheme.fontSizes[5]}
        iconFill={ClinTheme.colors.primary}
        iconName={iconName}
      />
      <StyledCheckoutPropertyDetail>
        <ClinText
          fontWeight={ClinTheme.fontWeights.medium}
          color={ClinTheme.colors.primary}
        >
          {transFn(translationKey)}
        </ClinText>

        <ClinText color={ClinTheme.colors.primary}>{children}</ClinText>
      </StyledCheckoutPropertyDetail>
    </StyledCheckoutProperty>
  )
}

interface ICheckoutOrderItemProps {
  orderLine: OrderLineDto
  currencyCode: string
  shippingMethod?: string
}

export const CheckoutOrderItem: FunctionComponent<ICheckoutOrderItemProps> = ({
  orderLine,
  currencyCode = 'GBP',
  shippingMethod = 'n/a'
}) => {
  const { t } = useTranslation()

  return (
    <StyledCheckoutOrderItem>
      <StyledCheckoutOrderItemDetails>
        <Row style={{ marginBottom: 'auto' }}>
          <Col xs={12} md={8} lg={6}>
            <ClinText
              fontWeight={ClinTheme.fontWeights.medium}
              fontSize={ClinTheme.fontSizes[1]}
              color={ClinTheme.colors.primary}
            >
              {t('checkout:generic_name_and_stength')}:
            </ClinText>
            <Link to={`/product/sku/${orderLine.skuCatalogItem?.sku}`}>
              <ClinText
                as="h5"
                fontWeight={ClinTheme.fontWeights.bold}
                variant={TypographyVariant.LargeParagraph}
                color={ClinTheme.colors.primary}
              >
                <u>
                  {orderLine.skuCatalogItem?.item.genericDosageConcatenation}
                </u>
              </ClinText>
            </Link>

            <ClinSpacer />
          </Col>
          <Col xs={12} md={3} lg={4} offset={{ xs: 0, lg: 1 }}>
            <ClinText
              marginBottom={ClinTheme.space[2]}
              fontWeight={ClinTheme.fontWeights.medium}
              fontSize={ClinTheme.fontSizes[1]}
              color={ClinTheme.colors.primary}
            >
              {t('glossary:product_code')}
            </ClinText>
            <ClinText>{orderLine.skuCatalogItem?.sku}</ClinText>
          </Col>
        </Row>
        <ClinSpacer height={ClinTheme.space[2]} />
        <ClinAccordion>
          <ClinAccordionItem title={t('checkout:product_details')}>
            <StyledCheckoutOrderItemProperties>
              <Row>
                <Col sm={12} md={6}>
                  {getDrugPropertyItem(
                    DrugProperty.DosageAndPacksize,
                    <ClinText
                      as="span"
                      variant={TypographyVariant.LargeParagraph}
                    >
                      {
                        orderLine.skuCatalogItem?.item
                          .genericDosageConcatenation
                      }
                      &nbsp;
                      {orderLine.skuCatalogItem?.item.packSize}
                    </ClinText>,
                    t
                  )}
                </Col>
                <Col sm={12} md={6}>
                  {getDrugPropertyItem(
                    DrugProperty.CountryOfLicense,
                    <ClinText
                      as="span"
                      variant={TypographyVariant.LargeParagraph}
                    >
                      {orderLine.skuCatalogItem?.licenses
                        .map((license) => license.country)
                        .join(', ')}
                    </ClinText>,
                    t
                  )}
                </Col>
                <Col sm={12} md={6}>
                  {getDrugPropertyItem(
                    DrugProperty.Brand,
                    <ClinText
                      as="span"
                      variant={TypographyVariant.LargeParagraph}
                    >
                      {orderLine.skuCatalogItem?.item.brand}
                    </ClinText>,
                    t
                  )}
                </Col>
                <Col sm={12} md={6}>
                  {getDrugPropertyItem(
                    DrugProperty.Overlabelled,
                    <ClinText
                      as="span"
                      variant={TypographyVariant.LargeParagraph}
                    >
                      {orderLine &&
                      orderLine.skuCatalogItem?.item.overlabelled === 'Y'
                        ? `${orderLine.skuCatalogItem?.item.overlabelLanguage} (${orderLine.skuCatalogItem?.item.overlabelType})`
                        : t('glossary:no')}
                    </ClinText>,
                    t
                  )}
                </Col>
                <Col sm={12} md={6}>
                  {getDrugPropertyItem(
                    DrugProperty.UnitPrice,
                    <ClinText
                      as="span"
                      variant={TypographyVariant.LargeParagraph}
                    >
                      {numberToCurrencyString(
                        orderLine.unitSellingPrice,
                        getBrowserLocale(),
                        {
                          style: 'currency',
                          currency: currencyCode,
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }
                      )}
                    </ClinText>,
                    t
                  )}
                </Col>
                <Col sm={12} md={6}>
                  {getDrugPropertyItem(
                    DrugProperty.Delivery,
                    <ClinText
                      as="span"
                      variant={TypographyVariant.LargeParagraph}
                    >
                      {shippingMethod}
                    </ClinText>,
                    t
                  )}
                </Col>
              </Row>
            </StyledCheckoutOrderItemProperties>
            <ClinSpacer hasBorder={true} />
            <ClinSpacer />
          </ClinAccordionItem>
        </ClinAccordion>
      </StyledCheckoutOrderItemDetails>
      <StyledCheckoutSidebar>
        <StyledCheckoutValues>
          <StyledCheckoutValue>
            <Row justify="between">
              <Col>
                <ClinText color={ClinTheme.colors.primary}>
                  {t('checkout:quantity')}
                </ClinText>
              </Col>
              <Col width="auto">
                <ClinText color={ClinTheme.colors.primary}>
                  {orderLine.quantities.ordered}
                </ClinText>
              </Col>
            </Row>
          </StyledCheckoutValue>
          <StyledCheckoutValue>
            <Row justify="between" align="end">
              <Col>
                <ClinText color={ClinTheme.colors.primary}>
                  {t('checkout:subtotal')}
                </ClinText>
              </Col>
              <Col width="auto">
                <ClinText
                  color={ClinTheme.colors.primary}
                  fontSize={ClinTheme.fontSizes[4]}
                >
                  {numberToCurrencyString(
                    orderLine.totals.subTotal,
                    getBrowserLocale(),
                    {
                      style: 'currency',
                      currency: currencyCode,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }
                  )}
                </ClinText>
              </Col>
            </Row>
          </StyledCheckoutValue>
        </StyledCheckoutValues>
      </StyledCheckoutSidebar>
    </StyledCheckoutOrderItem>
  )
}
