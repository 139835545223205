import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'

export const StyledNavIconsMobileContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-around',
  padding: '0 16px',
  alignItems: 'center',
  background: 'white',
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  height: '80px',
  zIndex: '1',
  '.clin-icon': {
    height: '32px',
    width: '32px'
  },
  '.clin-icon path:nth-of-type(1)': {
    fill: ClinTheme.colors.primaryLight
  },
  '.basket-items': {
    color: ClinTheme.colors.primaryLight,
    fontSize: '16px'
  },
  '.clin-dropdown-container': {
    top: 'unset',
    bottom: '60px',
    zIndex: '1000',
    background: ClinTheme.colors.white
  },
  '.block-institute-picker path:nth-of-type(1)': {
    stroke: ClinTheme.colors.primaryLight
  }
})
