import React, { FunctionComponent, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { matchPath, Redirect, Route, useLocation } from 'react-router'
import { ClinTheme } from '../../ClinTheme'
import { useAppContext } from '../../context/app'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { NotFound } from '../../pages/NotFound'
import { isProgramClosed } from '../../pages/Patients/PatientDetail/PatientDetail.model'
import { getPatientDetail } from '../../services/ApiService'
import { PatientProgramDto } from '../../types/swaggerTypes'
import { AnnounceMode } from '../ClinAnnounceBar/ClinAnnounceBar'
import { ClinSpinner } from '../ClinSpinner'
import { StyledSpinnerContainer } from '../ClinSpinner/ClinSpinner.styles'

interface IPatientRouteParams {
  physicianId: string
  patientId: string
}

interface IPrivatePatientRouteProps {
  component: FunctionComponent<any>
  exact: boolean
  path: string
}

export const PrivatePatientRoute: FunctionComponent<
  IPrivatePatientRouteProps
> = ({ component, path, exact }) => {
  const [program, setProgram] = useState<PatientProgramDto>()
  const { dispatch } = useAppContext()
  const location = useLocation()
  const match = matchPath<IPatientRouteParams>(location.pathname, {
    path: '/programs/my-physicians/:physicianId/:patientId',
    exact: false,
    strict: false
  })
  const physicianId = match?.params.physicianId
  const patientId = match?.params.patientId
  const accessProgramsPath = '/programs/access-programs'
  const patientDetailsPath = `/programs/my-physicians/${physicianId}/${patientId}/`

  useEffect(() => {
    patientId &&
      physicianId &&
      getPatientDetail(patientId, physicianId)
        .then((response) => {
          setProgram(response.data.program)
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `An error occurred while retrieving this patients details. ${error}`
            )
          )
        })
  }, [dispatch, patientId, physicianId])

  if (program === undefined) {
    return (
      <Container>
        <Row justify="center">
          <Col width="auto">
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <>
      {!isProgramClosed(program) ? (
        <Route path={path} exact={exact} component={component} />
      ) : (
        <>
          {location.pathname === patientDetailsPath ? (
            <Redirect to={patientDetailsPath} exact={true} />
          ) : (
            <Route
              path={accessProgramsPath}
              exact={true}
              component={NotFound}
            />
          )}
        </>
      )}
    </>
  )
}
