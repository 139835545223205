import React, { FunctionComponent } from 'react'

import { StyledUnenrolledProgramsInfoWrapper } from './UnenrolledProgramsInfo.styles'
import { ClinText } from '../../../../components/ClinText'
import { ClinButton } from '../../../../components/ClinButton'
import { getProgramInfoVariantConfig } from './UnenrolledProgramsInfo.config'
import { ClinTheme } from '../../../../ClinTheme'
import { SideBarMode } from '../ProgramDetail.models'
import { useHistory } from 'react-router'
import { t } from 'i18next'

interface IUnenrolledProgramsInfoProps {
  programInfoVariant: SideBarMode | undefined
  programId?: number
}

export const UnenrolledProgramsInfo: FunctionComponent<
  IUnenrolledProgramsInfoProps
> = ({ programInfoVariant, programId }) => {
  const history = useHistory()
  const variantConfig = getProgramInfoVariantConfig(programInfoVariant)

  const handleButtonClick = () => {
    if (programInfoVariant === SideBarMode.NotEnrolled) {
      programId &&
        history.push(`/programs/access-programs/${programId}/enrolment-form`)
    } else if (programInfoVariant === SideBarMode.NotAvailableInYourCountry) {
      programId &&
        history.push(`/programs/access-programs/${programId}/register-interest`)
    }
  }
  return (
    variantConfig && (
      <StyledUnenrolledProgramsInfoWrapper
        paddintTop={variantConfig.paddingTop}
        paddingBottom={variantConfig.paddingBottom}
      >
        <ClinText
          fontSize={ClinTheme.fontSizes[2]}
          width={programInfoVariant === SideBarMode.NotEnrolled ? '60%' : '50%'}
          textAlign="center"
        >
          {t(variantConfig.description)}
        </ClinText>
        {variantConfig.showButton && variantConfig.buttonText && (
          <ClinButton
            variant="purpleSecondaryNarrow"
            style={{ width: variantConfig.buttonWidth, marginTop: '16px' }}
            onClick={() => handleButtonClick()}
          >
            {t(variantConfig.buttonText)}
          </ClinButton>
        )}
        {variantConfig.phoneLink && (
          <ClinText
            marginTop="12px"
            as="span"
            color={ClinTheme.colors.tealishBlue}
            lineHeight={ClinTheme.lineHeights.heading[0]}
          >
            {variantConfig.phoneLink}
          </ClinText>
        )}
        {variantConfig.emailLink && (
          <a
            className="raw-email"
            href={`mailto:${variantConfig.emailLink}`}
            style={{ overflowWrap: 'break-word' }}
          >
            <ClinText
              className="email"
              as="span"
              color={ClinTheme.colors.tealishBlue}
              lineHeight={ClinTheme.lineHeights.heading[0]}
            >
              {variantConfig.emailLink}
            </ClinText>
          </a>
        )}
      </StyledUnenrolledProgramsInfoWrapper>
    )
  )
}
