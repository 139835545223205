import React, { FunctionComponent, ReactElement } from 'react'
import {
  StyledClinTabHeader,
  IClinTabHeaderStyles
} from './ClinTabHeader.styles'

interface IClinTabHeaderProps extends IClinTabHeaderStyles {
  /** Tab title */
  title?: string
  /** Expose click */
  handleClick?: () => void
}
export type ClinTabHeaderElement = ReactElement<IClinTabHeaderProps>
export const ClinTabHeader: FunctionComponent<IClinTabHeaderProps> = ({
  isActive,
  isDisabled,
  title = 'Tab Title',
  handleClick
}) => (
  <StyledClinTabHeader
    className={'clin-tab-header'}
    isDisabled={isDisabled}
    isActive={isActive}
    onClick={() => handleClick?.()}
    role="tab"
    aria-selected={isActive ? 'true' : 'false'}
  >
    {title}
  </StyledClinTabHeader>
)
