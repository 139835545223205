import styled from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'
import {
  StyledOrderDetailItem,
  StyledOrderDetailItemDivider
} from './OrderDetailItem.styles'
import { mediaQuery, mediaQueryDown } from '../../../utils/mediaQuery'
import { rgba } from 'polished'

const MediumUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledOrderDetail = styled.div({
  [`${StyledOrderDetailItem}:last-of-type`]: {
    [`${StyledOrderDetailItemDivider}`]: {
      display: 'none'
    }
  }
})

export const StyledCarrierImage = styled.img({
  width: '100%;',
  height: ClinTheme.space[5],
  objectFit: 'contain',
  objectPosition: 'left'
})

export const StyledOrderDetailOrder = styled.div({})

export const StyledOrderHeading = styled.div({
  display: 'inline-flex',
  alignItems: 'center'
})

export const StyledOrderIcon = styled.div({
  borderRadius: '50%',
  width: ClinTheme.space[5],
  height: ClinTheme.space[5],
  display: 'inline-flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  backgroundColor: ClinTheme.colors.secondary
})

export const StyledOrderBar = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: ClinTheme.space[4],
  width: '100%',
  fontSize: ClinTheme.fontSizes[1],
  textTransform: 'uppercase',
  background: ClinTheme.colors.primary,
  color: ClinTheme.colors.white,
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.24)',
  zIndex: 1,
  boxSizing: 'border-box'
})

export const StyledOrderDetailHeader = styled.div({
  display: 'none',
  borderBottom: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.midGrey}`,
  margin: `${ClinTheme.space[4]}px ${ClinTheme.space[5]}px ${ClinTheme.space[3]}px ${ClinTheme.space[5]}px`,

  [mediaQuery(MediumUpBreakpoint)]: {
    display: 'block'
  }
})

export const StyledIconHeading = styled.div({
  display: 'inline-flex',
  alignItems: 'center'
})

export const StyledOrderDetailShipping = styled.div({
  padding: `${ClinTheme.space[4]}px ${ClinTheme.space[5]}px`,
  background: rgba(ClinTheme.colors.primaryLightest, 0.15),
  borderBottom: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`
})

export const ClinAddAllItems = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',

  [mediaQuery(MediumUpBreakpoint)]: {
    justifyContent: 'flex-end'
  },

  [mediaQueryDown(MediumUpBreakpoint)]: {
    '.clin-button ': {
      width: '100%'
    }
  }
})
