import { AxiosError } from 'axios'
import React, {
  FunctionComponent,
  useEffect,
  useCallback,
  useState
} from 'react'
import { RouteComponentProps } from 'react-router'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { useAppContext } from '../../../context/app'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import {
  getPatientDetail,
  getEnrolledPhysicians,
  cancelGetEnrolledPhysicians,
  transferPatientPhysician
} from '../../../services/ApiService'
import {
  PatientDetailsDto,
  PatientProgramDto,
  PhysiciansEnrolledSummaryDto
} from '../../../types/swaggerTypes'
import { useErrorMessage } from '../../../utils/useErrorMessage'
import { TransferPatient } from './TransferPatient'
import { StepState } from '../../../components/ClinStepper/ClinStepper'
import { ICustomSelectOption } from '../../../features/CustomSelect/CustomSelect'
import {
  PatientTransferSteps,
  patientSteps
} from '../models/TransferPatientContainer.model'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'

interface ITransferPatientRouteParams {
  patientId: string
  physicianId: string
}

interface ITransferPatientProps
  extends RouteComponentProps<ITransferPatientRouteParams> {}

export const TransferPatientContainer: FunctionComponent<
  ITransferPatientProps
> = ({ match, history }) => {
  const { dispatch, institute } = useAppContext()
  const { patientId, physicianId } = match.params
  const [patient, setPatient] = useState<PatientDetailsDto>()
  const [program, setProgram] = useState<PatientProgramDto>()
  const [physicians, setPhysicians] = useState<PhysiciansEnrolledSummaryDto[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [selectedPhysician, setSelectedPhysician] = useState<
    PhysiciansEnrolledSummaryDto | undefined
  >()
  const [currentStep, setCurrentStep] = useState<number>(
    PatientTransferSteps.STEP1
  )
  const [isAgreementConfirmed, setIsAgreementConfirmed] =
    useState<boolean>(false)

  useEffect(() => {
    patientId &&
      physicianId &&
      getPatientDetail(patientId, physicianId)
        .then((response) => {
          setPatient(response.data)
          setProgram(response.data.program)
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `An error occurred while retrieving this patients details . ${error}`
            )
          )
          window.scrollTo(0, 0)
        })
        .finally(() => setIsLoading(false))
  }, [dispatch, patientId, physicianId])

  const getPhysician = useCallback(() => {
    program?.programId &&
      getEnrolledPhysicians(`${program?.programId}`)
        .then((response) => {
          const physicians = response.data.physicians
          const currentPhysicianId = patient?.currentPhysician.physicianId
          const filteredPhysicians = physicians.filter(
            (physician) => physician.physicianId !== currentPhysicianId
          )
          response && setPhysicians(filteredPhysicians)
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching the enrolled physicians. ${error}`
            )
          )
        })
    return () => {
      cancelGetEnrolledPhysicians()
    }
  }, [dispatch, patient, program])

  const handlePhysicianSelect = (option: ICustomSelectOption): void => {
    const foundPhysician: PhysiciansEnrolledSummaryDto | undefined =
      physicians && physicians.find((item) => item.physicianId === option.id)
    setSelectedPhysician(
      foundPhysician &&
        physicians &&
        foundPhysician.physicianId !== selectedPhysician?.physicianId
        ? physicians.find((item) => item.physicianId === option.id)
        : undefined
    )
  }
  const handleCancel = () => {
    history.push(`/programs/my-physicians/${physicianId}/${patientId}`)
  }

  const handleBack = (step: number) => {
    setCurrentStep(PatientTransferSteps.STEP1)
  }

  const getSteppers = (): StepState[] => {
    return patientSteps[currentStep]
  }

  const handleContinue = (step: number) => {
    setCurrentStep(step)
    getPhysician()
  }

  const handleViewPhysician = () => {
    selectedPhysician?.physicianLinkedToUser === 'N'
      ? history.push(`/programs/my-physicians`)
      : history.push(
          `/programs/my-physicians/${selectedPhysician?.physicianId}/${patientId}`
        )
  }

  const handleViewProgram = () => {
    history.push(`/programs/access-programs/${program?.programId}`)
  }

  const handleSubmitError = useErrorMessage(
    'There was an error fetching the enrolled physicians'
  )

  const handleSubmit = (step: number) => {
    selectedPhysician?.physicianId &&
      transferPatientPhysician(physicianId, patientId, selectedPhysician)
        .then((response) => {
          if (response.status >= 200 && response.status <= 299) {
            setCurrentStep(step)
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.PatientTransferPhysician,
              {
                patientId,
                physicianIdFrom: physicianId,
                physicianIdTo: selectedPhysician.physicianId,
                instituteId: institute.data?.instituteId
              }
            )
          }
        })
        .catch((error: AxiosError) => handleSubmitError(error))
  }

  return (
    <TransferPatient
      isLoading={isLoading}
      patient={patient}
      program={program}
      currentStep={currentStep}
      physicians={physicians}
      selectedPhysician={selectedPhysician}
      isAgreementConfirmed={isAgreementConfirmed}
      setIsAgreementConfirmed={setIsAgreementConfirmed}
      selectedSteppers={getSteppers()}
      handleCancel={handleCancel}
      handleBack={handleBack}
      handleContinue={handleContinue}
      handlePhysicianSelect={handlePhysicianSelect}
      handleSubmit={handleSubmit}
      handleViewPhysician={handleViewPhysician}
      handleViewProgram={handleViewProgram}
    />
  )
}
