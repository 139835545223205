import { OrderDto, PriceAndAvailabilityDto } from '../../types/swaggerTypes'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { getPriceAndAvailabilityForSku } from '../../services/ApiService'

export const usePriceAndAvailability = (
  orders: OrderDto[] | undefined,
  addressId: string | undefined
): PriceAndAvailabilityDto[] => {
  const [pricingArr, setPricingArr] = useState<PriceAndAvailabilityDto[]>([])
  useEffect(() => {
    if (orders && addressId && orders.length > 0) {
      // Work out an array of SKUs and look up all
      const skus: string[] = _(orders)
        .flatMap('lines')
        .flatMap('skuCatalogItem')
        .flatMap('sku')
        .value()
      // Make a call to get all SKUs!
      const pricingCalls: Promise<PriceAndAvailabilityDto>[] = skus.map((sku) =>
        getPriceAndAvailabilityForSku(sku, addressId).then((r) => r.data)
      )
      Promise.all(pricingCalls)
        .then((results) => {
          setPricingArr(results)
        })
        .catch((error) => {
          console.error(
            `Error returning price and availability for checkout: ${error}`
          )
        })
    }
  }, [orders, addressId])
  return pricingArr
}
