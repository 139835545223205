import styled from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'
import { ClinButton } from '../../../components/ClinButton'

export const StyledPatientDetail = styled.div({
  backgroundColor: ClinTheme.colors.lightGrey
})

export const StyledPatientHeaderWrapper = styled.section({
  backgroundColor: ClinTheme.colors.white
})

export const StyledPatientHeader = styled.div({
  maxWidth: '1200px',
  width: '100%',
  margin: '0 auto',
  padding: `${ClinTheme.space[3]}px ${ClinTheme.space[4]}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [`.clin-text`]: {
    margin: 0
  },
  [`.clin-button`]: {
    margin: 0
  }
})
interface IStyledInitialsProps {
  background?: string
  blur?: boolean
}

interface IStyledProgressBar {
  percentage: string
}
export const StyledPatientInitialsAvatar = styled.div<IStyledInitialsProps>(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    color: ClinTheme.colors.primary,
    fontSize: ClinTheme.fontSizes[0],
    textTransform: 'uppercase',
    height: 28,
    width: 28,
    minWidth: 28,
    margin: 2,
    fontWeight: ClinTheme.fontWeights.medium,
    textAlign: 'center',
    lineHeight: ClinTheme.lineHeights.largeParagraph,
    marginRight: ClinTheme.space[3]
  },
  ({ background, blur }: IStyledInitialsProps) => ({
    backgroundColor: background ? background : ClinTheme.colors.primaryLight,
    filter: blur ? 'blur(3.5px)' : ''
  })
)

export const StyledPatientColumn = styled.div<IStyledInitialsProps>({
  display: 'flex',
  alignItems: 'center'
})

export const StyledPatientDateNameWrap = styled.div<IStyledInitialsProps>({
  display: 'flex',
  flexDirection: 'column',
  '.blur': {
    filter: 'blur(3.5px)'
  }
})

export const StyledProgresBarStatusWrapper = styled.div({
  display: 'flex',
  alignItems: 'center'
})

export const StyledProgresBarWrapper = styled.div({
  border: '1px solid #ccc',
  borderRadius: `${ClinTheme.space[4]}px`,
  overflow: 'hidden',
  width: '160px',
  height: `${ClinTheme.space[2]}px`
})

export const StyledProgresBar = styled.div<IStyledProgressBar>(
  {
    height: '30px',
    backgroundColor: '#007bff', // Change color as needed
    borderRadius: `${ClinTheme.space[0]}px`
  },
  ({ percentage }: IStyledProgressBar) => ({
    width: `${percentage}%`
  })
)

export const StyledActionDropdown = styled.div({
  position: 'absolute',
  textAlign: 'right',
  borderRadius: '3px',
  paddingTop: `${ClinTheme.space[3]}px`,
  paddingBottom: `${ClinTheme.space[2]}px`,
  top: '49px',
  right: '-25px',
  background: ClinTheme.colors.white,
  zIndex: 6,
  filter: 'drop-shadow(0px 0px 8px #00000029)',
  ':before': {
    content: `""`,
    position: 'absolute',
    border: '9px solid transparent',
    borderBottomColor: ClinTheme.colors.white,
    top: '-18px',
    right: '17%'
  }
})

export const StyledPatientActionMenuWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end'
})

export const StyledPatientActionLink = styled(ClinButton)({
  display: 'block',
  width: '100%',
  paddingLeft: `${ClinTheme.space[3]}px`,
  paddingRight: `${ClinTheme.space[3]}px`,
  whiteSpace: 'nowrap',
  textDecoration: 'none!important',
  fontSize: `${ClinTheme.fontSizes[1]}px`,
  color: ClinTheme.colors.primaryDark,
  fontWeight: ClinTheme.fontWeights.normal,
  lineHeight: ClinTheme.lineHeights.heading[0],
  textTransform: 'none',
  textAlign: 'right',
  paddingBottom: `${ClinTheme.space[2]}px`,
  [`:hover`]: {
    backgroundColor: ClinTheme.colors.lightGrey,
    [StyledActionDropdown]: {
      display: 'block'
    }
  }
})

export interface IStatusWrapperProps {
  color: string
}

export interface IActionWrapperProps {
  showGlowEffect?: boolean
}

export interface IExternalLinkProps {
  showTableInTab?: boolean
}

export const StyledStatusWrapper = styled.div<IStatusWrapperProps>(
  {},
  ({ color }: IStatusWrapperProps) => ({
    display: 'block',
    background: color,
    margin: '0 auto',
    padding: '4px 10px',
    borderRadius: `${ClinTheme.space[2]}px`
  })
)

export const StyledExternalLink = styled.a<IExternalLinkProps>(
  {
    fontWeight: ClinTheme.fontWeights.medium,
    color: ClinTheme.colors.tealishBlue,
    textTransform: 'initial',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center'
  },
  ({ showTableInTab }: IExternalLinkProps) => ({
    fontSize: showTableInTab ? ClinTheme.fontSizes[0] : ClinTheme.fontSizes[1],
    lineHeight: showTableInTab ? '2' : ClinTheme.lineHeights.small
  })
)

export const StyledFeatureHighlight = styled.div({
  marginRight: '10px',
  position: 'absolute',
  right: '85px'
})

export const StyledTooltipWrapper = styled.span({
  top: '-40px',
  position: 'absolute',
  right: '-40px'
})

export const StyledTooltipSearchWrapper = styled.span({
  position: 'absolute',
  left: '88px',
  zIndex: 5
})

export const StyledTooltipCreateWrapper = styled.span({
  position: 'absolute',
  left: '-63px'
})

export const StyledTooltipFilterWrapper = styled.span({
  position: 'absolute',
  left: '-37px'
})

export const StyledTooltipActionsWrapper = styled.span({
  position: 'absolute',
  left: '-7px',
  top: '-3px'
})

export const StyledClinTableOrderToggleWrapper = styled.div`
  position: relative;
  font-size: 12px;
  color: ${ClinTheme.colors.black};
  & > div {
    font-size: 12px;
    color: ${ClinTheme.colors.black};
    align-items: center;
    display: flex;
    flex-direction: row;
  }
`
export const StyledActionWrapper = styled.div<IActionWrapperProps>(
  {
    textAlign: 'right',
    position: 'relative',
    color: ClinTheme.colors.primary
  },
  ({ showGlowEffect }: IActionWrapperProps) => ({
    zIndex: showGlowEffect ? 3 : 0
  })
)

interface IStyledIconProps {
  height?: number
  width?: number
}

export const StyledIcon = styled.img<IStyledIconProps>(
  {
    objectFit: 'contain',
    objectPosition: 'left'
  },
  ({ height, width }: IStyledIconProps) => ({
    height: height ? `${height}px` : '20px',
    width: width ? `${width}px` : '27px'
  })
)
