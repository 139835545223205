import { InstituteDto } from '../types/swaggerTypes'

export const localStorageName = 'user_institute'

const getLocallyStoredInstitute = async (): Promise<
  InstituteDto | null | undefined
> => {
  const localContentData = localStorage.getItem(localStorageName)
  let passedLocalContentData: InstituteDto | null
  let institute
  if (localContentData) {
    try {
      passedLocalContentData = localContentData && JSON.parse(localContentData)
      if (passedLocalContentData) {
        institute = passedLocalContentData
      }
    } catch (e) {
      console.warn(e)
    }
  } else {
    institute = null
  }
  return institute
}

/**
 * Returns App Context institute id OR
 * Gets it from local storage
 */
const getLocallyStoredInstituteId = async (): Promise<string | undefined> => {
  // Check if it's defined on the window object
  const instituteIdFromAppContext = window.instituteId
  if (instituteIdFromAppContext) {
    return new Promise<string>((resolve) => {
      resolve(instituteIdFromAppContext)
    })
  }
  const institute = await getLocallyStoredInstitute()
  return institute?.instituteId?.toString()
}

const clearLocallyStoredInstitute = (): void => {
  localStorage.removeItem(localStorageName)
}

export {
  getLocallyStoredInstitute,
  getLocallyStoredInstituteId,
  clearLocallyStoredInstitute
}
