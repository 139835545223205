import { AxiosError } from 'axios'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { $enum } from 'ts-enum-util'
import { AnnounceMode } from '../../../../components/ClinAnnounceBar/ClinAnnounceBar'
import {
  IPagination,
  getParamValueFor,
  useUpdateQueryParam
} from '../../../../components/ClinPagination/ClinPagination.model'
import { SortDirectionType } from '../../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import {
  OrderColumnNames,
  UserRoleRecord,
  UserRole,
  isAusGaUser
} from '../../../../constants'
import { useAppContext } from '../../../../context/app'
import { useNewFeatures } from '../../../../context/newFeatures'
import { createAnnounceEvent } from '../../../../events/AnnounceEvent'
import { useEffectOnlyOnce } from '../../../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import { AnalyticsEvent } from '../../../../services/Analytics'
import { AnalyticsPageEvent } from '../../../../services/Analytics/AnalyticsPageEvent'
import analyticsServiceSingleton from '../../../../services/Analytics/initAnalytics'
import {
  cancelGetOrders,
  getOrders,
  AuthError
} from '../../../../services/ApiService'
import { IBanner } from '../../../../types/IBanner'
import { TOrderColumnNames, IOrderSummary } from '../../../../types/IOrder'
import { IRestEndpointState } from '../../../../types/IRestEndpointState'
import { OrderSearchDto, InstituteDto } from '../../../../types/swaggerTypes'
import { OrdersOld } from './OrdersOld'
import { OrderStatus } from '../../../../types/OrderStatus'

interface IDashboardContainerProps extends RouteComponentProps {}

export interface OrderColumn {
  name: TOrderColumnNames
  viewName: string
  translationKey: string
}

export const orderColumns: OrderColumn[] = [
  {
    name: OrderColumnNames.ToggleExpand,
    viewName: 'Toggle Expand',
    translationKey: 'orders:column_titles.toggle_expand'
  },
  {
    name: OrderColumnNames.OrderNumber,
    viewName: 'Order Number',
    translationKey: 'orders:column_titles.order_number'
  },
  {
    name: OrderColumnNames.PurchaseOrderNumber,
    viewName: 'Purchase Order no.',
    translationKey: 'orders:column_titles.purchase_order_number'
  },
  {
    name: OrderColumnNames.Medications,
    viewName: 'Products',
    translationKey: 'orders:column_titles.medications'
  },
  {
    name: OrderColumnNames.DateSubmitted,
    viewName: 'Date Submitted',
    translationKey: 'orders:column_titles.date_submitted'
  },
  {
    name: OrderColumnNames.TotalCost,
    viewName: 'Total Cost',
    translationKey: 'orders:column_titles.total_cost'
  },
  {
    name: OrderColumnNames.Status,
    viewName: 'Status',
    translationKey: 'orders:column_titles.status'
  }
]

/**
 * Utility function to get a sort column back
 * @param searchParams
 * @param defaultColumn
 */
export const getSortColumnFromURLParams = (
  searchParams: URLSearchParams,
  defaultColumn: OrderColumn
): OrderColumn => {
  const sortColumn = searchParams.get('sortColumn')
  const matchSortColumn = orderColumns.find(
    (oc) => oc.name.toString() === sortColumn
  )
  return matchSortColumn ? matchSortColumn : defaultColumn
}

const defaultRowsPerPage = 5

const defaultSearchParams: OrderSearchDto = {
  query: null,
  filter: {
    start: null,
    end: null,
    orderStatuses: [
      OrderStatus.Processing,
      OrderStatus.Submitted,
      OrderStatus.OnHold
    ],
    orderNumberStart: 0,
    orderNumberEnd: 2147483647, // max value of a 32 bit integer
    purchaseNumberStart: null,
    purchaseNumberEnd: null,
    itemGenerics: [],
    patients: [],
    showMyOrders: false
  },
  pagination: {
    skip: 0,
    take: defaultRowsPerPage
  },
  sorting: {
    sortBy: 'orderedDate',
    order: SortDirectionType.Descending
  }
}
const defaultPagination: IPagination = {
  count: 0,
  skip: 0,
  take: defaultRowsPerPage,
  total: 0
}

export const OrdersContainerOld: React.FC<IDashboardContainerProps> = ({
  history,
  location
}) => {
  const { portfolioCountryCode } = useAppContext()
  const { i18n } = useTranslation()
  const { institute, userDetails, userRole, supportContact } = useAppContext()
  const isMaUser = !!(userRole && UserRoleRecord[userRole as UserRole].isMaUser)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [orders, setOrders] = React.useState<IOrderSummary[]>([])
  const [banner, setBanner] = React.useState<IBanner>()

  // Restore page state from URL params
  const urlSearchParams = new URLSearchParams(location.search)

  // Query
  const [initialQuery] = React.useState<string>(() => {
    const orderQuery = urlSearchParams.get('q')
    return !orderQuery || orderQuery === '' ? '' : orderQuery
  })
  // Restore pagination state
  // PageSize
  defaultSearchParams.pagination.take = getParamValueFor(
    'pageSize',
    urlSearchParams,
    defaultRowsPerPage
  )
  // PageIndex
  defaultPagination.take = defaultSearchParams.pagination.take
  defaultSearchParams.pagination.skip =
    defaultSearchParams.pagination.take *
    getParamValueFor('pageIndex', urlSearchParams)

  const [pagination, setPagination] =
    React.useState<IPagination>(defaultPagination)

  // TabIndex
  const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(() => {
    const tabIndex = urlSearchParams.get('tabIndex')
    let defaultTabIndex: number = 0
    if (typeof tabIndex === 'string') {
      defaultTabIndex = parseInt(tabIndex)
      defaultSearchParams.filter.orderStatuses =
        defaultTabIndex === 0
          ? [OrderStatus.Processing, OrderStatus.Submitted, OrderStatus.OnHold]
          : [OrderStatus.Shipped, OrderStatus.Cancelled]
    }
    return defaultTabIndex
  })

  // Sorting column
  const [sortColumn, setSortColumn] = React.useState<OrderColumn>(() => {
    const defaultSortColumn = orderColumns[4] // Default is date submitted
    const restoredSortByColumn = getSortColumnFromURLParams(
      urlSearchParams,
      defaultSortColumn
    )
    defaultSearchParams.sorting.sortBy = restoredSortByColumn
      ? restoredSortByColumn.name.toString()
      : defaultSortColumn.name.toString()
    return restoredSortByColumn
  })

  // Sort direction
  const [sortDirection, setSortDirection] = React.useState<SortDirectionType>(
    () => {
      const sortDirection = urlSearchParams.get('sortDirection')
      const restoredSortDirection = $enum(SortDirectionType).asValueOrDefault(
        sortDirection,
        SortDirectionType.Descending
      )
      defaultSearchParams.sorting.order = restoredSortDirection.toString()
      return restoredSortDirection
    }
  )

  const [searchParams, setSearchParams] = React.useState<OrderSearchDto>({
    ...defaultSearchParams,
    query: initialQuery
  })

  const [searchQuery, setSearchQuery] = React.useState<string>(initialQuery)

  const { dispatch } = useAppContext()

  const [{ features }] = useNewFeatures()

  const handlePageClicked = (selectedPageIndex: number) => {
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: (selectedPageIndex - 1) * searchParams.pagination.take,
        take: searchParams.pagination.take
      }
    })
  }

  const handleTabSelected = (tabIndex: number) => {
    setIsLoading(true)
    cancelGetOrders()
    setOrders([])
    setPagination(defaultPagination)
    const isSameTab = selectedTabIndex === tabIndex // reset pagination
    setSearchParams({
      ...searchParams,
      filter: {
        start: null,
        end: null,
        orderStatuses:
          tabIndex === 0
            ? [
                OrderStatus.Processing,
                OrderStatus.Submitted,
                OrderStatus.OnHold
              ]
            : [OrderStatus.Shipped, OrderStatus.Cancelled],
        orderNumberStart: 0,
        orderNumberEnd: 2147483647, // max value of a 32 bit integer
        purchaseNumberStart: null,
        purchaseNumberEnd: null,
        itemGenerics: [],
        patients: [],
        showMyOrders: searchParams.filter.showMyOrders
      },
      pagination: isSameTab
        ? searchParams.pagination
        : {
            skip: 0,
            take: defaultRowsPerPage
          }
    })
    setSelectedTabIndex(tabIndex)
  }

  const toggleSortDirection = (
    current: SortDirectionType
  ): SortDirectionType => {
    if (current === SortDirectionType.None) {
      return SortDirectionType.Descending
    }
    if (current === SortDirectionType.Descending) {
      return SortDirectionType.Ascending
    }
    return SortDirectionType.None
  }

  const handleToggleSort = (columnName: OrderColumn) => {
    setIsLoading(true)
    const newSortDirection =
      sortColumn.viewName === columnName.viewName
        ? toggleSortDirection(sortDirection)
        : SortDirectionType.Descending
    setSortDirection(newSortDirection)
    setSortColumn(columnName)
    setSearchParams({
      ...searchParams,
      sorting: {
        sortBy: columnName.name,
        order: newSortDirection
      }
    })
  }

  const handleRowClicked = (selectedOrderId: string) => {
    history.push(`/order/${selectedOrderId}`, {
      from: window.location.pathname
    })
  }

  useEffect(() => {
    if (isAusGaUser(portfolioCountryCode, userRole)) {
      window.location.href = '/products/catalogue'
    }
    institute &&
      institute.data &&
      !institute.isLoading &&
      getOrders(searchParams)
        .then((response) => {
          if (searchParams && searchParams.query !== '') {
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.SubmitSearchQuery,
              {
                query: searchParams.query,
                searchLocation: 'order table',
                searchAPI: 'search'
              }
            )
          }
          setIsLoading(false)
          if (response) {
            const ordersFromAPI: IOrderSummary[] = response.data.result
            setOrders(ordersFromAPI)
            const paginationDto = response.data.pagination
            paginationDto &&
              setPagination({
                count: paginationDto.count,
                skip: paginationDto.skip,
                take: paginationDto.take,
                total: paginationDto.total
              })
          }
        })
        .catch((error: AxiosError) => {
          const { code, message } = error

          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          // If we have a full error show it
          if (error.response) {
            const { title, detail } = error.response.data
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error fetching orders. ${title ? title : ''} ${
                  message ? message : ''
                }`
              )
            )
            console.warn(title, detail)
          } else {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error fetching orders. ${message} ${
                  code ? code : ''
                }`
              )
            )
          }

          analyticsServiceSingleton.trackError(
            error,
            JSON.parse(localStorage.getItem('current_user') || '{}')
          )
          setIsLoading(false)
        })
    return () => {
      cancelGetOrders()
    }
  }, [searchParams, dispatch, institute.data, institute.isLoading, institute])

  const handleToggleOrderRow = (orderNumber: string) => {
    setOrders(
      orders.map((order) => {
        if (order.orderNumber !== orderNumber) {
          return order
        }
        return { ...order, isOpen: !order.isOpen }
      })
    )
  }

  const handlePageSizeChange = (pageSize: number) => {
    cancelGetOrders()
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: 0,
        take: pageSize
      }
    })
  }

  const handleOnSearch = (query: string) => {
    setSearchQuery(query)
  }

  const handleOnSubmit = () => {
    cancelGetOrders()
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        ...searchParams.pagination,
        skip: 0
      },
      query: searchQuery
    })
  }

  const handleShowMyOrders = (event: React.ChangeEvent<HTMLInputElement>) => {
    cancelGetOrders()
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      filter: {
        ...searchParams.filter,
        showMyOrders: event.target.checked
      }
    })
    analyticsServiceSingleton.trackEvent(AnalyticsEvent.MyOrdersToggle, {
      selected: event.target.checked
    })
  }

  const preferredName = userDetails?.contactCard.preferredName.length
    ? userDetails.contactCard.preferredName
    : userDetails?.contactCard.name.split(' ')[0]

  // Track changes to pagination and tabs as they update and update query string if it changes
  useUpdateQueryParam({ q: searchParams.query })
  useUpdateQueryParam({ tabIndex: selectedTabIndex })
  useUpdateQueryParam({
    pageIndex: searchParams.pagination.skip / searchParams.pagination.take
  })
  useUpdateQueryParam({ pageSize: searchParams.pagination.take })
  useUpdateQueryParam({ sortColumn: sortColumn.name.toString() })
  useUpdateQueryParam({ sortDirection: sortDirection.toString() })

  type Deps = IRestEndpointState<InstituteDto>[]
  useEffectOnlyOnce(
    (dependencies: Deps) => {
      analyticsServiceSingleton.trackPageView(
        AnalyticsPageEvent.ViewDashboard,
        {
          Institution: dependencies[0].data?.instituteName
        }
      )
    },
    [institute],
    (dependencies: Deps) => dependencies[0]
  )

  const listOfNewFeatures = undefined //expired feature

  const handleOnChange = (query: string) => {
    setIsLoading(true)
    setSearchParams((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        skip: 0
      },
      query
    }))
  }

  return (
    <>
      <OrdersOld
        userPreferredName={preferredName}
        isLoading={isLoading}
        isMaUser={isMaUser}
        orders={orders}
        newFeaturesList={listOfNewFeatures}
        pagination={pagination}
        selectedSortColumn={sortColumn}
        selectedSortDirection={sortDirection}
        selectedTabIndex={selectedTabIndex}
        initialQuery={searchQuery}
        isEmptySearch={!!(searchParams.query && orders.length === 0)}
        banner={banner}
        supportContact={supportContact}
        showMyOrders={defaultSearchParams.filter.showMyOrders || false}
        handleTabSelected={handleTabSelected}
        handlePageClicked={handlePageClicked}
        handleRowClicked={handleRowClicked}
        handleToggleSort={handleToggleSort}
        handleToggleOrderRow={handleToggleOrderRow}
        handlePageSizeChange={handlePageSizeChange}
        handleOnSearch={handleOnSearch}
        handleOnSubmit={handleOnSubmit}
        handleMyOrders={handleShowMyOrders}
        handleOnChange={handleOnChange}
      />
    </>
  )
}
