import React, { FunctionComponent } from 'react'
import {
  StyledClinPaginationDots,
  IClinPaginationDotsStyles
} from './ClinPaginationDots.styles'

interface IClinPaginationDotsProps extends IClinPaginationDotsStyles {
  /** totalIndex */
  totalIndex: number
  activeIndex: number
  alt?: boolean
}

export const ClinPaginationDots: FunctionComponent<
  IClinPaginationDotsProps
> = ({ totalIndex, activeIndex, alt }) => {
  const arr: number[] = Array.from(
    { length: alt ? totalIndex - 1 : totalIndex },
    (v, i) => i
  )
  let activeWithAlt = alt ? activeIndex - 1 : activeIndex

  return (
    <StyledClinPaginationDots>
      {arr.map((index: number) => {
        return (
          <span
            key={`dot-${index}`}
            className={index === activeWithAlt ? 'active' : ''}
          ></span>
        )
      })}
    </StyledClinPaginationDots>
  )
}
