import React, { FunctionComponent } from 'react'
import { ClinTheme } from '../../ClinTheme'
import { IDefaultStyleProps } from '../../types'
import { isMobile } from '../../utils/isMobile'
import { useLayoutResize } from '../../utils/useLayoutResize'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { StyledSearch, StyledSearchInput } from './ClinSearchInput.styles'

interface IClinSearchInputProps extends IDefaultStyleProps {
  /** Provided value */
  query?: string
  /** Provided custom styles for input */
  borderRadious?: string
  maxWidth?: string
  /** Placeholder copy to display */
  placeholder?: string
  /** Placeholder copy to display for mobile devices */
  placeholderForMobile?: string
  /** Automatically sets the focus state */
  autoFocus?: boolean
  iconColor?: string
  iconStyle?: {}
  textIndent?: number
  fontSize?: number
  iconHeight?: string
  iconSize?: string
  height?: string
  marginLeft?: number
  borderColor?: string
  boxShadow?: string
  zIndex?: number
  /** Response items */
  onChange(e: React.ChangeEvent<HTMLInputElement>): void
  /** When enter key pressed */
  onEnter?(e: React.KeyboardEvent<HTMLInputElement>): void
  /** When input is clicked */
  onClick?(e: React.MouseEvent<HTMLInputElement>): void
}

export const ClinSearchInput: FunctionComponent<IClinSearchInputProps> = ({
  className = '',
  query,
  placeholder,
  placeholderForMobile,
  autoFocus = false,
  borderRadious,
  maxWidth,
  textIndent,
  iconColor,
  iconStyle,
  fontSize,
  iconHeight,
  iconSize,
  height,
  marginLeft,
  borderColor,
  boxShadow,
  zIndex,
  onChange,
  onEnter,
  onClick // Add the onClick prop
}) => {
  const getPlaceholder = (): string | undefined =>
    isMobile() ? placeholderForMobile ?? placeholder : placeholder
  const actualPlaceholder = useLayoutResize<string | undefined>(getPlaceholder)

  return (
    <StyledSearch
      className={`clin-search-input ${className}`}
      maxWidth={maxWidth}
      marginLeft={marginLeft}
      zIndex={zIndex}
    >
      <StyledSearchInput
        boxShadow={boxShadow}
        autoFocus={autoFocus}
        placeholder={actualPlaceholder}
        fontSize={fontSize}
        textIndent={textIndent}
        height={height}
        borderColor={borderColor}
        value={query}
        borderRadious={borderRadious}
        onChange={onChange}
        onClick={onClick} // Pass the onClick prop to StyledSearchInput
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          onEnter &&
            ['Enter', 'NumpadEnter'].includes(event.key) &&
            onEnter(event)
        }}
      />
      <ClinIcon
        iconHeight={iconHeight ?? undefined}
        iconSize={iconSize ?? undefined}
        style={
          iconStyle ?? {
            position: 'absolute',
            right: ClinTheme.space[3],
            top: '50%',
            transform: 'translateY(-50%)'
          }
        }
        iconName={ClinIconPathName.Search}
        iconFill={iconColor}
      />
    </StyledSearch>
  )
}
