import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { ClinTheme } from '../../../ClinTheme'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../../components/ClinAccordion'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import { ClinStepper } from '../../../components/ClinStepper'
import { StepState } from '../../../components/ClinStepper/ClinStepper'
import { ClinText } from '../../../components/ClinText'
import { ClinAnnounceBar } from '../../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { PatientDetails } from '../../../features/TransferPatient/PatientDetails/PatientDetails'
import { ProgramDetails } from '../../../features/TransferPatient/ProgramDetails/ProgramDetails'
import { PatientTransferSteps } from '../models/TransferPatientContainer.model'
import { PhysicianDetailsForm } from './PhysicianDetailsForm'
import { InstituteDetailsForm } from './InstituteDetailsForm'
import { SuccessScreen } from './SuccessScreen'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import {
  PatientDetailsDto,
  PatientProgramDto,
  NewPhysicianDetilsDto,
  NewInstituteDetilsDto,
  CountryDto
} from '../../../types/swaggerTypes'
import { StyledTransferButton } from '../styles/TransferPatient.styles'
import { HipaaCompliance } from '../../../features/HipaaCompliance'
import { useTranslation } from 'react-i18next'

interface ITransferPatientProps {
  /** Whether we are loading or not */
  isLoading: boolean
  /** Whether we are submitting or not */
  isSubmitting: boolean
  /** Selected patient */
  patient?: PatientDetailsDto
  /** Selected program */
  program?: PatientProgramDto
  /** SelectedSteppers */
  selectedSteppers: StepState[] | undefined
  /** Physician details */
  physicianDetails: NewPhysicianDetilsDto
  /** CurrentStep */
  currentStep: number
  /** User is confirmed agreement or not */
  isAgreementConfirmed: boolean
  /** An array of provided countries */
  countries?: CountryDto[]
  /** List of titles from the LOV */
  physicianTitles?: string[]
  /** Handler of agreement confirmation */
  setIsAgreementConfirmed: (isConfirmed: boolean) => void
  /** Called when user click on Cancel button */
  handleCancel: () => void
  /** Called when user click on Back button */
  handleContinue: (step: number) => void
  /** Save physician details */
  savePhysicianDetails: (data: NewPhysicianDetilsDto) => void
  /** Save institute details */
  submitTransferring: (data: NewInstituteDetilsDto) => void
  /** Called when user click on View Patient details */
  handleViewPatientDetails: () => void
}

export const TransferPatientToInstitute: FunctionComponent<
  ITransferPatientProps
> = ({
  isLoading,
  isSubmitting,
  patient,
  program,
  selectedSteppers,
  currentStep,
  physicianDetails,
  isAgreementConfirmed,
  countries,
  physicianTitles,
  setIsAgreementConfirmed,
  handleCancel,
  handleContinue,
  savePhysicianDetails,
  submitTransferring,
  handleViewPatientDetails
}) => {
  const { t } = useTranslation()
  return isLoading || !patient ? (
    <Container>
      <Row align="center">
        <Col>
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    </Container>
  ) : (
    <Container className="transfer-patient">
      <ClinSpacer height={ClinTheme.space[5]} />
      <Row>
        <Col sm={12} lg={10}>
          <ClinText
            as="h1"
            className="physician-full-name"
            variant={TypographyVariant.H2}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {t('transfer_patient_to_institute:description')}
          </ClinText>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[5]} />
      {selectedSteppers && (
        <ClinStepper
          steps={[
            {
              state: selectedSteppers[0],
              displayText: t('transfer_patient_to_institute:stepper.step_1')
            },
            {
              state: selectedSteppers[1],
              displayText: t('transfer_patient_to_institute:stepper.step_2')
            },
            {
              state: selectedSteppers[2],
              displayText: t('transfer_patient_to_institute:stepper.step_3')
            }
          ]}
          width={600}
        />
      )}

      <ClinSpacer height={ClinTheme.space[8]} hasBorder />
      {currentStep === PatientTransferSteps.STEP1 && (
        <>
          <PatientDetails patient={patient} />
          <ClinSpacer height={ClinTheme.space[7]} />
          <Row>
            <Col sm={12} md={9} lg={9}>
              <ClinAccordion>
                <ClinAccordionItem
                  title={t('transfer_patient_to_institute:program_details')}
                >
                  <ProgramDetails program={program} />
                </ClinAccordionItem>
              </ClinAccordion>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[5]} />
          <Row>
            <Col sm={12} md={9} lg={9}>
              <HipaaCompliance
                transferToSameInstitute={false}
                preselected={isAgreementConfirmed}
                handleConfirmation={(isConfirmed) =>
                  setIsAgreementConfirmed(isConfirmed)
                }
              />
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[5]} />
          <Row>
            <Col sm={12} md={9} lg={9}>
              <ClinAnnounceBar
                mode={AnnounceMode.Information}
                title={t('transfer_patient_to_institute:please_note')}
              >
                {t('transfer_patient_to_institute:note_one')}
              </ClinAnnounceBar>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[7]} />
          <Row>
            <Col sm={12} md={9} lg={6}>
              <ClinGroup space={ClinTheme.space[3]}>
                <StyledTransferButton
                  className="cancel"
                  variant="secondary"
                  justifyContent="space-around"
                  onClick={() => handleCancel()}
                >
                  {t('common:buttons.cancel')}
                </StyledTransferButton>
                <StyledTransferButton
                  className="continue"
                  variant="primary"
                  justifyContent="space-around"
                  disabled={!isAgreementConfirmed}
                  onClick={() => handleContinue(PatientTransferSteps.STEP2)}
                >
                  {t('common:buttons.continue')}
                </StyledTransferButton>
              </ClinGroup>
            </Col>
          </Row>
        </>
      )}
      {currentStep === PatientTransferSteps.STEP2 && (
        <PhysicianDetailsForm
          physicianTitles={physicianTitles}
          savePhysicianDetails={savePhysicianDetails}
          physicianDetails={physicianDetails}
          handleCancel={handleCancel}
        />
      )}
      {currentStep === PatientTransferSteps.STEP3 && countries && (
        <InstituteDetailsForm
          countries={countries}
          isSubmitting={isSubmitting}
          submitTransferring={submitTransferring}
          handleCancel={handleCancel}
        />
      )}
      {currentStep === PatientTransferSteps.STEP4 && (
        <SuccessScreen handleViewPatientDetails={handleViewPatientDetails} />
      )}
      <ClinSpacer height={ClinTheme.space[9]} />
    </Container>
  )
}
