export enum OPAErrorCode {
  XXCL_OPA_LAUNCH_NOT_ELIGIBLE = 'XXCL_OPA_LAUNCH_NOT_ELIGIBLE',
  XXCL_OPA_LAUNCH_INVALID_URL = 'XXCL_OPA_LAUNCH_INVALID_URL',
  XXCL_OPA_LAUNCH_EXPIRED_URL = 'XXCL_OPA_LAUNCH_EXPIRED_URL',
  XXCL_OPA_LAUNCH_TIMEOUT = 'XXCL_OPA_LAUNCH_TIMEOUT',
  XXCL_OPA_INIT_PATIENT_LOCKED = 'XXCL_OPA_INIT_PATIENT_LOCKED'
}

/**
 * Window.postMessage data from the OPA
 */
export interface IOPAPostMessage {
  /** Hard Coded to OPA_ONLINE_SERVICES (mandatory) */
  event?: string
  /** This was used in POC instead of event TODO: Refactor to correct when OPAs supplied! */
  type?: string
  /** Generated Order Number */
  order?: number
  /** date-time Timestamp of OPA */
  createdOn?: string
  /** Return Status -> true for success and false for failure (mandatory)*/
  status: boolean
  /** Error Code if status is false */
  errorCode?: OPAErrorCode
  /** Error Message if status is false */
  errorMessage?: string
  /** Message for Save & Exit */
  message?: string
  /** Option scroll position */
  scroll?: string
  /** Window height */
  height?: number
  /**  SERVICE_CLOUD_CP_OPA*/
  system?: string
}

export enum OPAMessagingConstants {
  OpaOnlineServices = 'OPA_ONLINE_SERVICES',
  ServiceCloudCPOPA = 'SERVICE_CLOUD_CP_OPA',
  CheckoutCreated = 'CHECKPOINT_CREATED'
}
