/**
 * Returned from the API and used to determine the text we display
 */
export enum ProductItemAvailabilityStatus {
  AVAILABLE = 'Available',
  LIMITED_AVAILABLE = 'Limited available',
  AVAILABLE_ON_REQUEST = 'Available on request',
  UNAVAILABLE_ONLINE = 'Currently unavailable online',
  UNAVAILABLE = 'Unavailable',
  HIDDEN = 'Hidden'
}

/**
 * The order which products are sorted in the product variant list
 */
export const productStatusSortOrder: ProductItemAvailabilityStatus[] =
  Object.values(ProductItemAvailabilityStatus)

/**
 * Used to display the product availability status
 * Key refers to the value read from SquidEx via i18n
 */
export enum ProductItemAvailabilityStatusKey {
  AVAILABLE = 'portfolio_journey:available',
  AVAILABLE_ON_REQUEST = 'portfolio_journey:available_on_request',
  UNAVAILABLE_ONLINE = 'portfolio_journey:unavailable_online',
  UNAVAILABLE = 'portfolio_journey:unavailable',
  LIMITED_AVAILABLE = 'portfolio_journey:limited_available'
}
