import styled, { keyframes } from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'

export const NewFeatureDotWrap = styled.div({
  zIndex: ClinTheme.zIndices.confirmation
})

export const NewFeatureDotCircle1 = styled.div({
  position: 'relative',
  width: '36px',
  height: '36px',
  backgroundColor: 'rgba(140, 219, 31, 0.5);',
  borderRadius: '50%'
})

export const NewFeatureDotCircle2 = styled.div({
  transform: 'translate(25%,25%)',
  width: '24px',
  height: '24px',
  backgroundColor: ClinTheme.colors.secondary,
  borderRadius: '50%'
})

export const NewFeatureDotCircle3 = styled.div({
  transform: 'translate(48%,46%)',
  width: '12px',
  height: '12px',
  backgroundColor: ClinTheme.colors.green,
  borderRadius: '50%'
})

const pulsate = keyframes`
  0% {
    transform: scale(0.1, 0.1); 
    opacity: 0.0;
  }
  50% {opacity: 1.0;}
  100% {
    transform: scale(1.2, 1.2); 
    opacity: 0.0;
  }
`
export const NewFeatureDotRinging = styled.div`
  animation: ${pulsate} 1s ease-out infinite;
  border: ${ClinTheme.borderWidths[2]}px solid ${ClinTheme.colors.lightGreen};
  border-radius: 30px;
  height: 32px;
  width: 32px;
  position: absolute;
  left: -1px;
  top: -1px;
  opacity: 0;
`
