import { LocationContactBlock } from '../../types/ContentTypes'
/**
 * Provides a function that returns an array of locations arranged in asc alphabetical order by location name
 * @param locationA - first sort parameter
 * @param locationB - second sort parameter
 */
export const locationsSortFn = (
  locationA: LocationContactBlock,
  locationB: LocationContactBlock
) => {
  if (locationA.flatData.locationName && locationB.flatData.locationName) {
    if (locationA.flatData.locationName < locationB.flatData.locationName) {
      return -1
    }
    if (locationA.flatData.locationName > locationB.flatData.locationName) {
      return 1
    }
  }
  return 0
}
