import React, { FunctionComponent, useEffect } from 'react'
import {
  StyledCopyright,
  StyledFooter,
  StyledFooterExternalLink,
  StyledFooterLinks,
  StyledFooterNavigation
} from './Footer.styles'
import { Container } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import { CountryDto } from '../../types/swaggerTypes'
import { matchInArray } from '../Search/AutoSuggest/AutoSuggest.model'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { ClinStyledChatSupport } from '../../components/ClinStyledChatSupport'
import { NavIconsBarContainer } from '../../containers/NavIconsBarContainer'

export interface IFooterProps {
  supportContact?: CountryDto
}

export const Footer: FunctionComponent<IFooterProps> = () => {
  const { t } = useTranslation()

  const [isMobile, setIsMobile] = React.useState<boolean>(false)

  const updateIsMobile = () => {
    setIsMobile(
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) <= ClinTheme.breakpoints[2]
    )
  }

  const listener = () => {
    updateIsMobile()
  }

  useEffect(() => {
    updateIsMobile() // Set initial state
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  // Hide if we are on onboarding
  const location = useLocation()
  if (matchInArray(location.pathname, ['onboarding'])) {
    return null
  }

  return (
    <StyledFooter>
      <Container
        style={{
          paddingTop: ClinTheme.space[7],
          paddingBottom: isMobile ? ClinTheme.space[8] : ClinTheme.space[5]
        }}
      >
        <StyledFooterNavigation>
          <StyledFooterLinks>
            <StyledFooterExternalLink
              href="https://www.clinigengroup.com/direct/en-gb/privacy-policy/"
              target={'_blank'}
            >
              {t('navigation:privacy_policy')}
            </StyledFooterExternalLink>

            <StyledFooterExternalLink
              href="https://www.clinigengroup.com/direct/en-gb/terms-of-use/"
              target={'_blank'}
            >
              {t('navigation:terms_of_use')}
            </StyledFooterExternalLink>

            <StyledFooterExternalLink
              href="https://www.clinigengroup.com/direct/en-gb/terms-of-sale/"
              target={'_blank'}
            >
              {t('navigation:terms_of_sale')}
            </StyledFooterExternalLink>

            <StyledFooterExternalLink
              href="https://www.clinigengroup.com/direct/en-gb/privacy-statement-for-pharmacovigilance-data/"
              target={'_blank'}
            >
              {t('navigation:privacy_statement_for_pharmacovigilance')}
            </StyledFooterExternalLink>
          </StyledFooterLinks>
          <StyledCopyright>
            {t('common:copyright', {
              year: new Date().getFullYear()
            })}
          </StyledCopyright>
        </StyledFooterNavigation>
      </Container>

      <ClinStyledChatSupport></ClinStyledChatSupport>
      {isMobile && <NavIconsBarContainer></NavIconsBarContainer>}
    </StyledFooter>
  )
}
