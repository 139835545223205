import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import {
  CountryDto,
  FacetDto,
  ProgramCatalogDto
} from '../../types/swaggerTypes'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { ClinSpinner } from '../../components/ClinSpinner'
import { truncateString } from '../../utils/truncateString'
import { ClinSpacer } from '../../components/ClinSpacer'
import { TableFilterSearch } from '../../features/TableFilterSearch'
import { BasicSearch } from '../../features/Search/BasicSearch'
import {
  ClinTableBodyCell,
  ClinTableHeaderCell
} from '../../components/ClinTable/ClinTable'
import { ClinTableOrderToggle } from '../../components/ClinTableOrderToggle'
import { ClinTable } from '../../components/ClinTable'
import {
  DocumentationColumn,
  getTableCellContent,
  getTableColumnWidth
} from './ProgramDocumentation.models'
import { StyledClinTableRow } from '../Products/ProductSearch/ProductSearch.styles'
import { ClinPagination } from '../../components/ClinPagination'
import {
  getCurrentPage,
  getTotalPages,
  IPagination
} from '../../components/ClinPagination/ClinPagination.model'
import { SortDirectionType } from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { IProgramDocument } from '../../types/IProgramDocument'
import { NoPrograms } from '../../features/NoPrograms'
import { useTranslation } from 'react-i18next'
interface IProgramDocumentationProps {
  /** Whether the page is loading */
  isLoading?: boolean
  /** Program */
  program?: ProgramCatalogDto
  /** The array of products search results */
  documents?: IProgramDocument[]
  /** Any initial text query to populate the search with */
  initialQuery?: string
  /** Whether filters are open on mount (for dev only really) */
  isFiltersOpen?: boolean
  /** Pagination */
  pagination?: IPagination
  /** The array of Category values */
  categoryFilters?: FacetDto[]
  /** The array of selected Category values */
  selectedCategoryFilters?: FacetDto[]
  /** The array of language values */
  languageFilters?: FacetDto[]
  /** The array of selected language values */
  selectedLanguageFilters?: FacetDto[]
  /** The selected column for sorting */
  selectedSortColumn?: DocumentationColumn
  /** The selected sort direction */
  selectedSortDirection?: SortDirectionType
  /** Supplied contact data */
  supportContact?: CountryDto
  /** User country*/
  userCountry?: string
  /** Select the toggle sort column */
  handleToggleSort?: (columnName: DocumentationColumn) => void
  /** When a search query is entered and ENTER pressed */
  handleOnChange?: (query: string) => void
  /** When a filter is selected */
  handleFilterToggle?: (filter: FacetDto, filterGroupTitle: string) => void
  /** Clear all filters */
  handleClearFilters?: () => void
  /** When pagination page is selected */
  handlePageClicked?: (selectedPageIndex: number) => void
  /** Select results per page */
  handlePageSizeChange?: (pageSize: number) => void
  /** When a request to download a document is made */
  handleDownload?: (document: IProgramDocument) => void
}

export const ProgramDocumentation: FunctionComponent<
  IProgramDocumentationProps
> = ({
  isLoading,
  program,
  documents,
  initialQuery,
  isFiltersOpen,
  pagination,
  categoryFilters,
  selectedCategoryFilters,
  languageFilters,
  selectedLanguageFilters,
  selectedSortColumn,
  selectedSortDirection,
  supportContact,
  userCountry,
  handleToggleSort,
  handleOnChange,
  handleFilterToggle,
  handleClearFilters,
  handlePageClicked,
  handlePageSizeChange,
  handleDownload
}) => {
  const { t } = useTranslation()
  const programName: string = program?.programName ?? ''
  const columns = [
    DocumentationColumn.Category,
    DocumentationColumn.DocumentationName,
    DocumentationColumn.Language,
    DocumentationColumn.LastUpdated,
    DocumentationColumn.Download
  ]

  const getColumnNameForColumn = (column: DocumentationColumn): string => {
    switch (column) {
      case DocumentationColumn.Category:
        return t('program_documentation:table_column_category')
      case DocumentationColumn.DocumentationName:
        return t('program_documentation:table_column_name')
      case DocumentationColumn.Language:
        return t('program_documentation:table_column_language')
      case DocumentationColumn.LastUpdated:
        return t('program_documentation:table_column_last_updated')
      case DocumentationColumn.Download:
        return t('program_documentation:table_column_download')
      default:
        return ''
    }
  }

  // Loading
  if (isLoading && !program) {
    return (
      <StyledSpinnerContainer>
        <ClinSpinner size={ClinTheme.space[7]} />
      </StyledSpinnerContainer>
    )
  }

  return (
    <ClinPageContentFrame
      crumbs={[
        { path: '/orders', name: t('orders:page_name') },
        { path: '/programs/access-programs', name: t('access_programs:title') },
        {
          name: truncateString(programName),
          path: `/programs/access-programs/${program?.projectId}`
        },
        {
          path: `/programs/access-programs/${program?.projectId}/documentation`,
          name: t('program_documentation:page_name')
        }
      ]}
    >
      <Row>
        <Col sm={12}>
          <ClinText
            as="h1"
            variant={TypographyVariant.H2}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {t('program_documentation:title', { programName: programName })}
          </ClinText>
        </Col>
      </Row>
      <ClinSpacer />
      <Row>
        <Col sm={12}>
          <TableFilterSearch
            title={t('program_documentation:filters_title')}
            selectedTitle={t('program_documentation:filters_selected_title')}
            isOpenOnMount={isFiltersOpen}
            filterGroups={[
              {
                title: t('program_documentation:filters_category_title'),
                titleSelected: t(
                  'program_documentation:filters_category_selected',
                  {
                    numberFilters: selectedCategoryFilters?.length
                  }
                ),
                filters: categoryFilters,
                selectedFilters: selectedCategoryFilters
              },
              {
                title: t('program_documentation:filters_language_title'),
                titleSelected: t(
                  'program_documentation:filters_language_selected',
                  {
                    numberFilters: selectedLanguageFilters?.length
                  }
                ),
                filters: languageFilters,
                selectedFilters: selectedLanguageFilters
              }
            ]}
            handleSelectFilterGroup={(filter, filterGroupTitle) =>
              handleFilterToggle && handleFilterToggle(filter, filterGroupTitle)
            }
            handleClearAllFilters={handleClearFilters}
            searchInput={() => (
              <BasicSearch
                initialQuery={initialQuery}
                placeholder={t('program_documentation:search_placeholder')}
                debounceTimeMs={500}
                handleOnChange={(query) =>
                  handleOnChange && handleOnChange(query)
                }
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          {isLoading && (
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          )}
          {!isLoading && documents && (
            <ClinTable
              tableHeader={() =>
                columns.map((column: DocumentationColumn, index: number) => (
                  <ClinTableHeaderCell
                    key={index}
                    width={getTableColumnWidth(column)}
                    textAlign={'left'}
                  >
                    {column === DocumentationColumn.Download ? (
                      ''
                    ) : (
                      <ClinTableOrderToggle
                        sortDirection={
                          selectedSortColumn === column
                            ? selectedSortDirection
                            : SortDirectionType.None
                        }
                        onClick={() =>
                          handleToggleSort && handleToggleSort(column)
                        }
                      >
                        {getColumnNameForColumn(column)}
                      </ClinTableOrderToggle>
                    )}
                  </ClinTableHeaderCell>
                ))
              }
            >
              {documents.length > 0 ? (
                documents.map((document: IProgramDocument, rowIndex) => {
                  return (
                    <StyledClinTableRow key={rowIndex}>
                      {columns.map(
                        (column: DocumentationColumn, index: number) => {
                          return (
                            <ClinTableBodyCell
                              key={index}
                              verticalAlign={'middle'}
                              textAlign={'left'}
                              onClick={() =>
                                handleDownload &&
                                column === DocumentationColumn.Download &&
                                handleDownload(document)
                              }
                            >
                              {getTableCellContent(
                                t,
                                column,
                                document,
                                document.isDownloading
                              )}
                            </ClinTableBodyCell>
                          )
                        }
                      )}
                    </StyledClinTableRow>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={columns.length}>
                    <NoPrograms
                      selectedTabIndex={1}
                      isMaUser={true}
                      supportContact={supportContact}
                    />
                  </td>
                </tr>
              )}
            </ClinTable>
          )}
        </Col>
      </Row>
      <ClinSpacer />
      <Row>
        <Col>
          {!isLoading &&
            documents &&
            documents?.length > 0 &&
            pagination &&
            pagination.total > 0 && (
              <ClinPagination
                currentPage={getCurrentPage(pagination)}
                totalPages={getTotalPages(pagination)}
                pageSize={pagination.take}
                handlePageSelected={(pageIndex) =>
                  handlePageClicked && handlePageClicked(pageIndex)
                }
                handlePageSizeChange={handlePageSizeChange}
              />
            )}
        </Col>
      </Row>
    </ClinPageContentFrame>
  )
}
