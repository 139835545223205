// Utility functions to handle basket tasks

import { AppEvent } from '../context/app'
import { ApiBasketErrorType, IBasketError } from '../types/IBasketError'
import { AxiosError } from 'axios'
import { cancelDraftOrder, getCurrentBasket } from '../services/ApiService'
import { createAnnounceEvent } from '../events/AnnounceEvent'
import { AnnounceMode } from '../components/ClinAnnounceBar/ClinAnnounceBar'
import { createBasketErrorEvent } from '../events/BasketErrorEvent'
import { ActionType, BasketStatus, Dispatch } from '../context/basket'
import analyticsServiceSingleton from '../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../services/Analytics'

/**
 * Handle an API basket error and optionally dispatch the event
 * If a resetCallback function is passed the checkout will be cancelled and then callback called
 * @param error
 * @param dispatch
 * @param callbackFn
 */
export const handleBasketError = (
  error: any,
  dispatch?: (event: AppEvent) => void,
  resetCallback?: Function
): IBasketError | undefined => {
  if ((error as AxiosError).isAxiosError) {
    const basketError = error.response.data as IBasketError
    dispatch && dispatch(createBasketErrorEvent(basketError.errorType))
    if (resetCallback) {
      basketError &&
        cancelDraftOrder()
          .then((response) => {
            if (response.status === 200) {
              resetCallback()
            }
          })
          .catch((error) => {
            dispatch &&
              dispatch(
                createAnnounceEvent(
                  AnnounceMode.Error,
                  `An error occurred cancelling checkout. ${error}`
                )
              )
          })
    }
    // Track this event
    analyticsServiceSingleton.trackEvent(
      AnalyticsEvent.BasketStaleCheckoutError,
      {
        error: basketError.errorType
      }
    )
    return basketError
  }
}

/**
 * Checks if the current basket is checking out and cancels any current draft checkouts/baskets
 */
export const resetBasket = async (
  dispatch: (event: AppEvent) => void,
  basketDispatch: Dispatch
) => {
  try {
    const basketResponse = await getCurrentBasket()
    basketResponse &&
      basketDispatch({
        type: ActionType.UpdateBasket,
        basket: { data: basketResponse.data, isLoading: false }
      })
    if (basketResponse?.data.state === BasketStatus.CheckingOut) {
      analyticsServiceSingleton.trackEvent(
        AnalyticsEvent.BasketStaleCheckoutError,
        {
          error: ApiBasketErrorType.PERMISSION_ERROR
        }
      )
      const cancelCheckoutResponse = await cancelDraftOrder()
      if (cancelCheckoutResponse.status === 200) {
        return
      }
    }
    return
  } catch (error) {
    dispatch(
      createAnnounceEvent(
        AnnounceMode.Error,
        `An error occurred while resetting your basket. ${error}`
      )
    )
    throw error
  }
}
