import styled from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'
import { ClinCard } from '../../../components/ClinCard'
import { ClinModal } from '../../../components/ClinModal'
import { StyledModalContent } from '../../../components/ClinModal/ClinModal.styles'
import '@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css'
import { mediaQuery, mediaQueryDown } from '../../../utils/mediaQuery'
import { IClinTableRowStyles } from '../../../components/ClinTable/ClinTable'

const tabletBreakpoint = ClinTheme.breakpoints[2]

export const StyledPatientCard = styled(ClinCard)({
  padding: `${ClinTheme.space[3]}px ${ClinTheme.space[4]}px ${ClinTheme.space[2]}px ${ClinTheme.space[4]}px`
})

export const StyledPatientCardDetail = styled.div({
  marginBottom: ClinTheme.space[3]
})

export const StyledCalendarModal = styled(ClinModal)({
  [`${StyledModalContent}`]: { width: 'auto', borderRadius: ClinTheme.radii[4] }
})

export const StyledCalendarWrapper = styled.div({
  '.Calendar': {
    fontFamily: ClinTheme.fonts.body,
    border: `solid 1px ${ClinTheme.colors.primaryMid}`,
    borderRadius: ClinTheme.radii[4],
    minHeight: '33em',
    overflow: 'hidden',
    boxShadow: `0px 2px 6px 0px rgba(80, 30, 206, 0.5)`
  },

  '.Calendar__day.-selected ': {
    background: ClinTheme.colors.primaryLight,
    color: ClinTheme.colors.white,
    fontWeight: ClinTheme.fontWeights.bold
  },
  '.Calendar__sectionWrapper': {
    minHeight: '23.8em'
  },
  '.Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText, .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText':
    {
      background: ClinTheme.colors.primaryLight
    },
  '.Calendar__monthYear.-shown, .Calendar__yearSelectorText, .Calendar__monthSelectorItemText':
    {
      color: ClinTheme.colors.black,
      WebkitFontSmoothing: 'antialiased'
    }
})

export const StyledCTAColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
  padding: `${ClinTheme.space[2]}px ${ClinTheme.space[3]}px`,
  [`.clin-button`]: {
    margin: `0 ${ClinTheme.space[2]}px ${ClinTheme.space[4]}px`,
    maxWidth: `${ClinTheme.space[9] * 4}px`
  },
  [mediaQuery(tabletBreakpoint)]: {
    padding: `0 ${ClinTheme.space[3]}px`
  },
  [mediaQuery(tabletBreakpoint)]: {
    [`.clin-text`]: {
      margin: `${ClinTheme.space[3]}px 0`
    },
    [`.clin-button`]: {
      margin: `0 0 ${ClinTheme.space[4]}px`
    }
  }
})

export const StyleOpaAction = styled.div({
  display: 'flex',
  cursor: 'pointer'
})

export interface IStyledClinVialRowProps extends IClinTableRowStyles {
  isOrderToggledOpen: boolean
}

export interface IStyledCardWrapperProps {
  width?: string
}

export const StyledClinVialRow = styled.tr<IStyledClinVialRowProps>(
  {},
  ({ isOrderToggledOpen }: IStyledClinVialRowProps) => ({
    borderTop: !isOrderToggledOpen
      ? `solid 1px ${ClinTheme.colors.midGrey}`
      : 'initial',
    borderBottom: isOrderToggledOpen
      ? 'initial'
      : `solid 1px ${ClinTheme.colors.midGrey}`
  })
)

export const StyledPatientAttributesCardWrapper =
  styled.div<IStyledCardWrapperProps>(
    {
      display: 'flex',
      alignItems: 'center'
    },
    ({ width }: IStyledCardWrapperProps) => ({
      width: width ? width : 'initial',
      [mediaQueryDown(ClinTheme.breakpoints[2])]: {
        width: width ? '15%' : 'initial'
      }
    })
  )

export const StyledPatientAttributesCard = styled.div({
  padding: '24px 16px 24px 16px',
  height: '100%',
  boxSizing: 'border-box',
  width: '100%'
})

export const StyledPatientAttributes = styled.div({
  minHeight: '121px',
  background: ClinTheme.colors.white,
  border: '1px solid #E7E8E8',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap'
})

export const StyledPatientHeaderItemsWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: `${ClinTheme.space[5]}px 0px`,
  margin: '0px auto 0px',
  flexWrap: 'nowrap',
  width: '100%',
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    flexDirection: 'column',
    marginBottom: '15px'
  }
})

export const StyledPatientHeaderItemAvatar = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  alignItems: 'center'
})

export const StyledPatientHeaderItems = styled.div({
  alignItems: 'center',
  boxSizing: 'border-box',
  display: 'flex',
  flexWrap: 'nowrap',
  width: '100%',
  justifyContent: 'space-between'
})

interface IStyledInitialsProps {
  background?: string
}

export const StyledAttributePatientInitialsAvatar =
  styled.div<IStyledInitialsProps>(
    {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      color: ClinTheme.colors.primary,
      fontSize: 15,
      textTransform: 'uppercase',
      height: 34,
      width: 34,
      minWidth: 34,
      margin: 2,
      fontWeight: ClinTheme.fontWeights.medium,
      textAlign: 'center',
      lineHeight: '16px',
      marginRight: ClinTheme.space[2]
    },
    ({ background }: IStyledInitialsProps) => ({
      backgroundColor: background ? background : ClinTheme.colors.primaryLight
    })
  )

export const StyledPatientHeaderButtonContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  gap: ClinTheme.space[2]
})

export const StyledPatientTabEmptyHeaderWrapper = styled.div({
  background: ClinTheme.colors.white,
  border: '1px solid #E7E8E8',
  borderRadius: '4px',
  padding: '56px'
})
