import React from 'react'
import { Col, Row } from 'react-grid-system'
import { Trans, useTranslation } from 'react-i18next'
import { ClinTheme } from '../../../ClinTheme'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { AutoSuggest } from '../../../features/Search/AutoSuggest'
import { StyledPageHeader } from './HomepageOld.styles'
import { GlobalSearchAUSOld } from '../../../features/Search/GlobalSearch/old/GlobalSearchAUSOld'

export const HomepageOld: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <StyledPageHeader>
        <Row style={{ flexShrink: 1, margin: ClinTheme.space[0] }}>
          <Col className="clin_column" xs={12} md={12}>
            <ClinText
              variant={TypographyVariant.H2}
              color={ClinTheme.colors.white}
              as="h1"
              fontWeight={ClinTheme.fontWeights.bold}
            >
              {t('product_catalogue:aus_title_old')}
            </ClinText>
            <ClinText
              className="description"
              variant={TypographyVariant.LargerParagraph}
              color={ClinTheme.colors.white}
              marginTop={'0px'}
            >
              <Trans
                i18nKey="product_catalogue:aus_description"
                components={{ bold: <strong /> }}
              >
                <></>
              </Trans>
            </ClinText>
          </Col>
        </Row>
      </StyledPageHeader>
      <AutoSuggest
        noResultsText={t('global_search:suggestion_text_aus')}
        maUserNoResultsText={t('global_search:ma_user_no_results_text')}
        suggestionText={t('global_search:suggestion_text_aus')}
        noResultsPath={'/products/catalogue?q=*'}
        suggestionTextColor={ClinTheme.colors.primaryMid}
        suggestionTextPadding={`${ClinTheme.space[3]}px`}
        borderRadiusLastLiElement={'28'}
        searchComponent={(props) => <GlobalSearchAUSOld {...props} />}
      />
      <ClinPageContentFrame hideBreadcrumbs={true} marginBottom="0">
        <></>
      </ClinPageContentFrame>
    </>
  )
}
