import styled from 'styled-components'

export const StyledClinMarketingPreferencesWrap = styled.div({
  width: '100%',
  padding: '10px'
})

export const StyledClinMarketingPreferenceWrap = styled.div({
  borderRadius: '8px',
  border: `1px solid #E7E8E8 `,
  padding: '20px'
})
