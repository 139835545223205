import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'
import { IConfirmation } from '../../types/IConfirmation'

export interface IClinConfirmationStyles {
  confirmation: IConfirmation
}

export const StyledClinConfirmation = styled.div({
  // Theses styles mostly match the ClinNavBar - confirmations should
  // show below the nav on the right hand side
  margin: 'auto',
  padding: `${ClinTheme.space[3]}px ${ClinTheme.space[3]}px 0`,
  position: 'sticky',
  top: 0,
  height: 0,
  zIndex: ClinTheme.zIndices.dropdown,
  [mediaQuery(ClinTheme.breakpoints[2])]: {
    padding: 0,
    paddingLeft: ClinTheme.space[7]
  }
})

export const StyledClinConfirmationBox = styled.div<IClinConfirmationStyles>(
  {
    marginLeft: 'auto',
    boxShadow: ClinTheme.shadows[0],
    marginTop: ClinTheme.space[2],
    background: ClinTheme.colors.white
  },
  ({ confirmation }) => ({
    width: confirmation.boxWidth || 220,
    padding: confirmation.removePadding ? 0 : ClinTheme.space[3],
    marginRight: confirmation.marginRight
      ? confirmation.marginRight
      : ClinTheme.space[2]
  })
)
