import { INewFeatureItem } from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'

export enum ActionType {
  SetNewFeatures,
  UpdateNewFeatures,
  RemoveFeature
}

interface ActionNewFeaturesLoaded {
  type: ActionType.SetNewFeatures
  features: INewFeatureItem[]
}

interface ActionUpdateNewFeatures {
  type: ActionType.UpdateNewFeatures
  feature: INewFeatureItem
}

interface ActionRemoveFeature {
  type: ActionType.RemoveFeature
  featureId: string
}

export type ActionTypes =
  | ActionNewFeaturesLoaded
  | ActionUpdateNewFeatures
  | ActionRemoveFeature
