import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { ClinTheme } from '../../../ClinTheme'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../../components/ClinAccordion'
import { ClinButton } from '../../../components/ClinButton'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import {
  drugPropertyTitleAndIconRecord,
  DrugProperty
} from '../../../services/DrugPropertyProvider'
import {
  HoldDto,
  LicenseDto,
  OrderLineDto,
  UploadedDocumentDto
} from '../../../types/swaggerTypes'
import {
  StyledOrderDetailProperty,
  StyledOrderDetailPropertyDetail,
  StyledOrderDetailItemProperties,
  StyledOrderDetailItem,
  StyledOrderDetailLineAction,
  StyledOrderDetailItemValue,
  StyledOrderDetailMobileLabel,
  StyledOrderDetailItemDivider
} from './OrderDetailItem.styles'
import { OrderStatus } from '../../../types/OrderStatus'
import { Link } from 'react-router-dom'
import { DocumentUploadContainer } from '../../../features/DocumentUpload/DocumentUploadContainer'
import { getOrderHoldsInformation } from '../../../services/Holds/holds'
import { StyledBasketHoldItem } from '../../Basket/Basket.styles'
import { DocumentUpload } from '../../../features/DocumentUpload'
import { DateTime } from 'luxon'
import { HoldAnnounce } from '../../../features/HoldAnnounce'
import { HoldAnnounceMode } from '../../../features/HoldAnnounce/HoldAnnounce'
import { numberToCurrencyString } from '../../../utils/numberToCurrencyString'
import { getBrowserLocale } from '../../../utils/getBrowserLocale'
import { Trans, useTranslation } from 'react-i18next'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'

const getDrugPropertyItem = (
  drugProperty: DrugProperty,
  children: React.ReactNode
) => {
  const { iconName, title } = drugPropertyTitleAndIconRecord[drugProperty]
  return (
    <StyledOrderDetailProperty>
      <ClinIcon
        iconSize={ClinTheme.fontSizes[5]}
        iconFill={ClinTheme.colors.primary}
        iconName={iconName}
      />
      <StyledOrderDetailPropertyDetail>
        <ClinText
          fontWeight={ClinTheme.fontWeights.medium}
          color={ClinTheme.colors.primary}
        >
          {title}
        </ClinText>

        <ClinText color={ClinTheme.colors.primary}>{children}</ClinText>
      </StyledOrderDetailPropertyDetail>
    </StyledOrderDetailProperty>
  )
}

interface IOrderDetailItemProps {
  /** The order id **/
  orderId: string
  /** An orderline within a placed order **/
  orderLine: OrderLineDto
  /** While adding to basket disable */
  isAddingToBasket: boolean
  /** Disable reorder */
  cannotOrder: boolean
  /** Provides the currency assigned to the order **/
  currencyCode: string
  /** Handles cancelling an order line for an order **/
  onCancelOrderLine: (orderLine: OrderLineDto) => void
  /** Handles adding an order line to the basket **/
  onAddToBasket: (orderLine: OrderLineDto, quantity: number) => void
  /** Called when a file has been uploaded */
  handleFileUploadedForOrderAndHold?: (
    orderId: string,
    orderLine: OrderLineDto,
    hold: HoldDto,
    document: UploadedDocumentDto
  ) => void
}

export const OrderDetailItem: FunctionComponent<IOrderDetailItemProps> = ({
  orderId,
  orderLine,
  isAddingToBasket,
  cannotOrder,
  currencyCode,
  onAddToBasket,
  onCancelOrderLine,
  handleFileUploadedForOrderAndHold
}) => {
  const { t } = useTranslation()
  const holdsInformation = getOrderHoldsInformation(orderLine.holds)
  return (
    <StyledOrderDetailItem>
      <ClinSpacer height={ClinTheme.space[3]} />
      <Row>
        <Col sm={12} md={6}>
          <StyledOrderDetailItemValue>
            <StyledOrderDetailMobileLabel>
              <ClinText
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
                as="p"
                variant={TypographyVariant.Paragraph}
              >
                {t('glossary:product')}
              </ClinText>
            </StyledOrderDetailMobileLabel>

            <Link to={`/product/sku/${orderLine.skuCatalogItem?.sku}`}>
              <ClinText
                as="h5"
                fontWeight={ClinTheme.fontWeights.bold}
                variant={TypographyVariant.LargeParagraph}
                color={ClinTheme.colors.primary}
                wordBreak="break-word"
              >
                <u>{orderLine.itemDescription}</u>
              </ClinText>
            </Link>
          </StyledOrderDetailItemValue>
        </Col>
        <Col sm={12} md={2}>
          <StyledOrderDetailItemValue>
            <StyledOrderDetailMobileLabel>
              <ClinText
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
                as="p"
                variant={TypographyVariant.Paragraph}
              >
                {t('glossary:product_code')}
              </ClinText>
            </StyledOrderDetailMobileLabel>
            <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
              {orderLine.skuCatalogItem?.sku}
            </ClinText>
          </StyledOrderDetailItemValue>
        </Col>
        <Col sm={12} md={2}>
          <StyledOrderDetailItemValue>
            <StyledOrderDetailMobileLabel>
              <ClinText
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
                as="p"
                variant={TypographyVariant.Paragraph}
              >
                {t('glossary:quantity')}
              </ClinText>
            </StyledOrderDetailMobileLabel>
            <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
              {orderLine.quantities.ordered}
            </ClinText>
          </StyledOrderDetailItemValue>
        </Col>
        <Col sm={12} md={2}>
          <StyledOrderDetailItemValue>
            <StyledOrderDetailMobileLabel>
              <ClinText
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
                as="p"
                variant={TypographyVariant.Paragraph}
              >
                {t('glossary:subtotal')}
              </ClinText>
            </StyledOrderDetailMobileLabel>
            <ClinText
              className="sub-total-detail"
              as="p"
              variant={TypographyVariant.LargeParagraph}
            >
              {numberToCurrencyString(
                orderLine.totals.subTotal,
                getBrowserLocale(),
                {
                  style: 'currency',
                  currency: currencyCode ? currencyCode : 'GBP',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }
              )}
            </ClinText>
          </StyledOrderDetailItemValue>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[5]} />
      <Row>
        <Col sm={12} md={8}>
          <Row>
            <Col>
              <ClinGroup alignItems="flex-end" justifyContent="flex-start">
                <ClinText as="p" color={ClinTheme.colors.primary}>
                  {t('glossary:status')}
                </ClinText>
                <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
                  {orderLine.status}
                </ClinText>
              </ClinGroup>
            </Col>
          </Row>

          {/*<pre>{JSON.stringify(holdsInformation, null, 2)}</pre>*/}
          {holdsInformation &&
            holdsInformation.holdsDetails.map((hold, index) => (
              <Row key={`${hold.type}-${index}`}>
                <Col sm={12}>
                  <ClinSpacer height={ClinTheme.space[3]} />
                  <HoldAnnounce
                    mode={HoldAnnounceMode.Error}
                    title={hold.title}
                    message={hold.message.replace(
                      '{genericName}',
                      orderLine.skuCatalogItem?.item.genericDosageConcatenation
                        ? orderLine.skuCatalogItem.item
                            .genericDosageConcatenation
                        : t('glossary:drug_name')
                    )}
                  />
                </Col>
              </Row>
            ))}

          <ClinSpacer height={ClinTheme.space[5]} />

          <ClinAccordion>
            <ClinAccordionItem title={t('glossary:product_details')}>
              <StyledOrderDetailItemProperties>
                <Row>
                  <Col sm={12} md={6}>
                    {getDrugPropertyItem(
                      DrugProperty.DosageAndPacksize,
                      <ClinText
                        as="span"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {`${orderLine?.skuCatalogItem?.item.genericDosageConcatenation} ${orderLine?.skuCatalogItem?.item.packSize}`}
                      </ClinText>
                    )}
                  </Col>
                  <Col sm={12} md={6}>
                    {getDrugPropertyItem(
                      DrugProperty.CountryOfLicense,
                      <ClinText
                        as="span"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {orderLine?.skuCatalogItem?.licenses.map(
                          (licence: LicenseDto) => licence.country
                        )}
                      </ClinText>
                    )}
                  </Col>
                  <Col sm={12} md={6}>
                    {getDrugPropertyItem(
                      DrugProperty.Brand,
                      <ClinText
                        as="span"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {orderLine?.skuCatalogItem?.item.brand}
                      </ClinText>
                    )}
                  </Col>
                  <Col sm={12} md={6}>
                    {getDrugPropertyItem(
                      DrugProperty.Overlabelled,
                      <ClinText
                        as="span"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {orderLine &&
                        orderLine.skuCatalogItem?.item.overlabelled === 'Y'
                          ? `${orderLine.skuCatalogItem?.item.overlabelLanguage} (${orderLine.skuCatalogItem?.item.overlabelType})`
                          : 'No'}
                      </ClinText>
                    )}
                  </Col>
                  <Col sm={12} md={6}>
                    {getDrugPropertyItem(
                      DrugProperty.UnitPrice,
                      <ClinText
                        as="span"
                        className="unit-price"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {numberToCurrencyString(
                          orderLine.unitSellingPrice,
                          getBrowserLocale(),
                          {
                            style: 'currency',
                            currency: currencyCode ? currencyCode : 'GBP',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        )}
                      </ClinText>
                    )}
                  </Col>
                  <Col sm={12} md={6}>
                    {getDrugPropertyItem(
                      DrugProperty.Delivery,
                      <ClinText
                        as="span"
                        variant={TypographyVariant.LargeParagraph}
                      >
                        {orderLine.expectedDate
                          ? DateTime.fromISO(
                              orderLine.expectedDate
                            ).toLocaleString()
                          : t('order_detail:dispatch_time_may_vary')}
                      </ClinText>
                    )}
                  </Col>
                </Row>
              </StyledOrderDetailItemProperties>
              <ClinSpacer hasBorder={true} />
              <ClinSpacer />
            </ClinAccordionItem>
          </ClinAccordion>

          <ClinText
            color={ClinTheme.colors.primary}
            fontSize={ClinTheme.fontSizes[3]}
          >
            {t('order_detail:required_supporting_documents')}
          </ClinText>

          {holdsInformation.requiresDocuments ? (
            <ClinText marginBottom={ClinTheme.space[3]}>
              <Trans
                i18nKey="order_detail:required_documents"
                components={{
                  br: <br />
                }}
              />
            </ClinText>
          ) : (
            <ClinText marginBottom={ClinTheme.space[3]}>
              {t('order_detail:not_required_documents')}
            </ClinText>
          )}

          {holdsInformation.holdsDetails.map((hold, index) => {
            return hold.requiresDocumentUpload &&
              hold.requiredDocumentName &&
              !hold.documentUploaded ? (
              <StyledBasketHoldItem key={`doc-upload-${index}`}>
                <DocumentUploadContainer
                  isPreOrderVariant={true}
                  documentPrompt={hold.documentPrompt}
                  warehouseAddress={hold.wareHouseAddress}
                  prompt={hold.requiredDocumentName}
                  handleFileUploaded={(document) => {
                    analyticsServiceSingleton.trackEvent(
                      AnalyticsEvent.DocumentUpload,
                      {
                        sku: orderLine.skuCatalogItem?.sku,
                        documentType: hold.requiredDocumentName
                      }
                    )
                    handleFileUploadedForOrderAndHold &&
                      hold.holdDto?.holdId &&
                      handleFileUploadedForOrderAndHold(
                        orderId,
                        orderLine,
                        hold.holdDto,
                        document
                      )
                  }}
                />
              </StyledBasketHoldItem>
            ) : (
              hold.documentUploaded && (
                <DocumentUpload
                  key={`doc-upload-${index}`}
                  prompt={hold.documentUploaded.documentType}
                  fileName={hold.documentUploaded.documentName}
                  uploadDetails={{
                    uploadDate: hold.documentUploaded.uploadedDate,
                    uploadedBy: hold.documentUploaded.uploadedBy
                  }}
                />
              )
            )
          })}
        </Col>

        <Col sm={12} md={4} lg={4}>
          <StyledOrderDetailLineAction>
            {orderLine.status !== OrderStatus.Cancelled &&
              orderLine.status !== OrderStatus.Processing &&
              orderLine.status !== OrderStatus.Shipped && (
                <ClinButton
                  className="delete-orderline-btn"
                  disabled={isAddingToBasket}
                  onClick={() => onCancelOrderLine(orderLine)}
                  justifyContent="center"
                  variant="linkWithIcon"
                >
                  {t('order_detail:cancel_item_btn')}
                  <ClinIcon
                    style={{ marginLeft: ClinTheme.space[2] }}
                    iconSize={ClinTheme.fontSizes[2]}
                    iconName={ClinIconPathName.Cross}
                  />
                </ClinButton>
              )}
            <ClinButton
              className="delete-orderline-btn"
              disabled={isAddingToBasket || cannotOrder}
              onClick={() =>
                orderLine.skuCatalogItem?.sku &&
                onAddToBasket(orderLine, orderLine.quantities.ordered)
              }
              justifyContent="center"
            >
              {t('order_detail:add_to_basket_btn')}
            </ClinButton>
          </StyledOrderDetailLineAction>
        </Col>
      </Row>

      <StyledOrderDetailItemDivider />
    </StyledOrderDetailItem>
  )
}
