import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../components/ClinAccordion'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { OrderLineDto } from '../../types/swaggerTypes'
import { numberToCurrencyString } from '../../utils/numberToCurrencyString'
import {
  StyledItemProperty,
  StyledItemPropertyDetail,
  StyledOrderItemLayout,
  StyledOrderItemSidebar,
  StyledSidebarItem,
  StyledOrderItem,
  IStyledOrderItemProps,
  StyledProductColumn,
  StyledSidebarAmount
} from './OrderItem.styles'
import { StyleQuantityText } from './OrderItems.styles'
import { getBrowserLocale } from '../../utils/getBrowserLocale'
import { useTranslation } from 'react-i18next'

interface IDrugPropertyItemProps {
  iconName: ClinIconPathName
  title: string
  description: string
}

export enum OrderItemType {
  OrdinaryItem,
  ReassignedItem
}

export const DrugPropertyItem: FunctionComponent<IDrugPropertyItemProps> = ({
  iconName,
  title,
  description
}) => (
  <Col sm={12} md={6}>
    <StyledItemProperty>
      <ClinIcon
        iconSize={ClinTheme.fontSizes[5]}
        iconFill={ClinTheme.colors.primary}
        iconName={iconName}
      />
      <StyledItemPropertyDetail>
        <ClinText
          fontWeight={ClinTheme.fontWeights.medium}
          color={ClinTheme.colors.primary}
        >
          {title}
        </ClinText>

        <ClinText
          color={ClinTheme.colors.black}
          fontSize={ClinTheme.fontSizes[2]}
        >
          {description}
        </ClinText>
      </StyledItemPropertyDetail>
    </StyledItemProperty>
  </Col>
)

export interface IOrderItemProps extends IStyledOrderItemProps {
  /** Order items */
  orderLine: OrderLineDto
  /** Sponsor of program */
  sponsor: string | undefined
  /** Currency code */
  currencyCode: string
  /** Order item type */
  itemType: OrderItemType
  /** Displays product details if equal true */
  isShowingProductDetails?: boolean
}

export const OrderItem: FunctionComponent<IOrderItemProps> = ({
  orderLine,
  sponsor,
  currencyCode,
  itemType,
  isShowingProductDetails = true,
  hasBorder
}) => {
  const { t } = useTranslation()

  const { skuCatalogItem } = orderLine
  const genericName = skuCatalogItem?.item.genericDosageConcatenation
  const dosageAndPackSize = `${
    skuCatalogItem?.genericDosageConcatenation ?? ''
  } ${skuCatalogItem?.formulationPackSize ?? ''}`
  const quantity = `${
    itemType === OrderItemType.OrdinaryItem
      ? orderLine.quantities.ordered
      : orderLine.vialsReassignedQuantity || 0
  }`
  const brandName = skuCatalogItem?.item.brand
    ? skuCatalogItem?.item.brand
    : t('glossary:not_applicable')
  const licenseCountries: string[] = []
  skuCatalogItem?.licenses.forEach(
    (license) => license.country && licenseCountries.push(license.country)
  )
  const licenseCountry =
    licenseCountries.join(', ') || t('glossary:not_applicable')
  const packPrice = numberToCurrencyString(
    orderLine.unitSellingPrice,
    getBrowserLocale(),
    {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }
  )

  return (
    <StyledOrderItem hasBorder={hasBorder}>
      <Row>
        <Col sm={12} md={8}>
          <StyledOrderItemLayout>
            <Row>
              <Col sm={12} md={6}>
                <StyledProductColumn>
                  <ClinText
                    fontSize={ClinTheme.fontSizes[1]}
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.primary}
                  >
                    {t('opa_checkout:generic_name_and_strength')}
                  </ClinText>
                  <ClinText
                    fontSize={ClinTheme.fontSizes[3]}
                    fontWeight={ClinTheme.fontWeights.bold}
                    color={ClinTheme.colors.primary}
                  >
                    {genericName}
                  </ClinText>
                </StyledProductColumn>
              </Col>
              <Col sm={12} md={6}>
                <StyledProductColumn>
                  <ClinText
                    fontSize={ClinTheme.fontSizes[1]}
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.primary}
                  >
                    {t('opa_checkout:product_code')}
                  </ClinText>
                  <ClinText fontSize={ClinTheme.fontSizes[3]}>
                    {orderLine.skuCatalogItem?.sku
                      ? orderLine.skuCatalogItem?.sku
                      : t('glossary:not_applicable')}
                  </ClinText>
                </StyledProductColumn>
              </Col>
              {isShowingProductDetails && (
                <Col sm={12}>
                  <ClinAccordion activeItemIndices={[0]}>
                    <ClinAccordionItem title={t('glossary:program_details')}>
                      <Row>
                        <DrugPropertyItem
                          iconName={ClinIconPathName.PackSize}
                          title={t('glossary:dosage_and_packsize')}
                          description={dosageAndPackSize}
                        />
                        <DrugPropertyItem
                          iconName={ClinIconPathName.Marker}
                          title={t('glossary:country_of_license')}
                          description={licenseCountry}
                        />
                        <DrugPropertyItem
                          iconName={ClinIconPathName.BrandName}
                          title={t('glossary:product')}
                          description={brandName}
                        />
                        <DrugPropertyItem
                          iconName={ClinIconPathName.DocumentSponsor}
                          title={t('glossary:sponsor')}
                          description={sponsor || 'N/A'}
                        />
                        <DrugPropertyItem
                          iconName={ClinIconPathName.UnitPrice}
                          title={t('glossary:pack_price')}
                          description={packPrice}
                        />
                      </Row>
                    </ClinAccordionItem>
                  </ClinAccordion>
                </Col>
              )}
            </Row>
          </StyledOrderItemLayout>
        </Col>
        <Col sm={12} md={4}>
          <StyledOrderItemSidebar>
            <StyledSidebarItem
              style={{ marginBottom: 'auto' }}
              isMinimized={!isShowingProductDetails}
            >
              <ClinText
                fontSize={ClinTheme.fontSizes[1]}
                fontWeight={ClinTheme.fontWeights.medium}
                color={ClinTheme.colors.primary}
              >
                {itemType === OrderItemType.ReassignedItem
                  ? t('opa_checkout:units')
                  : t('opa_checkout:quantity')}
              </ClinText>

              <StyleQuantityText
                fontSize={ClinTheme.fontSizes[2]}
                fontWeight={ClinTheme.fontWeights.light}
              >
                {quantity}
              </StyleQuantityText>
            </StyledSidebarItem>
            <ClinSpacer
              className="amount-spacer"
              hasBorder={true}
              height={ClinTheme.space[1]}
            />
            <StyledSidebarAmount isMinimized={!isShowingProductDetails}>
              <ClinText
                fontSize={ClinTheme.fontSizes[1]}
                fontWeight={ClinTheme.fontWeights.medium}
                color={ClinTheme.colors.primary}
                marginRight={ClinTheme.space[3]}
              >
                {t('opa_checkout:amount')}
              </ClinText>
              <ClinText
                fontSize={ClinTheme.fontSizes[4]}
                fontWeight={ClinTheme.fontWeights.medium}
                color={ClinTheme.colors.primary}
              >
                {numberToCurrencyString(
                  orderLine.totals.subTotal,
                  getBrowserLocale(),
                  {
                    style: 'currency',
                    currency: currencyCode,
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }
                )}
              </ClinText>
            </StyledSidebarAmount>
          </StyledOrderItemSidebar>
        </Col>
      </Row>
    </StyledOrderItem>
  )
}
