import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'
const tabletUpBreakpoint = ClinTheme.breakpoints[1]

export interface IStyledIconvalueCellProps {
  /** The maximum width of the title */
  maxTitleWidth?: number | string
}
export const StyledIconValueCell = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  paddingTop: `${ClinTheme.space[3]}px`,
  paddingBottom: `${ClinTheme.space[3]}px`,
  minHeight: ClinTheme.space[6],
  borderBottom: `solid 1px ${ClinTheme.colors.grey}`,
  [mediaQuery(tabletUpBreakpoint)]: {
    minHeight: ClinTheme.space[9],
    [`:last-child`]: {
      borderBottom: 'none'
    }
  }
})

export const StyledValue = styled.div({
  flex: '1 1 0%',
  [`.clin-text`]: {
    lineHeight: ClinTheme.lineHeights.small,
    marginTop: ClinTheme.space[2]
  }
})

export const StyledIconTitle = styled.div<IStyledIconvalueCellProps>(
  {
    display: 'inline-flex',
    marginRight: ClinTheme.space[2],

    [`.clin-text`]: {
      marginTop: ClinTheme.space[2],
      lineHeight: ClinTheme.lineHeights.small
    }
  },
  ({ maxTitleWidth = 180 }) => ({
    flex: `0 0 ${maxTitleWidth}px`
  })
)

export const StyledTitle = styled.div<IStyledIconvalueCellProps>({
  marginRight: ClinTheme.space[2],
  width: '100%',

  [`.clin-text`]: {
    marginTop: ClinTheme.space[2],
    lineHeight: ClinTheme.lineHeights.small
  }
})

export const StyledValueCell = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  paddingTop: `${ClinTheme.space[3]}px`,
  paddingBottom: `${ClinTheme.space[3]}px`,
  borderBottom: `solid 1px ${ClinTheme.colors.grey}`,
  [mediaQuery(tabletUpBreakpoint)]: {
    minHeight: ClinTheme.space[5]
  }
})
