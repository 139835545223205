import React, { FunctionComponent } from 'react'
import {
  FileInputLabel,
  IDocumentUploadStyleProps,
  StyledButtonArea,
  StyledDocumentIcon,
  StyledFileAndIcon,
  StyledFileName,
  StyledFileRow,
  StyledDocumentUpload
} from './DocumentUpload.styles'
import { ClinSpinner } from '../../components/ClinSpinner'
import { ClinButton } from '../../components/ClinButton'
import { ClinText } from '../../components/ClinText'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinCenterTruncatedText } from '../../components/ClinCenterTruncatedText'
import { OrgAddressDto } from '../../types/swaggerTypes'
import { ClinSpacer } from '../../components/ClinSpacer'
import { validateFileExtensionAllowed } from './DocumentUpload.model'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

export interface IUploadedDetails {
  /** Name of the person who uploaded the file */
  uploadedBy?: string
  /** Date the file was uploaded */
  uploadDate?: string
}

export interface DocumentUploadPromptProps {
  /** Title prompt describing the file that should be uploaded*/
  prompt?: string
  /** More detailed information on the file that should be uploaded */
  documentPrompt?: any
  /** Optional warehouse address */
  warehouseAddress?: OrgAddressDto
  /** Valid file extensions */
  accept?: string
  /** Name of the selected file */
  fileName?: string | null
  /** Show required flag */
  required?: boolean
  /** Render visually different version */
  isPreOrderVariant?: boolean
}

interface DocumentUploadProps
  extends IDocumentUploadStyleProps,
    DocumentUploadPromptProps {
  // TODO: This needs cleaning up - technical debt - we should be able to compose stuff into here
  /** Document details from a document which has already been uploaded */
  uploadDetails?: IUploadedDetails | null
  /** Whether this document is currently being uploaded */
  isUploading?: boolean
  /** Whether this document is currently being removed */
  isRemoving?: boolean
  /** Whether user can remove a file */
  showRemoveFile?: boolean
  /** custom style for AUS */
  styleForAUS?: boolean
  /** An error message for the file */
  error?: string
  /** Called when a file is selected */
  handleFileSelected?: (file: File) => void
  /** Called when the user wants to remove uploaded file */
  handleClickRemove?: () => void
}

export const DocumentUpload: FunctionComponent<DocumentUploadProps> = ({
  documentPrompt,
  prompt,
  isPreOrderVariant,
  warehouseAddress,
  styleForAUS,
  handleFileSelected,
  handleClickRemove,
  accept = '.docx,.doc,.pdf,.jpg,.jpeg',
  fileName,
  isUploading,
  isRemoving,
  showRemoveFile = false,
  uploadDetails,
  required,
  error,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <StyledDocumentUpload isPreOrderVariant={isPreOrderVariant} {...props}>
      <StyledFileRow
        isPreOrderVariant={isPreOrderVariant}
        styleForAUS={styleForAUS}
      >
        <ClinText
          marginBottom={fileName ? ClinTheme.space[3] : 0}
          className="clin-document-upload__prompt"
        >
          {prompt}
          {!fileName && required && (
            <strong> {t('common:document_upload.required')}</strong>
          )}
        </ClinText>
        {documentPrompt && (
          <ClinText
            as={'div'}
            whiteSpace={'pre-line'}
            className={'sku-information'}
            variant={TypographyVariant.LargeParagraph}
            color={ClinTheme.colors.black}
            fontWeight={ClinTheme.fontWeights.normal}
            marginBottom={ClinTheme.space[2]}
          >
            {documentPrompt}
          </ClinText>
        )}
        {warehouseAddress && (
          <>
            <ClinText
              as={'div'}
              className={'warehouse-address'}
              variant={TypographyVariant.LargeParagraph}
              color={ClinTheme.colors.black}
              fontWeight={ClinTheme.fontWeights.medium}
              marginBottom={ClinTheme.space[2]}
            >
              {warehouseAddress.address1}
              <br />
              {warehouseAddress.address2}
              {warehouseAddress.address3 && <br />}
              {warehouseAddress.address3}
              {warehouseAddress.address4 && <br />}
              {warehouseAddress.address4}
              {warehouseAddress.city && <br />}
              {warehouseAddress.city}
              <br />
              {warehouseAddress.postalCode}
              <br />
              {warehouseAddress.country}
            </ClinText>
            <ClinSpacer />
          </>
        )}
        <StyledButtonArea styleForAUS={styleForAUS}>
          {!fileName && !isUploading && !isRemoving && (
            <FileInputLabel>
              <ClinButton as="span" variant="link">
                {t('common:buttons.upload')}
              </ClinButton>
              <input
                type="file"
                style={{ display: 'none' }}
                accept={accept}
                onChange={(e) => {
                  const input = e.target as HTMLInputElement
                  const file = input.files && input.files[0]
                  if (file) {
                    const isAllowed = validateFileExtensionAllowed(
                      file.name,
                      accept
                    )
                    if (!isAllowed) {
                      window.alert(
                        `${t(
                          'common:validation_warnings.choose_valid_file_type'
                        )}: ${accept}`
                      )
                      return
                    }
                    isAllowed && handleFileSelected && handleFileSelected(file)
                  }
                }}
              />
            </FileInputLabel>
          )}
        </StyledButtonArea>
      </StyledFileRow>
      <StyledFileRow
        isPreOrderVariant={isPreOrderVariant}
        styleForAUS={styleForAUS}
      >
        {fileName && (
          <StyledFileAndIcon>
            <StyledDocumentIcon
              iconName={ClinIconPathName.Document}
              iconFill={ClinTheme.colors.primary}
            />
            <StyledFileName>
              <ClinText
                fontWeight={ClinTheme.fontWeights.bold}
                color={ClinTheme.colors.primary}
              >
                <ClinCenterTruncatedText text={fileName} />
              </ClinText>
            </StyledFileName>
          </StyledFileAndIcon>
        )}
        <StyledButtonArea styleForAUS={styleForAUS}>
          {fileName && !isUploading && !isRemoving && showRemoveFile && (
            <ClinButton
              as="span"
              onClick={handleClickRemove}
              variant="linkButton"
            >
              {t('common:buttons.remove_file')}
            </ClinButton>
          )}
          {(isUploading || isRemoving) && (
            <ClinSpinner
              size={ClinTheme.space[4]}
              fillColor={ClinTheme.colors.primary}
            />
          )}
        </StyledButtonArea>
      </StyledFileRow>
      {uploadDetails && (
        <>
          <ClinText as="div" marginTop={ClinTheme.space[2]}>
            {t('common:document_upload.uploaded_by', {
              name: uploadDetails.uploadedBy
            })}
          </ClinText>
          <ClinText as="div">
            {uploadDetails.uploadDate &&
              DateTime.fromISO(uploadDetails.uploadDate).toLocaleString()}
          </ClinText>
        </>
      )}
      {error && (
        <ClinText
          color={ClinTheme.colors.redInvalid}
          fontWeight={ClinTheme.fontWeights.bold}
        >
          {error}
        </ClinText>
      )}
    </StyledDocumentUpload>
  )
}
