import { IUser } from '../../types'
import config from '../ConfigProvider'

/**
 * Wootric async survey
 * https://docs.wootric.com/javascript/#react-example-using-component
 * @param user
 * @param orderIds
 */
export const showWootricSurvey = (
  user?: IUser,
  orderIds?: string,
  division?: string
  // languageCode?: string
) => {
  if (!user) return
  if (!config.wootricToken) {
    console.warn('Wootric token missing from config')
    return
  }
  let setupScript = document.createElement('script')
  setupScript.type = 'text/javascript'
  setupScript.id = 'wootric-settings'
  setupScript.async = true
  // TODO: Hook up language code in properties
  // languageCode: "${languageCode}"
  // TODO: Hook up creation date when it is decided upon
  setupScript.innerHTML = `
      wootric_no_surveyed_cookie = ${
        window.location.hostname === 'localhost'
      }; // Testing only - disables the cookie writing after a survey is taken effectively disabling any client side mechanisms to prevent multiple survey displays
      wootric_survey_immediately = ${
        window.location.hostname === 'localhost'
      }; // Testing only - override all your throttle settings and fire the survey every time you refresh the browser
      window.wootricSettings = {
        email: '${user?.profile.preferred_username}',
        ask_permission_to_share_feedback: true,
        created_at: ${parseInt((new Date().getTime() / 1000).toFixed(0))}, 
        account_token: '${config.wootricToken}',
        language: 'en',
        properties:{
          order_ids: "${orderIds}",
          division: "${division}"
        }
      };
    `
  if (document.getElementById('wootric-settings') == null) {
    document.body.appendChild(setupScript)
  }

  // Beacon
  let beacon = document.createElement('script')
  beacon.type = 'text/javascript'
  beacon.id = 'wootric-beacon'
  beacon.async = true
  beacon.src = 'https://cdn.wootric.com/wootric-sdk.js'
  beacon.onload = function () {
    window.wootric('run')
  }
  if (document.getElementById('wootric-beacon') == null) {
    document.body.appendChild(beacon)
  }
}
