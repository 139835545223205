import { StepState } from '../../../components/ClinStepper/ClinStepper'
import { IIndexable } from '../../../types'
export enum PatientTransferSteps {
  NONE,
  STEP1,
  STEP2,
  STEP3,
  STEP4
}

export enum InputFieldLength {
  MAX60 = 60,
  MAX80 = 80,
  MAX100 = 100,
  MAX240 = 240
}

export const patientSteps: IIndexable = {
  [PatientTransferSteps.STEP1]: [
    StepState.Active,
    StepState.InComplete,
    StepState.InComplete
  ],
  [PatientTransferSteps.STEP2]: [
    StepState.Complete,
    StepState.Active,
    StepState.InComplete
  ],
  [PatientTransferSteps.STEP3]: [
    StepState.Complete,
    StepState.Complete,
    StepState.Active
  ],
  [PatientTransferSteps.STEP4]: [
    StepState.Complete,
    StepState.Complete,
    StepState.Complete
  ]
}

export enum TransferPatientTo {
  PHYSICIAN = 'physician',
  INSTITUTE = 'institute'
}

export const defaultPhysicianParams = {
  /** New physician title uses for transferring patient. */
  newPhysicianTitle: '',
  /** New physician first name uses for transferring patient. */
  newPhysicianFirstName: '',
  /** New physician last name uses for transferring patient. */
  newPhysicianLastName: '',
  /** New physician email uses for transferring patient. */
  newPhysicianEmail: ''
}
