import styled from 'styled-components'

export const TableContainer = styled.div`
  display: table;
  width: 100%;
`

export const TableRow = styled.div`
  display: table-row;
`

export const TableHeader = styled(TableRow)`
  font-weight: bold;
  text-align: left;
`

export const TableCell = styled.div`
  display: table-cell;
  padding: 8px;
  text-align: left;
`

export const QuantityCell = styled(TableCell)`
  width: 10%;
`

export const SkuCell = styled(TableCell)`
  width: 60%;
`

export const SubtotalCell = styled(TableCell)`
  width: 30%;
`
