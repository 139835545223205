/*
 * Checks if content can be accessible based on the user's location and a list of allowed locations for the selected page
 * Used in NavbarContainer to show/hide dropdown element
 * Used in PrivateRoleRoute to allow/deny acces to a route
 */

import {
  UserRole,
  UserRoleWithCountry,
  getUserRoleWithCountry
} from '../constants'

export const getIsPageAccessibleFromCountry = (
  countryCodeToCheck: string,
  allowedCountryCodes?: string[],
  forbidForCountryCodes?: string[]
): boolean => {
  return checkAllowedCountryCodes(countryCodeToCheck, allowedCountryCodes) &&
    checkForbidenCountryCodes(countryCodeToCheck, forbidForCountryCodes)
    ? true
    : false
}

export const isRoleValid = (
  countryCodeToCheck: string,
  userRoleToCheck: UserRole,
  acceptedRoleTypes?: string[],
  countriesExcludedFromThisRule?: string[]
): boolean => {
  return countriesExcludedFromThisRule &&
    countriesExcludedFromThisRule?.includes(countryCodeToCheck)
    ? true
    : !!(acceptedRoleTypes && acceptedRoleTypes.includes(userRoleToCheck))
}

const checkAllowedCountryCodes = (
  countryCodeToCheck: string,
  allowedCountryCodes?: string[]
) => {
  return (
    !allowedCountryCodes ||
    (allowedCountryCodes.length &&
      allowedCountryCodes.includes(countryCodeToCheck))
  )
}

const checkForbidenCountryCodes = (
  countryCodeToCheck: string,
  forbidenCountryCodes?: string[]
) => {
  return (
    !forbidenCountryCodes ||
    (forbidenCountryCodes.length &&
      !forbidenCountryCodes.includes(countryCodeToCheck))
  )
}

//use this check because aus ga users can not see programs pages (related to clos-1610)
export const getIsPageAccessibleFromCountryAndRole = (
  countryCodeToCheck: string,
  roleToCheck: string,
  forbidForCountryCodes?: string[],
  forbidRoleTypes?: string[]
): boolean => {
  return !(
    forbidForCountryCodes?.includes(countryCodeToCheck) &&
    forbidRoleTypes?.includes(roleToCheck)
  )
}

// check if page is accessible according to enum UserRoleWithCountry from user.ts file.
export const getIsPageAccessibleFromUserRoleAndCountry = (
  countryCodeToCheck: string,
  roleToCheck: string,
  allowedUserRolesWithCountry: UserRoleWithCountry[]
): boolean => {
  const userRoleWithCountry = getUserRoleWithCountry(
    countryCodeToCheck,
    roleToCheck
  )
  if (userRoleWithCountry) {
    return allowedUserRolesWithCountry.includes(userRoleWithCountry)
  }

  return false
}
