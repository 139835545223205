import { IIndexable } from '../types'

/**
 * Gets a nested object back from a SquidEx component array given a key and search value to find
 * @param keyName
 * @param searchValue
 * @param squidExObj
 * @param returnValueField
 * @example:
 * const role: IRole = getSquidExObjectForKeyAndValue('key', 'XXCL_MVP_SUPER_USER', squidExRoles.roles) as IRole
 * const holdValueForRequiredDocument = getSquidExObjectForKeyAndValue(
 *       'type',
 *       'Commercial Supply Hold',
 *       squidExHolds.holds,
 *       'requiredDocumentName'
 *     )
 */
export const getSquidExObjectForKeyAndValue = (
  keyName: string,
  searchValue: string,
  squidExObj: IIndexable<IIndexable<Object>>,
  returnValueField?: string
): Object | string | undefined => {
  const keys = Object.keys(squidExObj)
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    if (squidExObj[key][keyName] === searchValue) {
      return returnValueField
        ? squidExObj[key][returnValueField]
        : squidExObj[key]
    }
  }
  return undefined
}
