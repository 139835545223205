/**
 * Validates the quantity is within a range and step size or returns null if no errors
 * @param quantity
 * @param minQuantity
 * @param maxQuantity
 * @param stepQuantity
 * @param isRestricted
 */
export const validateQuantity = (
  quantity: number,
  minQuantity: number | null,
  maxQuantity: number | null,
  stepQuantity: number | null,
  isRestricted: boolean = false
): string | null => {
  if (isRestricted && maxQuantity && quantity > maxQuantity) {
    return `Order exceeds available quantity`
  }
  // Validate quantities
  if (isNaN(quantity) || (!minQuantity && quantity === 0)) {
    return `A minimum quantity of at least 1 pack is required`
  }
  if (minQuantity && quantity < minQuantity) {
    return `A minimum quantity of at least ${minQuantity} pack${
      minQuantity === 1 ? '' : 's'
    } is required`
  }
  if (maxQuantity && quantity > maxQuantity) {
    return `This product is limited to ${maxQuantity} pack${
      minQuantity === 1 ? '' : 's'
    } per institution`
  }
  if (stepQuantity && quantity % stepQuantity !== 0) {
    return `This product must be ordered in multiples of ${stepQuantity} packs`
  }
  if (quantity > 10000) {
    return `This product is limited to 10,000 packs per institution`
  }
  return null
}
