import React, { Fragment, FunctionComponent } from 'react'
import { Row, Col, Container } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import {
  Accordion,
  AccordionContent,
  SupportMainPage,
  TopicTemplate,
  Video,
  VideoGuides,
  VideoRow
} from '../../types/ContentTypes'
import {
  StyledVideoSection,
  StyleSupportLinkCard,
  StyleSupportLinkCards
} from './Support.styles'
import { TopicLinkCard } from '../../features/TopLinkCard'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../components/ClinAccordion'
import { ClinButton } from '../../components/ClinButton'
import config from '../../services/ConfigProvider'
import { useTranslation } from 'react-i18next'
import { UserRole, isAusGaUser } from '../../constants'
import { cloneDeep } from 'lodash'
import { useCheckUnableHomePage } from '../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'

interface ISupportProps {
  /** A list of available topics */
  supportTopics: TopicTemplate[]
  /** The main content of the page */
  content: SupportMainPage
  /** User country*/
  userCountry: string
  /** User country*/
  userRole?: UserRole
  /** A function which is called when a user requests to go back to another page */
  handlePageChangeRequest?: (path: string) => void
}

export const sortTopics = (topics: TopicTemplate[]) => {
  return topics
    .slice()
    .sort((a: TopicTemplate, b: TopicTemplate) =>
      a?.flatData?.sortOrderValue &&
      b?.flatData?.sortOrderValue &&
      a.flatData.sortOrderValue > b.flatData.sortOrderValue
        ? 1
        : -1
    )
}

export const Support: FunctionComponent<ISupportProps> = ({
  supportTopics,
  content,
  userCountry,
  userRole,
  handlePageChangeRequest
}) => {
  const { t } = useTranslation()
  const { enableNewHomePage } = useCheckUnableHomePage()
  let Content = cloneDeep(content)
  let SupportTopics = cloneDeep(supportTopics)

  const accordionBlocks = Content?.flatData?.contentBlocks?.filter(
    (item) => item.__typename === 'Accordion'
  ) as Accordion[]

  const allVideoGuides = Content?.flatData?.contentBlocks?.filter(
    (item) => item.__typename === 'VideoGuides'
  ) as VideoGuides[]

  const videoGuides = cloneDeep(allVideoGuides)

  let topics = sortTopics(SupportTopics)

  if (isAusGaUser(userCountry, userRole)) {
    topics = topics.filter((x) => x.flatData.slug !== 'access-program')
  }
  return (
    <ClinPageContentFrame
      isFullWidth={true}
      crumbs={[
        isAusGaUser(userCountry, userRole) && enableNewHomePage
          ? {
              path: '/home',
              name: t('navigation:home')
            }
          : { path: '/orders', name: t('orders:page_name') },
        {
          path: '/support',
          name: Content?.flatData?.title ? Content?.flatData?.title : ''
        }
      ]}
    >
      <Container>
        <Row>
          <Col>
            <ClinText
              as="h1"
              fontWeight={ClinTheme.fontWeights.bold}
              variant={TypographyVariant.H2}
            >
              {Content?.flatData?.title}
            </ClinText>
          </Col>
        </Row>
        <ClinSpacer height={ClinTheme.space[5]} />
        <Row>
          <Col sm={12}>
            <ClinText
              as="h2"
              fontWeight={ClinTheme.fontWeights.bold}
              variant={TypographyVariant.H4}
              marginTop="0"
            >
              {Content?.flatData?.topics}
            </ClinText>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <StyleSupportLinkCards>
              {topics.map((item: TopicTemplate) => {
                const { icon, title, slug } = item.flatData
                return (
                  <Fragment key={`support-content-${item.id}`}>
                    <StyleSupportLinkCard>
                      {title && (
                        <TopicLinkCard
                          title={title}
                          handlePageChangeRequest={() =>
                            handlePageChangeRequest &&
                            handlePageChangeRequest(`/support/${slug}`)
                          }
                          iconName={icon as ClinIconPathName}
                        />
                      )}
                    </StyleSupportLinkCard>
                  </Fragment>
                )
              })}
            </StyleSupportLinkCards>
          </Col>
        </Row>
        <ClinSpacer height={ClinTheme.space[6]} />

        {accordionBlocks?.map((item: Accordion) => {
          return (
            <Row key={`accordion-${item.id}`}>
              {item.flatData.title && (
                <Col sm={12}>
                  <ClinText
                    as="h3"
                    fontWeight={ClinTheme.fontWeights.bold}
                    variant={TypographyVariant.H4}
                    marginBottom={ClinTheme.space[7]}
                  >
                    {item.flatData.title}
                  </ClinText>
                </Col>
              )}
              <Col sm={12}>
                {item.flatData.accordionItems &&
                  item.flatData.accordionItems.length > 0 && (
                    <ClinAccordion>
                      {item.flatData.accordionItems.map(
                        (accordionItem: AccordionContent) => {
                          return (
                            <ClinAccordionItem
                              key={`accordion-item-${accordionItem.id}`}
                              title={accordionItem.flatData.title ?? ''}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: `${accordionItem.flatData.content}`
                                }}
                              />
                            </ClinAccordionItem>
                          )
                        }
                      )}
                    </ClinAccordion>
                  )}
              </Col>
            </Row>
          )
        })}
      </Container>

      <ClinSpacer height={ClinTheme.space[5]} />

      {!isAusGaUser(userCountry, userRole) &&
        videoGuides?.map((item: VideoGuides) => {
          return (
            <Fragment key={`video-guide-${item.id}`}>
              <Container>
                <Row>
                  <Col sm={12}>
                    <ClinText
                      as="h3"
                      fontWeight={ClinTheme.fontWeights.bold}
                      variant={TypographyVariant.H4}
                      marginBottom={ClinTheme.space[7]}
                    >
                      {item.flatData.title}
                    </ClinText>
                  </Col>
                </Row>
              </Container>
              {item.flatData.videoRows &&
                item.flatData.videoRows.length > 0 &&
                item.flatData.videoRows.map((row: VideoRow, index) => {
                  return (
                    <StyledVideoSection
                      key={`accordion-${row.id}`}
                      backgroundColor={
                        (index + 1) % 2 > 0
                          ? ClinTheme.colors.lightGrey
                          : ClinTheme.colors.white
                      }
                    >
                      <Container>
                        <Row>
                          <Col sm={3}>
                            <ClinText
                              as="h3"
                              variant={TypographyVariant.H4}
                              marginBottom={ClinTheme.space[7]}
                            >
                              {row.flatData.title}
                            </ClinText>
                          </Col>
                          <Col sm={9}>
                            <Row>
                              {row?.flatData?.videos?.map((video: Video) => {
                                return (
                                  <Col
                                    sm={6}
                                    key={`video-thumnail-${video.id}`}
                                  >
                                    <ClinButton
                                      as="a"
                                      onClick={() =>
                                        handlePageChangeRequest &&
                                        handlePageChangeRequest(
                                          `/support/video-guide/${video.flatData.vimeoId}`
                                        )
                                      }
                                      variant="linkButton"
                                    >
                                      {video?.flatData?.vimeoThumbnail?.[0] && (
                                        <img
                                          src={`${config.cmsRoot}/api/assets/${config.squidexRestfulInstanceName}/${video.flatData.vimeoThumbnail[0].id}`}
                                          width="100%"
                                          alt={
                                            video.flatData.vimeoThumbnail[0]
                                              .fileName ?? ''
                                          }
                                        />
                                      )}
                                    </ClinButton>
                                    <ClinText
                                      fontWeight={ClinTheme.fontWeights.medium}
                                      marginTop={ClinTheme.space[2]}
                                      marginBottom={ClinTheme.space[4]}
                                    >
                                      {video.flatData.shortDescription}
                                    </ClinText>
                                  </Col>
                                )
                              })}
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    </StyledVideoSection>
                  )
                })}
            </Fragment>
          )
        })}
    </ClinPageContentFrame>
  )
}
