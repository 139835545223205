import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  StyledSearchWrapperForAUS,
  StyledGlobalSearchforAUS,
  StyledSearchInputAus,
  StyledResultsAus
} from './GlobalSearchAUS.styles'
import { StyledResultsWithNewFeature } from './GlobalSearch.styles'
import { ClinTheme } from '../../../ClinTheme'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { IAutoSuggestProps } from '../AutoSuggest/AutoSuggest.model'
import { useTranslation } from 'react-i18next'
import { StyledSearchIcon } from '../../../pages/Homepage/Homepage.styles'
import { useAppContext } from '../../../context/app'
import { useNewFeatures, removeFeature } from '../../../context/newFeatures'
import { useNewFeaturesList } from '../../../hooks/useNewFeaturesList/useNewFeaturesList'
import {
  NewFeatureElements,
  PageNames
} from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { ClinNewFeatureTooltipContainer } from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'

export const GlobalSearchAUS: FunctionComponent<IAutoSuggestProps> = ({
  query = '',
  suggestions = [],
  isLoading,
  showSuggestions,
  handleChange,
  handleKeyDown,
  handleEnter,
  renderSuggestions
}) => {
  const { t } = useTranslation()
  const { userDetails } = useAppContext()
  const [, dispatch] = useNewFeatures()

  const localStorageKey = userDetails ? userDetails.contactCard.name : ''

  const { newFeaturesList } = useNewFeaturesList(PageNames.GlobalSearch)

  const newUserFastTrack =
    userDetails?.contactCard.name &&
    localStorage.getItem(userDetails?.contactCard.name)

  const [showFeature, setShowFeature] = useState<boolean>(false)

  const checkDisplayFeature = () => {
    const featureExists: boolean =
      !!newFeaturesList[NewFeatureElements.GlobalSearchNewUserFeature]
    setShowFeature(featureExists && !newUserFastTrack)
  }

  useEffect(() => {
    checkDisplayFeature()
  }, [localStorage.getItem(localStorageKey), newFeaturesList])

  const handleSearchInputClick = () => {
    if (newFeaturesList[NewFeatureElements.GlobalSearchNewUserFeature]) {
      // Remove focus from input
      const input = document.querySelector(
        '.clin-global-search-input'
      ) as HTMLInputElement
      input && input.blur()
      removeFeature(
        dispatch,
        newFeaturesList[NewFeatureElements.GlobalSearchNewUserFeature].id
      )
    }
  }

  return (
    <div>
      <StyledGlobalSearchforAUS>
        <StyledSearchWrapperForAUS
          showSuggestions={showSuggestions}
          className={'clin-global-search-input-wrapper'}
          onKeyDown={handleKeyDown}
          style={{
            zIndex:
              showFeature || showSuggestions
                ? ClinTheme.zIndices.dropdown
                : 'unset'
          }}
        >
          {newFeaturesList[NewFeatureElements.GlobalSearchNewUserFeature] &&
            !newUserFastTrack && (
              <div
                style={{
                  position: 'absolute',
                  // zIndex: showFeature ? ClinTheme.zIndices.dropdown : 'unset',
                  top: '15px',
                  left: '-17px'
                }}
              >
                <ClinNewFeatureTooltipContainer
                  feature={
                    newFeaturesList[
                      NewFeatureElements.GlobalSearchNewUserFeature
                    ]
                  }
                  openTooltipInitially={true}
                  disableDismiss={true}
                  disableClinFeature={true}
                  total={2}
                  current={1}
                />
              </div>
            )}
          <StyledSearchInputAus
            className={'clin-global-search-input'}
            showSuggestions={showSuggestions}
            showGlowEffect={showFeature}
            placeholder={t('global_search:global_search_placeholder')}
            value={query}
            onChange={handleChange}
            onKeyDown={(event) => {
              handleEnter && event.keyCode === 13 && handleEnter(event)
            }}
            onClick={handleSearchInputClick}
            style={{
              pointerEvents: 'auto',
              zIndex: showFeature ? ClinTheme.zIndices.dropdown : 'unset'
            }}
          />

          {isLoading && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '20px'
              }}
            >
              <ClinSpinner size={ClinTheme.space[3]} />
            </div>
          )}
          <StyledSearchIcon>
            <ClinIcon
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                color: ClinTheme.colors.black // Change color to black
              }}
              iconHeight={'30'} // Increase the height
              iconSize={'30'} // Increase the size
              iconName={ClinIconPathName.Search}
            />
          </StyledSearchIcon>
          {showFeature ? (
            <StyledResultsWithNewFeature showSuggestions={showSuggestions}>
              {suggestions &&
                renderSuggestions &&
                renderSuggestions(suggestions)}
            </StyledResultsWithNewFeature>
          ) : (
            <StyledResultsAus showSuggestions={showSuggestions}>
              {suggestions &&
                renderSuggestions &&
                renderSuggestions(suggestions)}
            </StyledResultsAus>
          )}
        </StyledSearchWrapperForAUS>
      </StyledGlobalSearchforAUS>
    </div>
  )
}
