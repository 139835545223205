import React from 'react'
import { ClinModal } from '../../../../components/ClinModal'
import { t } from 'i18next'
import { ClinTheme } from '../../../../ClinTheme'
import {
  StyledButtonContainer,
  StyledCancelButton,
  StyledDeleteButton,
  StyledHeaderText,
  StyledModalContent,
  StyledSuggestionText
} from './PatientDeleteModal.styles'
import { StyledOverlayLoader } from '../../../OpaCheckout/DeletePAFModal/DeletePAFModal.styles'
import { Col, Container, Row } from 'react-grid-system'
import { ClinText } from '../../../../components/ClinText'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'
import { ClinGroup } from '../../../../components/ClinGroup'
import { ClinLoader } from '../../../../components/ClinLoader'
import { ClinSpacer } from '../../../../components/ClinSpacer'

interface IPatientDeleteModalProps {
  isOpen: boolean
  isSubmitting: boolean
  handleDelete: () => void
  handleClose: () => void
}

export const PatientDeleteModal: React.FC<IPatientDeleteModalProps> = ({
  isOpen,
  isSubmitting,
  handleDelete,
  handleClose
}) => {
  return (
    <ClinModal
      isOpen={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      height="auto"
      padding={`${ClinTheme.space[5]}px`}
      borderRadius="4px"
    >
      <StyledModalContent>
        <StyledHeaderText as="div">
          {t('patient_detail:delete_patient_modal.modal_title')}
        </StyledHeaderText>
        <StyledSuggestionText as="div">
          {t('patient_detail:delete_patient_modal.modal_description')}
        </StyledSuggestionText>
        <StyledButtonContainer>
          <StyledCancelButton
            variant="purpleSecondaryNarrow"
            onClick={handleClose}
          >
            {t('patient_detail:delete_patient_modal.cancel_button_text')}
          </StyledCancelButton>
          <StyledDeleteButton variant="purpleNarrow" onClick={handleDelete}>
            {t('patient_detail:delete_patient_modal.delete_button_text')}
          </StyledDeleteButton>
        </StyledButtonContainer>
      </StyledModalContent>
      {isSubmitting && (
        <>
          <StyledOverlayLoader>
            <Container>
              <Row justify="center" align="center">
                <Col sm={12}>
                  <ClinText
                    marginLeft="auto"
                    marginRight="auto"
                    marginBottom={ClinTheme.space[5]}
                    textAlign="center"
                    variant={TypographyVariant.LargeParagraph}
                  >
                    {t('physician_details:please_wait')}
                  </ClinText>
                  <ClinGroup justifyContent="center">
                    <div
                      style={{
                        height: ClinTheme.space[9] * 2,
                        width: ClinTheme.space[9] * 2
                      }}
                    >
                      <ClinLoader minHandDuration={'1s'} />
                    </div>
                  </ClinGroup>
                </Col>
              </Row>
            </Container>
          </StyledOverlayLoader>
          <ClinSpacer height={24} />
        </>
      )}
    </ClinModal>
  )
}
