import i18next from 'i18next'
import { IIndexable } from '../../types'

export enum PlaceholderType {
  SelectInput,
  TextInput,
  physicianSearch,
  programSearch
}

/**
 * Gets a translated placeholder string for a specific placeholder type
 * @param inputType - Required - Selects the required placeholder type
 * @example:
 * getInputPlaceholder(PlaceholderType.Required)
 */
export const getInputPlaceholder = (inputType: PlaceholderType): string => {
  const placeholder: IIndexable = {
    [PlaceholderType.SelectInput]: i18next.t('forms:placeholders.select_input'),
    [PlaceholderType.TextInput]: i18next.t('forms:placeholders.text_input'),
    [PlaceholderType.physicianSearch]: i18next.t(
      'forms:placeholders.physician_search'
    ),
    [PlaceholderType.programSearch]: i18next.t(
      'forms:placeholders.program_search'
    )
  }
  return placeholder[inputType]
}
