import { useState, useCallback } from 'react'
import {
  ValidationType,
  getInputValidation
} from '../../utils/Forms/getInputValidation'

const useEmailValidation = () => {
  const emailValidationText = getInputValidation(ValidationType.Email)
  const [emailErrorMessage, setEmailErrorMessage] = useState<
    string | undefined
  >(undefined)

  const getEmailErrorMessage = useCallback(
    (email: string) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      const isValidEmail = email === '' || emailRegex.test(email)
      if (!isValidEmail) {
        return emailValidationText
      }
      return undefined
    },
    [emailValidationText]
  )

  const checkEmail = useCallback(
    (email: string) => {
      const errorMessage = getEmailErrorMessage(email)
      setEmailErrorMessage(errorMessage)
      return !!errorMessage
    },
    [getEmailErrorMessage]
  )

  return {
    emailErrorMessage,
    checkEmail
  }
}

export default useEmailValidation
