import React, { FunctionComponent, ChangeEvent, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { ClinTheme } from '../../ClinTheme'
import { physicianTitleRecord, PhysicianTitles } from '../../constants'
import { NewPhysicianEnrollDto } from '../../types/swaggerTypes'
import { InputFieldLength } from '../TransferPatient/models/TransferPatientContainer.model'
import { Row, Col } from 'react-grid-system'
import { ClinSelect } from '../../components/ClinSelect'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { ClinTextInput } from '../../components/ClinTextInput'
import { ClinButton } from '../../components/ClinButton'
import {
  StyledEnrolPhysicianDetailsForm,
  StyledForm
} from './EnrolPhysicianOnToProgram.styles'
import {
  getInputValidation,
  ValidationType
} from '../../utils/Forms/getInputValidation'
import { t } from 'i18next'
import useEmailValidation from '../../hooks/useCheckEmailError/useCheckEmailError'

interface IPhysicianDetailsProps {
  /** Save physician details */
  handleSavedPhysicianDetails: (data: NewPhysicianEnrollDto) => void
  /** Handle whether the form is edited or not */
  handleIsFormEdited: (isEdited: boolean) => void
}

const defaultState: NewPhysicianEnrollDto = {
  title: PhysicianTitles.DR,
  firstName: '',
  lastName: '',
  emailAddress: ''
}

export const EnrolPhysicianDetailsForm: FunctionComponent<
  IPhysicianDetailsProps
> = ({ handleSavedPhysicianDetails, handleIsFormEdited }) => {
  const requiredTextValidation = getInputValidation(
    ValidationType.RequiredField
  )
  const PhysicianDetailsSchema = object().shape({
    title: string(),
    firstName: string().required(requiredTextValidation),
    lastName: string().required(requiredTextValidation),
    emailAddress: string().required(requiredTextValidation)
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    reset,
    control
  } = useForm<NewPhysicianEnrollDto>({
    defaultValues: defaultState,
    mode: 'onChange',
    resolver: yupResolver(PhysicianDetailsSchema)
  })

  const { emailErrorMessage, checkEmail } = useEmailValidation()

  useEffect(() => {
    handleIsFormEdited(Object.keys(formState.dirtyFields).length > 0)
  }, [formState, handleIsFormEdited])

  return (
    <StyledForm
      onSubmit={handleSubmit((values) => {
        let isEmailError = values.emailAddress
          ? checkEmail(values.emailAddress)
          : false
        if (!isEmailError) {
          handleSavedPhysicianDetails(values)
          reset()
        }
      })}
    >
      <Row>
        <Col sm={12} md={9}>
          <ClinText
            fontWeight={ClinTheme.fontWeights.bold}
            marginBottom={ClinTheme.space[4]}
          >
            {t('enrol_physician_on_to_program:enter_details_of_physician')}
          </ClinText>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={9}>
          <StyledEnrolPhysicianDetailsForm>
            <Row gutterWidth={ClinTheme.space[3]}>
              <Col md={2}>
                <Controller
                  name={'title'}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ClinSelect
                      id="title"
                      label={t('common:labels.title')}
                      title={t('common:labels.title')}
                      width="100%"
                      value={value}
                      onChange={(changeValue: ChangeEvent<HTMLSelectElement>) =>
                        onChange(changeValue.currentTarget.value)
                      }
                      hasError={!!(errors && errors.title)}
                      prompt={errors.title?.message}
                    >
                      {Object.values(PhysicianTitles).map(
                        (val: string, idx) => {
                          return (
                            <option key={`title-names-${idx}`} value={val}>
                              {t(
                                `${
                                  physicianTitleRecord[val as PhysicianTitles]
                                    .transKey
                                }`
                              )}
                            </option>
                          )
                        }
                      )}
                    </ClinSelect>
                  )}
                />
              </Col>
              <Col md={3}>
                <ClinTextInput
                  id="first-name"
                  {...register('firstName')}
                  label={t('common:labels.first_name')}
                  width="100%"
                  maxLength={InputFieldLength.MAX80}
                  hasError={!!(errors && errors.firstName)}
                  prompt={errors.firstName?.message}
                />
              </Col>
              <Col md={3}>
                <ClinTextInput
                  id="last-name"
                  {...register('lastName')}
                  label={t('common:labels.last_name')}
                  width="100%"
                  maxLength={InputFieldLength.MAX80}
                  hasError={!!(errors && errors.lastName)}
                  prompt={errors.lastName?.message}
                />
              </Col>
              <Col md={4}>
                <ClinTextInput
                  id="email"
                  {...register('emailAddress')}
                  label={t('common:labels.email')}
                  width="100%"
                  maxLength={InputFieldLength.MAX100}
                  hasError={!!emailErrorMessage}
                  prompt={emailErrorMessage}
                />
              </Col>
            </Row>
          </StyledEnrolPhysicianDetailsForm>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[4]} />
      <Row>
        <Col sm={12} md={9} lg={6}>
          <ClinButton
            variant="primary"
            disabled={!formState.isValid}
            type="submit"
          >
            {t('enrol_physician_on_to_program:add_physician')}
          </ClinButton>
        </Col>
      </Row>
    </StyledForm>
  )
}
