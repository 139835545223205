import React, { FunctionComponent, useRef } from 'react'
import {
  StyledClinDropdown,
  StyledClinDropdownContainer
} from './ClinDropdown.styles'
import { IDefaultStyleProps } from '../../types'
import { useDropdown } from './use-dropdown'

export interface IDropdownStyles {
  /** Aligns the menu */
  alignment?: 'left' | 'right'
  /** Sets the dropdown open by default */
  isOpen?: boolean
  isMyAccount?: boolean
}

interface IClinDropdownProps extends IDefaultStyleProps, IDropdownStyles {
  /** Exposes the internal state of the dropdown */
  onOpen?: (isDropdownOpen: boolean) => void
  /** React element to act as trigger */
  trigger(): React.ReactElement
  /** Whether to show the hover state */
  showHover?: boolean
  isDisabled?: boolean
  isMyAccount?: boolean
}

export const ClinDropdown: FunctionComponent<IClinDropdownProps> = ({
  className = '',
  trigger,
  isOpen = false,
  alignment = 'left',
  showHover = false,
  onOpen,
  children,
  isMyAccount = false,
  isDisabled
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const [isDropdownOpen] = useDropdown(
    dropdownRef,
    isOpen,
    showHover,
    onOpen,
    isDisabled
  )

  return (
    <StyledClinDropdown
      ref={dropdownRef}
      className={`clin-dropdown ${className}`}
      isMyAccount={isMyAccount}
    >
      <>{trigger()}</>
      <StyledClinDropdownContainer
        className={`clin-dropdown-container`}
        alignment={alignment}
        isOpen={isDropdownOpen}
      >
        {children}
      </StyledClinDropdownContainer>
    </StyledClinDropdown>
  )
}
