import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import {
  ProgramAvailability,
  ProgramCountryStatus,
  ProgramStatus
} from '../../../constants'
import { useAppContext } from '../../../context/app'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import { ICustomSelectOption } from '../../../features/CustomSelect/CustomSelect'
import { useGetEnrolledPhysicians } from '../../../hooks/useGetEnrolledPhysicians/useGetEnrolledPhysicians'
import {
  getProgramFromSearchIndexById,
  programsEnrolled,
  cancelGetProgramFromSearchIndexById,
  cancelProgramsEnrolled
} from '../../../services/ApiService'

import {
  findProgramAvailability,
  findProgramCountryStatus,
  findProgramStatus,
  ProgramTranslatedStatusRecord
} from '../../../types'
import {
  DocumentSearchResultDto,
  FullProgramDto,
  OrgAddressDto,
  PaginationResultDto,
  PhysiciansEnrolledSummaryDto,
  ProgramCatalogDto,
  ProgramCountryDto,
  ProgramSummaryDto
} from '../../../types/swaggerTypes'

export enum SideBarMode {
  Enrolled = 'Enrolled',
  NotEnrolled = 'Not Enrolled',
  NotAvailableInYourCountry = 'Not available in your country',
  Closed = 'Closed'
}

export interface ProgramUiState {
  programUiStatus:
    | ProgramStatus
    | ProgramAvailability
    | ProgramCountryStatus
    | undefined
  sideBarMode: SideBarMode | undefined
  hasProgramCharge: boolean | undefined
  statusText: string
}
export const findCountryProgram = (
  programCountries: ProgramCountryDto[] | undefined,
  defaultShippingAddress: OrgAddressDto
) => {
  return programCountries?.find(
    (programCountry) =>
      programCountry.countryId === defaultShippingAddress.country
  )
}
export const determineProgramStatus = (program: FullProgramDto | undefined) => {
  return program?.programStatus
    ? findProgramStatus[program.programStatus.toUpperCase()]
    : ProgramStatus.Unknown
}
export const determineProgramAvailability = (
  availabilty: string | undefined
) => {
  return availabilty
    ? findProgramAvailability[availabilty.toUpperCase()]
    : ProgramAvailability.Unknown
}
export const determineProgramCountryStatus = (
  foundCountryProgram: ProgramCountryDto | undefined
) => {
  return foundCountryProgram?.countryProgramStatus
    ? findProgramCountryStatus[
        foundCountryProgram.countryProgramStatus.toUpperCase()
      ]
    : ProgramCountryStatus.Unknown
}
/**
 * Get program status to be displayed in the ui
 */
export const getDisplayedProgramStatus = (
  programCatalogue: ProgramCatalogDto,
  defaultShippingAddress: OrgAddressDto,
  availabilty?: string
): ProgramUiState => {
  const { program, programCountries } = programCatalogue

  const foundCountryProgram = findCountryProgram(
    programCountries,
    defaultShippingAddress
  )
  const programStatus = determineProgramStatus(program)
  const programAvailability = determineProgramAvailability(availabilty)

  const programCountryStatus =
    determineProgramCountryStatus(foundCountryProgram)

  const hasProgramCharge =
    foundCountryProgram?.programChargeType?.toUpperCase() !== 'FREE OF CHARGE'

  if (
    programStatus === ProgramStatus.Open &&
    programAvailability === ProgramAvailability.Enrol &&
    (programCountryStatus === ProgramCountryStatus.AcceptingNewPatients ||
      programCountryStatus === ProgramCountryStatus.OpenForResupply)
  ) {
    return {
      programUiStatus: programCountryStatus,
      sideBarMode: SideBarMode.Enrolled,
      hasProgramCharge: hasProgramCharge,
      statusText: t(
        ProgramTranslatedStatusRecord[programCountryStatus].translationKey
      )
    }
  } else if (
    programStatus === ProgramStatus.Open &&
    programAvailability === ProgramAvailability.NotEnrolled &&
    (programCountryStatus === ProgramCountryStatus.AcceptingNewPatients ||
      programCountryStatus === ProgramCountryStatus.OpenForResupply)
  ) {
    return {
      programUiStatus: programCountryStatus,
      sideBarMode: SideBarMode.NotEnrolled,
      hasProgramCharge: false,
      statusText: t(
        ProgramTranslatedStatusRecord[programCountryStatus].translationKey
      )
    }
  } else if (
    programStatus === ProgramStatus.Open &&
    programAvailability === ProgramAvailability.Unknown &&
    (programCountryStatus === ProgramCountryStatus.AcceptingNewPatients ||
      programCountryStatus === ProgramCountryStatus.OpenForResupply)
  ) {
    return {
      programUiStatus: programCountryStatus,
      sideBarMode: SideBarMode.NotEnrolled,
      hasProgramCharge: false,
      statusText: t(
        ProgramTranslatedStatusRecord[programCountryStatus].translationKey
      )
    }
  } else if (
    programStatus === ProgramStatus.Open &&
    programAvailability === ProgramAvailability.NotAvailableInYourCountry &&
    (programCountryStatus === ProgramCountryStatus.AcceptingNewPatients ||
      programCountryStatus === ProgramCountryStatus.OpenForResupply ||
      programCountryStatus === ProgramCountryStatus.Unknown)
  ) {
    return {
      programUiStatus: ProgramAvailability.NotYetAvailableInYourCountry,
      sideBarMode: SideBarMode.NotAvailableInYourCountry,
      hasProgramCharge: false,
      statusText: t(
        ProgramTranslatedStatusRecord[
          ProgramAvailability.NotYetAvailableInYourCountry
        ].translationKey
      )
    }
  } else if (
    programStatus === ProgramStatus.Open &&
    programAvailability === ProgramAvailability.Unknown &&
    programCountryStatus === ProgramCountryStatus.Unknown
  ) {
    return {
      programUiStatus: ProgramStatus.Open,
      sideBarMode: SideBarMode.NotAvailableInYourCountry,
      hasProgramCharge: false,
      statusText: t(
        ProgramTranslatedStatusRecord[ProgramStatus.Open].translationKey
      )
    }
  } else
    return {
      programUiStatus: ProgramCountryStatus.Closed,
      sideBarMode: SideBarMode.Closed,
      hasProgramCharge: hasProgramCharge,
      statusText: t(
        ProgramTranslatedStatusRecord[ProgramStatus.Closed].translationKey
      )
    }
}
export const getShowRWDCard = (program: ProgramCatalogDto | undefined) => {
  let shouldShowRWDCard =
    (program?.program?.realWorldDataFlag === 'Y' ||
      program?.program?.realWorldDataFlag === 'Yes') &&
    (program.programCountry?.realWorldDataFlag === 'Yes' ||
      program.programCountry?.realWorldDataFlag === 'Y')
  return shouldShowRWDCard
}
export enum AssociateMode {
  active,
  submitting,
  hasError
}

export interface IProgram {
  isProgramLoading: boolean
  isEnrolledProgramsLoading: boolean
  enrolledPrograms: ProgramSummaryDto[] | undefined | null
  programError: boolean
  program: ProgramCatalogDto | undefined
  programUiState: ProgramUiState | undefined
  defaultShippingAddress: OrgAddressDto | undefined
}

/**
 * Custom hook that gets a program detail, calculates program status,
 * @param programId
 */
export const useProgramStatus = (programId: string): IProgram => {
  const { dispatch, defaultShippingAddress } = useAppContext()
  const [isProgramLoading, setIsProgramLoading] = useState<boolean>(true)
  const [isEnrolledProgramsLoading, setIsEnrolledProgramsLoading] =
    useState<boolean>(true)

  const [programError, setProgramError] = useState<boolean>(false)
  const [program, setProgram] = useState<ProgramCatalogDto | undefined>()
  const [enrolledPrograms, setEnrolledPrograms] = useState<
    ProgramSummaryDto[] | null
  >()
  const [programUiState, setProgramUiState] = useState<
    ProgramUiState | undefined
  >()

  useEffect(() => {
    if (programId && defaultShippingAddress) {
      getProgramFromSearchIndexById(programId)
        .then((response) => {
          const program = response.data
          setProgram(program)
          if (
            program.program?.programStatus === null ||
            (program.program?.programStatus &&
              program.program.programStatus?.toUpperCase() ===
                ProgramStatus.Closed.toUpperCase())
          ) {
            setProgramUiState(
              getDisplayedProgramStatus(program, defaultShippingAddress)
            )
            setIsEnrolledProgramsLoading(false)
          }
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching the program detail. ${error}`
            )
          )
          setProgramError(true)
        })
        .finally(() => setIsProgramLoading(false))
    }

    return () => {
      cancelGetProgramFromSearchIndexById()
    }
  }, [defaultShippingAddress, dispatch, programId])

  useEffect(() => {
    isEnrolledProgramsLoading &&
      !isProgramLoading &&
      programsEnrolled({
        query: '',
        filter: {},
        pagination: {
          take: 100,
          skip: 0
        },
        sorting: {
          sortBy: 'programName',
          order: 'ASC'
        }
      })
        .then((response) => {
          setEnrolledPrograms(response?.data.result)
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching the program detail. ${error}`
            )
          )
          setProgramError(true)
        })
        .finally(() => setIsEnrolledProgramsLoading(false))

    return () => {
      cancelProgramsEnrolled()
    }
  }, [dispatch, isEnrolledProgramsLoading, isProgramLoading])

  useEffect(() => {
    if (
      defaultShippingAddress &&
      program &&
      !isEnrolledProgramsLoading &&
      !isProgramLoading
    ) {
      const enrolledProgram = enrolledPrograms?.find(
        (enrolledProgram) => enrolledProgram.programId === program?.projectId
      )
      const programState = getDisplayedProgramStatus(
        program,
        defaultShippingAddress,
        enrolledProgram?.availability
      )
      setProgramUiState(programState)
    }
  }, [
    defaultShippingAddress,
    enrolledPrograms,
    isEnrolledProgramsLoading,
    isProgramLoading,
    program
  ])

  return {
    isProgramLoading,
    isEnrolledProgramsLoading,
    enrolledPrograms,
    programError,
    program,
    programUiState,
    defaultShippingAddress
  }
}

export interface IPhysician {
  physiciansLoading: boolean
  physiciansError: boolean
  physicians: PhysiciansEnrolledSummaryDto[] | undefined
  selectedPhysician: PhysiciansEnrolledSummaryDto | undefined
  handlePhysicianSelect: (option: ICustomSelectOption) => void
}

export const usePhysicians = (
  programId: string,
  isStatusReady: boolean
): IPhysician => {
  const [selectedPhysician, setSelectedPhysician] = useState<
    PhysiciansEnrolledSummaryDto | undefined
  >()

  const { physicians, physiciansError, physiciansLoading } =
    useGetEnrolledPhysicians(programId, isStatusReady)

  const handlePhysicianSelect = (option: ICustomSelectOption): void => {
    const foundPhysician: PhysiciansEnrolledSummaryDto | undefined =
      physicians?.find((item) => item.physicianId === option.id)
    setSelectedPhysician(
      foundPhysician &&
        physicians &&
        foundPhysician.physicianId !== selectedPhysician?.physicianId
        ? physicians.find((item) => item.physicianId === option.id)
        : undefined
    )
  }

  return {
    physiciansLoading,
    physiciansError,
    physicians,
    selectedPhysician,
    handlePhysicianSelect
  }
}

export interface TabModel {
  linkText: string
  showCountNumber?: boolean
  countNumber?: number
  tabIndex: number
  active?: boolean
  onClick: (tabIndex: number) => void
}

export interface IPhysicianColumn {
  title: string
  renderValue: (
    physician: PhysiciansEnrolledSummaryDto,
    index?: number
  ) => JSX.Element | string
  width: string
  name?: string
  isSortable?: boolean
}

export interface IProgramDocumentColumn {
  title: string
  renderValue: (
    document: DocumentSearchResultDto,
    index?: number
  ) => JSX.Element | string
  width: string
  name?: string
  isSortable?: boolean
}

export const defaultDocumentPagination: PaginationResultDto = {
  /** number items on page */
  count: 5,
  /** items to skip */
  skip: 0,
  /** items in page */
  take: 5,
  /** total items */
  total: 0
}

export interface ActionDocumentDropdownItem {
  text: string
  color?: string
  onClick: (document: DocumentSearchResultDto) => void
}

export interface ActionPhysicianDropdownItem {
  text: string
  color?: string
  onClick: (physician: PhysiciansEnrolledSummaryDto) => void
}

export interface LoadingDocument {
  isLoading: boolean
  documentId?: string
}
