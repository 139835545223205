import { IConfig } from '../types/IConfig'
/**
 * Parse environmental vars from window object & export as Singleton
 */
const config =
  (window as any).config || ({ environment: 'storybook' } as IConfig)
if (config.environment === 'local' || config.environment === 'dev') {
  console.info(`Loaded config '${config.environment}':`)
  console.info(JSON.stringify(config, null, 2))
}

export default config
