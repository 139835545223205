import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { ClinRadio } from '../../components/ClinRadio'
import { IRadioProps } from '../../components/ClinRadio/ClinRadio'
import { ClinText } from '../../components/ClinText'
import { StyledClinText } from '../../components/ClinText/ClinText.styles'
import { mediaQueryDown } from '../../utils/mediaQuery'
import { ClinSpacer } from '../../components/ClinSpacer'

interface IStyledClinRadioProps extends IRadioProps {
  /** Decreases margin between items if enabled */
  isCompact?: boolean
  isLast?: boolean
  padding?: string
}
export const StyledClinRadio = styled(ClinRadio)<IStyledClinRadioProps>(
  {
    display: 'flex',
    borderRadius: ClinTheme.radii[2],
    border: `solid ${ClinTheme.borderWidths[0]}px ${ClinTheme.colors.deepGrey}`,
    outline: 'none',
    boxShadow: `0px 0px 4px rgba(80, 30, 206, 0.5)`,
    transition: 'color .4s, border-color .4s, background-color .4s'
  },
  ({
    checked,
    isCompact,
    disabled,
    isLast,
    padding
  }: IStyledClinRadioProps) => ({
    ':hover': {
      '.clin-text': {
        color: ClinTheme.colors.black
      },
      borderColor: disabled ? 'none' : ClinTheme.colors.primaryMid,
      backgroundColor: disabled
        ? ClinTheme.colors.lightGrey
        : ClinTheme.colors.white
    },
    backgroundColor: disabled
      ? ClinTheme.colors.lightGrey
      : ClinTheme.colors.white,
    borderColor: checked ? ClinTheme.colors.primaryMid : 'none',
    marginBottom: isLast
      ? '0px'
      : isCompact
      ? `${ClinTheme.space[3]}px`
      : `${ClinTheme.space[4]}px`,
    padding: padding
      ? padding
      : `${ClinTheme.space[4]}px ${ClinTheme.space[5]}px`
  })
)

export const StyledNewShippingAddressLink = styled(StyledClinText)({
  color: ClinTheme.colors.primaryLight,
  cursor: 'pointer',
  fontWeight: ClinTheme.fontWeights.medium,
  fontSize: ClinTheme.fontSizes[1],
  display: 'inline-block',
  marginLeft: ClinTheme.space[3],
  marginRight: ClinTheme.space[3],
  [mediaQueryDown(ClinTheme.breakpoints[2])]: {
    marginLeft: ClinTheme.space[5]
  }
})

export const StyledNewShippingAddressLinkOneStepCheckout = styled(
  StyledClinText
)({
  color: ClinTheme.colors.primaryLight,
  cursor: 'pointer',
  fontWeight: ClinTheme.fontWeights.medium,
  fontSize: ClinTheme.fontSizes[1],
  display: 'inline-block',
  marginLeft: ClinTheme.space[3],
  marginRight: ClinTheme.space[3],
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    marginLeft: ClinTheme.space[4],
    marginBottom: ClinTheme.space[4]
  }
})

export const StyledDeliveryAddressText = styled(ClinText)({
  display: 'inline-block',
  paddingLeft: ClinTheme.space[5],
  marginTop: ClinTheme.space[3],
  [mediaQueryDown(ClinTheme.breakpoints[2])]: {
    marginRight: ClinTheme.space[3]
  }
})

export const StyledDeliveryAddressTextOneStepCheckout = styled(ClinText)({
  display: 'inline-block',
  padding: ClinTheme.space[4],
  paddingRight: 0,
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    marginRight: ClinTheme.space[3],
    paddingBottom: 0
  }
})

export const StyledDeliveryAddressWrap = styled.div({
  backgroundColor: 'white'
})

export const StyledDeliveryAddressWrapper = styled.div({
  maxHeight: '390px',
  overflowY: 'scroll',
  padding: '0 5px',
  paddingBottom: ClinTheme.space[2],
  marginTop: ClinTheme.space[5],
  marginBottom: ClinTheme.space[8],

  [mediaQueryDown(ClinTheme.breakpoints[0])]: {
    maxHeight: '400px'
  }
})

export const StyledDeliveryAddressOneStepCheckoutWrapper = styled.div({
  maxHeight: '390px',
  overflowY: 'scroll',
  padding: '0 5px',
  paddingBottom: ClinTheme.space[2],
  marginBottom: ClinTheme.space[8],

  [mediaQueryDown(ClinTheme.breakpoints[0])]: {
    maxHeight: '350px'
  }
})

export const StyledClinSpacer = styled(ClinSpacer)({
  height: ClinTheme.space[7],
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    height: 0
  }
})

export const PhoneInputWrapper = styled.div({
  width: '290px',
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    width: '100%'
  }
})
