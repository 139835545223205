import styled from 'styled-components'

export const StyledSvg = styled.svg({
  flexShrink: 0
})

export const DemoUl = styled.ul({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  width: '100%'
})

export const DemoLi = styled.li({
  boxSizing: 'border-box',
  display: 'inline-flex',
  flexDirection: 'column',
  width: '50%'
})

export const DemoBox = styled.div({
  border: `1px solid #ccc`,
  background: '#fff',
  padding: '8px',
  margin: '8px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center'
})

export const DemoPre = styled.pre({
  marginTop: '4px',
  marginBottom: 0,
  fontSize: 10,
  color: '#666'
})
