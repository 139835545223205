import styled from 'styled-components'

export const StyledNoPatients = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  [`.clin-icon`]: {
    display: 'block'
  },
  [`.msg-copy`]: {
    maxWidth: '450px'
  }
})

export const StyledFlexSpacer = styled.div({
  width: '100%'
})
