import React, { ChangeEvent, FunctionComponent } from 'react'
import { ClinTheme } from '../../../ClinTheme'
import { Col, Row } from 'react-grid-system'
import { ClinText } from '../../../components/ClinText'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { CountryDto, NewInstituteDetilsDto } from '../../../types/swaggerTypes'
import { object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { ClinTextInput } from '../../../components/ClinTextInput'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinAnnounceBar } from '../../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { InputFieldLength } from '../models/TransferPatientContainer.model'
import { StyledTransferButton } from '../styles/TransferPatient.styles'
import { StyledLoadingSpinner } from '../../InstitutePages/InviteNewUser/InviteNewUser.styles'
import { ClinSelect } from '../../../components/ClinSelect'
import { useTranslation } from 'react-i18next'
import {
  getInputValidation,
  ValidationType
} from '../../../utils/Forms/getInputValidation'
import {
  getInputPlaceholder,
  PlaceholderType
} from '../../../utils/Forms/getInputPlaceholder'

interface IInstituteDetailsProps {
  /** An array of provided countries */
  countries: CountryDto[]
  /** Whether we are submitting or not */
  isSubmitting: boolean
  /** Save Institute details */
  submitTransferring: (data: NewInstituteDetilsDto) => void
  /** Cancel form and return to previous page */
  handleCancel: () => void
}

const defaultState: NewInstituteDetilsDto = {
  newInstituteName: '',
  newInstitutePhone: '',
  newInstituteAddress1: '',
  newInstituteAddress2: '',
  newInstituteAddress3: '',
  newInstituteCity: '',
  newInstituteCounty: '',
  newInstituteCountry: '',
  newInstitutePostalCode: ''
}

export const InstituteDetailsForm: FunctionComponent<
  IInstituteDetailsProps
> = ({ countries, isSubmitting, submitTransferring, handleCancel }) => {
  const { t } = useTranslation()

  const requiredText = getInputValidation(ValidationType.RequiredField)
  const InstituteDetailsSchema = object().shape({
    newInstitutePhone: string().required(requiredText),
    newInstituteName: string().required(requiredText),
    newInstituteAddress1: string().required(requiredText),
    newInstituteAddress2: string(),
    newInstituteAddress3: string(),
    newInstituteCity: string().required(requiredText),
    newInstituteCounty: string().required(requiredText),
    newInstituteCountry: string().required(requiredText),
    newInstitutePostalCode: string().required(requiredText)
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    formState
  } = useForm<NewInstituteDetilsDto>({
    defaultValues: defaultState,
    mode: 'onChange',
    resolver: yupResolver(InstituteDetailsSchema)
  })
  const { isValid } = formState
  return (
    <>
      <Row>
        <Col sm={12} md={9}>
          <ClinText
            as="h5"
            variant={TypographyVariant.H4}
            color={ClinTheme.colors.primary}
            fontWeight={ClinTheme.fontWeights.bold}
            marginBottom={ClinTheme.space[4]}
          >
            {t('transfer_patient_to_institute:new_institute_form_intro')}
          </ClinText>
        </Col>
      </Row>
      <form
        className="institute-details-form"
        onSubmit={handleSubmit(submitTransferring)}
      >
        <Row>
          <Col xs={12} md={6} lg={4}>
            <ClinTextInput
              id="institute-phone"
              {...register('newInstitutePhone')}
              label={t('transfer_patient_to_institute:phone_number_label')}
              maxLength={InputFieldLength.MAX80}
              width="100%"
              hasError={!!(errors && errors?.newInstitutePhone)}
              prompt={errors.newInstitutePhone?.message}
            />
            <ClinSpacer height={ClinTheme.space[4]} />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6} lg={4}>
            <ClinTextInput
              id="institute-name"
              {...register('newInstituteName')}
              label={t('transfer_patient_to_institute:hospital_name')}
              maxLength={InputFieldLength.MAX80}
              width="100%"
              hasError={!!(errors && errors?.newInstituteName)}
              prompt={errors.newInstituteName?.message}
            />
            <ClinSpacer height={ClinTheme.space[4]} />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6} lg={4}>
            <ClinTextInput
              id="address-1"
              {...register('newInstituteAddress1')}
              label={t('transfer_patient_to_institute:address_line_one')}
              maxLength={InputFieldLength.MAX80}
              width="100%"
              hasError={!!(errors && errors?.newInstituteAddress1)}
              prompt={errors.newInstituteAddress1?.message}
            />
            <ClinSpacer height={ClinTheme.space[4]} />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6} lg={4}>
            <ClinTextInput
              id="address-2"
              {...register('newInstituteAddress2')}
              label={t('transfer_patient_to_institute:address_line_two')}
              maxLength={InputFieldLength.MAX240}
              width="100%"
              hasError={!!(errors && errors?.newInstituteAddress2)}
              prompt={errors.newInstituteAddress2?.message}
            />
            <ClinSpacer height={ClinTheme.space[4]} />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6} lg={4}>
            <ClinTextInput
              id="address-3"
              {...register('newInstituteAddress3')}
              label={t('transfer_patient_to_institute:address_line_three')}
              maxLength={InputFieldLength.MAX240}
              width="100%"
              hasError={!!(errors && errors.newInstituteAddress3)}
              prompt={errors.newInstituteAddress3?.message}
            />
            <ClinSpacer height={ClinTheme.space[4]} />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6} lg={4}>
            <Row>
              <Col xs={12} lg={6}>
                <ClinTextInput
                  id="city"
                  {...register('newInstituteCity')}
                  label={t('transfer_patient_to_institute:city')}
                  maxLength={InputFieldLength.MAX240}
                  width="100%"
                  hasError={!!errors?.newInstituteCity}
                  prompt={errors.newInstituteCity?.message}
                />
                <ClinSpacer height={ClinTheme.space[4]} />
              </Col>
              <Col xs={12} lg={6}>
                <ClinTextInput
                  id="county"
                  {...register('newInstituteCounty')}
                  label={t('transfer_patient_to_institute:county')}
                  maxLength={InputFieldLength.MAX60}
                  width="100%"
                  hasError={!!(errors && errors.newInstituteCounty)}
                  prompt={errors.newInstituteCounty?.message}
                />
                <ClinSpacer height={ClinTheme.space[4]} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} lg={4}>
            <Controller
              name={'newInstituteCountry'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <ClinSelect
                  id="country"
                  label={t('transfer_patient_to_institute:country')}
                  title={t('transfer_patient_to_institute:country')}
                  width="100%"
                  value={value}
                  onChange={(changeValue: ChangeEvent<HTMLSelectElement>) =>
                    onChange(changeValue.currentTarget.value)
                  }
                  hasError={!!(errors && errors.newInstituteCountry)}
                  prompt={errors.newInstituteCountry?.message}
                >
                  <option value="" disabled={true}>
                    {getInputPlaceholder(PlaceholderType.SelectInput)}
                  </option>
                  {countries
                    .sort((a, b) =>
                      a.countryName && b.countryName
                        ? a.countryName.localeCompare(b.countryName)
                        : -1
                    )
                    .map((country: CountryDto) => {
                      return (
                        <option
                          key={`country-codes-${country.countryCode}`}
                          value={country.countryCode}
                        >
                          {country.countryName}
                        </option>
                      )
                    })}
                </ClinSelect>
              )}
            />
            <ClinSpacer height={ClinTheme.space[4]} />
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={3} lg={2}>
            <ClinTextInput
              id="postcode"
              {...register('newInstitutePostalCode')}
              label={t('transfer_patient_to_institute:postcode')}
              maxLength={InputFieldLength.MAX60}
              width="100%"
              hasError={!!(errors && errors.newInstitutePostalCode)}
              prompt={errors.newInstitutePostalCode?.message}
            />
            <ClinSpacer height={ClinTheme.space[4]} />
          </Col>
        </Row>

        <ClinSpacer height={ClinTheme.space[7]} />
        <Row>
          <Col sm={12} md={9} lg={9}>
            <ClinAnnounceBar
              mode={AnnounceMode.Information}
              title={t('transfer_patient_to_institute:please_note')}
            >
              {t('transfer_patient_to_institute:note_three')}
            </ClinAnnounceBar>
          </Col>
        </Row>
        <ClinSpacer height={ClinTheme.space[8]} />
        <Row>
          <Col sm={12} md={9} lg={6}>
            <ClinGroup space={ClinTheme.space[3]}>
              <StyledTransferButton
                className="cancel"
                variant="secondary"
                justifyContent="space-around"
                onClick={() => handleCancel()}
              >
                {t('common:buttons.cancel')}
              </StyledTransferButton>
              <StyledTransferButton
                className="continue"
                variant="primary"
                justifyContent="space-around"
                disabled={!isValid || isSubmitting}
                type="submit"
              >
                {t('common:buttons.continue')}
              </StyledTransferButton>
              {isSubmitting && (
                <StyledLoadingSpinner>
                  <ClinSpinner />
                </StyledLoadingSpinner>
              )}
            </ClinGroup>
          </Col>
        </Row>
      </form>
    </>
  )
}
