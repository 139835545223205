import React, { ChangeEvent, FunctionComponent } from 'react'
import { ClinTheme } from '../../../ClinTheme'
import { Col, Container, Row } from 'react-grid-system'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import {
  CountryDto,
  CreateShippingAddressDto
} from '../../../types/swaggerTypes'
import { number, object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { ClinTextInput } from '../../../components/ClinTextInput'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { ClinButton } from '../../../components/ClinButton'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinAnnounceBar } from '../../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import {
  StyledLoadingContainer,
  StyledLoadingSpinner
} from '../InviteNewUser/InviteNewUser.styles'
import { ClinSelect } from '../../../components/ClinSelect'
import { Trans, useTranslation } from 'react-i18next'
import {
  getInputValidation,
  ValidationType
} from '../../../utils/Forms/getInputValidation'
import { StyledSpinnerContainer } from '../../OpaCheckout/OpaCheckout.styles'
import {
  getInputPlaceholder,
  PlaceholderType
} from '../../../utils/Forms/getInputPlaceholder'

interface INewShippingAddressProps {
  /** Whether we are loading or not */
  isLoading: boolean
  /** Whether the submission API request is in progress */
  isSubmitting?: boolean
  /** Support contact details **/
  supportContact: CountryDto
  /** An array of provided countries */
  countries: CountryDto[]
  /** Form submission handler */
  handleFormSubmission: (data: CreateShippingAddressDto) => void
  /** Cancel form and return to previous page */
  handleCancel: () => void
}

const defaultState: CreateShippingAddressDto = {
  address: {
    addressId: 0,
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    city: '',
    postalCode: '',
    state: '',
    country: ''
  }
}

export const NewShippingAddress: FunctionComponent<
  INewShippingAddressProps
> = ({
  isLoading,
  isSubmitting,
  supportContact,
  countries,
  handleFormSubmission,
  handleCancel
}) => {
  const requiredText = getInputValidation(ValidationType.RequiredField)
  const maxCharsText = getInputValidation(ValidationType.MaxiumumCharacters, 30)
  const { t } = useTranslation()

  const NewShippingAddressSchema = object().shape({
    address: object().shape({
      addressId: number(),
      address1: string().required(requiredText),
      address2: string().required(requiredText),
      address3: string(),
      address4: string(),
      city: string().required(requiredText),
      postalCode: string().required(requiredText),
      state: string().required(requiredText).max(30, maxCharsText),
      country: string().required(requiredText)
    })
  })

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<CreateShippingAddressDto>({
    defaultValues: defaultState,
    resolver: yupResolver(NewShippingAddressSchema)
  })

  return isLoading ? (
    <Container>
      <Row align="center">
        <Col>
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    </Container>
  ) : (
    <ClinPageContentFrame>
      <form onSubmit={handleSubmit(handleFormSubmission)}>
        <Row>
          <Col xs={12}>
            <ClinText
              as="h1"
              variant={TypographyVariant.H2}
              fontWeight={ClinTheme.fontWeights.bold}
            >
              {t('request_new_shipping_address:title')}
            </ClinText>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} lg={5}>
            <ClinText variant={TypographyVariant.LargeParagraph}>
              {t('request_new_shipping_address:details')}
            </ClinText>
          </Col>
        </Row>
        <ClinSpacer height={ClinTheme.space[3]} />

        <Row>
          <Col xs={12} md={8} lg={7}>
            <ClinAnnounceBar
              mode={AnnounceMode.Information}
              title={t('request_new_shipping_address:announce_title')}
            >
              <Trans
                i18nKey="request_new_shipping_address:announce_copy"
                components={[
                  <a
                    aria-label="Link to customer support phone number"
                    key="tel"
                    href={`tel:${supportContact.rawPhoneNumber}`}
                  />,
                  <a
                    aria-label="Mail to customer support"
                    key="mail"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`mailto:${supportContact.csEmailAddress}`}
                  />
                ]}
                values={{
                  csPhoneNumber: supportContact.csPhoneNumber,
                  csEmailAddress: supportContact.csEmailAddress
                }}
              >
                <></>
              </Trans>
            </ClinAnnounceBar>
          </Col>
        </Row>
        <ClinSpacer height={ClinTheme.space[4]} />

        <Row>
          <Col xs={12} md={8} lg={5}>
            <ClinText variant={TypographyVariant.LargeParagraph}>
              {t('request_new_shipping_address:mandatory_message')}
            </ClinText>
          </Col>
        </Row>
        <ClinSpacer height={ClinTheme.space[4]} />
        <Row>
          <Col xs={12} md={8} lg={4}>
            <div style={{ display: 'none' }}>
              <ClinTextInput
                id="address-id"
                {...register(`address.addressId`)}
                value={0}
              />
            </div>
            <ClinTextInput
              id="address"
              {...register(`address.address1`)}
              label={t('request_new_shipping_address:form_label_hospital')}
              width="100%"
              hasError={!!(errors && errors.address?.address1)}
              prompt={errors.address?.address1?.message}
            />
            <ClinSpacer height={ClinTheme.space[4]} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={4}>
            <ClinTextInput
              id="address-one"
              {...register(`address.address2`)}
              label={t(
                'request_new_shipping_address:form_label_address_line_1'
              )}
              width="100%"
              hasError={!!(errors && errors.address?.address2)}
              prompt={errors.address?.address2?.message}
            />
            <ClinSpacer height={ClinTheme.space[4]} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} lg={4}>
            <ClinTextInput
              id="address-two"
              {...register(`address.address3`)}
              label={t(
                'request_new_shipping_address:form_label_address_line_2'
              )}
              width="100%"
              hasError={!!(errors && errors.address?.address3)}
              prompt={errors.address?.address3?.message}
            />
            <ClinSpacer height={ClinTheme.space[4]} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} lg={4}>
            <ClinTextInput
              id="address-three"
              {...register(`address.address4`)}
              label={t(
                'request_new_shipping_address:form_label_address_line_3'
              )}
              width="100%"
              hasError={!!(errors && errors.address?.address4)}
              prompt={errors.address?.address4?.message}
            />
            <ClinSpacer height={ClinTheme.space[4]} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} lg={6}>
            <Row>
              <Col xs={12} lg={5}>
                <ClinTextInput
                  id="city"
                  {...register(`address.city`)}
                  label={t('request_new_shipping_address:form_label_city')}
                  width="100%"
                  hasError={!!(errors && errors.address?.city)}
                  prompt={errors.address?.city?.message}
                />
                <ClinSpacer height={ClinTheme.space[4]} />
              </Col>
              <Col xs={12} lg={6}>
                <ClinTextInput
                  id="city"
                  {...register(`address.state`)}
                  label={t('request_new_shipping_address:form_label_state')}
                  width="100%"
                  hasError={!!(errors && errors.address?.state)}
                  prompt={errors.address?.state?.message}
                />
                <ClinSpacer height={ClinTheme.space[4]} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} lg={4}>
            <Controller
              name={'address.country'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <ClinSelect
                  id="country"
                  label={t('request_new_shipping_address:form_label_country')}
                  width="100%"
                  value={value}
                  onChange={(changeValue: ChangeEvent<HTMLSelectElement>) =>
                    onChange(changeValue.currentTarget.value)
                  }
                  hasError={!!(errors && errors.address?.country)}
                  prompt={errors.address?.country?.message}
                >
                  <option value="" disabled={true}>
                    {getInputPlaceholder(PlaceholderType.SelectInput)}
                  </option>
                  {countries
                    .sort((a, b) =>
                      a.countryName && b.countryName
                        ? a.countryName.localeCompare(b.countryName)
                        : -1
                    )
                    .map((country: CountryDto) => {
                      return (
                        <option
                          key={`country-codes-${country.countryId}`}
                          value={country.countryCode}
                        >
                          {country.countryName}
                        </option>
                      )
                    })}
                </ClinSelect>
              )}
            />
            <ClinSpacer height={ClinTheme.space[4]} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} lg={4}>
            <ClinTextInput
              id="postal-code"
              {...register(`address.postalCode`)}
              label={t('request_new_shipping_address:form_label_postcode')}
              width="100%"
              hasError={!!(errors && errors.address?.postalCode)}
              prompt={errors.address?.postalCode?.message}
            />
            <ClinSpacer height={ClinTheme.space[8]} />
            <ClinGroup>
              <ClinButton onClick={() => handleCancel()}>
                {t('common:buttons.cancel')}
              </ClinButton>
              <StyledLoadingContainer>
                <ClinButton
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting}
                >
                  {t('common:buttons.submit_request')}
                </ClinButton>
                {isSubmitting && (
                  <StyledLoadingSpinner>
                    <ClinSpinner />
                  </StyledLoadingSpinner>
                )}
              </StyledLoadingContainer>
            </ClinGroup>
          </Col>
        </Row>
      </form>
    </ClinPageContentFrame>
  )
}
