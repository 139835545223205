import React, { FunctionComponent } from 'react'
import { Redirect, Route } from 'react-router'
import { useAppContext } from '../../context/app'
import { UserRole } from '../../constants'
import { NotFound } from '../../pages/NotFound'
import {
  getIsPageAccessibleFromCountry,
  isRoleValid
} from '../../utils/getIsPageAccessibleFromCountry'

interface IPrivateRoleRouteProps {
  component: FunctionComponent<any>
  exact: boolean
  path: string
  redirect?: string
  acceptedRoleTypes?: UserRole[]
  /** Optional error page (uses standard NotFound 404 page by default) */
  errorPage?: FunctionComponent<any>
  /** Optional check for access from a specific country */
  onlyAllowForCountryCodes?: string[]
  /** Optional check for access from a specific country */
  forbidForCountryCodes?: string[]
  /** Optional parameter for passing countries that does not accept only that roles - related to ticket clos-1614 (aus ma users can see products catalogue) */
  countriesExcludedFromThisRule?: string[]
}

export const PrivateRoleRoute: FunctionComponent<IPrivateRoleRouteProps> = ({
  component,
  path,
  exact,
  redirect,
  acceptedRoleTypes,
  errorPage = NotFound,
  onlyAllowForCountryCodes,
  forbidForCountryCodes,
  countriesExcludedFromThisRule
}) => {
  const { userRole, portfolioCountryCode } = useAppContext()

  return (
    <>
      {userRole &&
      isRoleValid(
        portfolioCountryCode,
        userRole,
        acceptedRoleTypes,
        countriesExcludedFromThisRule
      ) &&
      getIsPageAccessibleFromCountry(
        portfolioCountryCode,
        onlyAllowForCountryCodes,
        forbidForCountryCodes
      ) ? (
        <Route path={path} exact={exact} component={component} />
      ) : (
        <>
          {redirect ? (
            <Redirect to={redirect} exact={true} />
          ) : (
            <Route path={path} exact={true} component={errorPage} />
          )}
        </>
      )}
    </>
  )
}
