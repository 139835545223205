import React, { FunctionComponent } from 'react'
import { ClinTheme } from '../../../ClinTheme'
import { Col, Row } from 'react-grid-system'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinTextInput } from '../../../components/ClinTextInput'
import { ClinButton } from '../../../components/ClinButton'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  StyledChangePasswordLoader,
  StyledLoaderContainer
} from './ChangePassword.styles'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { PageLayoutVariant } from '../../../components/ClinPageContentFrame/ClinPageContentFrame'
import { ClinGroup } from '../../../components/ClinGroup'
import { useTranslation } from 'react-i18next'

export enum FormSubmitState {
  Form,
  Loading,
  Success,
  Error
}

type ChangePasswordFormData = {
  currentPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

interface IChangePasswordProps {
  /** State of the form */
  formState?: FormSubmitState
  /** New password callback when form submitted */
  handleSubmitClick?: (
    password: string,
    newPassword: string,
    newPasswordConfirmation: string
  ) => void
  /** Return home button */
  handleReturnToAccounts?: (event: React.MouseEvent) => void
  /** Try form submit again */
  handleTryAgain?: (event: React.MouseEvent) => void
}

export const ChangePassword: FunctionComponent<IChangePasswordProps> = ({
  formState = FormSubmitState.Form,
  handleSubmitClick,
  handleReturnToAccounts,
  handleTryAgain
}) => {
  const { t } = useTranslation()

  const changePasswordSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .required(t('account_details:change_password.password_current')),
    newPassword: yup
      .string()
      .required(t('account_details:change_password.password_required'))
      .min(10, t('account_details:change_password.password_minimum_chars'))
      .matches(
        /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[*.#!@$%^&(){}[\]:;<>,?/~_+\-=|]).{10,}$/,
        t('account_details:change_password.password_minimum_requirements')
      ),
    newPasswordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref('newPassword'), undefined],
        t('account_details:change_password.password_mismatch')
      )
  })

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm<ChangePasswordFormData>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: ''
    },
    resolver: yupResolver(changePasswordSchema)
  })

  const onSubmit = handleSubmit(
    ({
      currentPassword,
      newPassword,
      newPasswordConfirmation
    }: ChangePasswordFormData) => {
      if (newPassword === newPasswordConfirmation) {
        handleSubmitClick &&
          handleSubmitClick(
            currentPassword,
            newPassword,
            newPasswordConfirmation
          )
      }
    }
  )

  const renderSwitch = (state?: FormSubmitState): React.ReactNode => {
    switch (state) {
      case FormSubmitState.Form:
        return (
          <form onSubmit={onSubmit} noValidate={true}>
            <Col xs={12}>
              <Controller
                name="currentPassword"
                control={control}
                render={({ field }) => (
                  <ClinTextInput
                    {...field}
                    id="current-password"
                    label={t('account_details:change_password:title')}
                    type="password"
                    hasError={!!errors.currentPassword}
                    prompt={errors.currentPassword?.message}
                  />
                )}
              />
              <ClinSpacer />
            </Col>
            <Col xs={12}>
              <Controller
                name="newPassword"
                control={control}
                render={({ field }) => (
                  <ClinTextInput
                    {...field}
                    id="new-password"
                    label={t('account_details:change_password:new_password')}
                    type="password"
                    hasError={!!errors.newPassword}
                    prompt={errors.newPassword?.message}
                  />
                )}
              />
              <ClinSpacer />
            </Col>
            <Col xs={12}>
              <Controller
                name="newPasswordConfirmation"
                control={control}
                render={({ field }) => (
                  <ClinTextInput
                    {...field}
                    id="retype-password"
                    label={t(
                      'account_details:change_password:password_confirmation'
                    )}
                    type="password"
                    hasError={!!errors.newPasswordConfirmation}
                    prompt={errors.newPasswordConfirmation?.message}
                  />
                )}
              />
              <ClinSpacer height={ClinTheme.space[5]} />
            </Col>
            <Col xs={12}>
              <ClinGroup
                justifyContent="space-between"
                space={ClinTheme.space[2]}
              >
                <ClinButton
                  onClick={(event) =>
                    handleReturnToAccounts && handleReturnToAccounts(event)
                  }
                >
                  {t('common:labels:cancel')}
                </ClinButton>
                <ClinButton variant="primary" type="submit">
                  {t('account_details:change_password:submit_button')}
                </ClinButton>
              </ClinGroup>
            </Col>
          </form>
        )
      case FormSubmitState.Success:
        return (
          <Col>
            <ClinText as="p" variant={TypographyVariant.Paragraph}>
              {t('account_details:change_password:success_message')}
            </ClinText>
            <ClinButton
              variant="primary"
              onClick={(event) =>
                handleReturnToAccounts && handleReturnToAccounts(event)
              }
            >
              {t('account_details:change_password:back_button')}
            </ClinButton>
          </Col>
        )
      case FormSubmitState.Error:
        return (
          <Col>
            <ClinText as="p" variant={TypographyVariant.Paragraph}>
              {t('account_details:change_password:error_message')}
            </ClinText>
            <ClinButton
              variant="primary"
              onClick={(event) => handleTryAgain && handleTryAgain(event)}
            >
              {t('account_details:change_password:retry_button')}
            </ClinButton>
          </Col>
        )
      default:
        return (
          <Col xs="content">
            <StyledChangePasswordLoader>
              <StyledLoaderContainer>
                <ClinSpinner />
              </StyledLoaderContainer>
              <ClinText
                as="p"
                variant={TypographyVariant.Paragraph}
                textAlign="center"
              >
                {t('account_details:change_password:progress_message')}
              </ClinText>
            </StyledChangePasswordLoader>
          </Col>
        )
    }
  }

  return (
    <ClinPageContentFrame layoutVariant={PageLayoutVariant.NarrowContent}>
      <Row justify="center">
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <ClinText
                as="h1"
                variant={TypographyVariant.H2}
                fontWeight={ClinTheme.fontWeights.bold}
              >
                {t('account_details:change_password:title')}
              </ClinText>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ClinText as="div">
                {t('account_details:change_password:description')}
                <ClinSpacer />
                <ul>
                  <li>
                    {t('account_details:change_password:validation_rule_1')}
                  </li>
                  <li>
                    {t('account_details:change_password:validation_rule_2')}
                  </li>
                </ul>
                <ClinSpacer />
              </ClinText>
            </Col>
          </Row>
          <Row>{renderSwitch(formState)}</Row>
        </Col>
      </Row>
    </ClinPageContentFrame>
  )
}
