import styled from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'

export const StyledChangePasswordLoader = styled.div({
  textAlign: 'center',
  width: 364 // TODO: This is technical debt as when the title changes this will be out of alignment
})

export const StyledLoaderContainer = styled.div({
  display: 'inline-block',
  width: 170,
  height: 170,
  marginBottom: ClinTheme.space[5]
})
