import React, { FunctionComponent } from 'react'
import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import {
  StyledFlexSpacer,
  StyledNoPatients
} from '../NoPrograms/NoPrograms.styles'
import { ClinSpacer } from '../../components/ClinSpacer'
import { t } from 'i18next'

export const NoProgramDocuments: FunctionComponent = () => (
  <StyledNoPatients>
    <ClinSpacer height={ClinTheme.space[8]} />
    <ClinIcon
      iconName={ClinIconPathName.Document}
      iconFill={ClinTheme.colors.grey}
      iconSize={170}
    />
    <StyledFlexSpacer />
    <ClinText
      textAlign={'center'}
      variant={TypographyVariant.H4}
      fontWeight={ClinTheme.fontWeights.bold}
      marginTop={ClinTheme.space[2]}
    >
      {t('program_detail:no_documents')}
    </ClinText>
    <StyledFlexSpacer />
    <StyledFlexSpacer />
    <ClinSpacer height={ClinTheme.space[8]} />
  </StyledNoPatients>
)
