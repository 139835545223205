import { DateTime } from 'luxon'
import { RelationshipDto, UserDto } from '../../types/swaggerTypes'
import {
  FeatureFlag,
  FeatureFlagVariant,
  FeatureFlagWithTargetingFilter,
  FeatureFlagWithTimeWindow,
  TimeWindowFilter
} from './FeatureFlagModels'

// AudienceParameters interface
export interface AudienceParameters {
  IncludedUsers: string[]
  ExcludedUsers: string[]
  Groups: string[]
  ExcludedCountries: string[]
  IncludedCountries: string[]
  IncludedRoles: string[]
  ExcludedRoles: string[]
  DefaultRolloutPercentage: number
}

// Type guards
export function isFeatureFlagWithTargetingFilter(
  flag: FeatureFlag
): flag is FeatureFlagWithTargetingFilter {
  return (
    (flag as FeatureFlagWithTargetingFilter).conditions.client_filters[0]
      ?.name === FeatureFlagVariant.Targeting
  )
}

export function isFeatureFlagWithTimeWindow(
  flag: FeatureFlag
): flag is FeatureFlagWithTimeWindow {
  return (
    (flag as FeatureFlagWithTimeWindow).conditions.client_filters[0]?.name ===
    FeatureFlagVariant.TimeWindow
  )
}

export const parseFeatureFlagData = (raw: any) => {
  let parsedData: Record<string, FeatureFlag> = {}
  try {
    for (const key in raw) {
      if (Object.prototype.hasOwnProperty.call(raw, key)) {
        const flag: FeatureFlag = JSON.parse(raw[key])
        parsedData[flag.id] = flag
      }
    }
  } catch (error) {
    console.error(
      'Error parsing feature flag data, please raise an issue with our customer services: ',
      error
    )
    return {} // Abort if json is malformed, means a breaking change in Azure App Config structure
  }
  return parsedData
}

export const evaluateFeatureFlags = (
  parsedFeatureFlagData: Record<string, FeatureFlag>,
  userDetails: UserDto | undefined
) => {
  const evaluatedFlags: Record<string, boolean> = {}
  for (const key in parsedFeatureFlagData) {
    if (parsedFeatureFlagData.hasOwnProperty(key)) {
      evaluatedFlags[key] = evaluateFeatureFlag(
        parsedFeatureFlagData[key],
        userDetails
      )
    }
  }
  return evaluatedFlags
}

// TODO break down into individual methods based on FeatureFlagVariant
export const evaluateFeatureFlag = (
  featureFlag: FeatureFlag,
  userDetails: UserDto | undefined
): boolean => {
  // Feature flag disabled?
  if (featureFlag.enabled === false) {
    return false
  }

  // Base feature flag, if it's on, it's on
  if (featureFlag.conditions.client_filters.length === 0) {
    return featureFlag.enabled
  }

  if (isAlwaysEnabledFeatureFlag(featureFlag)) {
    console.log('Always enabled feature flag:', featureFlag.id)
    return true
  }

  // Targeting feature flag - variants are audience and user/group
  if (isFeatureFlagWithTargetingFilter(featureFlag)) {
    const targetingFilter = featureFlag.conditions.client_filters.find(
      (filter) => filter.name === FeatureFlagVariant.Targeting
    )

    if (targetingFilter) {
      const audience: AudienceParameters = targetingFilter.parameters?.Audience
      const currentUser = userDetails?.contactCard.email
      const userCountryCodes = getShipToCountryCodes()
      const userRoles = getUserRoles()

      if (!currentUser) return false

      const includedCountries = audience.IncludedCountries || []
      const excludedCountries = audience.ExcludedCountries || []
      const includedUsers = audience.IncludedUsers || []
      const excludedUsers = audience.ExcludedUsers || []
      const includedRoles = audience.IncludedRoles || []
      const excludedRoles = audience.ExcludedRoles || []

      // Check if user's role is in includedRoles
      const isRoleIncluded = includedRoles.some((role) =>
        userRoles.includes(role)
      )

      // Check if user's role is in excludedRoles
      const isRoleExcluded = excludedRoles.some((role) =>
        userRoles.includes(role)
      )

      // If user's role is excluded, return false unless it's also included
      if (isRoleExcluded && !isRoleIncluded) {
        return false
      }

      // If user's role is included, allow access regardless of country
      if (isRoleIncluded) {
        return true
      }

      // Existing logic for user and country checks
      if (excludedUsers.includes(currentUser)) {
        return false
      }

      if (includedUsers.includes(currentUser)) {
        return true
      }

      if (includedUsers.length > 0 && !includedUsers.includes(currentUser)) {
        return false
      }

      if (
        includedUsers.length === 0 &&
        !excludedUsers.includes(currentUser) &&
        excludedUsers.length > 0
      ) {
        return true
      }

      const isCountryIncluded = includedCountries.some((country) =>
        userCountryCodes.includes(country)
      )
      const isCountryExcluded = excludedCountries.some((country) =>
        userCountryCodes.includes(country)
      )

      if (includedCountries.length > 0 && !isCountryExcluded) {
        return isCountryIncluded
      }

      if (excludedCountries.length > 0 && !isCountryIncluded) {
        return !isCountryExcluded
      }

      // If we've reached this point, use the default rollout percentage
      if (
        audience.DefaultRolloutPercentage > 0 &&
        audience.DefaultRolloutPercentage <= 100
      ) {
        const randomPercentage = Math.random() * 100
        return randomPercentage <= audience.DefaultRolloutPercentage
      }
    }
  }

  // Time window feature flag - if enabled and if it's the right time
  if (isFeatureFlagWithTimeWindow(featureFlag)) {
    const timeWindowFilter: TimeWindowFilter | undefined =
      featureFlag.conditions.client_filters.find(
        (filter) => filter.name === FeatureFlagVariant.TimeWindow
      )

    if (timeWindowFilter) {
      const currentTime: DateTime = DateTime.now() // Get the current time
      const endTime: DateTime = DateTime.fromFormat(
        timeWindowFilter.parameters.End,
        "EEE, dd MMM yyyy HH:mm:ss 'GMT'"
      )

      // Check if the current time is before the specified end time
      if (currentTime < endTime) {
        return true
      } else {
        return false
      }
    }
  }

  // Space for custom feature flag evaluations. Expected to expand upon types in models.
  return false // Fallback value set to false always
}

const getCurrentUser = () => {
  const currentUserJson = localStorage.getItem('current_user')
  if (!currentUserJson) {
    console.error('No current user found in local storage')
    return null
  }

  try {
    return JSON.parse(currentUserJson)
  } catch (error) {
    console.error('Error parsing current user data from local storage:', error)
    return null
  }
}

const getShipToCountryCodes = () => {
  const currentUser = getCurrentUser()
  if (!currentUser) {
    return []
  }

  const contactCard = currentUser.contactCard
  if (!contactCard || !contactCard.clientRelationships) {
    console.error('Invalid contact card data')
    return []
  }

  return contactCard.clientRelationships.map(
    (relationship: RelationshipDto) => relationship.shipToCountryCode
  )
}

const getUserRoles = () => {
  const currentUser = getCurrentUser()
  if (!currentUser) {
    return []
  }

  const contactCard = currentUser.contactCard
  if (!contactCard || !contactCard.clientRelationships) {
    console.error('Invalid contact card data')
    return []
  }

  return contactCard.clientRelationships.map(
    (relationship: RelationshipDto) => relationship.roleType
  )
}

const isAlwaysEnabledFeatureFlag = (featureFlag: FeatureFlag): boolean => {
  if (
    featureFlag.enabled &&
    featureFlag.conditions.client_filters.length === 1 &&
    featureFlag.conditions.client_filters[0].name ===
      FeatureFlagVariant.Targeting
  ) {
    const audience = (
      featureFlag.conditions.client_filters[0].parameters as any
    )?.Audience
    if (audience) {
      const isArrayEmptyOrUndefined = (arr: any[] | undefined): boolean =>
        !arr || arr.length === 0

      return (
        isArrayEmptyOrUndefined(audience.IncludedUsers) &&
        isArrayEmptyOrUndefined(audience.ExcludedUsers) &&
        isArrayEmptyOrUndefined(audience.IncludedCountries) &&
        isArrayEmptyOrUndefined(audience.ExcludedCountries) &&
        isArrayEmptyOrUndefined(audience.IncludedRoles) &&
        isArrayEmptyOrUndefined(audience.ExcludedRoles) &&
        isArrayEmptyOrUndefined(audience.Groups) &&
        audience.DefaultRolloutPercentage === 0
      )
    }
  }
  return false
}
