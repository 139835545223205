import React, { FunctionComponent, useEffect } from 'react'
import { useHistory } from 'react-router'
import { ClinModal } from '../../components/ClinModal'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { useAppContext } from '../../context/app'

export const CheckoutErrorModalContainer: FunctionComponent = () => {
  const { basketError } = useAppContext()
  const history = useHistory()
  const [showAnnounceModal, setShowAnnounceModal] =
    React.useState<boolean>(false)

  useEffect(() => setShowAnnounceModal(!!basketError), [basketError])

  const handleContinue = () => {
    history.push('/basket')
    setShowAnnounceModal(false)
  }

  return (
    <ClinModal
      isOpen={showAnnounceModal}
      isDismissible={false}
      onClose={() => handleContinue()}
      header={() => (
        <ClinGroup justifyContent="flex-start">
          <ClinText
            as="h3"
            variant={TypographyVariant.H3}
            marginBottom={0}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            It looks like you&apos;ve made changes in another tab or window
          </ClinText>
        </ClinGroup>
      )}
    >
      <ClinText variant={TypographyVariant.LargeParagraph}>
        You&apos;ve either edited your basket or have an ongoing checkout
        elsewhere.
      </ClinText>
      <ClinSpacer height={ClinTheme.space[4]} />
      <ClinGroup justifyContent="flex-end">
        <ClinButton variant="primary" onClick={() => handleContinue()}>
          Back to basket
        </ClinButton>
      </ClinGroup>
    </ClinModal>
  )
}
