import analyticsServiceSingleton from '../services/Analytics/initAnalytics'

export function setupConsoleInterceptor() {
  const originalConsole = {
    error: console.error,
    warn: console.warn,
    log: console.log
  }

  const recentErrors = new Set<string>()
  const MAX_RECENT_ERRORS = 100
  const DEBOUNCE_TIME = 5000 // 5 seconds

  function debounce(func: Function, wait: number) {
    let timeout: NodeJS.Timeout | null = null
    return function (...args: any[]) {
      const later = () => {
        timeout = null
        func(...args)
      }
      if (timeout !== null) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(later, wait)
    }
  }

  function trackError(type: string, args: any[]) {
    const errorMessage = args
      .map((arg) => (typeof arg === 'string' ? arg : JSON.stringify(arg)))
      .join(' ')
    const errorKey = `${type}:${errorMessage}`

    if (!recentErrors.has(errorKey)) {
      analyticsServiceSingleton.trackError(new Error(errorMessage), { type })
      recentErrors.add(errorKey)
      if (recentErrors.size > MAX_RECENT_ERRORS) {
        const firstItem = recentErrors.values().next().value
        recentErrors.delete(firstItem)
      }
      setTimeout(() => recentErrors.delete(errorKey), DEBOUNCE_TIME)
    }
  }

  const debouncedTrackError = debounce(trackError, DEBOUNCE_TIME)

  console.error = function (...args) {
    debouncedTrackError('console.error', args)
    originalConsole.error.apply(console, args)
  }

  console.warn = function (...args) {
    debouncedTrackError('console.warn', args)
    originalConsole.warn.apply(console, args)
  }

  // Optionally intercept console.log as well
  // console.log = function(...args) {
  //   debouncedTrackError('console.log', args)
  //   originalConsole.log.apply(console, args)
  // }
}
