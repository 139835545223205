import React, { FunctionComponent } from 'react'
import { StyledNoBookmarksMessage } from './Bookmarks.styles'
import { ClinTheme } from '../../ClinTheme'
import { Col, Row } from 'react-grid-system'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { BookmarkDto } from '../../types/swaggerTypes'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { ClinTabs } from '../../components/ClinTabs'
import { ClinTabHeader } from '../../components/ClinTabHeader'
import { VariantCard } from '../../features/VariantCard'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinSpinner } from '../../components/ClinSpinner'
import { GenericCard } from '../../features/GenericCard'
import { ClinPagination } from '../../components/ClinPagination'
import {
  getCurrentPage,
  getTotalPages,
  IPagination
} from '../../components/ClinPagination/ClinPagination.model'
import { BookmarkableItemType } from '../../types/BookmarkableItem'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinGroup } from '../../components/ClinGroup'
import { getVariantInformationForCatalogItem } from '../../services/PortfolioJourneys/PortfolioJourney.model'
import { ClinButtonIcon } from '../../components/ClinButtonIcon'
import { ClinCard } from '../../components/ClinCard'
import { getDefaultWarehouseInfoForSku } from '../Products/ProductDetail/ProductDetail.models'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { UserRole, isAusGaUser } from '../../constants'
import { useCheckUnableHomePage } from '../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'

interface INoBookmarksMessage {
  /** Bookmarks to show */
  translateFn: TFunction
}

const NoBookmarksMessage: FunctionComponent<INoBookmarksMessage> = ({
  translateFn
}) => {
  return (
    <StyledNoBookmarksMessage>
      <ClinSpacer height={ClinTheme.space[5]} />
      <ClinIcon
        iconName={ClinIconPathName.Bookmark}
        iconFill={ClinTheme.colors.grey}
      />
      <ClinText
        color={ClinTheme.colors.primary}
        fontWeight={ClinTheme.fontWeights.bold}
        marginTop={ClinTheme.space[4]}
        marginBottom={ClinTheme.space[4]}
        textAlign={'center'}
        fontSize={ClinTheme.fontSizes[4]}
      >
        {translateFn('bookmarks:have_not_bookmarked')}
      </ClinText>
      <ClinText
        textAlign={'center'}
        className="no-bookmarks-message__instructions"
      >
        {translateFn('bookmarks:bookmark_helper')}
      </ClinText>
      <ClinSpacer height={ClinTheme.space[5]} />
    </StyledNoBookmarksMessage>
  )
}
interface IBookmarksProps {
  /** Bookmarks to show */
  bookmarks?: BookmarkDto[] | null
  /** Index of the selected tab */
  selectedTabIndex: number
  /** Country code for the user */
  countryCode: string
  /** The item that's shown */
  itemType: BookmarkableItemType
  /** Pagination details */
  pagination: IPagination
  /** Bookmarks that have been deleted */
  deletedBookmarks: BookmarkDto[]
  /** Bookmarks that should show in a deleted state */
  recentlyDeletedBookmarks: BookmarkDto[]
  /** List of bookmarks where brands have been expanded */
  bookmarksWithBrandsExpanded?: BookmarkDto[]
  /** User country */
  userCountry?: string
  /** User country */
  userRole?: UserRole
  /** Called when the user selects the Generic/Sku tab */
  onTabSelected: (selectedTabIndex: number) => void
  /** Called when the bookmark is deleted */
  onClickDelete: (bookmark: BookmarkDto) => void
  /** Called when the user clicks on the bookmark */
  onClick: (bookmark: BookmarkDto) => void
  /** Called when the user selects a different page size in the pagination */
  onPageSizeChange: (pageSize: number) => void
  /** Called when the user selects a page in the pagination */
  onPageSelected: (pageIndex: number) => void
  /** Called when the user toggles showing all brands on a generic */
  onToggleExpandBrands: (bookmark: BookmarkDto) => void
}

export const Bookmarks: FunctionComponent<IBookmarksProps> = ({
  bookmarks,
  selectedTabIndex,
  countryCode,
  userRole,
  itemType,
  pagination,
  bookmarksWithBrandsExpanded = [],
  deletedBookmarks = [],
  recentlyDeletedBookmarks = [],
  onTabSelected,
  onPageSizeChange,
  onPageSelected,
  onClickDelete,
  onClick,
  onToggleExpandBrands
}) => {
  const { t } = useTranslation()
  const { enableNewHomePage } = useCheckUnableHomePage()

  if (bookmarks) {
    bookmarks = bookmarks.filter(
      (b) =>
        !deletedBookmarks.includes(b) || recentlyDeletedBookmarks.includes(b)
    )
  }
  return (
    <ClinPageContentFrame
      crumbs={[
        isAusGaUser(countryCode, userRole) && enableNewHomePage
          ? {
              path: '/home',
              name: t('navigation:home')
            }
          : { path: '/orders', name: t('orders:page_name') },
        { path: '/bookmarks', name: t('bookmarks:title') }
      ]}
    >
      <Row>
        <Col xs={12}>
          <ClinText
            as="h1"
            variant={TypographyVariant.H2}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {t('bookmarks:title')}
          </ClinText>
          <ClinTabs
            activeItemIndex={selectedTabIndex}
            handleSelected={onTabSelected}
          >
            <ClinTabHeader title={t('bookmarks:generics')} />
            <ClinTabHeader title={t('bookmarks:product_variants')} />
          </ClinTabs>
          <ClinSpacer height={ClinTheme.space[4]} />
          {bookmarks === null && (
            <>
              <ClinSpacer height={ClinTheme.space[5]} />
              <ClinGroup justifyContent={'space-around'}>
                <ClinSpinner />
              </ClinGroup>
              <ClinSpacer height={ClinTheme.space[5]} />
            </>
          )}
          {bookmarks && bookmarks.length === 0 && (
            <NoBookmarksMessage translateFn={t} />
          )}
          {bookmarks && bookmarks.length > 0 && (
            <>
              {itemType === 'Generic' &&
                bookmarks.map((bookmark, index) => {
                  const isDeleted = recentlyDeletedBookmarks.includes(bookmark)
                  if (!bookmark.value?.catalog) {
                    if (isDeleted) {
                      return null
                    }
                    // I think this should be rare, possibly if the bookmarked product is deleted
                    return (
                      <ClinCard key={`${bookmark.bookmarkId}-${index}`}>
                        <ClinGroup
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <ClinText
                            as="div"
                            color={ClinTheme.colors.primary}
                            fontWeight={ClinTheme.fontWeights.medium}
                            marginBottom={ClinTheme.space[2]}
                          >
                            {t('bookmarks:generic_name')}
                          </ClinText>
                          <ClinButtonIcon
                            iconName={ClinIconPathName.Cross}
                            onClick={() => onClickDelete(bookmark)}
                          />
                        </ClinGroup>
                        <ClinText as="div" marginBottom={ClinTheme.space[2]}>
                          {bookmark.itemId}
                        </ClinText>
                        <ClinText as="div" marginBottom={ClinTheme.space[2]}>
                          {t('bookmarks:cannot_display')}{' '}
                        </ClinText>
                      </ClinCard>
                    )
                  }
                  return (
                    <GenericCard
                      cta={t('common:buttons.view')}
                      key={bookmark.bookmarkId}
                      countryCode={countryCode}
                      catalogueItem={bookmark.value.catalog}
                      onClickDelete={() => onClickDelete(bookmark)}
                      onClick={() => onClick(bookmark)}
                      onToggleExpandBrands={() =>
                        onToggleExpandBrands(bookmark)
                      }
                      isBrandsExpanded={bookmarksWithBrandsExpanded.includes(
                        bookmark
                      )}
                      isDeleted={isDeleted}
                    />
                  )
                })}
              {itemType === 'Sku' && (
                <>
                  {bookmarks.map((bookmark) => {
                    const isDeleted =
                      recentlyDeletedBookmarks.includes(bookmark)
                    if (!bookmark.value?.sku && isDeleted) {
                      return null
                    }
                    return (
                      <React.Fragment key={bookmark.bookmarkId}>
                        {bookmark.value.sku && (
                          <VariantCard
                            catalogueItem={getVariantInformationForCatalogItem(
                              bookmark.value.sku,
                              countryCode,
                              getDefaultWarehouseInfoForSku(
                                bookmark.value.sku,
                                countryCode
                              )
                            )}
                            cta={t('common:buttons.view')}
                            onClickDelete={() => onClickDelete(bookmark)}
                            onClick={() => onClick(bookmark)}
                            key={bookmark.bookmarkId}
                            isDeleted={isDeleted}
                          >
                            <ClinText
                              fontWeight={ClinTheme.fontWeights.bold}
                              fontSize={ClinTheme.fontSizes[4]}
                              color={ClinTheme.colors.primary}
                              marginBottom={ClinTheme.space[2]}
                            >
                              {bookmark.value.sku.products
                                .map((p) => p.genericName)
                                .join(', ')}
                            </ClinText>
                          </VariantCard>
                        )}
                      </React.Fragment>
                    )
                  })}
                </>
              )}
              <ClinPagination
                currentPage={getCurrentPage(pagination)}
                totalPages={getTotalPages(pagination)}
                pageSize={pagination.take}
                handlePageSelected={(pageIndex) =>
                  onPageSelected && onPageSelected(pageIndex)
                }
                handlePageSizeChange={onPageSizeChange}
              />
            </>
          )}
        </Col>
      </Row>
    </ClinPageContentFrame>
  )
}
