import React, { FunctionComponent } from 'react'
import { DateTime } from 'luxon'
import { Col, Row } from 'react-grid-system'
import { ClinTheme } from '../../../ClinTheme'
import { ClinText } from '../../../components/ClinText'
import { PatientDetailsDto } from '../../../types/swaggerTypes'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { useTranslation } from 'react-i18next'

interface ITransferPatientConfirmation {
  /** Selected patient */
  patient?: PatientDetailsDto
}

export const PatientDetails: FunctionComponent<
  ITransferPatientConfirmation
> = ({ patient }) => {
  const { t } = useTranslation()
  return (
    <>
      <Row>
        <Col sm={12} md={9}>
          <ClinText
            as="h5"
            variant={TypographyVariant.H4}
            color={ClinTheme.colors.primary}
            fontWeight={ClinTheme.fontWeights.bold}
            marginBottom={ClinTheme.space[4]}
          >
            {t('transfer_patient_to_physician:please_confirm')}
          </ClinText>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4}>
          <ClinText variant={TypographyVariant.LargeParagraph}>
            {t('transfer_patient_to_physician:patient_id')}
          </ClinText>
          <ClinText
            className="patient-id"
            variant={TypographyVariant.LargeParagraph}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {patient?.patientNumber}
          </ClinText>
        </Col>
        <Col sm={12} md={4}>
          <ClinText variant={TypographyVariant.LargeParagraph}>
            {t('transfer_patient_to_physician:initials')}
          </ClinText>
          <ClinText
            className="initials"
            variant={TypographyVariant.LargeParagraph}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {patient?.initials}
          </ClinText>
        </Col>
        <Col sm={12} md={4}>
          <>
            <ClinText variant={TypographyVariant.LargeParagraph}>
              {t('transfer_patient_to_physician:treating_physician')}
            </ClinText>

            <ClinText
              className="patients-treating-physician"
              variant={TypographyVariant.LargeParagraph}
              fontWeight={ClinTheme.fontWeights.bold}
            >
              {patient?.currentPhysician?.physicianFullName
                ? `${patient?.currentPhysician?.physicianTitle ?? ''} ${
                    patient?.currentPhysician?.physicianFirstName
                  } ${patient?.currentPhysician?.physicianLastName}`
                : t('glossary:not_applicable')}
            </ClinText>
          </>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={4}>
          <ClinText variant={TypographyVariant.LargeParagraph}>
            {t('transfer_patient_to_physician:institute_identifier')}
            Institute Identifier:
          </ClinText>
          <ClinText
            className="patient-ref-number"
            variant={TypographyVariant.LargeParagraph}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {patient?.hospitalRefNo
              ? patient?.hospitalRefNo
              : t('glossary:not_applicable')}
          </ClinText>
        </Col>
        <Col sm={12} md={4}>
          <ClinText variant={TypographyVariant.LargeParagraph}>
            {t('transfer_patient_to_physician:date_of_birth')}
          </ClinText>
          <ClinText
            className="date-of-birth"
            variant={TypographyVariant.LargeParagraph}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {patient?.dateOfBirth
              ? DateTime.fromISO(patient?.dateOfBirth, {zone: 'utc'}).toLocaleString()
              : t('glossary:not_applicable')}
          </ClinText>
        </Col>
      </Row>
    </>
  )
}
