import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { ClinCard } from '../../components/ClinCard'

export const StyledContactName = styled.div({
  borderTop: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.primaryLight}`,
  paddingTop: ClinTheme.space[2]
})

export const StyledContactCard = styled(ClinCard)({
  padding: ClinTheme.space[4]
})
