import * as React from 'react'

import { IDefaultStyleProps } from '../../types'

import { StyledGroup } from './ClinGroup.styles'
import { ClinTheme } from '../../ClinTheme'

export interface IGroupStyles {
  /** The space between the buttons */
  space?: number
  /** Css justify-content value */
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'initial'
    | 'inherit'
  /** Css justify-content value */
  alignItems?:
    | 'stretch'
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'baseline'
    | 'initial'
    | 'inherit'
  /** Direction of group */
  direction?: 'row' | 'column'
  display?: 'block' | 'flex'
}

interface IButtonGroupProps extends IDefaultStyleProps, IGroupStyles {
  /** ReactNode to display as child */
  children: React.ReactNode
}

export const ClinGroup: React.FC<IButtonGroupProps> = ({
  className = '',
  children,
  space = ClinTheme.space[2],
  justifyContent = 'initial',
  alignItems = 'initial',
  direction = 'row',
  display = 'flex'
}) => {
  return (
    <StyledGroup
      className={`clin-group ${className}`}
      space={space}
      justifyContent={justifyContent}
      alignItems={alignItems}
      direction={direction}
      display={display}
    >
      {children}
    </StyledGroup>
  )
}
