import styled from 'styled-components'

import { IVerticalSpacerStyles } from './ClinSpacer'
import { ClinTheme } from '../../ClinTheme'
export const StyledVerticalSpacer = styled.div<IVerticalSpacerStyles>(
  {
    position: 'relative',
    display: 'block',
    width: '100%'
  },
  ({ height, hasBorder, zIndex, color }) => ({
    height,
    ':before': {
      content: hasBorder ? `" "` : '',
      position: 'absolute',
      top: '50%',
      width: '100%',
      height: '1px',
      background: color ? color : ClinTheme.colors.midGrey
    },
    zIndex
  })
)
