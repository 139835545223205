import {
  NewPhysicianEnrollDto,
  PhysiciansSummaryDto
} from '../types/swaggerTypes'

export const isPhysicianUnique = (
  savedPhysicians: NewPhysicianEnrollDto[] | undefined,
  selectedPhysicians: PhysiciansSummaryDto[] | undefined,
  email: string
): boolean => {
  const foundSavedPhysician =
    savedPhysicians &&
    savedPhysicians.find(
      (physician: NewPhysicianEnrollDto) =>
        physician.emailAddress?.toUpperCase() === email.toUpperCase()
    )
  const foundSelectedPhysician =
    selectedPhysicians &&
    selectedPhysicians.find(
      (physician: PhysiciansSummaryDto) =>
        physician.physicianEmail?.toUpperCase() === email.toUpperCase()
    )

  return !foundSavedPhysician && !foundSelectedPhysician
}
