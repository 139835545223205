import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQueryDown } from '../../utils/mediaQuery'
import { Col, Row } from 'react-grid-system'
import { ClinButton } from '../../components/ClinButton'

const MediumUpBreakpoint = ClinTheme.breakpoints[1]

interface IPatientWrapperProps {
  background?: string
  showtableintab?: string
}

interface ICreateButtonWrapper {
  showGlowEffect?: boolean
}

export const StyledPatientSearchDivWrapper = styled.div<IPatientWrapperProps>(
  {},
  ({ background, showtableintab }) => ({
    background: background ? background : `${ClinTheme.colors.white}`,
    borderBottomLeftRadius:
      showtableintab === 'true' ? `${ClinTheme.space[2]}px` : 0,
    borderBottomRightRadius:
      showtableintab === 'true' ? `${ClinTheme.space[2]}px` : 0,
    [mediaQueryDown(ClinTheme.breakpoints[0])]: {
      borderTopLeftRadius:
        showtableintab === 'true' ? `${ClinTheme.space[2]}px` : 0,
      borderTopRightRadius:
        showtableintab === 'true' ? `${ClinTheme.space[2]}px` : 0
    }
  })
)

export const StyledPatientSearchWrapper = styled(Row)<IPatientWrapperProps>(
  {
    display: 'flex',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    maxWidth: '1140px'
  },
  ({ showtableintab }) => ({
    paddingTop: showtableintab === 'true' ? `${ClinTheme.space[3]}px` : '37px',
    paddingBottom:
      showtableintab === 'true' ? `${ClinTheme.space[3]}px` : '27px'
  })
)

export const StyledPatientSearchContentWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  position: 'relative'
})

export const StyledPatientSearchButtonWrapper = styled(
  Col
)<IPatientWrapperProps>(
  {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  ({ showtableintab }) => ({
    [mediaQueryDown(MediumUpBreakpoint)]: {
      justifyContent: showtableintab === 'true' ? 'start' : 'center',
      marginTop: '15px'
    }
  })
)

export const StyledPatientSwitchButtonWrapper = styled.span({
  display: 'flex',
  alignItems: 'center',
  marginRight: '5px'
})

export const StyledPatientCreateButton = styled(
  ClinButton
)<ICreateButtonWrapper>(({ showGlowEffect }) => ({
  boxShadow: showGlowEffect ? '0px 0px 20px 0px #FFF' : 'unset',
  zIndex: showGlowEffect ? 3 : 0
}))
