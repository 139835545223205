import React, { FunctionComponent } from 'react'
import SwiperCore, { Navigation, Pagination, Keyboard } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import {
  StyleRecentSkuCards,
  StyleRecentSkuCard,
  StyledDetailDescription,
  StyledDetailRow,
  StyleRecentSkuCardDetail
} from './RecentSkuCards.styles'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinButton } from '../../components/ClinButton'
import { CatalogItemDto } from '../../types/swaggerTypes'
import { IAugmentedCatalogItemDto } from '../../services/PortfolioJourneys/PortfolioJourney.types'
import {
  DrugProperty,
  drugPropertyTitleAndIconRecord
} from '../../services/DrugPropertyProvider'
import { useTranslation } from 'react-i18next'
// import { PortfolioJourneyDebug } from '../../services/PortfolioJourneys/PortfolioJourneyDebug'

interface IRecentSkuProps {
  // An array of recent skus
  skus?: IAugmentedCatalogItemDto[]
  /** Called when the view product button is clicked */
  onClickViewProduct?: (sku: CatalogItemDto) => void
}

SwiperCore.use([Navigation, Pagination, Keyboard])

export const RecentSkuCards: FunctionComponent<IRecentSkuProps> = ({
  skus,
  onClickViewProduct
}) => {
  const { t } = useTranslation()

  if (skus === undefined || skus.length === 0) {
    return null
  }

  return (
    <StyleRecentSkuCards>
      <ClinText
        marginBottom={ClinTheme.space[6]}
        as="h2"
        fontWeight={ClinTheme.fontWeights.bold}
        variant={TypographyVariant.H2}
      >
        {t('orders:recent_skus.title')}:
      </ClinText>
      <Swiper
        keyboard={{ enabled: true }}
        pagination={{ clickable: true }}
        breakpoints={{
          380: {
            slidesPerView: 1,
            slidesPerGroup: 1
          },
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2
          },
          992: {
            slidesPerView: 3,
            slidesPerGroup: 3
          }
        }}
        updateOnWindowResize
        spaceBetween={ClinTheme.space[4]}
      >
        {skus.map((sku: IAugmentedCatalogItemDto, index) => (
          <SwiperSlide key={`slide-${index}`}>
            <StyleRecentSkuCard>
              <StyleRecentSkuCardDetail>
                <ClinText
                  color={ClinTheme.colors.primaryMid}
                  as="span"
                  variant={TypographyVariant.H5}
                >
                  {t('orders:recent_skus.card_title')}:
                </ClinText>
                <ClinText as="h5" fontSize={ClinTheme.fontSizes[3]}>
                  {sku.products.map((product) => (
                    <ClinText
                      as="div"
                      variant={TypographyVariant.LargeParagraph}
                      key={product.genericName}
                      marginTop={ClinTheme.space[1]}
                      marginBottom={ClinTheme.space[1]}
                    >
                      {product.genericName} {product.strength}
                    </ClinText>
                  ))}
                </ClinText>
                <ClinSpacer />
                <StyledDetailRow>
                  <ClinIcon iconName={ClinIconPathName.BrandName} />
                  <StyledDetailDescription>
                    <ClinText
                      color={ClinTheme.colors.primaryMid}
                      as="span"
                      variant={TypographyVariant.H5}
                    >
                      {t(
                        drugPropertyTitleAndIconRecord[DrugProperty.Brand]
                          .translationKey
                      )}
                    </ClinText>
                    <ClinText>{sku.brandNormalized}</ClinText>
                  </StyledDetailDescription>
                </StyledDetailRow>
                <StyledDetailRow>
                  <ClinIcon iconName={ClinIconPathName.ProductCode} />
                  <StyledDetailDescription>
                    <ClinText
                      color={ClinTheme.colors.primaryMid}
                      as="span"
                      variant={TypographyVariant.H5}
                    >
                      {t(
                        drugPropertyTitleAndIconRecord[DrugProperty.ProductCode]
                          .translationKey
                      )}
                    </ClinText>
                    <ClinText>{sku.sku}</ClinText>
                  </StyledDetailDescription>
                </StyledDetailRow>
                <StyledDetailRow>
                  <ClinIcon iconName={ClinIconPathName.PackSize} />
                  <StyledDetailDescription>
                    <ClinText
                      color={ClinTheme.colors.primaryMid}
                      as="span"
                      variant={TypographyVariant.H5}
                    >
                      {' '}
                      {t(
                        drugPropertyTitleAndIconRecord[
                          DrugProperty.DosageAndPacksize
                        ].translationKey
                      )}
                    </ClinText>
                    <ClinText>
                      {sku.item.formulation} {sku.item.packSize}
                    </ClinText>
                  </StyledDetailDescription>
                </StyledDetailRow>
                <StyledDetailRow>
                  <ClinIcon iconName={ClinIconPathName.Availability} />
                  <StyledDetailDescription>
                    <ClinText
                      color={ClinTheme.colors.primaryMid}
                      as="span"
                      variant={TypographyVariant.H5}
                    >
                      {t(
                        drugPropertyTitleAndIconRecord[
                          DrugProperty.Availability
                        ].translationKey
                      )}
                    </ClinText>
                    <ClinText
                      color={ClinTheme.colors.greenValid}
                      fontWeight={ClinTheme.fontWeights.medium}
                    >
                      <ClinText
                        as="span"
                        color={sku.availableColour}
                        fontWeight={ClinTheme.fontWeights.bold}
                        marginBottom={ClinTheme.space[3]}
                      >
                        {sku.availableText}
                      </ClinText>
                    </ClinText>
                    {/*<PortfolioJourneyDebug catalogueItem={sku} />*/}
                  </StyledDetailDescription>
                </StyledDetailRow>
              </StyleRecentSkuCardDetail>
              <ClinButton
                style={{ width: '100%' }}
                variant="secondary"
                onClick={() => onClickViewProduct && onClickViewProduct(sku)}
              >
                {t('orders:recent_skus.view_medicines')}
              </ClinButton>
            </StyleRecentSkuCard>
          </SwiperSlide>
        ))}
      </Swiper>
    </StyleRecentSkuCards>
  )
}
