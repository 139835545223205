import { useEffect, useRef } from 'react'

/**
 * Custom hook to call a function once on mount
 * @param func
 */
export const useOnMount = (func: Function) => {
  const isInitialMount = useRef(true)
  useEffect(() => {
    if (isInitialMount.current) {
      func()
      isInitialMount.current = false
    }
  }, [func])
}
