import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { ISelectStyles, ClinSelectVariant } from './ClinSelect'
import { ClinIcon } from '../ClinIcon'

const getBoxShadow = (
  isValid?: boolean,
  hasError?: boolean,
  hasFocus?: boolean,
  hover?: boolean
) => {
  let boxShadowColor

  if (hasError) {
    boxShadowColor = ClinTheme.colors.redInvalid
  } else if (isValid) {
    boxShadowColor = ClinTheme.colors.greenValid
  } else if (hasFocus) {
    boxShadowColor = ClinTheme.colors.primaryMid
  } else if (hover) {
    boxShadowColor = ClinTheme.colors.grey
  } else {
    boxShadowColor = 'none'
  }
  return `0px 0px 3px 0px ${boxShadowColor}`
}

const getStyles = (disabled: boolean, isValid: boolean, hasError: boolean) => {
  if (isValid) {
    return {
      borderColor: disabled ? '' : ClinTheme.colors.greenValid,
      boxShadow: disabled ? '' : getBoxShadow(isValid)
    }
  }

  if (hasError) {
    return {
      borderColor: disabled ? '' : ClinTheme.colors.redInvalid,
      boxShadow: disabled ? '' : getBoxShadow(true, hasError)
    }
  }
}

export const StyledSelectSpan = styled.span<ISelectStyles>(
  {
    display: 'block',
    position: 'relative',
    boxSizing: 'border-box',
    fontFamily: ClinTheme.fonts.body,
    fontSize: ClinTheme.fontSizes[2],
    width: '100%',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    border: `solid ${ClinTheme.borderWidths[0]}px ${ClinTheme.colors.grey}`,
    borderRadius: ClinTheme.radii[2],
    transition: '.1s linear box-shadow',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  ({
    disabled,
    isValid,
    hasError,
    variant,
    lineHeight,
    padding,
    color,
    background
  }) => ({
    color: color ?? 'inherit',
    lineHeight: lineHeight ?? `${ClinTheme.fontSizes[4] - 2}px`,
    padding:
      padding ??
      `${ClinTheme.space[2]}px ${ClinTheme.space[4]}px ${ClinTheme.space[2]}px ${ClinTheme.space[3]}px`,
    backgroundColor: disabled
      ? ClinTheme.colors.lightGrey
      : background ?? ClinTheme.colors.white,
    ...getStyles(disabled!, isValid!, hasError!),
    ...(variant === ClinSelectVariant.secondary
      ? {
          borderColor: 'transparent',
          color: ClinTheme.colors.primary,
          fontWeight: ClinTheme.fontWeights.bold,
          textAlign: 'right',
          paddingRight: ClinTheme.space[6],
          paddingLeft: ClinTheme.space[2],
          fontSize: ClinTheme.fontSizes[1]
        }
      : {})
  })
)

export const StyledSelectLabel = styled.label<ISelectStyles>(
  {
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  ({ disabled, width }) => ({
    color: disabled ? ClinTheme.colors.grey : ClinTheme.colors.black,
    ':hover': {
      [`> span`]: {
        borderColor: disabled ? '' : ClinTheme.colors.grey,
        boxShadow: disabled
          ? ''
          : getBoxShadow(undefined, undefined, undefined, true)
      }
    },
    ':focus-within, :focus': {
      [`> span`]: {
        borderColor: disabled ? '' : ClinTheme.colors.primaryMid,
        boxShadow: disabled ? '' : getBoxShadow(undefined, undefined, true)
      }
    },
    width
  })
)

export const StyledHiddenSelect = styled.select({
  position: 'absolute',
  top: '0',
  left: '0',
  height: '100%',
  width: '100%',
  opacity: 0,
  border: 'none',
  cursor: 'pointer',
  ':focus': {
    outline: 'none'
  },
  ':disabled': {
    cursor: 'not-allowed'
  }
})

interface IStyledIconProps {
  top?: number
}

export const StyledIcon = styled(ClinIcon)<IStyledIconProps>(
  {
    position: 'absolute',
    right: ClinTheme.space[2],
    pointerEvents: 'none'
  },
  ({ top }) => ({
    top: top ?? ClinTheme.space[2]
  })
)
