import React, { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { uploadDocument } from '../../services/ApiService'
import {
  BasketItemDocumentDto,
  UploadedDocumentDto
} from '../../types/swaggerTypes'
import {
  DocumentUpload,
  DocumentUploadPromptProps,
  IUploadedDetails
} from './DocumentUpload'
import { DocumentUploadAus } from './DocumentUploadAus'
import { IViewBasketItem } from '../../types'

interface IDocumentUploadContainerProps extends DocumentUploadPromptProps {
  /** Filename for an already selected file */
  defaultFileName?: string
  /** Whether user can remove a file */
  canRemoveFile?: boolean
  /** Custom styling for AUS */
  styleForAUS?: boolean
  /** Document details from a document which has already been uploaded */
  uploadDetails?: IUploadedDetails | null
  /** Called when a file has been uploaded */
  handleFileUploaded?: (document: UploadedDocumentDto) => void
  /** Called when file is removed, spinner shows until returned promise is resolved */
  handleFileRemoved?: () => Promise<void> | undefined
  handleSpecificFileRemoved?: (item: BasketItemDocumentDto) => void
  /** Maximum file size in MiB */
  maxFileSizeMiB?: number
  basketItem?: IViewBasketItem
}

export const DocumentUploadContainer: FunctionComponent<
  IDocumentUploadContainerProps
> = ({
  defaultFileName,
  canRemoveFile,
  styleForAUS,
  uploadDetails,
  basketItem,
  handleFileUploaded,
  handleFileRemoved,
  handleSpecificFileRemoved,
  maxFileSizeMiB = 6,
  ...props
}) => {
  const { t } = useTranslation()
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [isRemoving, setIsRemoving] = useState<boolean>(false)
  const [fileName, setFileName] = useState<string | undefined>(defaultFileName)
  const [error, setError] = useState<string | undefined>(undefined)

  return styleForAUS ? (
    <DocumentUploadAus
      {...props}
      isUploading={isUploading}
      isRemoving={isRemoving}
      fileName={fileName}
      styleForAUS={styleForAUS}
      showRemoveFile={canRemoveFile}
      uploadDetails={uploadDetails}
      error={error}
      basketItem={basketItem}
      handleClickRemove={(item: BasketItemDocumentDto) => {
        handleSpecificFileRemoved && handleSpecificFileRemoved(item)
      }}
      handleFileSelected={async (file) => {
        if (file.size > maxFileSizeMiB * 1024 * 1024) {
          setError(t('common:file_size_validation_aus'))
          return
        }
        setError(undefined)
        setIsUploading(true)
        setFileName(file.name)
        uploadDocument(file)
          .then((response) => {
            setIsUploading(false)
            const document = response.data
            handleFileUploaded && handleFileUploaded(document)
          })
          .catch((err) => {
            setIsUploading(false)
            setError(err.message)
            setFileName(undefined)
          })
      }}
    />
  ) : (
    <DocumentUpload
      {...props}
      isUploading={isUploading}
      isRemoving={isRemoving}
      fileName={fileName}
      styleForAUS={styleForAUS}
      showRemoveFile={canRemoveFile}
      uploadDetails={uploadDetails}
      error={error}
      handleClickRemove={async () => {
        setError(undefined)
        setIsRemoving(true)
        if (handleFileRemoved) {
          await handleFileRemoved()
        }
        setFileName(undefined)
        setIsRemoving(false)
      }}
      handleFileSelected={async (file) => {
        if (file.size > maxFileSizeMiB * 1024 * 1024) {
          setError(
            t('common:validation_warnings.file_size_to_large', {
              fileSize: maxFileSizeMiB
            })
          )
          return
        }
        setError(undefined)
        setIsUploading(true)
        setFileName(file.name)
        uploadDocument(file)
          .then((response) => {
            setIsUploading(false)
            const document = response.data
            handleFileUploaded && handleFileUploaded(document)
          })
          .catch((err) => {
            setIsUploading(false)
            setError(err.message)
            setFileName(undefined)
          })
      }}
    />
  )
}
