import { FunctionComponent, useEffect } from 'react'
import { useAppContext } from '../../context/app'
import { createLogInAuthEvent } from '../../events/AuthEvent'
import { useLocation } from 'react-router'

interface ILogInProps {
  redirectUrl: string
}

export const LogIn: FunctionComponent<ILogInProps> = ({ redirectUrl }) => {
  const { dispatch, isAuthenticated, user } = useAppContext()
  const location = useLocation()
  // Watch whether isAuthenticated or not and log in if so
  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(createLogInAuthEvent(redirectUrl ? redirectUrl : '/'))
    }
  }, [dispatch, isAuthenticated, user, redirectUrl, location.pathname])

  return null
}
