import React, { FunctionComponent } from 'react'
import { StyledNavTextAndChevron } from './ClinNavIconButton.styles'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import { ClinIcon } from '../ClinIcon'
import { ClinTheme } from '../../ClinTheme'
import { ClinNavUpperItem } from '../ClinNavItem/ClinNavItem'
import { StyledMainItemsName } from '../ClinNavbar/ClinNavbar.styles'
import ColumnTooltip from '../../pages/OrderPages/Orders/ColumnTooltip/ColumnTooltip'

interface INavIconButtonProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Title of the icon */
  title?: string
  /** Name of the icon path */
  iconName?: ClinIconPathName
  id?: string
  toLink: string
  disabled?: boolean
  className?: string
  isCurrentPath?: boolean
  iconViewBox?: string
  tooltip?: string
  onClick?: () => void
}

export const ClinNavIconButton: FunctionComponent<INavIconButtonProps> = ({
  title = 'Account',
  iconName = ClinIconPathName.Profile,
  id,
  disabled,
  toLink,
  isCurrentPath,
  iconViewBox,
  className,
  tooltip,
  onClick,
  ...rest
}) => (
  <StyledNavTextAndChevron
    style={{ marginRight: '15px', marginTop: '0' }}
    className={className}
    id={id}
  >
    {tooltip ? (
      <ColumnTooltip tooltipText={tooltip}>
        <ClinNavUpperItem
          onClick={onClick}
          disabled={disabled}
          to={toLink}
          exact={true}
          strict={true}
          current={isCurrentPath}
        >
          <ClinIcon
            style={{ marginRight: '5px' }}
            iconName={iconName}
            iconSize={ClinTheme.space[4]}
            viewBox={iconViewBox ?? undefined}
            iconFill={ClinTheme.colors.white}
          />
          <StyledMainItemsName className="item-name">
            {title}
          </StyledMainItemsName>
        </ClinNavUpperItem>
      </ColumnTooltip>
    ) : (
      <ClinNavUpperItem
        onClick={onClick}
        disabled={disabled}
        to={toLink}
        exact={true}
        strict={true}
        current={isCurrentPath}
      >
        <ClinIcon
          style={{ marginRight: '5px' }}
          iconName={iconName}
          iconSize={ClinTheme.space[4]}
          viewBox={iconViewBox ?? undefined}
          iconFill={ClinTheme.colors.white}
        />
        <StyledMainItemsName className="item-name">{title}</StyledMainItemsName>
      </ClinNavUpperItem>
    )}
  </StyledNavTextAndChevron>
)
