import { lighten } from 'polished'
import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'

const mobileUpBreakpoint = ClinTheme.breakpoints[1]

export interface ISearchInputStyles {
  borderRadious?: string
  maxWidth?: string
  textIndent?: number
  fontSize?: number
  height?: string
  borderColor?: string
  boxShadow?: string
}

export interface ISearchStyles {
  borderRadious?: string
  maxWidth?: string
  marginLeft?: number
  zIndex?: number
}

export const StyledSearch = styled.div<ISearchStyles>(
  {
    position: 'relative'
  },
  ({ maxWidth, marginLeft, zIndex }) => ({
    width: maxWidth ?? '100%',
    zIndex: zIndex ?? 0,
    [mediaQuery(mobileUpBreakpoint)]: {
      marginLeft: marginLeft ?? ClinTheme.space[2],
      marginRight: ClinTheme.space[3]
    }
  })
)

export const StyledSearchInput = styled.input<ISearchInputStyles>(
  {
    boxSizing: 'border-box',
    color: ClinTheme.colors.black,
    fontWeight: ClinTheme.fontWeights.normal,
    fontFamily: ClinTheme.fonts.body,
    lineHeight: `${ClinTheme.fontSizes[4] - 2}px`, // subtract top and bottom border 1px widths
    letterSpacing: ClinTheme.letterSpacing[0],
    padding: ClinTheme.space[2],
    transition: '.1s linear box-shadow',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: ClinTheme.space[7],
    [`:focus`]: {
      outline: 'none',
      border: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.primaryMid}`,
      boxShadow: `0px 0px 3px 0px ${ClinTheme.colors.primaryMid}`
    },
    '::placeholder': {
      color: lighten(0.5, ClinTheme.colors.black),
      textOverflow: 'ellipsis'
    },
    ':-ms-input-placeholder': {
      // Special case for Text Inputs to have correct height in IE 11
      height: '40px',
      textOverflow: 'ellipsis'
    },
    ':disabled': {
      cursor: 'not-allowed'
    }
  },
  ({
    borderRadious,
    maxWidth,
    textIndent,
    fontSize,
    height,
    borderColor,
    boxShadow
  }) => ({
    boxShadow: boxShadow ?? 'unset',
    borderRadius: borderRadious ? `${borderRadious}px` : ClinTheme.radii[2],
    width: maxWidth ?? '100%',
    textIndent: textIndent ?? ClinTheme.space[2],
    fontSize: fontSize ?? ClinTheme.fontSizes[2],
    height: height ?? '40px',
    border: borderColor
      ? `${ClinTheme.borderWidths[0]}px solid ${borderColor}`
      : `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`
  })
)
