import { AxiosError } from 'axios'
import React, { FunctionComponent, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { useAppContext } from '../../context/app'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { SortDirectionType } from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { useUpdateQueryParams } from '../../components/ClinPagination/ClinPagination.model'
import {
  cancelGetPhysicanPatients,
  cancelGetPhysicianById,
  disassociatePhysician,
  discontinuePhysician,
  getPhysicianById,
  getPhysicianPatients
} from '../../services/ApiService'
import {
  FacetDto,
  InstituteDto,
  PatientSummaryDto,
  PatientSummaryAssociatedToPhysiciansSearchDto,
  PhysicianDetailRequestDto,
  PhysicianDetailsDto
} from '../../types/swaggerTypes'
import { PhysicianDetails } from './PhysicianDetails'
import {
  getSortColumnFromURLParams,
  isDiscontinueAvailable,
  ProgramColumn,
  programColumns
} from './PhysicianDetails.models'
import { $enum } from 'ts-enum-util'
import { DissociateModal } from './DissociateModal'
import { AssociatedPatientsContainer } from '../../features/AssociatedPatients'
import { TransferOrDiscontinueModal } from './TransferOrDiscontinueModal'
import {
  AssociatedPatientsSortField,
  AssociatedPatientsStatus
} from '../../features/AssociatedPatients/AssociatedPatients.model'
import { useOnMount } from '../../utils/useOnMount'
import { useErrorMessage } from '../../utils/useErrorMessage'
import { DiscontinueModal } from './DiscontinueModal/DiscontinueModal'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../services/Analytics'
import { useEffectOnlyOnce } from '../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import { IRestEndpointState } from '../../types/IRestEndpointState'
import { AnalyticsPageEvent } from '../../services/Analytics/AnalyticsPageEvent'

export enum ModalMode {
  active,
  submitting,
  hasError
}

const defaultSearchParams: PhysicianDetailRequestDto = {
  sortProgram: [
    {
      sortBy: SortDirectionType.Ascending.toUpperCase(),
      name: programColumns[0].name
    }
  ]
}

interface IPhysicianDetailsRouteParams {
  physicianId: string
}
interface IPhysicianDetailsProps
  extends RouteComponentProps<IPhysicianDetailsRouteParams> {}

export const PhysicianDetailsContainer: FunctionComponent<
  IPhysicianDetailsProps
> = ({ history, location, match }) => {
  const { physicianId } = match.params

  const defaultPatientsSearchParams: PatientSummaryAssociatedToPhysiciansSearchDto =
    {
      query: '',
      filter: {
        programs: [],
        patientStatuses: [AssociatedPatientsStatus.ActiveTreatment]
      },
      pagination: {
        skip: 0,
        take: 5
      },
      sorting: {
        sortBy: AssociatedPatientsSortField.Status,
        order: SortDirectionType.Ascending.toUpperCase()
      }
    }
  const { dispatch, institute, portfolioCountryCode } = useAppContext()
  // Restore page state from URL params
  const urlSearchParams = new URLSearchParams(location.search)

  const [physician, setPhysician] = React.useState<PhysicianDetailsDto>()
  const [patients, setPatients] = React.useState<
    PatientSummaryDto[] | undefined
  >(undefined)
  // TabIndex
  const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(() => {
    const tabIndex = urlSearchParams.get('tabIndex')
    let defaultTabIndex: number = 0
    if (typeof tabIndex === 'string') {
      defaultTabIndex = parseInt(tabIndex)
    }
    return defaultTabIndex
  })
  const [isPhysicianLoading, setIsPhysicianLoading] = React.useState(false)
  const [isPatientsLoading, setIsPatientsLoading] = React.useState(false)
  const [isDiscontinued, setIsDiscontinued] = React.useState(false)
  const [searchParams, setSearchParams] = React.useState(defaultSearchParams)
  const [patientStatusFilter, setPatientStatusFilter] = React.useState<
    FacetDto[] | undefined
  >()

  // Sorting column
  const [sortColumn, setSortColumn] = React.useState<ProgramColumn>(() => {
    const defaultSortColumn = programColumns[0] // Default is ProgramName
    const restoredSortByColumn = getSortColumnFromURLParams(
      urlSearchParams,
      defaultSortColumn
    )
    defaultSearchParams.sortProgram[0].name = restoredSortByColumn
      ? restoredSortByColumn.name.toString()
      : defaultSortColumn.name.toString()
    return restoredSortByColumn
  })
  // Sorting direction
  const [sortDirection, setSortDirection] = React.useState<SortDirectionType>(
    () => {
      const sortDirection = urlSearchParams.get('sortDirection')
      const restoredSortDirection = $enum(SortDirectionType).asValueOrDefault(
        sortDirection,
        SortDirectionType.Ascending
      )
      defaultSearchParams.sortProgram[0].sortBy = restoredSortDirection
        .toString()
        .toUpperCase()
      return restoredSortDirection
    }
  )

  const [isTransferOrDiscontinueOpen, setIsTransferOrDiscontinueOpen] =
    React.useState<boolean>(false)

  const [dissociateModalMode, setDissociateModalMode] = React.useState<
    ModalMode | undefined
  >()
  const [dissociateError, setDissociateError] = React.useState<string>()

  const [discontinueModalMode, setDiscontinueModalMode] = React.useState<
    ModalMode | undefined
  >()
  const [discontinueError, setDiscontinueError] = React.useState<string>()

  const handlePatientsError = useErrorMessage(
    'There was an error fetching patients.'
  )
  const handlePhysicianDetailsError = useErrorMessage(
    'There was an error fetching the physicians details.'
  )

  const handleGoBack = () => {
    history.push('/programs/my-physicians')
  }

  const handleProgramRowClicked = (programId: number) => {
    history.push(`/programs/access-programs/${programId}`)
  }

  const toggleSortDirection = (
    current: SortDirectionType
  ): SortDirectionType => {
    if (current === SortDirectionType.None) {
      return SortDirectionType.Descending
    }
    if (current === SortDirectionType.Descending) {
      return SortDirectionType.Ascending
    }
    return SortDirectionType.None
  }

  const handleToggleSort = (columnName: ProgramColumn) => {
    setIsPhysicianLoading(true)
    const newSortDirection =
      sortColumn.viewName === columnName.viewName
        ? toggleSortDirection(sortDirection)
        : SortDirectionType.Descending
    setSortDirection(newSortDirection)
    setSortColumn(columnName)
    setSearchParams({
      ...searchParams,
      sortProgram: [
        {
          sortBy: newSortDirection.toUpperCase(),
          name: columnName.name
        }
      ]
    })
  }

  useOnMount(() => {
    getPhysicianPatients(Number(physicianId), defaultPatientsSearchParams)
      .then((response) => {
        setIsPatientsLoading(false)
        if (response) {
          setPatients(response.data.result)
        }
      })
      .catch((error: AxiosError) => {
        handlePatientsError(error)
        setIsPatientsLoading(false)
      })

    return () => {
      cancelGetPhysicanPatients()
    }
  })

  useEffect(() => {
    getPhysicianById(physicianId, searchParams)
      .then((response) => {
        setIsPhysicianLoading(false)
        if (response) {
          setPhysician(response.data)
        }
      })
      .catch((error: AxiosError) => {
        handlePhysicianDetailsError(error)
        setIsPhysicianLoading(false)
      })

    return () => {
      cancelGetPhysicianById()
    }
  }, [dispatch, handlePhysicianDetailsError, physicianId, searchParams])

  const handleRequestToDissociate = () => {
    setDissociateModalMode(ModalMode.active)
  }

  // handlers of 'Transfer or discontinue all patients associated with physician' modal window
  const handleViewPatients = () => {
    setSelectedTabIndex(0)
    setPatientStatusFilter([
      { value: AssociatedPatientsStatus.ActiveTreatment, count: 1 }
    ])
    setIsTransferOrDiscontinueOpen(false)
  }

  const handleTransferOrDiscontinueClose = () => {
    setIsTransferOrDiscontinueOpen(false)
  }

  // handlers of disassociate modal window
  const handleDissociateConfirmation = () => {
    setDissociateModalMode(ModalMode.submitting)
    physicianId &&
      disassociatePhysician(physicianId)
        .then((response) => {
          if (response.status === 200) {
            handleGoBack()
            setTimeout(() => {
              dispatch(
                createAnnounceEvent(
                  AnnounceMode.Success,
                  `${physician?.physicianTitle ?? ''} ${
                    physician?.physicianFirstName
                  } ${
                    physician?.physicianLastName
                  } has been removed from your physician list.`,
                  'Complete'
                )
              )
            }, 100)
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.DisassociatePhysician,
              {
                physicianId: physician?.physicianId,
                instituteId: institute.data?.instituteId
              }
            )
          }
        })
        .catch((error: AxiosError) => {
          setDissociateModalMode(ModalMode.hasError)
          setDissociateError(error.message)
        })
  }

  const handleDissociateClose = () => {
    setDissociateModalMode(undefined)
  }

  // handlers of discontinue modal window

  const handleRequestToRemove = () => {
    setDiscontinueModalMode(ModalMode.active)
  }

  const handleDiscontinueConfirmation = () => {
    if (patients && isDiscontinueAvailable(patients)) {
      setDiscontinueModalMode(ModalMode.submitting)
      physicianId &&
        discontinuePhysician(physicianId)
          .then((response) => {
            if (response.status === 200) {
              setDiscontinueModalMode(undefined)
              setIsDiscontinued(true)
              analyticsServiceSingleton.trackEvent(
                AnalyticsEvent.RemovePhysician,
                {
                  physicianId: physician?.physicianId,
                  instituteId: institute.data?.instituteId
                }
              )
            }
          })
          .catch((error: AxiosError) => {
            setDiscontinueModalMode(ModalMode.hasError)
            setDiscontinueError(error.message)
          })
    } else {
      setDiscontinueModalMode(undefined)
      setIsTransferOrDiscontinueOpen(true)
    }
  }

  const handleDiscontinueClose = () => {
    setDiscontinueModalMode(undefined)
  }

  useUpdateQueryParams([
    { sortColumn: sortColumn.name.toString() },
    { sortDirection: sortDirection.toString() },
    { tabIndex: selectedTabIndex }
  ])

  const physicianFullName = `${physician?.physicianTitle ?? ''} ${
    physician?.physicianFirstName
  } ${physician?.physicianLastName}`

  type Deps = [boolean, PhysicianDetailsDto, IRestEndpointState<InstituteDto>]
  useEffectOnlyOnce(
    (dependencies: Deps) => {
      analyticsServiceSingleton.trackPageView(
        AnalyticsPageEvent.ViewPhysician,
        {
          'Physician ID': dependencies[1].physicianId,
          'Institute ID': dependencies[2].data?.instituteId
        }
      )
    },
    [isPatientsLoading && isPhysicianLoading, physician, institute],
    (dependencies: Deps) =>
      dependencies[0] === false && dependencies[1] && dependencies[2]
  )

  return (
    <>
      {physician && (
        <>
          <DissociateModal
            isOpen={dissociateModalMode !== undefined}
            isSubmitting={dissociateModalMode === ModalMode.submitting}
            hasError={dissociateModalMode === ModalMode.hasError}
            errorMessage={dissociateError}
            physicianName={physicianFullName}
            handleConfirmation={handleDissociateConfirmation}
            handleClose={handleDissociateClose}
          />
          <DiscontinueModal
            isOpen={discontinueModalMode !== undefined}
            isSubmitting={discontinueModalMode === ModalMode.submitting}
            hasError={discontinueModalMode === ModalMode.hasError}
            errorMessage={discontinueError}
            handleConfirmation={handleDiscontinueConfirmation}
            handleClose={handleDiscontinueClose}
          />
          <TransferOrDiscontinueModal
            isOpen={isTransferOrDiscontinueOpen}
            physicianName={physicianFullName}
            handleConfirmation={handleViewPatients}
            handleClose={handleTransferOrDiscontinueClose}
          />
        </>
      )}
      <PhysicianDetails
        isLoading={isPatientsLoading && isPhysicianLoading}
        isDiscontinued={isDiscontinued}
        selectedTabIndex={selectedTabIndex}
        onTabSelected={setSelectedTabIndex}
        physician={physician}
        selectedSortColumn={sortColumn}
        selectedSortDirection={sortDirection}
        columns={programColumns}
        userCountry={portfolioCountryCode}
        handleToggleSort={handleToggleSort}
        handleGoBack={handleGoBack}
        handleRequestToDissociate={handleRequestToDissociate}
        handleRequestToRemove={handleRequestToRemove}
        handleProgramRowClicked={handleProgramRowClicked}
        renderAssociatedPatients={({ physicianId }) => (
          <AssociatedPatientsContainer
            physicianId={physicianId}
            preselectedPatientStatusFilters={patientStatusFilter}
          />
        )}
      />
    </>
  )
}
