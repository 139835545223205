import styled from 'styled-components'
import { lighten } from 'polished'
import { ClinTheme } from '../../ClinTheme'

export interface IToggleStyles {
  /** Css display value */
  display?: 'flex' | 'inline-flex'
  /** Disables the input */
  disabled?: boolean
  /** SwitchColor prop for modifying the css background-color value of the StyledSlider */
  switchColor?: string
  /**  Css for flex-direction  */
  flexDirection?: 'row' | 'row-reverse'
  /** React checked attribute */
  defaultChecked?: boolean
  /** Options for changing the size of the component */
  size?: 'normal' | 'large'
}

const getMarginStyles = (variant: string) => {
  const config: any = {
    row: {
      marginRight: ClinTheme.space[3]
    },
    'row-reverse': {
      marginLeft: ClinTheme.space[3]
    },
    default: {
      marginLeft: ClinTheme.space[3]
    }
  }
  return config[variant] ? config[variant] : config.default
}

const getSizeStyles = (size: string) => {
  const config: any = {
    normal: {
      [`${StyledSlider}`]: {
        height: ClinTheme.space[1] * 5,
        width: ClinTheme.space[1] * 5,
        '&:after': {
          height: ClinTheme.space[1] * 5,
          width: ClinTheme.space[1] * 5
        }
      },
      [`${StyledTrack}`]: {
        height: ClinTheme.space[3],
        width: ClinTheme.space[6],
        '&:after': {
          height: ClinTheme.space[1] * 5,
          width: ClinTheme.space[1] * 5
        }
      },
      [`${StyledCheckbox}`]: {
        [`:checked + ${StyledTrack} > ${StyledSlider}`]: {
          transform: `translate(${
            ClinTheme.space[6] - ClinTheme.space[1] * 7
          }px, -${ClinTheme.space[1] * 2.5}px)`
        }
      }
    },
    large: {
      [`${StyledSlider}`]: {
        marginTop: '-2px',
        height: ClinTheme.space[5],
        width: ClinTheme.space[5],
        '&:after': {
          height: ClinTheme.space[5],
          width: ClinTheme.space[5]
        }
      },
      [`${StyledTrack}`]: {
        height: ClinTheme.space[4],
        width: ClinTheme.space[9],
        '&:after': {
          height: ClinTheme.space[5],
          width: ClinTheme.space[5]
        }
      },
      [`${StyledCheckbox}`]: {
        [`:checked + ${StyledTrack} > ${StyledSlider}`]: {
          transform: `translate(${
            ClinTheme.space[6] - ClinTheme.space[1] * 4
          }px, -${ClinTheme.space[1] * 2.5}px)`
        }
      }
    }
  }
  return config[size]
}

export const StyledTrack = styled.span<IToggleStyles>(
  {
    borderRadius: ClinTheme.radii[4],
    backgroundColor: ClinTheme.colors.lightGrey,
    boxShadow: `inset 0 0 4px ${lighten(0.8, ClinTheme.colors.black)}`,
    transition: 'all .4s'
  },
  ({ flexDirection }) => ({
    ...getMarginStyles(flexDirection!)
  })
)

export const StyledSlider = styled.span<IToggleStyles>({
  boxSizing: 'content-box',
  display: 'block',
  borderRadius: '100%',
  position: 'absolute',
  transition: '.4s transform',
  backgroundClip: 'padding-box',

  '&:after': {
    display: 'block',
    content: "''",
    borderRadius: '100%',
    boxShadow: `0 0 5px ${lighten(0.7, ClinTheme.colors.black)}`
  }
})

export const StyledCheckbox = styled.input<IToggleStyles>(
  {
    position: 'absolute',
    opacity: 0,
    height: 0,
    width: 0
  },
  ({ switchColor = ClinTheme.colors.primaryMid, disabled }) => ({
    [`+ ${StyledTrack} > ${StyledSlider}`]: {
      border: `${ClinTheme.space[2]}px solid rgba(0,0,0,0)`,
      '&:hover': {
        border: disabled
          ? `${ClinTheme.space[2]}px solid rgba(0,0,0,0)`
          : `${ClinTheme.space[2]}px solid rgba(0,0,0,0.05)`
      }
    },
    [`:checked + ${StyledTrack} > ${StyledSlider}`]: {
      backgroundColor: switchColor
    },
    [`:checked + ${StyledTrack}`]: {
      backgroundColor: lighten(0.2, switchColor ?? ClinTheme.colors.grey),
      boxShadow: `inset 0 0 4px ${lighten(0.5, ClinTheme.colors.black)}`
    },
    [`:not(:checked) + ${StyledTrack} > ${StyledSlider}`]: {
      transform: `translate(-${ClinTheme.space[2]}px, -${
        ClinTheme.space[1] * 2.5
      }px)`,
      backgroundColor: ClinTheme.colors.white
    }
  })
)

export const StyledLabel = styled.label<IToggleStyles>(
  {
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    margin: ClinTheme.space[2]
  },
  ({ display, disabled, flexDirection, size }) => ({
    display,
    flexDirection,
    cursor: disabled ? 'not-allowed' : 'pointer',
    ...getSizeStyles(size!)
  })
)
