import { SideBarMode } from '../ProgramDetail.models'

export enum ProgramInfoVariant {
  AvailableInYourCountry,
  NotAvailableInYourCountry,
  Closed
}

export const getProgramInfoVariantConfig = (
  variant: SideBarMode | undefined
): PageInfoConfig | null => {
  switch (variant) {
    case SideBarMode.NotEnrolled:
      return {
        paddingTop: '40px',
        paddingBottom: '48px',
        buttonPaddingTop: '16px',
        showButton: true,
        buttonText: 'program_detail:enrol_program_button',
        description: 'program_detail:enrol_program_description',
        buttonWidth: '134px'
      }
    case SideBarMode.NotAvailableInYourCountry:
      return {
        paddingTop: '65px',
        paddingBottom: '96px',
        buttonPaddingTop: '30px',
        showButton: true,
        buttonText: 'program_detail:register_interest_button',
        description: 'program_detail:register_interest_description',
        buttonWidth: '239px'
      }
    case SideBarMode.Closed:
      return {
        paddingTop: '65px',
        paddingBottom: '65px',
        buttonPaddingTop: '16px',
        phoneLink: '+44 (0) 1932 824 100',
        emailLink: 'ukcustomerservice@clinigengroup.com',
        description: 'program_detail:closed_program_description'
      }
    default:
      return null
  }
}

export interface PageInfoConfig {
  paddingTop: string
  paddingBottom: string
  buttonPaddingTop: string
  showButton?: boolean
  buttonText?: string
  description: string
  buttonWidth?: string
  phoneLink?: string
  emailLink?: string
}
