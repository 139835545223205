import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'

export const StyledDebugContainer = styled.div({
  paddingLeft: '48px'
})

export const StyledDebugCode = styled.pre({
  fontSize: ClinTheme.fontSizes[0],
  whiteSpace: 'pre-line'
})
