import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'

export interface IClinDividerHeadingStyles {
  marginBottom?: number
}

export const StyledClinDividerHeading = styled.div<IClinDividerHeadingStyles>(
  ({ marginBottom }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: ClinTheme.space[4],
    marginBottom: marginBottom,
    width: '100%',
    background: ClinTheme.colors.primary,
    color: ClinTheme.colors.white,
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.24)',
    zIndex: 1,
    boxSizing: 'border-box'
  })
)
