import { useEffect, useState } from 'react'
import config from '../services/ConfigProvider'
import { IdsUserRole } from '../constants/index'
import { IUser } from '../types'

export const redirectToStsAuthority = () => {
  window.location.replace(config.stsAuthority)
}

/**
 * Find out if the user has Customer as a value within their list of roles, if not then redirect them to a url provided by stsAuthority
 * @param user
 */
export const useIDSRoleCheck = (user?: IUser): boolean => {
  const [isRoleLoaded, setIsRoleLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (user) {
      try {
        if (
          user?.profile?.role?.toUpperCase() ===
          IdsUserRole.CUSTOMER.toUpperCase()
        ) {
          setIsRoleLoaded(true)
        } else {
          redirectToStsAuthority()
        }
      } catch {
        redirectToStsAuthority()
      }
    }
  }, [user])

  return isRoleLoaded
}
