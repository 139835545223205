import { ApiBasketErrorType } from '../types/IBasketError'

export enum BasketErrorEventType {
  BASKET_ERROR = 'basket_error',
  CLEAR_ERROR = 'clear_error'
}

// Show Basket Error ------------------------------------------------------------------------

export interface IBasketShowErrorEvent {
  type: BasketErrorEventType.BASKET_ERROR
  errorType: ApiBasketErrorType
}

export const createBasketErrorEvent = (
  errorType: ApiBasketErrorType
): IBasketErrorEvent => ({
  type: BasketErrorEventType.BASKET_ERROR,
  errorType
})

// Clear Permissions Error ------------------------------------------------------------------------

export interface IBasketClearErrorEvent {
  type: BasketErrorEventType.CLEAR_ERROR
}

export const createBasketClearErrorEvent = (): IBasketClearErrorEvent => ({
  type: BasketErrorEventType.CLEAR_ERROR
})

export type IBasketErrorEvent = IBasketShowErrorEvent | IBasketClearErrorEvent
