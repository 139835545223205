import React, { FunctionComponent } from 'react'
import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { ClinButton } from '../../components/ClinButton'
import { Link } from 'react-router-dom'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { StyledFlexSpacer, StyledNoPatients } from './NoPrograms.styles'
import { ClinGroup } from '../../components/ClinGroup'
import { CountryDto } from '../../types/swaggerTypes'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../services/Analytics'
import { useTranslation } from 'react-i18next'

export interface INoPrograms {
  /** Selected tab allows to show correct message for user */
  selectedTabIndex?: number
  /** URL to link to */
  url?: string
  /** If the user is MA only or not */
  isMaUser?: boolean
  /** Supplied contact data */
  supportContact?: CountryDto
}

export const NoPrograms: FunctionComponent<INoPrograms> = ({
  selectedTabIndex,
  url = '/sourcing-enquiry',
  isMaUser,
  supportContact
}) => {
  const { t } = useTranslation()
  return (
    <StyledNoPatients>
      <ClinSpacer height={ClinTheme.space[8]} />
      <ClinIcon
        iconName={ClinIconPathName.ProductCode}
        iconFill={ClinTheme.colors.grey}
        iconSize={170}
      />
      <StyledFlexSpacer />
      {selectedTabIndex ? (
        <>
          <ClinText
            variant={TypographyVariant.H4}
            fontWeight={ClinTheme.fontWeights.bold}
            marginTop={ClinTheme.space[2]}
          >
            {t('program_documentation:no_results_title')}
          </ClinText>
          <StyledFlexSpacer />
          {isMaUser ? (
            <>
              <ClinText
                className={'msg-copy'}
                textAlign={'center'}
                variant={TypographyVariant.LargeParagraph}
                marginBottom={ClinTheme.space[4]}
              >
                {`${t('program_documentation:no_results_description')} `}
                <strong>
                  {t('program_documentation:no_results_get_in_touch')}
                </strong>
              </ClinText>
              <ClinGroup alignItems="center" justifyContent="center">
                <ClinIcon
                  iconName={ClinIconPathName.Phone}
                  style={{ marginRight: 0 }}
                  iconFill={ClinTheme.colors.primary}
                />
                <a
                  className="display-telephone"
                  href={`tel:${supportContact?.rawPhoneNumber}`}
                  style={{ margin: 0 }}
                  onClick={() => {
                    analyticsServiceSingleton.trackEvent(
                      AnalyticsEvent.ContactClicked,
                      { contactType: 'telephone' }
                    )
                  }}
                >
                  <ClinText
                    className={'telephone'}
                    as="span"
                    color={ClinTheme.colors.primaryLight}
                    marginLeft={ClinTheme.space[2]}
                    variant={TypographyVariant.LargeParagraph}
                  >
                    {supportContact?.csPhoneNumber}
                  </ClinText>
                </a>
              </ClinGroup>
              <ClinSpacer />
              <ClinGroup alignItems="center" justifyContent="center">
                <ClinIcon
                  iconName={ClinIconPathName.Mail}
                  style={{ marginRight: 0 }}
                  iconFill={ClinTheme.colors.primary}
                />
                <a
                  className="raw-email"
                  href={`mailto:${supportContact?.csEmailAddress}`}
                  style={{ margin: 0 }}
                  onClick={() => {
                    analyticsServiceSingleton.trackEvent(
                      AnalyticsEvent.ContactClicked,
                      { contactType: 'email' }
                    )
                  }}
                >
                  <ClinText
                    className="email"
                    as="span"
                    color={ClinTheme.colors.primaryLight}
                    marginLeft={ClinTheme.space[2]}
                    variant={TypographyVariant.LargeParagraph}
                  >
                    {supportContact?.csEmailAddress}
                  </ClinText>
                </a>
              </ClinGroup>

              <ClinSpacer height={ClinTheme.space[4]} />
            </>
          ) : (
            <>
              <ClinText
                as="div"
                className={'msg-copy'}
                textAlign={'center'}
                variant={TypographyVariant.LargeParagraph}
              >
                {t('program_documentation:no_results_let_us_know')}
              </ClinText>
              <StyledFlexSpacer />
              <ClinSpacer height={ClinTheme.space[4]} />
              <Link to={url}>
                <ClinButton variant="primary">
                  {t('program_documentation:no_results_sourcing_request_btn')}
                </ClinButton>
              </Link>
              <ClinSpacer height={ClinTheme.space[8]} />
            </>
          )}
        </>
      ) : (
        <ClinText
          variant={TypographyVariant.H4}
          fontWeight={ClinTheme.fontWeights.bold}
          marginTop={ClinTheme.space[2]}
        >
          {t('program_documentation:no_results_not_enrolled')}
        </ClinText>
      )}

      <StyledFlexSpacer />
      <ClinSpacer height={ClinTheme.space[8]} />
    </StyledNoPatients>
  )
}
