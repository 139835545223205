import { ClinMultiSelectOption } from '../../components/ClinMultiSelect/ClinMultiSelect'

export interface IDrugRequestFormState {
  genericName: string
  brandName: string
  strength: string
  packSize?: string
  pharmaceutical: ClinMultiSelectOption[]
  presentation: ClinMultiSelectOption[]
  requiredQty: string
  targetPrice: string
  controlledDrug: boolean
  authFlag: boolean
  importFlag: boolean
  anythingElse: string
  preferredLang: ClinMultiSelectOption[]
  documentation: ClinMultiSelectOption[]
  acceptEEA: boolean
  acceptUSA: boolean
  acceptThirdCountries: boolean
}

export const convertOptionsToString = (options: ClinMultiSelectOption[]) => {
  return options.map((v) => v.value).join(', ')
}

interface ISelectOption {
  value: string
  transKey: string
}

export enum LanguageOptions {
  ANY = 'Any',
  ENGLISH = 'English',
  FRENCH = 'French',
  GERMAN = 'German',
  GREEK = 'Greek',
  ITALIAN = 'Italian',
  PORTUGUESE = 'Portuguese',
  SPANISH = 'Spanish',
  OTHER = 'Other'
}

/**
 * Look up record/table for returning the language value and transkey
 * @usage:
 * const { value, transKey } = LanguageOptionsRecord[language]
 */
export const LanguageOptionsRecord: Record<LanguageOptions, ISelectOption> = {
  [LanguageOptions.ANY]: {
    value: LanguageOptions.ANY,
    transKey: 'drug_request_options:language_any'
  },
  [LanguageOptions.ENGLISH]: {
    value: LanguageOptions.ENGLISH,
    transKey: 'drug_request_options:language_english'
  },
  [LanguageOptions.FRENCH]: {
    value: LanguageOptions.FRENCH,
    transKey: 'drug_request_options:language_french'
  },
  [LanguageOptions.GERMAN]: {
    value: LanguageOptions.GERMAN,
    transKey: 'drug_request_options:language_german'
  },
  [LanguageOptions.GREEK]: {
    value: LanguageOptions.GREEK,
    transKey: 'drug_request_options:language_greek'
  },
  [LanguageOptions.ITALIAN]: {
    value: LanguageOptions.ITALIAN,
    transKey: 'drug_request_options:language_italian'
  },
  [LanguageOptions.PORTUGUESE]: {
    value: LanguageOptions.PORTUGUESE,
    transKey: 'drug_request_options:language_portuguese'
  },
  [LanguageOptions.SPANISH]: {
    value: LanguageOptions.SPANISH,
    transKey: 'drug_request_options:language_spanish'
  },
  [LanguageOptions.OTHER]: {
    value: LanguageOptions.OTHER,
    transKey: 'drug_request_options:language_other'
  }
}

export enum PresentationOptions {
  ANY = 'Any',
  AMPOULE = 'Ampoule',
  BLISTER = 'Blister',
  BOTTLE = 'Bottle',
  PEN = 'Pen',
  PREFILED_SYRINGE = 'Pre-filed syringe',
  SACHETS = 'Sachets',
  VIALS = 'Vial',
  OTHER = 'Other'
}

/**
 * Look up record/table for returning the presentation option value and transkey
 * @usage:
 * const { value, transKey } = PresentationOptionsRecord[presentationOption]
 */
export const PresentationOptionsRecord: Record<
  PresentationOptions,
  ISelectOption
> = {
  [PresentationOptions.ANY]: {
    value: PresentationOptions.ANY,
    transKey: 'drug_request_options:presentation_any'
  },
  [PresentationOptions.AMPOULE]: {
    value: PresentationOptions.AMPOULE,
    transKey: 'drug_request_options:presentation_ampoule'
  },
  [PresentationOptions.BLISTER]: {
    value: PresentationOptions.BLISTER,
    transKey: 'drug_request_options:presentation_blister'
  },
  [PresentationOptions.BOTTLE]: {
    value: PresentationOptions.BOTTLE,
    transKey: 'drug_request_options:presentation_bottle'
  },
  [PresentationOptions.PEN]: {
    value: PresentationOptions.PEN,
    transKey: 'drug_request_options:presentation_pen'
  },
  [PresentationOptions.PREFILED_SYRINGE]: {
    value: PresentationOptions.PREFILED_SYRINGE,
    transKey: 'drug_request_options:presentation_prefiled_syringe'
  },
  [PresentationOptions.SACHETS]: {
    value: PresentationOptions.SACHETS,
    transKey: 'drug_request_options:presentation_sachets'
  },
  [PresentationOptions.VIALS]: {
    value: PresentationOptions.VIALS,
    transKey: 'drug_request_options:presentation_vials'
  },
  [PresentationOptions.OTHER]: {
    value: PresentationOptions.OTHER,
    transKey: 'drug_request_options:presentation_other'
  }
}

export enum DocumentationOptions {
  CERT_OF_ANALYSIS = 'Certificate of Analysis',
  CERT_OF_MANUFACTURER = 'Certificate of Manufacturer’s GMP compliance',
  CERT_OF_ORIGIN = 'Certificate of Origin',
  CERT_OF_PHARMA_PRODUCT = 'Certificate of Pharmaceutical Product',
  PATIENT_INFO_LEAFLET = 'Patient Information Leaflet',
  SECONDARY_PKG_ARWORK_COPY = 'Secondary Packaging Artwork copy',
  SUMMARY_OF_PROD_CHARACTERISTICS = 'Summary of the Product Characteristics',
  NONE = 'None'
}

/**
 * Look up record/table for returning the documentation option value and transkey
 * @usage:
 * const { value, transKey } = DocumentationOptionsRecord[ocumentationOptions]
 */
export const DocumentationOptionsRecord: Record<
  DocumentationOptions,
  ISelectOption
> = {
  [DocumentationOptions.CERT_OF_ANALYSIS]: {
    value: DocumentationOptions.CERT_OF_ANALYSIS,
    transKey: 'drug_request_options:documentation_cert_of_analysis'
  },
  [DocumentationOptions.CERT_OF_MANUFACTURER]: {
    value: DocumentationOptions.CERT_OF_MANUFACTURER,
    transKey: 'drug_request_options:documentation_cert_of_manufacturer'
  },
  [DocumentationOptions.CERT_OF_ORIGIN]: {
    value: DocumentationOptions.CERT_OF_ORIGIN,
    transKey: 'drug_request_options:documentation_cert_of_origin'
  },
  [DocumentationOptions.CERT_OF_PHARMA_PRODUCT]: {
    value: DocumentationOptions.CERT_OF_PHARMA_PRODUCT,
    transKey: 'drug_request_options:documentation_cert_of_pharma_product'
  },
  [DocumentationOptions.PATIENT_INFO_LEAFLET]: {
    value: DocumentationOptions.PATIENT_INFO_LEAFLET,
    transKey: 'drug_request_options:documentation_patient_info_leaflet'
  },
  [DocumentationOptions.SECONDARY_PKG_ARWORK_COPY]: {
    value: DocumentationOptions.SECONDARY_PKG_ARWORK_COPY,
    transKey:
      'drug_request_options:documentation_secondary_pkg_arwork_copylocal'
  },
  [DocumentationOptions.SUMMARY_OF_PROD_CHARACTERISTICS]: {
    value: DocumentationOptions.SUMMARY_OF_PROD_CHARACTERISTICS,
    transKey: 'drug_request_options:documentation_summary_of_prod_character'
  },
  [DocumentationOptions.NONE]: {
    value: DocumentationOptions.NONE,
    transKey: 'drug_request_options:documentation_none'
  }
}

export enum PharmaceuticalOptions {
  ANY = 'Any',
  CAPSULES = 'Capsules',
  ENEMA = 'Enema',
  IMPLANT = 'Implant',
  INJECTABLE = 'Injectable',
  ORAL_SOLUTION = 'Oral Solution',
  ORAL_SUSPENSION = 'Oral Suspension',
  OVULES_VAGINAL_TABLETS = 'Ovules/Vaginal tablets',
  PATCH = 'Patch',
  SUPPOSITORY = 'Suppository',
  TABLETS = 'Tablets',
  OTHER = 'Other'
}

/**
 * Look up record/table for returning the documentation option value and transkey
 * @usage:
 * const { value, transKey } = DocumentationOptionsRecord[ocumentationOptions]
 */
export const PharmaceuticalOptionsRecord: Record<
  PharmaceuticalOptions,
  ISelectOption
> = {
  [PharmaceuticalOptions.ANY]: {
    value: PharmaceuticalOptions.ANY,
    transKey: 'drug_request_options:pharmaceutical_any'
  },
  [PharmaceuticalOptions.CAPSULES]: {
    value: PharmaceuticalOptions.CAPSULES,
    transKey: 'drug_request_options:pharmaceutical_capsules'
  },
  [PharmaceuticalOptions.ENEMA]: {
    value: PharmaceuticalOptions.ENEMA,
    transKey: 'drug_request_options:pharmaceutical_enema'
  },
  [PharmaceuticalOptions.IMPLANT]: {
    value: PharmaceuticalOptions.IMPLANT,
    transKey: 'drug_request_options:pharmaceutical_implant'
  },
  [PharmaceuticalOptions.INJECTABLE]: {
    value: PharmaceuticalOptions.INJECTABLE,
    transKey: 'drug_request_options:pharmaceutical_injectable'
  },
  [PharmaceuticalOptions.ORAL_SOLUTION]: {
    value: PharmaceuticalOptions.ORAL_SOLUTION,
    transKey: 'drug_request_options:pharmaceutical_oral_solution'
  },
  [PharmaceuticalOptions.ORAL_SUSPENSION]: {
    value: PharmaceuticalOptions.ORAL_SUSPENSION,
    transKey: 'drug_request_options:pharmaceutical_oral_suspension'
  },
  [PharmaceuticalOptions.OVULES_VAGINAL_TABLETS]: {
    value: PharmaceuticalOptions.OVULES_VAGINAL_TABLETS,
    transKey: 'drug_request_options:pharmaceutical_ovules_vaginal_tablets'
  },
  [PharmaceuticalOptions.PATCH]: {
    value: PharmaceuticalOptions.PATCH,
    transKey: 'drug_request_options:pharmaceutical_patch'
  },
  [PharmaceuticalOptions.SUPPOSITORY]: {
    value: PharmaceuticalOptions.SUPPOSITORY,
    transKey: 'drug_request_options:pharmaceutical_suppository'
  },
  [PharmaceuticalOptions.TABLETS]: {
    value: PharmaceuticalOptions.TABLETS,
    transKey: 'drug_request_options:pharmaceutical_tablets'
  },
  [PharmaceuticalOptions.OTHER]: {
    value: PharmaceuticalOptions.OTHER,
    transKey: 'drug_request_options:pharmaceutical_other'
  }
}
