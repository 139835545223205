import { BookmarkDto } from './swaggerTypes'

export enum BookmarkableItemType {
  SKU = 'Sku',
  Generic = 'Generic'
}

export interface BookmarkableItem
  extends Pick<BookmarkDto, 'itemId' | 'itemType'> {
  itemType: BookmarkableItemType
}
