/**
 * Basket State Management
 * (aka Store)
 *
 * Inspiration for pattern from https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */

import * as React from 'react'
import {
  BasketCheckedOutDto,
  BasketDto,
  OrderDto,
  PriceAndAvailabilityDto,
  UpdateBasketDetailsDto
} from '../../types/swaggerTypes'
import { basketReducer } from './BasketContext.reducer'
import { ActionTypes } from './BasketContext.actions'
import { IRestEndpointState } from '../../types/IRestEndpointState'
import { BasketStatus, IViewBasket } from './BasketContext.types'
import { defaultBasketDetails, defaultBasketMADetails } from '../../constants'

export type Dispatch = (action: ActionTypes) => void

export type State = {
  basket: IRestEndpointState<BasketDto>
  priceAndAvailability: IRestEndpointState<PriceAndAvailabilityDto[]>
  viewBasket?: IViewBasket
  orders?: OrderDto[] | undefined
  completedOrder?: BasketCheckedOutDto | undefined
  expectedDeliveryDate?: string
  basketDetails: UpdateBasketDetailsDto
  basketMADetails: UpdateBasketDetailsDto
}

/**
 * N.B. These default values are only necessary so we can expose defaultValues for testing
 */
export const defaultBasketState: State = {
  basket: { isLoading: true },
  priceAndAvailability: { isLoading: true },
  basketDetails: defaultBasketDetails,
  basketMADetails: defaultBasketMADetails,
  viewBasket: {
    items: [],
    total: 0,
    currencyCode: 'GBP',
    instituteId: '',
    basketId: '',
    created: '',
    lastUpdated: '',
    state: BasketStatus.Default,
    userId: '',
    checkoutStamp: '',
    orders: []
  }
}

type BasketProviderProps = { children: React.ReactNode; defaultValues?: State } // optional default value for testing only

const BasketStateContext = React.createContext<State | undefined>(undefined)
const BasketDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
)

/**
 * Custom consumer component
 * @param children
 * @param defaultValues
 * @constructor
 */
const BasketProvider = ({
  children,
  defaultValues = defaultBasketState
}: BasketProviderProps) => {
  const [state, dispatch] = React.useReducer(basketReducer, defaultValues)
  return (
    <BasketStateContext.Provider value={state}>
      <BasketDispatchContext.Provider value={dispatch}>
        {children}
      </BasketDispatchContext.Provider>
    </BasketStateContext.Provider>
  )
}

const useBasketState = () => {
  const context = React.useContext(BasketStateContext)
  if (context === undefined) {
    throw new Error('useBasketState must be used within a BasketProvider')
  }
  return context
}

export const useBasketDispatch = () => {
  const context = React.useContext(BasketDispatchContext)
  if (context === undefined) {
    throw new Error('useBasketDispatch must be used within a BasketProvider')
  }
  return context
}

/**
 * Custom consumer hook to use the state with
 * @usage:
 * const [basketState, basketDispatch] = useBasket()
 * const [{ viewBasket }, basketDispatch] = useBasket()
 * basketDispatch({ type: ActionType.UpdateItemQuantity, basketItem, quantity })
 * <button onClick={(e) => basketDispatch({ type:  ActionType.UpdateItemQuantity, basketItem: basketItem, quantity: 10 })}>Click Me</button>
 */
const useBasket = () => {
  return [useBasketState(), useBasketDispatch()] as const //  freeze the type with a const assertion
}

export { BasketProvider, useBasket }
