import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery, mediaQueryDown } from '../../utils/mediaQuery'
import { ClinText } from '../../components/ClinText'
import { StyledClinText } from '../../components/ClinText/ClinText.styles'

const tabletUpBreakpoint = ClinTheme.breakpoints[1]

interface IStyledDeliveryDetialsProps {
  padding: string
}

export const StyledCheckoutCardDetail = styled.div({
  marginTop: ClinTheme.space[6],
  marginBottom: ClinTheme.space[6],
  marginLeft: ClinTheme.space[5],
  marginRight: ClinTheme.space[5]
})

export const StyledSpinnerContainer = styled.div({
  display: 'flex',
  height: '50vh',
  alignItems: 'center',
  justifyContent: 'center'
})

export const StyledCheckoutSectionWrapper = styled.div({
  borderRadius: '4px',
  border: `1px solid ${ClinTheme.colors.midGrey}`,
  background: ClinTheme.colors.white,
  padding: '30px 24px 30px 40px',
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    padding: '20px'
  }
})

export const StyledCheckoutSubmitButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: `${ClinTheme.space[7]}px`
})

export const StyledCheckoutCustomAddressWrapper = styled.div({
  border: `1px solid ${ClinTheme.colors.midGrey}`,
  borderTop: 'unset',
  background: ClinTheme.colors.white,
  padding: '24px'
})

export const StyledDeliveryAddressTextOpaCheckout = styled(ClinText)({
  display: 'inline-block',
  paddingRight: 0,
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    marginRight: ClinTheme.space[3],
    paddingBottom: 0
  }
})

export const StyledCheckoutEditSectionWrapper = styled.div({
  borderRadius: '4px',
  border: `1px solid ${ClinTheme.colors.midGrey}`,
  background: ClinTheme.colors.white,
  padding: '30px 24px 24px 47px',
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    padding: '20px'
  }
})

export const StyledDetailsTitle = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    flexDirection: 'column'
  }
})

export const StyledNewShippingAddressLinkCheckout = styled(StyledClinText)({
  color: ClinTheme.colors.primaryLight,
  cursor: 'pointer',
  fontWeight: ClinTheme.fontWeights.medium,
  fontSize: ClinTheme.fontSizes[1],
  display: 'inline-block',
  marginLeft: ClinTheme.space[3],
  marginRight: ClinTheme.space[3],
  [mediaQueryDown(ClinTheme.breakpoints[3])]: {
    marginLeft: 0
  }
})

export const StyledDeliveryDetails = styled.div<IStyledDeliveryDetialsProps>(
  {},
  ({ padding }: IStyledDeliveryDetialsProps) => ({
    paddingLeft: padding
  })
)

export const StyledEstimatedDelivery = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '7px 0'
})

export const StyledSummaryWrapper = styled.div({
  border: `1px solid ${ClinTheme.colors.midGrey}`,
  background: ClinTheme.colors.white
})

export const StyledDeliverySummary = styled.div({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: ClinTheme.colors.white,
  border: `1px solid ${ClinTheme.colors.midGrey}`,
  paddingTop: '24px',
  paddingLeft: '25px',
  paddingRight: '15px',
  paddingBottom: '43px',
  [mediaQuery(tabletUpBreakpoint)]: {
    marginTop: ClinTheme.space[5],
    padding: ClinTheme.space[5]
  }
})

export const StyledSummaryLine = styled.div({
  height: '1px',
  background: ClinTheme.colors.black
})

export const StyledDeliveryNoteWarningWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
})

export const StyledEditButtonHighlightWrapper = styled.span({
  display: 'flex',
  alignItems: 'center'
})

export const StyledEditButtonHighlight = styled.span({
  marginRight: '10px'
})

export const StyledDeliveryNoteHighlightWrapper = styled.span({
  position: 'relative'
})

export const StyledDeliveryNoteHighlight = styled.span({
  marginRight: '10px',
  position: 'absolute',
  top: '0',
  left: '-85px'
})

export const StyledTimeoutSpinnerContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

export const StyledTimeoutSpinnerWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
})
