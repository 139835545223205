import React, {
  ChangeEvent,
  FunctionComponent,
  useState,
  useEffect
} from 'react'
import { StyledBasketHoldItemForAUS } from './Basket.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinRadio } from '../../components/ClinRadio'
import { HoldType } from '../../services/Holds/holds.constants'
import { DocumentUploadContainer } from '../../features/DocumentUpload/DocumentUploadContainer'
import { Trans, useTranslation } from 'react-i18next'
import {
  BasketItemDocumentDto,
  UploadedDocumentDto
} from '../../types/swaggerTypes'
import { IHoldDetails } from '../../services/Holds/holds.types'
import { IViewBasketItem } from '../../types'
import { updateHoldOption } from '../../services/ApiService'
import { AxiosError } from 'axios'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinButton } from '../../components/ClinButton'
import {
  StyledButtonArea,
  StyledFileRow
} from '../../features/DocumentUpload/DocumentUpload.styles'
interface IDocumentProps {
  index: number
  hold: IHoldDetails
  basketItem: IViewBasketItem
  submittedHoldDocument: UploadedDocumentDto | undefined
  handleFileUploadedForBasketItem?: (
    basketItemId: string,
    document: UploadedDocumentDto,
    holdDetails: IHoldDetails,
    basketItemDocumentId?: string | null
  ) => void
  handleRemoveFileForHoldAsync?: (
    holdDetails: IHoldDetails,
    basketItemId: string
  ) => Promise<void> | undefined
  handleRemoveSpecificFileForHoldAsync?: (item: BasketItemDocumentDto) => void
}

export const SupportDocuments: FunctionComponent<IDocumentProps> = ({
  handleFileUploadedForBasketItem,
  handleRemoveFileForHoldAsync,
  handleRemoveSpecificFileForHoldAsync,
  index,
  hold,
  submittedHoldDocument,
  basketItem
}) => {
  const { t } = useTranslation()
  const [radioValue, setRadioValue] = useState('')
  const [response, setResponse] = useState('')

  const handleSelect = (selectedValue: string) => {
    const updateHoldDetails = {
      holdKey: hold.type,
      customJson: JSON.stringify({ radioOptionForHold: selectedValue })
    }
    updateHoldOption(basketItem.basketItemId, updateHoldDetails)
      .then((response) => {
        if (response) {
          setResponse(response.data)
          setRadioValue(selectedValue)
          if (
            handleRemoveFileForHoldAsync &&
            selectedValue !== t('basket:checklist_for_aus_hold.option_1') &&
            basketItem.documents.length
          ) {
            handleRemoveFileForHoldAsync(hold, basketItem.basketItemId)
          }
        }
      })
      .catch((error: AxiosError) => {
        createAnnounceEvent(
          AnnounceMode.Error,
          `There was an error fetching the basket. ${error}`
        )
      })
  }

  useEffect(() => {
    let selectedOption
    if (basketItem?.documents?.[0]?.customJson) {
      selectedOption = JSON.parse(
        basketItem.documents[0]?.customJson
      ).radioOptionForHold
    }
    if (selectedOption) {
      setRadioValue(selectedOption)
    }
    if (basketItem?.documents?.[0]?.basketItemDocumentId) {
      setResponse(basketItem.documents[0].basketItemDocumentId)
    }
  }, [])

  return (
    <StyledBasketHoldItemForAUS
      key={`doc-upload-container-${basketItem.basketItemId}`}
    >
      {hold.type === HoldType.SafetyLetter ? (
        <>
          <ClinText as={'div'} variant={TypographyVariant.H5}>
            {hold.requiredDocumentName}
          </ClinText>
          <ClinText as={'p'} variant={TypographyVariant.LargeParagraph}>
            {hold.documentPrompt}
          </ClinText>
        </>
      ) : (
        <>
          <StyledFileRow>
            <ClinText
              variant={TypographyVariant.H5}
              fontWeight={ClinTheme.fontWeights.medium}
              color={ClinTheme.colors.primaryMid}
            >
              {t('basket:checklist_for_aus_hold.title')}
            </ClinText>
            <StyledButtonArea styleForAUS={true}>
              <ClinButton
                as="span"
                display={'inline-flex'}
                justifyContent={'center'}
                variant={'linkButton'}
                disabled={!radioValue}
                onClick={() => handleSelect('')}
              >
                {t('common:buttons.clear')}
              </ClinButton>
            </StyledButtonArea>
          </StyledFileRow>
          <ClinText
            variant={TypographyVariant.LargeParagraph}
            fontWeight={ClinTheme.fontWeights.normal}
            color={ClinTheme.colors.black}
          >
            {t('basket:checklist_for_aus_hold.checklist_text')}
          </ClinText>
          <ClinRadio
            label="some label functional test one"
            id={`${t('basket:checklist_for_aus_hold.option_1')}}-${
              basketItem.basketItemId
            }`}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleSelect(event.target.value)
            }
            checked={radioValue === t('basket:checklist_for_aus_hold.option_1')}
            value={t('basket:checklist_for_aus_hold.option_1')}
          >
            {t('basket:aus_documentation_sas_option')}
          </ClinRadio>
          <ClinSpacer />
          <ClinRadio
            label="some label functional test two"
            id={`${t('basket:checklist_for_aus_hold.option_2')}}-${
              basketItem.basketItemId
            }`}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleSelect(event.target.value)
            }
            checked={radioValue === t('basket:checklist_for_aus_hold.option_2')}
            value={t('basket:checklist_for_aus_hold.option_2')}
          >
            {t('basket:checklist_for_aus_hold.option_2')}
          </ClinRadio>
          {radioValue === t('basket:checklist_for_aus_hold.option_2') && (
            <ClinText
              variant={TypographyVariant.LargeParagraph}
              fontWeight={ClinTheme.fontWeights.normal}
              color={ClinTheme.colors.black}
              marginLeft={'42px'}
              whiteSpace={'pre-line'}
            >
              <Trans
                i18nKey={'basket:checklist_for_aus_hold.emergency_statement'}
                values={{ newLine: '\n' }}
              >
                <></>
              </Trans>
            </ClinText>
          )}
          {radioValue === t('basket:checklist_for_aus_hold.option_1') && (
            <>
              <ClinSpacer height={'28px'} />
              <ClinSpacer
                height={'1px'}
                hasBorder={true}
                color={ClinTheme.colors.grey}
              />
              <DocumentUploadContainer
                isPreOrderVariant={true}
                canRemoveFile={true}
                styleForAUS={true}
                basketItem={basketItem}
                defaultFileName={
                  submittedHoldDocument
                    ? submittedHoldDocument?.fileName
                    : undefined
                }
                handleFileUploaded={(document) => {
                  handleFileUploadedForBasketItem &&
                    hold.holdDto?.holdName &&
                    handleFileUploadedForBasketItem(
                      basketItem.basketItemId,
                      document,
                      hold,
                      response
                    )
                }}
                handleFileRemoved={() =>
                  handleRemoveFileForHoldAsync?.(hold, basketItem.basketItemId)
                }
                handleSpecificFileRemoved={(item: BasketItemDocumentDto) => {
                  handleRemoveSpecificFileForHoldAsync &&
                    handleRemoveSpecificFileForHoldAsync(item)
                }}
                documentPrompt={
                  submittedHoldDocument?.fileName ? (
                    ''
                  ) : (
                    <Trans
                      i18nKey={
                        'basket:checklist_for_aus_hold.accepted_file_types_aus'
                      }
                      values={{ newLine: '\n' }}
                    >
                      <></>
                    </Trans>
                  )
                }
              />
            </>
          )}
        </>
      )}
    </StyledBasketHoldItemForAUS>
  )
}
