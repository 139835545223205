import React, { FunctionComponent, useEffect, useRef, useState } from 'react'

import {
  ActionType,
  BasketStatus,
  getBasketAsync,
  useBasket
} from '../../context/basket'

import { useHistory } from 'react-router'
import { useAppContext } from '../../context/app'
import {
  CreateShippingAddressDto,
  OrderAddressDto,
  PriceAndAvailabilityDto,
  UpdateBasketDetailsDto
} from '../../types/swaggerTypes'
import { TFunction } from 'i18next'
import { OneStepCheckout } from './OneStepCheckout'
import { CheckoutViewMode } from '../Checkout/CheckoutContainer'
import { ClinTheme } from '../../ClinTheme'
import { useTranslation } from 'react-i18next'
import {
  getPriceAndAvailabilityForSku,
  placeDraftOrder,
  submitOrder
} from '../../services/ApiService'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { CreateAddressModal } from './CreateAddressModal/CreateAddressModal'
import { useCountries } from '../../utils/useCountries'
import { handleBasketError } from '../../utils/basketUtils'
import { showWootricSurvey } from '../../services/Wootric/showWootricSurvey'
import { UserRoleRecord, defaultBasketDetails } from '../../constants'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../services/Analytics'
import { returnDefaultBasketValues } from '../Basket/Basket'
import useChangeBackgroundColor from '../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'

export const OneStepCheckoutContainer: FunctionComponent = () => {
  const history = useHistory()
  const { t } = useTranslation(['checkout_options'])
  const { dispatch, userDetails } = useAppContext()

  const { countries } = useCountries()

  const {
    institute,
    defaultShippingAddress,
    portfolioCountryCode,
    userRole,
    user
  } = useAppContext()

  const [orderWasSubmitted, setOrderWasSubmitted] = useState<boolean>(false)
  const [
    { orders, viewBasket, basketDetails, completedOrder, basket },
    basketDispatch
  ] = useBasket()

  useChangeBackgroundColor(ClinTheme.colors.lightGrey)

  const [newAddress, setNewAddress] = useState<OrderAddressDto>()
  const [isNewShippingAddressOpen, setIsNewShippingAddressOpen] =
    useState<boolean>(false)
  const [hasErrorMessage, setHasErrorMessage] = useState<string | undefined>(
    undefined
  )

  const [viewMode, setViewMode] = useState<CheckoutViewMode>(
    CheckoutViewMode.Loading
  )

  const [deliveryOptions, setDeliveryOptions] =
    useState<UpdateBasketDetailsDto>(basketDetails)

  const [deliveryAddresses, setDeliveryAddresses] = useState<OrderAddressDto[]>(
    []
  )

  const localStorageAddressKey = 'new-address'

  const handleBackToBasket = () => {
    history.push('/basket')
  }

  const defaultCountry = countries?.find((c) => c.countryCode === 'GB')

  const hasStandardShipping = !!(
    defaultCountry &&
    userRole &&
    !UserRoleRecord[userRole].isMaUser
  )

  const getAddressFromLocalstorage = () => {
    const addressFromLocalStorage = localStorage.getItem(localStorageAddressKey)
    if (
      addressFromLocalStorage &&
      deliveryAddresses[0].addressId !==
        JSON.parse(addressFromLocalStorage).addressId
    ) {
      let newDeliveryAddresses: OrderAddressDto[] = deliveryAddresses.slice()
      newDeliveryAddresses.unshift(JSON.parse(addressFromLocalStorage))
      setDeliveryAddresses(newDeliveryAddresses)
      setNewAddress(JSON.parse(addressFromLocalStorage))
      setDeliveryOptions({
        ...deliveryOptions,
        shippingAddressId: '0'
      })
    }
  }

  useEffect(() => {
    if (
      completedOrder &&
      orderWasSubmitted &&
      viewBasket?.state === BasketStatus.Submitted.toString()
    ) {
      // Get the new basket
      getBasketAsync(basketDispatch).then(() => {
        // Empty basket and refresh
        // This is commented releted to ticket clos-2075
        // basketDispatch({
        //   type: ActionType.EmptyBasket
        // })

        history.push(`/checkout/summary`)
      })
    }
  }, [basketDispatch, history, orderWasSubmitted, viewBasket, completedOrder])

  useEffect(() => {
    if (orders && orders.length > 0 && !orderWasSubmitted) {
      setViewMode(CheckoutViewMode.ShowOrders)
    }
  }, [orderWasSubmitted, orders])

  useEffect(() => {
    setViewMode(CheckoutViewMode.ShowOrders)
  }, [hasStandardShipping, orders])

  const hasCustomDeliveryAddress = () => {
    return deliveryOptions.shippingAddressId === '0' //custom delivery address will always have shippingAddressId === '0'
  }

  /**
   * Order is actually booked at this step
   */
  const handlePlaceOrderSubmit = () => {
    setHasErrorMessage(getPOErrorMessage(t, deliveryOptions.poNumber))
    if (deliveryOptions.poNumber && !hasErrorMessage) {
      setViewMode(CheckoutViewMode.Submitting)
      window.scrollTo({
        behavior: 'smooth',
        top: 0
      })
      placeDraftOrder(
        portfolioCountryCode,
        deliveryOptions.poNumber.trim(),
        deliveryOptions.deliverToContact.trim(),
        hasCustomDeliveryAddress()
          ? ''
          : deliveryOptions.shippingAddressId?.toString(),
        hasCustomDeliveryAddress() ? newAddress : undefined
      )
        .then((response) => {
          const checkoutStamp = response.data.basket.checkoutStamp

          if (!checkoutStamp) {
            return
          }

          return submitOrder(checkoutStamp)
            .then((response) => {
              response.data.checkedOutOrders.map((order) =>
                analyticsServiceSingleton.trackEvent(
                  AnalyticsEvent.OrderCompleted,
                  {
                    order: order.orderNumber,
                    order_number: order.orderNumber, // for hubspot format
                    division: 'GA',
                    institute_id: user?.institute?.instituteId,
                    has_hold: order.holds.length > 0,
                    order_value: order.totals.total
                  }
                )
              )
              setOrderWasSubmitted(true)
              basketDispatch({
                type: ActionType.OrderSubmitted,
                completedOrder: response.data
              })
              //remove expected delivery date from basket context after submit order
              basketDispatch({
                type: ActionType.SetExpectedDeliveryDate
              })

              let basketDetails: UpdateBasketDetailsDto = defaultBasketDetails
              if (userRole && userDetails)
                basketDetails = returnDefaultBasketValues(userRole, userDetails)
              basketDispatch({
                type: ActionType.UpdateBasketDetails,
                basketDetails: basketDetails
              })

              // Trigger survey and pass through user and order numbers
              showWootricSurvey(
                user,
                response.data.checkedOutOrders
                  .map((o) => o.orderNumber)
                  .join(','),
                'GA'
              )
            })
            .catch((error) => {
              const basketError = handleBasketError(error, dispatch)
              !basketError &&
                dispatch(
                  createAnnounceEvent(
                    AnnounceMode.Error,
                    `An error occurred submitting your order. ${error}`
                  )
                )
            })
        })
        .catch((error) => {
          const basketError = handleBasketError(error, dispatch)
          !basketError &&
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `An error occurred creating your order. ${error}`
              )
            )
        })
    }
  }

  const handleSendEmail = () => {
    window.location.href = 'mailto:pv@linkhealthcare.co'
  }

  /**
   * When addresses are loaded set them and select the default shipping address
   */
  const isOptionsLoaded = useRef(false) // Make sure this only happens once

  useEffect(() => {
    if (
      !isOptionsLoaded.current &&
      !institute.isLoading &&
      institute?.data &&
      defaultShippingAddress
    ) {
      setDeliveryAddresses(institute.data.shipTo.map((address) => address)) // Map from (OrgAddressDto to OrderAddressDto)

      setDeliveryOptions({
        ...deliveryOptions,
        shippingAddressId: deliveryOptions.shippingAddressId
          ? deliveryOptions.shippingAddressId
          : defaultShippingAddress.addressId
      })
      isOptionsLoaded.current = true
    }
  }, [institute, defaultShippingAddress, deliveryOptions])

  useEffect(() => {
    if (deliveryAddresses.length) {
      getAddressFromLocalstorage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryAddresses])

  const saveAddressToLocalStorage = (address: OrderAddressDto) => {
    localStorage.setItem(localStorageAddressKey, JSON.stringify(address))
  }

  const handleNewShippingAddress = (data: CreateShippingAddressDto) => {
    let newDeliveryAddresses: OrderAddressDto[] = deliveryAddresses.slice()
    const createdAddress = { ...(data.address as unknown as OrderAddressDto) }
    createdAddress.addressId = '0'

    if (newAddress) {
      newDeliveryAddresses[0] = createdAddress
    } else {
      newDeliveryAddresses.unshift(createdAddress)
    }
    setDeliveryAddresses(newDeliveryAddresses)
    setNewAddress(createdAddress)
    setIsNewShippingAddressOpen(false)
    setDeliveryOptions({
      ...deliveryOptions,
      shippingAddressId: '0'
    })
    let addressScroll = document.getElementById('scrollView')
    addressScroll &&
      addressScroll.scrollTo({
        behavior: 'smooth',
        top: 0
      })
    saveAddressToLocalStorage(createdAddress)
  }

  /**
   * Validate Purchase Order
   * */
  const getPOErrorMessage = (
    transFn: TFunction,
    poNumber?: string
  ): string | undefined => {
    const purchaseOrderNumber = poNumber
    if (purchaseOrderNumber && purchaseOrderNumber.indexOf(' ') >= 0) {
      return transFn('po_error_message_no_space')
    }
    if (purchaseOrderNumber && purchaseOrderNumber?.length >= 50) {
      return transFn('po_error_message_max_length')
    }
    if (
      !purchaseOrderNumber ||
      (purchaseOrderNumber && purchaseOrderNumber === '')
    ) {
      return transFn('po_error_message_not_supplied')
    }
    return undefined
  }

  const getPriceAndAvailability = () => {
    if (basket?.data && defaultShippingAddress?.addressId) {
      const skus: string[] = basket.data.items.map((item) => item.sku)
      const pricingCalls: Promise<PriceAndAvailabilityDto>[] = skus.map((sku) =>
        getPriceAndAvailabilityForSku(
          sku,
          defaultShippingAddress?.addressId
        ).then((r) => r.data)
      )
      Promise.all(pricingCalls)
        .then((results) => {
          basket.data &&
            basketDispatch({
              type: ActionType.SetCurrentBasket,
              basket: basket.data,
              priceAndAvailability: results,
              countryCode: portfolioCountryCode
            })
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `An error occurred fetching your basket. ${error}`
            )
          )
        })
    }
  }

  //call this only if user reloads the page and tha basket detials is empty in context
  useEffect(() => {
    if (!viewBasket?.basketId) {
      getPriceAndAvailability()
    }
  }, [viewBasket, basket])

  return (
    <>
      <CreateAddressModal
        isOpen={isNewShippingAddressOpen}
        handleFormSubmit={handleNewShippingAddress}
        handleClose={() => {
          setIsNewShippingAddressOpen(false)
        }}
        isSubmitting={false}
        resetOnSubmit={true}
        countries={countries}
      />

      <OneStepCheckout
        viewMode={viewMode}
        basket={viewBasket}
        customerPoNumber={
          deliveryOptions.poNumber ? deliveryOptions.poNumber : ''
        }
        deliverToContact={
          deliveryOptions.deliverToContact
            ? deliveryOptions.deliverToContact
            : ''
        }
        errorMessage={hasErrorMessage}
        selectedAddressId={
          deliveryOptions.shippingAddressId
            ? deliveryOptions.shippingAddressId
            : ''
        }
        deliveryAddresses={deliveryAddresses}
        handleChangeAddress={(selectedAddressId) =>
          setDeliveryOptions({
            ...deliveryOptions,
            shippingAddressId: selectedAddressId
          })
        }
        handleBackToBasket={handleBackToBasket}
        handlePoChange={(value) => {
          setDeliveryOptions({ ...deliveryOptions, poNumber: value })
          setHasErrorMessage(getPOErrorMessage(t, value))
        }}
        handleRecipientsNameChange={(value) =>
          setDeliveryOptions({ ...deliveryOptions, deliverToContact: value })
        }
        handleNewShippingAddress={() => setIsNewShippingAddressOpen(true)}
        handleOrderSubmit={handlePlaceOrderSubmit}
        handleSendEmail={handleSendEmail}
      />
    </>
  )
}
