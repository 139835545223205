import React, { FunctionComponent, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { AppQueries } from '../../types/ContentTypes'
import { Row, Col } from 'react-grid-system'
import { ClinSpinner } from '../../components/ClinSpinner'
import { Support } from './Support'
import { getSupportContent } from '../../services/ContentApiService'
import { ClinTheme } from '../../ClinTheme'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { useAppContext } from '../../context/app'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { RouteComponentProps } from 'react-router'

export const SupportContainer: FunctionComponent<RouteComponentProps> = ({
  history
}) => {
  const { dispatch, portfolioCountryCode, userRole } = useAppContext()
  const { loading, data, error } = useQuery<AppQueries>(getSupportContent, {
    errorPolicy: 'all'
  })

  useEffect(() => {
    if (error) {
      dispatch(
        createAnnounceEvent(
          AnnounceMode.Error,
          `There was an error loading this content. ${error}`
        )
      )
    }
  }, [dispatch, error])

  const handlePageChangeRequest = (path: string) => {
    history.push(path)
  }

  if (loading)
    return (
      <Row justify="center">
        <Col width="auto">
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    )

  return (
    <>
      {data &&
        data.querySupportMainPageContents &&
        data.queryTopicTemplateContents &&
        data.querySupportMainPageContents[0] &&
        data.queryTopicTemplateContents[0] && (
          <Support
            supportTopics={data.queryTopicTemplateContents}
            content={data.querySupportMainPageContents[0]}
            userCountry={portfolioCountryCode}
            userRole={userRole}
            handlePageChangeRequest={handlePageChangeRequest}
          />
        )}
    </>
  )
}
