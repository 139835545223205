import { AxiosError } from 'axios'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useAppContext } from '../../../context/app'
import { useErrorMessage } from '../../../utils/useErrorMessage'
import { transferPatientInstitute } from '../../../services/ApiService'
import { getPatientDetail } from '../../../services/ApiService'
import {
  NewInstituteDetilsDto,
  NewPhysicianDetilsDto,
  PatientDetailsDto,
  PatientProgramDto
} from '../../../types/swaggerTypes'
import { TransferPatientToInstitute } from './TransferPatientToInstitute'
import { StepState } from '../../../components/ClinStepper/ClinStepper'
import { useUpdateQueryParam } from '../../../components/ClinPagination/ClinPagination.model'
import {
  PatientTransferSteps,
  patientSteps,
  defaultPhysicianParams
} from '../models/TransferPatientContainer.model'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { useCountries } from '../../../utils/useCountries'
import { useTranslation } from 'react-i18next'

interface ITransferPatientRouteParams {
  patientId: string
  physicianId: string
}

interface ITransferPatientProps
  extends RouteComponentProps<ITransferPatientRouteParams> {}

export const TransferPatientToInstituteContainer: FunctionComponent<
  ITransferPatientProps
> = ({ match, history }) => {
  const { t } = useTranslation()
  const { dispatch } = useAppContext()
  const { patientId, physicianId } = match.params
  const [patient, setPatient] = useState<PatientDetailsDto>()
  const [program, setProgram] = useState<PatientProgramDto>()
  const [isPatientLoading, setIsPatientLoading] = useState<boolean>(true)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [currentStep, setCurrentStep] = useState<number>(
    PatientTransferSteps.STEP1
  )
  const [physicianDetails, setPhysicianDetails] =
    useState<NewPhysicianDetilsDto>(defaultPhysicianParams)
  const [isAgreementConfirmed, setIsAgreementConfirmed] =
    useState<boolean>(false)

  const { isCountriesLoading, countries } = useCountries()

  //Needed only for Browser Back/Forward buttons work
  const setupURLparams = () => {
    history.push(
      `/programs/my-physicians/${physicianId}/${patientId}/transfer-patient/institute?step=${currentStep}`
    )
  }

  const handleCancel = () => {
    history.push(`/programs/my-physicians/${physicianId}/${patientId}`)
  }

  const getSteppers = (): StepState[] | undefined => {
    if (currentStep !== null) {
      return patientSteps[currentStep]
    }
  }

  const handleContinue = (step: number) => {
    setupURLparams()
    setCurrentStep(step)
  }

  const savePhysicianDetails = (data: NewPhysicianDetilsDto) => {
    setPhysicianDetails(data)
    setupURLparams()
    setCurrentStep(PatientTransferSteps.STEP3)
  }

  const handleSubmitError = useErrorMessage(
    t('transfer_patient_to_institute:submit_error')
  )

  const handleGetPatientDetailError = useErrorMessage(
    t('transfer_patient_to_institute:patient_detail_error')
  )

  const submitTransferring = (instituteDetails: NewInstituteDetilsDto) => {
    setIsSubmitting(true)
    transferPatientInstitute(physicianId, patientId, {
      ...physicianDetails,
      ...instituteDetails
    })
      .then((response) => {
        if (response.status >= 200 && response.status <= 299) {
          setIsSubmitting(false)
          setCurrentStep(PatientTransferSteps.STEP4)
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.PatientTransferInstitute,
            {
              patientId,
              physicianId,
              instituteName: instituteDetails.newInstituteName
            }
          )
        }
      })
      .catch((error: AxiosError) => handleSubmitError(error))
  }

  useUpdateQueryParam({ step: currentStep })

  useEffect(() => {
    patientId &&
      physicianId &&
      getPatientDetail(patientId, physicianId)
        .then((response) => {
          setPatient(response.data)
          setProgram(response.data.program)
        })
        .catch((error: AxiosError) => handleGetPatientDetailError(error))
        .finally(() => setIsPatientLoading(false))

    const unListen = history.listen(() => {
      const urlSearchParams = new URLSearchParams(history.location.search)
      const step: number = Number(urlSearchParams.get('step'))
      setCurrentStep(step)
    })

    return () => {
      unListen()
    }
  }, [dispatch, handleGetPatientDetailError, patientId, physicianId, history])

  return (
    <TransferPatientToInstitute
      isLoading={isPatientLoading && isCountriesLoading}
      isSubmitting={isSubmitting}
      patient={patient}
      program={program}
      physicianDetails={physicianDetails}
      currentStep={currentStep}
      isAgreementConfirmed={isAgreementConfirmed}
      countries={countries}
      setIsAgreementConfirmed={setIsAgreementConfirmed}
      selectedSteppers={getSteppers()}
      handleCancel={handleCancel}
      handleContinue={handleContinue}
      savePhysicianDetails={savePhysicianDetails}
      submitTransferring={submitTransferring}
      handleViewPatientDetails={handleCancel}
    />
  )
}
