import { AxiosError } from 'axios'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from 'react'
import { RouteComponentProps, StaticContext, matchPath } from 'react-router'
import { enrolPhysiciansOnToProgram } from '../../services/ApiService'
import { useBeforeUnload } from '../../utils/useBeforeUnload'
import { useErrorMessage } from '../../utils/useErrorMessage'
import {
  SideBarMode,
  useProgramStatus
} from '../Programs/ProgramDetail/ProgramDetail.models'
import { EnrolPhysicianOnToProgram } from './EnrolPhysicianOnToProgram'
import {
  EnrolPhysicianErrorCode,
  useEnrolPhysicianPageState
} from './EnrolPhysicianOnToProgram.model'
import { EnrolPhysicianOnToProgramSuccess } from './EnrolPhysicianOnToProgramSuccess/EnrolPhysicianOnToProgramSuccess'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../services/Analytics'
import { usePhysiciansToEnrol } from '../EnrolUserOnToProgram/EnrolUserOnToProgram.model'
import { useTranslation } from 'react-i18next'
import { useOnMount } from '../../utils/useOnMount'
import { PhysiciansSummaryDto } from '../../types/swaggerTypes'
import { useAppContext } from '../../context/app'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'

interface IEnrolPhysicianOnToProgramRouteParams {
  programId: string
}

type LocationState = {
  from?: string
  programId: string
  physicianIds?: number[]
}

interface IEnrolPhysicianOnToProgramProps
  extends RouteComponentProps<
    IEnrolPhysicianOnToProgramRouteParams,
    StaticContext,
    LocationState
  > {}

export const EnrolPhysicianOnToProgramContainer: FunctionComponent<
  IEnrolPhysicianOnToProgramProps
> = ({ match, history }) => {
  const { t } = useTranslation()
  const { programId } = match.params
  const { dispatch } = useAppContext()
  const handleError = useErrorMessage(
    'There was an error submitting your request.',
    true
  )

  const {
    selectedPhysicians,
    savedPhysicians,
    handleOnSelect,
    handlePhysicianRemoval,
    handleSavedPhysicianDetails,
    handleSavedPhysicianRemoval
  } = usePhysiciansToEnrol(programId, true)

  const {
    currentStep,
    selectedTabIndex,
    associateConfirmation,
    handleGoToNextStep,
    handleGoToPreviousStep,
    handleTabSelected,
    handleRequestToAddPhysician,
    handleConfirmationSelect,
    handleGetSteppers
  } = useEnrolPhysicianPageState(selectedPhysicians, savedPhysicians)

  const { isProgramLoading, program, programUiState, enrolledPrograms } =
    useProgramStatus(programId)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isSubmitted, setSubmitted] = useState<boolean>(false)
  const [isFormEdited, setIsFormEdited] = useState<boolean>(false)
  const [fromPage, setFromPage] = useState<string>('')

  useOnMount(() => {
    const matchPatientDetails =
      history.location.state &&
      history.location?.state?.from &&
      matchPath(history.location?.state?.from, {
        path: `/create-patient`
      })
    if (matchPatientDetails) {
      setFromPage(history.location?.state?.from!)
    }
  })

  const leaveMessage = t('common:leave_message')
  useBeforeUnload(
    leaveMessage,
    !!(
      (isFormEdited ||
        (savedPhysicians && savedPhysicians?.length > 0) ||
        (selectedPhysicians && selectedPhysicians?.length > 0)) &&
      isSubmitted === false
    )
  )

  const handleBackToTop = () => {
    window.scrollTo(0, 0)
  }

  const handleRequestToViewProgram = useCallback((): void => {
    history.push(`/programs/access-programs/${programId}`)
  }, [history, programId])

  const handleRequestToViewDashboard = (): void => {
    history.push('/orders')
  }

  const redirectToProgramDetailPage = useCallback((): void => {
    const searchParams = new URLSearchParams()
    searchParams.set('autoenrolled', 'true')
    history.push({
      pathname: `/programs/access-programs/${programId}`,
      search: `?${searchParams.toString()}`
    })
  }, [history, programId])

  const redirectToCreatePatientPage = useCallback(
    (selectedPhysiciansToBePassed: PhysiciansSummaryDto[]): void => {
      history.push({
        pathname: `/create-patient`,
        state: {
          from: window.location.pathname,
          programId: programId,
          physicianIds: selectedPhysicians?.map((x) => x.physicianId)
        }
      })
    },
    [history, programId, selectedPhysicians]
  )

  const isEnrollExistingPhysicianResponseError = (response: any) => {
    const errorCodes = [
      EnrolPhysicianErrorCode.XXCL_ENROLLMENT_FAILED,
      EnrolPhysicianErrorCode.XXCL_USER_AND_PHYSICIAN_ENROLLMENT_FAILED,
      EnrolPhysicianErrorCode.XXCL_PHYSICIAN_ENROLLMENT_FAILED
    ]

    return (
      response.split('XXCL_') &&
      response.split('XXCL_')[1] &&
      errorCodes.some((code) => response.split('XXCL_')[1].includes(code))
    )
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    enrolPhysiciansOnToProgram(programId, {
      enrolReason: '',
      existingPhysicians: selectedPhysicians ? selectedPhysicians : [],
      newPhysicians: savedPhysicians ? savedPhysicians : [],
      isUserEnrolled: enrolledPrograms?.some(
        (x) => x.programId.toString() === programId
      )
    })
      .then((response) => {
        setSubmitted(true)
        if (response.data.isAutoEnrolled) {
          localStorage.setItem('patientFilterChecked', 'false')
          if (
            (!savedPhysicians || savedPhysicians.length === 0) &&
            fromPage === '/patients'
          ) {
            redirectToProgramDetailPage()
          } else {
            fromPage
              ? redirectToCreatePatientPage(
                  selectedPhysicians ? selectedPhysicians : []
                )
              : redirectToProgramDetailPage()
          }
        } else if (response.data.incidentNumber) {
          setSubmitted(true)
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.EnrolPhysicianOnToProgram,
            {
              programId,
              physicianIds: selectedPhysicians
                ? selectedPhysicians.map((p) => p.physicianId).toString()
                : '',
              programName: program?.programName
            }
          )
        }
      })
      .catch((error: AxiosError) => {
        handleBackToTop()
        var response = error.response?.data?.dependency?.response
        if (response && isEnrollExistingPhysicianResponseError(response)) {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              t('enrol_physician_on_to_program:enrol_physician_error')
            )
          )
        } else {
          handleError(error)
        }
      })
      .finally(() => setIsSubmitting(false))
  }

  const handleIsFormEdited = (isEdited: boolean) => {
    setIsFormEdited(isEdited)
  }

  useEffect(() => {
    if (programUiState && !isProgramLoading) {
      programUiState.sideBarMode !== SideBarMode.Enrolled &&
        handleRequestToViewProgram()
    }
  }, [
    handleRequestToViewProgram,
    history,
    isProgramLoading,
    programId,
    programUiState
  ])

  return (
    <>
      {!isSubmitted ? (
        <EnrolPhysicianOnToProgram
          isLoading={isProgramLoading}
          program={program}
          currentStep={currentStep}
          selectedSteppers={handleGetSteppers()}
          savedPhysicians={savedPhysicians}
          selectedPhysicians={selectedPhysicians}
          selectedTabIndex={selectedTabIndex}
          associateConfirmation={associateConfirmation}
          isSubmitting={isSubmitting}
          isFormEdited={isFormEdited}
          isSubmitted={isSubmitted}
          handleTabSelected={handleTabSelected}
          handleOnSelect={handleOnSelect}
          handleRequestToAddPhysician={handleRequestToAddPhysician}
          handlePhysicianRemoval={handlePhysicianRemoval}
          handleSavedPhysicianDetails={handleSavedPhysicianDetails}
          handleIsFormEdited={handleIsFormEdited}
          handleSavedPhysicianRemoval={handleSavedPhysicianRemoval}
          handleGoToNextStep={handleGoToNextStep}
          handleGoToPreviousStep={handleGoToPreviousStep}
          handleConfirmationSelect={handleConfirmationSelect}
          handleSubmit={handleSubmit}
          handleRequestToViewProgram={handleRequestToViewProgram}
        />
      ) : (
        <EnrolPhysicianOnToProgramSuccess
          program={program}
          savedPhysicians={savedPhysicians}
          selectedPhysicians={selectedPhysicians}
          handleRequestToViewDashboard={handleRequestToViewDashboard}
          handleRequestToViewProgram={handleRequestToViewProgram}
        />
      )}
    </>
  )
}
