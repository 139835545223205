import React, { FunctionComponent, useEffect, useState } from 'react'
import { BasicSearch } from '../../features/Search/BasicSearch'
import { t } from 'i18next'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { Col } from 'react-grid-system'
import {
  StyledPatientCreateButton,
  StyledPatientSearchButtonWrapper,
  StyledPatientSearchContentWrapper,
  StyledPatientSearchDivWrapper,
  StyledPatientSearchWrapper,
  StyledPatientSwitchButtonWrapper
} from './PatientSearch.styles'
import { useHistory } from 'react-router'
import { ClinSwitch } from '../../components/ClinSwitch'
import { PatientFilterOptions } from './Patients.model'
import {
  StyledTooltipCreateWrapper,
  StyledTooltipSearchWrapper
} from './PatientDashboard/PatientDashboard.styles'
import { ClinNewFeatureTooltipContainer } from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'
import {
  INewFeature,
  NewFeatureElements
} from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../services/Analytics'
import { useAppContext } from '../../context/app'
import { useNewFeatures, removeFeature } from '../../context/newFeatures' // Import hooks and actions
import {
  FLOATING_OVERLAY_VISIBLE,
  eventEmitter
} from '../../utils/eventEmitter'

interface IPatientsSearchProps {
  /** When a search query is entered and ENTER pressed */
  handleOnChange?: (query: string) => void
  handleMyPatinets?: (value: string) => void
  handleCloseFeatureHighlight?: (feature: NewFeatureElements) => void
  initialQuery?: string
  showTableInTab?: boolean
  newFeaturesList?: { [id: string]: INewFeature }
  displayNewFeature?: (feature: NewFeatureElements) => boolean | undefined
  displayNewFeatureGlowEffect?: (
    feature: NewFeatureElements
  ) => boolean | undefined
  patientFilterChecked: boolean | null
}

export const PatientsSearch: FunctionComponent<IPatientsSearchProps> = ({
  handleOnChange,
  handleMyPatinets,
  handleCloseFeatureHighlight,
  displayNewFeature,
  displayNewFeatureGlowEffect,
  initialQuery,
  showTableInTab,
  newFeaturesList,
  patientFilterChecked
}) => {
  const { userDetails, institute } = useAppContext()
  const history = useHistory()
  const [, featuresDispatch] = useNewFeatures()
  const [isFeatureVisible, setIsFeatureVisible] = useState(false)

  setTimeout(() => {
    localStorage.removeItem('patientFilterChecked')
  }, 0)

  useEffect(() => {
    const handleFeatureSwitch = (isVisible: boolean) => {
      setIsFeatureVisible(isVisible)
    }

    eventEmitter.on(FLOATING_OVERLAY_VISIBLE, handleFeatureSwitch)

    return () => {
      eventEmitter.off(FLOATING_OVERLAY_VISIBLE, handleFeatureSwitch)
    }
  }, [])

  const handleBasicSearchClick = () => {
    if (newFeaturesList && newFeaturesList[NewFeatureElements.PatientsSearch]) {
      // Remove focus from input
      const input = document.querySelector(
        '.clin-search-input input'
      ) as HTMLInputElement
      input && input.blur()
      removeFeature(
        featuresDispatch,
        newFeaturesList[NewFeatureElements.PatientsSearch].id
      )
    }
  }

  return (
    <StyledPatientSearchDivWrapper
      background={ClinTheme.colors.white}
      showtableintab={showTableInTab?.toString()}
    >
      <StyledPatientSearchWrapper showtableintab={showTableInTab?.toString()}>
        <Col md={8} offset={{ md: 2 }} style={{ marginLeft: '0px' }}>
          <StyledPatientSearchContentWrapper>
            {!showTableInTab && (
              <>
                {newFeaturesList &&
                  displayNewFeature &&
                  displayNewFeature(NewFeatureElements.PatientsSearch) && (
                    <StyledTooltipSearchWrapper>
                      <ClinNewFeatureTooltipContainer
                        feature={
                          newFeaturesList &&
                          newFeaturesList[NewFeatureElements.PatientsSearch]
                        }
                        openTooltipInitially={true}
                        showFeatureFromTable={true}
                        disableDismiss={true}
                        tooltipWidth="242px"
                        total={4}
                        current={1}
                      ></ClinNewFeatureTooltipContainer>
                    </StyledTooltipSearchWrapper>
                  )}
                <ClinText
                  color={ClinTheme.colors.primary}
                  fontWeight={ClinTheme.fontWeights.bold}
                  variant={TypographyVariant.H4}
                  marginRight={'22px'}
                  marginTop={'0px'}
                  marginBottom={'0px'}
                >
                  {t('patient_detail:search_title')}
                </ClinText>
              </>
            )}

            <BasicSearch
              boxShadow={
                newFeaturesList &&
                displayNewFeatureGlowEffect &&
                displayNewFeatureGlowEffect(NewFeatureElements.PatientsSearch)
                  ? '0 0 5px #fff'
                  : ''
              }
              zIndex={
                !isFeatureVisible &&
                newFeaturesList &&
                displayNewFeatureGlowEffect &&
                displayNewFeatureGlowEffect(NewFeatureElements.PatientsSearch)
                  ? 3
                  : 0
              }
              borderRadious={showTableInTab ? '6' : '20'}
              height={showTableInTab ? `${ClinTheme.space[5]}px` : undefined}
              textIndent={showTableInTab ? ClinTheme.space[4] : undefined}
              fontSize={ClinTheme.fontSizes[1]}
              maxWidth={showTableInTab ? '289px' : '77%'}
              iconHeight={
                showTableInTab ? `${ClinTheme.space[3]}px` : undefined
              }
              iconSize={showTableInTab ? `${ClinTheme.space[3]}px` : undefined}
              marginLeft={1}
              iconColor={
                showTableInTab
                  ? ClinTheme.colors.darkGrey
                  : ClinTheme.colors.primary
              }
              iconStyle={
                showTableInTab
                  ? {
                      position: 'absolute',
                      left: ClinTheme.space[2],
                      top: '50%',
                      transform: 'translateY(-50%)'
                    }
                  : undefined
              }
              initialQuery={initialQuery}
              placeholder={
                showTableInTab
                  ? t('program_detail:patient_search_placeholder')
                  : t('patient_detail:search_placeholder')
              }
              debounceTimeMs={500}
              handleOnChange={(query) => {
                handleOnChange && handleOnChange(query)
              }}
              handleClick={handleBasicSearchClick}
            />
          </StyledPatientSearchContentWrapper>
        </Col>
        <StyledPatientSearchButtonWrapper
          showtableintab={showTableInTab?.toString()}
          md={2}
        >
          {!showTableInTab ? (
            <>
              {newFeaturesList &&
                displayNewFeature &&
                displayNewFeature(NewFeatureElements.PatientsCreate) && (
                  <StyledTooltipCreateWrapper>
                    <ClinNewFeatureTooltipContainer
                      feature={
                        newFeaturesList[NewFeatureElements.PatientsCreate]
                      }
                      openTooltipInitially={
                        !newFeaturesList[NewFeatureElements.PatientsCreate]
                          ?.isCancelled
                      }
                      showFeatureFromTable={true}
                      disableDismiss={true}
                      total={4}
                      current={2}
                      tooltipWidth="242px"
                    ></ClinNewFeatureTooltipContainer>
                  </StyledTooltipCreateWrapper>
                )}
              <StyledPatientCreateButton
                showGlowEffect={
                  !!(
                    newFeaturesList &&
                    displayNewFeatureGlowEffect &&
                    displayNewFeatureGlowEffect(
                      NewFeatureElements.PatientsCreate
                    )
                  )
                }
                variant="primaryNarrow"
                onClick={() => {
                  if (
                    newFeaturesList &&
                    newFeaturesList[NewFeatureElements.PatientsCreate]
                  ) {
                    handleCloseFeatureHighlight &&
                      handleCloseFeatureHighlight(
                        NewFeatureElements.PatientsCreate
                      )
                  }
                  analyticsServiceSingleton.trackEvent(
                    AnalyticsEvent.CreatePatient,
                    {
                      location: history.location.pathname,
                      instituteId: institute.data?.instituteId,
                      userId: userDetails?.contactCard.contactId
                    }
                  )
                  history.push('/create-patient')
                }}
              >
                {t('patient_detail:search_button')}
              </StyledPatientCreateButton>
            </>
          ) : (
            <>
              <StyledPatientSwitchButtonWrapper>
                {patientFilterChecked !== null && (
                  <ClinSwitch
                    id="my_patients"
                    label={t('orders:my_orders_button_label')}
                    flexDirection="row-reverse"
                    defaultChecked={patientFilterChecked}
                    onChange={(event) =>
                      handleMyPatinets &&
                      handleMyPatinets(PatientFilterOptions.MyPatients)
                    }
                  />
                )}
                <ClinText
                  as="div"
                  whiteSpace="nowrap"
                  fontSize={ClinTheme.fontSizes[0]}
                  fontWeight={ClinTheme.fontWeights.medium}
                  marginRight="0px"
                >
                  {t('patient_detail:filter_options.filter_option_my_patients')}
                </ClinText>
              </StyledPatientSwitchButtonWrapper>
            </>
          )}
        </StyledPatientSearchButtonWrapper>
      </StyledPatientSearchWrapper>
    </StyledPatientSearchDivWrapper>
  )
}
