import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQueryDown } from '../../utils/mediaQuery'

const mediumUpBreakpoint = ClinTheme.breakpoints[1]

export const StyleOpaAction = styled.div({
  display: 'flex',
  cursor: 'pointer'
})

export const StyledMaOrderDetailsButtonsGroup = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  [`.clin-button`]: {
    marginLeft: ClinTheme.space[4]
  },
  [mediaQueryDown(mediumUpBreakpoint)]: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    [`.clin-button`]: {
      width: '100%',
      marginLeft: ClinTheme.space[0],
      marginTop: ClinTheme.space[3]
    }
  }
})
