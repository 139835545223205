import { AnnounceMode } from '../components/ClinAnnounceBar/ClinAnnounceBar'

export enum AnnounceEventType {
  SHOW = 'ShowAnnounceEvent',
  HIDE = 'HideAnnounceEvent'
}

// Show ------------------------------------------------------------------------

export interface IShowAnnounceEvent {
  /** Event type is fixed */
  type: AnnounceEventType.SHOW
  /** Title announce bar mode */
  mode: AnnounceMode
  /** Description of the announce */
  description?: string
  /** Title of the announce */
  title?: string
}

export const createAnnounceEvent = (
  mode: AnnounceMode,
  description?: string,
  title?: string
): IShowAnnounceEvent => ({
  type: AnnounceEventType.SHOW,
  mode,
  description,
  title
})

// Hide ------------------------------------------------------------------------

export interface IHideAnnounceEvent {
  type: AnnounceEventType.HIDE
}

export const clearAnnounceEvent = (): IHideAnnounceEvent => ({
  type: AnnounceEventType.HIDE
})

export type IAnnounceEvent = IShowAnnounceEvent | IHideAnnounceEvent
