import React, { FunctionComponent } from 'react'
import { Redirect, Route } from 'react-router'
import { useAppContext } from '../../context/app'
import { NotFound } from '../../pages/NotFound'
import { getIsPageAccessibleFromCountryAndRole } from '../../utils/getIsPageAccessibleFromCountry'

interface IPrivateCountryRoleRouteProps {
  component: FunctionComponent<any>
  exact: boolean
  path: string
  redirect?: string
  /** Optional error page (uses standard NotFound 404 page by default) */
  errorPage?: FunctionComponent<any>
  /** Optional check for access from a specific country */
  forbidForCountryCodes: string[]
  forbidRoleTypes: string[]
}

//use this route because aus ga users can not see programs pages (related to clos-1610)
export const PrivateCountryRoleRoute: FunctionComponent<
  IPrivateCountryRoleRouteProps
> = ({
  component,
  path,
  exact,
  redirect,
  errorPage = NotFound,
  forbidForCountryCodes,
  forbidRoleTypes
}) => {
  const { portfolioCountryCode, userRole } = useAppContext()

  return (
    <>
      {userRole &&
      getIsPageAccessibleFromCountryAndRole(
        portfolioCountryCode,
        userRole,
        forbidForCountryCodes,
        forbidRoleTypes
      ) ? (
        <Route path={path} exact={exact} component={component} />
      ) : (
        <>
          {redirect ? (
            <Redirect to={redirect} exact={true} />
          ) : (
            <Route path={path} exact={true} component={errorPage} />
          )}
        </>
      )}
    </>
  )
}
