import { FunctionComponent, useEffect, useRef } from 'react'
import { matchPath, useHistory } from 'react-router-dom'

interface IScrollToTopProps {
  exceptionLocations?: string[]
}
export const ScrollToTop: FunctionComponent<IScrollToTopProps> = ({
  exceptionLocations
}) => {
  const history = useHistory()
  const lastPagePath = useRef<string>('')
  useEffect(() => {
    const unlisten = history.listen(() => {
      let isException = false
      exceptionLocations?.forEach((path) => {
        const match = matchPath(history.location.pathname, { path: path })
        if (
          match?.isExact &&
          history.location.pathname === lastPagePath.current
        ) {
          isException = true
        }
      })
      if (!isException) {
        window.scrollTo(0, 0)
        lastPagePath.current = history.location.pathname
      }
    })
    return () => {
      unlisten()
    }
  }, [exceptionLocations, history, history.location.pathname, lastPagePath])

  return null
}
