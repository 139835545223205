import styled from 'styled-components'
import { ClinTableRow } from '../../components/ClinTable/ClinTable'
import { ClinTheme } from '../../ClinTheme'

export const StyledClinTableRow = styled(ClinTableRow)({
  background: ClinTheme.colors.white
})

export const StyledColoredText = styled.span({
  color: ClinTheme.colors.primaryMid
})
