import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { StyledClinCard } from '../../components/ClinCard/ClinCard.styles'

export const StyledTopicLinkCard = styled.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  height: '100%',
  [`${StyledClinCard}`]: {
    height: '100%',
    margin: 0,
    border: `solid ${ClinTheme.borderWidths[0]}px ${ClinTheme.colors.grey}`,
    boxShadow: `0px 0px 8px 0px rgba(0, 0, 0, 0.16)`,
    transition: '.2s linear box-shadow, .2s linear border',
    cursor: 'pointer'
  },

  [`:hover`]: {
    [`${StyledClinCard}`]: {
      borderColor: ClinTheme.colors.primary,
      border: `solid ${ClinTheme.borderWidths[0]}px ${ClinTheme.colors.primaryMid}`,
      boxShadow: `0px 4px 8px 0px rgba(114, 57, 255, 0.16), 0px 0px 8px 0px rgba(114, 57, 255, 0.16)`
    }
  }
})

export const StyledTopicLinkCardInner = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  height: '100%',
  padding: `${ClinTheme.space[4]}px ${ClinTheme.space[3]}px`,
  boxSizing: 'border-box',

  [`.topic-title`]: {
    marginRight: 'auto'
  },

  [`.topic-icon`]: {
    marginRight: ClinTheme.space[3]
  }
})
