import React, { FunctionComponent } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import NavigationPrompt from 'react-router-navigation-prompt'
import { ClinTheme } from '../../ClinTheme'
import { ClinButton } from '../../components/ClinButton'
import { ClinModal } from '../../components/ClinModal'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { StyledActions, StyledSubmit } from './Prompt.styles'

interface IPromptProps {
  /** When the prompt should be enabled */
  when?: boolean
  handleCloseEvent?: () => void
}

export const Prompt: FunctionComponent<IPromptProps> = ({
  handleCloseEvent,
  when = true
}) => {
  const { t } = useTranslation()

  return (
    <NavigationPrompt when={when}>
      {({ onConfirm, onCancel }) => (
        <ClinModal maxWidth="xs" height="auto" isOpen={true}>
          <Container>
            <Row justify="center">
              <Col sm={12}>
                <Row>
                  <Col sm={12} md={10}>
                    <ClinText
                      className="prompt-title"
                      as="h4"
                      variant={TypographyVariant.H4}
                      fontWeight={ClinTheme.fontWeights.bold}
                    >
                      {t('common:prompt.title')}
                    </ClinText>
                    <ClinText
                      className="prompt-description"
                      variant={TypographyVariant.LargeParagraph}
                      marginBottom="0"
                    >
                      {t('common:prompt.description')}
                    </ClinText>
                  </Col>
                </Row>
                <StyledSubmit>
                  <Row justify="between">
                    <Col md={5} lg={6}>
                      <StyledActions>
                        <ClinButton
                          className="cancel-page-change"
                          onClick={() => {
                            handleCloseEvent && handleCloseEvent()
                            onCancel()
                          }}
                        >
                          {t('common:buttons.cancel')}
                        </ClinButton>
                        <ClinButton
                          className="continue-page-change"
                          variant="primary"
                          onClick={onConfirm}
                        >
                          {t('common:buttons.continue')}
                        </ClinButton>
                      </StyledActions>
                    </Col>
                  </Row>
                </StyledSubmit>
              </Col>
            </Row>
          </Container>
        </ClinModal>
      )}
    </NavigationPrompt>
  )
}
