import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import { ClinCard } from '../../components/ClinCard'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { OrderAddressDto } from '../../types/swaggerTypes'
import { useTranslation } from 'react-i18next'

interface IOpaDeliveryDetailsProps {
  /** Deliver adress */
  deliverTo: OrderAddressDto
  /** The name of the recipient */
  deliverToContact: string
  /** Purchase Order number */
  poNumber: string
  /** Delivery method */
  deliveryMethod: string
  /** Recipient email */
  recipientEmail?: string
  /** Recipient phone number */
  recipientPhoneNumber?: string
  /** Delivery address line 1 */
  address1?: string
  /** Delivery city */
  city?: string
  /** Delivery country */
  country?: string
  /** Delivery contact */
  shipToContact?: string
  /** Customer purchase order number */
  customerPoNumber?: string
  deliveryNote?: string
  institute?: string | undefined
}

const extractAddress = (deliverTo: OrderAddressDto) => {
  let address: string = ''
  address += deliverTo.address1 + ', '
  if (deliverTo.address2) address += deliverTo.address2 + ', '
  if (deliverTo.address3) address += deliverTo.address3 + ', '
  if (deliverTo.city) address += deliverTo.city + ', '
  if (deliverTo.state) address += deliverTo.state + ', '
  if (deliverTo.country) address += deliverTo.country + ', '
  return address
}

export const OpaDeliveryDetails: FunctionComponent<
  IOpaDeliveryDetailsProps
> = ({
  deliverTo,
  deliveryNote,
  shipToContact,
  customerPoNumber,
  recipientEmail,
  recipientPhoneNumber,
  institute,
  country
}) => {
  const { t } = useTranslation()

  return (
    <ClinCard>
      <ClinText
        as="h4"
        variant={TypographyVariant.H4}
        marginTop={'0'}
        fontWeight={ClinTheme.fontWeights.medium}
        color={ClinTheme.colors.black}
      >
        {t('opa_order_summary:delivery_and_po_details')}
      </ClinText>
      <Row>
        <Col>
          <ClinText
            fontSize={ClinTheme.fontSizes[2]}
            fontWeight={ClinTheme.fontWeights.medium}
            color={ClinTheme.colors.black}
          >
            {t('opa_order_summary:delivery_contact')}
          </ClinText>
          <ClinSpacer height={ClinTheme.space[1]} />
          <ClinText
            fontSize={ClinTheme.fontSizes[2]}
            fontWeight={ClinTheme.fontWeights.normal}
            color={ClinTheme.colors.black}
          >
            {shipToContact} <br />
            {recipientEmail} <br />
            {recipientPhoneNumber} <br />
            {institute} <br />
            {extractAddress(deliverTo)} <br />
            {/* {getAddressField(deliverTo.address1)}{' '}
            {getAddressField(deliverTo.city)} <br />*/}
            {deliverTo.postalCode}
          </ClinText>
          {deliveryNote !== '' && <ClinSpacer height={ClinTheme.space[4]} />}
          {deliveryNote !== '' && (
            <>
              <ClinText
                fontSize={ClinTheme.fontSizes[2]}
                fontWeight={ClinTheme.fontWeights.medium}
                color={ClinTheme.colors.black}
              >
                {t('opa_order_summary:delivery_notes')}
              </ClinText>
              <ClinSpacer height={ClinTheme.space[1]} />
              <ClinText
                fontSize={ClinTheme.fontSizes[2]}
                fontWeight={ClinTheme.fontWeights.normal}
                color={ClinTheme.colors.black}
                wordBreak={'break-word'}
              >
                {deliveryNote}
              </ClinText>
            </>
          )}
          <ClinSpacer height={ClinTheme.space[4]} />
          <ClinText
            fontSize={ClinTheme.fontSizes[2]}
            fontWeight={ClinTheme.fontWeights.medium}
            color={ClinTheme.colors.black}
          >
            {t('opa_order_summary:purchase_order_number')}
          </ClinText>
          <ClinSpacer height={ClinTheme.space[1]} />
          <ClinText
            fontSize={ClinTheme.fontSizes[2]}
            fontWeight={ClinTheme.fontWeights.normal}
            color={ClinTheme.colors.black}
          >
            {customerPoNumber}
          </ClinText>
        </Col>
      </Row>
    </ClinCard>
  )
}
