import styled from 'styled-components'
import { ClinTheme } from '../../../../ClinTheme'
import { mediaQueryDown } from '../../../../utils/mediaQuery'
const mobileUpBreakpoint = ClinTheme.breakpoints[0]

export const StyledNoPatients = styled.div({
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50vh',
  [mediaQueryDown(mobileUpBreakpoint)]: {
    height: '60vh'
  }
})

export const StyledNoPatientsWrap = styled.div({
  position: 'relative'
})

export const StyledNoPatientsIconText = styled.span({
  position: 'absolute',
  top: '48%',
  left: '46%',
  color: ClinTheme.colors.primaryMid,
  fontSize: ClinTheme.fontSizes[5],
  fontWeight: ClinTheme.fontWeights.medium,
  lineHeight: ClinTheme.lineHeights.heading[2]
})
