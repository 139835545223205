import styled from 'styled-components'

export const StyledResponsiveVideoEmbed = styled.div({
  position: 'relative',
  paddingBottom: '56.25%',
  height: 0,
  overflow: 'hidden',
  maxWidth: '100%',

  [`iframe, object, embed`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
})
