import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinButton } from '../../components/ClinButton'
import { Link, useLocation } from 'react-router-dom'
import { ClinTheme } from '../../ClinTheme'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import { useTranslation } from 'react-i18next'
import { useAppContext } from '../../context/app/AppContext'
import { isAusGaUser } from '../../constants'
import { useCheckUnableHomePage } from '../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'

export const NotFound: FunctionComponent = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { portfolioCountryCode, userRole } = useAppContext()
  const ausGaUser = isAusGaUser(portfolioCountryCode, userRole)
  const { enableNewHomePage } = useCheckUnableHomePage()
  const redirectUrl = ausGaUser && enableNewHomePage ? '/home' : '/orders'

  return (
    <ClinPageContentFrame className="page-not-found">
      <Row>
        <Col>
          <ClinText variant={TypographyVariant.H3}>
            {t('not_found:title')}
          </ClinText>
          <ClinText variant={TypographyVariant.LargeParagraph}>
            {t('not_found:description', {
              path: `${location.pathname}`,
              interpolation: { escapeValue: false }
            })}
          </ClinText>
          <ClinSpacer />
          <Link to={redirectUrl}>
            <ClinButton variant="linkButton">
              {ausGaUser
                ? t('common:buttons.back_to_home')
                : t('common:buttons.back')}
            </ClinButton>
          </Link>
          <ClinSpacer height={ClinTheme.space[5]} />
        </Col>
      </Row>
    </ClinPageContentFrame>
  )
}
