import styled from 'styled-components'

export const StyledClinCenterTruncatedText = styled.span({
  display: 'flex'
})

export const StyledTextStart = styled.span({
  textOverflow: 'ellipsis',
  whiteSpace: 'pre', // don't wrap, but preserve spaces
  overflow: 'hidden'
})

export const StyledTextEnd = styled.span({
  whiteSpace: 'pre'
})
