import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import {
  IRegisterInterestFormState,
  ProgramRegisterInterest
} from './ProgramRegisterInterest'
import {
  cancelPostRegisterInterestProgram,
  registerInterestProgram
} from '../../../services/ApiService'
import { RouteComponentProps } from 'react-router'
import { RegisterInterestDto } from '../../../types/swaggerTypes'
import { AxiosError } from 'axios'
import { useErrorMessage } from '../../../utils/useErrorMessage'
import { useBeforeUnload } from '../../../utils/useBeforeUnload'
import {
  SideBarMode,
  useProgramStatus
} from '../ProgramDetail/ProgramDetail.models'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../../context/app/AppContext'

interface IProgramRegisterInterestRouteParams {
  programId: string
}

interface IProgramRegisterInterestProps
  extends RouteComponentProps<IProgramRegisterInterestRouteParams> {}

export const ProgramRegisterInterestContainer: FunctionComponent<
  IProgramRegisterInterestProps
> = ({ match, history }) => {
  const { t } = useTranslation()
  const { portfolioCountryCode } = useContext(AppContext)
  const { programId } = match.params
  const handleError = useErrorMessage(
    t('program_register_interest:error_registering_interest')
  )
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const [isPageEdited, setIsPageEdited] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const leaveMessage = t('common:leave_message')

  useBeforeUnload(leaveMessage, isPageEdited && !isSubmitted)

  const { isProgramLoading, programError, program, programUiState } =
    useProgramStatus(programId)

  const handleFormSubmission = (formValues: IRegisterInterestFormState) => {
    setIsSubmitting(true)
    const registerInterestDto: RegisterInterestDto = {
      contactByEmail: 'Y',
      contactByMail: '',
      contactByPhone: '',
      reason: formValues.reason
    }
    registerInterestProgram(programId, registerInterestDto)
      .then(() => {
        setIsSubmitting(false)
        setIsSubmitted(true)
        setIsPageEdited(false)
        analyticsServiceSingleton.trackEvent(
          AnalyticsEvent.ProgramRegisterInterest,
          {
            programId,
            programName: program?.programName
          }
        )
        history.push(`/programs/access-programs/${programId}`, {
          hasRegisteredInterest: true
        })
      })
      .catch((error: AxiosError) => {
        handleError(error)
        setIsSubmitting(false)
      })
  }

  const handleIsPageEdited = (isEdited: boolean) => {
    setIsPageEdited(isEdited)
  }

  const goToProgram = useCallback((): void => {
    history.replace(`/programs/access-programs/${programId}`)
  }, [history, programId])

  const handleCancel = () => {
    cancelPostRegisterInterestProgram()
    goToProgram()
  }

  useEffect(() => {
    isSubmitted &&
      history.push(`/programs/access-programs/${programId}`, {
        hasRegisteredInterest: true
      })
  }, [history, isSubmitted, programId])

  useEffect(() => {
    if (programUiState && !isProgramLoading) {
      programUiState.sideBarMode !== SideBarMode.NotAvailableInYourCountry &&
        goToProgram()
    }
  }, [goToProgram, history, isProgramLoading, programId, programUiState])

  return (
    <ProgramRegisterInterest
      isLoading={isProgramLoading}
      hasError={programError}
      program={program}
      isSubmitting={isSubmitting}
      isSubmitted={isSubmitted}
      isPageEdited={isPageEdited}
      userCountry={portfolioCountryCode}
      handleCancel={handleCancel}
      handleFormSubmission={handleFormSubmission}
      handleIsPageEdited={handleIsPageEdited}
    />
  )
}
