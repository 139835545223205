/**
 * Get browser locale from browser
 */
export const getBrowserLocale = (): string =>
  Intl
    ? Intl.DateTimeFormat().resolvedOptions().locale // 'en-GB' £300 28/03/2021 'uk-UA' GBP300
    : navigator.language || // 'en' GBP300 03/28/2021
      (navigator.languages &&
        navigator.languages.length &&
        navigator.languages[0]) ||
      'en'
