// RealWorldDataProgram.styles.ts
import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'

const mobileBreakpoint = `${ClinTheme.breakpoints[0]}px`

export const ContentContainer = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 0 48px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);

  @media (max-width: ${mobileBreakpoint}) {
    padding: 0 20px; // Reduced padding on mobile devices
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  max-width: 1200px;
  position: relative;
  margin: 0 auto;

  @media (max-width: ${mobileBreakpoint}) {
    flex-direction: column; // Stack the title and author on top of each other on mobile
  }
`

export const HeaderColumnTitle = styled.div`
  flex: 3;
  h1 {
    font-size: ${ClinTheme.fontSizes[5]}px;
    color: ${ClinTheme.colors.black};
    margin-top: 0;
    margin-bottom: ${ClinTheme.space[3]}px;
  }

  @media (max-width: ${mobileBreakpoint}) {
    h1 {
      font-size: ${ClinTheme
        .fontSizes[4]}px; // Smaller font size for the title on mobile
    }
  }
`

export const HeaderColumnAuthor = styled.div`
  flex: 1;
`

export const PostImageContainer = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 896px;

  @media (max-width: ${mobileBreakpoint}) {
    width: 100%; // Full width on mobile devices
  }
`

export const PostImage = styled.div<{ src: string }>`
  width: 100%;
  height: 350px; // Fixed height, consider making it responsive
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: ${ClinTheme.space[3]}px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media (max-width: ${mobileBreakpoint}) {
    height: 200px; // Smaller fixed height for the image on mobile
  }
`

export const AuthorInfo = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: ${ClinTheme.space[3]}px;
`

export const AuthorImage = styled.img`
  width: 51px;
  height: 51px;
  border-radius: 50%;
  margin-right: ${ClinTheme.space[2]}px;

  @media (max-width: ${mobileBreakpoint}) {
    width: 40px; // Smaller author image on mobile
    height: 40px;
  }
`

export const AuthorText = styled.div`
  display: flex;
  flex-direction: column;
`

export const AuthorName = styled.div`
  color: ${ClinTheme.colors.black};
  font-weight: ${ClinTheme.fontWeights.bold};
  font-size: ${ClinTheme.fontSizes[1]}px;
`

export const AuthorTitle = styled.div`
  color: ${ClinTheme.colors.black};
  font-weight: ${ClinTheme.fontWeights.bold};
  font-size: ${ClinTheme.fontSizes[1]}px;
`

export const PublishDate = styled.div`
  color: ${ClinTheme.colors.black};
  font-size: ${ClinTheme.fontSizes[0]}px;
`

export const ReadTime = styled.div`
  text-transform: uppercase;
  color: ${ClinTheme.colors.darkGrey};
  font-size: ${ClinTheme.fontSizes[0]}px;
  margin-bottom: ${ClinTheme.space[3]}px;
`

export const Content = styled.div`
  padding: 20px 0;
  color: ${ClinTheme.colors.black};
  line-height: ${ClinTheme.lineHeights.body};
  font-size: ${ClinTheme.fontSizes[2]}px;
  font-family: ${ClinTheme.fonts.body};

  blockquote {
    position: relative; // Position the quote icon
    padding: 30px; // Adjust the padding as needed
    margin: ${ClinTheme.space[4]}px 0; // Add margin for spacing around the quote
    background-color: ${ClinTheme.colors
      .lightPurple}; // Use the theme's light purple color
    border-radius: ${ClinTheme
      .radii[2]}px; // Adjust the border-radius as needed
    font-weight: ${ClinTheme.fontWeights.bold}; // Use bold font weight
    font-size: ${ClinTheme.fontSizes[3]}px; // Adjust font size as needed
    quotes: '\\201C''\\201D''\\2018''\\2019'; // CSS for adding quotation marks

    &:before {
      position: absolute; // Position the quote icon
      top: 0; // Position the quote icon at the top of the blockquote
      content: open-quote; // This adds the opening quote
      font-size: 8em; // Large size for the quote icon
      color: ${ClinTheme.colors.white}; // Color for the quote icon
      top: -35px;
      left: 15px;
    }
    p {
      position: relative; // Position the text relative to the quote icon
      color: ${ClinTheme.colors.primary}; // Adjust text color as needed
    }
  }

  p img {
    max-width: 100%;
    height: auto;
    display: block; // Images are block level to avoid inline issues
    margin: ${ClinTheme.space[3]}px auto; // Centers the image with auto margins and adds spacing
  }
`
