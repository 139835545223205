import React, { FunctionComponent } from 'react'
import { ReportAdverseEvent } from './ReportAdverseEvent'

interface IReportAdverseEventProps {
  /** Example ReportAdverseEvent prop please replace or remove! */
  exampleReportAdverseEventProp?: boolean
}

export const ReportAdverseEventContainer: FunctionComponent<
  IReportAdverseEventProps
> = () => <ReportAdverseEvent />
