import { ClinTheme } from '../../ClinTheme'
import { ProductItemAvailabilityStatus, isAusUser } from '../../constants'
import {
  DrugDivisionType,
  IAugmentedCatalogItemDto,
  UIVariationState
} from '../../services/PortfolioJourneys/PortfolioJourney.types'
import { CatalogItemDto } from '../../types/swaggerTypes'

/**
 * Calculate the correct text color
 * @param availabilityOrdinal
 */
const getAvailabilityColor = (availabilityOrdinal: number): string => {
  return availabilityOrdinal >= 3
    ? ClinTheme.colors.greenValid
    : availabilityOrdinal === 2
    ? ClinTheme.colors.orangeWarn
    : ClinTheme.colors.redInvalid
}

//added condition for stock levels greater than 0 for displaying edd (clos-1193)
const shouldDisplayEstimatedDeliveryDate = (
  expectedDeliveryDate: string | undefined,
  isPastCutOffTime: boolean,
  catalogueItem: IAugmentedCatalogItemDto
): boolean => {
  return !!(
    expectedDeliveryDate &&
    !isPastCutOffTime &&
    catalogueItem.availableStatus !==
      ProductItemAvailabilityStatus.UNAVAILABLE &&
    catalogueItem.reservableStock &&
    catalogueItem.reservableStock > 0 &&
    (catalogueItem.uiVariantState ===
      UIVariationState.AvailableAccurateStockLevel ||
      catalogueItem.uiVariantState === UIVariationState.AvailableGoodStock ||
      catalogueItem.uiVariantState === UIVariationState.AvailableDistributor)
  )
}

const shouldDisplayEstimatedDeliveryDateProductPage = (
  expectedDeliveryDate: string | undefined,
  catalogueItem: IAugmentedCatalogItemDto,
  cutOffTime: string | undefined
): boolean => {
  return !!(
    catalogueItem &&
    cutOffTime &&
    expectedDeliveryDate &&
    catalogueItem.availableStatus !==
      ProductItemAvailabilityStatus.UNAVAILABLE &&
    catalogueItem.reservableStock &&
    catalogueItem.reservableStock > 0 &&
    (catalogueItem.uiVariantState ===
      UIVariationState.AvailableAccurateStockLevel ||
      catalogueItem.uiVariantState === UIVariationState.AvailableGoodStock ||
      catalogueItem.uiVariantState === UIVariationState.AvailableDistributor)
  )
}

const shouldDisplayEstimatedDeliveryDateForAus = (
  catalogueItem: IAugmentedCatalogItemDto
): boolean => {
  return !!(
    catalogueItem.availableStatus !== ProductItemAvailabilityStatus.UNAVAILABLE
  )
}

const shouldDisplaAvailability = (
  catalogueItem: IAugmentedCatalogItemDto,
  countryCode: string
): boolean => {
  return !(
    isAusUser(countryCode) &&
    catalogueItem.item.division === DrugDivisionType.SP &&
    catalogueItem.availableStatus === ProductItemAvailabilityStatus.AVAILABLE
  )
}

/**
 * Calculate the amount of stock available
 * @param catalogueItem
 */
const getAvailableQuantity = (catalogueItem: CatalogItemDto): number => {
  return catalogueItem.stockLevels.reduce(
    (stockNumber, currentValue) => stockNumber + currentValue.reservableStock,
    0
  )
}

export {
  getAvailabilityColor,
  getAvailableQuantity,
  shouldDisplayEstimatedDeliveryDate,
  shouldDisplayEstimatedDeliveryDateForAus,
  shouldDisplayEstimatedDeliveryDateProductPage,
  shouldDisplaAvailability
}
