export enum BootstrapErrorType {
  ACCOUNT_PERMISSION_ERROR, // 403 or 500 - SVC sync error
  USER_AUTH_ERROR, // failed on user authentication call
  USER_DETAILS_ERROR, // failed on user call
  NO_USER_ROLES_SET, // no roles returned from user details call
  INSTITUTE_ERROR, // failed on institute details call
  GENERIC_ERROR // 400 - basket stamps are different
}

export interface IBootstrapError {
  type: BootstrapErrorType
  description: string
}
