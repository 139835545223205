import React, { FunctionComponent } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { ClinTheme } from '../../../ClinTheme'
import { ClinAnnounceBar } from '../../../components/ClinAnnounceBar'
import { ClinButton } from '../../../components/ClinButton'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinLoader } from '../../../components/ClinLoader'
import { ClinModal } from '../../../components/ClinModal'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { StyledActions, StyledOverlayLoader } from './DeletePAFModal.styles'
import { ClinSpacer } from '../../../components/ClinSpacer'

interface IDeletePAFModalProps {
  /** Control internal state of modal **/
  isOpen?: boolean
  /** Displays an error notification **/
  hasError?: boolean
  /** Displays error message **/
  errorMessage?: string
  /** Whether the submission is still processing **/
  isSubmitting: boolean
  isCancelling?: boolean
  /** Handle confirmation of dissociation  **/
  handleConfirmation: () => void
  /** Handle close modal **/
  handleClose: () => void
}

export const DeletePAFModal: FunctionComponent<IDeletePAFModalProps> = ({
  isOpen,
  hasError,
  errorMessage,
  isSubmitting,
  isCancelling,
  handleConfirmation,
  handleClose
}) => {
  const { t } = useTranslation()

  return (
    <ClinModal
      onClose={handleClose}
      isDismissible={!isCancelling}
      maxWidth="xs"
      height="auto"
      padding="32px 38px 27px 32px"
      isOpen={isOpen}
    >
      <Container className="delete-paf-confirmation" style={{ padding: 0 }}>
        <Row justify="start">
          <Col sm={12} md={11}>
            {errorMessage && hasError && (
              <Row>
                <Col>
                  <ClinAnnounceBar title={'Error'}>
                    {errorMessage}
                  </ClinAnnounceBar>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={12}>
                <ClinText
                  as="h3"
                  variant={TypographyVariant.LargerParagraph}
                  fontWeight={ClinTheme.fontWeights.bold}
                  color={ClinTheme.colors.primary}
                >
                  {t('opa_checkout:cancel_order')}
                </ClinText>
                <ClinSpacer height={8} />
              </Col>
            </Row>

            <Row>
              <Col>
                <ClinText
                  variant={TypographyVariant.LargeParagraph}
                  lineHeight={ClinTheme.lineHeights.body}
                >
                  {t('opa_checkout:cancel_order_text')}
                </ClinText>
                <ClinSpacer height={14} />
              </Col>
            </Row>
            <ClinSpacer height={5} />
            <div>
              <Row justify="between">
                <Col md={5} lg={6}>
                  <StyledActions>
                    <ClinButton
                      style={{
                        width: '87px'
                      }}
                      variant="secondaryNarrow"
                      className="cancel-delete-paf"
                      onClick={() => handleClose()}
                    >
                      {t('glossary:no')}
                    </ClinButton>
                    <ClinButton
                      style={{ width: '128px' }}
                      className="confirm"
                      variant="primaryNarrow"
                      onClick={() => handleConfirmation()}
                    >
                      {t('glossary:yes')}
                    </ClinButton>
                  </StyledActions>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      {isSubmitting && (
        <>
          <StyledOverlayLoader>
            <Container>
              <Row justify="center" align="center">
                <Col sm={12}>
                  <ClinText
                    marginLeft="auto"
                    marginRight="auto"
                    marginBottom={ClinTheme.space[5]}
                    textAlign="center"
                    variant={TypographyVariant.LargeParagraph}
                  >
                    {t('physician_details:please_wait')}
                  </ClinText>
                  <ClinGroup justifyContent="center">
                    <div
                      style={{
                        height: ClinTheme.space[9] * 2,
                        width: ClinTheme.space[9] * 2
                      }}
                    >
                      <ClinLoader minHandDuration={'1s'} />
                    </div>
                  </ClinGroup>
                </Col>
              </Row>
            </Container>
          </StyledOverlayLoader>
          <ClinSpacer height={24} />
        </>
      )}
    </ClinModal>
  )
}
