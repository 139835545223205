import { IIndexable } from '.'
import {
  OrderCancellationCodes,
  OrderColumnNames,
  OrderDelivery
} from '../constants'
import { OrderDto, OrderSummaryDto } from './swaggerTypes'

export interface IOrder extends OrderDto {
  deliveryOption?: OrderDelivery.Regular | OrderDelivery.Custom
  availableDeliveryOptions?: OrderDelivery[]
}

export interface IOrderSummary extends OrderSummaryDto {
  isOpen?: boolean
}

export type TOrderCancellationCodes =
  | OrderCancellationCodes.XXCLGREATERQTY
  | OrderCancellationCodes.XXCLLEADTIME
  | OrderCancellationCodes.XXCLLOWPRICE
  | OrderCancellationCodes.XXCLMVPOTH
  | OrderCancellationCodes.XXCLORDERR

export const findOrderCancellationDescription: IIndexable<string> = {
  [OrderCancellationCodes.XXCLGREATERQTY]: 'Greater quantity required',
  [OrderCancellationCodes.XXCLLEADTIME]: 'The lead time is too long/unknown',
  [OrderCancellationCodes.XXCLLOWPRICE]:
    'We have found the product(s) at a lower price',
  [OrderCancellationCodes.XXCLMVPOTH]: 'Other',
  [OrderCancellationCodes.XXCLORDERR]: 'Order placed in error'
}

export const findOrderCancellationLookupCode: IIndexable<TOrderCancellationCodes> =
  {
    [OrderCancellationCodes.XXCLGREATERQTY]:
      OrderCancellationCodes.XXCLGREATERQTY,
    [OrderCancellationCodes.XXCLLEADTIME]: OrderCancellationCodes.XXCLLEADTIME,
    [OrderCancellationCodes.XXCLLOWPRICE]: OrderCancellationCodes.XXCLLOWPRICE,
    [OrderCancellationCodes.XXCLMVPOTH]: OrderCancellationCodes.XXCLMVPOTH,
    [OrderCancellationCodes.XXCLORDERR]: OrderCancellationCodes.XXCLORDERR
  }

export type TOrderColumnNames =
  | OrderColumnNames.DateSubmitted
  | OrderColumnNames.Medications
  | OrderColumnNames.OrderNumber
  | OrderColumnNames.PurchaseOrderNumber
  | OrderColumnNames.Status
  | OrderColumnNames.ToggleExpand
  | OrderColumnNames.TotalCost
