import styled from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'
import { mediaQuery } from '../../../utils/mediaQuery'

const mobileUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledSubmit = styled.div({
  display: 'inline-flex',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  width: '100%',

  'button:last-of-type': {
    marginBottom: ClinTheme.space[3]
  },

  [mediaQuery(mobileUpBreakpoint)]: {
    flexDirection: 'row',
    flexWrap: 'nowrap',

    button: {
      marginBottom: 0,
      marginRight: ClinTheme.space[3]
    },

    'button:last-of-type': {
      marginBottom: 0
    }
  }
})

export const StyledSpinner = styled.div({
  paddingLeft: '50%'
})
