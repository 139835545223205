import { DateTime } from 'luxon'
import { Position } from 'react-tippy'

/**
 * New feature interface
 */
export interface INewFeature {
  /** feature element */
  id: string
  version: string
  style: object
  position?: Position
  title?: string
  description: string
  cssClass?: string
  groupId?: string
  mandatoryViewedFeatures?: NewFeatureElements[]
  isCancelled?: boolean
}

export interface INewFeatureItem {
  id: string
  isCancelled?: boolean
}

/**
 *
 New feature elements enum
 */
export enum NewFeatureElements {
  SwitchButtonFeature = 'order-feature',
  TrackingLinkFeature = 'tracking-link',
  GlobalSearchFeature = 'global-search',
  BookmarkFeature = 'bookmark',
  GlobalSearchNewUserFeature = 'global-search-new-user',
  EditButtonMaChekout = 'edit-button-ma-checkout',
  DeliveryNote = 'delivery-note-ma',
  ContinueOPA = 'continue-opa',
  ContinueCheckout = 'continue-checkout',
  CancelOPA = 'cancel-opa',
  CancelCheckout = 'cancel-checkout',
  PatientsNavbar = 'patients-navbar',
  PatientsSearch = 'patients-search',
  PatientsCreate = 'patients-create',
  PatientsFilter = 'patients-filter',
  PatientsAction = 'patients-action',
  OrdersSearch = 'orders-search',
  OrdersNavbar = 'orders-navbar',
  OrdersFirst = 'orders-first',
  OrdersFilter = 'orders-filter'
}

export interface IAbsoluteTooltipPostion {
  top: string
  right?: string
  left?: string
}

export enum INewFeaturesEvents {
  SwitchButtonFeature = 'Orders toggle',
  TrackingLinkFeature = 'Global tracking',
  GlobalSearchFeature = 'Global search',
  BookmarkFeature = 'Bookmark'
}

/**
 * New feature interface
 */
export interface INewFeatureVersion {
  version: string
  releaseDate: DateTime
}

/**
 * cross side interface
 */
export interface ICrossSide {
  name: string
  value: string
}

export enum ArrowPosition {
  top = 'top',
  topStart = 'top-start',
  topEnd = 'top-end',
  bottom = 'bottom',
  bottomStart = 'bottom-start',
  bottomEnd = 'bottom-end',
  left = 'left',
  leftStart = 'left-start',
  leftEnd = 'left-end',
  right = 'right',
  rightStart = 'right-start',
  rightEnd = 'right-end',
  end = 'end'
}

export enum NewFetureGroups {
  PatientsTour = 'patients-tour',
  HomePageTour = 'home-page-tour',
  OrdersTour = 'orders-tour'
}

export enum PageNames {
  Patients = 'patients',
  Navbar = 'navbar',
  OpaCheckout = 'opa-checkout',
  GlobalSearch = 'global-search',
  Orders = 'orders'
}

export interface NewFeatureDictionary {
  id: string
  feature: INewFeature
}
