import React, {
  ChangeEvent,
  Fragment,
  FunctionComponent,
  useEffect
} from 'react'
import { ClinTheme } from '../../../ClinTheme'
import { Col, Row } from 'react-grid-system'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinSpacer } from '../../../components/ClinSpacer'
import {
  ContactPhoneDto,
  InstituteContactDto,
  LovDto,
  UpdateContactDto
} from '../../../types/swaggerTypes'
import { array, object, string } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { ClinTextInput } from '../../../components/ClinTextInput'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { isArray } from 'lodash'
import { ClinSelect } from '../../../components/ClinSelect'
import { ClinButton } from '../../../components/ClinButton'
import { ClinGroup } from '../../../components/ClinGroup'
import {
  ContactTitles,
  PhoneContactResponseType,
  phoneTypesRecord,
  userTitleRecord
} from '../../../constants'
import {
  StyledLoadingContainer,
  StyledLoadingSpinner
} from '../InviteNewUser/InviteNewUser.styles'
import { requiredMinStringLength } from '../../../utils/Forms/requiredMinStringLength'
import { useTranslation } from 'react-i18next'
import {
  getInputPlaceholder,
  PlaceholderType
} from '../../../utils/Forms/getInputPlaceholder'
import { countryCodes } from '../../../constants'
import {
  getInputValidation,
  ValidationType
} from '../../../utils/Forms/getInputValidation'

interface IEditContactFormProps {
  /** An array of specialism values */
  specialisms: LovDto[]
  /** Contact to be edited */
  contact: InstituteContactDto
  /** An array of lov job title values */
  jobTitles: LovDto[]
  /** Whether the submission API request is in progress */
  isSubmitting?: boolean
  /** Form submission handler */
  handleFormSubmission: (data: UpdateContactDto) => void
  /** Cancel form and return to previous page */
  handleCancel: () => void
}

export const EditContactForm: FunctionComponent<IEditContactFormProps> = ({
  contact,
  specialisms,
  jobTitles,
  isSubmitting,
  handleFormSubmission,
  handleCancel
}) => {
  const { t } = useTranslation()
  const EditContactFormSchema = object().shape({
    firstName: requiredMinStringLength(100),
    lastName: requiredMinStringLength(100),
    title: requiredMinStringLength(100),
    preferredName: string().test(
      'len',
      getInputValidation(ValidationType.MaxiumumCharacters, '240'),
      (val) => {
        return val === '' || (val && val.length < 240) ? true : false
      }
    ),
    licenseNumber: string().when('classification', {
      is: (classification: string) =>
        classification &&
        (classification.toLowerCase() === 'pharmacist' ||
          classification.toLowerCase() === 'physician'),
      then: string().required(getInputValidation(ValidationType.RequiredField))
    }),
    specialism: requiredMinStringLength(240),
    classification: requiredMinStringLength(240).nullable(),
    phones: array()
      .compact()
      .of(
        object().shape(
          {
            phoneId: string(),
            requestType: string(),
            phoneType: string(),
            areaCode: string().when(['phoneNumber'], {
              is: (phoneNumber: any) => phoneNumber,
              then: requiredMinStringLength(20)
            }),
            phoneNumber: string().when(['areaCode'], {
              is: (areaCode: any) => areaCode,
              then: requiredMinStringLength(20)
            })
          },
          [['areaCode', 'phoneNumber']]
        )
      )
  })
  const contactTitlesOptions = Object.values(ContactTitles)
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
    control
  } = useForm<UpdateContactDto>({
    defaultValues: contact,
    resolver: yupResolver(EditContactFormSchema)
  })

  const watchClassification = watch('classification')
  const showLicenceNumber =
    watchClassification &&
    (watchClassification.toLowerCase() === 'pharmacist' ||
      watchClassification.toLowerCase() === 'physician')

  useEffect(() => {
    reset()
  }, [contact, reset])

  return (
    <form
      onSubmit={handleSubmit((values) => {
        const updatedValues = values
        let filterPhoneResponces = null
        if (updatedValues.phones && updatedValues.phones.length > 0) {
          filterPhoneResponces = updatedValues.phones.filter((item) => {
            if (
              (item.areaCode && item.phoneNumber) ||
              item.requestType === PhoneContactResponseType.DELETE
            ) {
              if (item.requestType === PhoneContactResponseType.DELETE) {
                let contactNumber = contact.phones.find(
                  (contact) => contact.phoneId === item.phoneId
                )
                item.areaCode = contactNumber?.areaCode || ''
                item.countryCode = contactNumber?.countryCode || ''
                item.phoneNumber = contactNumber?.phoneNumber || ''
              }
              return item
            }
            return false
          })
          updatedValues.phones = filterPhoneResponces
        }
        handleFormSubmission(updatedValues)
      })}
    >
      <Row>
        <Col xs={12} lg={7}>
          <Row>
            <Col xs={12} lg={9}>
              <ClinText
                as="h1"
                variant={TypographyVariant.H2}
                fontWeight={ClinTheme.fontWeights.bold}
              >
                {t('edit_contact:title')}
              </ClinText>
              <ClinText variant={TypographyVariant.LargeParagraph}>
                {t('edit_contact:description')}
              </ClinText>
              <ClinSpacer />
              <ClinText variant={TypographyVariant.LargeParagraph}>
                {t('edit_contact:mandatory_description')}
              </ClinText>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[4]} />
          <Row>
            <Col xs={12} lg={9}>
              <Controller
                name="title"
                control={control}
                render={({ field: { onChange } }) => (
                  <ClinSelect
                    id="title"
                    label={t('edit_contact:title_label')}
                    width="100%"
                    defaultValue={contact.title}
                    onChange={(changeValue: ChangeEvent<HTMLSelectElement>) =>
                      onChange(changeValue.currentTarget.value)
                    }
                    hasError={!!(errors && errors.title)}
                    prompt={errors.title?.message}
                  >
                    <option value="" disabled={true}>
                      {getInputPlaceholder(PlaceholderType.SelectInput)}
                    </option>
                    {contactTitlesOptions.map((title: string, index) => {
                      return (
                        <option key={`title-${index}`} value={title}>
                          {t(
                            `${
                              userTitleRecord[title as ContactTitles].transKey
                            }`
                          )}
                        </option>
                      )
                    })}
                  </ClinSelect>
                )}
              />
            </Col>
          </Row>

          <ClinSpacer height={ClinTheme.space[4]} />
          <Row>
            <Col xs={12} lg={9}>
              <ClinTextInput
                id="first-name"
                {...register('firstName')}
                label={t('edit_contact:first_name')}
                width="100%"
                hasError={!!(errors && errors.firstName)}
                prompt={errors.firstName?.message}
              />
              <ClinSpacer height={ClinTheme.space[4]} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={9}>
              <ClinTextInput
                id="last-name"
                {...register('lastName')}
                label={t('edit_contact:last_name')}
                width="100%"
                hasError={!!(errors && errors.lastName)}
                prompt={errors.lastName?.message}
              />
              <ClinSpacer height={ClinTheme.space[4]} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={9}>
              <ClinTextInput
                id="preferred-name"
                {...register('preferredName')}
                label={t('edit_contact:preferred_name')}
                width="100%"
                hasError={!!(errors && errors.preferredName)}
                prompt={errors.preferredName?.message}
              />
              <ClinSpacer height={ClinTheme.space[4]} />
            </Col>
          </Row>

          {specialisms && (
            <Row>
              <Col xs={12} lg={9}>
                <Controller
                  name="specialism"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ClinSelect
                      id="specialism"
                      label={t('edit_contact:specialism')}
                      width="100%"
                      value={value}
                      onChange={(changeValue: ChangeEvent<HTMLSelectElement>) =>
                        onChange(changeValue.currentTarget.value)
                      }
                      hasError={!!(errors && errors.specialism)}
                      prompt={errors.specialism?.message}
                    >
                      <option value="" disabled={true}>
                        {getInputPlaceholder(PlaceholderType.SelectInput)}
                      </option>
                      {specialisms
                        .sort((a, b) =>
                          a.lovValue && b.lovValue
                            ? a.lovValue.localeCompare(b.lovValue)
                            : 0
                        )
                        .map((specialism: LovDto, index) => {
                          return (
                            <option
                              key={`specialism${index}`}
                              value={specialism.lovCode}
                            >
                              {specialism.lovValue}
                            </option>
                          )
                        })}
                    </ClinSelect>
                  )}
                />
                <ClinSpacer height={ClinTheme.space[4]} />
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12} lg={9}>
              <Controller
                name="classification"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ClinSelect
                    id="classification"
                    label={t('edit_contact:job_type')}
                    width="100%"
                    value={value}
                    onChange={(changeValue: ChangeEvent<HTMLSelectElement>) =>
                      onChange(changeValue.currentTarget.value)
                    }
                    hasError={!!(errors && errors.classification)}
                    prompt={errors.classification?.message}
                  >
                    <option value="" disabled={true}>
                      {getInputPlaceholder(PlaceholderType.SelectInput)}
                    </option>
                    {jobTitles.map((jobType: LovDto, index) => {
                      return (
                        <option
                          key={`classification-${index}`}
                          value={jobType.lovCode}
                        >
                          {jobType.lovValue}
                        </option>
                      )
                    })}
                  </ClinSelect>
                )}
              />
              <ClinSpacer height={ClinTheme.space[4]} />
            </Col>
          </Row>

          <Row style={{ display: showLicenceNumber ? 'block' : 'none' }}>
            <Col xs={12} lg={9}>
              <ClinTextInput
                {...register('licenseNumber')}
                id="license-number"
                label={t('edit_contact:license_number')}
                width="100%"
                hasError={!!(errors && errors.licenseNumber)}
                prompt={errors.licenseNumber?.message}
              />
              <ClinSpacer height={ClinTheme.space[4]} />
            </Col>
          </Row>

          {contact.phones.length !== 0 &&
            contact.phones.map((item: ContactPhoneDto, index) => {
              const phoneErrorValues =
                isArray(errors.phones) &&
                errors.phones[index] !== undefined &&
                errors.phones[index]

              const responseType =
                watch(`phones.${index}.countryCode`) === '' &&
                watch(`phones.${index}.areaCode`) === '' &&
                watch(`phones.${index}.phoneNumber`) === ''
                  ? PhoneContactResponseType.DELETE
                  : PhoneContactResponseType.UPDATE

              return (
                <Fragment key={`phone-type-${index}`}>
                  <Row>
                    <Col xs={12} lg={4}>
                      <div style={{ display: 'none' }}>
                        <ClinTextInput
                          {...register(`phones.${index}.phoneId` as const)}
                          id="phone-id"
                        />
                        <ClinTextInput
                          id="phone-type"
                          {...register(`phones.${index}.phoneType` as const)}
                          value={item.phoneType}
                        />
                        <ClinTextInput
                          id="request-type"
                          {...register(`phones.${index}.requestType` as const)}
                          value={responseType}
                        />
                      </div>

                      <Controller
                        name={`phones.${index}.countryCode`}
                        control={control}
                        render={({ field: { onChange } }) => (
                          <ClinSelect
                            id="country-code"
                            label={t('edit_contact:country_code')}
                            width="100%"
                            defaultValue={item.countryCode}
                            onChange={(
                              changeValue: ChangeEvent<HTMLSelectElement>
                            ) => onChange(changeValue.currentTarget.value)}
                            hasError={
                              !!(
                                phoneErrorValues && phoneErrorValues.countryCode
                              )
                            }
                            prompt={
                              phoneErrorValues && phoneErrorValues.countryCode
                                ? phoneErrorValues.countryCode?.message
                                : undefined
                            }
                          >
                            <option value="44">+44</option>
                            {countryCodes.map(
                              (code: string | undefined, index: number) => {
                                return (
                                  <option key={`code-${index}`} value={code}>
                                    {`+${code}`}
                                  </option>
                                )
                              }
                            )}
                          </ClinSelect>
                        )}
                      />
                    </Col>
                    <Col xs={12} lg={3}>
                      <ClinTextInput
                        id="area-code"
                        {...register(`phones.${index}.areaCode`)}
                        label={t('edit_contact:area_code')}
                        type="tel"
                        width="100%"
                        hasError={
                          !!(phoneErrorValues && phoneErrorValues.areaCode)
                        }
                        prompt={
                          phoneErrorValues && phoneErrorValues.areaCode
                            ? phoneErrorValues.areaCode?.message
                            : undefined
                        }
                      />
                    </Col>
                    <Col xs={12} lg={5}>
                      <ClinTextInput
                        id="phone-number"
                        {...register(`phones.${index}.phoneNumber`)}
                        label={t('edit_contact:phone_number')}
                        type="tel"
                        width="100%"
                        hasError={
                          !!(phoneErrorValues && phoneErrorValues.phoneNumber)
                        }
                        prompt={
                          phoneErrorValues && phoneErrorValues.phoneNumber
                            ? phoneErrorValues.phoneNumber?.message
                            : undefined
                        }
                      />
                    </Col>
                  </Row>
                  <ClinSpacer height={ClinTheme.space[4]} />
                </Fragment>
              )
            })}

          {contact.phones.length === 0 && (
            <>
              <Row>
                <Col xs={12} lg={4}>
                  <div style={{ display: 'none' }}>
                    <ClinTextInput
                      id="phone-type"
                      {...register(`phones.0.phoneType` as const)}
                      value={phoneTypesRecord['GEN'].type}
                    />
                    <ClinTextInput
                      id="request-type"
                      {...register(`phones.0.requestType` as const)}
                      value={PhoneContactResponseType.CREATE}
                    />
                  </div>

                  <Controller
                    name="phones.0.countryCode"
                    control={control}
                    defaultValue={'44'}
                    render={({ field: { onChange, value } }) => {
                      !value &&
                        setValue('phones.0.countryCode', '44', {
                          shouldDirty: true
                        })
                      return (
                        <ClinSelect
                          id="country-code"
                          label={t('edit_contact:country_code')}
                          width="100%"
                          defaultValue={value}
                          onChange={(
                            changeValue: ChangeEvent<HTMLSelectElement>
                          ) => onChange(changeValue.currentTarget.value)}
                          hasError={
                            !!(
                              isArray(errors.phones) &&
                              errors.phones[0] !== undefined &&
                              errors.phones[0].countryCode
                            )
                          }
                          prompt={
                            isArray(errors.phones) &&
                            errors.phones[0] !== undefined &&
                            errors.phones[0].countryCode
                              ? errors.phones[0].countryCode?.message
                              : undefined
                          }
                        >
                          <option value="44">+44</option>
                          {countryCodes.map(
                            (code: string | undefined, index: number) => {
                              return (
                                <option key={`code-${index}`} value={code}>
                                  {`+${code}`}
                                </option>
                              )
                            }
                          )}
                        </ClinSelect>
                      )
                    }}
                  />
                </Col>
                <Col xs={12} lg={3}>
                  <ClinTextInput
                    id="area-code"
                    {...register('phones.0.areaCode')}
                    label={t('edit_contact:area_code')}
                    width="100%"
                    type="tel"
                    hasError={
                      !!(
                        isArray(errors.phones) &&
                        errors.phones[0] !== undefined &&
                        errors.phones[0].areaCode
                      )
                    }
                    prompt={
                      isArray(errors.phones) &&
                      errors.phones[0] !== undefined &&
                      errors.phones[0].areaCode
                        ? errors.phones[0].areaCode?.message
                        : undefined
                    }
                  />
                </Col>
                <Col xs={12} lg={5}>
                  <ClinTextInput
                    id="phone-number"
                    {...register('phones.0.phoneNumber')}
                    label={t('edit_contact:phone_number')}
                    width="100%"
                    type="tel"
                    hasError={
                      !!(
                        isArray(errors.phones) &&
                        errors.phones[0] !== undefined &&
                        errors.phones[0].phoneNumber
                      )
                    }
                    prompt={
                      isArray(errors.phones) &&
                      errors.phones[0] !== undefined &&
                      errors.phones[0].phoneNumber
                        ? errors.phones[0].phoneNumber?.message
                        : undefined
                    }
                  />
                </Col>
              </Row>
              <ClinSpacer height={ClinTheme.space[4]} />
            </>
          )}
          <ClinSpacer height={ClinTheme.space[4]} />

          <ClinGroup>
            <ClinButton onClick={() => handleCancel()}>
              {t('common:buttons.cancel')}
            </ClinButton>
            <StyledLoadingContainer>
              <ClinButton
                type="submit"
                variant="primary"
                disabled={isSubmitting}
              >
                {t('common:buttons.save')}
              </ClinButton>
              {isSubmitting && (
                <StyledLoadingSpinner>
                  <ClinSpinner />
                </StyledLoadingSpinner>
              )}
            </StyledLoadingContainer>
          </ClinGroup>
        </Col>
      </Row>
    </form>
  )
}
