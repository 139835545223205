import React, { FunctionComponent, useRef } from 'react'

import { useTranslation } from 'react-i18next'

import { useOnMount } from '../../../utils/useOnMount'
import { ActionDocumentDropdownItem } from './ProgramDetail.models'
import {
  StyledActionDropdown,
  StyledPatientActionLink,
  StyledPatientActionMenuWrapper
} from '../../Patients/PatientDashboard/PatientDashboard.styles'

interface ITableActionMenuProps<T, I> {
  action: T
  items: I[]
  handleCloseActionMenu: () => void
}

const TableActionMenu: FunctionComponent<ITableActionMenuProps<any, any>> = ({
  items,
  action,
  handleCloseActionMenu
}) => {
  const { t } = useTranslation()
  const dropdownRef = useRef<any>()

  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: ActionDocumentDropdownItem
  ) => {
    item.onClick(action)
    event.stopPropagation()
  }

  const handleOutusidePopupClick = (event: Event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      handleCloseActionMenu()
    }
  }

  useOnMount(() => {
    document.addEventListener('click', (event) =>
      handleOutusidePopupClick(event)
    )
  })

  return (
    <>
      <StyledActionDropdown
        onClick={(e) => e.stopPropagation()}
        ref={dropdownRef}
      >
        <StyledPatientActionMenuWrapper>
          {items &&
            items.length &&
            items.map((item, index) => (
              <StyledPatientActionLink
                key={index}
                variant="linkButton"
                onClick={(e) => handleOnClick(e, item)}
              >
                {t(item.text)}
              </StyledPatientActionLink>
            ))}
        </StyledPatientActionMenuWrapper>
      </StyledActionDropdown>
    </>
  )
}

export default TableActionMenu
