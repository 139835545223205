import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQueryDown } from '../../utils/mediaQuery'
import { ITextInputStyles } from './ClinTextInput'

export const StyledInputWrapper = styled.div({
  position: 'relative',
  display: 'inline-block',
  width: '100%'
})

const getBoxShadow = (
  isValid?: boolean,
  hasError?: boolean,
  hasFocus?: boolean
) => {
  let boxShadowColor

  if (hasError) {
    boxShadowColor = ClinTheme.colors.redInvalid
  } else if (isValid) {
    boxShadowColor = ClinTheme.colors.greenValid
  } else if (hasFocus) {
    boxShadowColor = ClinTheme.colors.primaryMid
  } else {
    boxShadowColor = 'none'
  }

  return hasFocus ? `0px 0px 3px 0px ${boxShadowColor}` : boxShadowColor
}

export const StyledClinTextInput = styled.input<ITextInputStyles>(
  {
    fontFamily: ClinTheme.fonts.body,
    fontSize: ClinTheme.fontSizes[2],
    fontWeight: ClinTheme.fontWeights.normal,
    lineHeight: `${ClinTheme.fontSizes[4] - 2}px`, // subtract top and bottom border 1px widths
    letterSpacing: ClinTheme.letterSpacing[0],
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[3]}px`,
    borderRadius: ClinTheme.radii[2],
    border: `solid 1px ${ClinTheme.colors.grey}`,
    transition: '.1s linear box-shadow',
    boxSizing: 'border-box',
    [`:focus`]: {
      outline: 'none',
      border: `solid 1px ${ClinTheme.colors.primaryMid}`,
      boxShadow: getBoxShadow(false, false, true)
    },
    ':-ms-input-placeholder': {
      // Special case for Text Inputs to have correct height in IE 11
      height: '40px'
    },
    ':disabled': {
      cursor: 'not-allowed'
    }
  },
  ({
    isValid,
    hasError,
    disabled,
    placeholderColor,
    width = '100%',
    color = ClinTheme.colors.black
  }: ITextInputStyles) => ({
    color: !disabled ? color : ClinTheme.colors.darkGrey,
    borderColor: hasError
      ? ClinTheme.colors.redInvalid
      : isValid
      ? ClinTheme.colors.greenValid
      : ClinTheme.colors.grey,
    boxShadow: getBoxShadow(isValid, hasError),
    width,
    backgroundColor: disabled
      ? ClinTheme.colors.lightGrey
      : ClinTheme.colors.white,
    '::placeholder': {
      color: placeholderColor ?? 'inherit'
    }
  })
)

const mobileUpBreakpoint = ClinTheme.breakpoints[1]
export const StyledLabel = styled.label<ITextInputStyles>(
  {
    display: 'block',
    [`:focus`]: {
      outline: 'none'
    }
  },
  ({ width }: ITextInputStyles) => ({
    width
  }),
  ({ marginBottom }: ITextInputStyles) => ({
    [mediaQueryDown(mobileUpBreakpoint)]: {
      marginBottom: marginBottom ?? 0
    }
  })
)
