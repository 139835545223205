import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { ProvideAppContext } from './context/app'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import App from './pages/App/App'
import { LogIn } from './pages/LogIn'
import { PrivateRoute } from './components/PrivateRoute'
import { AppInformation } from './components/AppInformation'
import packageJson from '../package.json'
import versionJson from './version.json'
import { client } from './services/ContentApiService'
import { ApolloProvider } from '@apollo/client'
import config from './services/ConfigProvider'
import { FeatureFlagProvider } from './context/featureFlags/FeatureFlagContext'

// redirectUrl enables a redirect value to be stored against
// the user enabling a specific page to be loaded in a new session
const redirectUrl = window.location.href

ReactDOM.render(
  <React.StrictMode>
    <ProvideAppContext>
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <Route
              path={['/', '/login']}
              exact
              render={() => <LogIn redirectUrl={redirectUrl} />}
            />
            <FeatureFlagProvider>
              <PrivateRoute exact component={App} />
            </FeatureFlagProvider>
          </Switch>
        </Router>
        <AppInformation
          name={packageJson.name}
          version={packageJson.version}
          buildInfo={
            versionJson.buildNumber !== '#{BuildNumber}#'
              ? versionJson.buildNumber
              : '0'
          }
          logToConsole={true}
          renderInline={false}
        />
      </ApolloProvider>
    </ProvideAppContext>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// Disable console logs for > dev or local
// Located here so we get app information logged out first
if (config.environment !== 'local' && config.environment !== 'dev') {
  console.log = () => {}
  console.info = () => {}
}
