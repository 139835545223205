import React, { FunctionComponent, useEffect, RefObject } from 'react'
import { useQuery } from '@apollo/client'
import { AppQueries } from '../../types/ContentTypes'
import { Row, Col } from 'react-grid-system'
import { ClinSpinner } from '../../components/ClinSpinner'
import {
  getTopicContentBySlug,
  getTopicLinks
} from '../../services/ContentApiService'
import { RouteComponentProps } from 'react-router-dom'
import { SupportDetail } from './SupportDetail'
import { ClinTheme } from '../../ClinTheme'
import { StyledSpinnerContainer } from '../../components/ClinSpinner/ClinSpinner.styles'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { useAppContext } from '../../context/app'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import Player from '@vimeo/player'
import { isAusGaUser } from '../../constants'

interface ISupportDetailContainerRouteParams {
  pageName: string
}

interface ISupportDetailContainerProps
  extends RouteComponentProps<ISupportDetailContainerRouteParams> {}

export const SupportDetailContainer: FunctionComponent<
  ISupportDetailContainerProps
> = ({ match, history }) => {
  const { dispatch, portfolioCountryCode, userRole } = useAppContext()
  const { pageName } = match.params
  const { loading, data, error } = useQuery<AppQueries>(getTopicContentBySlug, {
    errorPolicy: 'all',
    variables: { slug: `data/slug/en eq '${pageName}'` }
  })
  const {
    loading: linksLoading,
    data: linksData,
    error: linksErrors
  } = useQuery<AppQueries>(getTopicLinks)
  const parentPageName =
    data &&
    data.querySupportMainPageContents &&
    data.querySupportMainPageContents[0].flatData.title
      ? data.querySupportMainPageContents[0].flatData.title
      : ''

  const handleGoBack = () => {
    history.push('/support')
  }

  useEffect(() => {
    if (error) {
      dispatch(
        createAnnounceEvent(
          AnnounceMode.Error,
          `There was an error loading this content. ${error}`
        )
      )
    }
  }, [dispatch, error])

  if (loading && linksLoading)
    return (
      <Row justify="center">
        <Col width="auto">
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    )

  if (error || linksErrors) return <p>ERROR</p>

  let filteredLinks =
    linksData &&
    linksData.queryTopicTemplateContents &&
    linksData.queryTopicTemplateContents.filter(
      (link) =>
        data?.queryTopicTemplateContents &&
        link.flatData.slug !== data.queryTopicTemplateContents[0].flatData.slug
    )

  if (isAusGaUser(portfolioCountryCode, userRole)) {
    filteredLinks = filteredLinks?.filter(
      (x) =>
        data?.queryTopicTemplateContents && x.flatData.slug !== 'access-program'
    )
  }

  const handleSectionCallback = (ref: RefObject<HTMLIFrameElement>) => {
    if (ref.current) {
      new Player(ref.current).pause()
    }
  }

  const handlePageChangeRequest = (path: string) => {
    history.push(path)
  }

  return (
    <>
      {data &&
        data.queryTopicTemplateContents &&
        data.querySupportMainPageContents &&
        data.queryTopicTemplateContents[0] &&
        filteredLinks && (
          <SupportDetail
            slug={pageName}
            parentPageName={parentPageName}
            content={data.queryTopicTemplateContents[0]}
            otherTopicLinks={filteredLinks}
            userCountry={portfolioCountryCode}
            userRole={userRole}
            handleGoBack={handleGoBack}
            handleSectionCallback={handleSectionCallback}
            handlePageChangeRequest={handlePageChangeRequest}
          />
        )}
    </>
  )
}
