import React, { FunctionComponent, useEffect, useState } from 'react'
import { useAppContext } from '../../context/app'
import {
  createHideInstituteModalEvent,
  createSetFirstAccessEvent
} from '../../events/InstituteModalEvent'
import { LightweightInstituteDto } from '../../types/swaggerTypes'
import { InstituteModal, ModalViewMode } from '../../features/InstituteModal'
import { createSwitchInstituteEvent } from '../../events/InstituteEvent'
import { useHistory, useLocation } from 'react-router'
import { matchInArray } from '../../features/Search/AutoSuggest/AutoSuggest.model'
import { BootstrapErrorType } from '../../types/IBootstrapError'
import { redirectUser } from '../../utils/redirectToHome'
import { useCheckUnableHomePage } from '../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'

export const InstituteModalContainer: FunctionComponent = () => {
  const history = useHistory()
  const location = useLocation()
  const {
    dispatch,
    userDetails,
    userRole,
    institute,
    institutes,
    isFirstAccess,
    isInstituteModalOpen,
    instituteWasSwitched,
    bootstrapError,
    portfolioCountryCode
  } = useAppContext()
  const [currentInstitute, setCurrentInstitute] =
    useState<LightweightInstituteDto | null>()
  const [selectedInstitute, setSelectedInstitute] =
    useState<LightweightInstituteDto | null>()
  const [modalViewMode, setModalViewMode] = useState<ModalViewMode>(
    ModalViewMode.Loading
  )
  const { redirectUrl } = useCheckUnableHomePage()

  const handleChange = (id: string) => {
    const matchingInstitute = institutes?.find(
      (item: LightweightInstituteDto) => item.instituteId === id
    )
    if (matchingInstitute) {
      setSelectedInstitute(matchingInstitute)
    }
  }

  const handleSubmit = (institute: LightweightInstituteDto) => {
    setModalViewMode(ModalViewMode.Submitting)
    dispatch(createSwitchInstituteEvent(institute.instituteId, true))
  }

  const handleClose = () => {
    currentInstitute && setSelectedInstitute(currentInstitute)
    dispatch(createHideInstituteModalEvent())
    isFirstAccess && dispatch(createSetFirstAccessEvent(false))

    //this will redirect user if he is on home screen and he didn't choose institute. Othervise he will stay on home page which is empty.
    if (isFirstAccess || window.location.pathname === '/homepage') {
      redirectUser(
        portfolioCountryCode,
        userRole,
        location.pathname,
        redirectUrl
      )
    }
  }

  const handleRequestAccess = () => {
    const isPageSet = matchInArray(location.pathname, ['request-access'])
    dispatch(createHideInstituteModalEvent())
    !isPageSet && history.push('/institute/request-access')
  }

  // Hide modal once institute is updated
  useEffect(() => {
    // When institute has switched and loaded show submitted
    if (instituteWasSwitched) {
      // Blow state away
      redirectUser(portfolioCountryCode, userRole, undefined, redirectUrl)
    }
  }, [instituteWasSwitched])

  // Get all institutes
  useEffect(() => {
    institutes && setModalViewMode(ModalViewMode.ShowInstitutes)
    const current: LightweightInstituteDto | undefined = institutes?.find(
      (instituteLightWeight) =>
        instituteLightWeight.instituteId.toString() ===
        institute.data?.instituteId.toString()
    )
    current && setCurrentInstitute(current)
    current && setSelectedInstitute(current)
  }, [institutes, institute])

  return userDetails && institutes ? (
    <InstituteModal
      isOpen={isInstituteModalOpen}
      viewMode={modalViewMode}
      isFirstAccess={isFirstAccess}
      currentInstitute={currentInstitute}
      selectedInstitute={selectedInstitute}
      institutesList={institutes}
      hasError={
        bootstrapError !== undefined &&
        bootstrapError.type === BootstrapErrorType.INSTITUTE_ERROR
      }
      errorMessage={bootstrapError?.description}
      handleChange={handleChange}
      handleSubmit={() => selectedInstitute && handleSubmit(selectedInstitute)}
      handleClose={handleClose}
      handleRequestAccess={handleRequestAccess}
    />
  ) : null
}
