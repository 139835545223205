import React, { FunctionComponent } from 'react'
import { ClinText } from '../ClinText'
import { ClinTheme } from '../../ClinTheme'
import { TypographyVariant } from '../ClinText/ClinText.styles'
import { StyledClinMarketingPreferenceWrap } from './ClinMarketingPreferences.styles'
import { ClinGroup } from '../ClinGroup'
import { ClinCheckbox } from '../ClinCheckbox'
import { ClinIcon } from '../ClinIcon'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'

export interface IMarketinPreferenceWithIcon {
  id: string
  title: string
  description: string
  iconName: ClinIconPathName
  iconColor?: string
  checked?: boolean
  initialValue?: boolean
  onPreferenceChange: (value: any) => void
}

export const ClinMarketingPreferenceWithIcon: FunctionComponent<
  IMarketinPreferenceWithIcon
> = ({
  id,
  title,
  description,
  checked,
  iconName,
  iconColor,
  initialValue,
  onPreferenceChange
}) => (
  <StyledClinMarketingPreferenceWrap>
    <ClinGroup alignItems={'flex-start'} direction="row">
      <ClinCheckbox
        checked={checked}
        onClick={(event: React.MouseEvent<HTMLInputElement>) => {
          onPreferenceChange(event.currentTarget.checked)
        }}
        id={id}
      ></ClinCheckbox>
      <div>
        <ClinText
          variant={TypographyVariant.SmallUI}
          fontWeight={ClinTheme.fontWeights.bold}
          lineHeight={'16px'}
          wordBreak={'break-all'}
          marginBottom={0}
          marginTop={0}
        >
          {title}
        </ClinText>
        <ClinText
          variant={TypographyVariant.SmallUI}
          fontWeight={ClinTheme.fontWeights.normal}
          lineHeight={'16px'}
          wordBreak={'keep-all'}
          marginBottom={0}
          marginTop={0}
        >
          {description}
        </ClinText>
      </div>
      <ClinIcon
        iconName={iconName}
        iconFill={iconColor}
        iconSize={32}
        iconHeight={32}
        viewBox="0 0 27 21"
      />
    </ClinGroup>
  </StyledClinMarketingPreferenceWrap>
)
