import { BasketErrorEventType } from '../events/BasketErrorEvent'

export enum ApiBasketErrorType {
  PERMISSION_ERROR = 'urn:clinigen:onlineservices:errors:permission-error', // 403 - checkout in progress
  STATE_ERROR = 'urn:clinigen:onlineservices:errors:basket-state-error', // 400 - when your state is mismatched
  CHECKOUT_ERROR = 'urn:clinigen:onlineservices:errors:checkout-error' // 400 - basket stamps are different
}

export interface IBasketError {
  type: BasketErrorEventType
  errorType: ApiBasketErrorType
}
