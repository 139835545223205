import { t } from 'i18next'
import {
  AuthError,
  getOrderById,
  upsertSkuItemToBasket,
  getPatientDetail
} from '../../../../services/ApiService'
import {
  OPAOrderTypes,
  PatientOrderStatus
} from '../../../Patients/PatientDashboard/Patient.model'
import { LocationState } from 'history'
import { useHistory } from 'react-router'
import { ClinTheme } from '../../../../ClinTheme'
import { StyledActionWrapper } from '../Orders.styles'
import { ClinIcon } from '../../../../components/ClinIcon'
import { OrderStatus } from '../../../../types/OrderStatus'
import React, { createContext, useContext, useState } from 'react'
import { useAppContext } from '../../../../context/app/AppContext'
import { createAnnounceEvent } from '../../../../events/AnnounceEvent'
import { OrderDto, OrderSummaryDto } from '../../../../types/swaggerTypes'
import TableActionMenu from '../../../Programs/ProgramDetail/TableActionMenu'
import { AnalyticsEvent } from '../../../../services/Analytics/AnalyticsEvent'
import { ClinIconPathName } from '../../../../components/ClinIcon/ClinIcon.paths'
import analyticsServiceSingleton from '../../../../services/Analytics/initAnalytics'
import { AnnounceMode } from '../../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { getVariantInformationForCatalogItem } from '../../../../services/PortfolioJourneys/PortfolioJourney.model'

// Create context to manage active dropdown state
const ActionDropdownContext = createContext<{
  activeDropdown: string | null
  setActiveDropdown: (orderId: string | null) => void
}>({
  activeDropdown: null,
  setActiveDropdown: () => {}
})

interface ActionDropdownItem {
  text: string
  color?: string
  onClick: (order: OrderSummaryDto) => void
}

interface ActionProps {
  order: OrderSummaryDto
  handleTrackOrder: () => void
}

// Provider component to wrap the table
export const ActionDropdownProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null)

  return (
    <ActionDropdownContext.Provider
      value={{ activeDropdown, setActiveDropdown }}
    >
      {children}
    </ActionDropdownContext.Provider>
  )
}

const ActionsColumn: React.FC<ActionProps> = ({ order, handleTrackOrder }) => {
  const history = useHistory()
  const { activeDropdown, setActiveDropdown } = useContext(
    ActionDropdownContext
  )

  // Check if this dropdown is currently active
  const isOpen = activeDropdown === order.orderNumber

  const { dispatch, defaultShippingAddress, portfolioCountryCode } =
    useAppContext()

  const handleViewOrderDetails = (order: OrderSummaryDto) => {
    if (order.orderNumber) {
      history.push(`/order/${order.orderNumber}`, {
        from: window.location.pathname
      })
    }
  }

  const handleOrderAgain = async (order: OrderSummaryDto) => {
    // Only attempt to get patient details if we have the IDs
    if (order.patientId && order.physicianId) {
      let patient = await getPatientDetail(
        order.patientId.toString(),
        order.physicianId.toString()
      )
      // One more step to go
      if (
        patient.data.patientOrderStatus == PatientOrderStatus.IncompleteCheckout
      ) {
        history.push(`/opa-checkout/${order.orderNumber}`, {
          from: window.location.pathname,
          patientId: order.patientId,
          patientNumber: order.patientNumber,
          orderType: order.orderType,
          programId: order.programId,
          stockOrder: patient.data.stockOrderFlag
        })
        return
      }
    }

    if (order.programId) {
      handleMAOrder(order)
    } else {
      handleGAOrder(order)
    }
  }

  const handleMAOrder = (order: OrderSummaryDto) => {
    history.push(
      `/programs/access-programs/${order.programId}/patient-access-form/${order.physicianId}`,
      {
        orderType: OPAOrderTypes.Resupply,
        patientNumber: order.patientNumber,
        patientId: order.patientId,
        patientStatus: order.orderStatus,
        stockOrder: order.orderType,
        from: window.location.pathname
      } as LocationState
    )
  }

  const handleGetOrder = async () => {
    if (!order?.orderNumber) {
      return
    }
    getOrderById(order.orderNumber)
      .then((response) => {
        handleRequestAgain(response.data as OrderDto)
      })
      .catch((error) => {
        if (error.message !== AuthError.RequestCancelled) {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching the order. ${error}`
            )
          )
          let currentUser
          try {
            currentUser = JSON.parse(
              localStorage.getItem('current_user') ?? '{}'
            )
          } catch (e) {
            console.error('Error parsing current_user from localStorage', e)
          }
          analyticsServiceSingleton.trackError(error, currentUser)
        }
      })
  }

  const handleRequestAgain = async (orderData: OrderDto) => {
    if (!orderData || !defaultShippingAddress) {
      return
    }

    const StockKeepingUnits = orderData.lines
      .filter(
        (o) =>
          o.skuCatalogItem?.sku &&
          o.medicationFlag === 'Y' &&
          !getVariantInformationForCatalogItem(
            o.skuCatalogItem,
            portfolioCountryCode
          )?.cannotOrder
      )
      .map((o) => o.skuCatalogItem?.sku as string)

    if (StockKeepingUnits.length > 0) {
      try {
        await Promise.all(
          StockKeepingUnits.map((StockKeepingUnit) => {
            // Find the corresponding line item
            const lineItem = orderData.lines.find(
              (line) => line.skuCatalogItem?.sku === StockKeepingUnit
            )

            // Get quantity with safety checks
            let quantity = 1 // Default value
            if (
              lineItem?.quantities?.ordered &&
              typeof lineItem.quantities.ordered === 'number' &&
              lineItem.quantities.ordered > 0
            ) {
              quantity = lineItem.quantities.ordered
            }

            return upsertSkuItemToBasket(StockKeepingUnit, quantity)
          })
        )
        setTimeout(() => history.push('/basket'), 500)
      } catch (error) {
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `${t('order_detail:error_reordering')} ${error}`
          )
        )
      }
    }

    analyticsServiceSingleton.trackEvent(AnalyticsEvent.OpenRepeatOrderModal)
  }

  const handleGAOrder = (order: OrderSummaryDto) => {
    handleGetOrder()
  }

  // Check if the action menu should be disabled
  const isActionMenuDisabled = () => {
    // Disable for cancelled orders (both GA and MA)
    if (order.orderStatus === OrderStatus.Cancelled) {
      return true
    }

    // Disable for medical review status orders
    const medicalReviewStatuses = [
      OrderStatus.UnderMedicalReview,
      OrderStatus.HealthAuthorityReview,
      OrderStatus.MedicalReviewDeclined,
      OrderStatus.HealthAuthorityDeclined
    ]

    return medicalReviewStatuses.includes(order.orderStatus as OrderStatus)
  }

  const getStatusActions = (status: OrderStatus): ActionDropdownItem[] => {
    // If action menu is disabled, return empty array
    if (isActionMenuDisabled()) {
      return []
    }

    let actions: ActionDropdownItem[] = []

    // Check if this is a GA order (no programId means it's a GA order)
    const isGAOrder = !order.programId

    const viewOrderDetailsAction = {
      text: 'orders:actions.view_order_details',
      onClick: () => handleViewOrderDetails(order)
    }

    const orderAgainAction = {
      text: 'orders:actions.order_again',
      onClick: () => handleOrderAgain(order)
    }

    const trackOrderAction = {
      text: 'orders:actions.track_order',
      onClick: () => handleTrackOrder()
    }

    let isOrderAgainEnabled = order.orderabilityFlag === 'Y'

    // condition for MA orders
    let canOrderAgainMAOrder =
      order.programId &&
      order.physicianId &&
      order.patientNumber &&
      order.patientId &&
      order.orderStatus &&
      order.orderType === 'Resupply'

    // Status mapping according to the provided table
    switch (status) {
      case OrderStatus.Submitted:
        if (isGAOrder) {
          actions.push(viewOrderDetailsAction)
        }
        break

      case OrderStatus.Processing:
      case OrderStatus.Pending:
      case OrderStatus.Shipped:
        if (isGAOrder) {
          actions.push(viewOrderDetailsAction)
        } else if (canOrderAgainMAOrder && isOrderAgainEnabled) {
          actions.push(orderAgainAction)
        }
        break

      case OrderStatus.InTransit:
      case OrderStatus.OutForDelivery:
      case OrderStatus.FailedAttempt:
      case OrderStatus.Failed_Attempt:
      case OrderStatus.AvailableForPickup:
        if (isGAOrder) {
          actions.push(viewOrderDetailsAction)
        } else if (canOrderAgainMAOrder && isOrderAgainEnabled) {
          actions.push(orderAgainAction)
        }
        actions.push(trackOrderAction)
        break

      case OrderStatus.Delivered:
      case OrderStatus.FailedDelivery:
        if (isGAOrder) {
          actions.push(viewOrderDetailsAction)
        } else if (canOrderAgainMAOrder && isOrderAgainEnabled) {
          actions.push(orderAgainAction)
        }
        actions.push(trackOrderAction)
        break
    }

    return actions
  }

  const actions = getStatusActions(order.orderStatus as OrderStatus)

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation()
    // If this dropdown is already open, close it
    // Otherwise, set this dropdown as active (which will close any other open dropdown)
    setActiveDropdown(isOpen ? null : order.orderNumber || null)
  }

  const handleCloseActionMenu = () => {
    setActiveDropdown(null)
  }

  return (
    <StyledActionWrapper>
      <div onClick={actions.length > 0 ? handleToggle : undefined}>
        <ClinIcon
          iconName={ClinIconPathName.ThreeDots}
          iconFill={
            actions.length > 0
              ? ClinTheme.colors.darkGrey
              : ClinTheme.colors.grey
          }
          iconSize={28}
          viewBox="0 0 25 26"
        />
      </div>
      {isOpen && actions.length > 0 && (
        <TableActionMenu
          items={actions}
          action={order}
          handleCloseActionMenu={handleCloseActionMenu}
        />
      )}
    </StyledActionWrapper>
  )
}

export default ActionsColumn
