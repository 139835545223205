import { ClinTheme } from '../../ClinTheme'
import { FacetDto } from '../../types/swaggerTypes'

export enum AssociatedPatientsColumn {
  PatientNumber = 'Patient ID',
  Initials = 'Initials',
  DOB = 'Date of Birth',
  ProgramName = 'Program',
  HospitalRefNumber = 'Institute Identifier',
  Status = 'Status'
}

export enum AssociatedPatientsStatus {
  ActiveTreatment = 'Active Treatment',
  Discontinued = 'Discontinued'
}

export enum AssociatedPatientsSortField {
  PatientId = 'PatientId',
  Initials = 'Initials',
  Program = 'Program',
  Status = 'Status'
}

const sortFieldsForColumn = {
  [AssociatedPatientsColumn.PatientNumber.toString()]:
    AssociatedPatientsSortField.PatientId,
  [AssociatedPatientsColumn.Initials.toString()]:
    AssociatedPatientsSortField.Initials,
  [AssociatedPatientsColumn.ProgramName.toString()]:
    AssociatedPatientsSortField.Program,
  [AssociatedPatientsColumn.Status.toString()]:
    AssociatedPatientsSortField.Status
}

const columnForSortField = {
  [AssociatedPatientsSortField.PatientId.toString()]:
    AssociatedPatientsColumn.PatientNumber,
  [AssociatedPatientsSortField.Initials.toString()]:
    AssociatedPatientsColumn.Initials,
  [AssociatedPatientsSortField.Program.toString()]:
    AssociatedPatientsColumn.ProgramName,
  [AssociatedPatientsSortField.Status.toString()]:
    AssociatedPatientsColumn.Status
}

export enum PatientFilterType {
  Status = 'Status',
  Programs = 'Programs'
}

/**
 * Helper function to get API sort field name from UI column name
 * @param columnName
 */
export const getSortFieldForColumn = (
  columnName: AssociatedPatientsColumn
): string | undefined => {
  return sortFieldsForColumn[columnName]
}

export const getColumnForSortField = (sortField: string) => {
  return columnForSortField[sortField]
    ? columnForSortField[sortField]
    : AssociatedPatientsColumn.Status
}
/**
 * Helper function to get text color according to the patient status
 * @param patientStatus
 */
export const getStatusColor = (patientStatus: string) => {
  switch (patientStatus) {
    case AssociatedPatientsStatus.ActiveTreatment:
      return ClinTheme.colors.greenValid
    case AssociatedPatientsStatus.Discontinued:
      return ClinTheme.colors.redInvalid
    default:
      return ClinTheme.colors.greenValid
  }
}
/**
 * Utility function to add or remove filters
 * @param tag
 * @param filters
 */
export const getUpdatedFiltersForTag = (
  tag: FacetDto,
  filters: FacetDto[]
): FacetDto[] => {
  const itemExists = filters.find((t) => t.value === tag.value)
  return itemExists
    ? filters.filter((sf) => sf.value !== tag.value)
    : [...filters, tag]
}

interface IColumn {
  transKey: string
}

/**
 * Look up record/table for returning a column translated key
 * @usage:
 *
 * const { transKey } = ColumnRecord[column]
 *
 */
export const ColumnRecord: Record<AssociatedPatientsColumn, IColumn> = {
  [AssociatedPatientsColumn.PatientNumber]: {
    transKey: 'glossary:patient_id'
  },
  [AssociatedPatientsColumn.Initials]: {
    transKey: 'glossary:initials'
  },
  [AssociatedPatientsColumn.DOB]: {
    transKey: 'glossary:date_of_birth'
  },
  [AssociatedPatientsColumn.ProgramName]: {
    transKey: 'glossary:program'
  },
  [AssociatedPatientsColumn.HospitalRefNumber]: {
    transKey: 'glossary:institute_identifier'
  },
  [AssociatedPatientsColumn.Status]: {
    transKey: 'glossary:status'
  }
}
