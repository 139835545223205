import React, { FunctionComponent, useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { ClinTheme } from '../../../ClinTheme'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import { ClinText } from '../../../components/ClinText'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { PatientDetailsDto } from '../../../types/swaggerTypes'
import { DateTime } from 'luxon'
import { TransferPatientForm } from './TransferPatientForm'
import { truncateString } from '../../../utils/truncateString'
import { ITransferForm } from './TransferPatientForm/TransferPatientForm'
import { IPastOrderColumn } from './PatientDetailContainer'
import { useTranslation } from 'react-i18next'
import { patientOrderStatusTooltipTranslation } from '../PatientDashboard/Patient.model'
import { useFeatureFlags } from '../../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../../context/featureFlags/FeatureFlagKeys'
import { StyledVerticalLine } from '../../Programs/ProgramDetail/StyledVerticalLine'
import { PatientDetailHeader } from './PatientDetailHeader'
import { PatientDetailCard } from './PatientDetailCard'
import RealWorldDataCardPatientDetails from '../../../features/RealWorldDataCardPatientDetails/RealWorldDataCardPatientDetails'
import { ClinPagination } from '../../../components/ClinPagination'
import { TabModel } from '../../Programs/ProgramDetail/ProgramDetail.models'
import { PastOrdersTable } from '../../../features/PastOrdersTable'
import { VialReconciliationTable } from '../../../features/VialReconciliationTable'
import { PatientDetailTabLink } from './PatientDetailTabLink/PatientDetailTabLink'
import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import PatientDetailsStatusCard from './PatientDetailsStatusCard'
import {
  StyledPatientAttributes,
  StyledPatientAttributesCard,
  StyledPatientAttributesCardWrapper,
  StyledPatientTabEmptyHeaderWrapper
} from './PatientDetail.styles'
import {
  IPagination,
  getCurrentPage,
  getTotalPages
} from '../../../components/ClinPagination/ClinPagination.model'
import {
  IPastOrderSummary,
  IPatientVial,
  VialRecordState,
  VialReconciliationType
} from './PatientDetail.types'
import {
  StyledTabSection,
  StyledTabsRow
} from '../../Programs/ProgramDetail/ProgramDetail.styles'
import { PatientOrderStatus } from '../../../pages/Patients/PatientDashboard/Patient.model'

interface IPatientDetailProps {
  /** Whether we are loading or not */
  isLoading: boolean
  /** Primary CTA title */
  detailsCtaButtonTitle: string
  /** Whether we are saving a vial reconciliation */
  isSavingVial: boolean
  /** If the program is closed displaying an appropriated banner */
  isProgramClosed: boolean
  /** If the program allows for discontinuation */
  allowsDiscontinuation: boolean
  /** Selected patient */
  patient?: PatientDetailsDto
  /** Past orders */
  pastOrders: IPastOrderSummary[]
  /** Selected row item / vial */
  selectedVialRow?: IPatientVial
  pastOrderColumns: IPastOrderColumn[]
  tabData: TabModel[]
  selectedOrdersTabIndex: number
  /** If true displays patient transfer form */
  isFormOpen: boolean
  /** Disable transfer button */
  disableTransferButton: boolean
  programRealWorldData: boolean
  /** Called when user close form */
  handleHideTransferForm?: () => void
  /** Called when user open  form */
  handleShowTransferForm?: () => void
  /** Called when form submitted */
  handleFormSubmission?: (form: ITransferForm) => void
  /** Called when for radio is selected **/
  handleDestinationInputChange?: (selectedRadioButtonId: string) => void
  handleChangeTab: (tabIndex: number) => void
  /** Called when the user click on "Primary CTA" */
  handlePrimaryCTA?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Called when the user click on "Order Reference" */
  handleReferenceOrderClicked?: (selectedOrderId: string | number) => void
  /** Pagination to display if there are more than 5 orders */
  pagination?: IPagination
  /** The type of vials to reconcile i.e. vials, packs etc */
  vialsUnitType: string | null
  /** The type of reconciliation - determines what can be edited */
  vialReconciliationType: VialReconciliationType
  /** Called when pagination is clicked */
  handlePageClicked?: (selectedPageIndex: number) => void
  /** Called when pagination page size change is called */
  handlePageSizeChange?: (pageSize: number) => void
  /** Triggered when the date picker is selected */
  handleVialDateInputSelected?: (vial: IPatientVial) => void
  /** Called when vial reconciliation row is saved */
  handleSaveChangesToVial?: (updatedVial: IPatientVial) => void
  /** Discontinue from program */
  handleDiscontinueFromProgram?: () => void
  handleSortConfigChange?: (
    sortColumn: string,
    sortOrder: SortDirectionType
  ) => void
  showRecordTreatmentTab: boolean
}

export const PatientDetail: FunctionComponent<IPatientDetailProps> = ({
  isLoading,
  detailsCtaButtonTitle,
  isProgramClosed,
  allowsDiscontinuation,
  disableTransferButton,
  patient,
  isFormOpen,
  tabData,
  selectedOrdersTabIndex,
  pagination,
  pastOrderColumns,
  handleReferenceOrderClicked,
  pastOrders,
  isSavingVial,
  selectedVialRow,
  vialsUnitType,
  vialReconciliationType,
  programRealWorldData,
  handleSortConfigChange,
  handleVialDateInputSelected,
  handleSaveChangesToVial,
  handleChangeTab,
  handlePageClicked,
  handlePageSizeChange,
  handleHideTransferForm,
  handleFormSubmission,
  handleDestinationInputChange,
  handlePrimaryCTA,
  handleDiscontinueFromProgram,
  handleShowTransferForm,
  showRecordTreatmentTab
}) => {
  const { t } = useTranslation()
  const physicianFullName = `${
    patient?.currentPhysician.physicianTitle ?? ''
  } ${patient?.currentPhysician.physicianFirstName} ${
    patient?.currentPhysician.physicianLastName
  }`

  const { useFeatureFlag } = useFeatureFlags()

  const [vialRecordStates, setVialRecordStates] = useState<VialRecordState[]>(
    []
  )

  const handleVialRecordState = (vial: IPatientVial) => {
    const vialRecordState = {
      vialNumber: vial.vialNumber!,
      batchNumber: vial.batchNumber!,
      vialStatus: vial.vialStatus!,
      dateAdministered: vial.dateAdministered!
    }

    const existingIndex = vialRecordStates.findIndex(
      (v) => v.vialNumber === vial.vialNumber
    )

    if (existingIndex !== -1) {
      const updatedVials = vialRecordStates.map((vial, index) =>
        index === existingIndex ? vialRecordState : vial
      )
      setVialRecordStates(updatedVials)
    } else {
      setVialRecordStates([...vialRecordStates, vialRecordState])
    }
  }

  let patientCentricFeatureFlag = useFeatureFlag(
    FeatureFlagKeys.PatientCentricJourneyPerUser
  )
  let primaryCTATitle = patientCentricFeatureFlag
    ? t(
        patientOrderStatusTooltipTranslation[
          patient?.patientOrderStatus as keyof typeof patientOrderStatusTooltipTranslation
        ] ?? 'patient_detail:title_resupply'
      )
    : t('patient_detail:title_resupply')

  const primaryCTAButtonTitle = patientCentricFeatureFlag
    ? t(detailsCtaButtonTitle || 'patient_detail:button_request_resupply')
    : t('patient_detail:button_request_resupply')

  const areAnyVialsToReconcile = (pastOrders: IPastOrderSummary[]): boolean => {
    const pastOrdersFiltered = pastOrders.filter((o) => o.canBeReconciled)

    return pastOrdersFiltered.reduce((acc, order) => {
      return acc || order.vialsToReconcile.length > 0
    }, false)
  }

  const getDaySuffix = (day: number) => {
    if (day > 3 && day < 21) return 'th' // special case for 11th to 20th
    switch (day % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  if (primaryCTATitle.includes('MISSING KEY')) {
    primaryCTATitle = t('patient_detail:title_resupply')
  }

  if (isFormOpen) {
    return (
      <Container className="transfer-patient-form">
        <TransferPatientForm
          disableTransferButton={disableTransferButton}
          handleClose={() => handleHideTransferForm && handleHideTransferForm()}
          handleFormSubmission={(form) =>
            handleFormSubmission && handleFormSubmission(form)
          }
          handleDestinationInputChange={handleDestinationInputChange}
        />
      </Container>
    )
  }

  return isLoading || !patient ? (
    <ClinPageContentFrame>
      <Row align="center">
        <Col>
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    </ClinPageContentFrame>
  ) : (
    <>
      <PatientDetailHeader
        patient={patient}
        isProgramClosed={isProgramClosed}
        primaryCTAButtonText={primaryCTAButtonTitle}
        primaryCTAButtonTooltip={primaryCTATitle}
        handlePrimaryCTA={handlePrimaryCTA}
        handleShowTransferForm={handleShowTransferForm}
        handleDiscontinueFromProgram={handleDiscontinueFromProgram}
        allowsDiscontinuation={allowsDiscontinuation}
      />

      <ClinPageContentFrame
        className="patient-detail"
        crumbs={[
          { path: '/orders', name: t('orders:page_name') },
          { path: '/programs/my-physicians', name: t('my_physicians:title') },
          {
            name: truncateString(physicianFullName),
            path: `/programs/my-physicians/${patient.currentPhysician.physicianId}`
          },
          {
            name: `${t('glossary:patient')} ${patient.patientNumber}`,
            path: `/product/${patient.patientId}`
          }
        ]}
        hideBreadcrumbs={true}
      >
        <ClinSpacer height={24} />
        <Row>
          <Col md={9} lg={9}>
            <StyledPatientAttributes>
              {patient.patientOrderStatus && (
                <StyledPatientAttributesCardWrapper width={'26%'}>
                  <StyledPatientAttributesCard>
                    <ClinText
                      marginBottom={10}
                      fontSize={ClinTheme.fontSizes[0]}
                      color={ClinTheme.colors.darkGrey}
                      fontWeight={ClinTheme.fontWeights.medium}
                      lineHeight={ClinTheme.lineHeights.largeParagraph}
                    >
                      {t('patient_detail:past_orders:status_column')}
                    </ClinText>
                    <PatientDetailsStatusCard
                      status={patient?.patientOrderStatus as PatientOrderStatus}
                    />
                  </StyledPatientAttributesCard>
                </StyledPatientAttributesCardWrapper>
              )}
              {patient.dateOfBirth && (
                <StyledPatientAttributesCardWrapper>
                  <StyledVerticalLine
                    height="60%"
                    width="1px"
                    backgroundColor={ClinTheme.colors.midGrey}
                  />
                  <StyledPatientAttributesCard>
                    <ClinText
                      marginBottom={16}
                      fontSize={ClinTheme.fontSizes[0]}
                      color={ClinTheme.colors.darkGrey}
                      fontWeight={ClinTheme.fontWeights.medium}
                      lineHeight={ClinTheme.lineHeights.largeParagraph}
                    >
                      {t('patient_detail:patient_attribute_dob')}
                    </ClinText>
                    <ClinText fontWeight={ClinTheme.fontWeights.medium}>
                      {DateTime.fromISO(patient.dateOfBirth, {
                        zone: 'utc'
                      }).toFormat('MMMM d')}
                      {getDaySuffix(
                        DateTime.fromISO(patient.dateOfBirth, { zone: 'utc' })
                          .day
                      )}
                      ,
                      {DateTime.fromISO(patient.dateOfBirth, {
                        zone: 'utc'
                      }).toFormat(' yyyy')}
                    </ClinText>
                  </StyledPatientAttributesCard>
                </StyledPatientAttributesCardWrapper>
              )}
              {patient.currentPhysician.physicianFullName && (
                <StyledPatientAttributesCardWrapper>
                  <StyledVerticalLine
                    height="60%"
                    width="1px"
                    backgroundColor={ClinTheme.colors.midGrey}
                  />
                  <StyledPatientAttributesCard>
                    <ClinText
                      marginBottom={16}
                      fontSize={ClinTheme.fontSizes[0]}
                      color={ClinTheme.colors.darkGrey}
                      fontWeight={ClinTheme.fontWeights.medium}
                      lineHeight={ClinTheme.lineHeights.largeParagraph}
                    >
                      {t('patient_detail:patient_attribute_physician')}
                    </ClinText>
                    <ClinText fontWeight={ClinTheme.fontWeights.medium}>
                      {patient.currentPhysician.physicianFullName}
                    </ClinText>
                  </StyledPatientAttributesCard>
                </StyledPatientAttributesCardWrapper>
              )}
              <StyledPatientAttributesCardWrapper>
                <StyledVerticalLine
                  height="60%"
                  width="1px"
                  backgroundColor={ClinTheme.colors.midGrey}
                />
                <StyledPatientAttributesCard>
                  <ClinText
                    marginBottom={16}
                    fontSize={ClinTheme.fontSizes[0]}
                    color={ClinTheme.colors.darkGrey}
                    fontWeight={ClinTheme.fontWeights.medium}
                    lineHeight={ClinTheme.lineHeights.largeParagraph}
                  >
                    {t('patient_detail:patient_attribute_institute_identifier')}
                  </ClinText>
                  <ClinText fontWeight={ClinTheme.fontWeights.medium}>
                    {patient?.hospitalRefNo
                      ? patient?.hospitalRefNo
                      : t('glossary:not_applicable')}
                  </ClinText>
                </StyledPatientAttributesCard>
              </StyledPatientAttributesCardWrapper>
            </StyledPatientAttributes>
            <ClinSpacer height={16} />
            <StyledTabsRow>
              <Col md={12} lg={12}>
                <StyledTabSection
                  selectedTabIndex={selectedOrdersTabIndex}
                  style={{
                    minWidth: '508px',
                    borderTopLeftRadius: `${ClinTheme.radii[2]}px`,
                    borderTopRightRadius: `${ClinTheme.space[1]}px`
                  }}
                >
                  {tabData.map((tab) => (
                    <PatientDetailTabLink
                      key={tab.tabIndex}
                      linkText={tab.linkText}
                      showCountNumber={tab.showCountNumber}
                      countNumber={tab.countNumber}
                      tabIndex={tab.tabIndex}
                      active={selectedOrdersTabIndex === tab.tabIndex}
                      onClick={() => handleChangeTab(tab.tabIndex)}
                    />
                  ))}
                </StyledTabSection>
              </Col>
            </StyledTabsRow>
            {selectedOrdersTabIndex === 0 && (
              <>
                <PastOrdersTable
                  isLoading={isLoading}
                  columns={pastOrderColumns}
                  pastOrders={pastOrders}
                  handleSortConfigChange={handleSortConfigChange}
                  handleReferenceOrderClicked={handleReferenceOrderClicked}
                />
                <ClinSpacer />
                <Row>
                  <Col lg={12}>
                    {!isLoading &&
                      patient.pastOrders &&
                      patient.pastOrders.length > 0 &&
                      pagination &&
                      pagination.total > 0 && (
                        <ClinPagination
                          showLeftSpacer={true}
                          showResultsPerPage={false}
                          currentPage={getCurrentPage(pagination)}
                          totalPages={getTotalPages(pagination)}
                          pageSize={pagination.take}
                          handlePageSelected={(pageIndex) =>
                            handlePageClicked && handlePageClicked(pageIndex)
                          }
                          handlePageSizeChange={handlePageSizeChange}
                        />
                      )}
                  </Col>
                </Row>
              </>
            )}
            {showRecordTreatmentTab && selectedOrdersTabIndex === 1 && (
              <Row>
                <Col md={12} lg={12}>
                  {!areAnyVialsToReconcile(pastOrders) && (
                    <StyledPatientTabEmptyHeaderWrapper>
                      <ClinText
                        fontSize={ClinTheme.fontSizes[1]}
                        textAlign={'center'}
                      >
                        {t('patient_detail:empty_vial_reconciliation_header')}
                      </ClinText>
                    </StyledPatientTabEmptyHeaderWrapper>
                  )}
                  {pastOrders?.map((order, index) => (
                    <React.Fragment key={order.orderNumber}>
                      {order.canBeReconciled &&
                        order.vialsToReconcile.length > 0 && (
                          <VialReconciliationTable
                            showDebug={false}
                            isSavingVial={isSavingVial}
                            selectedVial={selectedVialRow}
                            patientVials={order.vialsToReconcile}
                            unitType={vialsUnitType}
                            vialReconciliationType={vialReconciliationType}
                            vialRecordStates={vialRecordStates}
                            handleVialRecordState={handleVialRecordState}
                            handleSaveChanges={handleSaveChangesToVial}
                            handleVialDateInputSelected={
                              handleVialDateInputSelected
                            }
                            showHeaders={index === 0} // Show headers only for the first table instance
                          />
                        )}
                    </React.Fragment>
                  ))}
                </Col>
              </Row>
            )}
          </Col>
          <Col md={3} lg={3}>
            <PatientDetailCard
              programName={patient.program.programName}
              programStatus={patient.program.status}
              programSponsor={patient.program.sponsor}
              programId={patient.program.programId}
            />
            <ClinSpacer height={16} />
            {patient?.programEnrolledFlag === 'Y' && programRealWorldData && (
              <RealWorldDataCardPatientDetails patient={patient} />
            )}
          </Col>
        </Row>
      </ClinPageContentFrame>
    </>
  )
}
