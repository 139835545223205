import React, { FunctionComponent } from 'react'
import { Redirect, Route } from 'react-router'
import { useAppContext } from '../../context/app'
import { NotFound } from '../../pages/NotFound'
import { getIsPageAccessibleFromCountry } from '../../utils/getIsPageAccessibleFromCountry'

interface IPrivateCountryRouteProps {
  component: FunctionComponent<any>
  exact: boolean
  path: string
  redirect?: string
  /** Optional error page (uses standard NotFound 404 page by default) */
  errorPage?: FunctionComponent<any>
  /** Optional check for access from a specific country */
  onlyAllowForCountryCodes?: string[]
}

export const PrivateCountryRoute: FunctionComponent<
  IPrivateCountryRouteProps
> = ({
  component,
  path,
  exact,
  redirect,
  errorPage = NotFound,
  onlyAllowForCountryCodes
}) => {
  const { portfolioCountryCode } = useAppContext()

  return (
    <>
      {getIsPageAccessibleFromCountry(
        portfolioCountryCode,
        onlyAllowForCountryCodes
      ) ? (
        <Route path={path} exact={exact} component={component} />
      ) : (
        <>
          {redirect ? (
            <Redirect to={redirect} exact={true} />
          ) : (
            <Route path={path} exact={true} component={errorPage} />
          )}
        </>
      )}
    </>
  )
}
