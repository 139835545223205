import React from 'react'
import { ClinText } from '../../../../components/ClinText'
import { ClinTheme } from '../../../../ClinTheme'
import ColumnTooltip from '../ColumnTooltip/ColumnTooltip'

interface PurchaseOrderColumnProps {
  purchaseOrderNumber: string | undefined
}

const MAX_VISIBLE_LENGTH = 10

const PurchaseOrderColumn: React.FC<PurchaseOrderColumnProps> = ({
  purchaseOrderNumber
}) => {
  const displayPurchaseOrderNumber = purchaseOrderNumber || 'N/A'
  const isTruncated = displayPurchaseOrderNumber.length > MAX_VISIBLE_LENGTH

  const renderContent = () => (
    <ClinText
      fontSize={ClinTheme.fontSizes[1]}
      lineHeight={ClinTheme.lineHeights.heading[0]}
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {isTruncated
        ? `${displayPurchaseOrderNumber.slice(0, MAX_VISIBLE_LENGTH)}...`
        : displayPurchaseOrderNumber}
    </ClinText>
  )

  if (isTruncated) {
    return (
      <ColumnTooltip tooltipText={displayPurchaseOrderNumber}>
        {renderContent()}
      </ColumnTooltip>
    )
  }

  return renderContent()
}

export default PurchaseOrderColumn
