import { ClinTheme } from '../ClinTheme'

export const mediaQuery = (
  breakPoint: (typeof ClinTheme.breakpoints)[number]
) => `@media (min-width: ${breakPoint}px)`

export const mediaQueryDown = (
  breakPoint: (typeof ClinTheme.breakpoints)[number]
) => `@media (max-width: ${breakPoint}px)`

export const mediaQueryTooltip = (breakPoint: number) =>
  `@media (max-width: ${breakPoint}px)`

export const betweenQueryTooltip = (min: number, max: number) =>
  `@media only screen and (min-device-width: ${min}px) and (max-device-width: ${max}px)`
