import { useEffect } from 'react'
import { ClinTheme } from '../../ClinTheme'

const useChangeBackgroundColor = (color: string, tabIndex?: number) => {
  useEffect(() => {
    const element = document.getElementsByTagName('main')[0]
    const elementBody = document.getElementsByTagName('body')[0]

    if (element) {
      element.style.backgroundColor = color
      elementBody.style.backgroundColor = color
    }

    return () => {
      if (element) {
        element.style.backgroundColor = ClinTheme.colors.white
        elementBody.style.backgroundColor = 'inherit'
      }
    }
  }, [color, tabIndex])
}

export default useChangeBackgroundColor
