import React, { FunctionComponent } from 'react'
import {
  StyledClinDividerHeading,
  IClinDividerHeadingStyles
} from './ClinDividerHeading.styles'

interface IClinDividerHeadingProps extends IClinDividerHeadingStyles {
  /** Content inside the divider heading */
  children: React.ReactNode
}

export const ClinDividerHeading: FunctionComponent<
  IClinDividerHeadingProps
> = ({ children, ...props }) => (
  <StyledClinDividerHeading {...props}>{children}</StyledClinDividerHeading>
)
