import React, {
  cloneElement,
  FunctionComponent,
  useEffect,
  useState
} from 'react'
import { StyledClinTabs } from './ClinTabs.styles'
import { ClinTabHeaderElement } from '../ClinTabHeader'

interface IClinTabsProps {
  /**  The tab index that's active  */
  activeItemIndex?: number
  /**  Whether tab is disabled from user interaction  */
  isDisabled?: boolean
  /** Only accepts children that are ClinTabHeader */
  children: ClinTabHeaderElement[] | ClinTabHeaderElement
  /** Callback for tab change */
  handleSelected?: (selectedTabIndex: number) => void
}

export const ClinTabs: FunctionComponent<IClinTabsProps> = ({
  activeItemIndex = 0,
  isDisabled,
  children,
  handleSelected
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(activeItemIndex)
  const handleTabClick = (index: number) => {
    if (isDisabled) return
    setActiveIndex(index)
    handleSelected && handleSelected(index)
  }

  useEffect(() => {
    setActiveIndex(activeItemIndex)
  }, [activeItemIndex])

  return (
    <StyledClinTabs role="tablist" aria-label="Tabs" aria-disabled={isDisabled}>
      {children && (
        <>
          {Array.isArray(children)
            ? (children as Array<ClinTabHeaderElement>).map(
                (tabItem: ClinTabHeaderElement, idx: number) => {
                  const key = tabItem.key || `tab-item-${idx}`
                  return cloneElement(tabItem, {
                    ...tabItem.props,
                    key,
                    isActive: idx === activeIndex,
                    isDisabled,
                    handleClick: () => handleTabClick(idx)
                  })
                }
              )
            : cloneElement(children, {
                ...children.props,
                key: children.key || 'tab-item-0',
                isActive: true,
                isDisabled,
                handleClick: () => handleTabClick(0)
              })}
        </>
      )}
    </StyledClinTabs>
  )
}
