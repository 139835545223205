import React, { FunctionComponent } from 'react'
import { StyledUkShortages, StyledNoShortagesMessage } from './Shortages.styles'
import { ClinTheme } from '../../../ClinTheme'
import { Col, Row } from 'react-grid-system'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { ClinTable } from '../../../components/ClinTable'
import {
  ClinTableBodyCell,
  ClinTableHeaderCell,
  ClinTableRow
} from '../../../components/ClinTable/ClinTable'
import { ClinTableOrderToggle } from '../../../components/ClinTableOrderToggle'
import { ClinChip } from '../../../components/ClinChip'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { Link } from 'react-router-dom'
import { ClinButton } from '../../../components/ClinButton'
import { CatalogItemDto } from '../../../types/swaggerTypes'
import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n/config'
import { CountryAlphaCodes } from '../../../constants/countryAlpha2Codes'
import { UserRole, isAusGaUser, isAusUser } from '../../../constants'
import { useCheckUnableHomePage } from '../../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'

interface IShortagesProps {
  countryCode: string
  /** List of shortages */
  shortages?: CatalogItemDto[]
  /** Current sorted column */
  sortBy: UkShortagesSortBy
  /** Direction of the sort */
  sortDirection: SortDirectionType
  /** Called when user clicks on a shortage */
  onClickShortage?: (shortage: CatalogItemDto) => void
  /** Called when the user toggles the search order of a column */
  onClickOrderToggle?: (sortBy: UkShortagesSortBy) => void
  /** User country*/
  userCountry: string
  /** User role*/
  userRole?: UserRole
}

export type UkShortagesSortBy =
  | 'genericConcatenationNormalized'
  | 'genericDosageConcatenationNormalized'
  | 'shortage/startDate'
  | 'shortage/regulatoryPathway'

interface IColumn {
  title: string
  renderValue: (shortage: CatalogItemDto) => JSX.Element | string
  width: string
  sortKey?: UkShortagesSortBy
}

export const Shortages: FunctionComponent<IShortagesProps> = ({
  countryCode,
  shortages,
  sortBy,
  sortDirection,
  onClickShortage,
  onClickOrderToggle,
  userCountry,
  userRole
}) => {
  const { t } = useTranslation()
  const { enableNewHomePage } = useCheckUnableHomePage()

  const columns: IColumn[] = [
    {
      title: t('uk_shortages:column_titles.in_shortage'),
      renderValue: function column(shortage: CatalogItemDto) {
        return (
          <ClinText fontWeight={ClinTheme.fontWeights.bold}>
            {shortage.genericConcatenation}
          </ClinText>
        )
      },
      sortKey: 'genericConcatenationNormalized' as UkShortagesSortBy,
      width: '20%'
    },
    {
      title: t('uk_shortages:column_titles.alternative'),
      renderValue: function column(shortage: CatalogItemDto) {
        return (
          <ClinText
            fontWeight={ClinTheme.fontWeights.bold}
            color={ClinTheme.colors.primary}
            className={'shortages__alternative-name'}
          >
            {shortage.genericDosageConcatenation}
          </ClinText>
        )
      },
      sortKey: 'genericDosageConcatenationNormalized' as UkShortagesSortBy,
      width: '25%'
    },
    {
      title: t('uk_shortages:column_titles.form_and_pack_size'),
      renderValue: function column(shortage: CatalogItemDto) {
        return <ClinChip> {shortage.formulationPackSize}</ClinChip>
      },
      width: '20%'
    },

    isAusUser(countryCode)
      ? {
          title: t('uk_shortages:column_titles.regulatory_pathway'),
          renderValue: function column(shortage: CatalogItemDto) {
            const regulatoryPath =
              shortage.shortages &&
              shortage.shortages.find((x) => x.country === countryCode)
                ? shortage.shortages.find((x) => x.country === countryCode)
                    ?.regulatoryPathway
                : ''
            return (
              <ClinText
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.medium}
                textAlign="center"
              >
                {regulatoryPath}
              </ClinText>
            )
          },
          sortKey: 'shortage/regulatoryPathway' as UkShortagesSortBy,
          width: '13%'
        }
      : {
          title: t('uk_shortages:column_titles.doh_start_date'),
          renderValue: function column(shortage: CatalogItemDto) {
            const startDate =
              shortage.shortages &&
              shortage.shortages.find((x) => x.country === countryCode)
                ? shortage.shortages.find((x) => x.country === countryCode)
                    ?.startDate!
                : ''

            //condition added to prevent invalid date format in table in case we don't get start date
            if (startDate) {
              return DateTime.fromISO(startDate).toLocaleString(
                {
                  month: 'long',
                  year: 'numeric'
                },
                { locale: i18n.language }
              )
            }
            return ''
          },
          sortKey: 'shortage/startDate' as UkShortagesSortBy,
          width: '13%'
        },
    {
      title: t('uk_shortages:column_titles.doh_status'),
      renderValue: function column() {
        return (
          <ClinText color={ClinTheme.colors.orangeWarn}>
            {t('uk_shortages:in_shortage')}
          </ClinText>
        )
      },
      width: '12%'
    }
  ]
  return (
    <StyledUkShortages>
      <ClinPageContentFrame
        crumbs={
          isAusGaUser(userCountry, userRole) && enableNewHomePage
            ? [
                {
                  path: '/home',
                  name: t('navigation:home')
                },
                {
                  path: '/shortages/aus-shortages',
                  name: t('uk_shortages:aus_shortages.title')
                }
              ]
            : [
                { path: '/orders', name: t('orders:page_name') },
                {
                  path: '/shortages/uk-shortages',
                  name: t('uk_shortages:title')
                }
              ]
        }
      >
        <Row>
          <Col xs={12}>
            <ClinText
              as="h1"
              variant={TypographyVariant.H2}
              fontWeight={ClinTheme.fontWeights.bold}
            >
              {countryCode === CountryAlphaCodes.Australia
                ? t('uk_shortages:aus_shortages.title')
                : t('uk_shortages:title')}
            </ClinText>
            <Row>
              <Col md={7} xs={12}>
                {countryCode !== CountryAlphaCodes.Australia && (
                  <ClinText
                    marginBottom={ClinTheme.space[4]}
                    variant={TypographyVariant.LargeParagraph}
                  >
                    {t('uk_shortages:description_one')}
                  </ClinText>
                )}
                <ClinText
                  marginBottom={ClinTheme.space[4]}
                  variant={TypographyVariant.LargeParagraph}
                >
                  {countryCode === CountryAlphaCodes.Australia
                    ? t('uk_shortages:aus_shortages.description_two')
                    : t('uk_shortages:description_two')}
                </ClinText>
                <ClinText
                  marginBottom={ClinTheme.space[3]}
                  variant={TypographyVariant.LargeParagraph}
                >
                  {countryCode === CountryAlphaCodes.Australia
                    ? t('uk_shortages:aus_shortages.description_three')
                    : t('uk_shortages:description_three')}
                </ClinText>
                <ClinText variant={TypographyVariant.LargeParagraph}>
                  {countryCode === CountryAlphaCodes.Australia
                    ? t('uk_shortages:aus_shortages.description_four')
                    : t('uk_shortages:description_four')}
                </ClinText>
              </Col>
            </Row>
            <ClinSpacer height={ClinTheme.space[6]} />
            <ClinTable
              tableHeader={() =>
                columns.map((col, i) => {
                  return (
                    <ClinTableHeaderCell key={i} width={col.width}>
                      <ClinTableOrderToggle
                        sortDirection={
                          col.sortKey === sortBy ? sortDirection : undefined
                        }
                        hideSortIcon={!col.sortKey}
                        onClick={() =>
                          col.sortKey &&
                          onClickOrderToggle &&
                          onClickOrderToggle(col.sortKey)
                        }
                      >
                        {col.title.toUpperCase()}
                      </ClinTableOrderToggle>
                    </ClinTableHeaderCell>
                  )
                })
              }
            >
              {shortages &&
                shortages.map((shortage, index) => {
                  return (
                    <ClinTableRow
                      key={index}
                      onRowClick={() =>
                        onClickShortage && onClickShortage(shortage)
                      }
                    >
                      {columns.map((col, i) => (
                        <ClinTableBodyCell key={i}>
                          {col.renderValue(shortage)}{' '}
                        </ClinTableBodyCell>
                      ))}
                    </ClinTableRow>
                  )
                })}
            </ClinTable>
            {!shortages && (
              <>
                <ClinSpacer height={ClinTheme.space[6]} />
                <ClinGroup justifyContent={'center'}>
                  <ClinSpinner />
                </ClinGroup>
              </>
            )}
            {shortages && shortages.length === 0 && <NoShortagesMessage />}
          </Col>
          <ClinSpacer height={ClinTheme.space[6]} />
        </Row>
      </ClinPageContentFrame>
    </StyledUkShortages>
  )
}

function NoShortagesMessage() {
  return (
    <StyledNoShortagesMessage>
      <ClinSpacer height={ClinTheme.space[6]} />
      <ClinIcon
        iconName={ClinIconPathName.InfoOutline}
        iconFill={ClinTheme.colors.grey}
      />
      <ClinText
        color={ClinTheme.colors.primary}
        fontWeight={ClinTheme.fontWeights.bold}
        marginTop={ClinTheme.space[4]}
        marginBottom={ClinTheme.space[4]}
        textAlign={'center'}
        fontSize={ClinTheme.fontSizes[4]}
      >
        There are no current reported shortages
      </ClinText>
      <ClinText
        textAlign={'center'}
        className="no-shortages-message__instructions"
      >
        Is there a shortage not listed here? Let us know what you{"'"}
        re looking for and we can help.
      </ClinText>
      <ClinSpacer height={ClinTheme.space[4]} />
      <Link to="/shortages/report">
        <ClinButton variant={'primary'}>Report a shortage</ClinButton>
      </Link>
    </StyledNoShortagesMessage>
  )
}
