import {
  BasketCheckedOutDto,
  BasketDto,
  BasketItemDocumentDto,
  BasketItemDto,
  OrderDto,
  PriceAndAvailabilityDto,
  UpdateBasketDetailsDto
} from '../../types/swaggerTypes'
import { IRestEndpointState } from '../../types/IRestEndpointState'

export enum ActionType {
  UpdateBasket,
  UpdateBasketItemDocuments,
  AddBasketItem,
  SetCurrentBasket,
  EmptyBasket,
  UpsertBasketItem,
  LoadPriceAndAvailability,
  UpdateItemQuantity,
  RemoveBasketItem,
  PlaceOrder,
  OrderSubmitted,
  ClearCompletedOrder,
  SetExpectedDeliveryDate,
  UpdateBasketDetails
}

// Load initial basket ------------------------------------------------------------------------

interface ActionBasketLoaded {
  type: ActionType.UpdateBasket
  basket: IRestEndpointState<BasketDto>
}

// Add Basket item ------------------------------------------------------------------------

interface ActionBasketItemLoaded {
  type: ActionType.AddBasketItem
  basket: IRestEndpointState<BasketDto>
  basketItem?: BasketItemDto
  pricing?: PriceAndAvailabilityDto
  quantity?: number
}

// Update basket item ------------------------------------------------------------------------

interface ActionUpdateBasketItemDocuments {
  type: ActionType.UpdateBasketItemDocuments
  basketItemId: string
  basketItemDocuments: BasketItemDocumentDto[]
  remove?: boolean
}

// Empty basket ------------------------------------------------------------------------

interface ActionEmptyBasket {
  type: ActionType.EmptyBasket
}

// Load pricing availability basket ------------------------------------------------------------------------

interface ActionBasketPriceAndAvailability {
  type: ActionType.LoadPriceAndAvailability
  priceAndAvailability: IRestEndpointState<PriceAndAvailabilityDto[]>
}

// Merge basketDTo and pricing availability into current basket ------------------------------------------------------------------------

interface ActionSetBasket {
  type: ActionType.SetCurrentBasket
  basket: BasketDto
  priceAndAvailability: PriceAndAvailabilityDto[]
  countryCode: string
}

// Add estimated delivery date to basket

export interface ActionSetExpectedDeliveryDate {
  type: ActionType.SetExpectedDeliveryDate
  countryCode?: string
}

// Update basket details

export interface ActionUpdateBasketDetails {
  type: ActionType.UpdateBasketDetails
  basketDetails: UpdateBasketDetailsDto
}

// Update basket item quantity ------------------------------------------------------------------------

export interface ActionUpdateBasketItemQuantity {
  type: ActionType.UpdateItemQuantity
  basketItem: BasketItemDto
  quantity: number
}

// Remove basket item ------------------------------------------------------------------------

export interface ActionRemoveBasketItem {
  type: ActionType.RemoveBasketItem
  basketItem: BasketItemDto
}

// Place order ------------------------------------------------------------------------

export interface ActionPlaceOrder {
  type: ActionType.PlaceOrder
  orders: OrderDto[]
  basket: BasketDto
}

// Order submitted ------------------------------------------------------------------------

export interface ActionSubmitOrder {
  type: ActionType.OrderSubmitted
  completedOrder: BasketCheckedOutDto
}

// Completed orders empty ------------------------------------------------------------------------

export interface ActionClearCompletedOrders {
  type: ActionType.ClearCompletedOrder
}

export type ActionTypes =
  | ActionBasketLoaded
  | ActionBasketItemLoaded
  | ActionEmptyBasket
  | ActionBasketPriceAndAvailability
  | ActionSetBasket
  | ActionUpdateBasketItemQuantity
  | ActionRemoveBasketItem
  | ActionPlaceOrder
  | ActionSubmitOrder
  | ActionClearCompletedOrders
  | ActionUpdateBasketItemDocuments
  | ActionSetExpectedDeliveryDate
  | ActionUpdateBasketDetails
