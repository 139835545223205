import React, { FunctionComponent } from 'react'
import { isArray } from 'lodash'
import { Col, Container, Row } from 'react-grid-system'
import { ClinTheme } from '../../../ClinTheme'
import {
  ClinAccordion,
  ClinAccordionItem
} from '../../../components/ClinAccordion'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { CustomSelect } from '../../../features/CustomSelect'
import { ICustomSelectOption } from '../../../features/CustomSelect/CustomSelect'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import { ClinStepper } from '../../../components/ClinStepper'
import { StepState } from '../../../components/ClinStepper/ClinStepper'
import { ClinText } from '../../../components/ClinText'
import { ClinAnnounceBar } from '../../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { PatientDetails } from '../../../features/TransferPatient/PatientDetails/PatientDetails'
import { ProgramDetails } from '../../../features/TransferPatient/ProgramDetails/ProgramDetails'
import {
  PatientTransferSteps,
  TransferPatientTo
} from '../models/TransferPatientContainer.model'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import {
  PatientDetailsDto,
  PatientProgramDto,
  PhysiciansEnrolledSummaryDto
} from '../../../types/swaggerTypes'
import {
  StyledTransferButton,
  StyledCustomGreenBadge,
  StyledRow
} from '../styles/TransferPatient.styles'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { StyledCustomSelectTick } from '../../../features/CustomSelect/CustomSelect.styles'
import { HipaaCompliance } from '../../../features/HipaaCompliance'
import { Trans, useTranslation } from 'react-i18next'
import {
  getInputPlaceholder,
  PlaceholderType
} from '../../../utils/Forms/getInputPlaceholder'

interface ITransferPatientProps {
  /** Whether we are loading or not */
  isLoading: boolean
  /** Selected patient */
  patient?: PatientDetailsDto
  /** Selected program */
  program?: PatientProgramDto
  /** SelectedSteppers */
  selectedSteppers: StepState[]
  /** CurrentStep */
  currentStep: number
  /** Available physicians */
  physicians?: PhysiciansEnrolledSummaryDto[]
  /** Selected physician */
  selectedPhysician?: PhysiciansEnrolledSummaryDto
  /** User is confirmed agreement or not */
  isAgreementConfirmed: boolean
  /** Handler of agreement confirmation */
  setIsAgreementConfirmed: (isConfirmed: boolean) => void
  /** Called when user click on Cancel button */
  handleCancel: () => void
  /** Called when user click on Back button */
  handleBack: (step: number) => void
  /** Called when user click on Continue button */
  handleContinue: (step: number) => void
  /** Called when user click on View My Physicians */
  handleViewPhysician: () => void
  /** Called when user click on Submit button */
  handleSubmit: (step: number) => void
  /** A function that returns the selected physician */
  handlePhysicianSelect: (option: ICustomSelectOption) => void
  /** A function that is called if the user wishes to view the program */
  handleViewProgram: () => void
}

export const TransferPatient: FunctionComponent<ITransferPatientProps> = ({
  isLoading,
  patient,
  program,
  selectedSteppers,
  currentStep,
  physicians,
  selectedPhysician,
  isAgreementConfirmed,
  setIsAgreementConfirmed,
  handleCancel,
  handleBack,
  handleContinue,
  handlePhysicianSelect,
  handleViewPhysician,
  handleSubmit,
  handleViewProgram
}) => {
  const { t } = useTranslation()
  return isLoading || !patient ? (
    <Container>
      <Row align="center">
        <Col>
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        </Col>
      </Row>
    </Container>
  ) : (
    <Container className="transfer-patient">
      <ClinSpacer height={ClinTheme.space[5]} />
      <Row>
        <Col sm={12} lg={10}>
          <ClinText
            as="h1"
            className="physician-full-name"
            variant={TypographyVariant.H2}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {t('transfer_patient_to_physician:title')}
          </ClinText>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[5]} />
      {selectedSteppers && (
        <ClinStepper
          steps={[
            {
              state: selectedSteppers[0],
              displayText: t('transfer_patient_to_physician:stepper.step_1')
            },
            {
              state: selectedSteppers[1],
              displayText: t('transfer_patient_to_physician:stepper.step_2')
            }
          ]}
          width={400}
        />
      )}

      <ClinSpacer height={ClinTheme.space[8]} hasBorder />
      {currentStep === PatientTransferSteps.STEP1 && (
        <>
          <PatientDetails patient={patient} />
          <ClinSpacer height={ClinTheme.space[7]} />
          <StyledRow>
            <Col sm={12} md={12} lg={12}>
              <ClinAccordion>
                <ClinAccordionItem
                  title={t('transfer_patient_to_physician:program_details')}
                >
                  <ProgramDetails
                    program={program}
                    typeTransferring={TransferPatientTo.PHYSICIAN}
                  />
                </ClinAccordionItem>
              </ClinAccordion>
            </Col>
          </StyledRow>
          <ClinSpacer height={ClinTheme.space[5]} />
          <Row>
            <Col sm={12} md={9} lg={9}>
              <HipaaCompliance
                transferToSameInstitute={true}
                preselected={isAgreementConfirmed}
                handleConfirmation={(isConfirmed) =>
                  setIsAgreementConfirmed(isConfirmed)
                }
              />
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[5]} />
          <Row>
            <Col sm={12} md={6} lg={6}>
              <ClinGroup space={ClinTheme.space[3]}>
                <StyledTransferButton
                  variant="secondary"
                  justifyContent="space-around"
                  onClick={() => handleCancel()}
                >
                  {t('common:buttons.cancel')}
                </StyledTransferButton>
                <StyledTransferButton
                  variant="primary"
                  justifyContent="space-around"
                  disabled={!isAgreementConfirmed}
                  onClick={() => handleContinue(PatientTransferSteps.STEP2)}
                >
                  {t('common:buttons.continue')}
                </StyledTransferButton>
              </ClinGroup>
            </Col>
          </Row>
        </>
      )}
      {currentStep === PatientTransferSteps.STEP2 && (
        <>
          <Row>
            <Col sm={12} md={7}>
              <ClinText
                as="h5"
                variant={TypographyVariant.H4}
                color={ClinTheme.colors.primary}
                fontWeight={ClinTheme.fontWeights.bold}
                marginBottom={ClinTheme.space[4]}
              >
                {t(
                  'transfer_patient_to_physician:select_new_treating_physician'
                )}
              </ClinText>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[4]} />
          <Row>
            <Col sm={12} md={7}>
              <ClinGroup alignItems="center">
                <StyledCustomSelectTick>
                  <ClinIcon
                    iconName={ClinIconPathName.Tick}
                    iconSize={ClinTheme.space[3]}
                  />
                </StyledCustomSelectTick>
                <ClinText variant={TypographyVariant.LargeParagraph}>
                  {t('transfer_patient_to_physician:associated_physician')}
                </ClinText>
              </ClinGroup>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[7]} />
          <Row>
            <Col sm={12} md={6}>
              <CustomSelect
                label={t('transfer_patient_to_physician:physicians_name_label')}
                placeholder={
                  (physicians && physicians.length === 0) || !physicians
                    ? t(
                        'transfer_patient_to_physician:no_associated_physicians'
                      )
                    : getInputPlaceholder(PlaceholderType.SelectInput)
                }
                options={
                  physicians && isArray(physicians)
                    ? physicians.map(
                        (physician: PhysiciansEnrolledSummaryDto) => {
                          return {
                            id: physician.physicianId,
                            value: `${physician.physicianTitle ?? ''} ${
                              physician.physicianFirstName
                            } ${physician.physicianLastName}`,
                            isActive: physician.physicianLinkedToUser === 'Y'
                          }
                        }
                      )
                    : []
                }
                selectedOption={
                  selectedPhysician && {
                    id: selectedPhysician.physicianId,
                    value: `${selectedPhysician.physicianTitle ?? ''} ${
                      selectedPhysician.physicianFirstName
                    } ${selectedPhysician.physicianLastName}`
                  }
                }
                handleSelect={handlePhysicianSelect}
              />
              {selectedPhysician?.physicianLinkedToUser === 'N' && (
                <>
                  <ClinSpacer />
                  <ClinAnnounceBar
                    mode={AnnounceMode.Warning}
                    title={t(
                      'transfer_patient_to_physician:not_associated_announce_title'
                    )}
                  >
                    <Trans
                      i18nKey="transfer_patient_to_physician:not_associated_announce_description"
                      components={{
                        link: (
                          <span
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer'
                            }}
                            onClick={handleViewProgram}
                          />
                        )
                      }}
                    >
                      <></>
                    </Trans>
                  </ClinAnnounceBar>
                </>
              )}
            </Col>
          </Row>

          <ClinSpacer height={ClinTheme.space[9]} />
          <Row>
            <Col sm={12} md={9} lg={6}>
              <ClinGroup space={ClinTheme.space[3]}>
                <StyledTransferButton
                  variant="secondary"
                  justifyContent="space-around"
                  onClick={() => handleBack(PatientTransferSteps.STEP1)}
                >
                  {t('common:buttons.back')}
                </StyledTransferButton>
                <StyledTransferButton
                  disabled={!selectedPhysician}
                  variant="primary"
                  justifyContent="space-around"
                  onClick={() => handleSubmit(PatientTransferSteps.STEP3)}
                >
                  {t('transfer_patient_to_physician:transfer')}
                </StyledTransferButton>
              </ClinGroup>
            </Col>
          </Row>
        </>
      )}
      {currentStep === PatientTransferSteps.STEP3 && (
        <>
          <Row>
            <Col sm={12} md={7}>
              <ClinGroup>
                <StyledCustomGreenBadge>
                  <ClinIcon
                    iconName={ClinIconPathName.ArrowRight}
                    iconFill={ClinTheme.colors.white}
                    iconSize={ClinTheme.space[4]}
                  />
                </StyledCustomGreenBadge>
                <ClinText
                  as="h5"
                  variant={TypographyVariant.H4}
                  color={ClinTheme.colors.primary}
                  fontWeight={ClinTheme.fontWeights.bold}
                  marginBottom={ClinTheme.space[4]}
                >
                  {t('transfer_patient_to_physician:successfully_transfered')}
                </ClinText>
              </ClinGroup>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[7]} />
          <Row>
            <Col sm={12} md={7}>
              <ClinText variant={TypographyVariant.LargeParagraph}>
                {t('transfer_patient_to_physician:successfully_transfered_to', {
                  physician: `${selectedPhysician?.physicianTitle ?? ''} ${
                    selectedPhysician?.physicianFirstName
                  } ${selectedPhysician?.physicianLastName}`
                })}
              </ClinText>
              <ClinSpacer height={ClinTheme.space[3]} />
              <ClinText
                variant={TypographyVariant.LargeParagraph}
                marginBottom={ClinTheme.space[4]}
              >
                {selectedPhysician?.physicianLinkedToUser === 'N' &&
                  t(
                    'transfer_patient_to_physician:successfully_transfered_linked'
                  )}
              </ClinText>
            </Col>
          </Row>
          <ClinSpacer height={ClinTheme.space[7]} />
          <Row>
            <Col sm={12} md={4} lg={3}>
              <ClinGroup space={ClinTheme.space[3]}>
                <StyledTransferButton
                  variant="primary"
                  justifyContent="space-around"
                  onClick={() => handleViewPhysician()}
                >
                  {selectedPhysician?.physicianLinkedToUser === 'N'
                    ? t('transfer_patient_to_physician:view_my_physicians')
                    : t('transfer_patient_to_physician:view_patient_details')}
                </StyledTransferButton>
              </ClinGroup>
            </Col>
          </Row>
        </>
      )}
      <ClinSpacer height={ClinTheme.space[9]} />
    </Container>
  )
}
