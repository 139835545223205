import React, { FunctionComponent, useContext, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { AppContext } from '../../context/app'
import { CreateSourcingRequestDto } from '../../types/swaggerTypes'
import { SourcingEnquiry } from './SourcingEnquiry'
import { createSourcingRequest } from '../../services/ApiService'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../services/Analytics'
import { IDrugRequestFormState } from '../../features/DrugRequestForm/DrugRequestForm.model'

export type TSourcingEnquiryParams = {
  generic: string
  brand: string
}

interface ISourcingEnquiryProps
  extends RouteComponentProps<TSourcingEnquiryParams> {}

export const SourcingEnquiryContainer: FunctionComponent<
  ISourcingEnquiryProps
> = ({ location }) => {
  const history = useHistory()
  const { portfolioCountryCode, userRole } = useContext(AppContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  // Pass through any default values to form
  const urlParams = new URLSearchParams(location.search)
  const generic: string | null = urlParams.get('generic')
  const brand: string | null = urlParams.get('brand')

  const defaultDrugProperties: IDrugRequestFormState = {
    genericName: generic ? generic : '',
    brandName: brand ? brand : '',
    strength: '',
    packSize: '',
    pharmaceutical: [],
    presentation: [],
    requiredQty: '',
    targetPrice: '',
    controlledDrug: false,
    authFlag: false,
    importFlag: false,
    anythingElse: '',
    preferredLang: [],
    documentation: [],
    acceptEEA: false,
    acceptUSA: false,
    acceptThirdCountries: false
  }

  const handleFormSubmission = (data: CreateSourcingRequestDto) => {
    setIsSubmitting(true)
    createSourcingRequest(data)
      .then((incidentNumber) => {
        analyticsServiceSingleton.trackEvent(
          AnalyticsEvent.SourcingRequestSubmit,
          { incidentNumber }
        )
        // Redirect to success page
        history.push({
          pathname: '/sourcing-enquiry-submit',
          state: {
            confirmationProductDetail: {
              referenceNumber: incidentNumber.data.incidentNumber,
              brandName: data.brandName,
              genericName: data.genericName,
              strength: data.strength,
              packSize: data.packSize as unknown as string,
              requiredQuantity: data.requiredQty as unknown as string,
              requiredTargetPrice: data.targetPrice,
              dosageForm: data.pharmaceutical,
              comment: data.anythingElse
            }
          }
        })
      })
      .catch((error) => {
        setIsSubmitting(false)
        setErrorMessage(error.message)
        window.scrollTo(0, 0)
      })
  }

  return (
    <SourcingEnquiry
      defaultDrugProperties={defaultDrugProperties}
      handleFormSubmission={handleFormSubmission}
      errorMessage={errorMessage}
      isSubmitting={isSubmitting}
      userCountry={portfolioCountryCode}
      userRole={userRole}
    />
  )
}
