import { OrgAddressDto } from '../types/swaggerTypes'

export enum DeliveryAddressEventType {
  SWITCH = 'SwitchDeliveryAddress'
}

// Switch ------------------------------------------------------------------------

export interface ISwitchDeliveryAddressEvent {
  type: DeliveryAddressEventType.SWITCH
  address: OrgAddressDto
}

export const createSwitchDeliveryAddressEvent = (
  address: OrgAddressDto
): ISwitchDeliveryAddressEvent => ({
  type: DeliveryAddressEventType.SWITCH,
  address
})

export type IDeliveryAddress = ISwitchDeliveryAddressEvent
