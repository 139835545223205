import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { DateTime } from 'luxon'
import { ClinTheme } from '../../../ClinTheme'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'

import { getCarrierLogo } from './OrderDetail.model'

import {
  StyledOrderDetail,
  StyledOrderDetailOrder,
  StyledOrderDetailHeader,
  StyledOrderBar,
  StyledOrderDetailShipping,
  ClinAddAllItems
} from './OrderDetail.styles'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinCard } from '../../../components/ClinCard'
import { OrderDetailItem } from './OrderDetailItem'
import {
  CountryDto,
  HoldDto,
  OrderDto,
  OrderLineDto,
  UploadedDocumentDto
} from '../../../types/swaggerTypes'
import { ClinButton } from '../../../components/ClinButton'
import { ClinGroup } from '../../../components/ClinGroup'
import { Link } from 'react-router-dom'
import { OrderStatus } from '../../../types/OrderStatus'
import {
  ClinAnnounceBar,
  IClinAnnounceBarProps
} from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { InvoiceFlags } from '../../../constants'
import { getAdditionalLineItems, getMedications } from './OrderDetail.model'
import { IHoldDetails } from '../../../services/Holds/holds.types'
import { getVariantInformationForCatalogItem } from '../../../services/PortfolioJourneys/PortfolioJourney.model'
import { IAugmentedCatalogItemDto } from '../../../services/PortfolioJourneys/PortfolioJourney.types'
import { HoldAnnounce } from '../../../features/HoldAnnounce'
import { numberToCurrencyString } from '../../../utils/numberToCurrencyString'
import { getBrowserLocale } from '../../../utils/getBrowserLocale'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { Trans, useTranslation } from 'react-i18next'
import i18n from '../../../i18n/config'
import { ClinNewFeatureTooltipContainer } from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltipContainer'
import {
  INewFeature,
  NewFeatureElements
} from '../../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'

interface IOrderDetailProps {
  /** A placed order **/
  order?: OrderDto
  /** List of new features for this page **/
  newFeaturesList?: { [id: string]: INewFeature }
  /** Whether we are loading or not **/
  isLoading: boolean
  /** Country code **/
  countryCode: string
  /** Whether we are adding something to basket **/
  isAddingToBasket: boolean
  /** Provides order level holds to display above the order */
  orderLevelHolds?: IHoldDetails[]
  /** Displays an announce at the top of a page **/
  orderAnnounce?: IClinAnnounceBarProps
  /** Whether all ordered items can be requested again **/
  canAllItemsBeOrdered?: boolean
  /** Support contact details **/
  supportContact: CountryDto
  /** Back button title */
  backButtonTitle?: string
  /** Request to print order **/
  onPrint?: () => void
  /** Request order again **/
  onRequestAgain?: () => void
  /** Request to go back to a page **/
  onBack?: () => void
  /** Handles cancelling an order line for an order **/
  onCancelOrderLine?: (orderLine: OrderLineDto) => void
  /** Handles adding an order line to the basket **/
  onAddToBasket?: (orderLine: OrderLineDto, quantity: number) => void
  /** Handles a request to email order details **/
  onEmailOrderDetails?: () => void
  /** Handles the dismissal of the announce bar **/
  handleDismissAnnounce?: () => void
  /** Called when a file has been uploaded */
  handleFileUploadedForOrderAndHold?: (
    orderId: string,
    orderLine: OrderLineDto,
    hold: HoldDto,
    document: UploadedDocumentDto
  ) => void
  handleTrackingLinkClicked: (orderLine: OrderLineDto, language: string) => void
  getCarrierType: (carrier: string | undefined) => string
}

export const OrderDetail: FunctionComponent<IOrderDetailProps> = ({
  order,
  isLoading,
  countryCode,
  isAddingToBasket,
  orderLevelHolds,
  canAllItemsBeOrdered,
  orderAnnounce,
  supportContact,
  backButtonTitle,
  newFeaturesList,
  onRequestAgain,
  onBack,
  onCancelOrderLine,
  onAddToBasket,
  onEmailOrderDetails,
  handleDismissAnnounce,
  handleFileUploadedForOrderAndHold,
  handleTrackingLinkClicked,
  getCarrierType
}) => {
  const { t } = useTranslation()
  /**
   * Calculate what are medication line items and what are additional line items
   * */
  const medications: OrderLineDto[] = getMedications(order)
  const additionalLineItems: OrderLineDto[] = getAdditionalLineItems(order)

  const isDeliveredOrderWithAftership = (orderLine: OrderLineDto) => {
    const freightCarrier = getCarrierType(
      orderLine.trackingInfo?.freightCarrier
    )
    return (
      order?.orderStatus === OrderStatus.Delivered &&
      (freightCarrier === 'Aftership' || freightCarrier === 'Partial')
    )
  }

  return (
    <ClinPageContentFrame>
      {isLoading && (
        <Row justify="center">
          <Col width="auto">
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      )}

      {!isLoading && order && order.lines && (
        <StyledOrderDetail>
          <ClinText
            variant={TypographyVariant.H2}
            as="span"
            fontWeight={ClinTheme.fontWeights.bold}
            marginLeft={ClinTheme.space[2]}
          >
            {t('order_detail:order_number', {
              orderNumber: order.orderNumber
            })}
          </ClinText>
          <ClinSpacer height={ClinTheme.space[8]} hasBorder={true} />
          {onBack && backButtonTitle && (
            <ClinButton onClick={() => onBack()} variant="linkWithIcon">
              <ClinIcon
                style={{ marginRight: ClinTheme.space[2] }}
                iconSize={ClinTheme.fontSizes[3]}
                iconName={ClinIconPathName.ChevronLeft}
              />
              {backButtonTitle}
            </ClinButton>
          )}

          {orderAnnounce && (
            <>
              <ClinSpacer height={ClinTheme.space[4]} />
              <ClinAnnounceBar
                handleClose={handleDismissAnnounce}
                mode={orderAnnounce.mode}
                title={orderAnnounce.title}
              >
                {orderAnnounce.message}
              </ClinAnnounceBar>
            </>
          )}

          {orderLevelHolds ? (
            <>
              <ClinSpacer height={ClinTheme.space[6]} />
              {orderLevelHolds.map((orderLevelHold, index) => (
                <HoldAnnounce
                  key={`order-error-${index}`}
                  mode={orderLevelHold.announceMode}
                  title={orderLevelHold.title}
                  marginBottom={ClinTheme.space[3]}
                >
                  {orderLevelHold.message}
                </HoldAnnounce>
              ))}
              <ClinSpacer height={ClinTheme.space[7]} />
            </>
          ) : (
            <ClinSpacer height={ClinTheme.space[4]} />
          )}

          <StyledOrderBar>
            <ClinText
              color={ClinTheme.colors.white}
              textTransform="uppercase"
              fontSize={ClinTheme.fontSizes[1]}
            >
              {t('order_detail:title')}
            </ClinText>
          </StyledOrderBar>
          <ClinCard>
            <Row>
              <Col sm={12} md={3}>
                <ClinText
                  color={ClinTheme.colors.primary}
                  marginBottom={ClinTheme.space[2]}
                  fontWeight={ClinTheme.fontWeights.medium}
                >
                  {t('order_detail:order_reference')}
                </ClinText>
                <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
                  {order.orderNumber}
                </ClinText>
                <ClinSpacer height={ClinTheme.space[2]} />
              </Col>
              <Col sm={12} md={3}>
                <ClinText
                  color={ClinTheme.colors.primary}
                  marginBottom={ClinTheme.space[2]}
                  fontWeight={ClinTheme.fontWeights.medium}
                >
                  {t('order_detail:date_submitted')}
                </ClinText>
                <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
                  {DateTime.fromISO(order.orderedDate, {
                    zone: 'utc'
                  }).toLocaleString()}
                </ClinText>
                <ClinSpacer height={ClinTheme.space[2]} />
              </Col>

              <Col sm={12} md={3}>
                <ClinText
                  color={ClinTheme.colors.primary}
                  marginBottom={ClinTheme.space[2]}
                  fontWeight={ClinTheme.fontWeights.medium}
                >
                  {t('order_detail:submitted_by')}
                </ClinText>
                <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
                  {order.shipToContact}
                </ClinText>
                <ClinSpacer height={ClinTheme.space[2]} />
              </Col>

              <Col sm={12} md={3}>
                <ClinText
                  color={ClinTheme.colors.primary}
                  marginBottom={ClinTheme.space[2]}
                  fontWeight={ClinTheme.fontWeights.medium}
                >
                  {t('order_detail:order_status')}
                </ClinText>
                <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
                  {order.orderStatus}
                </ClinText>
                <ClinSpacer height={ClinTheme.space[2]} />
              </Col>

              {order.invoiceFlag === InvoiceFlags.Y.toString() && (
                <Col style={{ textAlign: 'right' }}>
                  <ClinButton
                    onClick={() => onEmailOrderDetails && onEmailOrderDetails()}
                    justifyContent="center"
                    variant="linkButton"
                  >
                    {t('order_detail:email_invoice_details')}
                    <ClinIcon
                      style={{ marginLeft: ClinTheme.space[2] }}
                      iconSize={ClinTheme.fontSizes[3]}
                      iconName={ClinIconPathName.Mail}
                    />
                  </ClinButton>
                </Col>
              )}
            </Row>
          </ClinCard>
          <ClinSpacer height={ClinTheme.space[2]} />
          <Row>
            <Col sm={12} md={6}>
              <ClinCard>
                <ClinText as="h3" variant={TypographyVariant.H3}>
                  {t('order_detail:delivery_and_order_number')}
                </ClinText>

                <ClinText
                  color={ClinTheme.colors.primary}
                  fontWeight={ClinTheme.fontWeights.medium}
                  as="p"
                  variant={TypographyVariant.Paragraph}
                >
                  {t('order_detail:delivery_method')}
                </ClinText>
                <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
                  {order.shipMethod}
                </ClinText>
                <ClinSpacer />
                <ClinText
                  color={ClinTheme.colors.primary}
                  fontWeight={ClinTheme.fontWeights.medium}
                  as="p"
                  variant={TypographyVariant.Paragraph}
                >
                  {t('order_detail:delivery_address')}
                </ClinText>
                <ClinText variant={TypographyVariant.LargeParagraph}>
                  {order.deliverToContact && (
                    <>
                      {order.deliverToContact}
                      <br />
                    </>
                  )}
                  {order.deliverTo.address1 && (
                    <>
                      {order.deliverTo.address1}
                      <br />
                    </>
                  )}
                  {order.deliverTo.address2 && (
                    <>
                      {order.deliverTo.address2}
                      <br />
                    </>
                  )}
                  {order.deliverTo.address3 && (
                    <>
                      {order.deliverTo.address3}
                      <br />
                    </>
                  )}
                  {order.deliverTo.address4 && (
                    <>
                      {order.deliverTo.address4}
                      <br />
                    </>
                  )}
                  {order.deliverTo.city && (
                    <>
                      {order.deliverTo.city}
                      <br />
                    </>
                  )}
                  {order.deliverTo.country && (
                    <>
                      {order.deliverTo.country}
                      <br />
                    </>
                  )}
                  {order.deliverTo.postalCode && (
                    <>
                      {order.deliverTo.postalCode}
                      <br />
                    </>
                  )}
                  {order.deliverTo.state && <>{order.deliverTo.state}</>}
                </ClinText>
                <ClinSpacer />
                <ClinText
                  color={ClinTheme.colors.primary}
                  fontWeight={ClinTheme.fontWeights.medium}
                  as="p"
                  variant={TypographyVariant.Paragraph}
                >
                  {t('order_detail:purchase_order_number')}
                </ClinText>
                <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
                  {order?.customerPoNumber
                    ? order?.customerPoNumber
                    : order?.orderNumber
                    ? order.orderNumber
                    : 'N/A'}
                </ClinText>
              </ClinCard>
            </Col>
            <Col sm={12} md={6}>
              <ClinCard>
                <ClinText as="h3" variant={TypographyVariant.H3}>
                  {t('order_detail:order_summary')}
                </ClinText>
                {additionalLineItems.map((additionalItem, idx) => (
                  <div key={`order-line-item-other-${idx}`}>
                    <Row justify="between">
                      <Col width="auto">
                        <ClinText
                          color={ClinTheme.colors.primary}
                          fontWeight={ClinTheme.fontWeights.medium}
                          as="p"
                          variant={TypographyVariant.Paragraph}
                        >
                          {/* TODO: Requirements unclear as to whether to show category and or quantity */}
                          {/*{`${additionalItem.quantities.ordered} x ${additionalItem.skuCatalogItem?.item.itemCategoryCode}`}*/}
                          {/*<br />*/}
                          {additionalItem.itemDescription}:
                        </ClinText>
                      </Col>
                      <Col width="auto" className={`sub-total-${idx}`}>
                        {numberToCurrencyString(
                          additionalItem.totals.subTotal,
                          getBrowserLocale(),
                          {
                            style: 'currency',
                            currency: order.currencyCode
                              ? order.currencyCode
                              : 'GBP',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        )}
                      </Col>
                    </Row>
                    <ClinSpacer height={ClinTheme.space[5]} hasBorder={true} />
                  </div>
                ))}
                <Row justify="between">
                  <Col width="auto">
                    <ClinText
                      color={ClinTheme.colors.primary}
                      fontWeight={ClinTheme.fontWeights.medium}
                      as="p"
                      variant={TypographyVariant.Paragraph}
                    >
                      {order.currencyCode === 'GBP'
                        ? t('order_detail:vat')
                        : t('order_detail:tax')}
                    </ClinText>
                  </Col>
                  <Col width="auto" className="tax">
                    {numberToCurrencyString(
                      order.totals.tax,
                      getBrowserLocale(),
                      {
                        style: 'currency',
                        currency: order.currencyCode,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }
                    )}
                  </Col>
                </Row>
                <ClinSpacer hasBorder={true} />
                <Row justify="between">
                  <Col width="auto">
                    <ClinText
                      color={ClinTheme.colors.primary}
                      fontWeight={ClinTheme.fontWeights.medium}
                      as="p"
                      variant={TypographyVariant.Paragraph}
                    >
                      {t('order_detail:total')}
                    </ClinText>
                  </Col>
                  <Col width="auto" className="total">
                    {numberToCurrencyString(
                      order.totals.total,
                      getBrowserLocale(),
                      {
                        style: 'currency',
                        currency: order.currencyCode
                          ? order.currencyCode
                          : 'GBP',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }
                    )}
                  </Col>
                </Row>
              </ClinCard>
              <ClinSpacer height={ClinTheme.space[3]} />

              {order.orderStatus === OrderStatus.Shipped && (
                <>
                  <ClinGroup space={ClinTheme.space[2]}>
                    <ClinText fontWeight={ClinTheme.fontWeights.bold}>
                      {t('order_detail:questions_about_returns')}
                    </ClinText>
                    <ClinText>
                      <Link to="/support">
                        {t('order_detail:view_our_support_section')}
                      </Link>
                    </ClinText>
                  </ClinGroup>
                  <ClinSpacer height={ClinTheme.space[3]} />
                  <ClinText>
                    <Trans
                      i18nKey="order_detail:email_or_call_us"
                      components={{
                        phone: (
                          <ClinButton
                            style={{
                              textTransform: 'lowercase',
                              fontWeight: ClinTheme.fontWeights.normal
                            }}
                            variant="linkButton"
                            as="a"
                            href={`tel:${supportContact.rawPhoneNumber}`}
                            onClick={() => {
                              analyticsServiceSingleton.trackEvent(
                                AnalyticsEvent.ContactClicked,
                                { contactType: 'telephone' }
                              )
                            }}
                          />
                        ),
                        email: (
                          <ClinButton
                            style={{
                              textTransform: 'lowercase',
                              fontWeight: ClinTheme.fontWeights.normal
                            }}
                            as="a"
                            variant="linkButton"
                            target="_blank"
                            href={`mailto:${supportContact.csEmailAddress}`}
                            onClick={() => {
                              analyticsServiceSingleton.trackEvent(
                                AnalyticsEvent.ContactClicked,
                                { contactType: 'email' }
                              )
                            }}
                          />
                        )
                      }}
                      values={{
                        phoneNumber: supportContact.csPhoneNumber,
                        emailAddress: supportContact.csEmailAddress
                      }}
                    >
                      <></>
                    </Trans>
                  </ClinText>
                </>
              )}
            </Col>
          </Row>

          <StyledOrderDetailOrder>
            <ClinSpacer height={ClinTheme.space[4]} />

            <StyledOrderBar>
              {t('order_detail:medical_items', {
                counter: medications.length
              })}
            </StyledOrderBar>

            <Row justify="end">
              <Col xs={12} md={6}>
                <ClinSpacer height={ClinTheme.space[5]} />
                <ClinAddAllItems>
                  <ClinButton
                    disabled={isAddingToBasket || !canAllItemsBeOrdered}
                    onClick={() => onRequestAgain && onRequestAgain()}
                  >
                    {t('order_detail:add_items_to_basket')}
                  </ClinButton>
                </ClinAddAllItems>
              </Col>
            </Row>

            <ClinSpacer height={ClinTheme.space[5]} />

            {order.lines
              .filter((ol) => ol.medicationFlag === 'Y')
              .map((orderLine) => (
                <ClinCard
                  key={`order-${orderLine.lineId}`}
                  removePadding={true}
                >
                  {/* Shipped header with extra details */}
                  {getCarrierType(orderLine.trackingInfo?.freightCarrier) !==
                    'Not supported' && (
                    <StyledOrderDetailShipping>
                      <Row>
                        <Col>
                          <ClinText
                            color={ClinTheme.colors.primary}
                            fontWeight={ClinTheme.fontWeights.medium}
                          >
                            {t('order_detail:status')}
                          </ClinText>
                          <ClinText
                            color={ClinTheme.colors.black}
                            variant={TypographyVariant.LargeParagraph}
                          >
                            {order.orderStatus}
                          </ClinText>
                        </Col>
                        <Col>
                          <ClinText
                            color={ClinTheme.colors.primary}
                            fontWeight={ClinTheme.fontWeights.medium}
                          >
                            {t('order_detail:carrier')}
                          </ClinText>
                          <ClinText
                            color={ClinTheme.colors.black}
                            variant={TypographyVariant.LargeParagraph}
                            className={'clin-text-carrier'}
                          >
                            {orderLine.trackingInfo?.freightCarrier
                              ? getCarrierLogo(
                                  orderLine.trackingInfo?.freightCarrier
                                )
                              : t('glossary:not_available')}
                          </ClinText>
                        </Col>
                        <Col>
                          <ClinText
                            className="relative"
                            color={ClinTheme.colors.primary}
                            fontWeight={ClinTheme.fontWeights.medium}
                          >
                            {t('order_detail:tracking_link')}
                          </ClinText>
                          <span
                            id="tracking-link"
                            className="tooltip-span"
                            style={{
                              display: 'flex',
                              justifyContent: 'flexStart',
                              alignItems: 'center'
                            }}
                          >
                            <ClinText
                              color={ClinTheme.colors.black}
                              variant={TypographyVariant.LargeParagraph}
                              className={'clin-text-waybill-na relative'}
                            >
                              {/* If no way bill or not Aftership then show not available */}
                              {orderLine.trackingInfo?.waybillNumber &&
                              getCarrierType(
                                orderLine.trackingInfo?.freightCarrier
                              ) === 'Aftership' ? (
                                !!orderLine.trackingInfo
                                  ?.aftershipTrackingLink ? (
                                  <ClinButton
                                    variant="linkButton"
                                    as="a"
                                    target="_blank"
                                    href={
                                      orderLine.trackingInfo
                                        ?.aftershipTrackingLink
                                    }
                                    className={'clin-text-waybill'}
                                    style={{height: 'unset'}}
                                    onClick={() =>
                                      handleTrackingLinkClicked(
                                        orderLine,
                                        i18n.language
                                      )
                                    }
                                  >
                                    {orderLine.trackingInfo?.waybillNumber}
                                  </ClinButton>
                                ) : (
                                  t('order_detail:waiting_for_carrier')
                                )
                              ) : (
                                t('glossary:not_available')
                              )}
                            </ClinText>
                            {/* Display new feature for tracking link ********************************************* */}
                            {orderLine.trackingInfo?.waybillNumber &&
                              getCarrierType(
                                orderLine.trackingInfo?.freightCarrier
                              ) === 'Aftership' &&
                              !!orderLine.trackingInfo?.aftershipTrackingLink &&
                              newFeaturesList &&
                              newFeaturesList[
                                NewFeatureElements.TrackingLinkFeature
                              ] && (
                                <ClinNewFeatureTooltipContainer
                                  feature={
                                    newFeaturesList[
                                      NewFeatureElements.TrackingLinkFeature
                                    ]
                                  }
                                ></ClinNewFeatureTooltipContainer>
                              )}
                          </span>
                        </Col>
                        {/* For orders with aftership or partial and order status Delivered do not show estimated deliery date section. */}
                        {isDeliveredOrderWithAftership(orderLine) ? (
                          <Col></Col>
                        ) : (
                          <Col>
                            <ClinText
                              color={ClinTheme.colors.primary}
                              fontWeight={ClinTheme.fontWeights.medium}
                            >
                              {t('product_detail:estimated_delivery_title')}
                            </ClinText>
                            <ClinText
                              color={ClinTheme.colors.black}
                              variant={TypographyVariant.LargeParagraph}
                              className={'clin-text-carrier'}
                            >
                              {orderLine.trackingInfo?.estimatedDeliveryDate
                                ? DateTime.fromISO(
                                    orderLine.trackingInfo
                                      ?.estimatedDeliveryDate || '',
                                    { zone: 'utc' }
                                  )
                                    .setLocale(i18n.language)
                                    .toFormat('cccc, MMMM d')
                                : orderLine.expectedDate
                                ? DateTime.fromISO(orderLine.expectedDate || '')
                                    .setLocale(i18n.language)
                                    .toFormat('cccc, MMMM d')
                                : t('glossary:not_available')}
                            </ClinText>
                          </Col>
                        )}
                      </Row>
                    </StyledOrderDetailShipping>
                  )}
                  <StyledOrderDetailHeader>
                    <Row>
                      <Col sm={12} md={6}>
                        <ClinText as="h5" variant={TypographyVariant.H5}>
                          {t('glossary:product')}
                        </ClinText>
                      </Col>
                      <Col sm={12} md={2}>
                        <ClinText
                          textAlign="right"
                          as="h5"
                          variant={TypographyVariant.H5}
                        >
                          {t('glossary:product_code')}
                        </ClinText>
                      </Col>
                      <Col sm={12} md={2}>
                        <ClinText
                          textAlign="right"
                          as="h5"
                          variant={TypographyVariant.H5}
                        >
                          {t('glossary:quantity')}
                        </ClinText>
                      </Col>
                      <Col sm={12} md={2}>
                        <ClinText
                          textAlign="right"
                          as="h5"
                          variant={TypographyVariant.H5}
                        >
                          {t('glossary:subtotal')}
                        </ClinText>
                      </Col>
                    </Row>
                  </StyledOrderDetailHeader>

                  {orderLine.medicationFlag === 'Y' &&
                    (() => {
                      const variantInformation: IAugmentedCatalogItemDto | null =
                        orderLine.skuCatalogItem &&
                        getVariantInformationForCatalogItem(
                          orderLine.skuCatalogItem,
                          countryCode
                        )
                      const cannotReOrder = variantInformation?.cannotOrder
                      return (
                        <OrderDetailItem
                          orderId={order?.orderNumber}
                          orderLine={orderLine}
                          cannotOrder={!!cannotReOrder}
                          isAddingToBasket={isAddingToBasket}
                          onCancelOrderLine={(orderLine) =>
                            onCancelOrderLine && onCancelOrderLine(orderLine)
                          }
                          onAddToBasket={(orderLine, quantity) =>
                            onAddToBasket && onAddToBasket(orderLine, quantity)
                          }
                          currencyCode={order.currencyCode}
                          handleFileUploadedForOrderAndHold={
                            handleFileUploadedForOrderAndHold
                          }
                        />
                      )
                    })()}

                  <ClinSpacer />
                </ClinCard>
              ))}
          </StyledOrderDetailOrder>
        </StyledOrderDetail>
      )}
    </ClinPageContentFrame>
  )
}
