import React, { ChangeEvent, FunctionComponent, useState } from 'react'
import { Container, Col, Row } from 'react-grid-system'
import { Controller, useForm } from 'react-hook-form'
import { ClinTheme } from '../../ClinTheme'
import { object, string } from 'yup'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { yupResolver } from '@hookform/resolvers/yup'
import { ClinButton } from '../../components/ClinButton'
import {
  getInputValidation,
  ValidationType
} from '../../utils/Forms/getInputValidation'
import { useTranslation } from 'react-i18next'
import { PatientDetailsDto } from '../../types/swaggerTypes'
import { ClinSelect } from '../../components/ClinSelect'
import {
  getInputPlaceholder,
  PlaceholderType
} from '../../utils/Forms/getInputPlaceholder'
import { StyledSubmit } from '../../pages/Patients/PatientDetail/DiscontinueConfirmationModal/DiscontinueConfirmationModal.styles'
import { PatientDetailDiscontinued } from '../Patients/PatientDetail/PatientDetailDiscontinued/PatientDetailDiscontinued'

interface IPatientDiscontinuationProps {
  /** Selected patient */
  patient?: PatientDetailsDto
  /** If the reason for discontinuation has been submitted */
  isReasonSubmitted: boolean
  /** Called when user click on Cancel button */
  handleCancel: () => void
  /** Called to reach the adverse event page */
  handleAdverseEvent: () => void
  /** Called when user click on Submit button */
  handleFormSubmittion: (message: IDiscontinuationReason) => void
  /** Array of lov for patient discontinuation */
  lovList: any[]
  /** Wether we have loaded lov list or not*/
  isLovLoading: boolean
}

interface IDiscontinuationLov {
  generatedId: string
  lovType: string
  lovCode: string
  lovValue: string
  lovDataType: string
  activeFlag: string
  visibleOnlineFlag: string
  dataSourceSystemName: string
  dataSourceTableName: string
}

export interface IDiscontinuationReason {
  reasonCode: string
}

const defaultState: IDiscontinuationReason = {
  reasonCode: 'Please select one'
}

export const PatientDiscontinuation: FunctionComponent<
  IPatientDiscontinuationProps
> = ({
  patient,
  handleCancel,
  handleFormSubmittion,
  handleAdverseEvent,
  lovList,
  isReasonSubmitted
}) => {
  const sampleSchema = object().shape({
    reasonCode: string().required(
      getInputValidation(ValidationType.RequiredField)
    )
  })

  const [disableDiscontinuationButton, setDisableContinuationButton] =
    useState<boolean>(true)

  const { t } = useTranslation()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IDiscontinuationReason>({
    defaultValues: defaultState,
    resolver: yupResolver(sampleSchema)
  })
  return !isReasonSubmitted ? (
    <Container
      style={{
        marginTop: ClinTheme.space[5],
        marginBottom: ClinTheme.space[4]
      }}
    >
      <Row>
        <Col sm={12} md={9} lg={9}>
          <form onSubmit={handleSubmit(handleFormSubmittion)}>
            <ClinText
              as="h2"
              color="#270072"
              fontWeight={700}
              variant={TypographyVariant.H2}
            >
              {`Discontinue Patient ${patient?.patientNumber}`}
            </ClinText>
            <ClinSpacer height={ClinTheme.space[4]} />

            <Controller
              name={'reasonCode'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <ClinSelect
                  id="patientDiscontinuationReason"
                  label={
                    'Please select the reason for discontinuing this patient'
                  }
                  width="100%"
                  value={value}
                  onChange={(changeValue: ChangeEvent<HTMLSelectElement>) => {
                    onChange(changeValue.currentTarget.value)
                    setDisableContinuationButton(false)
                  }}
                  hasError={!!(errors && errors.reasonCode)}
                  prompt={errors.reasonCode?.message}
                >
                  <option value="" disabled={true}>
                    {getInputPlaceholder(PlaceholderType.SelectInput)}
                  </option>
                  {lovList.map(
                    (
                      discontinuationLov: IDiscontinuationLov,
                      index: number
                    ) => {
                      return (
                        <option
                          key={`discontinuationLov-${index}`}
                          value={discontinuationLov.lovCode}
                        >
                          {discontinuationLov.lovValue}
                        </option>
                      )
                    }
                  )}
                </ClinSelect>
              )}
            />
            <ClinSpacer height={ClinTheme.space[8]} />
            <Row>
              <Col sm={12} md={7}>
                <Row justify="between">
                  <Col md={5} lg={6}>
                    <StyledSubmit>
                      <ClinButton
                        className="cancel"
                        onClick={() => {
                          handleCancel()
                        }}
                      >
                        {t('common:buttons.cancel')}
                      </ClinButton>
                      <ClinButton
                        type="submit"
                        className="submit"
                        variant="primary"
                        disabled={disableDiscontinuationButton}
                      >
                        {t(
                          'patient_detail:discontinue_confirmation_modal.discontinue_btn'
                        )}
                      </ClinButton>
                    </StyledSubmit>
                  </Col>
                </Row>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </Container>
  ) : (
    <PatientDetailDiscontinued
      patientNumber={patient?.patientNumber || ''}
      handleBackToPatientDetails={handleCancel}
      handleAdverseEventDownload={handleAdverseEvent}
    ></PatientDetailDiscontinued>
  )
}
