import React, { FunctionComponent } from 'react'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../ClinText'
import { StyledEstimatedTimeRowAus } from '../../pages/Products/ProductDetail/ProductDetail.styles'
import { Trans } from 'react-i18next'
import DeliveryIcon from '../../assets/media/delivery-logos/delivery-aus.svg'
import { StyledIcon } from './EstimatedDeliveryDate.styles'
import { t } from 'i18next'

export interface IClinEddAusProps {
  stockLevel: number
  padding?: string
  isLimitedAvailable?: boolean
  dateWidth?: string
}

export const EstimatedDeliveryDateAus: FunctionComponent<IClinEddAusProps> = ({
  stockLevel,
  padding,
  isLimitedAvailable,
  dateWidth = '70%'
}) => {
  const estDeliveryTitle =
    stockLevel > 0
      ? 'product_detail:estimated_delivery_title_with_stock_aus'
      : 'product_detail:estimated_delivery_title_without_stock_aus'

  const estDeliveryDescription =
    stockLevel > 0
      ? 'product_detail:estimated_delivery_time_with_stock_aus'
      : 'product_detail:estimated_delivery_time_without_stock_aus'

  return (
    <StyledEstimatedTimeRowAus
      padding={padding}
      style={{ alignItems: 'start' }}
    >
      <ClinText color={ClinTheme.colors.black}>
        <StyledIcon width={27} height={20} src={DeliveryIcon} />
      </ClinText>

      <div
        style={{
          marginLeft: ClinTheme.space[3],
          width: '100%'
        }}
      >
        <ClinText
          as="div"
          marginBottom={ClinTheme.space[2]}
          color={ClinTheme.colors.primary}
          fontWeight={ClinTheme.fontWeights.medium}
          lineHeight={ClinTheme.lineHeights.heading[2]}
        >
          <Trans i18nKey={estDeliveryTitle} components={{ bold: <strong /> }}>
            <></>
          </Trans>
        </ClinText>
        {isLimitedAvailable ? (
          <ClinText
            as="div"
            width={dateWidth}
            marginBottom={ClinTheme.space[2]}
            color={ClinTheme.colors.primary}
            fontWeight={ClinTheme.fontWeights.normal}
            lineHeight={ClinTheme.lineHeights.heading[2]}
          >
            {t('product_variants:estimated_delivery_l_imited_availability')}
          </ClinText>
        ) : (
          <ClinText
            as="div"
            marginBottom={ClinTheme.space[2]}
            color={ClinTheme.colors.primary}
            fontWeight={ClinTheme.fontWeights.medium}
            lineHeight={ClinTheme.lineHeights.heading[2]}
          >
            <Trans
              i18nKey={estDeliveryDescription}
              components={{ bold: <strong /> }}
            >
              <></>
            </Trans>
          </ClinText>
        )}
      </div>
    </StyledEstimatedTimeRowAus>
  )
}
