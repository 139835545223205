import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQueryDown } from '../../utils/mediaQuery'
import { ClinButton } from '../ClinButton'

export interface IOpaHeaderStepperStyles {
  /** Example prop please replace or remove! */
  exampleOpaHeaderStepperStyle?: string
}

export const StyledOpaHeaderStepper = styled.div<IOpaHeaderStepperStyles>(
  {
    background: ClinTheme.colors.white,
    border: '1px solid',
    borderColor: ClinTheme.colors.midGrey,
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.15)'
  },
  ({ exampleOpaHeaderStepperStyle }: IOpaHeaderStepperStyles) => ({
    exampleOpaHeaderStepperStyle
  })
)

export const StyledStepperItem = styled.div({
  position: 'relative',
  width: '39.70%',
  flexShrink: '1'
})

export const StyledStepperItemsWrapper = styled.div({
  display: 'flex',
  alignItems: 'top',
  boxSizing: 'border-box',
  maxWidth: '1140px',
  padding: '0px 16px',
  margin: '0px auto 0px',
  flexWrap: 'nowrap',
  width: '100%',
  marginTop: '5px',
  marginBottom: '10px',
  [mediaQueryDown(ClinTheme.breakpoints[1])]: {
    flexDirection: 'column',
    marginBottom: '15px'
  }
})

export const StyledStepperItems = styled.div({
  alignItems: 'top',
  boxSizing: 'border-box',
  maxWidth: '1140px',
  padding: '0px 16px',
  margin: '34px auto 0px',
  display: 'flex',
  flexWrap: 'nowrap',
  width: '100%'
})

export const StyledStepperLine = styled.div({
  width: 'calc(100% - 20px)',
  height: '4px',
  position: 'absolute',
  right: '0px',
  top: '8px',

  backgroundColor: ClinTheme.colors.primaryMid
})

export const StyledStepperButtonWrapper = styled.div({
  marginTop: '34px',
  display: 'flex',
  [mediaQueryDown(ClinTheme.breakpoints[0])]: {
    flexDirection: 'column',
    marginTop: '15px',
    alignItems: 'center'
  }
})

export const StyledSaveButton = styled(ClinButton)({
  marginRight: '14px',
  [mediaQueryDown(ClinTheme.breakpoints[0])]: {
    marginRight: '0px',
    marginBottom: '10px'
  }
})
