import React, { FunctionComponent, useContext, useState } from 'react'
import { AppContext } from '../../../context/app'
import { createShortageReport } from '../../../services/ApiService'
import { CreateShortageRequestDto } from '../../../types/swaggerTypes'
import { ReportShortage } from './ReportShortage'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { useHistory } from 'react-router'

export const ReportShortageContainer: FunctionComponent = () => {
  const { portfolioCountryCode, userRole } = useContext(AppContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [submitCounter, setSubmitCounter] = useState<number>(0)
  const history = useHistory()

  const handleFormSubmission = (data: CreateShortageRequestDto) => {
    setIsSubmitting(true)
    createShortageReport(data)
      .then((response) => {
        analyticsServiceSingleton.trackEvent(AnalyticsEvent.ReportShortage, {
          genericName: data.genericName,
          incidentNumber: response.data.incidentNumber
        })
        setIsSubmitting(false)
        // Normally using react hook forms reset() function would successfully clear the existing form after submission
        // however the multiselect inputs persist state seperate from the form so the only way to effectively reset these and
        // the form is to update the key to force a rerender of the component.
        setSubmitCounter(submitCounter + 1)

        // Redirect to success page
        history.push({
          pathname: '/report-shortages-submit',
          state: {
            confirmationProductDetail: {
              referenceNumber: response.data.incidentNumber,
              brandName: data.brandName,
              genericName: data.genericName,
              strength: data.strength,
              packSize: data.packSize,
              requiredQuantity: data.requiredQty as unknown as string,
              requiredTargetPrice: data.targetPrice,
              dosageForm: data.pharmaceutical,
              comment: data.anythingElse
            }
          }
        })
      })
      .catch((error) => {
        setIsSubmitting(false)
        setErrorMessage(error.message)
        window.scrollTo(0, 0)
      })
  }

  return (
    <ReportShortage
      key={submitCounter}
      handleFormSubmission={handleFormSubmission}
      errorMessage={errorMessage}
      isSubmitting={isSubmitting}
      userCountry={portfolioCountryCode}
      userRole={userRole}
    />
  )
}
