import styled from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'
import { mediaQuery, mediaQueryDown } from '../../../utils/mediaQuery'
import { ISearchStylesProps } from '../AutoSuggest/AutoSuggest'

const mobileUpBreakpoint = ClinTheme.breakpoints[2]

export const StyledGlobalSearch = styled.section({
  [`@media print`]: {
    display: 'none'
  }
})

export const StyledGlobalSearchHome = styled.section({
  padding: `${ClinTheme.space[2]}px ${ClinTheme.space[3]}px`,
  backgroundColor: ClinTheme.colors.primaryLight,
  [mediaQuery(mobileUpBreakpoint)]: {
    padding: `${ClinTheme.space[4]}px ${ClinTheme.space[3]}px`
  },
  [`@media print`]: {
    display: 'none'
  }
})

export const StyledSearchWrapper = styled.div({
  position: 'relative',

  justifyContent: 'center',
  marginTop: '-4px',
  height: '40px',
  padding: '0px 16px',

  marginRight: '18px',

  [`.clin-icon`]: {
    width: '18px',
    height: '18px',
    paddingLeft: '16px'
  },
  [mediaQuery(mobileUpBreakpoint)]: {
    marginLeft: '18px',
    marginRight: '18px',
    display: 'flex',
    minWidth: '312px',
    width: '312px',
    maxWidth: '768px',
    marginTop: '-7px',
    padding: '0',
    transition: 'width .3s ease-in-out',
    [`&:focus-within`]: {
      width: 'calc(100% - 28px);',
      outline: 'none'
    },
    [`.clin-icon`]: {
      paddingLeft: '0px'
    }
  },
  [`&.clin-global-search-menu-input-wrapper`]: {
    height: '48px',
    marginTop: '0',
    marginRight: '32px',
    [`.clin-icon`]: {
      paddingBottom: '8px'
    }
  }
})

export const StyledSearchWrapperHome = styled.div({
  backgroundColor: ClinTheme.colors.primaryLight,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  minWidth: '312px',
  width: '312px',
  maxWidth: '768px',
  height: '40px',
  transition: 'width .3s ease-in-out',
  marginRight: '18px',
  marginTop: '-7px',
  [`.clin-icon`]: {
    transition: '.2s linear color',
    color: ClinTheme.colors.white
  },
  [`> input:focus`]: {
    [`~ .clin-icon`]: {
      color: ClinTheme.colors.primary
    }
  },
  [mediaQuery(mobileUpBreakpoint)]: {
    maxWidth: 650,
    margin: 'auto'
  }
})

export const StyledSearchInput = styled.input<ISearchStylesProps>(
  {
    fontFamily: ClinTheme.fonts.body,
    textOverflow: 'ellipsis',
    position: 'relative',
    flex: '1 1 auto',
    marginBottom: '0',
    width: '100%',
    textIndent: ClinTheme.space[4],
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[3]}px`,
    fontSize: ClinTheme.fontSizes[1],
    fontWeight: ClinTheme.fontWeights.normal,
    lineHeight: '24px',
    color: ClinTheme.colors.deepGrey,
    borderStyle: 'none',
    outline: 'none',
    transition: '.2s linear color, .2s linear width',
    '::placeholder': {
      color: 'inherit'
    },
    ':-ms-input-placeholder': {
      // Special case for Text Inputs to have correct height in IE 11
      height: '40px'
    },
    [`:focus`]: {
      width: '100%'
    }
  },
  ({ showSuggestions }) => ({
    backgroundColor: ClinTheme.colors.white,
    borderBottomLeftRadius: showSuggestions ? 0 : ClinTheme.space[4],
    borderBottomRightRadius: showSuggestions ? 0 : ClinTheme.space[4],
    borderTopLeftRadius: showSuggestions
      ? ClinTheme.space[3]
      : ClinTheme.space[4],
    borderTopRightRadius: showSuggestions
      ? ClinTheme.space[3]
      : ClinTheme.space[4]
  })
)

export const StyledSearchInputAus = styled.input<ISearchStylesProps>(
  {
    fontFamily: ClinTheme.fonts.body,
    position: 'relative',
    flex: '1 1 auto',
    marginBottom: '0',
    textIndent: ClinTheme.space[4],
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[2]}px`,
    fontSize: ClinTheme.fontSizes[2],
    fontWeight: ClinTheme.fontWeights.light,
    color: ClinTheme.colors.white,
    borderRadius: ClinTheme.space[5],
    transition: '.2s linear background-color, .2s linear color',
    '::placeholder': {
      color: 'inherit'
    },
    ':-ms-input-placeholder': {
      // Special case for Text Inputs to have correct height in IE 11
      height: '40px'
    },
    [`:focus`]: {
      color: ClinTheme.colors.primary,
      backgroundColor: ClinTheme.colors.white,
      outline: '0'
    },
    [mediaQuery(mobileUpBreakpoint)]: {
      fontSize: ClinTheme.fontSizes[3]
    }
  },
  ({ showSuggestions }) => ({
    backgroundColor: showSuggestions
      ? ClinTheme.colors.white
      : ClinTheme.colors.primaryMid,
    borderBottomLeftRadius: showSuggestions ? 0 : ClinTheme.space[5],
    borderBottomRightRadius: showSuggestions ? 0 : ClinTheme.space[5],
    borderTopLeftRadius: showSuggestions
      ? ClinTheme.space[3]
      : ClinTheme.space[4],
    borderTopRightRadius: showSuggestions
      ? ClinTheme.space[3]
      : ClinTheme.space[5]
  }),
  ({ showGlowEffect }) => ({
    border: showGlowEffect ? '1px solid white' : 'none',
    boxShadow: showGlowEffect ? '0 0 5px #fff' : 'none'
  })
)

export const StyledResults = styled.ul<ISearchStylesProps>(
  {
    listStyle: 'none',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    margin: 0,
    padding: 0,
    zIndex: ClinTheme.zIndices.drawer,
    boxShadow: `0px 4px 8px 0px rgba(0, 0, 0, 0.16)`,
    [mediaQuery(mobileUpBreakpoint)]: {
      padding: 0
    },
    [mediaQueryDown(mobileUpBreakpoint)]: {
      marginLeft: '16px',
      marginRight: '-16px',
    },
    [`&.clin-global-search-menu-results`]: {
      marginTop: '-8px'
    }
  },
  ({ showSuggestions }) => ({
    display: showSuggestions ? 'block' : 'none'
  })
)

export const StyledResultsWithNewFeature = styled.ul<ISearchStylesProps>(
  {
    listStyle: 'none',
    position: 'absolute',
    top: '100%',
    left: '51px',
    right: 0,
    margin: 0,
    padding: 0,
    zIndex: ClinTheme.zIndices.suggestiveSearch,
    pointerEvents: 'auto',
    boxShadow: `0px 4px 8px 0px rgba(0, 0, 0, 0.16)`
  },
  ({ showSuggestions }) => ({
    display: showSuggestions ? 'block' : 'none'
  })
)
