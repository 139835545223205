import styled from 'styled-components'

export interface IStyledClinSpinnerProps {
  /** The spinner's width and height in pixels (default 24px) */
  size?: number
}

export const StyledClinSpinnerSvg = styled.svg<IStyledClinSpinnerProps>(
  {
    display: 'inline-block',
    flexShrink: 0
  },
  ({ size }) => ({
    width: size,
    height: size
  })
)

export const StyledSpinnerContainer = styled.div({
  display: 'flex',
  height: '50vh',
  alignItems: 'center',
  justifyContent: 'center'
})
