import React, { FunctionComponent } from 'react'
import { Container, Col, Row } from 'react-grid-system'
import { ClinTheme } from '../../../../ClinTheme'
import { ClinButton } from '../../../../components/ClinButton'
import { ClinSpacer } from '../../../../components/ClinSpacer'
import { ClinText } from '../../../../components/ClinText'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'
import { ClinIcon } from '../../../../components/ClinIcon'
import { ClinIconPathName } from '../../../../components/ClinIcon/ClinIcon.paths'
import {
  StyledSummaryHeading,
  StyledSummaryIcon
} from '../../../CheckoutSummary/CheckoutSummary.styles'
import { StyledSubmit } from '../DiscontinueConfirmationModal/DiscontinueConfirmationModal.styles'
import { StyledActions } from '../../../PhysicianDetails/DiscontinueModal/DiscontinueModal.styles'
import { useTranslation } from 'react-i18next'

interface IPatientDetailDiscontinued {
  /** The patient number */
  patientNumber: string
  /** Calls a function if the user requests to download an adverse event form */
  handleAdverseEventDownload: () => void
  /** Calls a function if the user requests to go back to the previous page */
  handleBackToPatientDetails: () => void
}

export const PatientDetailDiscontinued: FunctionComponent<
  IPatientDetailDiscontinued
> = ({
  patientNumber,
  handleAdverseEventDownload,
  handleBackToPatientDetails
}) => {
  const { t } = useTranslation()
  return (
    <Container>
      <StyledSummaryHeading>
        <StyledSummaryIcon>
          <ClinIcon
            iconName={ClinIconPathName.ArrowRight}
            iconSize={ClinTheme.space[4]}
          />
        </StyledSummaryIcon>
        <ClinText
          variant={TypographyVariant.H4}
          as="span"
          fontWeight={ClinTheme.fontWeights.bold}
          marginLeft={ClinTheme.space[3]}
        >
          {t('patient_detail_discontinued:title', {
            patientNumber: patientNumber
          })}
        </ClinText>
      </StyledSummaryHeading>
      <ClinSpacer />
      <Row>
        <Col sm={12} md={10} lg={8}>
          <ClinText as="p" variant={TypographyVariant.LargeParagraph}>
            {t('patient_detail_discontinued:description')}
          </ClinText>
        </Col>
      </Row>
      <ClinSpacer height={ClinTheme.space[6]} />
      <Row>
        <Col sm={12} md={10} lg={8}>
          <StyledSubmit>
            <Row justify="between">
              <Col md={5} lg={6}>
                <StyledActions>
                  <ClinButton
                    className="download-adverse-event-form"
                    onClick={() => handleAdverseEventDownload()}
                  >
                    {t('patient_detail_discontinued:download')}
                  </ClinButton>
                  <ClinButton
                    className="back-to-patient"
                    variant="primary"
                    onClick={() => handleBackToPatientDetails()}
                  >
                    {t('patient_detail_discontinued:back_btn')}
                  </ClinButton>
                </StyledActions>
              </Col>
            </Row>
          </StyledSubmit>
        </Col>
      </Row>
    </Container>
  )
}
