import React, { FunctionComponent, useEffect, useState } from 'react'
import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../ClinIcon'
import { ClinNavItem } from '../ClinNavItem'
import { ClinIconPathName } from '../ClinIcon/ClinIcon.paths'
import {
  StyledBurgerButton,
  StyledClinNavBar,
  IStyledClinNavBarProps,
  StyledNavItemsContainer,
  StyledNavIconsContainer,
  StyledLogo,
  StyledLogoLink,
  StyledLogoLinkInner,
  StyledSecondNavWrapper,
  StyledHeaderWrapper,
  StyledBackButton,
  StyledLanguageButton,
  StyledLanguageOption
} from './ClinNavbar.styles'
import { Link, useLocation } from 'react-router-dom'
import { useAppContext } from '../../context/app'
import { isAusGaUser } from '../../constants'
import { AutoSuggest } from '../../features/Search/AutoSuggest'
import { t } from 'i18next'
import { GlobalSearch } from '../../features/Search/GlobalSearch'
import { useCheckUnableHomePage } from '../../hooks/useCheckUnableHomePage/useCheckUnableHomePage'
import { ClinText } from '../ClinText'
import i18n, { defaultLanguages } from '../../i18n/config'
import EarthIcon from '../../assets/media/icons/earth.svg'
import { INavigationItem } from '../../services/ContentProvider'
import ColumnTooltip from '../../pages/OrderPages/Orders/ColumnTooltip/ColumnTooltip'

interface IClinNavbarProps extends IStyledClinNavBarProps {
  /** Nav items  */
  navItems?:
    | React.ReactElement<typeof ClinNavItem>[]
    | React.ReactElement<typeof ClinNavItem>
  /**Child Nav items  */
  childNavItems: INavigationItem[] | undefined
  /** Icon nav items  */
  navIcons?: React.ReactNode
  /** Whether the navbar is open initially */
  isMenuOpen?: boolean
  /** Call back to inform when a nav menu is closed */
  resetOnClose: () => void
  resetFilteredChildNavItems: () => void
}

export const ClinNavbar: FunctionComponent<IClinNavbarProps> = ({
  navItems,
  childNavItems,
  navIcons,
  isMenuOpen = false,
  resetOnClose,
  resetFilteredChildNavItems
}) => {
  const { userRole, portfolioCountryCode } = useAppContext()
  const [isBurgerOpen, setIsBurgerOpen] = React.useState<boolean>(isMenuOpen)
  const { enableNewHomePage } = useCheckUnableHomePage()

  const [isMobile, setIsMobile] = React.useState<boolean>(false)
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState<boolean>(false)
  // https://clinigen.atlassian.net/browse/CLOS-8642

  const baseUrl =
    isAusGaUser(portfolioCountryCode, userRole) || enableNewHomePage
      ? '/home'
      : '/orders'

  const updateIsMobile = () => {
    setIsMobile(
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) <= ClinTheme.breakpoints[2]
    )
  }

  const listener = () => {
    updateIsMobile()
    isBurgerOpen && !isMobile && setIsBurgerOpen(false)
  }

  useEffect(() => {
    updateIsMobile() // Set initial state
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [isBurgerOpen])

  // Close menu if we navigate away
  const location = useLocation()
  useEffect(() => {
    setIsBurgerOpen(false)
    setIsLanguageMenuOpen(false)
  }, [location])

  // If open on mobile hide overflow to stop scrolling
  useEffect(() => {
    document.body.style.overflow = isBurgerOpen ? 'hidden' : 'auto'
  }, [isBurgerOpen])

  const onClickLanguage = () => {
    setIsLanguageMenuOpen(!isLanguageMenuOpen)
  }

  const childNavItemsList =
    childNavItems &&
    childNavItems?.map((nav) => (
      <Link
        key={nav.path}
        to={nav.path}
        onClick={(e) => {
          e.stopPropagation()
          resetFilteredChildNavItems()
        }}
      >
        <StyledLanguageOption>{nav.title}</StyledLanguageOption>
      </Link>
    ))

  return (
    <>
      <StyledClinNavBar>
        <StyledBurgerButton
          className="nav-burger"
          onClick={() => {
            setIsBurgerOpen(!isBurgerOpen)
            setIsLanguageMenuOpen(false)
            isBurgerOpen && resetOnClose()
            resetFilteredChildNavItems()
          }}
        >
          {(!isBurgerOpen || !childNavItemsList) && !isLanguageMenuOpen && (
            <ClinIcon
              iconName={
                isBurgerOpen
                  ? ClinIconPathName.BurgerClosed
                  : ClinIconPathName.Burger
              }
              iconSize={ClinTheme.space[5]}
              iconFill={ClinTheme.colors.white}
            />
          )}
        </StyledBurgerButton>
        {!isBurgerOpen && (
          <StyledHeaderWrapper>
            <StyledLogoLink to={baseUrl}>
              <StyledLogoLinkInner>
                <ColumnTooltip tooltipText={t('navigation:clinigen_logo')}>
                  <StyledLogo inverted={true} />
                </ColumnTooltip>
              </StyledLogoLinkInner>
            </StyledLogoLink>
            <AutoSuggest
              pathsToHide={['/home', '/homepage']}
              noResultsText={t('global_search:no_results_text')}
              maUserNoResultsText={t('global_search:ma_user_no_results_text')}
              suggestionText={t('global_search:suggestion_text')}
              noResultsPath={'/products/catalogue?q=*'}
              searchComponent={(props) => (
                <GlobalSearch
                  {...props}
                  isAusGa={isAusGaUser(portfolioCountryCode, userRole)}
                  isMenuSearch={false}
                />
              )}
            />
            {!isMobile && (
              <StyledNavIconsContainer className="nav-icons-container">
                {navIcons}
              </StyledNavIconsContainer>
            )}
          </StyledHeaderWrapper>
        )}
      </StyledClinNavBar>
      <StyledSecondNavWrapper>
        {isBurgerOpen && (
          <AutoSuggest
            noResultsText={t('global_search:no_results_text')}
            maUserNoResultsText={t('global_search:ma_user_no_results_text')}
            suggestionText={t('global_search:suggestion_text')}
            noResultsPath={'/products/catalogue?q=*'}
            searchComponent={(props) => (
              <GlobalSearch
                {...props}
                isAusGa={isAusGaUser(portfolioCountryCode, userRole)}
                isMenuSearch={true}
              />
            )}
          />
        )}
        {!isLanguageMenuOpen ? (
          <StyledNavItemsContainer
            isMenuOpen={isBurgerOpen}
            className="nav-items-container"
            style={{ paddingTop: '0' }}
          >
            {isMobile && childNavItemsList && (
              <StyledBackButton
                className="nav-back"
                onClick={() => {
                  resetFilteredChildNavItems()
                }}
              >
                <ClinIcon
                  iconName={ClinIconPathName.ChevronLeft}
                  iconSize={ClinTheme.space[5]}
                  iconFill={ClinTheme.colors.midGrey}
                />
                <ClinText
                  color={ClinTheme.colors.midGrey}
                  fontWeight={ClinTheme.fontWeights.medium}
                >
                  {t('common:buttons.back_button_text')}
                </ClinText>
              </StyledBackButton>
            )}
            {(isMobile && childNavItemsList) || navItems}
            {isMobile && !childNavItemsList && (
              <StyledLanguageButton
                onClick={(e) => {
                  e.stopPropagation()
                  onClickLanguage()
                }}
              >
                <img src={EarthIcon} alt="Earth Icon" className="earth-icon" />
                {defaultLanguages.find((l) => l.id === i18n.language)?.value}
                <ClinIcon
                  iconName={ClinIconPathName.ChevronRight}
                  iconSize={ClinTheme.space[5]}
                  iconFill={ClinTheme.colors.midGrey}
                />
              </StyledLanguageButton>
            )}
          </StyledNavItemsContainer>
        ) : (
          <StyledNavItemsContainer
            isMenuOpen={isBurgerOpen && isLanguageMenuOpen}
          >
            <StyledBackButton
              className="nav-back"
              onClick={() => {
                setIsLanguageMenuOpen(!isLanguageMenuOpen)
                isLanguageMenuOpen && resetOnClose()
              }}
            >
              <ClinIcon
                iconName={ClinIconPathName.ChevronLeft}
                iconSize={ClinTheme.space[5]}
                iconFill={ClinTheme.colors.midGrey}
              />
              <ClinText
                color={ClinTheme.colors.midGrey}
                fontWeight={ClinTheme.fontWeights.medium}
              >
                {t('common:buttons.back_button_text')}
              </ClinText>
            </StyledBackButton>

            {defaultLanguages
              .filter((language) => language.id !== 'cimode')
              .map((language) => (
                <StyledLanguageOption
                  key={language.id}
                  onClick={() => {
                    i18n.changeLanguage(language.id)
                    setIsLanguageMenuOpen(false)
                  }}
                >
                  {language.value}
                </StyledLanguageOption>
              ))}
          </StyledNavItemsContainer>
        )}
      </StyledSecondNavWrapper>
    </>
  )
}
