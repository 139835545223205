import { lighten } from 'polished'
import styled from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'
import { ClinStyledButton } from '../../../components/ClinButton/ClinButton.styles'
import { mediaQuery } from '../../../utils/mediaQuery'
import { ISearchStylesProps } from './PhysicianAutoSuggest'

const tabletBreakpoint = ClinTheme.breakpoints[1]

export interface IPhysicianAutoSuggestResultProps {
  /** Show highlight styles */
  selected?: boolean
}

export const StyledPhysicianAutoSuggestWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flex: '1 0 auto',
  marginBottom: ClinTheme.space[6],

  [`${ClinStyledButton}`]: {
    marginTop: ClinTheme.space[4],
    width: '100%'
  },

  [mediaQuery(tabletBreakpoint)]: {
    flexDirection: 'row',

    [`${ClinStyledButton}`]: {
      marginTop: 0,
      marginLeft: ClinTheme.space[4],
      width: 'auto'
    }
  }
})

export const StyledPhysicianAutoSuggestResults = styled.ul<ISearchStylesProps>(
  {
    listStyle: 'none',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    margin: 0,
    padding: 0,
    zIndex: ClinTheme.zIndices.suggestiveSearch,
    boxShadow: `0px 4px 8px 0px rgba(0, 0, 0, 0.16)`
  },
  ({ showSuggestions }) => ({
    display: showSuggestions ? 'block' : 'none'
  })
)

export const StyledPhysicianAutoSuggestResultItem =
  styled.li<IPhysicianAutoSuggestResultProps>(
    {
      fontSize: ClinTheme.fontSizes[3],
      fontWeight: ClinTheme.fontWeights.light,
      color: ClinTheme.colors.primary,
      padding: `${ClinTheme.space[4]}px ${ClinTheme.space[5]}px`,
      backgroundColor: ClinTheme.colors.white,
      borderBottom: `solid 1px ${ClinTheme.colors.grey}`,
      cursor: 'pointer',
      [`a`]: {
        display: 'block',
        color: ClinTheme.colors.primary
      },
      [`:hover, :focus`]: {
        backgroundColor: lighten(0.2, ClinTheme.colors.primaryLightest)
      },
      [`:last-child`]: {
        borderBottom: 'none'
      }
    },
    ({ selected }) => ({
      backgroundColor: selected
        ? ClinTheme.colors.lightGrey
        : ClinTheme.colors.white
    })
  )

export const StyledPhysicianAutoSuggestLink =
  styled.li<IPhysicianAutoSuggestResultProps>(
    {
      padding: `${ClinTheme.space[3]}px ${ClinTheme.space[5]}px`,
      backgroundColor: ClinTheme.colors.white,
      cursor: 'pointer',
      [`a`]: {
        display: 'block',
        color: ClinTheme.colors.primary
      },
      [`:hover, :focus`]: {
        backgroundColor: lighten(0.2, ClinTheme.colors.primaryLightest)
      },
      [`:last-child`]: {
        borderBottom: 'none'
      }
    },
    ({ selected }) => ({
      backgroundColor: selected
        ? ClinTheme.colors.lightGrey
        : ClinTheme.colors.white
    })
  )

export const StyledNoResults = styled.div({
  cursor: 'pointer'
})
