import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import {
  account_details_en,
  account_details_it,
  account_details_fr,
  account_details_es,
  basket_en,
  basket_it,
  basket_fr,
  basket_es,
  report_adverse_event_en,
  report_adverse_event_it,
  report_adverse_event_fr,
  report_adverse_event_es,
  product_catalogue_en,
  product_catalogue_it,
  product_catalogue_fr,
  product_catalogue_es,
  orders_en,
  orders_it,
  orders_fr,
  orders_es,
  bookmarks_en,
  bookmarks_it,
  bookmarks_fr,
  bookmarks_es,
  product_variants_en,
  product_variants_it,
  product_variants_fr,
  product_variants_es,
  access_programs_en,
  access_programs_it,
  access_programs_fr,
  access_programs_es,
  product_detail_en,
  product_detail_it,
  product_detail_fr,
  product_detail_es,
  navigation_en,
  navigation_it,
  navigation_fr,
  navigation_es,
  global_search_en,
  global_search_it,
  global_search_fr,
  global_search_es,
  common_en,
  common_it,
  common_fr,
  common_es,
  glossary_en,
  glossary_it,
  glossary_es,
  glossary_fr,
  bootstrap_error_en,
  bootstrap_error_it,
  bootstrap_error_es,
  bootstrap_error_fr,
  checkout_en,
  checkout_it,
  checkout_fr,
  checkout_es,
  checkout_options_en,
  checkout_options_it,
  checkout_options_es,
  checkout_options_fr,
  opa_checkout_en,
  opa_checkout_it,
  opa_checkout_es,
  opa_checkout_fr,
  checkout_summary_en,
  checkout_summary_it,
  checkout_summary_es,
  checkout_summary_fr,
  institute_details_en,
  institute_details_it,
  institute_details_es,
  institute_details_fr,
  user_roles_en,
  user_roles_it,
  user_roles_es,
  user_roles_fr,
  physician_details_en,
  physician_details_it,
  physician_details_es,
  physician_details_fr,
  patient_access_form_en,
  patient_access_form_it,
  patient_access_form_es,
  patient_access_form_fr,
  ma_order_detail_en,
  ma_order_detail_it,
  ma_order_detail_es,
  ma_order_detail_fr,
  my_physicians_en,
  my_physicians_it,
  my_physicians_es,
  my_physicians_fr,
  invite_new_user_en,
  invite_new_user_it,
  invite_new_user_es,
  invite_new_user_fr,
  forms_en,
  forms_it,
  forms_es,
  forms_fr,
  not_found_en,
  not_found_it,
  not_found_es,
  not_found_fr,
  holds_en,
  holds_it,
  holds_fr,
  holds_es,
  sourcing_enquiry_en,
  sourcing_enquiry_it,
  sourcing_enquiry_es,
  sourcing_enquiry_fr,
  drug_request_options_en,
  drug_request_options_it,
  drug_request_options_es,
  drug_request_options_fr,
  report_shortage_en,
  report_shortage_it,
  report_shortage_es,
  report_shortage_fr,
  enrol_physician_on_to_program_en,
  enrol_physician_on_to_program_it,
  enrol_physician_on_to_program_es,
  enrol_physician_on_to_program_fr,
  program_detail_en,
  program_detail_it,
  program_detail_es,
  program_detail_fr,
  uk_shortages_en,
  uk_shortages_it,
  uk_shortages_es,
  uk_shortages_fr,
  transfer_patient_to_physician_en,
  transfer_patient_to_physician_it,
  transfer_patient_to_physician_es,
  transfer_patient_to_physician_fr,
  add_to_basket_confirmation_en,
  add_to_basket_confirmation_it,
  add_to_basket_confirmation_es,
  add_to_basket_confirmation_fr,
  transfer_patient_to_institute_en,
  transfer_patient_to_institute_it,
  transfer_patient_to_institute_es,
  transfer_patient_to_institute_fr,
  onboarding_en,
  onboarding_it,
  onboarding_es,
  onboarding_fr,
  request_new_shipping_address_en,
  request_new_shipping_address_it,
  request_new_shipping_address_es,
  request_new_shipping_address_fr,
  request_access_en,
  request_access_it,
  request_access_es,
  request_access_fr,
  edit_contact_en,
  edit_contact_it,
  edit_contact_es,
  edit_contact_fr,
  support_en,
  support_it,
  support_es,
  support_fr,
  opa_order_summary_en,
  opa_order_summary_it,
  opa_order_summary_es,
  opa_order_summary_fr,
  enrol_user_on_to_program_en,
  enrol_user_on_to_program_it,
  enrol_user_on_to_program_es,
  enrol_user_on_to_program_fr,
  patient_detail_en,
  patient_detail_it,
  patient_detail_es,
  patient_detail_fr,
  order_line_cancelation_modal_en,
  order_line_cancelation_modal_it,
  order_line_cancelation_modal_es,
  order_line_cancelation_modal_fr,
  program_documentation_en,
  program_documentation_it,
  program_documentation_es,
  program_documentation_fr,
  product_not_available_en,
  product_not_available_it,
  product_not_available_es,
  product_not_available_fr,
  app_loader_en,
  app_loader_it,
  app_loader_es,
  app_loader_fr,
  patient_detail_discontinued_en,
  patient_detail_discontinued_it,
  patient_detail_discontinued_es,
  patient_detail_discontinued_fr,
  transfer_patient_en,
  transfer_patient_it,
  transfer_patient_es,
  transfer_patient_fr,
  program_register_interest_en,
  program_register_interest_it,
  program_register_interest_es,
  program_register_interest_fr,
  announce_mode_en,
  announce_mode_it,
  announce_mode_es,
  announce_mode_fr,
  order_detail_en,
  order_detail_it,
  order_detail_es,
  order_detail_fr,
  portfolio_journey_en,
  portfolio_journey_it,
  portfolio_journey_es,
  portfolio_journey_fr,
  preferences_en,
  preferences_es,
  preferences_fr,
  preferences_it
} from '.'
import { i18nRef } from './i18nRef'
// import Backend from 'i18next-http-backend' // if you want to load translations from the backend

export const resources = {
  en: {
    account_details: account_details_en,
    basket: basket_en,
    report_adverse_event: report_adverse_event_en,
    product_catalogue: product_catalogue_en,
    orders: orders_en,
    bookmarks: bookmarks_en,
    product_detail: product_detail_en,
    product_variants: product_variants_en,
    access_programs: access_programs_en,
    navigation: navigation_en,
    global_search: global_search_en,
    common: common_en,
    glossary: glossary_en,
    bootstrap_error: bootstrap_error_en,
    checkout_options: checkout_options_en,
    opa_checkout: opa_checkout_en,
    ma_order_detail: ma_order_detail_en,
    checkout_summary: checkout_summary_en,
    checkout: checkout_en,
    institute_details: institute_details_en,
    user_roles: user_roles_en,
    physician_details: physician_details_en,
    my_physicians: my_physicians_en,
    patient_access_form: patient_access_form_en,
    invite_new_user: invite_new_user_en,
    forms: forms_en,
    holds: holds_en,
    not_found: not_found_en,
    request_new_shipping_address: request_new_shipping_address_en,
    onboarding: onboarding_en,
    sourcing_enquiry: sourcing_enquiry_en,
    drug_request_options: drug_request_options_en,
    report_shortage: report_shortage_en,
    enrol_physician_on_to_program: enrol_physician_on_to_program_en,
    program_detail: program_detail_en,
    request_access: request_access_en,
    uk_shortages: uk_shortages_en,
    transfer_patient_to_physician: transfer_patient_to_physician_en,
    add_to_basket_confirmation: add_to_basket_confirmation_en,
    transfer_patient_to_institute: transfer_patient_to_institute_en,
    edit_contact: edit_contact_en,
    support: support_en,
    enrol_user_on_to_program: enrol_user_on_to_program_en,
    opa_order_summary: opa_order_summary_en,
    order_line_cancelation_modal: order_line_cancelation_modal_en,
    product_not_available: product_not_available_en,
    app_loader: app_loader_en,
    program_documentation: program_documentation_en,
    patient_detail: patient_detail_en,
    patient_detail_discontinued: patient_detail_discontinued_en,
    transfer_patient: transfer_patient_en,
    program_register_interest: program_register_interest_en,
    announce_mode: announce_mode_en,
    order_detail: order_detail_en,
    portfolio_journey: portfolio_journey_en,
    preferences: preferences_en
  },
  es: {
    account_details: account_details_es,
    basket: basket_es,
    report_adverse_event: report_adverse_event_es,
    product_catalogue: product_catalogue_es,
    orders: orders_es,
    bookmarks: bookmarks_es,
    product_detail: product_detail_es,
    product_variants: product_variants_es,
    access_programs: access_programs_es,
    navigation: navigation_es,
    global_search: global_search_es,
    common: common_es,
    glossary: glossary_es,
    bootstrap_error: bootstrap_error_es,
    checkout_options: checkout_options_es,
    opa_checkout: opa_checkout_es,
    ma_order_detail: ma_order_detail_es,
    checkout_summary: checkout_summary_es,
    checkout: checkout_es,
    institute_details: institute_details_es,
    user_roles: user_roles_es,
    physician_details: physician_details_es,
    my_physicians: my_physicians_es,
    patient_access_form: patient_access_form_es,
    invite_new_user: invite_new_user_es,
    forms: forms_es,
    holds: holds_es,
    request_new_shipping_address: request_new_shipping_address_es,
    not_found: not_found_es,
    onboarding: onboarding_es,
    sourcing_enquiry: sourcing_enquiry_es,
    drug_request_options: drug_request_options_es,
    report_shortage: report_shortage_es,
    enrol_physician_on_to_program: enrol_physician_on_to_program_es,
    program_detail: program_detail_es,
    request_access: request_access_es,
    uk_shortages: uk_shortages_es,
    transfer_patient_to_physician: transfer_patient_to_physician_es,
    add_to_basket_confirmation: add_to_basket_confirmation_es,
    transfer_patient_to_institute: transfer_patient_to_institute_es,
    edit_contact: edit_contact_es,
    support: support_es,
    enrol_user_on_to_program: enrol_user_on_to_program_es,
    opa_order_summary: opa_order_summary_es,
    order_line_cancelation_modal: order_line_cancelation_modal_es,
    product_not_available: product_not_available_es,
    app_loader: app_loader_es,
    program_documentation: program_documentation_es,
    patient_detail_discontinued: patient_detail_discontinued_es,
    transfer_patient: transfer_patient_es,
    program_register_interest: program_register_interest_es,
    announce_mode: announce_mode_es,
    patient_detail: patient_detail_es,
    order_detail: order_detail_es,
    portfolio_journey: portfolio_journey_es,
    preferences: preferences_es
  },
  fr: {
    account_details: account_details_fr,
    basket: basket_fr,
    report_adverse_event: report_adverse_event_fr,
    product_catalogue: product_catalogue_fr,
    orders: orders_fr,
    bookmarks: bookmarks_fr,
    product_detail: product_detail_fr,
    product_variants: product_variants_fr,
    access_programs: access_programs_fr,
    navigation: navigation_fr,
    global_search: global_search_fr,
    common: common_fr,
    glossary: glossary_fr,
    bootstrap_error: bootstrap_error_fr,
    checkout_options: checkout_options_fr,
    opa_checkout: opa_checkout_fr,
    ma_order_detail: ma_order_detail_fr,
    checkout_summary: checkout_summary_fr,
    checkout: checkout_fr,
    institute_details: institute_details_fr,
    user_roles: user_roles_fr,
    physician_details: physician_details_fr,
    my_physicians: my_physicians_fr,
    patient_access_form: patient_access_form_fr,
    invite_new_user: invite_new_user_fr,
    forms: forms_fr,
    holds: holds_fr,
    request_new_shipping_address: request_new_shipping_address_fr,
    not_found: not_found_fr,
    onboarding: onboarding_fr,
    sourcing_enquiry: sourcing_enquiry_fr,
    drug_request_options: drug_request_options_fr,
    report_shortage: report_shortage_fr,
    enrol_physician_on_to_program: enrol_physician_on_to_program_fr,
    program_detail: program_detail_fr,
    request_access: request_access_fr,
    uk_shortages: uk_shortages_fr,
    transfer_patient_to_physician: transfer_patient_to_physician_fr,
    add_to_basket_confirmation: add_to_basket_confirmation_fr,
    transfer_patient_to_institute: transfer_patient_to_institute_fr,
    edit_contact: edit_contact_fr,
    support: support_fr,
    enrol_user_on_to_program: enrol_user_on_to_program_fr,
    opa_order_summary: opa_order_summary_fr,
    order_line_cancelation_modal: order_line_cancelation_modal_fr,
    product_not_available: product_not_available_fr,
    app_loader: app_loader_fr,
    program_documentation: program_documentation_fr,
    patient_detail_discontinued: patient_detail_discontinued_fr,
    transfer_patient: transfer_patient_fr,
    program_register_interest: program_register_interest_fr,
    announce_mode: announce_mode_fr,
    patient_detail: patient_detail_fr,
    order_detail: order_detail_fr,
    portfolio_journey: portfolio_journey_fr,
    preferences: preferences_fr
  },
  it: {
    account_details: account_details_it,
    basket: basket_it,
    report_adverse_event: report_adverse_event_it,
    product_catalogue: product_catalogue_it,
    orders: orders_it,
    bookmarks: bookmarks_it,
    product_detail: product_detail_it,
    product_variants: product_variants_it,
    access_programs: access_programs_it,
    navigation: navigation_it,
    global_search: global_search_it,
    common: common_it,
    glossary: glossary_it,
    bootstrap_error: bootstrap_error_it,
    checkout_options: checkout_options_it,
    opa_checkout: opa_checkout_it,
    ma_order_detail: ma_order_detail_it,
    checkout_summary: checkout_summary_it,
    checkout: checkout_it,
    institute_details: institute_details_it,
    user_roles: user_roles_it,
    physician_details: physician_details_it,
    my_physicians: my_physicians_it,
    patient_access_form: patient_access_form_it,
    invite_new_user: invite_new_user_it,
    forms: forms_it,
    holds: holds_it,
    request_new_shipping_address: request_new_shipping_address_it,
    not_found: not_found_it,
    onboarding: onboarding_it,
    sourcing_enquiry: sourcing_enquiry_it,
    drug_request_options: drug_request_options_it,
    report_shortage: report_shortage_it,
    enrol_physician_on_to_program: enrol_physician_on_to_program_it,
    program_detail: program_detail_it,
    request_access: request_access_it,
    uk_shortages: uk_shortages_it,
    transfer_patient_to_physician: transfer_patient_to_physician_it,
    add_to_basket_confirmation: add_to_basket_confirmation_it,
    transfer_patient_to_institute: transfer_patient_to_institute_it,
    edit_contact: edit_contact_it,
    support: support_it,
    enrol_user_on_to_program: enrol_user_on_to_program_it,
    opa_order_summary: opa_order_summary_it,
    order_line_cancelation_modal: order_line_cancelation_modal_it,
    product_not_available: product_not_available_it,
    app_loader: app_loader_it,
    program_documentation: program_documentation_it,
    patient_detail_discontinued: patient_detail_discontinued_it,
    transfer_patient: transfer_patient_it,
    program_register_interest: program_register_interest_it,
    announce_mode: announce_mode_it,
    patient_detail: patient_detail_it,
    order_detail: order_detail_it,
    portfolio_journey: portfolio_journey_it,
    preferences: preferences_it
  }
}

const detectionOptions = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng'
}

export interface ILanguageOption {
  id: string
  value: string
}

export const defaultLanguageEnglish = 'en'

export const defaultLanguages: ILanguageOption[] = [
  {
    id: defaultLanguageEnglish,
    value: 'English'
  },
  {
    id: 'es',
    value: 'Español'
  }
  //TODO uncomment this after we get translations for those languages
  // {
  //   id: 'fr',
  //   value: 'Français'
  // },
  // {
  //   id: 'it',
  //   value: 'Italian'
  // }
]

// Add debug CI Mode to show keys
if (window.location.hostname === 'localhost') {
  defaultLanguages.push({
    id: 'cimode',
    value: 'Debug keys'
  })
}

i18n
  /*
     load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
     learn more: https://github.com/i18next/i18next-http-backend
    */
  // .use(Backend)
  /*
      detect the language via browser locale
   */
  // .use(LanguageDetector)
  /*
     pass the i18n instance to react-i18next.
    */
  .use(initReactI18next)
  .init({
    // lng: 'en' // <--- turn off for detection to work
    // lng: 'cimode', // <--- show keys in DOM
    detection: detectionOptions,
    fallbackLng: 'en',
    debug: false, // <-- turn off console logging
    ns: [
      'account_details',
      'basket',
      'report_adverse_event',
      'product_catalogue',
      'orders',
      'bookmarks',
      'product_variants',
      'access_programs',
      'product_detail',
      'navigation',
      'global_search',
      'common',
      'glossary',
      'bootstrap_error',
      'opa_checkout',
      'checkout_summary',
      'checkout',
      'institute_details',
      'user_roles',
      'physician_details',
      'my_physicians',
      'patient_access_form',
      'forms',
      'program_detail',
      'uk_shortages',
      'add_to_basket_confirmation',
      'sourcing_enquiry',
      'drug_request_options',
      'report_shortage',
      'program_detail',
      'request_access',
      'transfer_patient_to_institute',
      'opa_order_summary',
      'order_line_cancelation_modal',
      'product_not_available',
      'app_loader',
      'edit_contact',
      'support',
      'opa_order_summary',
      'patient_detail_discontinued',
      'transfer_patient',
      'program_register_interest',
      'announce_mode',
      'order_detail',
      'preferences'
    ],
    defaultNS: 'common',
    supportedLngs: defaultLanguages.map((l) => l.id),
    resources,
    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    react: {
      // bindI18n: 'languageChanged',
      // bindI18nStore: '',
      // transEmptyNodeValue: '',
      // transSupportBasicHtmlNodes: true,
      // transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false
    },
    // Receives a key that was not found in t() and returns a value, that will be returned by t()
    parseMissingKeyHandler(key: string): any {
      console.warn(`${key} is missing`)
      return `#MISSING KEY: ${key}#`
    }
  })
  .then((tFunction) => {
    window.location.hostname === 'localhost' && console.log('i18n initialised')
    // Store TFunction
    i18nRef.transFn = tFunction
    i18nRef.isLoaded = true
  })

export default i18n
