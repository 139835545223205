import { AxiosError } from 'axios'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from 'react'
import { useHistory, useLocation } from 'react-router'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { IPagination } from '../../../components/ClinPagination/ClinPagination.model'
import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ContactStatus, UserRoleRecord, UserRole } from '../../../constants'
import { useAppContext } from '../../../context/app'
import { createAnnounceEvent } from '../../../events/AnnounceEvent'
import { createShowInstituteModalEvent } from '../../../events/InstituteModalEvent'
import { RemoveAddressConfirmation } from '../../../features/RemoveAddressConfirmation'
import { RemoveUserConfirmation } from '../../../features/RemoveUserConfirmation'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import {
  activateUser,
  AuthError,
  cancelActivateUser,
  cancelContactsSearch,
  contactsSearch,
  deleteContactFromInstitution,
  deleteShippingAddress,
  queryUserLoginStatus
} from '../../../services/ApiService'
import { IContact } from '../../../types/IContact'
import {
  ContactResponseDto,
  ContactSearchDto,
  OrgAddressDto,
  UserEmailDto,
  UserStatusDto
} from '../../../types/swaggerTypes'
import { InstituteDetail } from './InstituteDetail'
import { augmentContactWithStatus } from './InstituteDetail.model'
import { AnalyticsEvent } from '../../../services/Analytics'

const rowsPerPage: number = 2

const defaultSearchParams: ContactSearchDto = {
  query: '*',
  filter: {
    contactName: '',
    role: ''
  },
  pagination: {
    skip: 0,
    take: 1000
  },
  sorting: {
    sortBy: '',
    order: SortDirectionType.Descending
  }
}

const defaultPagination: IPagination = {
  count: 0,
  skip: 0,
  take: rowsPerPage,
  total: 0
}

export enum ModalMode {
  confirmUserDeletion,
  confirmAddressDeletion
}

interface ModalState {
  mode: ModalMode | undefined
  isLoading: boolean
  isInvite: boolean
  isSubmitting: boolean
  isSubmitted: boolean
  confirmAction?: boolean
  userToDelete?: ContactResponseDto
  addressToDelete?: OrgAddressDto
}

const defaultState: ModalState = {
  mode: undefined,
  isLoading: false,
  isInvite: false,
  isSubmitting: false,
  isSubmitted: false
}

interface SuccessAnnounce {
  title: string | undefined
  message: string | undefined
}

export const InstituteDetailContainer: FunctionComponent = () => {
  const location = useLocation().state as SuccessAnnounce
  const history = useHistory()
  const { dispatch, institute, userDetails, supportContact, userRole } =
    useAppContext()
  const isAdmin = !!(userRole && UserRoleRecord[userRole as UserRole].isAdmin)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [contacts, setContacts] = useState<IContact[]>([])
  const [activeContacts, setActiveContacts] = useState<IContact[]>([])
  const [pendingContacts, setPendingContacts] = useState<IContact[]>([])
  const [searchParams, setSearchParams] =
    useState<ContactSearchDto>(defaultSearchParams)
  const [pagination, setPagination] = useState<IPagination>(defaultPagination)
  const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(0)
  const [modalState, setModalState] = useState<ModalState>(defaultState)

  const handlePageClicked = (selectedPageIndex: number) => {
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: (selectedPageIndex - 1) * rowsPerPage,
        take: rowsPerPage
      }
    })
  }
  const handlePageSizeChange = (pageSize: number) => {
    cancelContactsSearch()
    setIsLoading(true)
    setSearchParams({
      ...searchParams,
      pagination: {
        skip: 0,
        take: pageSize
      }
    })
  }

  useEffect(() => {
    location &&
      location.message !== null &&
      location.title !== null &&
      dispatch(
        createAnnounceEvent(
          AnnounceMode.Success,
          location.message,
          location.title
        )
      )
  }, [dispatch, location])

  const getContacts = useCallback(() => {
    contactsSearch(searchParams)
      .then((response) => {
        if (response) {
          const contactsFromAPI: IContact[] = response.data.result.map((c) => {
            return {
              ...c,
              status: ContactStatus.INACTIVE
            }
          })
          setContacts(contactsFromAPI)
          const paginationDto = response.data.pagination
          setPagination({
            count: paginationDto.count,
            skip: paginationDto.skip,
            take: paginationDto.take,
            total: paginationDto.total
          })
        }
      })
      .catch((error: AxiosError) => {
        const { code, message } = error

        // If request is cancelled continue
        if (error.message === AuthError.RequestCancelled) {
          return
        }
        // If we have a full error show it
        if (error.response) {
          const { title, detail } = error.response.data
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching contacts. ${title ?? ''} ${
                message ?? ''
              }`
            )
          )
          console.warn(title, detail)
        } else {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error fetching your contacts. ${message} ${
                code ?? ''
              }`
            )
          )
        }

        analyticsServiceSingleton.trackError(
          error,
          JSON.parse(localStorage.getItem('current_user') || '{}')
        )
        setIsLoading(false)
      })
    return cancelContactsSearch()
  }, [dispatch, searchParams])

  useEffect(() => {
    if (isAdmin) {
      getContacts()
    } else {
      setIsLoading(false)
    }
  }, [searchParams, isAdmin, dispatch, getContacts])

  useEffect(() => {
    if (contacts && contacts.length > 0) {
      const contactEmails: UserEmailDto[] = contacts.map((contact) => {
        return {
          email: contact.contactEmail
        }
      })

      setIsLoading(true)
      contactEmails &&
        contactEmails.length > 0 &&
        queryUserLoginStatus(contactEmails)
          .then((response) => {
            const statuses: UserStatusDto[] = response.data
            const pendingContacts: IContact[] = augmentContactWithStatus(
              statuses,
              contacts,
              ContactStatus.PENDING
            )
            const activeContacts: IContact[] = augmentContactWithStatus(
              statuses,
              contacts,
              ContactStatus.ACTIVE
            )
            const errorContacts: IContact[] = augmentContactWithStatus(
              statuses,
              contacts,
              ContactStatus.ERROR
            )
            const inactiveContacts: IContact[] = augmentContactWithStatus(
              statuses,
              contacts,
              ContactStatus.INACTIVE
            )
            const otherContacts: IContact[] = [
              ...errorContacts,
              ...inactiveContacts
            ]
            if (otherContacts.length > 0) {
              otherContacts.map((contact) =>
                console.warn(`${contact.contactEmail} is ${contact.status}`)
              )
            }
            setPendingContacts(pendingContacts)
            setActiveContacts(activeContacts)
          })
          .catch((error: AxiosError) => {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error fetching your contacts. ${error.message}`
              )
            )
          })
          .finally(() => setIsLoading(false))
    }
  }, [contacts, dispatch, getContacts])

  useEffect(() => {
    setSelectedTabIndex(+!isAdmin)
  }, [isAdmin])

  /**
   * Methods related to contact change requests
   */
  const handleRequestNewUser = () => {
    history.push('/new-user')
  }

  const handleRemoveUser = (user: ContactResponseDto, isInvite: boolean) => {
    setModalState({
      ...modalState,
      mode: ModalMode.confirmUserDeletion,
      userToDelete: user,
      isInvite
    })
  }

  const handleSubmitRemoveUser = () => {
    setModalState({
      ...modalState,
      isSubmitting: true
    })
    modalState.mode === ModalMode.confirmUserDeletion &&
      modalState.userToDelete &&
      deleteContactFromInstitution(modalState.userToDelete.contactId.toString())
        .then((response) => {
          if (response.status === 200) {
            analyticsServiceSingleton.trackEvent(
              AnalyticsEvent.RemoveUserFromInstitute
            )
            setModalState({
              ...modalState,
              mode: undefined,
              isSubmitting: false,
              isSubmitted: true,
              userToDelete: undefined
            })
            window.scrollTo(0, 0)
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Success,
                'The contact was successfully removed',
                ' '
              )
            )
            getContacts()
          }
        })
        .catch((error) => {
          const { code, message } = error
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          window.scrollTo(0, 0)
          setModalState({
            ...modalState,
            mode: undefined,
            isSubmitting: false,
            isSubmitted: false,
            userToDelete: undefined
          })
          // If we have a full error show it
          if (error.response) {
            const { title, detail } = error.response.data
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error removing this user. ${title ?? ''} ${
                  message ?? ''
                }`
              )
            )
            console.warn(title, detail)
          } else {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error removing this user. ${message} ${
                  code ?? ''
                }`
              )
            )
          }
        })
  }

  const handleEditUser = (userId: number) => {
    history.push(`/edit-user/${userId}`)
  }

  /**
   * Methods related to contact address change requests
   */

  const handleRemoveAddress = (address: OrgAddressDto) => {
    setModalState({
      ...modalState,
      mode: ModalMode.confirmAddressDeletion,
      addressToDelete: address
    })
  }

  const handleSubmitRemoveAddress = () => {
    setModalState({
      ...modalState,
      isSubmitting: true
    })
    modalState.mode === ModalMode.confirmAddressDeletion &&
      modalState.addressToDelete &&
      deleteShippingAddress(modalState.addressToDelete.addressId)
        .then((response) => {
          if (response.data.incidentNumber)
            setModalState({
              ...modalState,
              mode: undefined,
              isSubmitting: false,
              isSubmitted: true,
              addressToDelete: undefined
            })
          window.scrollTo(0, 0)
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Success,
              'Your request to remove a shipping request has been submitted',
              ' '
            )
          )
        })
        .catch((error) => {
          const { code, message } = error
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          window.scrollTo(0, 0)
          setModalState({
            ...modalState,
            mode: undefined,
            isSubmitting: false,
            isSubmitted: false,
            addressToDelete: undefined
          })
          // If we have a full error show it
          if (error.response) {
            const { title, detail } = error.response.data
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error submitting your request. ${title ?? ''} ${
                  message ?? ''
                }`
              )
            )
            console.warn(title, detail)
          } else {
            dispatch(
              createAnnounceEvent(
                AnnounceMode.Error,
                `There was an error submitting your request. ${message} ${
                  code ?? ''
                }`
              )
            )
          }
        })
  }

  const handleRequestNewAddress = () => {
    history.push('/new-shipping-address')
  }

  /**
   * Cancel function to hide RemoveUserConfirmation or RemoveAddressConfirmation
   */

  const handleCancelModal = () => {
    const updatedModalState = {
      ...modalState,
      mode: undefined,
      userToDelete: undefined,
      addressToDelete: undefined
    }
    setModalState(updatedModalState)
  }

  const handleRequestInstituteChange = () => {
    dispatch(createShowInstituteModalEvent())
  }

  const handleResendInvite = (contact: IContact) => {
    const updatedPendingContacts = pendingContacts.map((pendingContact) => {
      if (pendingContact.contactEmail === contact.contactEmail) {
        pendingContact.isLoading = true
      }
      return pendingContact
    })
    setPendingContacts([...updatedPendingContacts])

    activateUser({ email: contact.contactEmail })
      .then((response) => {
        if (response.status === 200) {
          window.scrollTo(0, 0)
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Success,
              'You can view the status of their invitation below.',
              'Your invitation has been sent'
            )
          )
          const updatedPendingContacts = pendingContacts.map(
            (pendingContact) => {
              if (pendingContact.contactEmail === contact.contactEmail) {
                pendingContact.isLoading = false
              }
              return pendingContact
            }
          )
          setPendingContacts([...updatedPendingContacts])
        }
      })
      .catch((error: AxiosError) => {
        dispatch(createAnnounceEvent(AnnounceMode.Error, `${error.message}`))
      })
      .finally(() => {
        window.scrollTo(0, 0)
      })
  }

  useEffect(() => {
    return () => {
      cancelContactsSearch()
      cancelActivateUser()
    }
  }, [])

  return (
    <>
      <RemoveAddressConfirmation
        isLoading={modalState.isLoading}
        isOpen={modalState.mode === ModalMode.confirmAddressDeletion}
        isSubmitting={modalState.isSubmitting}
        handleClose={handleCancelModal}
        handleSubmit={handleSubmitRemoveAddress}
      />
      <RemoveUserConfirmation
        contact={modalState.userToDelete}
        isLoading={modalState.isLoading}
        isOpen={modalState.mode === ModalMode.confirmUserDeletion}
        isInvite={modalState.isInvite}
        isSubmitting={modalState.isSubmitting}
        handleClose={handleCancelModal}
        handleSubmit={handleSubmitRemoveUser}
      />
      {institute.data && userDetails && (
        <InstituteDetail
          isLoading={isLoading}
          isAdmin={isAdmin}
          userDetails={userDetails}
          institute={institute.data}
          pagination={pagination}
          activeContacts={activeContacts}
          pendingContacts={pendingContacts}
          supportContact={supportContact}
          selectedTabIndex={selectedTabIndex}
          onTabSelected={(tabIndex) => setSelectedTabIndex(tabIndex)}
          onPageClicked={handlePageClicked}
          onRequestNewUser={handleRequestNewUser}
          onEditUser={handleEditUser}
          onRemoveUser={handleRemoveUser}
          onPageSizeChange={handlePageSizeChange}
          onRemoveAddress={handleRemoveAddress}
          onRequestNewShippingAddress={handleRequestNewAddress}
          onRequestInstituteChange={handleRequestInstituteChange}
          onRequestInvite={handleResendInvite}
        />
      )}
    </>
  )
}
