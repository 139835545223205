import React, { FunctionComponent } from 'react'
import { ClinText } from '../../components/ClinText'
import { OrderAddressDto } from '../../types/swaggerTypes'
import { ClinTheme } from '../../ClinTheme'

interface IDeliveryAddressProps {
  /** Selected address */
  address: OrderAddressDto
  /** Option to show in 1 line if lots of them to display */
  isCompact?: boolean
  fontSize?: number
}

// TODO: Break this out into its own component with stories and refactor into other pages where required
export const DeliveryAddress: FunctionComponent<IDeliveryAddressProps> = ({
  address,
  isCompact,
  fontSize
}) => (
  <ClinText
    as="div"
    fontWeight={ClinTheme.fontWeights.medium}
    fontSize={fontSize ? fontSize : ClinTheme.fontSizes[1]}
  >
    {address.address1 && (
      <>
        {address.address1}
        {isCompact ? ', ' : <br />}
      </>
    )}
    {address.address2 && (
      <>
        {address.address2}
        {isCompact ? ', ' : <br />}
      </>
    )}
    {address.address3 && (
      <>
        {address.address3}
        {isCompact ? ', ' : <br />}
      </>
    )}
    {address.address4 && (
      <>
        {address.address4}
        {isCompact ? ', ' : <br />}
      </>
    )}
    {address.city && (
      <>
        {address.city}
        {isCompact ? ', ' : <br />}
      </>
    )}
    {address.country && (
      <>
        {address.country}
        {isCompact ? ', ' : <br />}
      </>
    )}
    {address.postalCode && (
      <>
        {address.postalCode}
        {isCompact ? ' ' : <br />}
      </>
    )}
    {address.state && <>{address.state}</>}
  </ClinText>
)
