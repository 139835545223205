import React, { FunctionComponent, useState } from 'react'
import {
  IDocumentUploadStyleProps,
  StyledButtonArea,
  StyledDocumentIcon,
  StyledFileAndIcon,
  StyledFileName,
  StyledFileRow,
  StyledFileRowWrap,
  StyledUploadDetailsDot,
  StyledUploadDetails,
  StyledUploadDetailsDate
} from './DocumentUpload.styles'
import { ClinSpinner } from '../../components/ClinSpinner'
import { ClinButton } from '../../components/ClinButton'
import { ClinText } from '../../components/ClinText'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinTheme } from '../../ClinTheme'
import { ClinCenterTruncatedText } from '../../components/ClinCenterTruncatedText'
import { BasketItemDocumentDto, OrgAddressDto } from '../../types/swaggerTypes'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { ClinButtonIcon } from '../../components/ClinButtonIcon'
import { IViewBasketItem } from '../../types'

export interface IUploadedDetails {
  /** Name of the person who uploaded the file */
  uploadedBy?: string
  /** Date the file was uploaded */
  uploadDate?: string
}

export interface DocumentUploadPromptProps {
  /** Title prompt describing the file that should be uploaded*/
  prompt?: string
  /** More detailed information on the file that should be uploaded */
  documentPrompt?: any
  /** Optional warehouse address */
  warehouseAddress?: OrgAddressDto
  /** Valid file extensions */
  accept?: string
  /** Name of the selected file */
  fileName?: string | null
  /** Show required flag */
  required?: boolean
  /** Render visually different version */
  isPreOrderVariant?: boolean
}

interface DocumentUploadItemProps
  extends IDocumentUploadStyleProps,
    DocumentUploadPromptProps {
  // TODO: This needs cleaning up - technical debt - we should be able to compose stuff into here
  /** custom style for AUS */
  styleForAUS?: boolean
  /** An error message for the file */
  error?: string
  basketItem?: IViewBasketItem
  item: BasketItemDocumentDto
  /** Called when a file is selected */
  handleFileSelected?: (file: File) => void
  /** Called when the user wants to remove uploaded file */
  handleClickRemove?: (item: BasketItemDocumentDto) => void
}

export const DocumentUploadItem: FunctionComponent<DocumentUploadItemProps> = ({
  documentPrompt,
  error,
  isPreOrderVariant,
  warehouseAddress,
  styleForAUS,
  handleFileSelected,
  handleClickRemove,
  accept = '.docx,.doc,.pdf,.jpg,.jpeg',
  required,
  basketItem,
  item,
  ...props
}) => {
  const { t } = useTranslation()

  const [isRemoving, setIsRemoving] = useState<boolean>(false)

  const uploadDetails = item.uploadedDocument
    ? {
        uploadDate: item.uploadedDocument.uploadedOn,
        uploadedBy: t('basket:you')
      }
    : undefined

  return (
    <StyledFileRowWrap style={{ marginTop: '17px' }}>
      <div>
        <StyledFileRow
          isPreOrderVariant={isPreOrderVariant}
          styleForAUS={styleForAUS}
        >
          <StyledFileAndIcon>
            <StyledDocumentIcon
              iconName={ClinIconPathName.Document}
              iconFill={ClinTheme.colors.primary}
            />
            {
              <StyledFileName>
                <ClinText
                  fontWeight={ClinTheme.fontWeights.bold}
                  color={ClinTheme.colors.primary}
                >
                  <ClinCenterTruncatedText
                    text={item.uploadedDocument.fileName}
                  />
                </ClinText>
              </StyledFileName>
            }
          </StyledFileAndIcon>
        </StyledFileRow>
        {uploadDetails && (
          <StyledUploadDetails>
            <ClinText as="span" marginLeft={30}>
              {t('common:document_upload.uploaded_by', {
                name: uploadDetails.uploadedBy
              })}
            </ClinText>
            <StyledUploadDetailsDot>.</StyledUploadDetailsDot>
            <StyledUploadDetailsDate>
              {uploadDetails.uploadDate &&
                DateTime.fromISO(uploadDetails.uploadDate).toLocaleString()}
            </StyledUploadDetailsDate>
          </StyledUploadDetails>
        )}
      </div>
      <StyledButtonArea styleForAUS={styleForAUS}>
        {!isRemoving && (
          <ClinButton
            as="span"
            onClick={() => {
              setIsRemoving(true)
              handleClickRemove && handleClickRemove(item)
            }}
            variant="linkButton"
          >
            <ClinButtonIcon
              iconName={ClinIconPathName.Cross}
              iconFill={ClinTheme.colors.primaryLight}
            />
          </ClinButton>
        )}
        {isRemoving && (
          <ClinSpinner
            size={ClinTheme.space[4]}
            fillColor={ClinTheme.colors.primary}
          />
        )}
      </StyledButtonArea>
    </StyledFileRowWrap>
  )
}
