// 1. Array of holds from pricing and availability - get name of document from separate lookup - HoldsService
// 2. Merge Basket
import {
  DrugProperty,
  drugPropertyTitleAndIconRecord
} from '../../services/DrugPropertyProvider'
import { StyledDrugDescription, StyledDrugDetail } from './Basket.styles'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import React from 'react'
import { ClinAnnounceBar } from '../../components/ClinAnnounceBar'
import { IViewBasketItem } from '../../context/basket'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { UIVariationState } from '../../services/PortfolioJourneys/PortfolioJourney.types'
import { Link } from 'react-router-dom'
import { IQuantityError } from './BasketContainer'
import { TFunction } from 'i18next'

/**
 * Get a drug detail icon and name view component
 * @param drugProperty
 * @param value
 * @param translateFn
 */
export const getDrugPropertyItem = (
  drugProperty: DrugProperty,
  value: string = 'CellValue',
  translateFn: TFunction
) => {
  const { iconName, translationKey } =
    drugPropertyTitleAndIconRecord[drugProperty]
  return (
    <StyledDrugDetail>
      <ClinIcon iconName={iconName} iconSize={ClinTheme.space[5]} />
      <StyledDrugDescription>
        <ClinText
          variant={TypographyVariant.H5}
          fontWeight={ClinTheme.fontWeights.medium}
          color={ClinTheme.colors.primary}
          marginTop={ClinTheme.space[1]}
        >
          {translateFn(translationKey)}
        </ClinText>
        <ClinText>{value}</ClinText>
      </StyledDrugDescription>
    </StyledDrugDetail>
  )
}

/**
 * Get the correct message to display in basket for products that aren't available
 * @param basketItem
 */
export const getVariantAnnounce = (
  basketItem: IViewBasketItem,
  t: TFunction
) => {
  switch (basketItem.uiVariantState) {
    case UIVariationState.SupplyDisruptionRegisterInterest:
      return (
        <ClinAnnounceBar
          mode={AnnounceMode.Error}
          marginBottom={ClinTheme.space[4]}
          title={' '}
        >
          <strong>
            {t('basket:supply_disruption_register_interest.part_1')}
            &nbsp;
            <Link
              to={`../../../product/sku/${basketItem.sku}`}
              style={{
                color: ClinTheme.colors.redInvalid,
                textDecoration: 'underline'
              }}
            >
              {t('basket:supply_disruption_register_interest.link')}
            </Link>
            {t('basket:supply_disruption_register_interest.part_2')}
          </strong>
        </ClinAnnounceBar>
      )
    case UIVariationState.AvailableOnRequestOnRequest:
    case UIVariationState.AvailableOnRequestRegisterInterest:
      return (
        <ClinAnnounceBar
          mode={AnnounceMode.Error}
          marginBottom={ClinTheme.space[4]}
          title={' '}
        >
          <strong>
            {t('basket:available_on_request_register_interest.part_1')}
            &nbsp;
            <Link
              to={`../../../product/sku/${basketItem.sku}`}
              style={{
                color: ClinTheme.colors.redInvalid,
                textDecoration: 'underline'
              }}
            >
              {t('basket:available_on_request_register_interest.link')}
            </Link>
            {t('basket:available_on_request_register_interest.part_2')}
          </strong>
        </ClinAnnounceBar>
      )
    case UIVariationState.AvailableDistributor:
      return (
        <ClinAnnounceBar
          mode={AnnounceMode.Error}
          marginBottom={ClinTheme.space[4]}
          title={' '}
        >
          <strong>
            {t('basket:available_distributor.part_1')}&nbsp;
            <Link
              to={`../../../product/sku/${basketItem.sku}`}
              style={{
                color: ClinTheme.colors.redInvalid,
                textDecoration: 'underline'
              }}
            >
              {t('basket:available_distributor.link')}
            </Link>
            &nbsp;
            {t('basket:available_distributor.part_2')}
          </strong>
        </ClinAnnounceBar>
      )
    case UIVariationState.GloballyUnavailable:
    default:
      return (
        <ClinAnnounceBar
          mode={AnnounceMode.Error}
          marginBottom={ClinTheme.space[4]}
          title={' '}
        >
          <strong>{t('basket:globally_unavailable.part_1')}</strong>
        </ClinAnnounceBar>
      )
  }
}

/**
 * Get the error for the basket item
 * @param basketItemId
 * @param quantityErrors
 */
export const getErrorForBasketItemId = (
  basketItemId: string,
  quantityErrors?: IQuantityError[]
): string | undefined => {
  return (
    quantityErrors &&
    quantityErrors.find((err) => err.basketItemId === basketItemId)?.message
  )
}

export const freightChargeDisclaimer = {
  amount: 250,
  charges: 20
}
