import {
  ApplicationInsights,
  ITelemetryItem
} from '@microsoft/applicationinsights-web'
import { AnalyticsEvent } from './AnalyticsEvent'
import authService from '../AuthService'
import AnalyticsService, { IAnalyticsProvider } from './AnalyticsService'

export class ApplicationInsightsProvider
  extends AnalyticsService
  implements IAnalyticsProvider
{
  private appInsights: ApplicationInsights | undefined

  initProvider = () => {
    this.logEvent(`ApplicationInsightsProvider initProvider`)
    const config: any = {
      disableFetchTracking: false,
      enableDebug: this.enableDebugLogging,
      enableAutoRouteTracking: true,
      disableTelemetry: false
    }

    if (this.tokenId.includes('InstrumentationKey=')) {
      config.connectionString = this.tokenId
    } else {
      config.instrumentationKey = this.tokenId
    }

    this.appInsights = new ApplicationInsights({ config })

    try {
      this.appInsights.loadAppInsights()
      this.appInsights.addTelemetryInitializer(this.addAuthUserContext)
      this.appInsights.trackPageView()
      this.setupGlobalErrorHandling()
    } catch (error) {
      console.error('Failed to initialize Application Insights:', error)
    }
  }

  private addAuthUserContext = (envelope: ITelemetryItem) => {
    authService.getUserAsync().then((user) => {
      if (user && this.appInsights) {
        this.appInsights.setAuthenticatedUserContext(
          user.profile.sub,
          undefined,
          true
        )
      }
    })
    return true
  }

  private setupGlobalErrorHandling = () => {
    if (!this.appInsights) return

    // Capture unhandled promise rejections
    window.addEventListener('unhandledrejection', (event) => {
      this.trackError(event.reason)
    })

    // Capture all other unhandled errors
    window.onerror = (message, url, lineNumber, columnNumber, error) => {
      this.trackError(error || new Error(message as string))
      return false // Let the default handler run
    }
  }

  trackError = (error: Error, properties: Record<string, any> = {}) => {
    this.logEvent(`ApplicationInsightsProvider trackError ${error}`)
    this.appInsights?.trackException({
      exception: error,
      properties: { ...properties, date: new Date().toISOString() }
    })
  }

  trackEvent = (
    event: AnalyticsEvent,
    properties: Record<string, any> = {}
  ) => {
    this.logEvent(`ApplicationInsightsProvider trackEvent ${event}`)
    this.appInsights?.trackEvent({
      name: event,
      properties: { ...properties, date: new Date().toISOString() }
    })
  }

  trackPageView = (path: string) => {
    this.logEvent(`ApplicationInsightsProvider trackPageView ${path}`)
    this.appInsights?.trackPageView({ name: path })
  }
}
