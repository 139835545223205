import { useEffect, useState } from 'react'

/**
 * Utility listen changes of screen size
 * @param listener
 */
export const useLayoutResize = <T>(getter: () => T) => {
  const [value, setValue] = useState<T>(getter())

  useEffect(() => {
    let listener = () => setValue(getter())
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [getter])

  return value
}
