import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery, mediaQueryDown } from '../../utils/mediaQuery'
import { ClinGroup } from '../../components/ClinGroup'
import { StyledClinSpinnerSvg } from '../../components/ClinSpinner/ClinSpinner.styles'

const tabletUpBreakpoint = ClinTheme.breakpoints[1]

export interface IStyledDrugPropertiesContainerProps {
  unavailable?: boolean
}

export const StyledBasketItem = styled.div({
  position: 'relative',
  backgroundColor: ClinTheme.colors.white,
  padding: ClinTheme.space[5],
  marginBottom: ClinTheme.space[4],
  [`.clin-button-group`]: {
    flexWrap: 'wrap',
    [mediaQuery(tabletUpBreakpoint)]: {
      flexWrap: 'nowrap'
    }
  },
  [`.quantity-selector`]: {
    [mediaQuery(tabletUpBreakpoint)]: {
      width: '270px'
    }
  }
})

export const StyledSummary = styled.div({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: ClinTheme.colors.white,
  padding: ClinTheme.space[3],
  [mediaQuery(tabletUpBreakpoint)]: {
    marginTop: ClinTheme.space[5],
    padding: ClinTheme.space[5]
  }
})

export const StyledSummaryOneStepCheckout = styled.div({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: ClinTheme.colors.white,
  padding: ClinTheme.space[4],
  [mediaQueryDown(ClinTheme.breakpoints[2])]: {
    marginTop: ClinTheme.space[5],
    padding: ClinTheme.space[5]
  }
})

export const StyledSummaryTitle = styled.div({})

export const StyledDrugPropertiesContainer =
  styled.div<IStyledDrugPropertiesContainerProps>(
    {
      display: 'flex',
      flexWrap: 'wrap'
    },
    ({ unavailable = false }: IStyledDrugPropertiesContainerProps) => ({
      opacity: unavailable ? 0.3 : 1
    })
  )

export const StyledDrugDetail = styled.div({
  display: 'inline-flex',
  flex: '1 0 100%',
  marginBottom: ClinTheme.space[3],
  [mediaQuery(tabletUpBreakpoint)]: {
    flex: '1 0 50%'
  }
})

export const StyledDrugDescription = styled.div({
  marginLeft: ClinTheme.space[2]
})

export const StyledQuantitySelectorContainer =
  styled.div<IStyledDrugPropertiesContainerProps>(
    {
      display: 'block',
      [`${StyledClinSpinnerSvg}`]: {
        marginRight: 'auto',
        marginTop: ClinTheme.space[3],
        marginLeft: ClinTheme.space[2]
      },
      [mediaQuery(tabletUpBreakpoint)]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }
    },
    ({ unavailable = false }: IStyledDrugPropertiesContainerProps) => ({
      opacity: unavailable ? 0.3 : 1
    })
  )

export const StyledDeleteButton = styled(ClinGroup)({
  position: 'absolute',
  right: ClinTheme.space[5],
  top: 44,
  width: 'auto',
  display: 'none',
  [mediaQuery(tabletUpBreakpoint)]: {
    display: 'block'
  }
})

export const StyledBasketHoldItem = styled.div({
  backgroundColor: ClinTheme.colors.lightGrey,
  borderTop: '1px solid ' + ClinTheme.colors.grey,
  padding: `${ClinTheme.space[4]}px ${ClinTheme.space[3]}px`,
  [`:last-child`]: {
    borderBottom: '1px solid ' + ClinTheme.colors.grey
  },
  [`.sku-information, .warehouse-address`]: {
    flexBasis: '100%'
  }
})

export const StyledBasketHoldItemForAUS = styled.div({
  backgroundColor: ClinTheme.colors.lightPurple,
  borderRadius: '24px',
  padding: `${ClinTheme.space[4]}px ${ClinTheme.space[5]}px`,
  [`.sku-information, .warehouse-address`]: {
    flexBasis: '100%'
  }
})

export const StyledMobileDelete = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: `${ClinTheme.space[4]}px 0`,
  marginBottom: ClinTheme.space[3],
  borderBottom: '1px solid ' + ClinTheme.colors.grey
})

export const StyledAmountContainer = styled.div({
  textAlign: 'right'
})

export const StyledWrapper = styled.div({
  paddingTop: `${ClinTheme.space[4]}px`
})

export const StyledSummaryWrap = styled.div({
  marginBottom: ClinTheme.space[4]
})

export const StyledSummaryItemWrap = styled.div({
  maxWidth: '50%'
})
