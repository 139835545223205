import React, { ChangeEvent, FunctionComponent, useEffect } from 'react'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import { Col, Row } from 'react-grid-system'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinTheme } from '../../../ClinTheme'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinButton } from '../../../components/ClinButton'
import { ClinTextarea } from '../../../components/ClinTextarea'
import { object, string } from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { ClinGroup } from '../../../components/ClinGroup'
import { Prompt } from '../../../features/Prompt'
import { truncateString } from '../../../utils/truncateString'
import { ProgramCatalogDto } from '../../../types/swaggerTypes'
import {
  StyledSmallSpinnerContainer,
  StyledSpinnerContainer
} from './ProgramRegisterInterest.styles'
import { useTranslation } from 'react-i18next'
import {
  getInputValidation,
  ValidationType
} from '../../../utils/Forms/getInputValidation'

interface IProgramRegisterInterestProps {
  /** Whether we are loading or not */
  isLoading: boolean
  /** displays error notification **/
  hasError?: boolean
  /** The provided program catalogue dto */
  program?: ProgramCatalogDto
  /** Whether the submission API request is in progress */
  isSubmitting?: boolean
  /** Whether the form has been submitted */
  isSubmitted?: boolean
  /** Decides whether the page is edited or not */
  isPageEdited: boolean
  /** User country*/
  userCountry?: string
  /** Handle cancel click */
  handleCancel?: (event: React.MouseEvent) => void
  /** Handle submit click and pass through reason */
  handleFormSubmission?: (formValues: IRegisterInterestFormState) => void
  /** Handle whether the page is edited or not */
  handleIsPageEdited: (isEdited: boolean) => void
}

export interface IRegisterInterestFormState {
  reason: string
}

export const ProgramRegisterInterest: FunctionComponent<
  IProgramRegisterInterestProps
> = ({
  isLoading,
  hasError,
  program,
  isSubmitting,
  isSubmitted,
  isPageEdited,
  userCountry,
  handleCancel,
  handleFormSubmission,
  handleIsPageEdited
}) => {
  const { t } = useTranslation()
  const registerInterestSchema = object().shape({
    reason: string()
      .min(1, getInputValidation(ValidationType.MinimumCharacters, 1))
      .max(250, getInputValidation(ValidationType.MaxiumumCharacters, 250))
      .required(getInputValidation(ValidationType.RequiredField))
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    formState
  } = useForm<IRegisterInterestFormState>({
    mode: 'onChange',
    resolver: yupResolver(registerInterestSchema),
    defaultValues: { reason: '' }
  })

  useEffect(() => {
    handleIsPageEdited(Object.keys(formState.dirtyFields).length > 0)
  }, [formState, handleIsPageEdited])

  return isLoading ? (
    <>
      {!hasError && (
        <ClinPageContentFrame>
          <Row justify="center">
            <Col width="auto">
              <StyledSpinnerContainer>
                <ClinSpinner size={ClinTheme.space[7]} />
              </StyledSpinnerContainer>
            </Col>
          </Row>
        </ClinPageContentFrame>
      )}
    </>
  ) : (
    <ClinPageContentFrame
      crumbs={[
        { path: '/orders', name: t('orders:page_name') },
        { name: t('access_programs:title'), path: '/programs/access-programs' },
        {
          name: program?.programName
            ? truncateString(program?.programName)
            : '',
          path: `/programs/access-programs/${program?.program?.projectId}`
        },
        {
          name: t('program_register_interest:title'),
          path: ''
        }
      ]}
    >
      <Row>
        <Col sm={12}>
          <ClinText
            as="h1"
            variant={TypographyVariant.H2}
            fontWeight={ClinTheme.fontWeights.bold}
          >
            {t('program_register_interest:register_interest', {
              programName: program?.programName
            })}
          </ClinText>
        </Col>
      </Row>
      {isPageEdited && !isSubmitted && <Prompt />}

      <form
        noValidate={true}
        onSubmit={handleFormSubmission && handleSubmit(handleFormSubmission)}
      >
        <Row>
          <Col xs={12} sm={8}>
            <ClinText
              variant={TypographyVariant.Paragraph}
              marginBottom={ClinTheme.space[4]}
            >
              {t('program_register_interest:description', {
                programName: program?.programName
              })}
            </ClinText>
            <ClinText
              variant={TypographyVariant.H4}
              marginBottom={ClinTheme.space[4]}
            >
              {t('program_register_interest:reason_for_interest_label')}
            </ClinText>
            <Controller
              name="reason"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <ClinTextarea
                    id="reason"
                    label={t('program_register_interest:enter_details')}
                    onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                      onChange(event)
                    }
                    height={160}
                    value={value}
                    hasError={!!(errors && errors.reason)}
                    prompt={errors.reason?.message}
                  />
                </>
              )}
            />
            <ClinText>
              {t('program_register_interest:do_not_add_personal_info')}
            </ClinText>
          </Col>
        </Row>
        <ClinSpacer height={ClinTheme.space[4]} />
        <Row>
          <Col sm={12}>
            <ClinGroup alignItems={'center'}>
              <ClinButton onClick={handleCancel}>
                {t('common:buttons.cancel')}
              </ClinButton>
              <ClinButton
                type="submit"
                variant="primary"
                disabled={isSubmitting || !isPageEdited}
              >
                {t('common:buttons.submit')}
              </ClinButton>
              {isSubmitting && (
                <StyledSmallSpinnerContainer>
                  <ClinSpinner />
                </StyledSmallSpinnerContainer>
              )}
            </ClinGroup>
          </Col>
        </Row>
      </form>
    </ClinPageContentFrame>
  )
}
