interface StyledVerticalLineProps {
  height: string
  width: string
  backgroundColor: string
}

export const StyledVerticalLine: React.FC<StyledVerticalLineProps> = ({
  height,
  width,
  backgroundColor
}) => (
  <div
    style={{
      height: height,
      width: width,
      backgroundColor: backgroundColor
    }}
  ></div>
)
