import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinPageContentFrame } from '../../components/ClinPageContentFrame'
import {
  HoldDto,
  OrderDto,
  OrderLineDto,
  ProgramCatalogDto,
  UploadedDocumentDto
} from '../../types/swaggerTypes'
import {
  StyledOpaPdfWrapper,
  StyledOrderSummaryHeading,
  StyledOrderSummaryIcon
} from './OpaOrderSummary.styles'
import {
  getOrderItems,
  getTotalCharges
} from '../OpaCheckout/OpaCheckout.models'
import { ClinButton } from '../../components/ClinButton'
import { MaOrderPrice } from '../../features/MaOrderPrice'
import { OrderItemType } from '../../features/OrderItems/OrderItem'
import { IHoldDetails } from '../../services/Holds/holds.types'
import { useTranslation } from 'react-i18next'
import { ClinThreeBoxes } from '../../components/ClinThreeBoxes'
import { OpaDeliveryDetails } from '../../features/OpaDeliveryDetails/OpaDeliveryDetails'
import {
  StyledProductCell,
  StyledProductHeader
} from '../Products/ProductVariant/ProductVariant.styles'
import { OpaOrderItems } from '../../features/OpaOrderItems/OpaOrderItems'

interface IOpaOrderSummaryProps {
  /** Whether order is loading or not  */
  isLoading: boolean
  /** Is equal false if at least one order line has a status equal "Shipped" or "Cancelled" */
  isOrderCancellable: boolean | undefined
  /** Order details */
  order: OrderDto | undefined
  /** Program details */
  program: ProgramCatalogDto | undefined
  /** Provides order level holds to display above the order */
  orderLevelHolds?: IHoldDetails[]
  /** patientAccessFormUrl */
  patientAccessFormUrl: string | undefined
  deliveryNote: string
  institute: string | undefined
  /** Handle click on "Completed Form" button */
  handlePatientAccessForm: () => void
  /** Handle click on "Cnacel Order" button */
  handleCancelOrder: () => void
  /** Handle click on "Place Order Again" button */
  handlePlaceOrderAgain: () => void
  /** Handle click on "View Dashboard" button */
  handleViewDashboard: () => void
  /** Called when a file has been uploaded */
  handleFileUploadedForOrderAndHold?: (
    orderId: string,
    orderLine: OrderLineDto,
    hold: HoldDto,
    document: UploadedDocumentDto
  ) => void

  isMaOrder: boolean
  handlePrint: () => void
}

export const OpaOrderSummary: FunctionComponent<IOpaOrderSummaryProps> = ({
  isLoading,
  order,
  program,
  deliveryNote,
  institute,
  handleFileUploadedForOrderAndHold,
  handlePrint
}) => {
  const customerPoNumber = order?.customerPoNumber
  const charges = order ? getTotalCharges(order) : 0
  const poNumber = customerPoNumber
    ? customerPoNumber
    : order?.orderNumber
    ? order.orderNumber
    : 'N/A'
  const orderedItems: OrderLineDto[] = getOrderItems(order)
  const { t } = useTranslation()

  return (
    <ClinPageContentFrame isLoading={isLoading}>
      {order && program && (
        <>
          <Row>
            <Col>
              <StyledOrderSummaryHeading>
                <StyledOrderSummaryIcon>
                  <ClinIcon
                    iconName={ClinIconPathName.ArrowRight}
                    iconSize={ClinTheme.space[4]}
                  />
                </StyledOrderSummaryIcon>
                <ClinText
                  variant={TypographyVariant.H2}
                  as="span"
                  fontWeight={ClinTheme.fontWeights.bold}
                  marginLeft={ClinTheme.space[2]}
                >
                  {t('opa_order_summary:title')}
                </ClinText>
              </StyledOrderSummaryHeading>

              <StyledProductHeader className="productHeaderAus">
                <div>
                  <ClinText variant={TypographyVariant.H5} width="100%">
                    {t('opa_order_summary:order_reference_number')}
                  </ClinText>
                  {order && (
                    <ClinText variant={TypographyVariant.H3}>
                      {order.orderNumber}
                    </ClinText>
                  )}
                </div>
                <StyledProductCell justifyContent={'space-between'}>
                  <ClinButton
                    variant="linkButton"
                    onClick={() => handlePrint && handlePrint()}
                  >
                    <span style={{ marginRight: ClinTheme.space[2] }}>
                      {t('common:download_pdf')}
                    </span>{' '}
                    <ClinIcon iconName={ClinIconPathName.Download} />
                  </ClinButton>
                </StyledProductCell>
              </StyledProductHeader>
            </Col>
          </Row>

          <ClinThreeBoxes
            moduleTitle={t('checkout_summary:what_happens_next')}
            firstBoxTitle={t('opa_order_summary:medical_review_title')}
            firstBoxContent={'opa_order_summary:medical_review_description'}
            firstBoxIcon={ClinIconPathName.Document}
            secondBoxTitle={t('opa_order_summary:regulatory_approval_title')}
            secondBoxContent={
              'opa_order_summary:regulatory_approval_description'
            }
            secondBoxIcon={ClinIconPathName.RegulatoryApproval}
            thirdBoxTitle={t('opa_order_summary:order_shipment_title')}
            thirdBoxContent={'opa_order_summary:order_shipment_description'}
            thirdBoxIcon={ClinIconPathName.OrderBox}
            optionalURL={order.orderNumber}
            isMaOrder={true}
            viewBox="0 0 80 80"
          ></ClinThreeBoxes>

          <StyledOpaPdfWrapper>
            <Row>
              <Col sm={12} md={6}>
                <OpaDeliveryDetails
                  deliverTo={order.deliverTo}
                  deliveryNote={deliveryNote}
                  deliverToContact={order.deliverToContact}
                  shipToContact={order.deliverToContact}
                  poNumber={poNumber}
                  deliveryMethod={'Custom'}
                  recipientEmail={order.recipientEmail}
                  recipientPhoneNumber={order.recipientPhoneNumber}
                  address1={order.billTo.address1}
                  city={order.billTo.city}
                  country={order.billTo.country}
                  customerPoNumber={order.customerPoNumber}
                  institute={institute}
                />
              </Col>
              <Col sm={12} md={6}>
                <MaOrderPrice
                  subtotal={order.totals.subTotal}
                  charges={charges}
                  nominalCharge={order.totals.charges}
                  tax={order.totals.tax}
                  total={order.totals.total}
                  currencyCode={order.currencyCode}
                />
              </Col>
            </Row>
          </StyledOpaPdfWrapper>

          <ClinSpacer height={ClinTheme.space[7]} />
          <OpaOrderItems
            orderId={order.orderNumber}
            orderLines={orderedItems}
            programSponsor={program?.sponsor}
            currencyCode={order?.currencyCode}
            itemType={OrderItemType.OrdinaryItem}
            handleFileUploadedForOrderAndHold={
              handleFileUploadedForOrderAndHold
            }
          />
          <ClinSpacer height={ClinTheme.space[3]} />
        </>
      )}
    </ClinPageContentFrame>
  )
}
