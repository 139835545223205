/**
 * Custom hook to run a callback when a number of dependencies match a condition
 * Prevents a re-render when deps change value - only triggered when the condition is met the first time
 *
 * @usage:
 *  type Deps = (CatalogDto | number)[]
 *   useEffectOnlyOnce(
 *     (dependencies: Deps) => {
 *       console.warn('I run if products and price exist.')
 *       console.log(dependencies[0])
 *       console.log(dependencies[1])
 *     },
 *     [product, unitPrice],
 *     (dependencies: Deps) => dependencies[0] && dependencies[1] > 0
 *   )
 */
import { useRef, useEffect } from 'react'

export const useEffectOnlyOnce = (
  callback: Function,
  dependencies: unknown[],
  condition: Function
) => {
  const calledOnce = useRef(false)
  useEffect(() => {
    if (calledOnce.current) {
      return
    }
    if (condition(dependencies)) {
      callback(dependencies)
      calledOnce.current = true
    }
  }, [callback, condition, dependencies])
}
