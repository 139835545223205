import { ClinIconPathName } from '../components/ClinIcon/ClinIcon.paths'

export enum DrugProperty {
  Brand = 'Brand',
  ProductCode = 'Product code',
  DosageAndPacksize = 'Dosage form and pack size',
  Availability = 'Availability',
  Delivery = 'Delivery',
  Strength = 'Strength',
  CountryOfLicense = 'Country of license',
  Overlabelled = 'Over-labelled',
  Storage = 'Storage',
  ControlledDrugStatus = 'Controlled drug status',
  Cytotoxicity = 'Cytotoxic',
  TherapeuticArea = 'Therapeutic area',
  LicenseNumber = 'License number',
  LicenseHolder = 'License holder',
  UnitPrice = 'Unit price',
  SupportingDocuments = 'Required supporting documents',
  Language = 'Language'
}

export interface IDrugTitleAndIcon {
  title: string
  iconName: ClinIconPathName
  translationKey: string
}

/**
 * Look up record/table for returning a drug property icon and title
 * @usage:
 *
 * const { iconName, title } = drugPropertyTitleAndIconRecord[drugProperty]
 *
 */
export const drugPropertyTitleAndIconRecord: Record<
  DrugProperty,
  IDrugTitleAndIcon
> = {
  [DrugProperty.Availability]: {
    title: DrugProperty.Availability.toString(),
    iconName: ClinIconPathName.Availability,
    translationKey: 'glossary:availability'
  },
  [DrugProperty.Brand]: {
    title: DrugProperty.Brand.toString(),
    iconName: ClinIconPathName.BrandName,
    translationKey: 'glossary:brand'
  },
  [DrugProperty.ControlledDrugStatus]: {
    title: DrugProperty.ControlledDrugStatus.toString(),
    iconName: ClinIconPathName.ExclamationOutline,
    translationKey: 'glossary:controlled_drug_status'
  },
  [DrugProperty.CountryOfLicense]: {
    title: DrugProperty.CountryOfLicense.toString(),
    iconName: ClinIconPathName.Marker,
    translationKey: 'glossary:country_of_license'
  },
  [DrugProperty.Cytotoxicity]: {
    title: DrugProperty.Cytotoxicity.toString(),
    iconName: ClinIconPathName.Cytotoxicity,
    translationKey: 'glossary:cytotoxicity'
  },
  [DrugProperty.Delivery]: {
    title: DrugProperty.Delivery.toString(),
    iconName: ClinIconPathName.OrderBox,
    translationKey: 'glossary:delivery'
  },
  [DrugProperty.LicenseHolder]: {
    title: DrugProperty.LicenseHolder.toString(),
    iconName: ClinIconPathName.Manufacturer,
    translationKey: 'glossary:license_holder'
  },
  [DrugProperty.LicenseNumber]: {
    title: DrugProperty.LicenseNumber.toString(),
    iconName: ClinIconPathName.LicenseNumber,
    translationKey: 'glossary:license_number'
  },
  [DrugProperty.Overlabelled]: {
    title: DrugProperty.Overlabelled.toString(),
    iconName: ClinIconPathName.Overlabelled,
    translationKey: 'glossary:overlabelled'
  },
  [DrugProperty.Language]: {
    title: DrugProperty.Overlabelled.toString(),
    iconName: ClinIconPathName.Overlabelled,
    translationKey: 'glossary:language'
  },
  [DrugProperty.DosageAndPacksize]: {
    title: DrugProperty.DosageAndPacksize.toString(),
    iconName: ClinIconPathName.PackSize,
    translationKey: 'glossary:dosage_and_packsize'
  },
  [DrugProperty.ProductCode]: {
    title: DrugProperty.ProductCode.toString(),
    iconName: ClinIconPathName.ProductCode,
    translationKey: 'glossary:product_code'
  },
  [DrugProperty.Storage]: {
    title: DrugProperty.Storage.toString(),
    iconName: ClinIconPathName.Storage,
    translationKey: 'glossary:storage'
  },
  [DrugProperty.Strength]: {
    title: DrugProperty.Strength.toString(),
    iconName: ClinIconPathName.StrengthAndDosage,
    translationKey: 'glossary:strength'
  },
  [DrugProperty.TherapeuticArea]: {
    title: DrugProperty.TherapeuticArea.toString(),
    iconName: ClinIconPathName.TherapeuticArea,
    translationKey: 'glossary:therapeutic_area'
  },
  [DrugProperty.UnitPrice]: {
    title: DrugProperty.UnitPrice.toString(),
    iconName: ClinIconPathName.UnitPrice,
    translationKey: 'glossary:unit_price'
  },
  [DrugProperty.SupportingDocuments]: {
    title: DrugProperty.SupportingDocuments.toString(),
    iconName: ClinIconPathName.Document,
    translationKey: 'glossary:supporting_documents'
  }
}
