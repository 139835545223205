import { AssociatedPatientsStatus } from '../../features/AssociatedPatients/AssociatedPatients.model'
import { PatientSummaryDto } from '../../types/swaggerTypes'

/**
 * Return true if all patients associated to the physician have status 'Discontinued' and all enrolled programs are closed
 * @param physician
 * @param patients
 */
export const isDiscontinueAvailable = (
  patients: PatientSummaryDto[]
): boolean => {
  let isAllPatientsDiscontinued =
    patients.map(
      (patient) =>
        patient.patientStatus === AssociatedPatientsStatus.ActiveTreatment
    ).length === 0
  return isAllPatientsDiscontinued
}

export enum ProgramColumnNames {
  ProgramName = 'programName',
  Sponsor = 'sponsor',
  TherapeuticArea = 'therapeuticArea',
  Status = 'status'
}

export type TProgramColumnNames =
  | ProgramColumnNames.ProgramName
  | ProgramColumnNames.Sponsor
  | ProgramColumnNames.TherapeuticArea
  | ProgramColumnNames.Status

export interface ProgramColumn {
  name: TProgramColumnNames
  viewName: string
  transcriptKey: string
}

export const programColumns: ProgramColumn[] = [
  {
    name: ProgramColumnNames.ProgramName,
    viewName: 'Generic Name',
    transcriptKey: 'glossary:generic_name'
  },
  {
    name: ProgramColumnNames.Sponsor,
    viewName: 'Sponsor',
    transcriptKey: 'glossary:sponsor'
  },
  {
    name: ProgramColumnNames.TherapeuticArea,
    viewName: 'Therapeutic Area',
    transcriptKey: 'glossary:therapeutic_area'
  },
  {
    name: ProgramColumnNames.Status,
    viewName: 'Status',
    transcriptKey: 'glossary:status'
  }
]

/**
 * Utility function to get a sort column back
 * @param searchParams
 * @param defaultColumn
 */
export const getSortColumnFromURLParams = (
  searchParams: URLSearchParams,
  defaultColumn: ProgramColumn
): ProgramColumn => {
  const sortColumn = searchParams.get('sortColumn')
  const matchSortColumn = programColumns.find((pc) => {
    return pc.name.toString() === sortColumn
  })
  return matchSortColumn ? matchSortColumn : defaultColumn
}
