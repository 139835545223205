import { CatalogDto } from '../../types/swaggerTypes'
import React, { FunctionComponent } from 'react'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { StyledLink } from '../../components/StyledLink'
import { ClinButton } from '../../components/ClinButton'
import { StyledReportAdverseEvent } from './RepportAdverseEventCTA.styles'
import { useTranslation } from 'react-i18next'

interface IReportAdverseEventCTAProps {
  /** Selected product */
  product: CatalogDto
}
export const ReportAdverseEventCTA: FunctionComponent<
  IReportAdverseEventCTAProps
> = ({ product }) => {
  const { t } = useTranslation()
  return (
    <StyledReportAdverseEvent>
      <ClinIcon
        iconName={ClinIconPathName.ExclamationOutline}
        iconFill={ClinTheme.colors.primary}
      />
      <ClinText variant={TypographyVariant.H4}>
        {t('product_detail:report_adverse_event')}
      </ClinText>
      <ClinText
        variant={TypographyVariant.LargeParagraph}
        marginBottom={ClinTheme.space[3]}
      >
        {t('product_detail:report_adverse_event_related_to_product', {
          interpolation: { escapeValue: false },
          productName: product.catalogItemName
        })}
      </ClinText>
      <StyledLink to={'/products/adverse-event'}>
        <ClinButton display="block">{t('common:buttons.report')}</ClinButton>
      </StyledLink>
    </StyledReportAdverseEvent>
  )
}
