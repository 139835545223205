import React from 'react'
import { State } from './NewFeaturesContext'
import { ActionType, ActionTypes } from './NewFeaturesContext.actions'
import { INewFeatureItem } from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'
import { newFeatureLookup } from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.config'

const newFeaturesReducer: React.Reducer<State, ActionTypes> = (
  state: State,
  action: ActionTypes
) => {
  const updateCancelledGroupFeatures = (
    feature: INewFeatureItem,
    updatedFeatures: INewFeatureItem[]
  ): INewFeatureItem[] => {
    const featureToClose = newFeatureLookup.find((x) => x.id === feature.id)
    if (featureToClose && featureToClose.groupId) {
      let groupFeatures = newFeatureLookup.filter(
        (x) => x.groupId === featureToClose.groupId
      )
      const mappedFeatures = updatedFeatures.map((feature) => {
        const existingFeature = groupFeatures.find((f) => f.id === feature.id)
        if (existingFeature) {
          return { id: existingFeature.id, isCancelled: true }
        } else {
          return { id: feature.id, isCancelled: feature.isCancelled }
        }
      })

      return mappedFeatures
    }
    return updatedFeatures
  }

  switch (action.type) {
    case ActionType.SetNewFeatures: {
      const { features } = action
      return {
        ...state,
        features: [...features]
      }
    }
    case ActionType.UpdateNewFeatures: {
      const { feature } = action
      let updatedFeatures = state.features.filter(
        (element) => element.id !== feature.id
      )
      if (feature.isCancelled && updatedFeatures && updatedFeatures.length) {
        updatedFeatures = updateCancelledGroupFeatures(feature, updatedFeatures)
      }

      return {
        ...state,
        features: [...updatedFeatures]
      }
    }
    case ActionType.RemoveFeature: {
      const { featureId } = action
      return {
        ...state,
        features: state.features.filter((feature) => feature.id !== featureId)
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${(action as any).type}`)
    }
  }
}

export { newFeaturesReducer }
