import React, { FunctionComponent } from 'react'
import { ClinText } from '../../components/ClinText'
import { ClinSpacer } from '../../components/ClinSpacer'
import {
  StyledLearnMoreButton,
  StyledRealWorldDataCard,
  StyledRealWorldDataCardContent,
  StyledOverlayText,
  StyledEnrolledIndicator,
  StyledRealWorldDataCardImage,
  StyledNomineeDetails,
  StyledLearnMoreLink
} from './RealWorldDataCardPatientDetails.styles'
import { ClinTheme } from '../../ClinTheme'
import { useTranslation } from 'react-i18next'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { PatientDetailsDto } from '../../types/swaggerTypes'

interface RealWorldDataCardPatientDetailsProps {
  patient?: PatientDetailsDto
}

const RealWorldDataCardPatientDetails: FunctionComponent<
  RealWorldDataCardPatientDetailsProps
> = ({ patient = null }) => {
  const { t } = useTranslation()
  const buttonId = 'learn-more-rwd-button'

  const isEnrolled = patient?.realWorldDataEnrolFlag === 'Y'

  const handleLearnMoreClick = () => {
    document.getElementById(buttonId)?.blur()
    window.open(`/programs/access-programs/real-world-data`, '_blank')
  }

  const capitalize = (str: string) => {
    if (!str) return str
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  const displayNomineeDetails = () => {
    return (
      patient &&
      patient?.rwdNominationDetails !== null &&
      (patient?.rwdNominationDetails?.classification !== '' ||
        patient?.rwdNominationDetails?.emailAddress !== '' ||
        patient?.rwdNominationDetails?.fullName !== '')
    )
  }

  const hasButtons = isEnrolled || !isEnrolled // Update this condition based on actual logic for determining button presence

  return (
    <StyledRealWorldDataCard>
      <StyledRealWorldDataCardImage isEnrolled={isEnrolled}>
        <StyledOverlayText
          onClick={hasButtons ? undefined : handleLearnMoreClick}
          isClickable={!hasButtons}
        >
          <ClinText>{t('access_programs:rwd_button_titile')}</ClinText>
        </StyledOverlayText>
      </StyledRealWorldDataCardImage>
      <StyledRealWorldDataCardContent>
        {!isEnrolled && (
          <ClinText
            fontSize={ClinTheme.fontSizes[2]}
            fontWeight={ClinTheme.fontWeights.bold}
            textAlign="center"
          >
            {t('access_programs:card_title')}
          </ClinText>
        )}
        {isEnrolled && (
          <>
            <ClinText>
              {t('access_programs:enroll_info_text')}
              <br></br>
              <StyledLearnMoreLink onClick={handleLearnMoreClick}>
                {t('access_programs:enroll_url_text')}
              </StyledLearnMoreLink>
            </ClinText>
            <ClinSpacer height={ClinTheme.space[3]} />
          </>
        )}
        {isEnrolled && (
          <StyledEnrolledIndicator>
            <ClinIcon
              iconName={ClinIconPathName.CheckFilled}
              iconSize={18}
              viewBox="0 0 20 20"
              iconHeight={20}
            ></ClinIcon>
            <ClinText>{t('access_programs:enroll_title')}</ClinText>
          </StyledEnrolledIndicator>
        )}
        <ClinSpacer height={ClinTheme.space[3]} />
        {displayNomineeDetails() && (
          <StyledNomineeDetails>
            <ClinText
              color={ClinTheme.colors.primaryDark}
              fontWeight={ClinTheme.fontWeights.bold}
            >
              {t('access_programs:nominee_details')}
            </ClinText>
            <ClinSpacer height={ClinTheme.space[1]} />
            <ClinText>{patient?.rwdNominationDetails?.fullName}</ClinText>
            <ClinText>
              {capitalize(patient?.rwdNominationDetails?.classification || '')}
            </ClinText>
            <ClinText>{patient?.rwdNominationDetails?.emailAddress}</ClinText>
          </StyledNomineeDetails>
        )}
        {!isEnrolled && (
          <>
            <ClinText
              color={ClinTheme.colors.darkGrey}
              fontSize={ClinTheme.fontSizes[0]}
              fontWeight={ClinTheme.fontWeights.medium}
              textTransform="uppercase"
            >
              {t('access_programs:time_to_read')}
            </ClinText>
            <ClinSpacer height={ClinTheme.space[3]} />
          </>
        )}
        {!isEnrolled && (
          <>
            <StyledLearnMoreButton id={buttonId} onClick={handleLearnMoreClick}>
              {t('access_programs:learn_more')}
            </StyledLearnMoreButton>
            <ClinSpacer height={ClinTheme.space[3]} />
          </>
        )}
      </StyledRealWorldDataCardContent>
    </StyledRealWorldDataCard>
  )
}

export default RealWorldDataCardPatientDetails
