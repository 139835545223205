import React, { FunctionComponent } from 'react'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinTheme } from '../../ClinTheme'
import { ClinLogo } from '../../components/ClinLogo'
import { Col, Container, Row } from 'react-grid-system'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { ClinGroup } from '../../components/ClinGroup'
import { ClinButton } from '../../components/ClinButton'
import { IBootstrapError } from '../../types/IBootstrapError'
import { StyledDebugCode } from './BootstrapSplash.styles'
import { CountryDto } from '../../types/swaggerTypes'
import { useTranslation, Trans } from 'react-i18next'

interface ISplashProps {
  /** The error which caused this **/
  bootstrapError: IBootstrapError
  /** Customer support details **/
  supportContact: CountryDto
  /** Try again button */
  handleTryAgain?: () => void
  /** Try again button */
  handleLogOut?: () => void
}

export const ErrorSplash: FunctionComponent<ISplashProps> = ({
  bootstrapError,
  supportContact,
  handleTryAgain,
  handleLogOut
}) => {
  const { t } = useTranslation()

  return (
    <Container fluid={false}>
      <ClinSpacer height={ClinTheme.space[7]} />
      <ClinLogo />
      <ClinSpacer height={ClinTheme.space[7]} />
      <Row align="center" justify="center">
        <Col xs={12} md={10} lg={5}>
          <ClinText
            as="h1"
            fontWeight={ClinTheme.fontWeights.bold}
            variant={TypographyVariant.H2}
            color={ClinTheme.colors.black}
          >
            {t('bootstrap_error:title')}
          </ClinText>
          <ClinText>
            <Trans
              i18nKey="bootstrap_error:description"
              components={{ bold: <strong /> }}
            >
              <></>
            </Trans>
          </ClinText>
          <ClinSpacer height={ClinTheme.space[3]} />
          {supportContact ? (
            <ClinButton
              variant="linkButton"
              as="a"
              target="_blank"
              href={`https://www.clinigengroup.com/direct/en/contact-us/`}
              className={'link-contact'}
              style={{ height: 'unset', color: ClinTheme.colors.tealishBlue }}
            >
              {t('navigation:contact_us')}
            </ClinButton>
          ) : null}
          <ClinSpacer height={ClinTheme.space[4]} />
          <ClinGroup justifyContent="flex-start" space={ClinTheme.space[2]}>
            <ClinButton
              variant="primary"
              onClick={() => handleTryAgain && handleTryAgain()}
            >
              Try again
            </ClinButton>
            <ClinButton onClick={() => handleLogOut && handleLogOut()}>
              Close
            </ClinButton>
          </ClinGroup>
          <ClinSpacer height={ClinTheme.space[3]} />
          {bootstrapError ? (
            <StyledDebugCode>{bootstrapError.description}</StyledDebugCode>
          ) : null}
        </Col>
      </Row>
    </Container>
  )
}
