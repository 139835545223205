import { ClinTheme } from '../ClinTheme/ClinTheme'

export interface IDefaultStyleProps {
  /** A css class */
  className?: string
}
const fontSizeOptions = ClinTheme.fontSizes

export interface ITypographyStyles {
  /** Css line-height value */
  lineHeight?: string | number
  /** Css letter-spacing value */
  letterSpacing?: string | number
  /** Css font-weight value */
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit'
  /** Css font-size value */
  fontSize?: (typeof fontSizeOptions)[number]
  /** Css font-weight value */
  fontWeight?: number // keyof typeof ClinTheme.fontWeights
  /** Css color value */
  color?: keyof typeof ClinTheme.colors | string
  /** Css margin-top value */
  marginTop?: string | number
  /** Css margin-right value */
  marginRight?: string | number
  /** Css margin-bottom value */
  marginBottom?: string | number
  /** Css margin-left value */
  marginLeft?: string | number
  /** Css text-transform values */
  textTransform?:
    | 'none'
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'initial'
    | 'inherit'
  /** Css display value */
  display?:
    | 'none'
    | 'inline'
    | 'inline-block'
    | 'block'
    | 'flex'
    | 'inline-flex'
    | 'initial'
    | 'inherit'
  whiteSpace?:
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-line'
    | 'pre-wrap'
    | 'initial'
    | 'inherit'
  wordBreak?:
    | 'normal'
    | 'break-all'
    | 'keep-all'
    | 'break-word'
    | 'initial'
    | 'inherit'
}

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'link'
  | 'linkWithIcon'
  | 'linkButton'
  | 'ghost'
  | 'primaryNarrow'
  | 'secondaryNarrow'
  | 'purpleNarrow'
  | 'purpleSecondaryNarrow'
  | 'purpleLight'
  | 'purpleLightSecondary'
