import { AxiosError } from 'axios'
import React, { FunctionComponent } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { ClinTheme } from '../../../../ClinTheme'
import { ClinAnnounceBar } from '../../../../components/ClinAnnounceBar'
import { ClinButton } from '../../../../components/ClinButton'
import { ClinCheckbox } from '../../../../components/ClinCheckbox'
import { ClinGroup } from '../../../../components/ClinGroup'
import { ClinLoader } from '../../../../components/ClinLoader'
import { ClinModal } from '../../../../components/ClinModal'
import { ClinSpacer } from '../../../../components/ClinSpacer'
import { ClinText } from '../../../../components/ClinText'
import { TypographyVariant } from '../../../../components/ClinText/ClinText.styles'
import { PhysiciansEnrolledSummaryDto } from '../../../../types/swaggerTypes'
import {
  StyledActions,
  StyledOverlayLoader,
  StyledSubmit
} from './AssociateModal.styles'

interface IAssociateModalProps {
  /** Control internal state of modal **/
  isOpen?: boolean
  /** The physician to user wishes to be associated to **/
  physician?: PhysiciansEnrolledSummaryDto
  /** Whether a user has confirmed whether they would like to associate with a physician or not **/
  associateConfirmation?: boolean
  /** Indicates whether the confirmation checkbox has been selected **/
  isConfirmed?: boolean
  /** Whether the submission is still processing **/
  isSubmitting: boolean
  /** Displays an error notification **/
  hasError?: boolean
  /** Displays an error notification message **/
  errorMessage?: AxiosError
  /** Handle confirmation to request association  **/
  handleConfirmationSelect: (confirmed: boolean) => void
  /** Handle confirmation of dissociation  **/
  handleConfirmation: () => void
  /** Handle close modal **/
  handleClose: () => void
}

export const AssociateModal: FunctionComponent<IAssociateModalProps> = ({
  isOpen,
  physician,
  associateConfirmation,
  errorMessage,
  isSubmitting,
  handleConfirmationSelect,
  handleConfirmation,
  handleClose
}) => {
  const { t } = useTranslation()

  const physicianFullName = `${physician?.physicianTitle ?? ''} ${
    physician?.physicianFirstName
  } ${physician?.physicianLastName}`

  return (
    <>
      <ClinModal
        onClose={handleClose}
        maxWidth="sm"
        height="auto"
        isOpen={isOpen}
      >
        <Container className="dissociate-physician-confirmation">
          <Row justify="center">
            {errorMessage && (
              <Col sm={12} md={11}>
                <ClinAnnounceBar title={errorMessage.name}>
                  {errorMessage.message}
                </ClinAnnounceBar>
              </Col>
            )}
            <Col sm={12} md={11}>
              <Row>
                <Col sm={12}>
                  <ClinText
                    as="h3"
                    variant={TypographyVariant.H3}
                    fontWeight={ClinTheme.fontWeights.bold}
                  >
                    {t('program_detail:associate_modal_title', {
                      physicianName: physicianFullName
                    })}
                  </ClinText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ClinText variant={TypographyVariant.LargeParagraph}>
                    {t('program_detail:associate_modal_description', {
                      physicianName: physicianFullName
                    })}
                  </ClinText>
                </Col>
              </Row>
              <ClinSpacer />
              <Row>
                <Col xs={12}>
                  <ClinGroup
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <ClinCheckbox
                      onClick={(event: React.MouseEvent<HTMLInputElement>) =>
                        handleConfirmationSelect &&
                        handleConfirmationSelect(event.currentTarget.checked)
                      }
                      id="confirmation-select"
                    />
                    <ClinText>
                      {t('program_detail:associate_modal_confirmation_message')}
                    </ClinText>
                  </ClinGroup>
                </Col>
              </Row>

              <StyledSubmit>
                <Row justify="between">
                  <Col md={5} lg={6}>
                    <StyledActions>
                      <ClinButton
                        className="cancel-disassociation"
                        onClick={() => handleClose()}
                      >
                        {t('common:buttons.cancel')}
                      </ClinButton>
                      <ClinButton
                        className="confirm"
                        variant="primary"
                        disabled={!associateConfirmation}
                        onClick={() => handleConfirmation()}
                      >
                        {t('program_detail:associate_and_order_btn')}
                      </ClinButton>
                    </StyledActions>
                  </Col>
                </Row>
              </StyledSubmit>
            </Col>
          </Row>
        </Container>
        {isSubmitting && (
          <StyledOverlayLoader>
            <Container>
              <Row justify="center" align="center">
                <Col sm={12}>
                  <ClinText
                    marginLeft="auto"
                    marginRight="auto"
                    marginBottom={ClinTheme.space[5]}
                    textAlign="center"
                    variant={TypographyVariant.LargeParagraph}
                  >
                    Please wait while we action your request
                  </ClinText>
                  <ClinGroup justifyContent="center">
                    <div
                      style={{
                        height: ClinTheme.space[9] * 2,
                        width: ClinTheme.space[9] * 2
                      }}
                    >
                      <ClinLoader minHandDuration={'1s'} />
                    </div>
                  </ClinGroup>
                </Col>
              </Row>
            </Container>
          </StyledOverlayLoader>
        )}
      </ClinModal>
    </>
  )
}
