import AnalyticsService, { IAnalyticsProvider } from './AnalyticsService'
import { AnalyticsEvent } from './AnalyticsEvent'
import authService from '../AuthService'
import { dynamicScriptLoader } from '../../utils/dynamicScriptLoader'
import config from '../ConfigProvider'
import { convertCamelToSnakeCase } from '../../utils/convertCamelToSnakeCase'

export class HubspotProvider
  extends AnalyticsService
  implements IAnalyticsProvider
{
  initProvider = async () => {
    this.logEvent(`HubspotProvider initProvider`)
    // Check hubspot app id is provided
    if (!config.hubspotAppId) {
      console.error('No hubspot id provided!')
      return
    }
    // Check hubspot code not loaded already?
    if (!!window._hsq) {
      console.warn('Hubspot already loaded')
      return
    }
    const isHubspotJSLoaded = await dynamicScriptLoader(
      `//js-eu1.hs-scripts.com/${config.hubspotAppId}.js`
    )
    if (isHubspotJSLoaded) {
      const _hsq = (window._hsq = window._hsq || [])
      // Assign user ID
      authService.getUserAsync().then((user) => {
        _hsq.push([
          'identify',
          {
            email: user?.profile.preferred_username
          }
        ])
      })
    } else {
      console.error(isHubspotJSLoaded)
    }
  }
  trackEvent = (
    event: AnalyticsEvent,
    properties?: { [key: string]: any }
  ): void => {
    const _hsq = (window._hsq = window._hsq || [])
    const usingSandbox =
      config.usingHubspotSandbox === 'true' ||
      config.usingHubspotSandbox === '1'
    // Look up the event
    let hubspotEventName = event.toString()
    // User logged in for sandbox only
    if (usingSandbox && event === AnalyticsEvent.UserLoggedIn.toString()) {
      hubspotEventName = 'pe25765817_user_logged_in'
    }
    // Order complete event prod and sandbox
    if (event === AnalyticsEvent.OrderCompleted.toString()) {
      hubspotEventName = usingSandbox
        ? 'pe25765817_order_complete' // Use sandbox value
        : 'pe25605503_order_complete' // Use prod value
    }

    //TODO: Fix the non use of updatedProperties
    if (
      usingSandbox &&
      event === AnalyticsEvent.EnrolUserOntoProgram.toString()
    ) {
      hubspotEventName = 'pe25765817_program_enrolment_v2'
      let updatedProperties: object = {}
      if (properties) {
        updatedProperties = Object.keys(properties).reduce(
          (acc: { [key: string]: any }, key) => {
            const newKey = convertCamelToSnakeCase(key)
            acc[newKey] = properties[key]
            return acc
          },
          {}
        )
      }
    }
    // Only send relevant hubspot events
    if (hubspotEventName) {
      this.logEvent(
        `HubspotProvider trackCustomBehavioralEvent: ${hubspotEventName}`
      )
      _hsq.push([
        'trackCustomBehavioralEvent',
        {
          name: hubspotEventName,
          properties: properties
        }
      ])
    }
  }
  trackPageView = (path: string): void => {
    this.logEvent(`HubspotProvider trackPageView ${path}`)
    const _hsq = (window._hsq = window._hsq || [])
    _hsq.push(['setPath', path])
    _hsq.push(['trackPageView'])
  }
  trackError = (error: Error): void => {
    this.logEvent(`HubspotProvider trackError ${error}`)
    this.trackEvent(AnalyticsEvent.Error, error)
  }
}
