import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme/ClinTheme'

export interface IClinPaginationDotsStyles {
  /** Example prop please replace or remove! */
  exampleClinPaginationDotsStyle?: string
}

export const StyledClinPaginationDots = styled.div<IClinPaginationDotsStyles>(
  {
    display: 'flex',
    justifyContent: 'end',
    [`> span`]: {
      display: 'block',
      width: '8px',
      height: '8px',
      marginRight: '6px',
      borderRadius: '50%',
      backgroundColor: ClinTheme.colors.lavender
    },
    [`> .active`]: {
      backgroundColor: ClinTheme.colors.primary
    }
  },
  ({ exampleClinPaginationDotsStyle }: IClinPaginationDotsStyles) => ({
    exampleClinPaginationDotsStyle
  })
)
