import styled from 'styled-components'
import { ClinTheme } from '../../../ClinTheme'

export const StyledLoadingContainer = styled.div({
  display: 'flex',
  alignItems: 'center'
})

export const StyledLoadingSpinner = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: ClinTheme.space[2]
})
