import React, { FunctionComponent, ReactElement } from 'react'
import {
  ISkeletonLoaderGroupStyles,
  ISkeletonLoaderItemStyles,
  StyledSkeletonItem,
  StyledSkeletonLoaderGroup
} from './ClinSkeletonLoader.styles'

type TSkeletonItem = FunctionComponent<ISkeletonLoaderItemStyles>

export const SkeletonItem: TSkeletonItem = ({
  width,
  height,
  marginBottom,
  backgroundColor
}) => (
  <StyledSkeletonItem
    backgroundColor={backgroundColor}
    width={width}
    height={height}
    marginBottom={marginBottom}
  />
)

type TReactSkeletonItem = ReactElement<TSkeletonItem>

interface ISkeletonLoaderGroupProps extends ISkeletonLoaderGroupStyles {
  /** Only accepts children which must are of type SkeletonItem */
  children: TReactSkeletonItem | TReactSkeletonItem[]
}

export const ClinSkeletonLoader: FunctionComponent<
  ISkeletonLoaderGroupProps
> = ({ children }) => (
  <StyledSkeletonLoaderGroup>{children}</StyledSkeletonLoaderGroup>
)
