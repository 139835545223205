import React, { FunctionComponent, useState } from 'react'
import { IDefaultStyleProps } from '../../types'
import {
  ClinTableOrderToggle,
  SortDirectionType
} from '../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { Row, Col } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import { ClinSpinner } from '../../components/ClinSpinner'
import { ClinTable } from '../../components/ClinTable'
import {
  ClinPatientTableHeaderCell,
  ClinTableRow,
  ClinTableBodyCell
} from '../../components/ClinTable/ClinTable'
import { StyledClinPastTableOrderToggleWrapper } from '../../pages/Programs/ProgramDetail/ProgramDetail.styles'
import { StyledSpinnerContainer } from '../DrugRequestForm/DrugRequestForm.styles'
import { IPastOrderColumn } from '../../pages/Patients/PatientDetail/PatientDetailContainer'
import { ShortOrderSummaryDto } from '../../types/swaggerTypes'
import { t } from 'i18next'
import { ClinText } from '../../components/ClinText'
import { StyledPatientTabEmptyHeaderWrapper } from '../../pages/Patients/PatientDetail/PatientDetail.styles'

interface IPastOrdersTableProps extends IDefaultStyleProps {
  /** Whether table is loading or not */
  isLoading: boolean
  /** Columns to show on the orders table */
  columns: IPastOrderColumn[]
  /** The array of orders coming through */
  pastOrders: ShortOrderSummaryDto[]
  /** Called when the user click on "Order Reference" */
  handleReferenceOrderClicked?: (selectedOrderId: string | number) => void
  handleSortConfigChange?: (
    sortColumn: string,
    sortOrder: SortDirectionType
  ) => void
}

export const PastOrdersTable: FunctionComponent<IPastOrdersTableProps> = ({
  isLoading = true,
  columns,
  pastOrders,
  handleSortConfigChange,
  handleReferenceOrderClicked
}) => {
  const [pastOrderSortConfig, setPastOrderSortConfig] = useState({
    sortColumn: columns[0].name,
    sortOrder: SortDirectionType.None
  })

  const isPatientPastOrdersExists = pastOrders && pastOrders.length > 0

  const handlePastOrdersSortChange = (sortKey: string | undefined) => {
    if (sortKey === undefined || !handleSortConfigChange) return

    setPastOrderSortConfig((currentConfig) => {
      const isNewColumn = currentConfig.sortColumn !== sortKey
      const isAscending = isNewColumn
        ? true
        : currentConfig.sortOrder !== SortDirectionType.Ascending
      return {
        sortColumn: sortKey,
        sortOrder: isAscending
          ? SortDirectionType.Ascending
          : SortDirectionType.Descending
      }
    })

    handleSortConfigChange(sortKey, pastOrderSortConfig.sortOrder)
  }

  return (
    <Row>
      <Col lg={12}>
        {isLoading ? (
          <StyledSpinnerContainer>
            <ClinSpinner size={ClinTheme.space[7]} />
          </StyledSpinnerContainer>
        ) : isPatientPastOrdersExists ? (
          <ClinTable
            className="TableOverflowVisible"
            borderColapseSeparate={true}
            borderSpacing="0  8px "
            padding="0px 2px 0px 2px"
            tableHeader={() =>
              columns.map((column) => (
                <ClinPatientTableHeaderCell
                  key={column.name}
                  width={column.width}
                  background={ClinTheme.colors.lightGrey}
                  color={ClinTheme.colors.darkGrey}
                  style={{
                    padding: '0px 16px 0'
                  }}
                >
                  {column.isSortable ? (
                    <StyledClinPastTableOrderToggleWrapper>
                      <ClinTableOrderToggle
                        sortDirection={
                          pastOrderSortConfig.sortColumn === column.name
                            ? pastOrderSortConfig.sortOrder
                            : SortDirectionType.None
                        }
                        onClick={() => handlePastOrdersSortChange(column.name)}
                      >
                        {column.title}
                      </ClinTableOrderToggle>
                    </StyledClinPastTableOrderToggleWrapper>
                  ) : (
                    <StyledClinPastTableOrderToggleWrapper>
                      <ClinTableOrderToggle hideSortIcon={true}>
                        {column.title.toUpperCase()}
                      </ClinTableOrderToggle>
                    </StyledClinPastTableOrderToggleWrapper>
                  )}
                </ClinPatientTableHeaderCell>
              ))
            }
          >
            {pastOrders.map((pastOrder, rowIndex) => (
              <ClinTableRow
                background={ClinTheme.colors.white}
                key={pastOrder.orderNumber}
                onRowClick={() =>
                  handleReferenceOrderClicked &&
                  pastOrder.orderNumber &&
                  handleReferenceOrderClicked(pastOrder.orderNumber)
                }
                onRowEnter={() =>
                  handleReferenceOrderClicked &&
                  pastOrder.orderNumber &&
                  handleReferenceOrderClicked(pastOrder.orderNumber)
                }
              >
                {columns.map((column, index) => (
                  <ClinTableBodyCell
                    key={column.name}
                    style={{
                      padding:
                        index === 4
                          ? '15px 0px 15px 25px'
                          : '15px 0px 15px 16px',
                      maxWidth: '230px'
                    }}
                  >
                    {column.renderValue(pastOrder, rowIndex)}
                  </ClinTableBodyCell>
                ))}
              </ClinTableRow>
            ))}
          </ClinTable>
        ) : (
          <StyledPatientTabEmptyHeaderWrapper>
            <ClinText fontSize={ClinTheme.fontSizes[1]} textAlign={'center'}>
              {t('patient_detail:empty_past_orders_header')}
            </ClinText>
          </StyledPatientTabEmptyHeaderWrapper>
        )}
      </Col>
    </Row>
  )
}
