import { MarketingPreferenceDto } from '../../types/swaggerTypes'

export const selectedAllPreferences: MarketingPreferenceDto[] = [
  {
    subscriptionName: 'AccessProgramMedicines',
    optedIn: true
  },
  {
    subscriptionName: 'UnlicensedAndUnavailableMedicines',
    optedIn: true
  },
  {
    subscriptionName: 'Shortages',
    optedIn: true
  },
  {
    subscriptionName: 'HowToGuides',
    optedIn: true
  }
]
//Because of GDPR we have to set them to false
export const defaultPreferences: MarketingPreferenceDto[] = [
  {
    subscriptionName: 'AccessProgramMedicines',
    optedIn: false
  },
  {
    subscriptionName: 'UnlicensedAndUnavailableMedicines',
    optedIn: false
  },
  {
    subscriptionName: 'Shortages',
    optedIn: false
  },
  {
    subscriptionName: 'HowToGuides',
    optedIn: false
  }
]
