import { ClinTheme } from '../ClinTheme'

export enum ProgramStatus {
  Open = 'Open',
  Closed = 'Closed - Archive',
  ClosedNotArchived = 'Closed',
  Unknown = 'Unknown'
}

export enum ProgramDetailStatus {
  AcceptingNewPatients = 'Accepting new patients',
  OpenForResupply = 'Open for resupply',
  Open = 'Open',
  Closed = 'Closed',
  NotAvailableInYourCountry = 'Not available in your country'
}

export enum ProgramCountryStatus {
  AcceptingNewPatients = 'Accepting new patients',
  OpenForResupply = 'Open for resupply',
  Closed = 'Closed',
  Unknown = 'Unknown'
}

export enum ProgramAvailability {
  NotAvailableInYourCountry = 'Not Available in your country',
  NotYetAvailableInYourCountry = 'Not yet available in your country',
  Enrol = 'Enrol',
  NotEnrolled = 'Not Enrolled',
  Unknown = 'Unknown'
}

interface ProgramDetail {
  color: string
}

export enum ProgramColumnNames {
  ProgramName = 'programName',
  Sponsor = 'sponsor',
  TherapeuticArea = 'therapeuticArea',
  Status = 'status',
  Availability = 'availability'
}

export const programStatusColorRecord: Record<
  ProgramStatus | ProgramCountryStatus | ProgramAvailability,
  ProgramDetail
> = {
  [ProgramStatus.Closed]: {
    color: ClinTheme.colors.redInvalid
  },
  [ProgramAvailability.NotAvailableInYourCountry]: {
    color: ClinTheme.colors.orangeWarn
  },
  [ProgramAvailability.NotYetAvailableInYourCountry]: {
    color: ClinTheme.colors.orangeWarn
  },
  [ProgramStatus.Open]: {
    color: ClinTheme.colors.greenValid
  },
  [ProgramCountryStatus.AcceptingNewPatients]: {
    color: ClinTheme.colors.greenValid
  },
  [ProgramCountryStatus.OpenForResupply]: {
    color: ClinTheme.colors.greenValid
  },
  [ProgramCountryStatus.Closed]: {
    color: ClinTheme.colors.redInvalid
  },
  [ProgramAvailability.Enrol]: {
    color: ClinTheme.colors.greenValid
  },
  [ProgramAvailability.NotEnrolled]: {
    color: ClinTheme.colors.greenValid
  },
  [ProgramCountryStatus.Unknown]: {
    color: ClinTheme.colors.black
  }
}
