import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { ClinTextInput } from '../../components/ClinTextInput'
import { mediaQueryDown } from '../../utils/mediaQuery'

export const StyledOnboarding = styled.div({
  backgroundColor: ClinTheme.colors.lightGrey,
  [`.onboarding-container`]: {
    maxWidth: '1140px',
    padding: '80px 70px',
    borderRadius: '8px',
    backgroundColor: `${ClinTheme.colors.white}`,
    [mediaQueryDown(ClinTheme.breakpoints[1])]: {
      padding: '40px 20px'
    }
  },
  [`.onboarding-buttons-wrap`]: {
    [mediaQueryDown(ClinTheme.breakpoints[1])]: {
      flexDirection: 'column'
    }
  }
})

export const StyledFormContainer = styled.div({
  backgroundColor: ClinTheme.colors.white,
  padding: ClinTheme.space[5],
  boxShadow: ClinTheme.shadows[1],
  display: 'flex'
})

export const StyledButtonLoadingContainer = styled.div({
  display: 'flex',
  alignItems: 'center'
})

export const StyledLoadingSpinner = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginLeft: ClinTheme.space[2]
})

export const StyledDisabledInput = styled(ClinTextInput)({
  [`> .clin-text`]: {
    color: ClinTheme.colors.black
  },
  [`input`]: {
    color: ClinTheme.colors.darkGrey,
    backgroundColor: ClinTheme.colors.lightGrey
  }
})
