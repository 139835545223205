import React, { Fragment, FunctionComponent } from 'react'
import { ClinTheme } from '../../../ClinTheme'
import { Col, Row } from 'react-grid-system'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { ClinPageContentFrame } from '../../../components/ClinPageContentFrame'
import {
  CountryDto,
  InstituteDto,
  OrgAddressDto,
  UserDto
} from '../../../types/swaggerTypes'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinTabHeader } from '../../../components/ClinTabHeader'
import { ClinTabs } from '../../../components/ClinTabs'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { ClinButton } from '../../../components/ClinButton'
import { InstituteDetailUserCard } from './InstituteDetailUserCard'
import { IPagination } from '../../../components/ClinPagination/ClinPagination.model'
import { StyledSpinnerContainer } from '../../../components/ClinSpinner/ClinSpinner.styles'
import { ClinSpinner } from '../../../components/ClinSpinner'
import { ClinAnnounceBar } from '../../../components/ClinAnnounceBar'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ClinCard } from '../../../components/ClinCard'
import { ClinIcon } from '../../../components/ClinIcon'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { IContact } from '../../../types/IContact'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { Trans, useTranslation } from 'react-i18next'

interface IInstituteDetailProps {
  /** Whether we are loading or not **/
  isLoading: boolean
  /** User role */
  isAdmin: boolean
  /** User details **/
  userDetails: UserDto
  /** Users institute **/
  institute: InstituteDto
  /** An array of activeContacts for an institute **/
  activeContacts: IContact[]
  /** An array of pendingContacts for an institute **/
  pendingContacts: IContact[]
  /** Pagination details */
  pagination: IPagination
  /** Support contact details **/
  supportContact: CountryDto
  /** Index of the selected tab */
  selectedTabIndex: number
  /** Called when the user selects a tab */
  onTabSelected: (selectedTabIndex: number) => void
  /** Called when the user clicks a page on the pagination */
  onPageClicked: (selectedPageIndex: number) => void
  /** Called when the user selects a different page size in the pagination */
  onPageSizeChange: (pageSize: number) => void
  /** An event for when a request is made for a new user */
  onRequestNewUser: () => void
  /** An event for when a request is made to edit a user */
  onEditUser: (userId: number) => void
  /** An event for when a request is made to remove a user */
  onRemoveUser: (user: IContact, isInvite: boolean) => void
  /** An event for when a request is made to remove a shipping address */
  onRemoveAddress: (address: OrgAddressDto) => void
  /** An event for when a request is made to create a shipping address */
  onRequestNewShippingAddress: () => void
  /** An event for when a request is made to change the applications current institute */
  onRequestInstituteChange: () => void
  /** An event for when a request is made to invite a contact */
  onRequestInvite: (contact: IContact) => void
}

export const InstituteDetail: FunctionComponent<IInstituteDetailProps> = ({
  isLoading,
  isAdmin,
  userDetails,
  institute,
  activeContacts,
  pendingContacts,
  pagination,
  supportContact,
  selectedTabIndex,
  onTabSelected,
  onPageClicked,
  onPageSizeChange,
  onRequestNewUser,
  onEditUser,
  onRemoveUser,
  onRemoveAddress,
  onRequestNewShippingAddress,
  onRequestInstituteChange,
  onRequestInvite
}) => {
  const { t } = useTranslation()

  return (
    <ClinPageContentFrame crumbs={[]}>
      {isLoading && (
        <Row justify="center">
          <Col width="auto">
            <StyledSpinnerContainer>
              <ClinSpinner size={ClinTheme.space[7]} />
            </StyledSpinnerContainer>
          </Col>
        </Row>
      )}
      {!isLoading && (
        <>
          <Row>
            <Col xs={12} md={9} lg={7}>
              <ClinText
                as="h1"
                variant={TypographyVariant.H2}
                fontWeight={ClinTheme.fontWeights.bold}
              >
                <Trans
                  i18nKey="institute_details:institute_details_for_user"
                  components={{ br: <br /> }}
                  values={{ instituteName: institute.instituteName }}
                  shouldUnescape={true}
                >
                  <></>
                </Trans>
                <br />
              </ClinText>

              <ClinSpacer height={ClinTheme.space[5]} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={10} lg={7}>
              <ClinGroup>
                <ClinText fontSize={ClinTheme.fontSizes[3]}>
                  {t('institute_details:account_number')}
                </ClinText>
                <ClinText
                  fontSize={ClinTheme.fontSizes[3]}
                  fontWeight={ClinTheme.fontWeights.bold}
                >
                  {institute.instituteId}
                </ClinText>
              </ClinGroup>
              <ClinSpacer height={ClinTheme.space[isAdmin ? 5 : 3]} />
              {isAdmin && (
                <ClinTabs activeItemIndex={0} handleSelected={onTabSelected}>
                  <ClinTabHeader title={t('institute_details:users')} />
                  <ClinTabHeader title={t('institute_details:addresses')} />
                </ClinTabs>
              )}
              <ClinSpacer height={ClinTheme.space[5]} hasBorder={!isAdmin} />
              {selectedTabIndex === 0 && (
                <>
                  <ClinText
                    marginBottom={ClinTheme.space[4]}
                    fontSize={ClinTheme.fontSizes[3]}
                  >
                    {t('institute_details:view_and_manage_your_users')}
                  </ClinText>

                  <ClinButton
                    variant="primary"
                    onClick={() => onRequestNewUser()}
                  >
                    {t('institute_details:invite_new_user_btn')}
                  </ClinButton>

                  <ClinSpacer height={ClinTheme.space[5]} />

                  {pendingContacts && pendingContacts.length > 0 && (
                    <>
                      <ClinText variant={TypographyVariant.H4}>
                        {t('institute_details:pending_invites')}
                      </ClinText>

                      <ClinSpacer height={ClinTheme.space[3]} />
                      {pendingContacts?.map((contact) => {
                        return (
                          <InstituteDetailUserCard
                            key={`contact-${contact.contactId}`}
                            isPending={true}
                            isUser={
                              userDetails.contactCard.email ===
                              contact.contactEmail
                            }
                            contact={contact}
                            onRequestInvite={onRequestInvite}
                            onRemoveUser={onRemoveUser}
                          />
                        )
                      })}
                      <ClinSpacer />
                    </>
                  )}

                  <ClinText variant={TypographyVariant.H4}>
                    {t('institute_details:active_users')}
                  </ClinText>

                  <ClinSpacer height={ClinTheme.space[3]} />
                  {activeContacts?.map((contact) => {
                    return (
                      <InstituteDetailUserCard
                        key={`contact-${contact.contactId}`}
                        isUser={
                          userDetails.contactCard.email === contact.contactEmail
                        }
                        isPending={false}
                        contact={contact}
                        onEditUser={onEditUser}
                        onRemoveUser={onRemoveUser}
                      />
                    )
                  })}
                  <ClinSpacer />

                  {/*{!isLoading && pagination && pagination.total > 0 && (*/}
                  {/*  <ClinPagination*/}
                  {/*    currentPage={getCurrentPage(pagination)}*/}
                  {/*    totalPages={getTotalPages(pagination)}*/}
                  {/*    pageSize={pagination.take}*/}
                  {/*    handlePageSelected={(pageIndex) =>*/}
                  {/*      onPageClicked(pageIndex)*/}
                  {/*    }*/}
                  {/*    handlePageSizeChange={onPageSizeChange}*/}
                  {/*  />*/}
                  {/*)}*/}
                </>
              )}
              {selectedTabIndex === 1 && (
                <>
                  {institute.billTo && (
                    <>
                      <ClinText
                        fontWeight={ClinTheme.fontWeights.light}
                        variant={TypographyVariant.H4}
                      >
                        {t('institute_details:billing_information')}
                      </ClinText>
                      <ClinSpacer height={ClinTheme.space[3]} />
                      {institute.billTo.address1 && (
                        <ClinText fontSize={ClinTheme.fontSizes[3]}>
                          {institute.billTo.address1},
                        </ClinText>
                      )}
                      {institute.billTo.address2 && (
                        <ClinText fontSize={ClinTheme.fontSizes[3]}>
                          {institute.billTo.address2},
                        </ClinText>
                      )}
                      {institute.billTo.address3 && (
                        <ClinText fontSize={ClinTheme.fontSizes[3]}>
                          {institute.billTo.address3},
                        </ClinText>
                      )}
                      {institute.billTo.address4 && (
                        <ClinText fontSize={ClinTheme.fontSizes[3]}>
                          {institute.billTo.address4},
                        </ClinText>
                      )}
                      {institute.billTo.state && (
                        <ClinText fontSize={ClinTheme.fontSizes[3]}>
                          {institute.billTo.state},
                        </ClinText>
                      )}
                      {institute.billTo.city && (
                        <ClinText fontSize={ClinTheme.fontSizes[3]}>
                          {institute.billTo.city},
                        </ClinText>
                      )}
                      {institute.billTo.country && (
                        <ClinText fontSize={ClinTheme.fontSizes[3]}>
                          {institute.billTo.country},
                        </ClinText>
                      )}
                      {institute.billTo.postalCode && (
                        <ClinText fontSize={ClinTheme.fontSizes[3]}>
                          {institute.billTo.postalCode}
                        </ClinText>
                      )}
                    </>
                  )}
                  {institute.taxRegNumber && (
                    <>
                      <ClinSpacer height={ClinTheme.space[5]} />
                      <ClinText
                        fontSize={ClinTheme.fontSizes[3]}
                        fontWeight={ClinTheme.fontWeights.bold}
                      >
                        {t('institute_details:tax_reg_number')}
                      </ClinText>
                      <ClinText fontSize={ClinTheme.fontSizes[3]}>
                        {institute.taxRegNumber}
                      </ClinText>
                    </>
                  )}
                  <ClinSpacer height={ClinTheme.space[6]} />
                  <ClinAnnounceBar
                    mode={AnnounceMode.Information}
                    title={t(
                      'institute_details:billing_information_announce_title'
                    )}
                  >
                    <Trans
                      i18nKey="institute_details:billing_information_announce_contact"
                      components={{
                        email: (
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <a
                            aria-label="Mail to creditcontrol@clinigengroup.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="mailto:creditcontrol@clinigengroup.com"
                          />
                        )
                      }}
                    >
                      <></>
                    </Trans>
                  </ClinAnnounceBar>
                  <ClinSpacer height={ClinTheme.space[5]} />
                  <ClinSpacer height={ClinTheme.space[5]} hasBorder={true} />
                  <ClinText
                    fontWeight={ClinTheme.fontWeights.light}
                    variant={TypographyVariant.H4}
                  >
                    {t('institute_details:shipping_addresses')}
                  </ClinText>
                  <ClinText
                    wordBreak="break-word"
                    variant={TypographyVariant.LargeParagraph}
                  >
                    <Trans
                      i18nKey="institute_details:request_to_add_or_remove_address_contact"
                      components={{
                        phone: (
                          <a
                            aria-label="Phone number link to customer support"
                            href={`tel:${supportContact.rawPhoneNumber}`}
                          />
                        ),
                        email: (
                          <a
                            aria-label="Mail to customer support"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`mailto:${supportContact.csEmailAddress}`}
                            onClick={() => {
                              analyticsServiceSingleton.trackEvent(
                                AnalyticsEvent.ContactClicked,
                                { contactType: 'email' }
                              )
                            }}
                          />
                        )
                      }}
                      values={{
                        phoneNumber: supportContact.csPhoneNumber,
                        emailAddress: supportContact.csEmailAddress
                      }}
                    >
                      <></>
                    </Trans>
                  </ClinText>
                  <ClinSpacer height={ClinTheme.space[9]} hasBorder={true} />

                  {institute.shipTo.map((address: OrgAddressDto) => (
                    <Fragment key={`ship-to-${address.addressId}`}>
                      <Row justify="between">
                        <Col xs={12} md={6}>
                          {address.primaryFlag === 'Y' && (
                            <>
                              <ClinText
                                color={ClinTheme.colors.primary}
                                fontSize={ClinTheme.fontSizes[1]}
                                fontWeight={ClinTheme.fontWeights.bold}
                              >
                                {t('institute_details:primary_address')}
                              </ClinText>
                              <ClinSpacer height={ClinTheme.space[2]} />
                            </>
                          )}

                          {address.address1 && (
                            <ClinText fontSize={ClinTheme.fontSizes[3]}>
                              {address.address1},
                            </ClinText>
                          )}
                          {address.address2 && (
                            <ClinText fontSize={ClinTheme.fontSizes[3]}>
                              {address.address2},
                            </ClinText>
                          )}
                          {address.address3 && (
                            <ClinText fontSize={ClinTheme.fontSizes[3]}>
                              {address.address3},
                            </ClinText>
                          )}
                          {address.address4 && (
                            <ClinText fontSize={ClinTheme.fontSizes[3]}>
                              {address.address4},
                            </ClinText>
                          )}
                          {address.state && (
                            <ClinText fontSize={ClinTheme.fontSizes[3]}>
                              {address.state},
                            </ClinText>
                          )}
                          {address.city && (
                            <ClinText fontSize={ClinTheme.fontSizes[3]}>
                              {address.city},
                            </ClinText>
                          )}
                          {address.country && (
                            <ClinText fontSize={ClinTheme.fontSizes[3]}>
                              {address.country},
                            </ClinText>
                          )}
                          {address.postalCode && (
                            <ClinText fontSize={ClinTheme.fontSizes[3]}>
                              {address.postalCode}
                            </ClinText>
                          )}
                        </Col>
                        <Col width="auto">
                          {isAdmin && (
                            <ClinButton
                              variant="link"
                              onClick={() => onRemoveAddress(address)}
                            >
                              {t('institute_details:request_to_remove')}
                            </ClinButton>
                          )}
                        </Col>
                      </Row>

                      <ClinSpacer
                        height={ClinTheme.space[9]}
                        hasBorder={true}
                      />
                    </Fragment>
                  ))}

                  <ClinSpacer height={ClinTheme.space[5]} />
                  <ClinButton
                    variant="primary"
                    onClick={() => onRequestNewShippingAddress()}
                  >
                    {t('institute_details:request_new_shipping_address')}
                  </ClinButton>
                  <ClinSpacer height={ClinTheme.space[5]} />
                </>
              )}
            </Col>
            <Col offset={{ lg: 1 }}>
              <ClinCard>
                <ClinIcon
                  iconSize={ClinTheme.space[4]}
                  iconFill={ClinTheme.colors.primaryLight}
                  iconName={ClinIconPathName.InfoOutline}
                />
                <ClinText
                  marginTop={ClinTheme.space[2]}
                  as="h4"
                  variant={TypographyVariant.H4}
                >
                  {t('institute_details:working_at_different_institute')}
                </ClinText>
                <ClinButton
                  onClick={() => onRequestInstituteChange()}
                  style={{ width: '100%' }}
                >
                  {t('institute_details:change_institute')}
                </ClinButton>
              </ClinCard>
            </Col>
          </Row>
        </>
      )}
    </ClinPageContentFrame>
  )
}
