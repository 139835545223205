import * as React from 'react'

import { newFeaturesReducer } from './NewFeaturesContext.reducer'
import { ActionTypes, ActionType } from './NewFeaturesContext.actions'
import { INewFeatureItem } from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'

export type Dispatch = (action: ActionTypes) => void

export type State = {
  features: INewFeatureItem[]
}

export const defaultNewFeaturesState: State = {
  features: []
}

type NewFeaturesProviderProps = {
  children: React.ReactNode
  defaultValues?: State
}

const NewFeaturesStateContext = React.createContext<State | undefined>(
  undefined
)
const NewFeaturesDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
)

const NewFeaturesProvider = ({
  children,
  defaultValues = defaultNewFeaturesState
}: NewFeaturesProviderProps) => {
  const [state, dispatch] = React.useReducer(newFeaturesReducer, defaultValues)
  return (
    <NewFeaturesStateContext.Provider value={state}>
      <NewFeaturesDispatchContext.Provider value={dispatch}>
        {children}
      </NewFeaturesDispatchContext.Provider>
    </NewFeaturesStateContext.Provider>
  )
}

const useNewFeaturesState = () => {
  const context = React.useContext(NewFeaturesStateContext)
  if (context === undefined) {
    throw new Error(
      'useNewFeaturesState must be used within a NewFeaturesProvider'
    )
  }
  return context
}

export const useNewFeaturesDispatch = () => {
  const context = React.useContext(NewFeaturesDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useNewFeaturesDispatch must be used within a NewFeaturesProvider'
    )
  }
  return context
}

const useNewFeatures = () => {
  return [useNewFeaturesState(), useNewFeaturesDispatch()] as const
}

// Action function to remove a feature
const removeFeature = (dispatch: Dispatch, featureId: string) => {
  dispatch({
    type: ActionType.RemoveFeature,
    featureId
  })
}

export { NewFeaturesProvider, useNewFeatures, removeFeature }
