import React, { FunctionComponent, useEffect } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { ClinSpacer } from '../../components/ClinSpacer'
import { ClinAnnounceBar } from '../../components/ClinAnnounceBar'
import { useAppContext } from '../../context/app'
import { clearAnnounceEvent } from '../../events/AnnounceEvent'
import { useLocation } from 'react-router-dom'

export const AnnounceContainer: FunctionComponent = () => {
  const { dispatch, announcement } = useAppContext()
  const location = useLocation()

  // Clean up announce once nav changes
  useEffect(() => {
    dispatch(clearAnnounceEvent())
  }, [location.pathname, dispatch])

  return announcement ? (
    <Container>
      <Row>
        <Col>
          <ClinSpacer />
          <ClinAnnounceBar
            mode={announcement.mode}
            title={announcement.title}
            isDismissible={true}
            handleClose={() => dispatch(clearAnnounceEvent())}
          >
            {announcement.description}
          </ClinAnnounceBar>
        </Col>
      </Row>
    </Container>
  ) : null
}
