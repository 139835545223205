import { UserRole } from '../constants'

export enum AuthEventType {
  UPDATE = 'UpdateAuthEvent',
  GET_USER = 'GetUserAuthEvent',
  GET_ROLE = 'GetUserRoleEvent',
  LOG_IN = 'LogInAuthEvent'
}

// Get user ---------------------------------------------------------------------

export interface IGetUserAuthEvent {
  /** Event type is fixed */
  type: AuthEventType.GET_USER
}

export const createGetUserAuthEvent = (): IGetUserAuthEvent => ({
  type: AuthEventType.GET_USER
})

// Set user role ---------------------------------------------------------------------

export interface IGetUserRoleEvent {
  /** Event type is fixed */
  type: AuthEventType.GET_ROLE
  userRole: UserRole
}

export const createUserRoleEvent = (userRole: UserRole): IGetUserRoleEvent => ({
  type: AuthEventType.GET_ROLE,
  userRole
})

// Login ---------------------------------------------------------------------

export interface ILogInAuthEvent {
  /** Event type is fixed */
  type: AuthEventType.LOG_IN
  redirectUrl: string
}

export const createLogInAuthEvent = (
  redirectUrl: string = '/'
): ILogInAuthEvent => ({
  type: AuthEventType.LOG_IN,
  redirectUrl
})

export type IAuthEvent = IGetUserAuthEvent | ILogInAuthEvent | IGetUserRoleEvent
