export type FeatureFlag =
  | FeatureFlagWithTargetingFilter
  | FeatureFlagWithTimeWindow // Union type

export interface ClientFilter {
  name: string
  parameters?: Record<string, unknown>
}
export interface AudienceParameters {
  IncludedUsers: string[]
  ExcludedUsers: string[]
  Groups: string[]
  ExcludedCountries: string[]
  IncludedCountries: string[]
  IncludedRoles: string[]
  ExcludedRoles: string[]
  DefaultRolloutPercentage: number
}
export interface FeatureFlagContextType {
  featureFlag: Record<string, boolean>
  setFeatureFlagRawData: React.Dispatch<React.SetStateAction<FeatureFlag[]>>
}
export enum FeatureFlagVariant {
  Targeting = 'Microsoft.Targeting',
  TimeWindow = 'Microsoft.TimeWindow'
}

export interface FeatureFlagWithTargetingFilter {
  id: string
  description: string
  enabled: boolean
  conditions: {
    client_filters: TargetingFilter[]
  }
}

export interface FeatureFlagWithTimeWindow {
  id: string
  description: string
  enabled: boolean
  conditions: {
    client_filters: TimeWindowFilter[]
  }
}

export interface TargetingFilter {
  name: string
  parameters: {
    Audience: AudienceParameters
  }
}

export interface TimeWindowFilter {
  name: string
  parameters: {
    End: string
  }
}
