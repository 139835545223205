import React, { FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'
import { isProductTemperatureTracker } from '../../pages/OpaCheckout/OpaCheckout.models'
import {
  HoldDto,
  OrderLineDto,
  OrderTrackingInfoDto,
  UploadedDocumentDto
} from '../../types/swaggerTypes'
import { OpaOrderItem, OrderItemType } from './OpaOrderItem'
import { OpaAncillaryOrderItem } from './OpaAncillaryOrderItem'
import { ClinCard } from '../../components/ClinCard'
import { StyledOpaOrderItemDetailHeader } from './OpaOrderItems.styles'
import { useTranslation } from 'react-i18next'
import { ClinSpacer } from '../../components/ClinSpacer'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'

interface IOpaOrderItemsProps {
  /** The order id */
  orderId: string
  /** Order items */
  orderLines: OrderLineDto[]
  /** Program sponsor */
  programSponsor: string | undefined
  /** Currency code*/
  currencyCode: string
  /** Order item type */
  itemType: OrderItemType
  /** Hide/show file upload field */
  isFileUploadEnabled?: boolean
  /** Displays program details of each item */
  isShowingProductDetails?: boolean
  /** The order status */
  orderStatus?: string
  /** Display any tracking info */
  trackingInfo?: OrderTrackingInfoDto
  /** Called when a file has been uploaded */
  handleFileUploadedForOrderAndHold?: (
    orderId: string,
    orderLine: OrderLineDto,
    hold: HoldDto,
    document: UploadedDocumentDto
  ) => void
}

export const OpaOrderItems: FunctionComponent<IOpaOrderItemsProps> = ({
  orderId,
  orderLines,
  programSponsor,
  currencyCode,
  itemType,
  isFileUploadEnabled = false,
  isShowingProductDetails = true,
  handleFileUploadedForOrderAndHold
}) => {
  const { t } = useTranslation()

  const filteredOrderLines = orderLines.filter(
    (orderLine) => !isProductTemperatureTracker(orderLine.skuCatalogItem?.item)
  )

  return orderLines.length > 0 ? (
    <ClinCard removePadding={true}>
      <StyledOpaOrderItemDetailHeader>
        <ClinText
          variant={TypographyVariant.H4}
          color={ClinTheme.colors.black}
          fontWeight={ClinTheme.fontWeights.medium}
        >
          {t('opa_order_summary:product_details')}
        </ClinText>

        <ClinSpacer></ClinSpacer>
        <Row className="order-items-header">
          <Col sm={12} md={6}>
            <ClinText
              fontSize={ClinTheme.fontSizes[1]}
              fontWeight={ClinTheme.fontWeights.medium}
              color={ClinTheme.colors.black}
            >
              {t('ma_order_detail:order_items.table_header_product')}
            </ClinText>
          </Col>
          <Col sm={12} md={2}>
            <ClinText
              fontSize={ClinTheme.fontSizes[1]}
              fontWeight={ClinTheme.fontWeights.medium}
              color={ClinTheme.colors.black}
              textAlign="right"
            >
              {t('ma_order_detail:order_items.table_header_product_code')}
            </ClinText>
          </Col>
          <Col sm={12} md={2}>
            <ClinText
              fontSize={ClinTheme.fontSizes[1]}
              fontWeight={ClinTheme.fontWeights.medium}
              color={ClinTheme.colors.black}
              textAlign="right"
            >
              {itemType === OrderItemType.ReassignedItem
                ? t('glossary:units')
                : t('glossary:quantity')}
            </ClinText>
          </Col>
          <Col sm={12} md={2}>
            <ClinText
              fontSize={ClinTheme.fontSizes[1]}
              fontWeight={ClinTheme.fontWeights.medium}
              color={ClinTheme.colors.black}
              textAlign="right"
            >
              {t('glossary:amount')}
            </ClinText>
          </Col>
        </Row>
      </StyledOpaOrderItemDetailHeader>

      {filteredOrderLines.map((orderLine, index) => {
        const hasBorder =
          filteredOrderLines.length > 1 && index < filteredOrderLines.length - 1
        return orderLine.medicationFlag === 'Y' ? (
          <OpaOrderItem
            key={orderLine.itemId}
            orderId={orderId}
            orderLine={orderLine}
            sponsor={programSponsor}
            currencyCode={currencyCode}
            itemType={itemType}
            hasBorder={hasBorder}
            isFileUploadEnabled={isFileUploadEnabled}
            isShowingProductDetails={isShowingProductDetails}
            handleFileUploadedForOrderAndHold={
              handleFileUploadedForOrderAndHold
            }
          />
        ) : (
          <OpaAncillaryOrderItem
            key={orderLine.itemId}
            orderLine={orderLine}
            currencyCode={currencyCode}
            hasBorder={hasBorder}
            isShowingProductDetails={isShowingProductDetails}
          />
        )
      })}
    </ClinCard>
  ) : (
    <Row>
      <Col>
        <ClinText fontSize={ClinTheme.fontSizes[3]}>
          {t('ma_order_detail:no_products_required')}
        </ClinText>
      </Col>
    </Row>
  )
}
