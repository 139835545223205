import { getUserNewFeatures } from '../../services/ApiService'
import { Dispatch } from './NewFeaturesContext'
import { ActionType } from './NewFeaturesContext.actions'
import { filterNewFeatures } from '../../services/NewFeaturesService'
import { INewFeatureItem } from '../../components/ClinNewFeatureTooltip/ClinNewFeatureTooltip.types'

/**
 * A collection of async helper functions for the new features context
 * Each one accepted the dispatch and deals with dispatching its own events once
 * promise calls have been resolved
 */

/**
 * Async API calls need to await and dispatch their own updates
 * @param dispatch
 */
async function getNewFeaturesAsync(dispatch: Dispatch) {
  let newFeatures: INewFeatureItem[] = []
  try {
    await getUserNewFeatures().then((response) => {
      newFeatures = filterNewFeatures(response.data)
    })

    newFeatures.length &&
      dispatch({
        type: ActionType.SetNewFeatures,
        features: newFeatures
      })
  } catch (error) {}
}

export { getNewFeaturesAsync }
