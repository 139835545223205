import React, { FunctionComponent } from 'react'
import { Redirect, Route } from 'react-router'
import { BasketStatus, useBasket } from '../../context/basket'
import { RouteComponentProps } from 'react-router-dom'
interface IPrivateCheckoutRouteProps {
  component: FunctionComponent<RouteComponentProps>
  exact: boolean
  path: string
  redirectPath: string
}

export const PrivateCheckoutRoute: FunctionComponent<
  IPrivateCheckoutRouteProps
> = ({ component, path, redirectPath, exact }) => {
  const [{ viewBasket }] = useBasket()
  return (
    <>
      {viewBasket?.state !== BasketStatus.Default ? (
        <Route path={path} exact={exact} component={component} />
      ) : (
        <Redirect exact={true} to={redirectPath} />
      )}
    </>
  )
}
