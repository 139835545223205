import React, { CSSProperties, FunctionComponent } from 'react'
import {
  StyledValue,
  IStyledIconvalueCellProps,
  StyledValueCell,
  StyledTitle
} from './IconValueCell.styles'
import { ClinTheme } from '../../ClinTheme'
import { ClinText } from '../../components/ClinText'
import { Col, Row } from 'react-grid-system'

interface IIconValueCellProps extends IStyledIconvalueCellProps {
  /** The title to display */
  title?: string
  /** The description to display */
  description?: string
  /** Any style properties to modify */
  style?: CSSProperties
}

export const ValueCell: FunctionComponent<IIconValueCellProps> = ({
  title = 'IconValueCell Title',
  description,
  children,
  style
}) => (
  <StyledValueCell className="clin-icon-value-container" style={style}>
    <StyledTitle className="clin-icon-title">
      <Row>
        <Col md={6}>
          <ClinText
            as="div"
            color={ClinTheme.colors.black}
            fontWeight={ClinTheme.fontWeights.bold}
            fontSize={ClinTheme.fontSizes[2]}
          >
            {title}
          </ClinText>
        </Col>
        <Col md={6}>
          <ClinText
            as="div"
            color={ClinTheme.colors.black}
            fontWeight={ClinTheme.fontWeights.normal}
            fontSize={ClinTheme.fontSizes[2]}
          >
            {description}
          </ClinText>
        </Col>
      </Row>
    </StyledTitle>
    <StyledValue className="clin-icon-value">{children}</StyledValue>
  </StyledValueCell>
)
