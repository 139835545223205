import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import React from 'react'
import { mediaQueryDown } from '../../utils/mediaQuery'
import { ClinSelect } from '../ClinSelect'
import { ClinButton } from '../ClinButton'

const tabletBreakpoint = ClinTheme.breakpoints[2]

export const StyledClinPagination = styled.nav({
  display: 'inline-flex',
  marginBottom: ClinTheme.space[9],
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
})

export const StyledPagination = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  [`.clin-select`]: {
    margin: 0,
    fontWeight: ClinTheme.fontWeights.bold
  }
})

export const StyledCenterContent = styled.div({
  flexGrow: 1,
  textAlign: 'center'
})

export const StyledCenterTabContent = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  textAlign: 'center'
})

export const StyledLeftSpacer = styled.div({
  flex: 1,
  minWidth: 250,
  [mediaQueryDown(tabletBreakpoint)]: { display: 'none' }
})

export const StyledResultsPerPage = styled.div({
  flex: '1 0 auto',
  minWidth: 250,
  textAlign: 'right'
})

export const StyledTabResultsPerPage = styled.div({
  flex: '1 0 auto',
  minWidth: 250,
  textAlign: 'right',
  fontSize: ClinTheme.fontSizes[1],
  fontWeight: ClinTheme.fontWeights.normal
})

export const StyledTabResultsPerPageWrapper = styled.div({
  position: 'absolute',
  right: `${ClinTheme.space[3]}px`,
  [mediaQueryDown(ClinTheme.breakpoints[3])]: {
    top: '30px'
  }
})

export const StyledResultsPerPageSelect = styled(ClinSelect)({
  marginLeft: ClinTheme.space[2]
})

export const StyledTabResultsPerPageSelect = styled(ClinSelect).attrs(
  (props) => ({
    background: props.background ?? ''
  })
)({
  marginLeft: ClinTheme.space[2]
})

interface IStyledPageButton
  extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Whether this page is active or not */
  isActive?: boolean
  /** Whether this button has a border - previous or next text button */
  hasBorder?: boolean
}

export const StyledPageButton = styled.button<IStyledPageButton>(
  {
    cursor: 'pointer',
    color: ClinTheme.colors.tealishBlue,
    textAlign: 'center',
    fontSize: ClinTheme.fontSizes[1],
    fontWeight: ClinTheme.fontWeights.bold,
    letterSpacing: ClinTheme.letterSpacing[2],
    minWidth: ClinTheme.space[5],
    margin: `0 ${ClinTheme.space[4]}px`,
    [mediaQueryDown(tabletBreakpoint)]: {
      margin: `0 ${ClinTheme.space[2]}px`
    },
    padding: `${ClinTheme.space[2]}px ${ClinTheme.space[3]}px`,
    [`:hover`]: {
      color: ClinTheme.colors.redOrange,
      textDecoration: 'underline'
    },
    [`:focus`]: {
      textDecoration: 'underline'
    },
    [`:disabled`]: {
      color: ClinTheme.colors.darkGrey,
      cursor: 'not-allowed'
    }
  },
  ({ isActive, hasBorder }) => ({
    backgroundColor: isActive
      ? ClinTheme.colors.midGrey
      : ClinTheme.colors.transparent,
    border: hasBorder ? `solid 1px ${ClinTheme.colors.midGrey}` : 'none'
  })
)

export const StyledPageTabButton = styled(ClinButton)<IStyledPageButton>(
  {
    cursor: 'pointer',
    color: ClinTheme.colors.tealishBlue,
    textAlign: 'center',
    fontSize: ClinTheme.fontSizes[1],
    fontWeight: ClinTheme.fontWeights.bold,
    letterSpacing: ClinTheme.letterSpacing[2],
    minWidth: ClinTheme.space[5],
    [`:hover`]: {
      color: ClinTheme.colors.redOrange,
      textDecoration: 'underline'
    },
    [`:focus`]: {
      textDecoration: 'underline'
    },
    [`:disabled`]: {
      color: ClinTheme.colors.darkGrey,
      cursor: 'not-allowed'
    }
  },
  ({ isActive, hasBorder }) => ({
    border: hasBorder ? `solid 1px ${ClinTheme.colors.midGrey}` : 'none'
  })
)

export const StyledClinTabsPagination = styled.nav({
  display: 'inline-flex',
  marginBottom: ClinTheme.space[9],
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%'
})
