import React from 'react'
import { ClinTheme } from '../../../ClinTheme'
import useChangeBackgroundColor from '../../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'
import { HomepageOld } from './HomepageOld'

export const HomepageContainerOld: React.FC = () => {
  useChangeBackgroundColor(ClinTheme.colors.lightGrey)

  return <HomepageOld />
}
