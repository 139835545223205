import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from 'react'
import { RouteComponentProps, StaticContext } from 'react-router'
import { useAppContext } from '../../../context/app'
import {
  AuthError,
  downloadDocumentById,
  reconcileVialForPatient
} from '../../../services/ApiService'
import { PatientDetail } from './PatientDetail'
import {
  getNumberOfVialsToReconcile,
  useAugmentedOrders,
  useProgram,
  useReadOnlyPatientAccessForm
} from './PatientDetail.model'
import { IPagination } from '../../../components/ClinPagination/ClinPagination.model'
import {
  StyledCalendarModal,
  StyledCalendarWrapper
} from './PatientDetail.styles'
import { ClinTheme } from '../../../ClinTheme'
import {
  Calendar,
  Day,
  utils
} from '@amir04lm26/react-modern-calendar-date-picker'
import {
  clearAnnounceEvent,
  createAnnounceEvent
} from '../../../events/AnnounceEvent'
import { AnnounceMode } from '../../../components/ClinAnnounceBar/ClinAnnounceBar'
import { ITransferForm } from './TransferPatientForm/TransferPatientForm'
import { DiscontinueWarningModal } from './DiscontinueWarningModal'
import {
  convertDateStringToDay,
  convertDayToISODateString
} from '../../../utils/dateUtils'
import {
  ICalendarPicker,
  IPastOrderSummary,
  IPatientVial,
  VialReconciliationType
} from './PatientDetail.types'
import set from 'lodash/set'
import { DiscontinueConfirmationModal } from './DiscontinueConfirmationModal'
import { useDiscontinueConfirmationModal } from './DiscontinueConfirmationModal/DiscontinueConfirmationModal.model'
import { PatientDetailDiscontinued } from './PatientDetailDiscontinued/PatientDetailDiscontinued'
import {
  InstituteDto,
  PatientDetailsDto,
  ProgramDocumentDto,
  ShortOrderSummaryDto
} from '../../../types/swaggerTypes'
import { AxiosError } from 'axios'
import download from 'downloadjs'
import { getFilenameFromUrl } from '../../../utils/getFilenameFromUrl'
import { $enum } from 'ts-enum-util'
import { PatientDocumentType } from '../../../constants'
import analyticsServiceSingleton from '../../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../../services/Analytics'
import { AnalyticsPageEvent } from '../../../services/Analytics/AnalyticsPageEvent'
import { DateTime } from 'luxon'
import { getBrowserLocale } from '../../../utils/getBrowserLocale'
import { useEffectOnlyOnce } from '../../../hooks/useEffectOnlyOnce/useEffectOnlyOnce'
import { IRestEndpointState } from '../../../types/IRestEndpointState'
import {
  PatientOrderStatus,
  OPAOrderTypes,
  ActionDropdownConstants,
  patientOrderStatusTooltipTranslation,
  patientOrderStatusTranslation
} from '../PatientDashboard/Patient.model'
import { useFeatureFlags } from '../../../context/featureFlags/FeatureFlagContext'
import { FeatureFlagKeys } from '../../../context/featureFlags/FeatureFlagKeys'
import useChangeBackgroundColor from '../../../hooks/useChangeBackgroundColor/useChangeBackgroundColor'
import { t } from 'i18next'
import { ClinText } from '../../../components/ClinText'
import { PastOrderColumn } from '../Patients.model'
import {
  TabModel,
  getShowRWDCard
} from '../../Programs/ProgramDetail/ProgramDetail.models'
import { ClinIconPathName } from '../../../components/ClinIcon/ClinIcon.paths'
import { StyledDocumentIcon } from '../../../features/DocumentUpload/DocumentUpload.styles'
import StatusTooltip from '../PatientDashboard/StatusTooltip'
import { StyledStatusWrapper } from './PatientDetailCard.styles'
import StyledTrackingLinkWrapper from '../PatientDashboard/StyledTrackingLinkWrapper'
import { Tooltip } from 'react-tippy'
import { PatientAccessFormItem } from './PatientDetailContainer.styles'
import { SortDirectionType } from '../../../components/ClinTableOrderToggle/ClinTableOrderToggle'
import { ClinButton } from '../../../components/ClinButton'
import { ClinGroup } from '../../../components/ClinGroup'
import { ClinModal } from '../../../components/ClinModal'
import { ClinSpacer } from '../../../components/ClinSpacer'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import { OrderStatus } from '../../../types/OrderStatus'
import { useOnMountWithSubsequent } from '../../../utils/useOnMountWithSubsequent'

export enum ReconcileStatus {
  SuccessfulReconciliation,
  RequiresReconciliation,
  NoReconciliationRequired
}

export interface IPastOrderColumn {
  title: string
  renderValue: (
    order: ShortOrderSummaryDto,
    index?: number
  ) => JSX.Element | string
  width: string
  hide?: boolean
  isSortable?: boolean
  name?: string
}

const defaultPagination: IPagination = {
  count: 0,
  skip: 0,
  take: 5,
  total: 0
}

type LocationState = {
  from?: string
  endpoint?: string
  orderNumber?: string
  activeTabIndex?: number
}

interface IPatientDetailRouteParams {
  patientId: string
  physicianId: string
}

interface IPatientDetailProps
  extends RouteComponentProps<
    IPatientDetailRouteParams,
    StaticContext,
    LocationState
  > {}

export const PatientDetailContainer: FunctionComponent<IPatientDetailProps> = ({
  match,
  history,
  location
}) => {
  //region useState and useEffect
  const { dispatch, institute } = useAppContext()
  const { patientId, physicianId } = match.params
  const [pagination, setPagination] = useState<IPagination>(defaultPagination)
  const [hasReconciliation, setHasReconciliation] = useState<boolean>(false)
  const [detailsCtaButtonTitle, setDetailsCtaButtonTitle] = useState<string>('')
  const [orderNumber, setOrderNumber] = useState<string>('')
  const [isPatientOrderNumber, setIsPatientOrderNumber] =
    useState<boolean>(false)

  const { useFeatureFlag } = useFeatureFlags()
  let patientCentricFeatureFlag = useFeatureFlag(
    FeatureFlagKeys.PatientCentricJourneyPerUser
  )

  useChangeBackgroundColor(ClinTheme.colors.lightGrey)

  useOnMountWithSubsequent(() => {
    if (history.location?.state?.orderNumber) {
      setOrderNumber(history.location?.state?.orderNumber)
    }
  })

  const {
    isLoading,
    patient,
    augmentedOrders,
    isProgramClosed,
    allowsDiscontinuation,
    getPatient
  } = useAugmentedOrders(
    patientId,
    physicianId,
    setPagination,
    setHasReconciliation,
    dispatch
  )
  const [vialsToReconcileCounter, setVialsToReconcileCounter] =
    useState<number>(0)

  const [showRecordTreatmentTab, setShowRecordTreatmentTab] = useState(true)

  const { isProgramLoading, program } = useProgram(patient?.program.programId)

  const {
    isConfirmationOpen,
    isConfirmationSubmitting,
    isConfirmationSubmitted,
    confirmationError,
    handleOpenConfirmation,
    handleCloseConfirmation,
    handleClearSubmission
  } = useDiscontinueConfirmationModal(
    patientId,
    physicianId,
    institute.data?.instituteId
  )

  const getUrlParams = useCallback(() => {
    const urlSearchParams = new URLSearchParams(history.location.search)
    const isFormShow: boolean = Boolean(
      urlSearchParams.get('transfer-patient-form')
    )
    setIsFormOpen(isFormShow)
  }, [history.location.search])

  const [pastOrderSortConfig, setPastOrderSortConfig] = useState({
    sortColumn: '',
    sortOrder: SortDirectionType.None
  })

  useEffect(() => {
    if (program?.program) {
      const supportsReconciliation =
        program.program.supportsReconcilliation === 'Yes' ||
        program.program.supportsReconcilliation === 'Y'
      setShowRecordTreatmentTab(supportsReconciliation)
    }
  }, [program])

  // Update orders when received and augmented
  useEffect(() => {
    const allPastOrdersClone = augmentedOrders.slice()

    const sortedPastOrders = sortPastOrders(
      allPastOrdersClone,
      pastOrderSortConfig.sortColumn as string,
      pastOrderSortConfig.sortOrder
    )

    setPastOrders([
      ...sortedPastOrders.splice(pagination.skip, pagination.take)
    ])

    getUrlParams()
    const unListen = history.listen(() => {
      getUrlParams()
    })
    return () => {
      unListen()
    }
  }, [
    augmentedOrders,
    getUrlParams,
    history,
    pagination.take,
    pastOrderSortConfig
  ])
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false)
  const [isReconcileWarningOpen, setIsReconcileWarningOpen] =
    useState<boolean>(false)

  const [pastOrders, setPastOrders] = useState<IPastOrderSummary[]>([])

  // Set initial tabIndex based on pastOrdersFiltered
  const [tabIndex, setTabIndex] = useState<number>(
    typeof location?.state?.activeTabIndex === 'number'
      ? location?.state?.activeTabIndex
      : 0
  )

  // Selected row logic
  const [selectedVialRow, setSelectedVialRow] = useState<
    IPatientVial | undefined
  >()
  const [showCalendar, setShowCalendar] = useState<ICalendarPicker>({
    isOpen: false,
    minimumDate: utils('en').getToday(),
    selectedDay: utils('en').getToday(),
    maximumDate: utils('en').getToday()
  })
  const [isSavingVial, setIsSavingVial] = useState<boolean>(false)
  const { isLoadingPatientAccessForm, patientAccessFormUrl } =
    useReadOnlyPatientAccessForm(dispatch, patient)

  const [isTransferringAnotherInstitute, setIsTransferringAnotherInstitute] =
    useState(false)

  //endregion

  useEffect(() => {
    setDetailsCtaButtonTitleBasedOnStatus()
  }, [patient])

  const allStatusActions = {
    [ActionDropdownConstants.BeginOrder]: {
      text: 'patient_detail:patient_actions.begin_order'
    },
    [ActionDropdownConstants.DiscontinuePatient]: {
      text: 'patient_detail:patient_actions.discontinue_patients'
    },
    [ActionDropdownConstants.ContinueOrder]: {
      text: 'patient_detail:patient_actions.continue_order'
    },
    [ActionDropdownConstants.OrderResuply]: {
      text: 'patient_detail:patient_actions.order_resupply'
    },
    [ActionDropdownConstants.TrackYourOrder]: {
      text: 'patient_detail:patient_actions.track_your_order'
    },
    [ActionDropdownConstants.ReconcileVials]: {
      text: 'patient_detail:patient_actions.reconcile_vials'
    },
    [ActionDropdownConstants.TransferPatient]: {
      text: 'patient_detail:patient_actions.transfer_patients'
    }
  }

  const setDetailsCtaButtonTitleBasedOnStatus = () => {
    if (!patient) return

    const stockOrderFlag = patient?.stockOrderFlag === 'Y'

    let ctaTitle = ''

    const defaultCtaTitle = 'patient_detail:button_request_resupply'

    switch (patient.patientOrderStatus) {
      case PatientOrderStatus.FailedDelivery:
      case PatientOrderStatus.Expired:
      case PatientOrderStatus.Pending:
      case PatientOrderStatus.Delivered:
      case PatientOrderStatus.Shipped:
      case PatientOrderStatus.ReadyForResupply:
      case PatientOrderStatus.InTransit:
      case PatientOrderStatus.OutForDelivery:
      case PatientOrderStatus.Available_for_Pickup:
      case PatientOrderStatus.FailedAttempt:
        ctaTitle = defaultCtaTitle
        if (hasReconciliation) {
          ctaTitle =
            allStatusActions[ActionDropdownConstants.ReconcileVials].text
          setDetailsCtaButtonTitle(ctaTitle)
          return
        }
        if (
          patient.patientOrderStatus === PatientOrderStatus.ReadyForResupply &&
          !stockOrderFlag
        ) {
          setDetailsCtaButtonTitle(defaultCtaTitle)
          return
        }
        break

      case PatientOrderStatus.BeginOrder:
        ctaTitle = allStatusActions[ActionDropdownConstants.BeginOrder].text
        break

      case PatientOrderStatus.IncompletePAF:
      case PatientOrderStatus.IncompleteCheckout:
        ctaTitle = allStatusActions[ActionDropdownConstants.ContinueOrder].text
        break

      case PatientOrderStatus.ProgramClosed:
      case PatientOrderStatus.PatientDiscontinued:
      case PatientOrderStatus.UnderMedicalReview:
        break
      default:
        break
    }
    setDetailsCtaButtonTitle(ctaTitle)
  }

  // Action Handlers Object
  const actionHandlers = {
    resupply: (patient: PatientDetailsDto) =>
      history.push(
        `/programs/access-programs/${patient.program.programId}/patient-access-form/${patient.currentPhysician.physicianId}`,
        {
          orderType: OPAOrderTypes.Resupply,
          patientNumber: patient.patientNumber,
          patientId: patient.patientId,
          patientStatus: patient.patientOrderStatus,
          stockOrder: patient.stockOrderFlag,
          from: window.location.pathname
        } as LocationState
      ),

    reconcileVials: (patient: PatientDetailsDto) =>
      history.push(
        `/programs/my-physicians/${patient.program.programId}/${patient.currentPhysician.physicianId}`,
        {
          from: window.location.pathname
        }
      ),

    trackOrder: (patient: PatientDetailsDto) => {
      if (patient.trackingLink) {
        window.location.href = patient.trackingLink
      }
    },

    transferPatient: (patient: PatientDetailsDto) =>
      history.push(
        `/programs/my-physicians/${patient.currentPhysician.physicianId}/${patient.patientId}?transfer-patient-form=true`
      ),

    beginOrder: (patient: PatientDetailsDto) =>
      history.push(
        `/programs/access-programs/${patient.program.programId}/patient-access-form/${patient.currentPhysician.physicianId}`,
        {
          orderType:
            patient.stockOrderFlag === 'Y'
              ? OPAOrderTypes.StockOrder
              : OPAOrderTypes.InitialOrder,
          patientNumber: patient.patientNumber,
          patientId: patient.patientId,
          patientStatus: patient.patientOrderStatus,
          stockOrder: patient.stockOrderFlag,
          from: window.location.pathname
        } as LocationState
      ),

    continueOrder: (patient: PatientDetailsDto) => {
      let orderType

      if (patient.patientOrderStatus === PatientOrderStatus.BeginOrder) {
        orderType = patient.stockOrderFlag
          ? OPAOrderTypes.StockOrder
          : OPAOrderTypes.InitialOrder
      } else {
        orderType = patient.orderType
      }

      history.push(
        `/programs/access-programs/${patient.program.programId}/patient-access-form/${patient.currentPhysician.physicianId}`,
        {
          orderType,
          patientNumber: patient.patientNumber,
          patientId: patient.patientId,
          patientStatus: patient.patientOrderStatus,
          stockOrder: patient.stockOrderFlag,
          from: window.location.pathname
        } as LocationState
      )
    },

    continueCheckout: (patient: PatientDetailsDto) => {
      if (!orderNumber) {
        setIsPatientOrderNumber(true)
        return
      }

      history.push(`/opa-checkout/${orderNumber}`, {
        from: location?.state?.from,
        patientId: patient.patientId,
        patientNumber: patient.patientNumber,
        orderType: patient.orderType,
        programId: patient.program.programId,
        stockOrder: patient.stockOrderFlag
      } as LocationState)
    }
  }

  const handlePrimaryCTA = () => {
    if (!patient) return

    if (!patientCentricFeatureFlag) {
      handleGoProgramDetail()
      return
    }

    const stockOrderFlag = patient?.stockOrderFlag === 'Y'
    const trackingLinkExists = patient?.trackingLink !== 'N/A'

    switch (patient.patientOrderStatus) {
      case PatientOrderStatus.FailedDelivery:
      case PatientOrderStatus.Expired:
      case PatientOrderStatus.Pending:
      case PatientOrderStatus.Delivered:
      case PatientOrderStatus.Shipped:
      case PatientOrderStatus.ReadyForResupply:
        actionHandlers['resupply']?.(patient)
        if (
          patient.patientOrderStatus === PatientOrderStatus.Pending &&
          hasReconciliation
        ) {
          actionHandlers['reconcileVials']?.(patient)
          return
        }
        break
      case PatientOrderStatus.InTransit:
      case PatientOrderStatus.OutForDelivery:
      case PatientOrderStatus.Available_for_Pickup:
      case PatientOrderStatus.FailedAttempt:
        if (!stockOrderFlag) {
          actionHandlers['resupply']?.(patient)
          return
        }
        if (trackingLinkExists) {
          actionHandlers['trackOrder']?.(patient)
          return
        }
        break
      case PatientOrderStatus.BeginOrder:
        actionHandlers['beginOrder']?.(patient)
        return
      case PatientOrderStatus.IncompletePAF:
        actionHandlers['continueOrder']?.(patient)
        return
      case PatientOrderStatus.IncompleteCheckout:
        actionHandlers['continueCheckout']?.(patient)
        return
      case PatientOrderStatus.PatientDiscontinued:
      default:
        handleGoProgramDetail()
        return
    }
  }

  const handleGoProgramDetail = () => {
    history.push(`/programs/access-programs/${patient?.program.programId}`)
  }

  const handleFormSubmission = (form: ITransferForm) => {
    const transferTo =
      form.destinationRadioId === '1' ? 'physician' : 'institute'
    history.push(
      `/programs/my-physicians/${physicianId}/${patientId}/transfer-patient/${transferTo}`
    )
  }

  const handleDestinationInputChange = (radioId: string) => {
    if (radioId === '1') {
      setIsTransferringAnotherInstitute(false)
      dispatch(clearAnnounceEvent())
      return
    }
    setIsTransferringAnotherInstitute(radioId === '2')
  }

  const handleHideTransferForm = () => {
    setIsFormOpen(false)
    setIsTransferringAnotherInstitute(false)
    dispatch(clearAnnounceEvent())
    history.push(`/programs/my-physicians/${physicianId}/${patientId}`)
  }

  const handleShowTransferForm = () => {
    dispatch(clearAnnounceEvent()) // Hide any vial reconciliation
    setIsFormOpen(true)
    history.push(
      `/programs/my-physicians/${physicianId}/${patientId}?transfer-patient-form=true`
    )
  }

  const handleReferenceOrderClicked = (selectedOrderId: string | number) => {
    history.push(`/order/${selectedOrderId}`, {
      from: window.location.pathname
    })
  }

  const sortPastOrders = (
    pastOrders: IPastOrderSummary[],
    sortColumn: string,
    sortOrder: SortDirectionType = SortDirectionType.None
  ) => {
    if (!pastOrders || !sortColumn || sortOrder === SortDirectionType.None) {
      return pastOrders
    }

    return pastOrders.sort(
      (a: ShortOrderSummaryDto, b: ShortOrderSummaryDto) => {
        const valA = getValueByColumn(a, sortColumn)
        const valB = getValueByColumn(b, sortColumn)

        if (valA === valB) {
          const dateA = new Date(a.orderedDate)
          const dateB = new Date(b.orderedDate)
          return sortOrder === SortDirectionType.Ascending
            ? dateA.getTime() - dateB.getTime()
            : dateB.getTime() - dateA.getTime()
        }

        if (typeof valA === 'string' && typeof valB === 'string') {
          return sortOrder === SortDirectionType.Ascending
            ? valA.localeCompare(valB)
            : valB.localeCompare(valA)
        } else if (typeof valA === 'number' && typeof valB === 'number') {
          return sortOrder === SortDirectionType.Ascending
            ? valA - valB
            : valB - valA
        } else {
          return 0
        }
      }
    )
  }

  const getValueByColumn = (
    order: ShortOrderSummaryDto,
    columnName: string
  ) => {
    switch (columnName) {
      case PastOrderColumn.OrderNumber:
        return order.orderNumber
      case PastOrderColumn.Medications:
        return order.medications[0].name.toLowerCase()
      case PastOrderColumn.OrderedDate:
        return order.orderedDate ? new Date(order.orderedDate).getTime() : 0
      case PastOrderColumn.OrderStatus:
        return order.orderStatus.toLowerCase()
      default:
        return null
    }
  }

  const handlePageSizeChange = (pageSize: number) => {
    const allPastOrdersClone = augmentedOrders.slice()

    const sortedPastOrders = sortPastOrders(
      allPastOrdersClone,
      pastOrderSortConfig.sortColumn as string,
      pastOrderSortConfig.sortOrder
    )

    if (sortedPastOrders) {
      setPastOrders([...sortedPastOrders.splice(0, pageSize)])

      setPagination({
        ...pagination,
        total: patient?.pastOrders.length ?? 0,
        take: pageSize,
        skip: 0
      })
    }
  }

  const handlePageClicked = (selectedPageIndex: number) => {
    const newSkip = (selectedPageIndex - 1) * pagination.take
    const allPastOrdersClone = augmentedOrders.slice()

    const sortedPastOrders = sortPastOrders(
      allPastOrdersClone,
      pastOrderSortConfig.sortColumn as string,
      pastOrderSortConfig.sortOrder
    )

    if (sortedPastOrders) {
      const newPastOrders = [
        ...sortedPastOrders.splice(newSkip, pagination.take)
      ]
      setPastOrders(newPastOrders)
      setPagination({
        ...pagination,
        skip: newSkip
      })
    }
  }

  /**
   * Trigger when clicking on a date selector show calendar
   * @param vial
   */
  const handleVialDateInputSelected = (vial: IPatientVial) => {
    // Work out minimum date (date order was shipped)
    const shippedDateForOrder: string | undefined = patient?.pastOrders.find(
      (o) => o.orderNumber.toString() === vial.orderNumber.toString()
    )?.shippedDate
    // Maximum date is always today
    const today = new Date().toISOString()
    // Convert to a 'Day' for the minimum date for date picker or use current day
    const minimumDate = shippedDateForOrder ?? today
    // Select the vial row (so we can update the correct one later)
    setSelectedVialRow(vial)
    // Show calendar
    setShowCalendar({
      minimumDate: convertDateStringToDay(minimumDate),
      selectedDay: convertDateStringToDay(vial.dateAdministered ?? today), // Work out day selected (if their is one)
      maximumDate: convertDateStringToDay(today),
      isOpen: true
    })
  }

  /**
   * When day is selected in calendar update the nested vial row date
   * @param selectedDay
   */
  const handleDaySelected = (selectedDay: Day) => {
    // Find matching order and vial
    const matchingOrderIndex = pastOrders.findIndex(
      (ord) =>
        ord.orderNumber.toString() === selectedVialRow?.orderNumber.toString()
    )
    const matchingOrder = pastOrders[matchingOrderIndex]
    const matchingVialIndex = matchingOrder.vialsToReconcile.findIndex(
      (v) => v.vialNumber === selectedVialRow?.vialNumber
    )
    const vialToUpdate = matchingOrder.vialsToReconcile[matchingVialIndex]
    const isoDate = convertDayToISODateString(selectedDay)
    const updatedVial = {
      ...vialToUpdate,
      dateAdministered: isoDate,
      dateAdministeredFormatted: DateTime.fromISO(isoDate)
        .setLocale(getBrowserLocale())
        .toLocaleString(DateTime.DATE_SHORT)
    }
    // Update all the orders with the updated vial
    const updatedPastOrders = {
      ...pastOrders
    }
    // Use lodash to update the nested vial
    set(
      updatedPastOrders,
      `[${matchingOrderIndex}].vialsToReconcile[${matchingVialIndex}]`,
      updatedVial
    )
    // Deselect the row
    setSelectedVialRow(undefined)
    // Close calendar
    setShowCalendar({
      ...showCalendar,
      minimumDate: matchingOrder.dayOrderShipped,
      selectedDay: selectedDay,
      isOpen: false
    })
  }

  const handleSaveChangesToVial = (updatedVial: IPatientVial) => {
    const { dateAdministered, vialNumber, vialStatus, batchNumber } =
      updatedVial
    setIsSavingVial(true)
    setSelectedVialRow(updatedVial)
    patient?.currentPhysician &&
      reconcileVialForPatient(
        patient.currentPhysician.physicianId.toString(),
        patient.patientId.toString(),
        {
          dateAdministered,
          vialNumber,
          vialStatus,
          batchNumber
        }
      )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            // Find matching order and vial
            const matchingOrderIndex = pastOrders.findIndex(
              (ord) =>
                ord?.orderNumber?.toString() ===
                updatedVial?.orderNumber?.toString()
            )
            const matchingOrder = pastOrders[matchingOrderIndex]
            const matchingVialIndex = matchingOrder.vialsToReconcile.findIndex(
              (v) =>
                v?.vialNumber?.toString() ===
                updatedVial?.vialNumber?.toString()
            )
            // Use lodash to update the nested vial that was just reconciled
            const updatedOrders = set(
              pastOrders,
              `[${matchingOrderIndex}].vialsToReconcile[${matchingVialIndex}]`,
              {
                ...updatedVial,
                wasReconciled: true
              }
            )

            setPastOrders(updatedOrders)

            // Update vials to reconcile counter
            const totalVialsToReconcile =
              getNumberOfVialsToReconcile(updatedOrders)

            setVialsToReconcileCounter(totalVialsToReconcile)

            analyticsServiceSingleton.trackEvent(AnalyticsEvent.ReconcileVial, {
              patientId,
              programId: program?.program?.cliniportProgramId,
              programName: program?.programName
            })

            // Should we scroll to top and show vial reconciliation complete message?
            if (totalVialsToReconcile === 0) {
              window.scrollTo({
                behavior: 'smooth',
                top: 0
              })
              dispatch(
                createAnnounceEvent(
                  AnnounceMode.Success,
                  `All products have been reconciled.`,
                  'Complete'
                )
              )
            }
          }
        })
        .catch((error) => {
          window.scrollTo(0, 0)
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error reconciling the vial. ${error}`
            )
          )
        })
        .finally(() => {
          setIsSavingVial(false)
          setSelectedVialRow(undefined)
        })
  }

  const handleDiscontinueFromProgram = (): void => {
    if (
      vialsToReconcileCounter &&
      vialsToReconcileCounter > 0 &&
      hasReconciliation
    ) {
      setIsReconcileWarningOpen(true)
    } else {
      handleOpenConfirmation()
    }
  }

  const handleCloseReconcileWarningModal = (): void => {
    setIsReconcileWarningOpen(false)
  }

  const handleBackToPatientDetails = () => {
    handleClearSubmission()
    getPatient()
  }

  const handleDownload = (documentItem: ProgramDocumentDto) => {
    documentItem.generatedId &&
      downloadDocumentById(documentItem.generatedId.toString())
        .then((response) => {
          documentItem.documentTypeName &&
            documentItem.documentUrl &&
            download(
              response.data,
              getFilenameFromUrl(documentItem.documentUrl),
              documentItem.documentTypeName
            )
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.DocumentDownload,
            {
              ...documentItem,
              patientId
            }
          )
        })
        .catch((error: AxiosError) => {
          // If request is cancelled continue
          if (error.message === AuthError.RequestCancelled) {
            return
          }
          window.scrollTo({
            behavior: 'smooth',
            top: 0
          })
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `There was an error downloading your document. ${error}`
            )
          )
        })
  }

  const goToAdverseEventPage = () => {
    history.push('/products/adverse-event')
  }

  const handleAdverseEventDownload = () => {
    if (program?.programDocuments && program.programDocuments.length > 0) {
      const adverseEventDocument: ProgramDocumentDto | undefined =
        program.programDocuments.find(
          (document: ProgramDocumentDto) =>
            document.documentTypeCode?.toUpperCase() ===
            PatientDocumentType.ADVERSE_EVENT_FORM.toUpperCase()
        )
      if (adverseEventDocument) {
        handleDownload(adverseEventDocument)
      } else {
        goToAdverseEventPage()
      }
    } else {
      goToAdverseEventPage()
    }
  }

  const handleOpenPatientAccessForm = () => {
    patientAccessFormUrl &&
      history.push(
        {
          pathname: `/programs/access-programs/${patient?.program.programId}/patient-access-form/${physicianId}`
        },
        {
          endpoint: patientAccessFormUrl,
          from: window.location.pathname
        }
      )
  }

  const handlePatientAccessFormError = () => {
    history.push('/about/contact-us')
  }

  const mockRedirect = () => {
    history.push(
      `/programs/my-physicians/${physicianId}/${patientId}/discontinue-patient`
    )
  }

  //endregion
  //region useEffects

  type Deps = [boolean, PatientDetailsDto, IRestEndpointState<InstituteDto>]
  useEffectOnlyOnce(
    (dependencies: Deps) => {
      analyticsServiceSingleton.trackPageView(AnalyticsPageEvent.ViewPatient, {
        'Physician ID': dependencies[1].currentPhysician.physicianId,
        'Patient ID': dependencies[1].patientId,
        'Institute ID': dependencies[2].data?.instituteId
      })
    },
    [
      isLoading || isProgramLoading || isLoadingPatientAccessForm,
      patient,
      institute
    ],
    (dependencies: Deps) =>
      dependencies[0] === false && dependencies[1] && dependencies[2]
  )

  const handleChangeTab = (tabIndex: number) => {
    setTabIndex(tabIndex)
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })
  }

  const tabData: TabModel[] = [
    {
      linkText: t('patient_detail:tab_title_one_past_orders'),
      showCountNumber: true,
      countNumber: augmentedOrders.length,
      tabIndex: 0,
      onClick: handleChangeTab
    },
    ...(showRecordTreatmentTab
      ? [
          {
            linkText: t('patient_detail:tab_title_two_past_orders'),
            showCountNumber: true,
            get countNumber() {
              const pastOrdersFiltered = pastOrders.filter(
                (o) => o.canBeReconciled
              )
              return pastOrdersFiltered.reduce((totalCount, order) => {
                return totalCount + order.vialsToReconcile.length
              }, 0)
            },
            tabIndex: 1,
            onClick: handleChangeTab
          }
        ]
      : [])
  ]

  const pastOrderColumns: IPastOrderColumn[] = [
    {
      title: t('patient_detail:past_orders.order_id_column'),
      renderValue: function column(order: ShortOrderSummaryDto) {
        return (
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            lineHeight={ClinTheme.lineHeights.heading[0]}
            fontWeight={ClinTheme.fontWeights.medium}
            color={ClinTheme.colors.tealishBlue}
          >
            {order.orderNumber}
          </ClinText>
        )
      },
      width: '20%',
      isSortable: true,
      name: PastOrderColumn.OrderNumber
    },
    {
      title: t('patient_detail:past_orders.products_column'),
      renderValue: function column(order: ShortOrderSummaryDto) {
        const medicationName = order.medications[0].name

        return (
          <Tooltip
            theme="light"
            position="bottom"
            trigger="mouseenter"
            arrow={true}
            title={medicationName}
          >
            <ClinText
              whiteSpace="nowrap"
              fontSize={ClinTheme.fontSizes[1]}
              lineHeight={ClinTheme.lineHeights.heading[0]}
              width="140px"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {medicationName}
            </ClinText>
          </Tooltip>
        )
      },
      width: '25%',
      isSortable: true,
      name: PastOrderColumn.Medications
    },
    {
      title: t('patient_detail:past_orders.date_placed_column'),
      renderValue: function column(order: ShortOrderSummaryDto) {
        return (
          <ClinText
            fontSize={ClinTheme.fontSizes[1]}
            lineHeight={ClinTheme.lineHeights.heading[0]}
            color="#4F5A65"
          >
            {order.orderedDate
              ? DateTime.fromISO(order.orderedDate)
                  .toFormat('dd/MMM/yyyy')
                  .toUpperCase()
              : ''}
          </ClinText>
        )
      },
      width: '20%',
      isSortable: true,
      name: PastOrderColumn.OrderedDate
    },
    {
      title: t('patient_detail:past_orders.status_column'),
      renderValue: function column(order: ShortOrderSummaryDto) {
        const validPatientStatuses = [
          PatientOrderStatus.Submitted,
          PatientOrderStatus.UnderMedicalReview,
          PatientOrderStatus.HealthAuthorityReview
        ]

        const cancelledStatuses = [
          PatientOrderStatus.MedicalReviewDeclined,
          PatientOrderStatus.HealthAuthorityDeclined
        ]

        const patientOrderStatus =
          patient?.patientOrderStatus as PatientOrderStatus

        if (order.orderStatus === OrderStatus.On_Hold) {
          order.orderStatus = validPatientStatuses.includes(patientOrderStatus)
            ? patientOrderStatus
            : PatientOrderStatus.UnderReview
        } else if (order.orderStatus === OrderStatus.Cancelled) {
          order.orderStatus = cancelledStatuses.includes(patientOrderStatus)
            ? patientOrderStatus
            : order.orderStatus
        }

        let statusComponent
        switch (order.orderStatus) {
          case PatientOrderStatus.Processing:
          case PatientOrderStatus.Submitted:
          case PatientOrderStatus.Shipped:
            statusComponent = (
              <StatusTooltip
                tooltipText={t(
                  patientOrderStatusTooltipTranslation[order.orderStatus]
                )}
              >
                <StyledStatusWrapper
                  color="rgba(0, 162, 35, 0.1)"
                  style={{ width: '90%' }}
                >
                  <ClinText
                    whiteSpace="nowrap"
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.greenValid}
                    textAlign="center"
                    fontSize={ClinTheme.fontSizes[0]}
                    lineHeight="2"
                  >
                    {t(patientOrderStatusTranslation[order.orderStatus])}
                  </ClinText>
                </StyledStatusWrapper>
              </StatusTooltip>
            )
            break
          case PatientOrderStatus.InTransit:
          case PatientOrderStatus.OutForDelivery:
          case PatientOrderStatus.Available_for_Pickup:
          case PatientOrderStatus.FailedAttempt:
            statusComponent = (
              <StatusTooltip
                tooltipText={t(
                  patientOrderStatusTooltipTranslation[order.orderStatus]
                )}
              >
                <StyledStatusWrapper
                  color="rgba(0, 162, 35, 0.1)"
                  style={{ width: '90%' }}
                >
                  <ClinText
                    whiteSpace="nowrap"
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.greenValid}
                    textAlign="center"
                    fontSize={ClinTheme.fontSizes[0]}
                    lineHeight="2"
                  >
                    {t(patientOrderStatusTranslation[order.orderStatus])}
                    {patient?.trackingLink! !== 'N/A' && (
                      <StyledTrackingLinkWrapper
                        href={patient?.trackingLink!}
                        text={t('patient_detail:patient_actions.track')}
                        target="_blank"
                      />
                    )}
                  </ClinText>
                </StyledStatusWrapper>
              </StatusTooltip>
            )
            break
          case PatientOrderStatus.Delivered:
            statusComponent = (
              <StyledStatusWrapper
                color="rgba(0, 162, 35, 0.1)"
                style={{ width: '90%' }}
              >
                <ClinText
                  whiteSpace="nowrap"
                  fontWeight={ClinTheme.fontWeights.medium}
                  color={ClinTheme.colors.greenValid}
                  textAlign="center"
                  fontSize={ClinTheme.fontSizes[0]}
                  lineHeight="2"
                >
                  {t(patientOrderStatusTranslation[order.orderStatus])}
                </ClinText>
              </StyledStatusWrapper>
            )
            break
          case PatientOrderStatus.UnderMedicalReview:
          case PatientOrderStatus.UnderReview:
          case PatientOrderStatus.HealthAuthorityReview:
            statusComponent = (
              <StatusTooltip
                tooltipText={t(
                  patientOrderStatusTooltipTranslation[order.orderStatus]
                )}
              >
                <StyledStatusWrapper
                  color="rgba(215, 106, 0, 0.10)"
                  style={{ width: '90%' }}
                >
                  <ClinText
                    whiteSpace="nowrap"
                    fontWeight={ClinTheme.fontWeights.medium}
                    color={ClinTheme.colors.orangeWarn}
                    textAlign="center"
                    fontSize={ClinTheme.fontSizes[0]}
                    lineHeight="2"
                  >
                    {t(patientOrderStatusTranslation[order.orderStatus])}
                  </ClinText>
                </StyledStatusWrapper>
              </StatusTooltip>
            )
            break
          default:
            statusComponent = (
              <StyledStatusWrapper
                color="rgba(117, 117, 117, 0.1)"
                style={{ width: '90%' }}
              >
                <ClinText
                  whiteSpace="nowrap"
                  fontWeight={ClinTheme.fontWeights.medium}
                  color={ClinTheme.colors.darkGrey}
                  textAlign="center"
                  fontSize={ClinTheme.fontSizes[0]}
                  lineHeight="2"
                >
                  {order.orderStatus}
                </ClinText>
              </StyledStatusWrapper>
            )
            break
        }

        return statusComponent
      },
      width: '25%',
      isSortable: true,
      name: PastOrderColumn.OrderStatus
    },
    {
      title: t('patient_detail:past_orders.patient_access_form_column'),
      renderValue: function column() {
        let isPatientAccessFormUrl = patientAccessFormUrl !== undefined
        return (
          <PatientAccessFormItem
            onClick={(e) => {
              e.stopPropagation()
              isPatientAccessFormUrl
                ? handleOpenPatientAccessForm()
                : handlePatientAccessFormError()
            }}
          >
            <StyledDocumentIcon
              iconName={ClinIconPathName.Document}
              iconFill={ClinTheme.colors.tealishBlue}
            />
            <ClinText
              marginTop={2}
              fontWeight={ClinTheme.fontWeights.bold}
              color={ClinTheme.colors.tealishBlue}
            >
              {isPatientAccessFormUrl
                ? t('common:buttons.view')
                : t('patient_detail:paf_error')}
            </ClinText>
          </PatientAccessFormItem>
        )
      },
      width: '10%',
      isSortable: false,
      name: PastOrderColumn.PatientAccessForm
    }
  ]

  const handleSortConfigChange = (
    sortColumn: string,
    sortOrder: SortDirectionType
  ) => {
    setPastOrderSortConfig({ sortColumn, sortOrder })
  }

  //endregion
  return (
    <>
      {!isConfirmationSubmitted ? (
        <>
          <DiscontinueWarningModal
            isOpen={isReconcileWarningOpen}
            handleClose={handleCloseReconcileWarningModal}
          />
          <DiscontinueConfirmationModal
            isOpen={isConfirmationOpen}
            isSubmitting={isConfirmationSubmitting}
            hasError={confirmationError?.isAxiosError}
            errorMessage={confirmationError?.message}
            handleSubmit={mockRedirect}
            handleClose={handleCloseConfirmation}
          />
          <PatientDetail
            isLoading={
              isLoading || isProgramLoading || isLoadingPatientAccessForm
            }
            detailsCtaButtonTitle={detailsCtaButtonTitle}
            isProgramClosed={isProgramClosed}
            allowsDiscontinuation={allowsDiscontinuation}
            patient={patient}
            pastOrders={pastOrders}
            selectedOrdersTabIndex={tabIndex}
            selectedVialRow={selectedVialRow}
            tabData={tabData}
            pastOrderColumns={pastOrderColumns}
            handleChangeTab={handleChangeTab}
            isFormOpen={isFormOpen}
            disableTransferButton={
              isTransferringAnotherInstitute && vialsToReconcileCounter > 0
            }
            vialsUnitType={program?.program?.unitType ?? null}
            vialReconciliationType={$enum(
              VialReconciliationType
            ).asValueOrDefault(
              program?.program?.reconciliationType,
              VialReconciliationType.DispositionBatchAndDate
            )}
            programRealWorldData={getShowRWDCard(program)}
            handleSortConfigChange={handleSortConfigChange}
            handleHideTransferForm={handleHideTransferForm}
            handleFormSubmission={handleFormSubmission}
            handleDestinationInputChange={handleDestinationInputChange}
            handlePrimaryCTA={handlePrimaryCTA}
            handleReferenceOrderClicked={handleReferenceOrderClicked}
            pagination={pagination}
            handlePageClicked={handlePageClicked}
            handlePageSizeChange={handlePageSizeChange}
            isSavingVial={isSavingVial}
            handleSaveChangesToVial={handleSaveChangesToVial}
            handleVialDateInputSelected={handleVialDateInputSelected}
            handleDiscontinueFromProgram={handleDiscontinueFromProgram}
            handleShowTransferForm={handleShowTransferForm}
            showRecordTreatmentTab={showRecordTreatmentTab}
          />
          <StyledCalendarModal
            isOpen={showCalendar.isOpen}
            isDismissible={true}
            maxWidth={'none'}
            removePadding={true}
            onClose={() => setShowCalendar({ ...showCalendar, isOpen: false })}
          >
            <StyledCalendarWrapper>
              <Calendar
                value={showCalendar.selectedDay}
                minimumDate={showCalendar.minimumDate}
                maximumDate={showCalendar.maximumDate}
                colorPrimary={ClinTheme.colors.primaryMid}
                colorPrimaryLight={ClinTheme.colors.primaryLight}
                onChange={handleDaySelected}
              />
              <StyledCalendarModal
                isOpen={showCalendar.isOpen}
                isDismissible={true}
                maxWidth={'none'}
                removePadding={true}
                onClose={() =>
                  setShowCalendar({ ...showCalendar, isOpen: false })
                }
              >
                <StyledCalendarWrapper>
                  <Calendar
                    value={showCalendar.selectedDay}
                    minimumDate={showCalendar.minimumDate}
                    maximumDate={showCalendar.maximumDate}
                    colorPrimary={ClinTheme.colors.primaryMid}
                    colorPrimaryLight={ClinTheme.colors.primaryLight}
                    onChange={handleDaySelected}
                  />
                </StyledCalendarWrapper>
              </StyledCalendarModal>
            </StyledCalendarWrapper>
          </StyledCalendarModal>
          <ClinModal
            isOpen={isPatientOrderNumber}
            isDismissible={false}
            onClose={() => setIsPatientOrderNumber(false)}
          >
            <ClinText variant={TypographyVariant.LargeParagraph}>
              Unable to load your order, please contact Customer Services to
              complete your order
            </ClinText>
            <ClinSpacer height={ClinTheme.space[4]} />
            <ClinGroup justifyContent="flex-end">
              <ClinButton
                variant="primary"
                onClick={() => setIsPatientOrderNumber(false)}
              >
                {t('common:buttons.ok')}
              </ClinButton>
            </ClinGroup>
          </ClinModal>
        </>
      ) : (
        <>
          {patient?.patientNumber && (
            <PatientDetailDiscontinued
              patientNumber={patient.patientNumber}
              handleAdverseEventDownload={handleAdverseEventDownload}
              handleBackToPatientDetails={handleBackToPatientDetails}
            />
          )}
        </>
      )}
    </>
  )
}
