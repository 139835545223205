import React, { FunctionComponent, ChangeEvent } from 'react'
import { StyledLabel, StyledRadio, StyledRadioMark } from './ClinRadio.styles'
import { IDefaultStyleProps } from '../../types'
import { ClinText } from '../ClinText'
import { ClinTheme } from '../../ClinTheme'

export interface IRadioStyles {
  /** Disables the input */
  disabled?: boolean
}

export interface IRadioProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    IDefaultStyleProps,
    IRadioStyles {
  /** HTML id attribute */
  id: string
  /** HTML name attribute */
  name?: string
  /** Sets the value of the checkbox */
  value?: string
  /** HTML label attribute */
  label: string
  /** React checked attribute for default state */
  defaultChecked?: boolean
  /** checked attribute */
  checked?: boolean
  /** Function that takes an event and returns nothing */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  /** Function that takes an event and returns nothing */
  onKeyPress?: (event: React.KeyboardEvent) => void
}

export const ClinRadio: FunctionComponent<IRadioProps> = ({
  className = '',
  children,
  name,
  label,
  id,
  value,
  disabled,
  checked,
  defaultChecked,
  onChange,
  onKeyPress,
  ...rest
}) => (
  <StyledLabel
    title={label}
    htmlFor={id}
    disabled={disabled}
    className={`clin-radio ${className}`}
  >
    <StyledRadio
      {...rest}
      type="radio"
      id={id}
      name={name}
      value={value}
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
        onKeyPress?.(event)
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event)
      }}
    />
    <StyledRadioMark disabled={disabled} />
    <ClinText
      color={disabled ? ClinTheme.colors.darkGrey : 'inherit'}
      fontSize={ClinTheme.fontSizes[2]}
      as="div"
    >
      {children}
    </ClinText>
  </StyledLabel>
)
