import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'

export const StyledClinTabs = styled.div({
  display: 'flex',
  alignContent: 'stretch',
  textTransform: 'uppercase',
  [`.clin-tab-header`]: {
    borderRight: `solid 1px ${ClinTheme.colors.midGrey}`
  },
  [`.clin-tab-header:last-child`]: {
    borderRight: 'none'
  }
})
