import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { StyledSelectLabel } from '../../components/ClinSelect/ClinSelect.styles'
import { StyledLabel } from '../../components/ClinTextInput/ClinTextInput.styles'
import { mediaQuery } from '../../utils/mediaQuery'

const tabletBreakpoint = ClinTheme.breakpoints[2]
export interface IEnrolUserOnToProgramStyleProps {
  /** If the user is submitting their request */
  isSubmitting?: boolean
}

const mobileUpBreakpoint = ClinTheme.breakpoints[1]

export const StyledSpinnerContainer = styled.div({
  display: 'flex',
  height: '50vh',
  alignItems: 'center',
  justifyContent: 'center'
})

export const StyledEnrolledIcon = styled.div({
  borderRadius: '50%',
  width: ClinTheme.space[5],
  height: ClinTheme.space[5],
  display: 'inline-flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  color: ClinTheme.colors.white,
  backgroundColor: ClinTheme.colors.secondary
})

export const StyledEnrolledPhysicians = styled.div({
  borderTop: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`
})

export const StyledEnrolledPhysician = styled.div({
  display: 'flex',
  alignItems: 'center',
  paddingTop: ClinTheme.space[4],
  paddingBottom: ClinTheme.space[4],
  paddingLeft: ClinTheme.space[3],
  borderBottom: `${ClinTheme.borderWidths[0]}px solid ${ClinTheme.colors.grey}`
})

export const StyledEnrolledPhysicianText = styled.div({})

export const StyledEnrolledPhysicianIcon = styled.div({
  borderRadius: '50%',
  width: ClinTheme.space[4],
  height: ClinTheme.space[4],
  marginRight: ClinTheme.space[3],
  display: 'inline-flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  color: ClinTheme.colors.white,
  backgroundColor: ClinTheme.colors.secondary
})

export const StyleAdditionalPhysician = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: `${ClinTheme.space[3]}px 0`,
  flexDirection: 'row',
  justifyContent: 'space-between'
})

export const StyleAdditionalPhysicianAction = styled.div({
  fontFamily: ClinTheme.fonts.body,
  fontSize: ClinTheme.fontSizes[1],
  color: ClinTheme.colors.primaryLight,
  fontWeight: ClinTheme.fontWeights.medium,
  textTransform: 'uppercase',
  cursor: 'pointer',
  marginLeft: ClinTheme.space[3]
})

export const StyleAdditionalPhysicianTitle = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: ClinTheme.space[4]
})

export const StyleAdditionalPhysicianBadge = styled.div({
  borderRadius: '4px',
  padding: '2px 16px'
})

export const StyleAdditionalPhysicians =
  styled.div<IEnrolUserOnToProgramStyleProps>(
    {
      display: 'flex',
      flexDirection: 'column',
      padding: '22px 22px 0 22px',
      marginBottom: ClinTheme.space[5],
      borderRadius: '6px',
      boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
      [`${StyleAdditionalPhysician}`]: {
        background: ClinTheme.colors.midGrey,
        borderRadius: '4px',
        padding: '22px',
        marginBottom: '22px'
      }
    },
    ({ isSubmitting }) => ({
      [`${StyleAdditionalPhysicianAction}`]: {
        opacity: isSubmitting ? '0.5' : '1',
        cursor: isSubmitting ? 'none' : 'pointer'
      }
    })
  )

export const StyleAdditionalPhysicianText = styled.div({})

export const StyledEnrolPhysicianDetailsForm = styled.div({
  display: 'flex',
  justifyContent: 'space-between',

  [`${StyledSelectLabel}, ${StyledLabel}`]: {
    marginBottom: ClinTheme.space[3]
  },

  [mediaQuery(mobileUpBreakpoint)]: {
    [`${StyledSelectLabel}, ${StyledLabel}`]: {
      marginBottom: 0
    }
  }
})

export const StyledActions = styled.div({
  display: 'inline-flex',
  flexDirection: 'column-reverse',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  width: '100%',
  paddingTop: ClinTheme.space[6],

  button: {
    marginBottom: ClinTheme.space[3]
  },

  [mediaQuery(mobileUpBreakpoint)]: {
    flexDirection: 'row',
    flexWrap: 'nowrap',

    button: {
      marginBottom: 0,
      marginRight: ClinTheme.space[3]
    },

    'button:last-of-type': {
      marginBottom: 0
    }
  }
})

export const StyledSuccessActions = styled.div({
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
  width: '100%',
  paddingTop: ClinTheme.space[6],

  button: {
    width: '100%'
  },

  'button:first-of-type': {
    marginBottom: ClinTheme.space[3]
  },

  [mediaQuery(mobileUpBreakpoint)]: {
    flexWrap: 'nowrap',
    flexDirection: 'row',

    button: {
      marginBottom: 0,
      marginRight: ClinTheme.space[3],
      width: 'auto'
    },

    'button:first-of-type': {
      marginBottom: 0
    }
  }
})

export const StyledPersonalDetails = styled.div({
  marginBottom: ClinTheme.space[7]
})

export const StyledPersonalDetailsRow = styled.div({
  width: '100%',
  paddingTop: ClinTheme.space[4],
  paddingBottom: ClinTheme.space[4],
  borderBottom: `1px solid ${ClinTheme.colors.grey}`,

  [mediaQuery(tabletBreakpoint)]: {
    paddingRight: ClinTheme.space[2],
    paddingLeft: ClinTheme.space[2]
  }
})

export const StyledLoader = styled.span({
  display: 'flex',
  marginLeft: ClinTheme.space[2]
})

export const StyledContactUsAction = styled.span({
  color: ClinTheme.colors.primaryLight,
  cursor: 'pointer'
})

export const StyledEnrolledSuccessCard = styled.div({
  maxWidth: '620px',
  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
  borderRadius: '6px',
  padding: '24px 22px 24px 22px',
  marginBottom: '24px'
})

export const StyledEnrolledSuccessCardAnnounceBar = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  backgroundColor: ClinTheme.colors.lightPurple,
  border: `1px solid ${ClinTheme.colors.darkPurple4}`,
  borderRadius: '4px',
  color: ClinTheme.colors.darkPurple4,
  padding: ClinTheme.space[3]
})

export const StylesEnrolledSuccessCardIcon = styled.div({
  borderRadius: '50%',
  border: `2px solid ${ClinTheme.colors.darkPurple4}`,
  color: ClinTheme.colors.darkPurple4,
  width: '28px',
  height: '28px',
  display: 'inline-flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  marginBottom: '14px',
  marginTop: '14px'
})

export const StyledEnrolledSuccessPhysicianDetails = styled.div({
  backgroundColor: ClinTheme.colors.midGrey,
  borderRadius: '4px',
  padding: ClinTheme.space[3],

  [`:not(:last-child)`]: {
    marginBottom: ClinTheme.space[3]
  }
})
