import React, { FunctionComponent, useEffect, useState } from 'react'
import { IOrder } from '../../types/IOrder'
import { OrderDto, UpdateBasketDetailsDto } from '../../types/swaggerTypes'
import { Checkout } from './Checkout'
import {
  cancelDraftOrder,
  submitOrder,
  updateBasketOrder
} from '../../services/ApiService'
import { createAnnounceEvent } from '../../events/AnnounceEvent'
import { AnnounceMode } from '../../components/ClinAnnounceBar/ClinAnnounceBar'
import { RouteComponentProps } from 'react-router'
import {
  defaultBasketDetails,
  OrderDelivery,
  regularShippingOptions,
  UserRoleRecord
} from '../../constants'
import { useAppContext } from '../../context/app'
import {
  ActionType,
  BasketStatus,
  getBasketAsync,
  useBasket
} from '../../context/basket'
import { usePriceAndAvailability } from './Checkout.models'
import { AxiosResponse } from 'axios'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../services/Analytics'
import { handleBasketError } from '../../utils/basketUtils'
import { noop } from '../../utils/noop'
import { showWootricSurvey } from '../../services/Wootric/showWootricSurvey'
import { useCountries } from '../../utils/useCountries'
import { returnDefaultBasketValues } from '../Basket/Basket'

export enum CheckoutViewMode {
  ShowOrders = 'ShowOrders',
  Submitting = 'Submitting',
  Loading = 'Loading',
  Redirect = 'Redirect'
}

export const CheckoutContainer: FunctionComponent<RouteComponentProps> = ({
  history
}) => {
  const { dispatch, defaultShippingAddress, userRole, user, userDetails } =
    useAppContext()
  const [{ orders, viewBasket, basket, completedOrder }, basketDispatch] =
    useBasket()
  const [orderWasSubmitted, setOrderWasSubmitted] = useState<boolean>(false)
  const ordersPriceAndAvailability = usePriceAndAvailability(
    orders,
    defaultShippingAddress?.addressId
  )
  const [augmentedOrders, setAugmentedOrders] = useState<IOrder[]>([])
  const [viewMode, setViewMode] = useState<CheckoutViewMode>(
    CheckoutViewMode.Loading
  )
  const [isOrderSplit, setIsOrderSplit] = useState<boolean>(false)
  const { countries } = useCountries()

  const defaultCountry =
    countries && countries.find((c) => c.countryCode === 'GB')

  const hasStandardShipping = !!(
    defaultCountry &&
    userRole &&
    !UserRoleRecord[userRole].isMaUser
  )

  const handleBackToDeliveryOptions = () => {
    if (
      window.confirm(
        `Are you sure you want to leave the checkout and return to delivery options?`
      )
    ) {
      setViewMode(CheckoutViewMode.Loading)
      cancelDraftOrder()
        .then((response) => {
          if (response.status === 200) {
            history.push('/checkout/delivery')
            return
          }
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `An error occurred restoring your basket. ${response.status}`
            )
          )
        })
        .catch((error) => {
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `An error occurred restoring your basket. ${error}`
            )
          )
        })
    }
  }

  /**
   * Order is actually booked at this step
   */
  const handlePlaceOrderSubmit = () => {
    const checkoutStamp = basket?.data?.checkoutStamp
    if (!checkoutStamp) {
      return
    }

    setViewMode(CheckoutViewMode.Submitting)
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    })

    // Do we need to update order with custom delivery?
    const ordersWithCustomDelivery =
      augmentedOrders &&
      augmentedOrders.filter((o) => o.deliveryOption === OrderDelivery.Custom)

    const updateOrderCallPromises: Promise<AxiosResponse<OrderDto>>[] =
      ordersWithCustomDelivery.map((order) =>
        updateBasketOrder(order.orderNumber, {
          shipmentHold: 'Y'
        })
      )

    Promise.all(
      updateOrderCallPromises.map((p, index) =>
        p.catch((e) =>
          Promise.reject(
            `Error updating order ${index} or ${updateOrderCallPromises.length}: ${e}`
          )
        )
      )
    )
      .then((response) => noop())
      .then(() => {
        return submitOrder(checkoutStamp)
      })
      .then((response) => {
        response.data.checkedOutOrders.map((order) =>
          analyticsServiceSingleton.trackEvent(AnalyticsEvent.OrderCompleted, {
            order: order.orderNumber,
            order_number: order.orderNumber, // for hubspot format
            division: 'GA',
            institute_id: user?.institute?.instituteId,
            has_hold: order.holds.length > 0,
            order_value: order.totals.total
          })
        )
        setOrderWasSubmitted(true)
        basketDispatch({
          type: ActionType.OrderSubmitted,
          completedOrder: response.data
        })
        //remove expected delivery date from basket context after submit order
        basketDispatch({
          type: ActionType.SetExpectedDeliveryDate
        })
        //set basket details to default values after submit order
        let basketDetails: UpdateBasketDetailsDto = defaultBasketDetails
        if (userRole && userDetails)
          basketDetails = returnDefaultBasketValues(userRole, userDetails)
        basketDispatch({
          type: ActionType.UpdateBasketDetails,
          basketDetails: basketDetails
        })
        // Trigger survey and pass through user and order numbers
        showWootricSurvey(
          user,
          response.data.checkedOutOrders.map((o) => o.orderNumber).join(','),
          'GA'
        )
      })
      .catch((error) => {
        const basketError = handleBasketError(error, dispatch)
        !basketError &&
          dispatch(
            createAnnounceEvent(
              AnnounceMode.Error,
              `An error occurred submitting your order. ${error}`
            )
          )
      })
  }

  // Extend the orders into IOrder objects
  useEffect(() => {
    if (orders && orders.length > 0) {
      const augOrders: IOrder[] = orders.map((order: OrderDto, index) => {
        // Build up list of delivery options
        let allAvailableDeliveryOptions: OrderDelivery[]
        const offersRegularDelivery =
          regularShippingOptions.indexOf(order.shipMethod) > -1

        // If we have regular we have both
        if (offersRegularDelivery) {
          allAvailableDeliveryOptions = [
            OrderDelivery.Regular,
            OrderDelivery.Custom
          ]
        } else {
          // Only custom - no DHL
          allAvailableDeliveryOptions = [OrderDelivery.Custom]
        }

        return {
          ...order,
          useCurrentDetails: true,
          deliveryOption:
            offersRegularDelivery && hasStandardShipping
              ? OrderDelivery.Regular
              : OrderDelivery.Custom, // by default select regular or custom if only one
          availableDeliveryOptions: allAvailableDeliveryOptions
        }
      })
      setAugmentedOrders(augOrders)
    }
    setViewMode(CheckoutViewMode.ShowOrders)
  }, [hasStandardShipping, orders])

  useEffect(() => {
    if (orders && orders.length > 0 && !orderWasSubmitted) {
      orders.length > 1 && setIsOrderSplit(true)
      setViewMode(CheckoutViewMode.ShowOrders)
    }
  }, [orderWasSubmitted, orders])

  // When order has been submitted OK proceed
  useEffect(() => {
    if (
      completedOrder &&
      orderWasSubmitted &&
      viewBasket?.state === BasketStatus.Submitted.toString()
    ) {
      // Get the new basket
      getBasketAsync(basketDispatch).then(() => {
        // Empty basket and refresh
        // This is commented releted to ticket clos-2075
        // basketDispatch({
        //   type: ActionType.EmptyBasket
        // })

        history.push(`/checkout/summary`)
      })
    }
  }, [basketDispatch, history, orderWasSubmitted, viewBasket, completedOrder])

  // If we navigate away remove orders - no way of knowing what
  useEffect(
    () => () => {
      cancelDraftOrder()
        .then(() => console.log('Cancelled order'))
        .catch((error) =>
          console.warn(`Attempt to cancel order failed: ${error}`)
        )
    },
    []
  )

  // FIXME: This is causing an issue when completing an order - needs addressing
  // If the user hits this directly then go to basket
  // useEffect(() => {
  //   if (viewBasket?.state === BasketStatus.Open) {
  //     setViewMode(CheckoutViewMode.Redirect)
  //   }
  // }, [viewBasket])
  //
  // if (viewMode === CheckoutViewMode.Redirect) {
  //   return <Redirect to={'/orders'} />
  // }

  return (
    <Checkout
      viewMode={viewMode}
      orders={augmentedOrders}
      orderWasSubmitted={orderWasSubmitted}
      priceAndAvailability={ordersPriceAndAvailability}
      isOrderSplit={isOrderSplit}
      handleBackToDeliveryOptions={handleBackToDeliveryOptions}
      handleOrderSubmit={handlePlaceOrderSubmit}
    />
  )
}
