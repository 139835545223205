import React, { FunctionComponent } from 'react'
import { Col, Row } from 'react-grid-system'
import { ClinTheme } from '../../ClinTheme'
import { ClinIcon } from '../../components/ClinIcon'
import { ClinIconPathName } from '../../components/ClinIcon/ClinIcon.paths'
import { ClinText } from '../../components/ClinText'
import { TypographyVariant } from '../../components/ClinText/ClinText.styles'
import { StyledNoPhysicians } from './NoPhysicians.styles'

export const NoPhysicians: FunctionComponent = () => (
  <StyledNoPhysicians>
    <ClinIcon
      iconName={ClinIconPathName.Profile}
      iconSize={150}
      iconFill={ClinTheme.colors.grey}
    />
    <Row>
      <Col>
        <ClinText
          textAlign={'center'}
          variant={TypographyVariant.H4}
          fontWeight={ClinTheme.fontWeights.bold}
        >
          {"You haven't associated with a physician yet"}
        </ClinText>
      </Col>
    </Row>
    <Row>
      <Col sm={0} md={2} />
      <Col sm={12} md={8}>
        <ClinText
          textAlign={'center'}
          fontSize={ClinTheme.fontSizes[2]}
          fontWeight={ClinTheme.fontWeights.light}
        >
          {'You can associate with a physician when you enrol in a program.'}
        </ClinText>
      </Col>
      <Col sm={0} md={2} />
    </Row>
  </StyledNoPhysicians>
)
