import {
  CatalogItemDto,
  InstituteDto,
  ItemDocumentDto,
  StockDto,
  WarehouseDto
} from '../../../types/swaggerTypes'
import {
  AvailableWarehouse,
  IAugmentedCatalogItemDto
} from '../../../services/PortfolioJourneys/PortfolioJourney.types'
import { TSourcingEnquiryParams } from '../../SourcingEnquiry/SourcingEnquiryContainer'
// @ts-ignore
import queryString from 'querystring-es3'
import { ProductDocumentUiState } from './ProductDetailContainer'
import { getDefaultWarehouseCode } from '../ProductVariant/ProductVariant.models'

/** Generate sourcing enquiry link */
export const getSourcingEnquiryLink = (
  catalogueItem: IAugmentedCatalogItemDto
): string => {
  const urlParams: TSourcingEnquiryParams = {
    generic: catalogueItem.genericConcatenation
      ? catalogueItem.genericConcatenation
      : '',
    brand: catalogueItem.item.brand ? catalogueItem.item.brand : ''
  }
  return `../../../sourcing-enquiry?${queryString.stringify(urlParams)}`
}

/**
 * Extract an array of documents from the product for analytics
 * @param documents
 */
export const getProductDocsInformation = (
  documents: ItemDocumentDto[]
): object => {
  return documents.map((doc) => {
    return {
      'Document type': doc.documentType,
      'Document sku': doc.sku,
      'Document generatedId': doc.generatedId,
      'Document language': doc.documentLanguage
    }
  })
}

/**
 * Utility method to determine the default warehouse code
 * Business logic =
 * In the ItemDTO we’re returned a defaultWarehouse value
 * If this value is null then use C02 (Byfleet)
 * @param catalogueItem
 */
export const getDefaultWarehouseCodeForCatalogueItem = (
  catalogueItem: CatalogItemDto
): string => {
  return catalogueItem.item.defaultWarehouse || AvailableWarehouse.C02
}

/**
 *
 * @param institute
 */
export const getDefaultWarehouseCodeForInstitute = (
  institute?: InstituteDto
): string | undefined => {
  return institute && institute.warehouses && institute.warehouses.length > 0
    ? institute.warehouses[0].warehouseCode
    : undefined
}

/**
 * Method to return a warehouse for a bookmark
 * In the ItemDTO we’re returned a defaultWarehouse value
 * If this value is null then use C02 (Byfleet)
 * @param catalogueItem
 */
export const getDefaultWarehouseInfoForSku = (
  catalogueItem: CatalogItemDto,
  portfolioCountryCode: string
): WarehouseDto | undefined => {
  const defaultWarehouseCode =
    catalogueItem.item.defaultWarehouse ||
    getDefaultWarehouseCode(portfolioCountryCode)
  const defaultWareHouseInfo: StockDto | undefined =
    catalogueItem.stockLevels.find(
      (s) => s.warehouse === getDefaultWarehouseCode(portfolioCountryCode)
    )
  const warehouseFromStocksArray: StockDto | undefined =
    catalogueItem.stockLevels.find((s) => s.warehouse === defaultWarehouseCode)
  return warehouseFromStocksArray
    ? warehouseFromStocksArray?.warehouseInfo
    : defaultWareHouseInfo?.warehouseInfo
}

/**
 * Get the documents we need to show
 * @param augmentedCatalogueItem
 */
export const getVisibleProductDocuments = (
  augmentedCatalogueItem: IAugmentedCatalogItemDto
): ProductDocumentUiState[] | undefined =>
  augmentedCatalogueItem.documents
    .filter((document: ItemDocumentDto) => document.externallyVisible === 'Y')
    ?.map((item: ItemDocumentDto) => {
      return {
        isDownloading: false,
        document: item
      }
    })
