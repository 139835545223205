import {
  getInputValidation,
  ValidationType
} from '../../utils/Forms/getInputValidation'
import { ClinMultiSelectOption } from '../../components/ClinMultiSelect/ClinMultiSelect'

/**
 * Custom yup test function to validate that the multi select options only go up to 250 characters max
 * N.B. This is to work around a limitation with EBS forms accepting a maximum of 250 characters
 */
export function getArrMax250CharsOptions(value: any, args: any) {
  const { path, createError } = args
  const arr: ClinMultiSelectOption[] | undefined = value as
    | ClinMultiSelectOption[]
    | undefined
  if (arr && arr.map((option) => option.value).join(', ').length > 250) {
    return createError({
      path,
      message: getInputValidation(ValidationType.TooManyOptionsSelected)
    })
  }
  return true
}
