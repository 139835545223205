import { AxiosError } from 'axios'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState
} from 'react'
import { RouteComponentProps, StaticContext, matchPath } from 'react-router'
import { useAppContext } from '../../context/app'
import { enrolPhysiciansOnToProgram } from '../../services/ApiService'
import { useErrorMessage } from '../../utils/useErrorMessage'
import {
  SideBarMode,
  useProgramStatus
} from '../Programs/ProgramDetail/ProgramDetail.models'
import { EnrolUserOnToProgram } from './EnrolUserOnToProgram'
import {
  useEnrolUserPageState,
  usePhysiciansToEnrol
} from './EnrolUserOnToProgram.model'
import { EnrolUserOnToProgramSuccess } from './EnrolUserOnToProgramSuccess/EnrolUserOnToProgramSuccess'
import analyticsServiceSingleton from '../../services/Analytics/initAnalytics'
import { AnalyticsEvent } from '../../services/Analytics'
import { useOnMount } from '../../utils/useOnMount'
import { PhysiciansSummaryDto } from '../../types/swaggerTypes'
import { ProgramEnroledEventType } from '../../events/ProgramEvents'

interface IEnrolUserOnToProgramRouteParams {
  programId: string
}

type LocationState = {
  from?: string
  programId?: string
  physicianIds?: string[]
}

interface IEnrolUserOnToProgramProps
  extends RouteComponentProps<
    IEnrolUserOnToProgramRouteParams,
    StaticContext,
    LocationState
  > {}

export const EnrolUserOnToProgramContainer: FunctionComponent<
  IEnrolUserOnToProgramProps
> = ({ match, history }) => {
  const { userDetails, institute, dispatch } = useAppContext()
  const { programId } = match.params
  const handleError = useErrorMessage(
    'There was an error submitting your request.',
    true
  )
  const { isProgramLoading, program, programUiState } =
    useProgramStatus(programId)
  const notEnrolled =
    !isProgramLoading && programUiState?.sideBarMode === SideBarMode.NotEnrolled
  const {
    physiciansLoading,
    physicians,
    selectedPhysicians,
    savedPhysicians,
    handleOnSelect,
    handlePhysicianRemoval,
    handleSavedPhysicianDetails,
    handleSavedPhysicianRemoval
  } = usePhysiciansToEnrol(programId, notEnrolled)

  const {
    currentStep,
    selectedTabIndex,
    disableSkip,
    associateConfirmation,
    handleGoToNextStep,
    handleGoToPreviousStep,
    handleTabSelected,
    handleRequestToAddPhysician,
    handleConfirmationSelect,
    handleGetSteppers
  } = useEnrolUserPageState(physicians, selectedPhysicians, savedPhysicians)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isSubmitted, setSubmitted] = useState<boolean>(false)
  const [isFormEdited, setIsFormEdited] = useState<boolean>(false)

  const patientsPath = '/patients'
  const createPatientPath = '/create-patient'

  const [fromPage, setFromPage] = useState<string>('')

  const handleRequestToViewProgram = useCallback((): void => {
    history.push(`/programs/access-programs/${programId}`)
  }, [history, programId])

  const handleRequestToViewDashboard = (): void => {
    history.push('/orders')
  }

  const handleRequestToViewPatientDashboard = (): void => {
    history.push('/patients')
  }

  const handleGoToContactUs = (): void => {
    history.push('/about/contact-us')
  }

  useOnMount(() => {
    const fromPath = history.location.state?.from || ''
    const matchCreatePatient = matchPath(fromPath, {
      path: createPatientPath
    })
    const matchPatientDashboard = matchPath(fromPath, {
      path: patientsPath
    })

    if (matchCreatePatient || matchPatientDashboard) {
      setFromPage(fromPath)
    }
  })

  const redirectToProgramDetailPage = useCallback((): void => {
    const searchParams = new URLSearchParams()
    searchParams.set('autoenrolled', 'true')
    history.push({
      pathname: `/programs/access-programs/${programId}`,
      search: `?${searchParams.toString()}`
    })
  }, [history, programId])

  const redirectToPatientsDashboard = useCallback((): void => {
    history.push(patientsPath)
  }, [history])

  const redirectToCreatePatientPage = useCallback(
    (selectedPhysiciansToBePassed: PhysiciansSummaryDto[]): void => {
      history.push({
        pathname: createPatientPath,
        state: {
          from: window.location.pathname,
          programId: programId,
          physicianIds: selectedPhysiciansToBePassed?.map((x) =>
            x.physicianId.toString()
          )
        }
      })
    },
    [history, programId]
  )

  const handleSubmit = () => {
    setIsSubmitting(true)
    enrolPhysiciansOnToProgram(programId, {
      enrolReason: '',
      existingPhysicians: selectedPhysicians ? selectedPhysicians : [],
      newPhysicians: savedPhysicians ? savedPhysicians : []
    })
      .then((response) => {
        if (response.data.isAutoEnrolled) {
          localStorage.setItem('patientFilterChecked', 'false')
          setSubmitted(true)
          if (
            (!savedPhysicians || savedPhysicians.length === 0) &&
            fromPage === patientsPath
          ) {
            redirectToProgramDetailPage()
          } else {
            switch (fromPage) {
              case patientsPath:
                redirectToPatientsDashboard()
                break

              case createPatientPath:
                redirectToCreatePatientPage(
                  selectedPhysicians ? selectedPhysicians : []
                )
                break

              default:
                redirectToProgramDetailPage()
                break
            }
          }
          dispatch({
            type: ProgramEnroledEventType.AUTOENROLEDEVENT
          })
        } else if (response.data.incidentNumber) {
          setSubmitted(true)
          analyticsServiceSingleton.trackEvent(
            AnalyticsEvent.EnrolUserOntoProgram,
            {
              programId,
              programName: program?.programName,
              userId: userDetails?.contactCard.contactId
            }
          )
        }
      })
      .catch((error: AxiosError) => {
        window.scrollTo(0, 0)
        handleError(error)
      })
      .finally(() => setIsSubmitting(false))
  }

  const handleIsFormEdited = (isEdited: boolean) => {
    setIsFormEdited(isEdited)
  }

  useEffect(() => {
    if (programUiState && !isProgramLoading && !notEnrolled) {
      handleRequestToViewProgram()
    }
  }, [
    handleRequestToViewProgram,
    isProgramLoading,
    notEnrolled,
    programUiState
  ])

  return (
    <>
      {!isSubmitted ? (
        <EnrolUserOnToProgram
          isLoading={
            isProgramLoading || physiciansLoading || !userDetails || !institute
          }
          userDetails={userDetails}
          institute={institute.data}
          program={program}
          currentStep={currentStep}
          selectedSteppers={handleGetSteppers()}
          savedPhysicians={savedPhysicians}
          physicians={physicians}
          selectedPhysicians={selectedPhysicians}
          selectedTabIndex={selectedTabIndex}
          associateConfirmation={associateConfirmation}
          disableSkip={disableSkip}
          isSubmitting={isSubmitting}
          isFormEdited={isFormEdited}
          isSubmitted={isSubmitted}
          isAutoEnrolled={program?.program?.autoEnrolmentFlag === 'Yes'}
          handleTabSelected={handleTabSelected}
          handleOnSelect={handleOnSelect}
          handleRequestToAddPhysician={handleRequestToAddPhysician}
          handlePhysicianRemoval={handlePhysicianRemoval}
          handleSavedPhysicianDetails={handleSavedPhysicianDetails}
          handleIsFormEdited={handleIsFormEdited}
          handleSavedPhysicianRemoval={handleSavedPhysicianRemoval}
          handleGoToNextStep={handleGoToNextStep}
          handleGoToPreviousStep={handleGoToPreviousStep}
          handleConfirmationSelect={handleConfirmationSelect}
          handleSubmit={handleSubmit}
          handleRequestToViewProgram={handleRequestToViewProgram}
          handleGoToContactUs={handleGoToContactUs}
        />
      ) : (
        <EnrolUserOnToProgramSuccess
          program={program}
          savedPhysicians={savedPhysicians}
          selectedPhysicians={selectedPhysicians}
          fromPatientDashboard={fromPage === patientsPath}
          handleRequestToViewDashboard={handleRequestToViewDashboard}
          handleRequestToViewProgram={handleRequestToViewProgram}
          handleRequestToViewPatientDashboard={
            handleRequestToViewPatientDashboard
          }
        />
      )}
    </>
  )
}
