export const getPathValueFromUrl = (url: string, pathValue: string): string => {
  if (url) {
    const pathNameArr = url.split('/')
    let pathValueIndex = pathNameArr.findIndex((elem) => elem === pathValue)
    if (pathValueIndex > 0) {
      return pathNameArr[pathValueIndex + 1]
    }
  }

  return ''
}
