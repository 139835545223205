import styled from 'styled-components'
import { ClinTheme } from '../../ClinTheme'
import { mediaQuery } from '../../utils/mediaQuery'

const xLargeUpBreakpoint = ClinTheme.breakpoints[3]

export const StyledNavIconButton = styled.div({
  display: 'inline-block',
  alignItems: 'center',
  padding: `1px ${ClinTheme.space[1]}px ${ClinTheme.space[1]}px ${ClinTheme.space[1]}px`,
  flexDirection: 'column',
  cursor: 'pointer',
  [mediaQuery(xLargeUpBreakpoint)]: {
    display: 'inline-flex',
    padding: `${ClinTheme.space[4]}px ${ClinTheme.space[3]}px ${ClinTheme.space[3]}px`,
    position: 'relative',
    ':before': {
      position: 'absolute',
      content: '""',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderLeftStyle: 'solid',
      borderLeftColor: ClinTheme.colors.midGrey,
      borderLeftWidth: `${ClinTheme.borderWidths[0]}px`
    },
    '&:hover': {
      backgroundColor: ClinTheme.colors.midGrey,
      transition: '.2s linear background-color'
    }
  }
})

export const StyledNavTextAndChevron = styled.div({
  display: 'inline-flex',
  [mediaQuery(xLargeUpBreakpoint)]: {
    alignItems: 'center',
    marginTop: ClinTheme.space[2],
    flex: '1 0 auto'
  }
})

export const StyledIconWrapper = styled.div({
  position: 'relative'
})
