import React, { FunctionComponent } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { ClinTheme } from '../../../ClinTheme'
import { ClinButton } from '../../../components/ClinButton'
import { ClinModal } from '../../../components/ClinModal'
import { ClinText } from '../../../components/ClinText'
import { TypographyVariant } from '../../../components/ClinText/ClinText.styles'
import {
  StyledActions,
  StyledSubmit
} from './TransferOrDiscontinueModal.styles'

interface ITransferOrDiscontinueModalProps {
  /** Control internal state of modal **/
  isOpen?: boolean
  /** Physician name */
  physicianName: string
  /** Whether the submission is still processing **/
  handleConfirmation: () => void
  /** Handle close modal **/
  handleClose: () => void
}

export const TransferOrDiscontinueModal: FunctionComponent<
  ITransferOrDiscontinueModalProps
> = ({ isOpen, physicianName, handleConfirmation, handleClose }) => {
  const { t } = useTranslation()

  return (
    <>
      <ClinModal
        onClose={handleClose}
        maxWidth="sm"
        height="auto"
        isOpen={isOpen}
      >
        <Container className="dissociate-physician-confirmation">
          <Row justify="center">
            <Col sm={12} md={11}>
              <Row>
                <Col sm={12}>
                  <ClinText
                    as="h3"
                    variant={TypographyVariant.H3}
                    fontWeight={ClinTheme.fontWeights.bold}
                  >
                    {t(
                      'physician_details:transfer_or_discontinue_modal_title',
                      {
                        physicianName: physicianName
                      }
                    )}
                  </ClinText>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ClinText variant={TypographyVariant.LargeParagraph}>
                    {t(
                      'physician_details:transfer_or_discontinue_modal_description'
                    )}
                  </ClinText>
                </Col>
              </Row>

              <StyledSubmit>
                <Row justify="between">
                  <Col md={5} lg={6}>
                    <StyledActions>
                      <ClinButton
                        className="cancel-disassociation"
                        onClick={() => handleClose()}
                      >
                        {t('common:buttons.cancel')}
                      </ClinButton>
                      <ClinButton
                        className="confirm"
                        variant="primary"
                        onClick={() => handleConfirmation()}
                      >
                        {t('physician_details:view_patients')}
                      </ClinButton>
                    </StyledActions>
                  </Col>
                </Row>
              </StyledSubmit>
            </Col>
          </Row>
        </Container>
      </ClinModal>
    </>
  )
}
