import { AxiosError } from 'axios'
import { useState, useEffect } from 'react'
import { AnnounceMode } from '../components/ClinAnnounceBar/ClinAnnounceBar'
import { useAppContext } from '../context/app'
import { createAnnounceEvent } from '../events/AnnounceEvent'
import { LovName } from '../constants'
import { getLov, cancelGetLov } from '../services/ApiService'
import { LovDto } from '../types/swaggerTypes'

export interface ILov {
  /** Whether service is loading or not */
  isLovLoading: boolean
  /** The returned values from the service */
  lovValues: LovDto[]
}

/**
 * Custom hook to retrieve an array of lov values
 * @usage:
 * const {isLovLoading,lovValues} = useLov(LovName.SPECIALISM)
 */
export const useLov = (lovType: LovName): ILov => {
  const [lovValues, setLovValues] = useState<LovDto[]>([])
  const [isLovLoading, setLovLoading] = useState<boolean>(true)
  const { dispatch } = useAppContext()

  useEffect(() => {
    getLov(lovType)
      .then((response) => response.data && setLovValues(response.data))
      .catch((error: AxiosError) =>
        dispatch(
          createAnnounceEvent(
            AnnounceMode.Error,
            `There was an error fetching ${lovType} data. ${error.message}`
          )
        )
      )
      .finally(() => setLovLoading(false))

    return () => {
      cancelGetLov()
    }
  }, [dispatch, lovType])

  return {
    isLovLoading,
    lovValues
  }
}
