import { SourceSystem, productStatusSortOrder } from '../../../constants'
import {
  AvailableWarehouse,
  IAugmentedCatalogItemDto
} from '../../../services/PortfolioJourneys/PortfolioJourney.types'
import {
  DefaultWarehouseDto,
  StockDto,
  WarehouseDto
} from '../../../types/swaggerTypes'

import _ from 'lodash'
import { CountryAlphaCodes } from '../../../constants/countryAlpha2Codes'

export interface ICatalogItemDto extends IAugmentedCatalogItemDto {
  warehouseCodes: string[]
  warehouses: WarehouseDto[] | undefined
}

/**
 * Get the stock/warehouse with the most stock in reserve
 * @param stocks StockDto[]
 * @returns stock StockDto
 */
export const getBestStockedWarehouse = (stocks: StockDto[]): StockDto => {
  return stocks.reduce<StockDto>(
    (previousValue: StockDto, currentValue: StockDto) => {
      if (currentValue.reservableStock > previousValue.reservableStock) {
        previousValue = currentValue
      }
      return previousValue
    },
    stocks[0]
  )
}

/**
 * Sorts the catalogue items in the order of an array of availability statuses
 * @param catalogueItems
 */
export const sortCatalogItemsByAvailability = (
  a: IAugmentedCatalogItemDto,
  b: IAugmentedCatalogItemDto
): number => {
  const sortedStatusA = a.availableStatus
    ? productStatusSortOrder.indexOf(a.availableStatus)
    : -1
  const sortedStatusB = b.availableStatus
    ? productStatusSortOrder.indexOf(b.availableStatus)
    : -1
  // If they are the same sort by availability
  if (
    sortedStatusA === sortedStatusB &&
    a.reservableStock &&
    b.reservableStock
  ) {
    return a.reservableStock > b.reservableStock ? -1 : 1
  }
  return sortedStatusA > sortedStatusB ? 1 : -1
}

/**
 * Sorts the catalogue items in the order of an array of availability statuses
 * @param catalogueItems
 */
export const sortCatalogItemsByAvailabilityAus = (
  a: IAugmentedCatalogItemDto,
  b: IAugmentedCatalogItemDto
): number => {
  const sortedStatusA = a.availableStatus
    ? productStatusSortOrder.indexOf(a.availableStatus)
    : -1
  const sortedStatusB = b.availableStatus
    ? productStatusSortOrder.indexOf(b.availableStatus)
    : -1

  if (sortedStatusA !== sortedStatusB) {
    return sortedStatusA > sortedStatusB ? 1 : -1
  }

  // If they are the same sort by availability
  if (a.reservableStock && b.reservableStock) {
    return a.reservableStock > b.reservableStock ? -1 : 1
  }

  // If they are the same first check if statuses are same and availabe and stock level is 0 or undefined
  //now we have 2 cases

  //2. a has stock that is undefined or 0
  if (a.reservableStock) {
    return -1
  }

  if (b.reservableStock) {
    return 1
  }

  if (a.item.sourceSystem === SourceSystem.Sage) {
    return -1
  }

  return 1
}

/**
 * Get all matching default warehouse codes from an array of stocks
 * If none supplied defaults to C02 Byfleet
 * @param stockLevels
 * @param defaultWarehouses
 */
export const getMatchingDefaultWarehouseCodes = (
  stockLevels: StockDto[],
  portfolioCountryCode: string,
  defaultWarehouses?: DefaultWarehouseDto[]
): string[] => {
  if (defaultWarehouses) {
    // Append name in same schema as stock levels so we can compare the two using lodash
    const defaultWarehousesAugmented = defaultWarehouses.map((w) => {
      return {
        ...w,
        warehouse: w.warehouseCode
      }
    })
    const matchingWarehouses = _.intersectionBy(
      defaultWarehousesAugmented,
      stockLevels,
      'warehouse'
    ).map((w) => w.warehouseCode)
    return matchingWarehouses ?? [getDefaultWarehouseCode(portfolioCountryCode)]
  }
  return [getDefaultWarehouseCode(portfolioCountryCode)]
}

export const getDefaultWarehouseCode = (portfolioCountryCode: string) => {
  switch (portfolioCountryCode) {
    case CountryAlphaCodes.Australia:
      return AvailableWarehouse.T07
    default:
      return AvailableWarehouse.C02
  }
}
